import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Fab,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Delete } from "@material-ui/icons";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DirectionsTransitFilledIcon from "@mui/icons-material/DirectionsTransitFilled";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { get, post, put, del } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import DeleteModal from "../Layout/Academic/DeleteModal";
import { Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import appendStringToUrls from "../../utils/appendString";
import SettingContext from "../../context/SettingsContext";
import SectionsSubHeader from "../../components/SectionSubHeader";
import Wrapper from "../../components/Wrapper";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";

const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const Vehicle = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState([]);
  const [list, setList] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [date, setDate] = useState(dayjs(Date.now()));
  const [dialog, setOpenDialog] = React.useState(false);
  const [dialogRoute, setOpenDialogRoute] = React.useState(false);
  const [editVehicle, setEditVehicle] = useState();
  const [addForm, setAddForm] = useState({});
  const [renewalDate, setRenewelDate] = useState();
  const [employees, setEmployees] = useState([]);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [showDetails, setShowDetails] = useState(null);

  const [loader, setLoader] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    getVehicle();
  }, [selectedSetting._id]);

  useEffect(() => {
    const getDriver = async () => {
      try {
        const { data: role, status } = await get(`${urls.role.getAllRole}`, {
          params: {
            search: {
              roleName: "DRIVER",
            },
          },
        });

        if (status > 199 && status < 299) {
          const { data: employees, status } = await get(
            `${urls.employee.getAllEmployee}`,
            {
              params: {
                search: {
                  role: role.data[0]?._id,
                },
              },
            }
          );
          if (status > 199 && status < 299) {
            setEmployees(employees.data);
            console.log(employees.data, "driverr");
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getDriver();
  }, [selectedSetting._id]);

  useEffect(() => {
    getVehicle();
  }, [selectedSetting._id]);

  const getVehicle = async () => {
    console.log("lklklklklklklklk");
    try {
      const { data, status } = await get(urls.transport.getList);
      setList(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(`${urls.transport.deletVehicle}`, id);
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setEditVehicle(null);
    setAddForm({});
    setOpen(false);
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      if (!editVehicle) {
        const { status } = await post(`${urls.transport.create}`, {
          vehicleNumber: addForm.vehicleNumber,
          vehicleModel: addForm.vehicleModel,
          driver: addForm.driver,
          vehicleLicense: addForm.vehicleLicense,
          vehicleContact: addForm.vehicleContact,
          trackingId: addForm.trackingId,
          insuranceName: addForm.insuranceName,
          insuranceId: addForm.insuranceId,
          renewalDate: renewalDate,
          totalPassenger: addForm.totalPassenger,
          note: addForm.note,
        });
        getVehicle();
        handleClose();
      } else {
        const { status } = await put(
          `${urls.transport.update}/${editVehicle}`,
          "",
          {
            vehicleNumber: addForm.vehicleNumber,
            vehicleModel: addForm.vehicleModel,
            driver: addForm.driver,
            vehicleLicense: addForm.vehicleLicense,
            vehicleContact: addForm.vehicleContact,
            trackingId: addForm.trackingId,
            insuranceName: addForm.insuranceName,
            insuranceId: addForm.insuranceId,
            renewalDate: renewalDate,
            totalPassenger: addForm.totalPassenger,
            note: addForm.note,
          }
        );
        getVehicle();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const styles = {
    button: {
      backgroundColor: "orange",
    },
    textfield: {
      marginTop: "25px",
    },
    addbutton: {
      paddingTop: "6.2px",
      paddingBottom: "6.2px",
    },
    tab: {
      width: "100%",
      borderCollapse: "collapse",
    },
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      list.length > 0 &&
        setSearchFilter(
          list.filter((ele) =>
            ele.vehicleNumber.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenDialog = (vehicle) => {
    setShowDetails(vehicle);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setShowDetails(null);
    setOpenDialog(false);
  };

  const handleClickOpenDialogRoute = () => {
    setOpenDialogRoute(true);
  };
  const handleCloseDialogRoute = () => {
    setOpenDialogRoute(false);
  };

  const handleClassMapEdit = (id, data) => {
    setEditVehicle(id);
    setAddForm({
      vehicleNumber: data.vehicleNumber,
      driver: data.driver_id,
      totalPassenger: data.totalPassenger,
      trackingId: data.trackingId,
      vehicleModel: data.vehicleModel,
      insuranceName: data.insuranceName,
      renewalDate: data.renewalDate,
      insuranceId: data.insuranceId,
      vehicleContact: data.vehicleContact,
      vehicleLicense: data.vehicleLicense,
    });
    handleClickOpen();
  };

  return (
    <Wrapper>
      <SectionsSubHeader title="Vehicle" />

      <div style={{ margin: "15px 0", overflow: "hidden" }}>
        {/* <div className={styles.searchInputContainer}> */}
        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          sx={{ marginTop: "15px", marginBottom: "10px" }}
        >
          <Grid xs="auto">
            <TextField
              size="small"
              value={search}
              placeholder="Search By Vehicle Number"
              onChange={handleSearch}
              label="Search"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        {/* </div> */}
        <Dialog
          fullScreen={fullScreen}
          aria-labelledby="responsive-dialog-title"
          open={open}
          onClose={handleClose}
        >
          <form className="form-tag" onSubmit={handleAddSubmit}>
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                textAlign="center"
                marginBottom="15px"
                fontSize="20px"
                fontWeight="bold"
              >
                {editVehicle ? "Update Vehicle" : "Add Vehicle"}
              </Typography>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                }}
              >
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    id="filled-required"
                    size="small"
                    label="Vehicle Number"
                    name="vehicleNumber"
                    value={addForm.vehicleNumber || ""}
                    onChange={handleAddForm}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    id="filled-required"
                    size="small"
                    label="Vehicle Model"
                    name="vehicleModel"
                    value={addForm.vehicleModel || ""}
                    onChange={handleAddForm}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Driver</InputLabel>
                    <Select
                      label="Driver"
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      name="driver"
                      value={addForm.driver || ""}
                      onChange={handleAddForm}
                    >
                      {employees.map((employee) => (
                        <MenuItem key={employee._id} value={employee._id}>
                          {employee.basicInfo.empName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    id="filled-required"
                    size="small"
                    label="Vehicle License"
                    name="vehicleLicense"
                    onChange={handleAddForm}
                    value={addForm.vehicleLicense || ""}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    id="filled-required"
                    size="small"
                    label="Vehicle Contact"
                    name="vehicleContact"
                    value={addForm.vehicleContact || ""}
                    onChange={handleAddForm}
                    sx={{
                      label: { fontSize: 12 },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    id="filled-required"
                    size="small"
                    label="Tracking ID"
                    name="trackingId"
                    value={addForm.trackingId || ""}
                    onChange={handleAddForm}
                    sx={{
                      label: { fontSize: 12 },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    id="filled-required"
                    size="small"
                    label="Insurence Name"
                    name="insuranceName"
                    onChange={handleAddForm}
                    value={addForm.insuranceName || ""}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    id="filled-required"
                    size="small"
                    label="Insurence ID"
                    name="insuranceId"
                    value={addForm.insuranceId || ""}
                    onChange={handleAddForm}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                    fullWidth
                    id="filled-required"
                    size="small"
                    label="Total Seats"
                    name="totalPassenger"
                    onChange={handleAddForm}
                    value={addForm.totalPassenger || ""}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date"
                      size="small"
                      value={renewalDate}
                      onChange={(newDate) => setRenewelDate(newDate)}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          size="small"
                          sx={{
                            label: { fontSize: 12 },
                          }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextareaAutosize
                    maxRows={4}
                    placeholder="Drop a note"
                    name="note"
                    value={addForm.note || ""}
                    onChange={handleAddForm}
                    style={{
                      width: "100%",
                      maxHeight: 110,
                      padding: 10,
                      height: 70,
                      borderRadius: "5px",
                      marginLeft: "2px",
                      marginTop: "6px",
                      overflow: "auto",
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "2px",
                      justifyContent: {
                        md: "right",
                        sm: "right",
                        xs: "center",
                        gap: "10px",
                      },
                    }}
                  >
                    <Tooltip title="Cancel">
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        color="error"
                        size="medium"
                        type="button"
                        sx={{ m: 1, color: "#fff" }}
                      >
                        Cancel
                      </Button>
                    </Tooltip>
                    <Tooltip title="Submit">
                      <LoadingButton
                        loading={loader}
                        variant="contained"
                        size="medium"
                        type="submit"
                        sx={{
                          background: "rgb(27, 55, 121)",
                          ":hover": { background: "rgb(27, 55, 121)" },
                        }}
                      >
                        {editVehicle ? "update" : "Add"}
                      </LoadingButton>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Dialog>

        <Box overflow="scroll">
          <Table sx={{ marginTop: "1rem", overflowX: "scroll" }}>
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  vehicle Number
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Driver
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Contact
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Total passengers
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Tracking ID
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Vehicle Model
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Insurance Name
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Renewal Date
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter.map((vehicle, index) => (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {vehicle.vehicleNumber}
                      </TableCell>
                      <TableCell align="center">
                        {vehicle.driver?.basicInfo.empName}
                      </TableCell>
                      <TableCell align="center">
                        {vehicle.vehicleContact}
                      </TableCell>
                      <TableCell align="center">
                        {vehicle.totalPassenger}
                      </TableCell>
                      <TableCell align="center">{vehicle.trackingId}</TableCell>
                      <TableCell align="center">
                        {vehicle.vehicleModel}
                      </TableCell>
                      <TableCell align="center">
                        {vehicle.insuranceName}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(vehicle.renewalDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            sx={{ color: "#1b3779" }}
                            onClick={() =>
                              handleClassMapEdit(vehicle._id, vehicle)
                            }
                          >
                            <EditIcon />
                          </Button>
                          <Button
                            sx={{ color: "#1b3779" }}
                            onClick={() => handleClickOpenDialog(vehicle)}
                          >
                            <VisibilityIcon />
                          </Button>
                          <Button
                            sx={{
                              color: "#1b3779",
                            }}
                            onClick={() => setDeleteModal(vehicle._id, vehicle)}
                          >
                            <Delete fontSize="small" />
                          </Button>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDeleteone}
                            id={vehicle._id}
                            setDeleteModal={setDeleteModal}
                          />
                          <Button
                            sx={{
                              color: "#1b3779",
                            }}
                            onClick={() => handleClickOpenDialogRoute()}
                          >
                            <DirectionsTransitFilledIcon />
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                : list.map((vehicle, index) => (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {vehicle.vehicleNumber}
                      </TableCell>
                      <TableCell align="center">
                        {vehicle.driver?.basicInfo.empName}
                      </TableCell>
                      <TableCell align="center">
                        {vehicle.vehicleContact}
                      </TableCell>
                      <TableCell align="center">
                        {vehicle.totalPassenger}
                      </TableCell>
                      <TableCell align="center">{vehicle.trackingId}</TableCell>
                      <TableCell align="center">
                        {vehicle.vehicleModel}
                      </TableCell>
                      <TableCell align="center">
                        {vehicle.insuranceName}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(vehicle.renewalDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                          className="edit-delete"
                        >
                          <Button
                            onClick={() =>
                              handleClassMapEdit(vehicle._id, vehicle)
                            }
                          >
                            <EditIcon />
                          </Button>
                          <Button
                            onClick={() => handleClickOpenDialog(vehicle)}
                          >
                            <VisibilityIcon />
                          </Button>
                          <Button
                            sx={{ color: "#1b3779", fontSize: "5px" }}
                            onClick={() => setDeleteModal(vehicle._id, vehicle)}
                          >
                            <Delete fontSize="small" />
                          </Button>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDeleteone}
                            id={vehicle._id}
                            setDeleteModal={setDeleteModal}
                          />
                          <Button onClick={() => handleClickOpenDialogRoute()}>
                            <DirectionsTransitFilledIcon />
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </Box>
        {!list.length && !search.trim() && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        {search.trim() && !searchFilter.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        <Box className="add-icon">
          <Fab
            variant="contained"
            sx={{
              color: "#ffff",
              backgroundColor: "#1b3779",
              ":hover": { background: "rgb(27, 55, 121)" },
            }}
            onClick={handleClickOpen}
          >
            <AddIcon />
          </Fab>
        </Box>
        <BootstrapDialog
          onClose={handleCloseDialog}
          aria-labelledby="customized-dialog-title"
          open={dialog}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: "#1b3779", color: "white" }}
          >
            Vehicle Information
          </BootstrapDialogTitle>

          <DialogContent dividers>
            <table className="hostel" style={styles.tab}>
              <tbody>
                {showDetails ? (
                  <Box>
                    <tr>
                      <td>School Name</td>
                      <td>{selectedSetting.schoolName}</td>
                    </tr>
                    <tr>
                      <td>Vehicle Number</td>
                      <td>{showDetails.vehicleNumber}</td>
                    </tr>
                    <tr>
                      <td>Vehicle Modal</td>
                      <td>{showDetails.vehicleModel}</td>
                    </tr>
                    <tr>
                      <td>Driver</td>
                      <td>{showDetails.driver.basicInfo.empName}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Vehicle License</td>
                      <td>{showDetails.vehicleLicense}</td>
                    </tr>
                    <tr>
                      <td>Vehicle Contact</td>
                      <td>{showDetails.vehicleContact}</td>
                    </tr>
                    <tr>
                      <td>Note</td>
                      <td>{showDetails.note}</td>
                    </tr>
                    <tr>
                      <td>Created</td>
                      <td>
                        {dayjs(showDetails.createdAt).format("DD-MM-YYYY")}
                      </td>
                    </tr>
                  </Box>
                ) : null}
              </tbody>
            </table>
          </DialogContent>
        </BootstrapDialog>

        <BootstrapDialog
          onClose={handleCloseDialogRoute}
          aria-labelledby="customized-dialog-title"
          open={dialogRoute}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDialogRoute}
            style={{ backgroundColor: "#1b3779", color: "white" }}
          >
            Vehicle Route Information
          </BootstrapDialogTitle>

          <DialogContent dividers>
            <table className="hostel" style={styles.tab}>
              <tbody>
                <Box>
                  <tr>
                    <td>School Name</td>
                    <td>Excellent English Medium Primary & High School</td>
                  </tr>
                  <tr>
                    <td>Transport Route Title</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Vehicle Start</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Vehicle End</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Route Stop Fare</td>
                    <td>
                      <tr
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <th>Stop Name</th>
                        <th>Stop KM</th>
                        <th>Pick Time</th>
                        <th>Drop Time</th>
                      </tr>
                    </td>
                  </tr>
                </Box>
              </tbody>
            </table>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </Wrapper>
  );
};

export default Vehicle;
