import React, { useContext, useEffect, useState } from "react";
import AddAdmitStudent from "./AddAdmitStudent";
import { useParams } from "react-router-dom";
import { get } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";

const EditStudent = (props) => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [student, setStudent] = useState(undefined);
  const { studentId } = useParams();

  useEffect(() => {
    const getStudent = async () => {
      try {
        const res = await get(urls.students.getStudent + studentId);
        setStudent(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getStudent();
  }, [studentId, selectedSetting]);
  return (
    <div>
      {student ? <AddAdmitStudent student={student} /> : <h1>loading</h1>}
    </div>
  );
};

export default EditStudent;
