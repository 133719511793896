import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { Delete, Edit } from "@mui/icons-material";
import DateModal from "./DateModal";
import "../../../styles/components/AcademicYear/AcademicYearTable.scss";
import { del, get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { Card, Grid, Tooltip, Typography, Box } from "@mui/material";
import DeleteModal from "../Academic/DeleteModal";
import { useContext } from "react";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SettingSelector from "../../SettingSelector";

const AcademicYear = () => {
  const [academicYears, setAcademicYears] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [updateAcademicYear, setUpdateAcademicYear] = useState(undefined);
  const { setSettingsContext, selectedSetting } = useContext(SettingContext);

  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [loader, setLoader] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setUpdateAcademicYear(undefined);
  };

  const handleDelete = async (id) => {
    try {
      const res = await del(urls.administrator.academicYearDel, id);
      if (res.data.success) {
        setAcademicYears(
          academicYears.filter((item) => item._id !== res.data.data._id)
        );
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdate = async (data, id) => {
    setLoader(true);
    try {
      const response = await put(urls.administrator.academicYearPut, id, data);
      setAcademicYears(
        academicYears.map((e) => {
          if (e._id === response.data.data._id) {
            return response.data.data;
          } else {
            return e;
          }
        })
      );
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const handleSubmit = async (e, data) => {
    e.preventDefault();
    setLoader(true);
    try {
      const res = await post(urls.administrator.academicYearPost, data);
      setAcademicYears([...academicYears, res.data.data]);
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };
  const handleEdit = async (id) => {
    try {
      setOpen(true);
      const getUserRoleById = academicYears.find((item) => item._id === id);
      setUpdateAcademicYear(getUserRoleById);
    } catch (error) {
      console.log(error);
    }
  };
  const handleYearActive = async (id) => {
    try {
      const res = await put(urls.administrator.academicYearActive, id);
      const activeYear = `${res.data.data.academicYearFrom}-${res.data.data.academicYearTo}`;
      setSettingsContext((prev) => ({
        ...prev,
        activeAcademicYear: activeYear,
      }));
      if (res.data.success) {
        const res = await get(urls.administrator.academicYearGetAll);
        setAcademicYears([...res.data.data]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const getAllAcademicYear = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll);
        setAcademicYears([...res.data.data]);
      } catch (error) {
        console.log(error);
      }
    };
    getAllAcademicYear();
  }, [selectedSetting]);
  return (
    <>
      <Card style={{ margin: 15, padding: 10 }}>
        <Box
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          spacing={2}
        >
          <Typography
            style={{
              color: "red",
              fontFamily: "cursive",
              display: "inline-block",
            }}
          >
            Academic Year :
          </Typography>
          <SettingSelector />
        </Box>
      </Card>
      <div style={{ margin: "15px" }}>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer component={Paper}>
            <Table sx={{}} aria-label="simple table">
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="academicYear-table-header">#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="academicYear-table-header">
                      Academic Year
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="academicYear-table-header">Status</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="academicYear-table-header">Note</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="academicYear-table-header">Action</span>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {academicYears.map((row, index) => (
                  <TableRow
                    key={row._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.academicYearFrom}-{row.academicYearTo}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.status}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.note}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      <div className="edit-delete">
                        <Tooltip title="Edit" disableInteractive>
                          <Button onClick={() => handleEdit(row._id)}>
                            <Edit />
                          </Button>
                        </Tooltip>
                        {/* <Tooltip title="Delete" disableInteractive>
                          <Button onClick={() => setDeleteModal(row._id)}>
                            <Delete />
                          </Button>
                        </Tooltip> */}
                        <Tooltip title="Status" disableInteractive>
                          <Button
                            variant="contained"
                            size="small"
                            sx={{
                              background:
                                row.status === "active"
                                  ? "green"
                                  : "rgb(27 55 121)",

                              ":hover": {
                                background:
                                  row.status === "active"
                                    ? "green"
                                    : "rgb(27 55 121)",
                              },
                              width: "90px",
                              fontSize: "12px",
                            }}
                            onClick={() => handleYearActive(row._id)}
                          >
                            {row.status === "active" ? "activated" : "active"}
                          </Button>
                        </Tooltip>
                      </div>
                      <DeleteModal
                        deleteModal={deleteModal}
                        handleDelete={handleDelete}
                        id={row._id}
                        setDeleteModal={setDeleteModal}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {!academicYears.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </Paper>
        <div className="add-icon">
          <DateModal
            loader={loader}
            handleSubmit={handleSubmit}
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleUpdate={handleUpdate}
            updateAcademicYear={updateAcademicYear}
          />
        </div>
      </div>
    </>
  );
};

export default AcademicYear;
