import React from "react";
import { Modal, Typography, Divider, Box, Button } from "@mui/material";
// import { WarningRounded } from "@mui/icons-material";
import ModalDialog from "@mui/joy/ModalDialog";

const DeleteModal = (props) => {
  return (
    <Modal
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      open={props.deleteModal === props.id}
      onClose={() => props.setDeleteModal(false)}
    >
      <ModalDialog
        sx={{ backgroundColor: "white", borderRadius: "10px" }}
        variant="outlined"
        role="alertdialog"
      >
        <Typography
          id="modal-modal-title"
          component="h2"
          level="inherit"
          fontSize="1.25em"
          mb="0.25em"
          // startDecorator={<WarningRounded />}
        >
          Confirmation
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Typography id="alert-dialog-modal-description" mb={3}>
          Are you sure you want to delete?
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="plain"
            color="neutral"
            onClick={() => props.setDeleteModal(false)}
            sx={{ background: "#ddd" }}
          >
            No
          </Button>

          <Button
            variant="solid"
            onClick={() => {
              props.handleDelete(props.id, props.routineIndex);
              props.setDeleteModal(false);
            }}
          >
            Yes
          </Button>
        </Box>
      </ModalDialog>
    </Modal>
  );
};

export default DeleteModal;
