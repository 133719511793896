import * as React from "react";
import { styled } from "@mui/material/styles";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { blue, red } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import css from "../styles/Dashboard/Dashboard.module.css";
import { useState, useEffect, useContext } from "react";
import { urls as url } from "../services/urlConstant";
import appendStringToUrls from "../utils/appendString";
import SettingContext from "../context/SettingsContext";

import { get } from "../services/apis";
import dayjs from "dayjs";
import { Box } from "@mui/material";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function StudentNews() {
  const [expanded, setExpanded] = React.useState(false);
  const [expanded1, setExpanded1] = React.useState(false);
  const [announceNotice, setAnounceNotice] = useState([]);
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  useEffect(() => {
    getNews();
  }, [selectedSetting]);

  const getNews = async () => {
    try {
      const res = await get(`${urls.studentDashboard.getNews}`, {
        params: {
          search: {
            webView: "yes",
          },
        },
      });
      setAnounceNotice(res.data.data);
      console.log(res, "newsffffffffffffffff");
    } catch (error) {
      console.log(error);
    }
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleExpandClick1 = () => {
    setExpanded1(!expanded1);
  };

  return (
    <div className={css.scrollableCard}>
      <div>
        <Box className={css.cardScroll}>
          {announceNotice.map((news) => (
            <>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                    S
                  </Avatar>
                }
                action={
                  <CardActions disableSpacing>
                    <ExpandMore
                      expand={expanded}
                      onClick={handleExpandClick}
                      aria-expanded={expanded}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  </CardActions>
                }
                title={news.title}
              />

              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                  <CardMedia
                    component="img"
                    height="194"
                    image={news.image.link}
                    alt="no image"
                  />
                  <Typography
                    paragraph
                    sx={{ textAlign: "left", fontSize: 14 }}
                  >
                    {news.news}
                  </Typography>
                  <Typography style={{ display: "flex", textAlign: "left" }}>
                    <b>{dayjs(news.date).format("DD/MM/YYYY")}</b>
                  </Typography>
                </CardContent>
              </Collapse>
            </>
          ))}
        </Box>
      </div>
    </div>
  );
}
