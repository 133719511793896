import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Search } from "@material-ui/icons";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { urls as url } from "../../../services/urlConstant";
import { get, post, put } from "../../../services/apis";
import SectionSubHeader from "../../SectionSubHeader";
import { useLocation } from "react-router-dom";
import Wrapper from "../../Wrapper";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

const Vendor = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [photo, setPhoto] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [dataForEdit, setDataForEdit] = useState();
  const [vendorData, setVendorData] = useState([]);
  const [showEditTab, setShowEditTab] = useState(false);
  const [editId, setEditId] = useState();
  const [image, setImage] = useState("");
  const { state: localState } = useLocation();
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    if (localState) {
      if (localState == "inventory") {
        setTabValue(1);
      }
    }
  }, [localState]);

  const [data, setData] = useState({
    category: "",
    name: "",
    phone: 0,
    email: "",
    gstNumber: "",
    dealerName: "",
    dealerPhoneNumber: 0,
    website: "",
    address: "",
    city: "",
    zipCode: 0,
    bankName: "",
    accountNumber: "",
    ifscCode: "",
    branchName: "",
    state: "",
  });

  let {
    category,
    name,
    phone,
    email,
    gstNumber,
    dealerName,
    dealerPhoneNumber,
    website,
    address,
    city,
    zipCode,
    bankName,
    accountNumber,
    ifscCode,
    branchName,
    state,
  } = data;

  const handleAddForm = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const styles = {
    button: {
      backgroundColor: "orange",
    },
    card: {
      padding: 10,
      margin: "10px 0px",
    },
    cardButton: {
      display: "flex",
      justifyContent: "flex-end",
      textAlign: "center",
      gap: "10px",
    },

    textfield: {
      marginTop: "25px",
      width: "100%",
    },
    addbutton: {
      paddingTop: "6.2px",
      paddingBottom: "6.2px",
    },
  };
  const handleClose = () => {
    setOpen(false);
    // setShowEdit(false);
    // setEditIssue(null);
    // setDataSet({});
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setTabValue(0);
  };

  function handleChange(e) {
    console.log(e.target.files);
    setPhoto(e.target.files[0]);
    setImage(e.target.files[0].name);
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      basicInfo: {
        name,
        category,
        phone,
        email,
        gstNumber,
        dealerName,
        dealerPhoneNumber,
        website,
      },
      addressInfo: {
        address,
        city,
        zipCode,
        state,
      },
      bankInfo: {
        bankName,
        ifscCode,
        accountNumber,
        branchName,
      },
      photo: "",
    };
    console.log(payload);

    let imageRes = "ghghghghghgh";
    if (image) {
      const fileForm = new FormData();
      fileForm.append("files", photo);
      imageRes = await post(urls.fileUpload.postFileUpload, fileForm);
    } else {
      return alert("Please select an Image");
    }

    if (!editId) {
      try {
        const { data } = await post(`${urls.vendor.createVendor}`, {
          ...payload,
          photo: imageRes.data.result[0]._id,
        });
        setVendorData([...vendorData, data.data]);
        fetchData();
        setShowEditTab(false);
        setTabValue(0);
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      let imageRes = "ghghghghghgh";
      if (image) {
        const fileForm = new FormData();
        fileForm.append("files", photo);
        imageRes = await post(urls.fileUpload.postFileUpload, fileForm);
        console.log(imageRes, "image");
      } else {
        return alert("Please select an Image");
      }
      const response = await put(`${urls.vendor.updateVendor}${editId}`, "", {
        ...payload,
        photo: imageRes.data.result[0]._id,
      });
      console.log(response);
      setVendorData(
        vendorData.map((e) => {
          if (e._id === response.data.data._id) {
            return response.data.data;
          } else {
            return e;
          }
        })
      );
      fetchData();
      setShowEditTab(false);
      setTabValue(0);
    }
    setData({
      category: "",
      name: "",
      phone: 0,
      email: "",
      gstNumber: "",
      dealerName: "",
      dealerPhoneNumber: 0,
      website: "",
      address: "",
      city: "",
      zipCode: 0,
      bankName: "",
      accountNumber: "",
      ifscCode: "",
      branchName: "",
      state: "",
    });

    window.scrollTo(0, 0);
  };

  useEffect(() => {
    fetchData();
  }, [selectedSetting]);
  const fetchData = async () => {
    try {
      let { data } = await get(`${urls.vendor.getAll}`);
      console.log(data.data, "datattta");
      setVendorData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  let handleEdit = (id, item) => {
    setShowEditTab(true);
    setIsEditMode(true); // Set edit mode to true when editing
    setEditId(id);
    setData({
      category: item.basicInfo.category,
      name: item.basicInfo.name,
      phone: item.basicInfo.phone,
      email: item.basicInfo.email,
      gstNumber: item.basicInfo.gstNumber,
      dealerName: item.basicInfo.dealerName,
      dealerPhoneNumber: item.basicInfo.dealerPhoneNumber,
      website: item.basicInfo.website,
      address: item.addressInfo.address,
      city: item.addressInfo.city,
      zipCode: item.addressInfo.zipCode,
      bankName: item.bankInfo.bankName,
      accountNumber: item.bankInfo.accountNumber,
      ifscCode: item.bankInfo.ifscCode,
      branchName: item.bankInfo.branchName,
      state: item.addressInfo.state,
    });
    setPhoto(item.photo);
    setTabValue(2);
    setDataForEdit(item);
  };

  const handleUpdateTabData = () => {
    console.log("handleUpdateTabData");
    setData({});
    setShowEditTab(false);
    setTabValue(2);
  };

  const handleUpdateShowTabData = () => {
    setShowEditTab(false);
  };

  let handleTabData = () => {
    setData({
      category: dataForEdit.basicInfo.category,
      name: dataForEdit.basicInfo.name,
      phone: dataForEdit.basicInfo.phone,
      email: dataForEdit.basicInfo.email,
      gstNumber: dataForEdit.basicInfo.gstNumber,
      dealerName: dataForEdit.basicInfo.dealerName,
      dealerPhoneNumber: dataForEdit.basicInfo.dealerPhoneNumber,
      website: dataForEdit.basicInfo.website,
      address: dataForEdit.addressInfo.address,
      city: dataForEdit.addressInfo.city,
      zipCode: dataForEdit.addressInfo.zipCode,
      bankName: dataForEdit.bankInfo.bankName,
      accountNumber: dataForEdit.bankInfo.accountNumber,
      ifscCode: dataForEdit.bankInfo.ifscCode,
      branchName: dataForEdit.bankInfo.branchName,
      state: dataForEdit.addressInfo.state,
    });
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      vendorData.length > 0 &&
        setFilter(
          vendorData.filter((ele) =>
            ele.basicInfo.name
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setFilter([]);
    }
  };

  return (
    <Wrapper>
      <SectionSubHeader title="Vendor" />
      <div style={{ margin: "15px 0" }}>
        <Card style={{ padding: 10 }}>
          <Box>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Vendor List"
                onClick={handleUpdateShowTabData}
                {...a11yProps(0)}
              />
              <Tab
                label="Add Vendor"
                onClick={handleUpdateTabData}
                {...a11yProps(1)}
              />
              {showEditTab && (
                <Tab
                  label="Edit Vendor"
                  onClick={handleTabData}
                  {...a11yProps(2)}
                />
              )}
            </Tabs>
          </Box>
        </Card>
        <TabPanel value={tabValue} index={0}>
          <Card style={{ padding: "10px", margin: "10px 0px" }}>
            <div style={styles.cardButton}>
              <Button variant="contained">Excel</Button>
              <Button variant="contained">Pdf</Button>
            </div>
          </Card>
          <Paper
            sx={{ width: "100%", height: "60px", overflow: "hidden", mt: 2 }}
          >
            <div style={{ paddingTop: "10px" }}>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item xs="auto">
                  <form>
                    <TextField
                      size="small"
                      value={search}
                      onChange={handleSearch}
                      label="Search according to name"
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton edge="end" type="submit">
                              <Search />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </form>
                </Grid>
              </Grid>
            </div>
          </Paper>
          <Table sx={{ marginTop: "2rem" }}>
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Name
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? filter.map((item, index) => (
                    <TableRow key={item._id}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {item.basicInfo.name}
                      </TableCell>
                      <TableCell align="center">
                        <div className="edit-delete">
                          <Button onClick={() => handleEdit(item._id, item)}>
                            <EditIcon />
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                : vendorData.map((item, index) => (
                    <TableRow key={item._id}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {item.basicInfo.name}
                      </TableCell>
                      <TableCell align="center">
                        <div className="edit-delete">
                          <Button onClick={() => handleEdit(item._id, item)}>
                            <EditIcon />
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
          {!vendorData.length && !search.trim() && (
            <Typography
              variant="h6"
              style={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {search.trim() && !filter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Paper sx={{ padding: 1 }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12}>
                  <FormControl
                    size="small"
                    sx={{
                      textAlign: "start",
                      label: { fontSize: 20 },
                      fontWeight: "bold",
                    }}
                  >
                    <Box
                      sx={{
                        display: { sm: "inline-block", md: "flex" },
                        gap: 2,
                      }}
                    >
                      <label>Add Logo</label>
                      <input
                        type="file"
                        name="photo"
                        accept="image/*"
                        onChange={handleChange}
                      />
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {" "}
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    textAlign="start"
                    sx={{ fontSize: "15px", fontWeight: "bold" }}
                  >
                    Basic Information
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    // sx={{ m: 1.5, minWidth: 227 }}
                    fullWidth
                    size="small"
                    label="Category"
                    name="category"
                    onChange={handleAddForm}
                    value={category}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  {" "}
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    // sx={{ m: 1.5, minWidth: 227 }}
                    fullWidth
                    size="small"
                    label="Name"
                    name="name"
                    value={name}
                    onChange={handleAddForm}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  {" "}
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    // sx={{ m: 1.5, minWidth: 227 }}
                    fullWidth
                    size="small"
                    label="Phone No"
                    type="number"
                    name="phone"
                    value={phone}
                    onChange={handleAddForm}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label="Email"
                    name="email"
                    fullWidth
                    size="small"
                    value={email}
                    onChange={handleAddForm}
                    // sx={{ m: 1.5, minWidth: 227, label: { fontSize: 12 } }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  {" "}
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label="GST Number"
                    name="gstNumber"
                    fullWidth
                    size="small"
                    value={gstNumber}
                    onChange={handleAddForm}
                    // sx={{ m: 1.5, minWidth: 227, label: { fontSize: 12 } }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  {" "}
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label="Dealer Name"
                    name="dealerName"
                    size="small"
                    fullWidth
                    value={dealerName}
                    onChange={handleAddForm}
                    // sx={{ m: 1.5, minWidth: 227, label: { fontSize: 12 } }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} bgcolor={"yellow"}>
                  {" "}
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label="Dealer Phone No"
                    type="number"
                    name="dealerPhoneNumber"
                    size="small"
                    fullWidth
                    value={dealerPhoneNumber}
                    onChange={handleAddForm}
                    // sx={{ m: 1.5, minWidth: 227, label: { fontSize: 12 } }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} bgcolor={"red"}>
                  {" "}
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label="Vendor Website"
                    name="website"
                    fullWidth
                    size="small"
                    value={website}
                    onChange={handleAddForm}
                    // sx={{ m: 1.5, minWidth: 227, label: { fontSize: 12 } }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    textAlign="start"
                    marginTop="15px"
                    sx={{ fontSize: "15px", fontWeight: "bold" }}
                  >
                    Address Information
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  {" "}
                  <TextField
                    required
                    id="outlined-basic"
                    variant="outlined"
                    // sx={{ m: 1.5, minWidth: 227 }}
                    fullWidth
                    size="small"
                    label="Address"
                    name="address"
                    value={address}
                    onChange={handleAddForm}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  {" "}
                  <TextField
                    required
                    id="outlined-basic"
                    variant="outlined"
                    // sx={{ m: 1.5, minWidth: 227 }}
                    size="small"
                    fullWidth
                    label="City"
                    name="city"
                    value={city}
                    onChange={handleAddForm}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  {" "}
                  <TextField
                    required
                    id="outlined-basic"
                    variant="outlined"
                    // sx={{ m: 1.5, minWidth: 227 }}
                    fullWidth
                    size="small"
                    label="State"
                    name="state"
                    value={state}
                    onChange={handleAddForm}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  {" "}
                  <TextField
                    required
                    id="outlined-basic"
                    variant="outlined"
                    label="Zip Code"
                    type="number"
                    name="zipCode"
                    size="small"
                    fullWidth
                    value={zipCode}
                    onChange={handleAddForm}
                    // sx={{ m: 1.5, minWidth: 227, label: { fontSize: 12 } }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {" "}
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    textAlign="start"
                    marginTop="15px"
                    sx={{ fontSize: "15px", fontWeight: "bold" }}
                  >
                    Bank Information
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  {" "}
                  <TextField
                    required
                    id="outlined-basic"
                    variant="outlined"
                    // sx={{ m: 1.5, minWidth: 227 }}
                    size="small"
                    fullWidth
                    label="Bank Name"
                    name="bankName"
                    value={bankName}
                    onChange={handleAddForm}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  {" "}
                  <TextField
                    required
                    id="outlined-basic"
                    variant="outlined"
                    // sx={{ m: 1.5, minWidth: 227 }}
                    size="small"
                    fullWidth
                    label="Account Number"
                    name="accountNumber"
                    value={accountNumber}
                    onChange={handleAddForm}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    required
                    id="outlined-basic"
                    variant="outlined"
                    // sx={{ m: 1.5, minWidth: 227 }}
                    size="small"
                    fullWidth
                    label="IFSC Code"
                    name="ifscCode"
                    value={ifscCode}
                    onChange={handleAddForm}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  {" "}
                  <TextField
                    required
                    id="outlined-basic"
                    variant="outlined"
                    // sx={{ m: 1.5, minWidth: 227 }}
                    size="small"
                    fullWidth
                    label="Branch Name"
                    name="branchName"
                    value={branchName}
                    onChange={handleAddForm}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  display="flex"
                  justifyContent={{
                    xs: "center",
                    sm: "center",
                    md: "flex-end",
                    lg: "flex-end",
                  }}
                >
                  <Tooltip title="Cancel">
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      color="error"
                      size="medium"
                      type="button"
                      sx={{ m: 1, color: "#fff" }}
                    >
                      Cancel
                    </Button>
                  </Tooltip>
                  <Tooltip title="Submit">
                    <Button
                      variant="contained"
                      size="medium"
                      type="submit"
                      sx={{
                        m: 1,
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                    >
                      Submit
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </TabPanel>

        <TabPanel value={tabValue} index={2}>
          <Paper>
            <form onSubmit={handleSubmit}>
              <Box>
                {/* <div style={{ marginTop: "2px", textAlign: "center" }}>
                  <FormControl
                    variant="standard"
                    sx={{
                      m: 1,
                      minWidth: 227,
                      textAlign: "start",
                      label: { fontSize: 20 },
                    }}
                  >
                    <Box display="flex" gap={1}>
                      <label style={{ fontWeight: "bold" }}>Add Logo </label>
                      <input type="file" name="photo" onChange={handleChange} />
                    </Box>
                  </FormControl>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {isEditMode && dataForEdit && dataForEdit.photo && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <div>
                          <img
                            width="70"
                            height="70"
                            src={`${dataForEdit.photo.link}`}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div> */}

                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  display="flex"
                  justifyContent={{
                    xs: "flex-start",
                    sm: "flex-start",
                    md: "center",
                    lg: "center",
                  }}
                  p={2}
                >
                  <FormControl
                    size="small"
                    sx={{
                      textAlign: "start",
                      label: { fontSize: 20 },
                      fontWeight: "bold",
                    }}
                  >
                    <Box
                      sx={{
                        display: { sm: "inline-block", md: "flex" },
                        gap: 2,
                      }}
                    >
                      <label>Add Logo</label>

                      <input type="file" name="photo" onChange={handleChange} />
                    </Box>
                  </FormControl>
                  {isEditMode && dataForEdit && dataForEdit.photo && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <div>
                          <img
                            width="70"
                            height="70"
                            src={`${dataForEdit.photo.link}`}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </Grid>
                <Grid container spacing={1} p={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      textAlign="start"
                      sx={{ fontSize: "15px", fontWeight: "bold" }}
                    >
                      Basic Information
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    {" "}
                    <TextField
                      size="small"
                      fullWidth
                      id="outlined-basic"
                      label="Category"
                      name="category"
                      onChange={handleAddForm}
                      value={category}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    {" "}
                    <TextField
                      size="small"
                      fullWidth
                      id="outlined-basic"
                      variant="outlined"
                      label="Name"
                      name="name"
                      value={name}
                      onChange={handleAddForm}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    {" "}
                    <TextField
                      size="small"
                      fullWidth
                      id="outlined-basic"
                      variant="outlined"
                      label="Phone No"
                      type="number"
                      name="phone"
                      value={phone}
                      onChange={handleAddForm}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    {" "}
                    <TextField
                      size="small"
                      fullWidth
                      id="outlined-basic"
                      variant="outlined"
                      label="Email"
                      name="email"
                      value={email}
                      onChange={handleAddForm}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    {" "}
                    <TextField
                      size="small"
                      fullWidth
                      id="outlined-basic"
                      variant="outlined"
                      label="GST Number"
                      name="gstNumber"
                      value={gstNumber}
                      onChange={handleAddForm}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    {" "}
                    <TextField
                      size="small"
                      fullWidth
                      id="outlined-basic"
                      variant="outlined"
                      label="Dealer Name"
                      name="dealerName"
                      value={dealerName}
                      onChange={handleAddForm}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    {" "}
                    <TextField
                      size="small"
                      fullWidth
                      id="outlined-basic"
                      variant="outlined"
                      label="Dealer Phone No"
                      type="number"
                      name="dealerPhoneNumber"
                      value={dealerPhoneNumber}
                      onChange={handleAddForm}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    {" "}
                    <TextField
                      size="small"
                      fullWidth
                      id="outlined-basic"
                      variant="outlined"
                      label="Vendor Website"
                      name="website"
                      value={website}
                      onChange={handleAddForm}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      textAlign="start"
                      // marginBottom="20px"
                      sx={{ fontSize: "15px", fontWeight: "bold", mt: 1 }}
                    >
                      Address Information
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    {" "}
                    <TextField
                      size="small"
                      fullWidth
                      required
                      id="outlined-basic"
                      variant="outlined"
                      bel="Address"
                      name="address"
                      value={address}
                      onChange={handleAddForm}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    {" "}
                    <TextField
                      size="small"
                      fullWidth
                      required
                      id="outlined-basic"
                      variant="outlined"
                      bel="City"
                      name="city"
                      value={city}
                      onChange={handleAddForm}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    {" "}
                    <TextField
                      size="small"
                      fullWidth
                      required
                      id="outlined-basic"
                      variant="outlined"
                      bel="State"
                      name="state"
                      value={state}
                      onChange={handleAddForm}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    {" "}
                    <TextField
                      size="small"
                      fullWidth
                      required
                      id="outlined-basic"
                      variant="outlined"
                      label="Zip Code"
                      type="number"
                      name="zipCode"
                      value={zipCode}
                      onChange={handleAddForm}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      textAlign="start"
                      // marginBottom="20px"
                      sx={{ fontSize: "15px", fontWeight: "bold", mt: 1 }}
                    >
                      Bank Information
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    {" "}
                    <TextField
                      size="small"
                      fullWidth
                      required
                      id="outlined-basic"
                      variant="outlined"
                      label="Bank Name"
                      name="bankName"
                      value={bankName}
                      onChange={handleAddForm}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    {" "}
                    <TextField
                      size="small"
                      fullWidth
                      required
                      id="outlined-basic"
                      variant="outlined"
                      label="Account Number"
                      name="accountNumber"
                      value={accountNumber}
                      onChange={handleAddForm}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    {" "}
                    <TextField
                      size="small"
                      fullWidth
                      required
                      id="outlined-basic"
                      variant="outlined"
                      label="IFSC Code"
                      name="ifscCode"
                      value={ifscCode}
                      onChange={handleAddForm}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    {" "}
                    <TextField
                      size="small"
                      fullWidth
                      required
                      id="outlined-basic"
                      variant="outlined"
                      label="Branch Name"
                      name="branchName"
                      value={branchName}
                      onChange={handleAddForm}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    display="flex"
                    justifyContent={{
                      xs: "center",
                      sm: "center",
                      md: "flex-end",
                      lg: "flex-end",
                    }}
                  >
                    {" "}
                    <Tooltip title="Cancel">
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        color="error"
                        size="medium"
                        type="button"
                        sx={{ m: 1, color: "#fff" }}
                      >
                        Cancel
                      </Button>
                    </Tooltip>
                    <Tooltip title="Submit">
                      <Button
                        variant="contained"
                        size="medium"
                        type="submit"
                        sx={{
                          m: 1,
                          background: "rgb(27, 55, 121)",
                          ":hover": { background: "rgb(27, 55, 121)" },
                        }}
                      >
                        Submit
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Paper>
        </TabPanel>
      </div>
    </Wrapper>
  );
};

export default Vendor;
