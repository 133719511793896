import React, { useEffect, useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import {
  Avatar,
  Box,
  Button,
  Card,
  Fab,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import DeleteModal from "../Academic/DeleteModal";
import { del, get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { objHas } from "../../../utils/utils";
import TablePagination from "@mui/material/TablePagination";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import { LoadingButton } from "@mui/lab";
import {
  PrintSharp,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import DownloadForOfflineSharpIcon from "@mui/icons-material/DownloadForOfflineSharp";
import { toast } from "react-toastify";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../../components/SectionSubHeader";
import { Search } from "@material-ui/icons";

const styles = {
  icon: {
    // padding: "10px",
    fontSize: "12px",
    fontWeight: 500,
  },
  card: {
    padding: 15,
    marginBottom: 20,
    width: "100%",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
  },
};
const modal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "5px",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const StudentTable = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const pageRef = useRef(null);
  const [academicYears, setAcademicYears] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [schoolStudents, setSchoolStudents] = useState([]);
  const [updateStudent, setUpdateStudent] = useState(undefined);
  const [loader, setLoader] = useState(false);
  const [value, setValue] = useState(false);
  const [item, setItem] = useState(false);
  const [print, setPrint] = useState(false);
  const [formData, setFormData] = useState({
    status: "active",
  });
  const [bulkAdmitModal, setBulkAdmitModal] = useState(false);
  const [bulkUpdateModal, setBulkUpdateModal] = useState(false);
  const [fileChoosen, setFileChoosen] = useState(undefined);
  const [totalCount, setTotalCount] = useState(100);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [showPagination, setShowPagination] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [rowsData, setRowsData] = useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchFilter, setSearchFilter] = useState([]);
  const [disable, setDisable] = useState(false);

  const handleInputChange = (e) => {
    setSearch(e.target.value);
  };
  const [activeSortColumn, setActiveSortColumn] = useState("");
  // const [sortOrder, setSortOrder] = useState("asc");
  // const [sortedData, setSortedData] = useState(schoolStudents);

  const [sortField, setSortField] = useState("sectionOrder");
  const [sortOrder, setSortOrder] = useState("asc");
  const [selectedUser, setSelectedUser] = useState(null);

  //key value pair
  const [sort, setSort] = useState({
    order: "asc",
    rollNo: "asc",
    admissionNo: "asc",
    name: "asc",
    status: "asc",
    section: "asc",
  });

  const sortByRoll = (e) => {
    if (sort.rollNo == "asc") {
      let sortedList = [
        ...schoolStudents.sort(
          (a, b) => b.academicInfo.rollNo - a.academicInfo.rollNo
        ),
      ];

      setSchoolStudents(sortedList);
      setSort({ ...sort, rollNo: "des" });
    } else {
      let sortedList = [
        ...schoolStudents.sort(
          (a, b) => a.academicInfo.rollNo - b.academicInfo.rollNo
        ),
      ];
      setSchoolStudents(sortedList);
      setSort({ ...sort, rollNo: "asc" });
    }
  };

  const sortByAdmissionNo = (e) => {
    console.log("jjjj");
    if (sort.admissionNo == "asc") {
      let sortedList = [
        ...schoolStudents.sort(
          (a, b) => b.academicInfo.admissionNo - a.academicInfo.admissionNo
        ),
      ];

      setSchoolStudents(sortedList);
      setSort({ ...sort, admissionNo: "des" });
    } else {
      let sortedList = [
        ...schoolStudents.sort(
          (a, b) => a.academicInfo.admissionNo - b.academicInfo.admissionNo
        ),
      ];
      setSchoolStudents(sortedList);
      setSort({ ...sort, admissionNo: "asc" });
    }
  };

  // sorting.....
  const sortByName = (e) => {
    console.log("name");
    if (sort.name == "asc") {
      let sortedList = [
        ...schoolStudents.sort((a, b) =>
          b.basicInfo.name.localeCompare(a.basicInfo.name)
        ),
      ];

      setSchoolStudents(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      let sortedList = [
        ...schoolStudents.sort((a, b) =>
          a.basicInfo.name.localeCompare(b.basicInfo.name)
        ),
      ];
      setSchoolStudents(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };
  // status
  const sortByStatus = (e) => {
    console.log("status");

    if (sort.status == "asc") {
      let sortedList = [
        ...schoolStudents.sort((a, b) => b.status.localeCompare(a.status)),
      ];

      setSchoolStudents(sortedList);
      setSort({ ...sort, status: "des" });
    } else {
      let sortedList = [
        ...schoolStudents.sort((a, b) => a.status.localeCompare(b.status)),
      ];
      setSchoolStudents(sortedList);
      setSort({ ...sort, status: "asc" });
    }
  };

  const sortBySection = (e) => {
    if (sort.section == "asc") {
      let sortedList = [
        ...schoolStudents.sort((a, b) =>
          b.academicInfo.section.sectionName.localeCompare(
            a.academicInfo.section.sectionName
          )
        ),
      ];

      setSchoolStudents(sortedList);
      setSort({ ...sort, section: "des" });
    } else {
      let sortedList = [
        ...schoolStudents.sort((a, b) =>
          a.academicInfo.section.sectionName.localeCompare(
            b.academicInfo.section.sectionName
          )
        ),
      ];
      setSchoolStudents(sortedList);
      setSort({ ...sort, section: "asc" });
    }
  };

  const sortedUsers = schoolStudents.slice().sort((a, b) => {
    const fieldA = a[sortField];
    const fieldB = b[sortField];
    if (fieldA < fieldB) {
      return sortOrder === "asc" ? -1 : 1;
    }
    if (fieldA > fieldB) {
      return sortOrder === "asc" ? 1 : -1;
    }
    return 0;
  });

  const handleIconChange = async (e) => {
    e.preventDefault();
    try {
      if (formData.section === "all") {
        const searchStudents = await get(urls.students.getAllStudent, {
          params: {
            search: {
              "basicInfo.name": { $regex: search, $options: "i" },
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              status: formData.status,
            },
            limit: rowsPerPage,
            page: page === 0 ? 1 : page + 1,
          },
        });

        setSchoolStudents(searchStudents.data.data);
        setShowPagination(true);
        setTotalCount(searchStudents.data.count);
      } else {
        const searchStudent = await get(urls.students.getAllStudent, {
          params: {
            search: {
              "basicInfo.name": { $regex: search, $options: "i" },
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              "academicInfo.section": formData.section,
              status: formData.status,
            },
            limit: rowsPerPage,
            page: page === 0 ? 1 : page + 1,
          },
        });

        setSchoolStudents(searchStudent.data.data);
        setShowPagination(true);
        setTotalCount(searchStudent.data.count);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setSort({
      order: "asc",
      rollNo: "asc",
      admissionNo: "asc",
      name: "asc",
      status: "asc",
      section: "asc",
    });
    if (totalCount < rowsPerPage) setShowPagination(false);
  }, [schoolStudents.length]);

  useEffect(() => {
    setPage(0);
  }, [rowsPerPage]);

  useEffect(() => {
    const getAcademicYears = async () => {
      setPage(0);
      try {
        const res = await get(urls.administrator.academicYearGetAll);
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data.length ? res.data.data[0]._id : "",
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, [selectedSetting]);

  const handleOnChange = (e) => {
    setPage(0);
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleClassChange = async (e) => {
    setPage(0);

    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    try {
      const section = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      setFormData((prev) => ({
        ...prev,
        section: "all",
      }));
      console.log(section.data.data, "kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk________");
      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDelete = async (id) => {
    try {
      const res = await del(urls.students.delStudent, id);
      if (res.data.success) {
        const filteredStudent = schoolStudents.filter(
          (item) => item._id !== res.data.data._id
        );
        setSchoolStudents(filteredStudent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };
  function handlepaginationSearch() {
    if (!schoolStudents.length) {
      setShowPagination(false);
    }
  }

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        let search = res.data.data.length
          ? { class: res.data.data[0]._id }
          : {};
        const resSections = await get(urls.schoolSection.getAllSection, {
          params: {
            search,
          },
        });

        setSections(resSections.data.data);
        setClasses(res.data.data);
        setFormData((prev) => ({
          ...prev,
          schoolClass: res.data.data.length ? res.data.data[0]._id : "",
          section: "all",
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, [selectedSetting]);

  useEffect(() => {
    const getSections = async () => {
      try {
        const res = await get(urls.schoolSection.getAllSection);
        setSections(res.data.data);
        setFormData((prev) => ({
          ...prev,
          section: "all",
        }));
        setPage(0);
      } catch (error) {
        console.log(error);
      }
    };
    getSections();
  }, [selectedSetting]);

  useEffect(() => {
    setPage(0);
  }, [formData]);

  const handleEdit = async (id) => {
    try {
      setOpen(true);
      const getStudentById = schoolStudents.find((item) => item._id === id);
      setUpdateStudent(getStudentById);
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetAdmitSheet = async (e) => {
    e.preventDefault();

    try {
      const getSheet = await get(`${urls.students.getBulkAdmitSheet}`, {
        // const getSheet = await get(`/bulk-admit/student/${formData.schoolClass}/${formData.section}/${formData.academicYear}`, {

        responseType: "blob",
      });
      const uri = URL.createObjectURL(getSheet.data);

      const link = document.createElement("a");
      link.href = uri;
      console.log("from", formData);
      const academicsYear = formData.academicYear;
      const getExcelyearById = academicYears.find(
        (excel) => excel._id === academicsYear
      );
      const classename = formData.schoolClass;
      const getExcelById = classes.find((excel) => excel._id === classename);
      const sectionsname = formData.section;
      const getExcelsectionById = sections.find(
        (excel) => excel._id === sectionsname
      );
      // link.setAttribute("download", `studentadmit.xlsx`);
      link.setAttribute(
        "download",
        `${getExcelyearById.academicYearFrom}-${getExcelyearById.academicYearTo} ${getExcelById.className}th std ,${getExcelsectionById.sectionName}.xlsx`
      );

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setLoadingBtn(false);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleGetPrintPdf = async (e) => {
    setPrint(true);
    try {
      if (formData.section === "all") {
        const getPrint = await get(
          `${urls.students.getStudentsListPdf}/${formData.schoolClass}/${formData.academicYear}`,
          {
            responseType: "blob",
          }
        );
        const uri = URL.createObjectURL(getPrint.data);
        window.open(uri, "__blank");
      } else {
        const getPrint = await get(
          `${urls.students.getStudentsListBySectionPdf}/${formData.schoolClass}/${formData.section}/${formData.academicYear}`,
          {
            responseType: "blob",
          }
        );

        const uri = URL.createObjectURL(getPrint.data);
        window.open(uri, "__blank");
      }
    } catch (error) {
      console.log(error);
    }
    setPrint(false);
  };

  useEffect(() => {
    handleStudentSearch();
  }, [page, rowsPerPage, formData]);

  const handleStudentSearch = async () => {
    if (
      !formData.academicYear ||
      !formData.schoolClass ||
      !formData.status ||
      !formData.section
    )
      return;
    try {
      if (formData.section === "all") {
        const searchParams = search.trim().length
          ? {
              "basicInfo.name": search.trim().length
                ? { $regex: search, $options: "i" }
                : "",
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              status: formData.status,
            }
          : {
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              status: formData.status,
            };
        const searchStudents = await get(urls.students.getAllStudent, {
          params: {
            search: searchParams,
            limit: rowsPerPage,
            page: page + 1,
          },
        });

        setSchoolStudents(searchStudents.data.data);
        setShowPagination(true);
        setTotalCount(searchStudents.data.count);
      } else {
        let searchParams = search.trim().length
          ? {
              "basicInfo.name": search.trim().length
                ? { $regex: search, $options: "i" }
                : "",
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              "academicInfo.section": formData.section,
              status: formData.status,
            }
          : {
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              "academicInfo.section": formData.section,
              status: formData.status,
            };
        const searchStudent = await get(urls.students.getAllStudent, {
          params: {
            search: searchParams,
            limit: rowsPerPage,
            page: page === 0 ? 1 : page + 1,
          },
        });

        setSchoolStudents(searchStudent.data.data);
        setShowPagination(true);
        setTotalCount(searchStudent.data.count);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetDownloadSheet = async (e) => {
    e.preventDefault();

    setLoadingBtn(true);
    try {
      const getDownload = await get(
        `${urls.students.getStudentsListExcel}/${formData.schoolClass}/${formData.academicYear}`,
        {
          responseType: "blob",
        }
      );
      const uri = URL.createObjectURL(getDownload.data);
      const link = document.createElement("a");
      console.log(uri);
      link.href = uri;

      const academicsYear = formData.academicYear;
      const getExcelyearById = academicYears.find(
        (excel) => excel._id === academicsYear
      );
      const classename = formData.schoolClass;
      const getExcelById = classes.find((excel) => excel._id === classename);
      link.setAttribute(
        "download",
        `${getExcelyearById.academicYearFrom}-${getExcelyearById.academicYearTo} ${getExcelById.className}th std.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      setLoadingBtn(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleStudentSearchSubmit = async (e) => {
    if (
      !formData.academicYear ||
      !formData.schoolClass ||
      !formData.status ||
      !formData.section
    )
      return;
    setLoader(true);
    e.preventDefault();
    try {
      if (formData.section === "all") {
        const searchParams = search.trim().length
          ? {
              "basicInfo.name": search.trim().length
                ? { $regex: search, $options: "i" }
                : "",
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              status: formData.status,
            }
          : {
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              status: formData.status,
            };
        const searchStudents = await get(urls.students.getAllStudent, {
          params: {
            search: searchParams,
            limit: rowsPerPage,
            page: page === 0 ? 1 : page + 1,
          },
        });

        setSchoolStudents(searchStudents.data.data);
        setShowPagination(true);
        setTotalCount(searchStudents.data.count);
      } else {
        let searchParams = search.trim().length
          ? {
              "basicInfo.name": search.trim().length
                ? { $regex: search, $options: "i" }
                : "",
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              "academicInfo.section": formData.section,
              status: formData.status,
            }
          : {
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              "academicInfo.section": formData.section,
              status: formData.status,
            };
        const searchStudent = await get(urls.students.getAllStudent, {
          params: {
            search: searchParams,
            limit: rowsPerPage,
            page: page === 0 ? 1 : page + 1,
          },
        });

        setSchoolStudents(searchStudent.data.data);
        setShowPagination(true);
        setTotalCount(searchStudent.data.count);
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    console.log(newPage);
    setPage(newPage);
  };

  const handleSubmitBulkAdmit = async (e) => {
    setValue(true);
    e.preventDefault();
    try {
      const formDataFile = new FormData();
      formDataFile.append("sheet", e.target.bulkAdmit.files[0]);
      if (formData.section !== "all")
        await post(
          `${urls.students.postBulkAdmit}/${formData.schoolClass}/${formData.section}/${formData.academicYear}`,
          formDataFile
        );
    } catch (error) {
      console.log(error);
    }
    setValue(false);
  };

  const handleGetUpdateSheet = async (e) => {
    e.preventDefault();
    setLoadingBtn(true);

    try {
      if (formData.section !== "all") {
        const getSheet = await get(
          `${urls.students.getBulkUpdate}/${formData.schoolClass}/${formData.section}/${formData.academicYear}`,
          {
            responseType: "blob",
          }
        );
        const uri = URL.createObjectURL(getSheet.data);

        const link = document.createElement("a");
        link.href = uri;

        const academicsYear = formData.academicYear;
        const getExcelyearById = academicYears.find(
          (excel) => excel._id === academicsYear
        );
        const classename = formData.schoolClass;
        const getExcelById = classes.find((excel) => excel._id === classename);
        const sectionsname = formData.section;
        const getExcelsectionById = sections.find(
          (excel) => excel._id === sectionsname
        );

        link.setAttribute(
          "download",
          `${getExcelyearById.academicYearFrom}-${getExcelyearById.academicYearTo} ${getExcelById.className}th std ,${getExcelsectionById.sectionName}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoadingBtn(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmitBulkUpdate = async (e) => {
    setItem(true);
    e.preventDefault();
    setLoadingBtn(true);
    try {
      const formData = new FormData();
      formData.append("sheet", e.target.bulkUpdate.files[0]);
      await put(`${urls.students.putBulkUpdateStudent}`, "", formData);
    } catch (error) {
      console.log(error);
    }
    setItem(false);
  };
  const handleFileChange = (e) => {
    setFileChoosen(e.target.files[0].name);
  };

  const getSerialNumber = (index) => {
    return (
      schoolStudents[index]?.serialNumber || page * rowsPerPage + index + 1
    );
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      schoolStudents.length > 0 &&
        setSearchFilter(
          schoolStudents.filter((ele) =>
            ele.basicInfo.name
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  return (
    <div>
      <SectionSubHeader title="Students" style={{ margin: "15px" }} />
      <div style={{ margin: "15px" }}>
        <Card style={styles.card}>
          <Box sx={{ flexGrow: 1 }}>
            <form onSubmit={handleStudentSearchSubmit}>
              <Grid
                container
                spacing={1}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Grid item xs={12} md={6} lg={2}>
                  <FormControl size="small" style={styles.textfield} required>
                    <InputLabel id="demo-simple-select-filled-label">
                      Academic Year
                    </InputLabel>
                    <Select
                      label="Academic Year"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="academicYear"
                      value={formData.academicYear || ""}
                      onChange={handleOnChange}
                    >
                      {academicYears &&
                        academicYears.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.academicYearFrom} - {row.academicYearTo}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" style={styles.textfield} required>
                    <InputLabel id="demo-simple-select-filled-label">
                      Class
                    </InputLabel>
                    <Select
                      label="Class"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="schoolClass"
                      value={formData.schoolClass || ""}
                      onChange={handleClassChange}
                    >
                      {classes &&
                        classes.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.className}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" style={styles.textfield} required>
                    <InputLabel id="demo-simple-select-filled-label">
                      Section
                    </InputLabel>
                    <Select
                      label="Section"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="section"
                      value={formData.section || ""}
                      onChange={handleOnChange}
                    >
                      {formData.schoolClass ? (
                        <MenuItem value="all">----all----</MenuItem>
                      ) : null}
                      {sections.length
                        ? sections
                            .slice() // Creating the copy of the array to avoid mutating the original array
                            .sort((a, b) =>
                              a.sectionName.localeCompare(b.sectionName)
                            )
                            .map((row, index) => (
                              <MenuItem
                                key={row._id}
                                value={row._id}
                                sx={{ fontSize: 12, fontWeight: 500 }}
                              >
                                {row.sectionName}
                              </MenuItem>
                            ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" style={styles.textfield} required>
                    <InputLabel id="demo-simple-select-filled-label">
                      Status
                    </InputLabel>
                    <Select
                      label="Status"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="status"
                      value={formData.status || ""}
                      onChange={handleOnChange}
                    >
                      <MenuItem
                        value={"active"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Active
                      </MenuItem>
                      <MenuItem
                        value={"inactive"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Inactive
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={1}
                  style={{ alignSelf: "center" }}
                >
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    style={styles.Button}
                    onClick={handlepaginationSearch}
                  >
                    SEARCH
                  </Button>
                </Grid>
              </Grid>
            </form>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "15px",
              }}
            >
              <Box>
                {formData.schoolClass &&
                  formData.academicYear &&
                  formData.section !== "all" && (
                    <Stack gap={1} direction={{ sm: "column", md: "row" }}>
                      <Tooltip title="Bulk Admit">
                        <Button
                          size="small"
                          variant="contained"
                          style={styles.Button}
                          onClick={() => setBulkAdmitModal(true)}
                        >
                          BULK ADMIT
                        </Button>
                      </Tooltip>
                      <Tooltip title="Bulk Update">
                        <LoadingButton
                          loading={loader}
                          size="small"
                          variant="contained"
                          style={styles.Button}
                          onClick={() => setBulkUpdateModal(true)}
                        >
                          BULK UPDATE
                        </LoadingButton>
                      </Tooltip>
                    </Stack>
                  )}
              </Box>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Stack direction="row">
                  <LoadingButton
                    onClick={handleGetDownloadSheet}
                    style={styles.icon}
                    loading={loadingBtn}
                  >
                    <DownloadForOfflineSharpIcon />
                  </LoadingButton>

                  <LoadingButton
                    loading={print}
                    style={styles.icon}
                    onClick={handleGetPrintPdf}
                  >
                    <PrintSharp />
                  </LoadingButton>
                </Stack>

                <Link to={"/student-bulk-photo"}>
                  <Button size="small" sx={{ p: 1, ml: 1 }} variant="contained">
                    Bulk Photo
                  </Button>
                </Link>

                <TextField
                  sx={{ ml: 3 }}
                  size="small"
                  value={search}
                  onChange={handleSearch}
                  label="Search"
                  placeholder="Search By Student Name"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" type="submit">
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {/* 
                <div
                  style={{
                    alignSelf: "flex-end",
                    marginLeft: "15px",
                    flex: "1",
                    
                  }}>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search here..."
                    inputProps={{ "aria-label": "search google maps" }}
                    onChange={handleInputChange}
                  />
                  <IconButton
                    onClick={handleIconChange}
                    type="button"
                    sx={{ p: "5px" }}
                    aria-label="search">
                    <SearchIcon />
                  </IconButton>
                </div> */}
              </Box>
            </Box>
          </Box>
        </Card>
        {/* <Card style={styles.card}>
          <div
            style={{
              display: "flex",
              justifyContent:"flex-start",
              alignItems:"center",
              
            }}>
            <LoadingButton
              onClick={handleGetDownloadSheet}
              loading={loadingBtn}>
              <span style={styles.icon}>
                <DownloadForOfflineSharpIcon  />
              </span>
            </LoadingButton>

            <LoadingButton loading={print} onClick={handleGetPrintPdf}>
              <span style={styles.icon}>
                <PrintSharp />
              </span>
            </LoadingButton>
            <div>
              <Link to={"/student-bulk-photo"}>
                <Button size="small" sx={{ml:2}} variant="contained">
                  Bulk Photo
                </Button>
              </Link>
            </div>
            <div style={{ alignSelf: "flex-end",marginLeft:"15px" }}>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search here..."
                inputProps={{ "aria-label": "search google maps" }}
                onChange={handleInputChange}
              />
              <IconButton
                onClick={handleIconChange}
                type="button"
                sx={{ p: "10px" }}
                aria-label="search">
                <SearchIcon />
              </IconButton>
            </div>
          </div>
        </Card> */}
        <Box sx={{ width: "100%" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead
                style={{
                  background: "rgb(27 55 121)",
                  position: "relative",
                  botton: "30px",
                }}
              >
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">Section Order</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Photo</span>
                  </TableCell>
                  <TableCell align="center">
                    <span onClick={sortByName} className="class-table-header">
                      Name
                      <span style={{ cursor: "pointer" }}>
                        {sort.name === "asc" ? "▲" : "▼"}
                      </span>
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span onClick={sortByStatus} className="class-table-header">
                      Status
                      <span style={{ cursor: "pointer" }}>
                        {sort.status === "asc" ? "▲" : "▼"}
                      </span>
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span
                      onClick={sortBySection}
                      className="class-table-header"
                    >
                      Section
                      <span style={{ cursor: "pointer" }}>
                        {sort.section === "asc" ? "▲" : "▼"}
                      </span>
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span onClick={sortByRoll} className="class-table-header">
                      Roll No.
                      <span style={{ cursor: "pointer" }}>
                        {sort.rollNo === "asc" ? "▲" : "▼"}
                      </span>
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span
                      onClick={sortByAdmissionNo}
                      className="class-table-header"
                    >
                      Admission No.
                      <span style={{ cursor: "pointer" }}>
                        {sort.admissionNo === "asc" ? "▲" : "▼"}
                      </span>
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Action</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {search
                  ? searchFilter.map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">
                          {getSerialNumber(index)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <Avatar src={objHas(row, "studentPhoto.link", "")} />
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                          key={row.id}
                        >
                          {row.basicInfo.name}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.status}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.academicInfo.section
                            ? row.academicInfo.section.sectionName
                            : "NA"}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.academicInfo.rollNo}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.academicInfo.admissionNo}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div className="edit-delete">
                            <Tooltip title="Edit" disableInteractive>
                              <Link to={`/edit-student/${row._id}`}>
                                <Button onClick={() => handleEdit(row._id)}>
                                  <Edit />
                                </Button>
                              </Link>
                            </Tooltip>
                            <Tooltip title="Delete" disableInteractive>
                              <Button onClick={() => setDeleteModal(row._id)}>
                                <Delete />
                              </Button>
                            </Tooltip>
                          </div>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={row._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  : schoolStudents.map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">
                          {getSerialNumber(index)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <Avatar src={objHas(row, "studentPhoto.link", "")} />
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                          key={row.id}
                        >
                          {row.basicInfo.name}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.status}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.academicInfo.section
                            ? row.academicInfo.section.sectionName
                            : "NA"}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.academicInfo.rollNo}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.academicInfo.admissionNo}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div className="edit-delete">
                            <Tooltip title="Edit" disableInteractive>
                              <Link to={`/edit-student/${row._id}`}>
                                <Button onClick={() => handleEdit(row._id)}>
                                  <Edit />
                                </Button>
                              </Link>
                            </Tooltip>
                            <Tooltip title="Delete" disableInteractive>
                              <Button onClick={() => setDeleteModal(row._id)}>
                                <Delete />
                              </Button>
                            </Tooltip>
                          </div>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={row._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>

          {!schoolStudents.length && !search.trim() && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {search.trim() && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}

          {/* {!schoolStudents.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}>
              No data found
            </Typography>
          )} */}
        </Box>
        {showPagination ? (
          <TablePagination
            ref={pageRef}
            style={{ display: "flex", alignItems: "start" }}
            //rowsPerPageOptions={rowsData || [10, 20, 30, 40]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}

            // onPageChange={handleChangePage}
            // rowsPerPage={rowsPerPage}
            // onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}

        <Modal
          open={bulkAdmitModal}
          onClose={() => setBulkAdmitModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modal}>
            <div style={{ textAlign: "center", marginBottom: "30px" }}>
              <span style={{ color: "red", fontFamily: "cursive" }}>
                Bulk Admit
              </span>
            </div>
            <form onSubmit={handleSubmitBulkAdmit}>
              <Grid container spacing={1}>
                <Grid item xs={4} md={4} lg={4}>
                  <Tooltip title="Get Sample">
                    <LoadingButton
                      variant="contained"
                      loading={loadingBtn}
                      // style={styles.Button}
                      onClick={handleGetAdmitSheet}
                      sx={{
                        backgroundColor: "#1b3779",
                        ":hover": { backgroundColor: "#1b3779" },
                      }}
                    >
                      Sample
                    </LoadingButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                  <Tooltip title="Upload">
                    <Button
                      variant="contained"
                      style={styles.Button}
                      component="label"
                    >
                      upload
                      <input
                        name="bulkAdmit"
                        onChange={handleFileChange}
                        hidden
                        accept=".xlsx, .xls, xlsm, .xlsb, .csv, .ods"
                        type="file"
                      />
                    </Button>
                  </Tooltip>

                  <div style={{ fontSize: "13px" }}>
                    {fileChoosen && fileChoosen}
                  </div>
                </Grid>

                <Grid item xs={4} md={4} lg={4}>
                  <Tooltip title="Submit">
                    <LoadingButton
                      loading={value}
                      variant="contained"
                      style={styles.Button}
                      type="submit"
                    >
                      submit
                    </LoadingButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Modal>
        <Modal
          open={bulkUpdateModal}
          onClose={() => setBulkUpdateModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modal}>
            <div style={{ textAlign: "center", marginBottom: "30px" }}>
              <span style={{ color: "red", fontFamily: "cursive" }}>
                Bulk Update
              </span>
            </div>
            <form onSubmit={handleSubmitBulkUpdate}>
              <Grid container spacing={1}>
                <Grid item xs={4} md={4} lg={4}>
                  <Tooltip title="Get Student Data">
                    <LoadingButton
                      variant="contained"
                      size="small"
                      type="submit"
                      style={{ padding: "6px" }}
                      loading={loadingBtn}
                      onClick={handleGetUpdateSheet}
                      sx={{
                        backgroundColor: "#1b3779",
                        ":hover": { backgroundColor: "#1b3779" },
                      }}
                    >
                      Download
                    </LoadingButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                  <Tooltip title="Upload">
                    <Button
                      variant="contained"
                      style={styles.Button}
                      component="label"
                    >
                      upload
                      <input
                        name="bulkUpdate"
                        onChange={handleFileChange}
                        hidden
                        accept=".xlsx, .xls, xlsm, .xlsb, .csv, .ods"
                        type="file"
                      />
                    </Button>
                  </Tooltip>

                  <div style={{ fontSize: "13px" }}>
                    {fileChoosen && fileChoosen}
                  </div>
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                  <Tooltip title="Submit">
                    <LoadingButton
                      loading={item}
                      variant="contained"
                      style={styles.Button}
                      type="submit"
                    >
                      submit
                    </LoadingButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Modal>
        <div className="add-btn">
          <Tooltip title="Add" disableInteractive>
            <Link to="/addstudent">
              <Fab
                size="medium"
                style={{ background: "#1b3779", color: "#fff" }}
                aria-label="add"
              >
                <Add />
              </Fab>
            </Link>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default StudentTable;
