import React, { useContext, useEffect, useRef, useState } from "react";
import galleryData from "./galleryData";
import Gallery from "./Gallery";
import {
  Container,
  Box,
  Typography,
  styled,
  Card,
  CardActionArea,
  CardContent,
} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import MovingText from "react-moving-text";
//import { settings } from "./Carousel2";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import { get } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import Slider from "react-slick";
import { calculateSlidersData, settings } from "../../Data/carousal";
import ImageCarousal from "../ImageCarousel";
// import AppSlider from "../Course/AppSlider";

const AppSlider = styled(Slider)`
  width: 100%;
  .slick-track {
    display: flex;
    //flex-shrink: 1;
  }
  .slick-slide {
    display: flex;
    justify-content: center;
    //margin-bottom: 1;
    outline: none;
  }
  .slick-list {
    overflow: hidden;
  }
`;

const TextBox1 = styled(Box)(({ theme }) => ({
  // marginTop: "5%",
  // marginLeft:"5%",
  textShadow: "10px 8px 8px #969c96",
  [theme.breakpoints.down("md")]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const GalleryMain = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const [galleryData, setGalleryData] = useState([]);

  const getGalleryData = async () => {
    try {
      const { data } = await get(urls.gallery.getAllGalleries);
      setGalleryData(data.data);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getGalleryData();
  }, []);

  let sliderRef = useRef(null);

  let handlePrevious = () => {
    sliderRef.current.slickPrev();
  };
  let handleNext = () => {
    sliderRef.current.slickNext();
  };

  return (
    <Box sx={{ margin: "50px 10px 100px 10px" }}>
      <TextBox1>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              variant="h3"
              color="black"
              sx={{ fontWeight: "bold", fontSize: "40px" }}
            >
              OUR
            </Typography>
          </Box>
          &nbsp;&nbsp;
          <Box>
            <Typography
              variant="h3"
              color="#f86f03"
              sx={{ fontWeight: "bold", fontSize: "40px" }}
            >
              GALLERY
            </Typography>
          </Box>
        </Box>
      </TextBox1>

      <Box
        sx={{ display: "flex", justifyContent: "center", marginBottom: "50px" }}
      >
        <Typography component="p">________</Typography>
        <FiberManualRecordIcon sx={{ fontSize: "8px", marginTop: "15px" }} />
        <FiberManualRecordIcon
          sx={{
            color: "#F86F03",
            fontSize: "10px",
            marginTop: "14px",
            marginLeft: "5px",
          }}
        />
        <FiberManualRecordIcon
          sx={{ fontSize: "8px", marginTop: "15px", marginLeft: "6px" }}
        />
        <Typography component="p">________</Typography>
      </Box>

      {/* <Box my={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box
          component="div"
          onClick={handlePrevious}
          sx={{
            border: "1px solid black",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          {" "}
          <NavigateBeforeIcon />
        </Box>
        <Box
          component="div"
          onClick={handleNext}
          ml={2}
          mr={3}
          sx={{
            border: "1px solid black",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          {" "}
          <NavigateNextIcon />
        </Box>
      </Box> */}
      <Container>
        <AppSlider
          ref={sliderRef}
          {...calculateSlidersData(galleryData.length)}
        >
          {galleryData.map((d, i) => (
            <Card
              key={d._id}
              sx={{
                maxWidth: "350px",
                display: "flex",
                flex: 1,
                flexDirection: "column",
              }}
            >
              {/* <CardActionArea> */}
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  width: "100%",
                  maxWidth: "350px",
                  minWidth: "250px",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ImageCarousal
                  style={{ width: "100%" }}
                  images={d.images.map((i) => i.link)}
                />
              </Box>
              <CardContent
                sx={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  height: "100px",
                }}
              >
                <Typography gutterBottom variant="h5" component="div">
                  {d.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {d.note || "Added new images"}
                </Typography>
              </CardContent>
              {/* </CardActionArea> */}
            </Card>
            // <Gallery key={i} data={d} />
          ))}
        </AppSlider>
      </Container>
    </Box>
  );
};

export default GalleryMain;
