import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { get } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { toast } from "react-toastify";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../SectionSubHeader";
const styles = {
  icon: {
    padding: "10px",
    fontSize: "12px",
    fontWeight: 500,
  },
  card: {
    padding: 15,
    marginBottom: 20,
    width: "100%",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
  },
};

const BalanceFeeReport = () => {
  const { selectedSetting } = useContext(SettingContext);
  const [academicYears, setAcademicYears] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [formData, setFormData] = useState([]);
  const [receipts, setReceipts] = useState([]);
  const [schoolStudents, setSchoolStudents] = useState([]);
  const [showPagination, setShowPagination] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [page, setPage] = useState(0);

  const [totalValue, setTotalValue] = useState(0);

  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const calculateTotalValue = (data) => {
    let total = 0;
    data.forEach((item) => {
      total += item.value; // Adjust the property name according to your data structure
    });
    return total;
  };

  useEffect(() => {
    const newTotalValue = calculateTotalValue(schoolStudents);
    setTotalValue(newTotalValue);
  }, [schoolStudents]);

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll);
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data.length ? res.data.data[0]._id : "",
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, []);

  useEffect(() => {
    const getAllReceipts = async () => {
      try {
        const res = await get(urls.recepitBook.getAll);
        setFormData((prev) => ({
          ...prev,
          name: res.data.data.length ? res.data.data[0]._id : "",
        }));
        setReceipts(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAllReceipts();
  }, [selectedSetting._id]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleClassChange = async (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);
    setSchoolStudents([]);
    setFormData({
      ...formData,
      [name]: value,
    });
    try {
      const section = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      setFormData((prev) => ({
        ...prev,
        section: "all",
      }));
      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        if (!res.data.data.length) {
          setClasses([]);
          setSections([]);
          setSchoolStudents([]);
          setFormData((prev) => ({
            ...prev,
            className: "",
            section: "",
          }));

          return;
        }
        const resSections = await get(urls.schoolSection.getAllSection, {
          params: {
            search: {
              class: res.data.data[0]._id,
            },
          },
        });
        if (!resSections.data.data.length) {
          setSections([]);
          setSchoolStudents([]);
          setFormData((prev) => ({
            ...prev,
            className: res.data.data[0]._id,
            section: "all",
          }));
          return;
        }
        let searchParms;
        if (formData.section == "all") {
          searchParms = {
            academicYear: formData.academicYear,
            "academicInfo.class": res.data.data[0]._id,
          };
        } else {
          searchParms = {
            academicYear: formData.academicYear,
            "academicInfo.class": res.data.data[0]._id,
            "academicInfo.section": resSections.data.data[0]._id,
          };
        }
        const searchStudents = await get(urls.students.getAllStudent, {
          params: {
            search: searchParms,
          },
        });
        // setSchoolStudents(searchStudents.data.data);
        setSections(resSections.data.data);
        setClasses(res.data.data);
        setFormData((prev) => ({
          ...prev,
          className: res.data.data[0]._id,
          section: "all",
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, [selectedSetting._id]);

  useEffect(() => {
    setSchoolStudents([]);
  }, [formData]);

  const handleFeeSearchSubmit = async (e) => {
    e.preventDefault();

    try {
      setShowPagination(true);

      const searchParams = {
        params: {
          academicYearId: formData.academicYear,
          receiptType: formData.receipt,
          classId: formData.schoolClass,
          sectionId: formData.section,
        },
      };

      if (formData.section === "all") {
        const searchStudents = await get(urls.balanceFeereceipt.getAllSection, {
          params: {
            academicYearId: formData.academicYear,
            receiptType: formData.receipt,
            classId: formData.schoolClass,
          },
        });

        setPage(0);
        setSchoolStudents(searchStudents.data.data);
        // setTotalCount(searchStudents.data.count);
      } else {
        const searchStudent = await get(
          urls.balanceFeereceipt.getAll,
          searchParams
        );
        console.log(searchStudent, "search////////////////////////////");
        setPage(0);
        setSchoolStudents(searchStudent.data.data);
        // setTotalCount(searchStudent.data.count);
      }

      // setFormData({});
    } catch (error) {
      console.log(error);
    }
  };

  const handleMarksExcelDownload = async () => {
    try {
      if (
        formData.academicYear &&
        formData.schoolClass &&
        formData.section !== "all" &&
        formData.receipt
      ) {
        const getMarksListSheet = await get(
          `/${selectedSetting._id}/fee-receipt/download/report/balance-fee`,
          {
            params: {
              academicYearId: formData.academicYear,
              receiptType: formData.receipt,
              classId: formData.schoolClass,
              sectionId: formData.section,
            },
            responseType: "blob",
          }
        );

        if (getMarksListSheet.status === 200) {
          const uri = URL.createObjectURL(getMarksListSheet.data);
          const link = document.createElement("a");
          link.href = uri;
          const academicYearGet = formData.academicYear;
          const getExcelByYearId = academicYears.find(
            (excel) => excel._id === academicYearGet
          );
          const classename = formData.schoolClass;
          const getExcelByClassId = classes.find(
            (excel) => excel._id === classename
          );
          const sectionsname = formData.section;
          console.log(sectionsname, "::::::::");
          const getExcelsectionById = sections.find(
            (excel) => excel._id === sectionsname
          );
          console.log(getExcelsectionById, "excel section");
          const receiptss = formData.receipt;
          const getExcelreceiptById = receipts.find(
            (excel) => excel._id === receiptss
          );

          link.setAttribute(
            "download",
            `${getExcelByYearId.academicYearFrom}-${
              getExcelByYearId.academicYearTo
            } ${getExcelByClassId.className} std ${
              getExcelsectionById.sectionName
            }, ${
              getExcelreceiptById
                ? getExcelreceiptById.name
                : "All Fee Receipts"
            }.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          console.log(getExcelByYearId, "getyear");
          console.log(getExcelsectionById, "getSec");

          link.parentNode.removeChild(link);
        }
      } else if (
        formData.academicYear &&
        formData.schoolClass &&
        formData.section == "all" &&
        formData.receipt
      ) {
        const getMarksListSheet = await get(
          `/${selectedSetting._id}/fee-receipt/download/report/all-sections/balance-fee`,
          {
            params: {
              academicYearId: formData.academicYear,
              receiptType: formData.receipt,
              classId: formData.schoolClass,
            },
            responseType: "blob",
          }
        );

        if (getMarksListSheet.status === 200) {
          const uri = URL.createObjectURL(getMarksListSheet.data);
          const link = document.createElement("a");
          link.href = uri;
          const academicYearGet = formData.academicYear;
          const getExcelByYearId = academicYears.find(
            (excel) => excel._id === academicYearGet
          );
          const classename = formData.schoolClass;
          const getExcelByClassId = classes.find(
            (excel) => excel._id === classename
          );
          const sectionsname = formData.section;
          const getExcelsectionById = sections.find(
            (excel) => excel._id === sectionsname
          );
          const receiptss = formData.receipt;
          const getExcelreceiptById = receipts.find(
            (excel) => excel._id === receiptss
          );

          link.setAttribute(
            "download",
            `${getExcelByYearId.academicYearFrom}-${
              getExcelByYearId.academicYearTo
            } ${getExcelByClassId.className} std ,${
              getExcelsectionById
                ? getExcelsectionById.sectionName
                : "All Sections"
            } ${
              getExcelreceiptById
                ? getExcelreceiptById.name
                : "All Fee Receipts"
            }.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      }
    } catch (error) {
      console.log(error, "error");
      toast.error(error.message);
    }
  };
  return (
    <Box p={"1rem"}>
      <div>
        <SectionSubHeader title="Balance fee report" />
        <Card style={{ margin: "15px 0", padding: "15px 10px" }}>
          <Box sx={{ flexGrow: 1 }}>
            <form onSubmit={handleFeeSearchSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl
                    size="small"
                    // style={{ width: "200px", margin: "0px 0px 20px 0px" }}
                    required
                    fullWidth
                  >
                    <InputLabel id="demo-simple-select-filled-label">
                      Academic Year
                    </InputLabel>
                    <Select
                      label="Academic Year"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="academicYear"
                      value={formData.academicYear || ""}
                      onChange={handleOnChange}
                    >
                      {academicYears &&
                        academicYears.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.academicYearFrom} - {row.academicYearTo}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" fullWidth required>
                    <InputLabel id="demo-simple-select-filled-label">
                      Class
                    </InputLabel>
                    <Select
                      label="Class"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="schoolClass"
                      value={formData.schoolClass || ""}
                      onChange={handleClassChange}
                    >
                      {classes &&
                        classes.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.className}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" fullWidth required>
                    <InputLabel id="demo-simple-select-filled-label">
                      Section
                    </InputLabel>
                    <Select
                      label="Section"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="section"
                      value={formData.section || ""}
                      onChange={handleOnChange}
                    >
                      {formData.schoolClass && (
                        <MenuItem value="all">--all--</MenuItem>
                      )}
                      {sections &&
                        sections.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.sectionName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" fullWidth required>
                    <InputLabel id="demo-simple-select-filled-label">
                      Receipt
                    </InputLabel>
                    <Select
                      label="Receipt"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="receipt"
                      value={formData.receipt || ""}
                      onChange={handleOnChange}
                    >
                      {receipts &&
                        [{ _id: "123456789123", name: "All" }, ...receipts].map(
                          (row, index) => (
                            <MenuItem
                              key={row._id}
                              value={row._id}
                              sx={{ fontSize: 12, fontWeight: 500 }}
                            >
                              {row.name}
                            </MenuItem>
                          )
                        )}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  display="flex"
                  justifyContent={{
                    xs: "center",
                    sm: "center",
                    md: "flex-end",
                    lg: "flex-end",
                  }}
                >
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    style={styles.Button}
                  >
                    find
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Card>
      </div>
      <div style={{ margin: "20px 0" }}>
        <div>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead style={{ background: "rgb(27 55 121)" }}>
                  <TableRow>
                    <TableCell align="center">
                      <span className="class-table-header">#SL</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Receipt</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Roll No</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Class</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Section</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Name</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Father</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Phone</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Amount</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Concession</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Fine</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Paid</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Balance</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {schoolStudents.map((row, index) => (
                    <TableRow
                      key={row.latestReceipt._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {row.latestReceipt?.receiptType?.name}
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.latestReceipt.studentDetails.rollNumber}
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.latestReceipt.studentDetails.classNumber}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.latestReceipt.studentDetails.section}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.latestReceipt.studentDetails.name}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.latestReceipt.studentDetails.parentName}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.latestReceipt.studentDetails.contactNumber}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.latestReceipt.payableAmount}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.latestReceipt.totalConcession}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.latestReceipt.totalPenalty}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.latestReceipt.payableAmount -
                          row.latestReceipt.netDue}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.latestReceipt.netDue}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {!schoolStudents.length && (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
              >
                No data found
              </Typography>
            )}
          </Paper>
        </div>

        {schoolStudents.length > 0 &&
          formData.academicYear &&
          formData.receipt &&
          formData.schoolClass &&
          formData.section && (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={handleMarksExcelDownload}
                  size="small"
                  variant="contained"
                  color="primary"
                  sx={{ mt: 3 }}
                >
                  DOWNLOAD
                </Button>
              </div>
            </div>
          )}
      </div>
    </Box>
  );
};
export default BalanceFeeReport;
