import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Card,
  Grid,
  Paper,
  Modal,
  TextField,
  Tab,
  Tabs,
  Box,
  Typography,
  Tooltip,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Stack,
  styled,
  IconButton,
  InputAdornment,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Delete, Search } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { del, get, post, put } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import SectionsSubHeader from "../../components/SectionSubHeader";
import Wrapper from "../Wrapper";
import { useTheme } from "@mui/material/styles";
const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
};

const ContainerBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "750px",
  maxHeight: "750px",
  borderRadius: "5px",
  height: "auto",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
    width: "90%",
  },
  [theme.breakpoints.between(819, 821)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(767, 769)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(911, 913)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1023, 1025)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1279, 1281)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </Box>
  );
}

const StudentIssue = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [tabValue, setTabValue] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = useState();
  const [date, setDate] = useState(dayjs(Date.now()));
  const [fromDate, setFromDate] = useState(dayjs(Date.now()));
  const [toDate, setToDate] = useState(dayjs(Date.now()));
  const [edate, setEDate] = useState(dayjs(Date.now()));

  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [dueSearchFilter, setDueSearchFilter] = useState([]);
  const [dueSearch, setDueSearch] = useState("");
  const [hisSearchFilter, setHisSearchFilter] = useState([]);
  const [hisSearch, setHisSearch] = useState("");

  const [issue, setIssue] = useState([]);
  const [dueIssue, setDueIssue] = useState([]);
  const [showPagination, setShowPagination] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [issueTotalCount, setIssueTotalCount] = useState();
  const [dueTotalCount, setDueTotalCount] = useState();
  const [book, setBook] = useState([]);
  const [students, setStudents] = useState([]);
  const [history, setHistory] = useState([]);
  const [addForm, setAddForm] = useState({});
  const [editIssue, setEditBook] = useState();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loader, setLoader] = useState(false);
  const [findLoader, setFindLoader] = useState(false);
  const [printLoadingBtn, setPrintLoadingBtn] = useState(false);
  const [popOpen, sePopOpen] = useState(false);
  const [addBtnLoader, setAddBtnLoader] = useState(false);

  const styles = {
    icon: {
      padding: "10px",
      fontSize: "12px",
      fontWeight: 500,
      backgroundColor: "#1b3779",
      color: "white",
    },
    button: {
      backgroundColor: "orange",
    },
    textfield: {
      marginTop: "25px",
      // width: "70%",
    },
    addbutton: {
      paddingTop: "6.2px",
      paddingBottom: "6.2px",
    },
    tab: {
      width: "100%",
      borderCollapse: "collapse",
    },
    card: {
      padding: 10,
      margin: "0px 0px 20px 0px",
    },
    Button: {
      background: "#1b3779",
      ":hover": { background: "#1b3779" },
      // marginTop: "10px",
    },
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      issue.length > 0 &&
        setSearchFilter(
          issue.filter((ele) =>
            ele.book.booktitle
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };
  const handleDueSearch = (e) => {
    const { value } = e.target;
    setDueSearch(value.trim());
    if (value.trim() !== "") {
      dueIssue.length > 0 &&
        setDueSearchFilter(
          dueIssue.filter((ele) =>
            ele.book.booktitle
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setDueSearchFilter([]);
    }
  };
  const handleHisSearch = (e) => {
    const { value } = e.target;
    setHisSearch(value.trim());
    if (value.trim() !== "") {
      history.length > 0 &&
        setHisSearchFilter(
          history.filter((ele) =>
            ele.book.booktitle
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setHisSearchFilter([]);
    }
  };
  useEffect(() => {
    getIssueList();
    getDueIssueList();
    getBookList();
    getLibraryStudent();
  }, [selectedSetting]);

  const getIssueList = async () => {
    try {
      setShowPagination(true);
      const response = await get(urls.issueList.get, {
        params: {
          limit: 10,
          page: 1,
        },
      });
      const filterIssue = response.data.data.filter(
        (issues) => issues.submitted === "no"
      );
      setIssue(filterIssue);
      console.log(filterIssue, "yyyyyyy");
      setIssueTotalCount(filterIssue.length);
    } catch (error) {
      console.log(error);
    }
  };
  const getDueIssueList = async () => {
    try {
      setShowPagination(true);
      const response = await get(urls.issueList.getdue, {
        params: {
          limit: 10,
          page: 1,
        },
      });
      const filteredDue = response.data.data.filter(
        (dues) => dues.submitted === "no"
      );

      setDueIssue(filteredDue);
      console.log(filteredDue, "dueeeee");
      setDueTotalCount(filteredDue.length);
      console.log("sdfghjk", filteredDue.length);
    } catch (error) {
      console.log(error);
    }
  };
  const getBookList = async () => {
    try {
      setShowPagination(true);
      const response = await get(urls.book.getList, {
        params: {
          limit: 10,
          page: 1,
        },
      });
      setBook(response.data.data);
      console.log("setBook", response.data.data);
      setTotalCount(response.data.count);
    } catch (error) {
      console.log(error);
    }
  };

  const getLibraryStudent = async () => {
    try {
      const searchStudents = await get(urls.students.getAllStudent, {
        params: {
          search: {
            "otherInfo.libraryMember": "yes",
          },
        },
      });
      setStudents(searchStudents.data.data);

      setTotalCount(searchStudents.length);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickPopOpen = () => {
    sePopOpen(true);
  };

  const handlePopClose = () => {
    sePopOpen(false);
  };

  const handleAddSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      // if (!editIssue) {
      const { status } = await post(`${urls.issueList.create}`, {
        issueDate: date,
        book: addForm.book,
        isbnNo: addForm.isbnNo,
        edison: addForm.edison,
        author: addForm.author,
        language: addForm.language,
        price: addForm.price,
        quantity: addForm.quantity,
        almiraNo: addForm.almiraNo,
        returnDate: edate,
        issuedTo: addForm.issuedTo,
      });

      getIssueList();
      getDueIssueList();
      handleClose();
      // } else {
      //   const { status } = await put(`${urls.issueList.edit}${editIssue}`, "", {
      //     issueDate: date,
      //     book: addForm.book,
      //     isbnNo: addForm.isbnNo,
      //     edison: addForm.edison,
      //     author: addForm.author,
      //     language: addForm.language,
      //     price: addForm.price,
      //     quantity: addForm.quantity,
      //     almiraNo: addForm.almiraNo,
      //     returnDate: edate,
      //     issuedTo: addForm.issuedTo,
      //   });

      //   getIssueList();
      //   getDueIssueList();
      //   handleClose();
      // }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const handleSubmitList = async (e, id) => {
    console.log("jhjhjhjhj");
    setAddBtnLoader(true);
    e.preventDefault();
    try {
      const { status } = await put(`${urls.issueList.submitBook}/`, id, {});

      getDueIssueList();
      getIssueList();
    } catch (error) {
      console.log(error);
    }
    setAddBtnLoader(false);
  };

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setAddForm({});
  };
  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const handleSearchHistory = async (e) => {
    setFindLoader(true);
    e.preventDefault();
    try {
      const searchOptions = {
        fromDate: fromDate,
        toDate: toDate,
      };

      const searchHistory = await get(urls.issueList.getHistory, {
        params: {
          fromDate: searchOptions.fromDate,
          toDate: searchOptions.toDate,
        },
      });

      setHistory(searchHistory.data.data);
      console.log(searchHistory.data.data, "searchHistory");
    } catch (error) {
      console.log(error);
    }
    setFindLoader(false);
  };

  const handleGetDownloadSheet = async (e) => {
    setLoadingBtn(true);
    e.preventDefault();
    try {
      const response = await get(
        `/${selectedSetting._id}/issuereturn/downloadexcel/${fromDate}/${toDate}`,
        {
          responseType: "blob",
        }
      );

      const downloadUrl = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute(
        "download",
        `library-history${fromDate}-${toDate}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
    setLoadingBtn(false);
  };

  const handleGetPrintPdf = async (e) => {
    e.preventDefault();
    setPrintLoadingBtn(true);
    try {
      const getPrint = await get(
        `/${selectedSetting._id}/issuereturn/downloadpdf/${fromDate}/${toDate}`,
        {
          responseType: "blob",
        }
      );
      const uri = URL.createObjectURL(getPrint.data);
      window.open(uri, "__blank");
    } catch (error) {
      console.log(error);
    }
    setPrintLoadingBtn(false);
  };
  return (
    <Wrapper>
      <SectionsSubHeader title="Student Issue & Return" />
      <Card style={{ padding: 10, marginTop: 20 }} overflow="scroll">
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                md: "row",
              },
              justifyContent: {
                md: "space-between",
                xs: "center",
                sm: "center",
              },
              alignItems: {
                md: "center",
                xs: "flex-start",
                sm: "center",
              },
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab label="Issue List" {...a11yProps(0)} />
              <Tab label="Due List" {...a11yProps(1)} />
              <Tab label="History List" {...a11yProps(2)} />
            </Tabs>
            <Button
              variant="contained"
              sx={{
                marginTop: {
                  xs: "10px",
                  sm: "10px",
                  md: "0",
                },
              }}
              onClick={handleClickOpen}
            >
              <AddIcon />
              Issue Book
            </Button>
          </Box>
        </Box>
      </Card>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleAddSubmit}>
          <Box sx={style}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-end",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  textAlign="center"
                  fontSize="20px"
                  fontWeight="bold"
                  // onChange={handleAddForm}
                >
                  Add Issue
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    variant="standar"
                    label="Issue Date"
                    // openTo="day"
                    // views={["day", "month", "year"]}
                    inputFormat="DD-MM-YYYY"
                    value={date}
                    onChange={(newValue) => {
                      setDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
                        size="small"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <FormControl
                  fullWidth
                  size="small"
                  // sx={{ m: 0.3, minWidth: { md: 227, xs: "100%" } }}
                >
                  <InputLabel sx={{ fontSize: 8 }}>Select Book</InputLabel>
                  <Select
                    label="Select Book"
                    labelId="demo-simpless-select-filled-label"
                    id="demo-simple-select-filled-l"
                    name="book"
                    value={addForm.book || ""}
                    onChange={handleAddForm}
                  >
                    {book.map((name) => (
                      <MenuItem key={name._id} value={name._id}>
                        {name.booktitle}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  fullWidth
                  id="filled-required"
                  size="small"
                  // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
                  label="ISBN No"
                  name="isbnNo"
                  onChange={handleAddForm}
                  value={addForm.isbnNo || ""}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  fullWidth
                  id="filled-required"
                  size="small"
                  // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
                  label="edison"
                  name="edison"
                  onChange={handleAddForm}
                  value={addForm.edison || ""}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  fullWidth
                  id="filled-required"
                  size="small"
                  label="Author"
                  name="author"
                  value={addForm.author || ""}
                  onChange={handleAddForm}
                  sx={{
                    // m: 1,
                    // minWidth: { md: 227, xs: "100%" },
                    label: { fontSize: 12 },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  fullWidth
                  id="filled-required"
                  size="small"
                  label="Language"
                  name="language"
                  value={addForm.language || ""}
                  onChange={handleAddForm}
                  sx={{
                    // m: 1,
                    // minWidth: { md: 227, xs: "100%" },
                    label: { fontSize: 12 },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  fullWidth
                  id="filled-required"
                  size="small"
                  // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
                  label="Price"
                  name="price"
                  onChange={handleAddForm}
                  value={addForm.price || ""}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  id="filled-required"
                  size="small"
                  label="Quantity"
                  name="quantity"
                  value={addForm.quantity || ""}
                  onChange={handleAddForm}
                  sx={{
                    // m: 1,
                    // minWidth: { md: 227, xs: "100%" },
                    label: { fontSize: 12 },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  fullWidth
                  id="filled-required"
                  size="small"
                  label="Almira No"
                  name="almiraNo"
                  value={addForm.almiraNo || ""}
                  onChange={handleAddForm}
                  sx={{
                    // m: 1,
                    // minWidth: { md: 227, xs: "100%" },
                    label: { fontSize: 12 },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    size="small"
                    label="Return Date"
                    // openTo="day"
                    // views={["day", "month", "year"]}
                    inputFormat="DD-MM-YYYY"
                    value={edate}
                    onChange={(newValue) => {
                      setEDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
                        size="small"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <FormControl
                  fullWidth
                  size="small"
                  // sx={{ m: 0.3, minWidth: { md: 227, xs: "100%" } }}
                >
                  <InputLabel sx={{ fontSize: 8 }}>Select Member</InputLabel>
                  <Select
                    label="Select Member"
                    labelId="demo-simpless-select-filled-label"
                    id="demo-simple-select-filled-l"
                    name="issuedTo"
                    value={addForm.issuedTo || ""}
                    onChange={handleAddForm}
                  >
                    {students.map((data) => (
                      <MenuItem value={data._id}>
                        {data.basicInfo.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: { md: "right", sm: "center", xs: "center" },
                  }}
                >
                  <Tooltip title="Cancel">
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      size="medium"
                      color="error"
                      type="button"
                      sx={{ m: 1, color: "#ffff" }}
                    >
                      Cancel
                    </Button>
                  </Tooltip>
                  <Tooltip title="Submit">
                    <LoadingButton
                      loading={loader}
                      variant="contained"
                      size="medium"
                      type="submit"
                      sx={{
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                    >
                      Add
                    </LoadingButton>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Dialog>
      <TabPanel value={tabValue} index={0}>
        {/* <Paper sx={{ margin: "15px 0", marginTop: 3 }}> */}
        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: {
              md: "flex-end",
              xs: "center",
              sm: "center",
              paddingTop: "10px",
              // paddingBottom: "10px",
            },
          }}
        >
          <Grid xs="auto" mt={2}>
            <TextField
              size="small"
              value={search}
              onChange={handleSearch}
              label="Search"
              variant="outlined"
              placeholder="Search By Title"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        {/* </Paper> */}

        <Box overflow="scroll" sx={{ margin: "20px 0px" }}>
          <Table>
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell></TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Issue
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Due
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Total</TableCell>
                <TableCell align="center">{issueTotalCount}</TableCell>
                <TableCell align="center">{dueTotalCount}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
        <Box overflow="scroll">
          <Table>
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Student
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Library ID
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Title
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Book ID
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Issue Date
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Due Date
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Return Date
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter.map((data, index) => (
                    <TableRow>
                      {" "}
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {" "}
                        {data.issuedTo.basicInfo.name}
                      </TableCell>
                      <TableCell align="center">
                        {data.issuedTo.academicInfo.rollNo}
                      </TableCell>
                      <TableCell align="center">
                        {data.book.booktitle}
                      </TableCell>
                      <TableCell align="center">{data.book.booksId}</TableCell>
                      <TableCell align="center">
                        {dayjs(data.issueDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(data.returnDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">--</TableCell>
                      <TableCell align="center">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <LoadingButton
                            loading={addBtnLoader}
                            sx={{ color: "#1b3779" }}
                            onClick={handleClickPopOpen}
                          >
                            <AddIcon />
                          </LoadingButton>
                        </Box>
                        <Dialog
                          open={popOpen}
                          onClose={handlePopClose}
                          maxWidth="xs"
                          fullWidth
                        >
                          <DialogContent>
                            Are You Sure You Want to Change The Issue Status
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handlePopClose}>Cancel</Button>
                            <Button
                              onClick={(e) => {
                                handleSubmitList(e, data._id);
                                handlePopClose();
                              }}
                            >
                              Yes
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </TableCell>
                    </TableRow>
                  ))
                : issue.map((data, index) => (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {" "}
                        {data.issuedTo.basicInfo.name}
                      </TableCell>
                      <TableCell align="center">
                        {data.issuedTo.academicInfo.rollNo}
                      </TableCell>
                      <TableCell align="center">
                        {data.book.booktitle}
                      </TableCell>
                      <TableCell align="center">{data.book.booksId}</TableCell>
                      <TableCell align="center">
                        {dayjs(data.issueDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(data.returnDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">--</TableCell>

                      <TableCell align="center">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <LoadingButton
                            loading={addBtnLoader}
                            sx={{ color: "#1b3779" }}
                            onClick={handleClickPopOpen}
                          >
                            <AddIcon />
                          </LoadingButton>
                        </Box>
                        <Dialog
                          open={popOpen}
                          onClose={handlePopClose}
                          maxWidth="xs"
                          fullWidth
                        >
                          <DialogContent>
                            Are You Sure You Want to Change The Issue Status
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handlePopClose}>Cancel</Button>
                            <Button
                              onClick={(e) => {
                                handleSubmitList(e, data._id);
                                handlePopClose();
                              }}
                            >
                              Yes
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </Box>
        {!issue.length && !search.trim() && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        {search.trim() && !searchFilter.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Box sx={{ margin: "15px 0" }}>
          {/* <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingBottom: "10px",
              }}>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid xs="auto">
                  <TextField
                    size="small"
                    fullWidth
                    // sx={styles.textfield}
                    value={dueSearch}
                    onChange={handleDueSearch}
                    label="Search"
                    variant="outlined"
                    sx={{ mt: "20px" }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Paper> */}

          {/* <div style={{ margin: "15px" }}>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid xs="auto">
                <TextField
                  size="small"
                  value={search}
                  onChange={handleSearch}
                  label="Search"
                  variant="outlined"
                  placeholder="Search By Title"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" type="submit">
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </div> */}

          {/* <Paper sx={{ marginTop: 3 }}> */}
          <Grid
            container
            spacing={2}
            sx={{
              justifyContent: {
                md: "flex-end",
                xs: "center",
                sm: "center",
              },
            }}
          >
            <Grid xs="auto">
              <TextField
                size="small"
                fullWidth
                // sx={styles.textfield}
                value={dueSearch}
                onChange={handleDueSearch}
                label="Search"
                variant="outlined"
                sx={{ mt: "20px", pb: "10px" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" type="submit">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          {/* </Paper> */}
          <Box overflow="scroll" sx={{ margin: "15px 0px" }}>
            <Table>
              <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
                <TableRow sx={{ color: "white" }}>
                  <TableCell sx={{ color: "white" }} align="center">
                    #SL
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Student
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Library ID
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Title
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Book ID
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Issue Date
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Due Date
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Return Date
                  </TableCell>

                  <TableCell sx={{ color: "white" }} align="center">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dueSearch
                  ? dueSearchFilter.map((data, index) => (
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {" "}
                          {data.issuedTo.basicInfo.name}
                        </TableCell>
                        <TableCell align="center">
                          {data.issuedTo.academicInfo.rollNo}
                        </TableCell>
                        <TableCell align="center">
                          {data.book.booktitle}
                        </TableCell>
                        <TableCell align="center">
                          {data.book.booksId}
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(data.issueDate).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(data.returnDate).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="center">--</TableCell>

                        <TableCell align="center">
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <LoadingButton
                              loading={addBtnLoader}
                              sx={{ color: "#1b3779" }}
                              onClick={handleClickPopOpen}
                            >
                              <AddIcon />
                            </LoadingButton>
                          </Box>
                          <Dialog
                            open={popOpen}
                            onClose={handlePopClose}
                            maxWidth="xs"
                            fullWidth
                          >
                            <DialogContent>
                              Are You Sure You Want to Change The Issue Status
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={handlePopClose}>Cancel</Button>
                              <Button
                                onClick={(e) => {
                                  handleSubmitList(e, data._id);
                                  handlePopClose();
                                }}
                              >
                                Yes
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </TableCell>
                      </TableRow>
                    ))
                  : dueIssue.map((data, index) => (
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {" "}
                          {data.issuedTo.basicInfo.name}
                        </TableCell>
                        <TableCell align="center">
                          {data.issuedTo.academicInfo.rollNo}
                        </TableCell>
                        <TableCell align="center">
                          {data.book.booktitle}
                        </TableCell>
                        <TableCell align="center">
                          {data.book.booksId}
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(data.issueDate).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(data.returnDate).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="center">--</TableCell>

                        <TableCell align="center">
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <LoadingButton
                              loading={addBtnLoader}
                              sx={{ color: "#1b3779" }}
                              onClick={handleClickPopOpen}
                            >
                              <AddIcon />
                            </LoadingButton>
                          </Box>
                          <Dialog
                            open={popOpen}
                            onClose={handlePopClose}
                            maxWidth="xs"
                            fullWidth
                          >
                            <DialogContent>
                              Are You Sure You Want to Change The Issue Status
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={handlePopClose}>Cancel</Button>
                              <Button
                                onClick={(e) => {
                                  handleSubmitList(e, data._id);
                                  handlePopClose();
                                }}
                              >
                                Yes
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </Box>
          {!dueIssue.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {dueSearch.trim() && !dueSearchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </Box>
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Box>
          {/* <Card
            style={{
              height: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}>
            <form onSubmit={handleSearchHistory}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={4} sx={{ alignSelf: "center" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      variant="standard"
                      label="From Date"
                      inputFormat="DD-MM-YYYY"
                      value={fromDate}
                      onChange={(newValue) => {
                        setFromDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField variant="standard" {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={12} lg={4} sx={{ alignSelf: "center" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      variant="standard"
                      label="To Date"
                      inputFormat="DD-MM-YYYY"
                      value={toDate}
                      onChange={(newValue) => {
                        setToDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField variant="standard" {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={12} lg={4} sx={{ alignSelf: "center" }}>
                  <LoadingButton
                    loading={findLoader}
                    variant="contained"
                    sx={styles.Button}
                    size="small"
                    type="submit">
                    Find
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>

            <div style={{ marginRight: "20px" }}>
              {" "}
              <Stack spacing={2} direction="row">
                <LoadingButton
                  loading={loadingBtn}
                  onClick={handleGetDownloadSheet}
                  sx={styles.icon}>
                  Download
                </LoadingButton>
                <LoadingButton
                  loading={printLoadingBtn}
                  sx={styles.icon}
                  onClick={handleGetPrintPdf}>
                  Print
                </LoadingButton>
              </Stack>
            </div>
          </Card> */}

          <Card
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: "20px",
              marginTop: 10,
            }}
          >
            <form onSubmit={handleSearchHistory}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  sx={{ alignSelf: "center" }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      size="small"
                      label="From Date"
                      inputFormat="DD-MM-YYYY"
                      value={fromDate}
                      onChange={(newValue) => {
                        setFromDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField size="small" fullWidth {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  sx={{ alignSelf: "center" }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      size="small"
                      label="To Date"
                      inputFormat="DD-MM-YYYY"
                      value={toDate}
                      onChange={(newValue) => {
                        setToDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField size="small" fullWidth {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={4} lg={3} sx={{ alignSelf: "center" }}>
                  <LoadingButton
                    loading={findLoader}
                    variant="contained"
                    sx={styles.Button}
                    size="small"
                    type="submit"
                  >
                    Find
                  </LoadingButton>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ alignSelf: "center" }}>
                  <Box style={{ paddingBottom: "10px" }}>
                    {" "}
                    <Stack spacing={2} direction="row">
                      <LoadingButton
                        size="small"
                        loading={loadingBtn}
                        onClick={handleGetDownloadSheet}
                        sx={{
                          color: "#ffff",
                          background: "rgb(27, 55, 121)",
                          ":hover": { background: "rgb(27, 55, 121)" },
                        }}
                      >
                        Download
                      </LoadingButton>
                      <LoadingButton
                        size="small"
                        loading={printLoadingBtn}
                        sx={{
                          color: "#ffff",
                          background: "rgb(27, 55, 121)",
                          ":hover": { background: "rgb(27, 55, 121)" },
                        }}
                        onClick={handleGetPrintPdf}
                      >
                        Print
                      </LoadingButton>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Box>

        <Box sx={{ margin: "15px 0", marginTop: 3 }}>
          {/* <Paper> */}
          <Grid
            container
            spacing={2}
            sx={{
              justifyContent: {
                md: "flex-end",
                xs: "center",
                sm: "center",
              },
            }}
          >
            <Grid xs="auto">
              <TextField
                size="small"
                fullWidth
                value={hisSearch}
                onChange={handleHisSearch}
                label="Search"
                variant="outlined"
                sx={{ mt: "20px", pb: "10px" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" type="submit">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          {/* </Paper> */}
        </Box>
        <Box overflow="scroll">
          <Table>
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Student
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Library ID
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Title
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Book ID
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Issue Date
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Due Date
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Return Date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {hisSearch
                ? hisSearchFilter.map((data, index) => (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {" "}
                        {data.issuedTo.basicInfo.name}
                      </TableCell>
                      <TableCell align="center">
                        {data.issuedTo.academicInfo.rollNo}
                      </TableCell>
                      <TableCell align="center">
                        {data.book.booktitle}
                      </TableCell>
                      <TableCell align="center">{data.book.booksId}</TableCell>
                      <TableCell align="center">
                        {dayjs(data.issueDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(data.returnDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        {data.submittedDate
                          ? dayjs(data.submittedDate).format("DD-MM-YYYY")
                          : "NA"}
                      </TableCell>
                    </TableRow>
                  ))
                : history.map((data, index) => (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {" "}
                        {data.issuedTo.basicInfo.name}
                      </TableCell>
                      <TableCell align="center">
                        {data.issuedTo.academicInfo.rollNo}
                      </TableCell>
                      <TableCell align="center">
                        {data.book.booktitle}
                      </TableCell>
                      <TableCell align="center">{data.book.booksId}</TableCell>
                      <TableCell align="center">
                        {dayjs(data.issueDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(data.returnDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        {data.submittedDate
                          ? dayjs(data.submittedDate).format("DD-MM-YYYY")
                          : "NA"}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </Box>
        {!history.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        {hisSearch.trim() && !hisSearchFilter.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
      </TabPanel>
    </Wrapper>
  );
};

export default StudentIssue;
