import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
  Box,
  Card,
  FormControl,
  Grid,
  Modal,
  TextField,
  Tooltip,
  styled,
  Typography,
  InputAdornment,
  IconButton,
  Fab,
  Dialog,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Delete, Search } from "@mui/icons-material";
import { get, post, put, del } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import DeleteModal from "../Layout/Academic/DeleteModal";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import SectionsSubHeader from "../../components/SectionSubHeader";
import Wrapper from "../Wrapper";

import { useTheme } from "@mui/material/styles";

const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
};

const ContainerBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "600px",
  maxHeight: "650px",
  borderRadius: "5px",
  height: "auto",
  // overflow:"scroll",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
    width: "90%",
  },
  [theme.breakpoints.between(819, 821)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(767, 769)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(911, 913)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1023, 1025)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1279, 1281)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ManageRoomType = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [search, setSearch] = useState("");
  const [open, setOpen] = React.useState(false);
  const [editRoomType, setEditRoomType] = useState();
  const [addForm, setAddForm] = useState({});
  const [list, setList] = React.useState([]);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [searchFilter, setSearchFilter] = useState([]);
  const [loader, setLoader] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setEditRoomType(null);
    setAddForm({});

    setOpen(false);
  };
  const styles = {
    button: {
      backgroundColor: "orange",
    },
    textfield: {
      // marginTop: "25px",
      // width: "70%",
    },
    addbutton: {
      paddingTop: "6.2px",
      paddingBottom: "6.2px",
      marginTop: 3.2,
    },
  };
  // const handleSearch = (e) => {
  //   const { value } = e.target;
  //   setSearch(value.trim());
  // };

  useEffect(() => {
    getRoomType();
  }, [selectedSetting]);

  const getRoomType = async () => {
    console.log("rrr");
    try {
      const response = await get(`${urls.roomType.getList}`, {
        params: {
          search: {},
          pageNumber: 1,
          limit: 1000,
        },
      });
      setList(response.data.data);
      console.log(response.data.data, "ddddd");
    } catch (error) {
      console.log(error);
    }
  };
  console.log(list, "aaaaa");
  const handleAddSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      if (!editRoomType) {
        const { status } = await post(`${urls.roomType.create}`, {
          name: addForm.name,
          specification: addForm.specification,
        });
        console.log(status, "ssss");
        getRoomType();
        handleClose();
      } else {
        const { status } = await put(
          `${urls.roomType.editRoomType}${editRoomType}`,
          "",
          {
            name: addForm.name,
            specification: addForm.specification,
          }
        );
        getRoomType();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };
  const handleAddForm = (event) => {
    const { name, value } = event.target;
    setAddForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleClassMapEdit = (id, data) => {
    setEditRoomType(id);
    setAddForm({
      name: data.name,
      specification: data.specification,
    });
    handleClickOpen();
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(`${urls.roomType.delRoom}`, id);
      console.log(res);
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      list.length > 0 &&
        setSearchFilter(
          list.filter((ele) =>
            ele.name.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  return (
    <Wrapper>
      <SectionsSubHeader title="Hostel Room Type" />
      <Box sx={{ margin: "20px 0" }}>
        {/* <Paper sx={{ width: "100%", overflow: "hidden" }}> */}
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: { md: "right", xs: "center" },
          }}
        >
          <Grid item xs="auto" mb={2}>
            <TextField
              fullWidth
              size="small"
              // sx={{minWidth:227}}
              value={search}
              onChange={handleSearch}
              label="Search"
              variant="outlined"
              placeholder="Search By Name"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {/* <Grid
              item
              xs={12}
              md={2}
              sx={{
                display: "flex",
                justifyContent: { md: "right", xs: "center" },
              }}
            >
              <Button
                variant="contained"
                // sx={{ mt: 3.2 }}
                // sx={styles.addbutton}
                onClick={handleClickOpen}
              >
                <AddIcon />
                Add Room Type
              </Button>
            </Grid> */}
        </Grid>
        {/* </Paper> */}

        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form className="form-tag" onSubmit={handleAddSubmit}>
            <Box sx={style}>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                }}
              >
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    textAlign="center"
                    fontSize="20px"
                    fontWeight="bold"
                  >
                    {editRoomType ? "Update Room Type" : "Add Room"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    fullWidth
                    required
                    size="small"
                    id="filled-required"
                    label="Name"
                    name="name"
                    value={addForm.name || ""}
                    onChange={handleAddForm}
                    sx={{
                      // minWidth: { md: 227, xs: "100%" },
                      label: { fontSize: 12 },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    fullWidth
                    size="small"
                    required
                    id="filled-required"
                    label="Specification"
                    name="specification"
                    value={addForm.specification || ""}
                    onChange={handleAddForm}
                    sx={{
                      // minWidth: { md: 227, xs: "100%" },
                      label: { fontSize: 12 },
                    }}
                    // style={{
                    //   width: "90%",
                    //   maxHeight: 110,
                    //   marginTop: 25,
                    // }}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: {
                        md: "right",
                        sm: "right",
                        xs: "center",
                        gap: "1%",
                      },
                    }}
                  >
                    <Tooltip title="Cancel">
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        size="medium"
                        color="error"
                        type="button"
                        sx={{ m: 1, color: "#fff" }}
                      >
                        Cancel
                      </Button>
                    </Tooltip>
                    <Tooltip title="Submit">
                      <LoadingButton
                        loading={loader}
                        variant="contained"
                        size="medium"
                        type="submit"
                        sx={{
                          background: "rgb(27, 55, 121)",
                          ":hover": { background: "rgb(27, 55, 121)" },
                          // m: 1,
                        }}
                      >
                        {editRoomType ? "update" : "Add"}
                      </LoadingButton>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Dialog>

        <Table>
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow sx={{ color: "white" }}>
              <TableCell sx={{ color: "white" }} align="center">
                #SL
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Name
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Specification
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {search
              ? searchFilter.map((room, index) => (
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{room.name}</TableCell>
                    <TableCell align="center">{room.specification}</TableCell>

                    <TableCell align="center">
                      <Box sx={{ marginLeft: "30px" }}>
                        <Button
                          sx={{
                            color: "#1b3779",
                          }}
                          onClick={() => handleClassMapEdit(room._id, room)}
                        >
                          <EditIcon fontSize="small" />
                        </Button>

                        <Button
                          sx={{
                            color: "#1b3779",
                          }}
                          onClick={() => setDeleteModal(room._id, room)}
                        >
                          <Delete fontSize="small" />
                        </Button>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDeleteone}
                          id={room._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              : list.map((room, index) => (
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{room.name}</TableCell>
                    <TableCell align="center">{room.specification}</TableCell>

                    <TableCell align="center">
                      <Box sx={{ marginLeft: "30px" }}>
                        <Button
                          sx={{
                            color: "#1b3779",
                          }}
                          onClick={() => handleClassMapEdit(room._id, room)}
                        >
                          <EditIcon fontSize="small" />
                        </Button>

                        <Button
                          sx={{
                            color: "#1b3779",
                          }}
                          onClick={() => setDeleteModal(room._id, room)}
                        >
                          <Delete fontSize="small" />
                        </Button>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDeleteone}
                          id={room._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
        <Box className="add-icon">
          <Fab
            variant="contained"
            sx={{
              color: "#ffff",

              background: "rgb(27, 55, 121)",
              ":hover": { background: "rgb(27, 55, 121)" },
            }}
            onClick={handleClickOpen}
          >
            <AddIcon />
          </Fab>
        </Box>
        {!list.length && !search.trim() && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        {search.trim() && !searchFilter.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
      </Box>
    </Wrapper>
  );
};
export default ManageRoomType;
