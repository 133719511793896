import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";

import React, { useState, useContext } from "react";
import { get } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { useEffect } from "react";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
dayjs.extend(localeData);

const styles = {
  card: {
    padding: 10,
    margin: "0px 0px 20px 0px",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
    m: 1,
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
    marginTop: "10px",
  },
};

const EmployeeAttendanceReport = () => {
  const { selectedSetting } = useContext(SettingContext);
  const [reportDetails, setReportDetails] = useState({});
  const [sections, setSections] = useState([]);
  const [classes, setClasses] = useState([]);
  const [academicYears, setAcademicYears] = useState([]);
  const [attendanceReports, setAttendanceReports] = useState([]);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const handleClasses = async (e) => {
    try {
      const { name, value } = e.target;
      setReportDetails({ ...reportDetails, [name]: value });
      const res = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: value,
          },
        },
      });
      setSections(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll);
        setAcademicYears(res.data.data);
        console.log("setAcademicYears", res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, [selectedSetting]);

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        setClasses(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, [selectedSetting]);

  const handleSections = (e) => {
    const { name, value } = e.target;
    setReportDetails({ ...reportDetails, [name]: value });
  };

  const handleAttendanceReportSubmit = async (e) => {
    e.preventDefault();
    try {
      const studentAttendanceReportRes = await get(
        `${urls.students.getStudentAttendanceReport}/${reportDetails.academicYear}/${reportDetails.class}/${reportDetails.section}/${reportDetails.month}`
      );
      if (studentAttendanceReportRes.data.success) {
        setAttendanceReports(studentAttendanceReportRes.data.data);
      } else {
        console.log(studentAttendanceReportRes);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Card style={styles.card}>
        <Box sx={{ flexGrow: 1 }}>
          <form onSubmit={handleAttendanceReportSubmit}>
            <Grid container spacing={2}>
              <Grid item sx={12} md={6} lg={2}>
                <FormControl variant="standard" sx={styles.textfield}>
                  <InputLabel id="demo-simple-select-filled-label">
                    Academic Year
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    required
                    id="demo-simple-select-filled"
                    name="academicYear"
                    onChange={(e) => handleSections(e)}
                    value={reportDetails.academicYear}
                  >
                    {academicYears &&
                      academicYears.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.academicYearFrom} - {row.academicYearTo}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl variant="standard" sx={styles.textfield} required>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}
                  >
                    Class
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    required
                    id="demo-simple-select-filled"
                    name="class"
                    value={reportDetails.class}
                    onChange={(event) => handleClasses(event)}
                  >
                    {classes &&
                      classes.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.className}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl variant="standard" sx={styles.textfield} required>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}
                  >
                    Section
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    required
                    id="demo-simple-select-filled"
                    name="section"
                    value={reportDetails.section}
                    onChange={(event) => handleSections(event)}
                  >
                    {sections &&
                      sections.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.sectionName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3} sx={{ alignSelf: "center" }}>
                <FormControl variant="standard" sx={styles.textfield} required>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}
                  >
                    Month
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    required
                    id="demo-simple-select-filled"
                    name="month"
                    value={reportDetails.month}
                    onChange={(event) => handleSections(event)}
                  >
                    {dayjs.months().map((month, index) => (
                      <MenuItem
                        key={month}
                        value={month}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {month}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={1}
                sx={{
                  alignSelf: "center",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  style={styles.Button}
                  size="small"
                  type="submit"
                >
                  Find
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Card>

      <div>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">Student</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Working Days</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Present Days</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Percentage</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {attendanceReports.length > 0 &&
                  attendanceReports.map((attendanceReport, index) => (
                    <TableRow
                      key={attendanceReport._id}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {attendanceReport.basicInfo.name}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {attendanceReport.workingDays}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {attendanceReport.presentDays}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {attendanceReport.percentage}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {!attendanceReports.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </Paper>
      </div>
    </div>
  );
};

export default EmployeeAttendanceReport;
