import React from "react";
import dayjs from "dayjs";
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import "../../../styles/components/Academic/Academic.scss";
import { useState, useContext } from "react";
import { del, get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { Delete, Edit, Search } from "@mui/icons-material";
import AddNotice from "./AddNotice";
import DeleteModal from "../Academic/DeleteModal";
import TablePagination from "@mui/material/TablePagination";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../../components/SectionSubHeader";

import styles from "../../../styles/components/Layout/Administrator/UserRole.module.css";
import Wrapper from "../../Wrapper";

const Notice = () => {
  const { selectedSetting } = useContext(SettingContext);

  const [announceNotice, setAnounceNotice] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [updateNotice, setUpdateNotice] = useState(undefined);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [loader, setLoader] = useState(false);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const [sort, setSort] = useState({
    title: "asc",
    webView: "asc",
    date: "asc",
  });

  const sortByTitle = () => {
    console.log("Title");
    if (search) {
      if (sort.title === "asc") {
        let sortedList = [
          ...searchFilter.sort((a, b) => b.title.localeCompare(a.title)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, Title: "des" });
      } else {
        let sortedList = [
          ...searchFilter.sort((a, b) => a.title.localeCompare(b.title)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, title: "asc" });
      }
    } else {
      if (sort.title === "asc") {
        let sortedList = [
          ...announceNotice.sort((a, b) => b.title.localeCompare(a.title)),
        ];
        setAnounceNotice(sortedList);
        setSort({ ...sort, title: "des" });
      } else {
        let sortedList = [
          ...announceNotice.sort((a, b) => a.title.localeCompare(b.title)),
        ];
        setAnounceNotice(sortedList);
        setSort({ ...sort, title: "asc" });
      }
    }
  };

  const compareDatesAsc = (a, b) => {
    let dateA = new Date(a.date);
    let dateB = new Date(b.date);

    return dateA - dateB;
  };

  const compareDatesDec = (a, b) => {
    let dateA = new Date(a.date);
    let dateB = new Date(b.date);

    return dateB - dateA;
  };

  const sortByDate = () => {
    console.log("Date");
    if (search) {
      if (sort.date === "asc") {
        let sortedList = [...searchFilter.sort(compareDatesDec)];
        setSearchFilter(sortedList);
        setSort({ ...sort, date: "des" });
      } else {
        let sortedList = [...searchFilter.sort(compareDatesAsc)];
        setSearchFilter(sortedList);
        setSort({ ...sort, date: "asc" });
      }
    } else {
      if (sort.date === "asc") {
        let sortedList = [...announceNotice.sort(compareDatesDec)];
        setAnounceNotice(sortedList);
        setSort({ ...sort, date: "des" });
      } else {
        let sortedList = [...announceNotice.sort(compareDatesAsc)];
        setAnounceNotice(sortedList);
        setSort({ ...sort, date: "asc" });
      }
    }
  };

  const sortByWebView = () => {
    console.log("WebView");
    if (search) {
      if (sort.webView === "asc") {
        let sortedList = [
          ...searchFilter.sort((a, b) => b.webView - a.webView),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, webView: "des" });
      } else {
        let sortedList = [
          ...searchFilter.sort((a, b) => a.webView - b.webView),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, webView: "asc" });
      }
    } else {
      if (sort.webView === "asc") {
        let sortedList = [
          ...announceNotice.sort((a, b) => b.webView - a.webView),
        ];
        setAnounceNotice(sortedList);
        setSort({ ...sort, webView: "des" });
      } else {
        let sortedList = [
          ...announceNotice.sort((a, b) => a.webView - b.webView),
        ];
        setAnounceNotice(sortedList);
        setSort({ ...sort, webView: "asc" });
      }
    }
  };

  const handleChangePage = async (event, newPage) => {
    try {
      const res = await get(urls.announceNotice.getNotice, {
        params: {
          limit: 10,
          page: newPage + 1,
        },
      });
      setAnounceNotice([...res.data.data]);
      setPage(newPage);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setUpdateNotice(undefined);
  };

  const handleDelete = async (id) => {
    try {
      const response = await del(urls.announceNotice.delNotice, id);
      if (response.data.success) {
        setAnounceNotice(
          announceNotice.filter((item) => item._id !== response.data.data._id)
        );
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = async (e, data) => {
    e.preventDefault();
    setLoader(true);
    try {
      const response = await post(urls.announceNotice.postNotice, data);
      setAnounceNotice([response.data.data, ...announceNotice]);
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const handleUpdate = async (data, id) => {
    // setLoading(true)
    setLoader(true);

    try {
      const response = await put(urls.announceNotice.putNotice, id, data);
      setAnounceNotice(
        announceNotice.map((e) => {
          if (e._id === response.data.data._id) {
            return response.data.data;
          } else {
            return e;
          }
        })
      );
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };
  const handleEdit = async (id) => {
    try {
      setOpen(true);
      const getNoticeById = announceNotice.find((item) => item._id === id);
      setUpdateNotice(getNoticeById);
    } catch (error) {
      console.log(error);
    }
    // setLoading(false)
  };

  useEffect(() => {
    const getNotice = async () => {
      try {
        const response = await get(urls.announceNotice.getNotice, {
          params: {
            limit: 10,
            page: 1,
          },
        });
        setAnounceNotice([...response.data.data]);
        setRowsPerPage(response.data.data.length);
      } catch (error) {
        console.log(error);
      }
    };
    getNotice();
  }, [selectedSetting]);
  useEffect(() => {
    const getNotice = async () => {
      try {
        const response = await get(urls.announceNotice.getNotice);
        setTotalCount(response.data.data.length);
      } catch (error) {
        console.log(error);
      }
    };
    getNotice();
  }, [selectedSetting]);
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    if (value === "") {
      setSearchFilter([]);
      setShowSearch(false);
    }
  };
  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    try {
      const noticeSearch = await get(`${urls.announceNotice.getNotice}`, {
        params: {
          search: {
            $or: [
              {
                title: { $regex: search.trim() },
              },
              {
                date: dayjs(search, "DD-MM-YYYY").isValid()
                  ? dayjs(search, "DD-MM-YYYY").toDate()
                  : "",
              },
            ],
          },
        },
      });
      setSearchFilter(noticeSearch.data.data);
      setShowSearch(true);
    } catch (error) {
      console.log(error.stack);
    }
  };
  return (
    <Wrapper>
      <SectionSubHeader title="Notice" />
      <Box sx={{ margin: "20px 0" }}>
        {/* <Box className={styles.searchInputContainer}> */}
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: { md: "right", xs: "center" },
          }}
        >
          <Grid item xs="auto" mb={2}>
            <form onSubmit={handleSearchSubmit}>
              <TextField
                size="small"
                value={search}
                onChange={handleSearch}
                label="Search by Title"
                // placeholder="Search by Title"
                variant="outlined"
                placeholder="Search By Title"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" type="submit">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </Grid>
        </Grid>
        {/* </Box> */}
        <Paper sx={{ width: "100%" }}>
          <TableContainer component={Grid}>
            <Table sx={{ overflow: "hidden" }} aria-label="simple table">
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Title</span>
                    <span
                      onClick={sortByTitle}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.title === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Date</span>
                    <span
                      onClick={sortByDate}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.date === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Is View On Web?</span>
                    <span
                      onClick={sortByWebView}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.webView === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Action </span>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {showSearch
                  ? searchFilter.map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.title}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {dayjs(row.date).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.webView ? "yes" : "no"}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <Box className="edit-delete">
                            <Tooltip title="Edit">
                              <Button onClick={() => handleEdit(row._id)}>
                                <Edit />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <Button onClick={() => setDeleteModal(row._id)}>
                                <Delete />
                              </Button>
                            </Tooltip>
                          </Box>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={row._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  : announceNotice.map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.title}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {dayjs(row.date).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.webView ? "yes" : "no"}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div className="edit-delete">
                            <Tooltip title="Edit">
                              <Button onClick={() => handleEdit(row._id)}>
                                <Edit />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <Button onClick={() => setDeleteModal(row._id)}>
                                <Delete />
                              </Button>
                            </Tooltip>
                          </div>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={row._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          {!announceNotice.length && !showSearch && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {showSearch && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </Paper>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={totalCount}
          page={page}
          style={{ position: "sticky", overflow: "visible" }}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <Box className="add-icon">
          <AddNotice
            loader={loader}
            // load={loading}

            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            updateNotice={updateNotice}
            handleUpdate={handleUpdate}
          />
        </Box>
      </Box>
    </Wrapper>
  );
};

export default Notice;

// import React from "react";
// import dayjs from "dayjs";
// import {
//   Box,
//   Button,
//   Card,
//   Grid,
//   IconButton,
//   InputAdornment,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   TextField,
//   Tooltip,
//   Typography,
// } from "@mui/material";
// import { useEffect } from "react";
// import "../../../styles/components/Academic/Academic.scss";
// import { useState, useContext } from "react";
// import { del, get, post, put } from "../../../services/apis";
// import { urls as url } from "../../../services/urlConstant";
// import { Delete, Edit, Search } from "@mui/icons-material";
// import AddNotice from "./AddNotice";
// import DeleteModal from "../Academic/DeleteModal";
// import TablePagination from "@mui/material/TablePagination";
// import SettingContext from "../../../context/SettingsContext";
// import appendStringToUrls from "../../../utils/appendString";

// import styles from "../../../styles/components/Layout/Administrator/UserRole.module.css";

// const Notice = () => {
//   const { selectedSetting } = useContext(SettingContext);

//   const [announceNotice, setAnounceNotice] = useState([]);
//   const [deleteModal, setDeleteModal] = useState(false);
//   const [open, setOpen] = useState(false);
//   const [updateNotice, setUpdateNotice] = useState(undefined);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = React.useState(10);
//   const [totalCount, setTotalCount] = useState(0);
//   const [searchFilter, setSearchFilter] = useState([]);
//   const [search, setSearch] = useState("");
//   const [showSearch, setShowSearch] = useState(false);
//   const [loader, setLoader] = useState(false);
//   const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

//   const [sort, setSort] = useState({
//     title: "asc",
//     webView: "asc",
//     date: "asc",
//   });

//   const sortByTitle = () => {
//     console.log("Title");
//     if (search) {
//       if (sort.title === "asc") {
//         let sortedList = [
//           ...searchFilter.sort((a, b) => b.title.localeCompare(a.title)),
//         ];
//         setSearchFilter(sortedList);
//         setSort({ ...sort, Title: "des" });
//       } else {
//         let sortedList = [
//           ...searchFilter.sort((a, b) => a.title.localeCompare(b.title)),
//         ];
//         setSearchFilter(sortedList);
//         setSort({ ...sort, title: "asc" });
//       }
//     } else {
//       if (sort.title === "asc") {
//         let sortedList = [
//           ...announceNotice.sort((a, b) => b.title.localeCompare(a.title)),
//         ];
//         setAnounceNotice(sortedList);
//         setSort({ ...sort, title: "des" });
//       } else {
//         let sortedList = [
//           ...announceNotice.sort((a, b) => a.title.localeCompare(b.title)),
//         ];
//         setAnounceNotice(sortedList);
//         setSort({ ...sort, title: "asc" });
//       }
//     }
//   };

//   const compareDatesAsc = (a, b) => {
//     let dateA = new Date(a.date);
//     let dateB = new Date(b.date);

//     return dateA - dateB;
//   };

//   const compareDatesDec = (a, b) => {
//     let dateA = new Date(a.date);
//     let dateB = new Date(b.date);

//     return dateB - dateA;
//   };

//   const sortByDate = () => {
//     console.log("Date");
//     if (search) {
//       if (sort.date === "asc") {
//         let sortedList = [...searchFilter.sort(compareDatesDec)];
//         setSearchFilter(sortedList);
//         setSort({ ...sort, date: "des" });
//       } else {
//         let sortedList = [...searchFilter.sort(compareDatesAsc)];
//         setSearchFilter(sortedList);
//         setSort({ ...sort, date: "asc" });
//       }
//     } else {
//       if (sort.date === "asc") {
//         let sortedList = [...announceNotice.sort(compareDatesDec)];
//         setAnounceNotice(sortedList);
//         setSort({ ...sort, date: "des" });
//       } else {
//         let sortedList = [...announceNotice.sort(compareDatesAsc)];
//         setAnounceNotice(sortedList);
//         setSort({ ...sort, date: "asc" });
//       }
//     }
//   };

//   const sortByWebView = () => {
//     console.log("WebView");
//     if (search) {
//       if (sort.webView === "asc") {
//         let sortedList = [
//           ...searchFilter.sort((a, b) => b.webView - a.webView),
//         ];
//         setSearchFilter(sortedList);
//         setSort({ ...sort, webView: "des" });
//       } else {
//         let sortedList = [
//           ...searchFilter.sort((a, b) => a.webView - b.webView),
//         ];
//         setSearchFilter(sortedList);
//         setSort({ ...sort, webView: "asc" });
//       }
//     } else {
//       if (sort.webView === "asc") {
//         let sortedList = [
//           ...announceNotice.sort((a, b) => b.webView - a.webView),
//         ];
//         setAnounceNotice(sortedList);
//         setSort({ ...sort, webView: "des" });
//       } else {
//         let sortedList = [
//           ...announceNotice.sort((a, b) => a.webView - b.webView),
//         ];
//         setAnounceNotice(sortedList);
//         setSort({ ...sort, webView: "asc" });
//       }
//     }
//   };

//   const handleChangePage = async (event, newPage) => {
//     try {
//       const res = await get(urls.announceNotice.getNotice, {
//         params: {
//           limit: 10,
//           page: newPage + 1,
//         },
//       });
//       setAnounceNotice([...res.data.data]);
//       setPage(newPage);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const handleOpen = () => setOpen(true);
//   const handleClose = () => {
//     setOpen(false);
//     setUpdateNotice(undefined);
//   };

//   const handleDelete = async (id) => {
//     try {
//       const response = await del(urls.announceNotice.delNotice, id);
//       if (response.data.success) {
//         setAnounceNotice(
//           announceNotice.filter((item) => item._id !== response.data.data._id)
//         );
//       } else {
//         console.log("Couldn't complete your request, Try again later");
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   const handleSubmit = async (e, data) => {
//     e.preventDefault();
//     setLoader(true);
//     try {
//       const response = await post(urls.announceNotice.postNotice, data);
//       setAnounceNotice([response.data.data, ...announceNotice]);
//     } catch (error) {
//       console.log(error);
//     }
//     setLoader(false);
//   };

//   const handleUpdate = async (data, id) => {
//     // setLoading(true)
//     setLoader(true);

//     try {
//       const response = await put(urls.announceNotice.putNotice, id, data);
//       setAnounceNotice(
//         announceNotice.map((e) => {
//           if (e._id === response.data.data._id) {
//             return response.data.data;
//           } else {
//             return e;
//           }
//         })
//       );
//     } catch (error) {
//       console.log(error);
//     }
//     setLoader(false);
//   };
//   const handleEdit = async (id) => {
//     try {
//       setOpen(true);
//       const getNoticeById = announceNotice.find((item) => item._id === id);
//       setUpdateNotice(getNoticeById);
//     } catch (error) {
//       console.log(error);
//     }
//     // setLoading(false)
//   };

//   useEffect(() => {
//     const getNotice = async () => {
//       try {
//         const response = await get(urls.announceNotice.getNotice, {
//           params: {
//             limit: 10,
//             page: 1,
//           },
//         });
//         setAnounceNotice([...response.data.data]);
//         setRowsPerPage(response.data.data.length);
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     getNotice();
//   }, []);
//   useEffect(() => {
//     const getNotice = async () => {
//       try {
//         const response = await get(urls.announceNotice.getNotice);
//         setTotalCount(response.data.data.length);
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     getNotice();
//   }, []);
//   const handleSearch = (e) => {
//     const { value } = e.target;
//     setSearch(value);
//     if (value === "") {
//       setSearchFilter([]);
//       setShowSearch(false);
//     }
//   };
//   const handleSearchSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const noticeSearch = await get(`${urls.announceNotice.getNotice}`, {
//         params: {
//           search: {
//             $or: [
//               {
//                 title: { $regex: search.trim() },
//               },
//               {
//                 date: dayjs(search, "DD-MM-YYYY").isValid()
//                   ? dayjs(search, "DD-MM-YYYY").toDate()
//                   : "",
//               },
//             ],
//           },
//         },
//       });
//       setSearchFilter(noticeSearch.data.data);
//       setShowSearch(true);
//     } catch (error) {
//       console.log(error.stack);
//     }
//   };
//   return (
//     <Box>
//       <Card style={{ margin: 15, padding: 10 }}>
//         <Grid container spacing={2}>
//           <Grid item xs={12} mg={12} lg={12}>
//             <Box
//               component="span"
//               style={{
//                 color: "red",
//                 fontFamily: "cursive",
//               }}>
//               Notice :
//             </Box>
//           </Grid>
//         </Grid>
//       </Card>
//       <Box sx={{ margin: "15px" }}>
//         <Paper sx={{ width: "100%" }}>
//           <Box className={styles.searchInputContainer}>
//             <Grid container spacing={2} justifyContent="flex-end">
//               <Grid xs="auto">
//                 <form onSubmit={handleSearchSubmit}>
//                   <TextField
//                     size="small"
//                     value={search}
//                     onChange={handleSearch}
//                     label="Search"
//                     variant="outlined"
//                     InputProps={{
//                       endAdornment: (
//                         <InputAdornment position="end">
//                           <IconButton edge="end" type="submit">
//                             <Search />
//                           </IconButton>
//                         </InputAdornment>
//                       ),
//                     }}
//                   />
//                 </form>
//               </Grid>
//             </Grid>
//           </Box>
//           <TableContainer component={Grid}>
//             <Table sx={{ overflow: "hidden" }} aria-label="simple table">
//               <TableHead style={{ background: "rgb(27 55 121)" }}>
//                 <TableRow>
//                   <TableCell align="center">
//                     <span className="class-table-header">#SL</span>
//                   </TableCell>
//                   <TableCell align="center">
//                     <span className="class-table-header">Title</span>
//                     <span
//                       onClick={sortByTitle}
//                       style={{ cursor: "pointer", color: "#fff" }}>
//                       {sort.title === "asc" ? "▲" : "▼"}
//                     </span>
//                   </TableCell>
//                   <TableCell align="center">
//                     <span className="class-table-header">Date</span>
//                     <span
//                       onClick={sortByDate}
//                       style={{ cursor: "pointer", color: "#fff" }}>
//                       {sort.date === "asc" ? "▲" : "▼"}
//                     </span>
//                   </TableCell>
//                   <TableCell align="center">
//                     <span className="class-table-header">Is View On Web?</span>
//                     <span
//                       onClick={sortByWebView}
//                       style={{ cursor: "pointer", color: "#fff" }}>
//                       {sort.webView === "asc" ? "▲" : "▼"}
//                     </span>
//                   </TableCell>
//                   <TableCell align="center">
//                     <span className="class-table-header">Action </span>
//                   </TableCell>
//                 </TableRow>
//               </TableHead>

//               <TableBody>
//                 {showSearch
//                   ? searchFilter.map((row, index) => (
//                       <TableRow
//                         key={row._id}
//                         sx={{
//                           "&:last-child td, &:last-child th": { border: 0 },
//                         }}>
//                         <TableCell align="center">{index + 1}</TableCell>
//                         <TableCell
//                           align="center"
//                           style={{ textTransform: "capitalize" }}>
//                           {row.title}
//                         </TableCell>
//                         <TableCell
//                           align="center"
//                           style={{ textTransform: "capitalize" }}>
//                           {dayjs(row.date).format("DD-MM-YYYY")}
//                         </TableCell>
//                         <TableCell
//                           align="center"
//                           style={{ textTransform: "capitalize" }}>
//                           {row.webView ? "yes" : "no"}
//                         </TableCell>

//                         <TableCell
//                           align="center"
//                           style={{ textTransform: "capitalize" }}>
//                           <Box className="edit-delete">
//                             <Tooltip title="Edit">
//                               <Button onClick={() => handleEdit(row._id)}>
//                                 <Edit />
//                               </Button>
//                             </Tooltip>
//                             <Tooltip title="Delete">
//                               <Button onClick={() => setDeleteModal(row._id)}>
//                                 <Delete />
//                               </Button>
//                             </Tooltip>
//                           </Box>
//                           <DeleteModal
//                             deleteModal={deleteModal}
//                             handleDelete={handleDelete}
//                             id={row._id}
//                             setDeleteModal={setDeleteModal}
//                           />
//                         </TableCell>
//                       </TableRow>
//                     ))
//                   : announceNotice.map((row, index) => (
//                       <TableRow
//                         key={row._id}
//                         sx={{
//                           "&:last-child td, &:last-child th": { border: 0 },
//                         }}>
//                         <TableCell align="center">{index + 1}</TableCell>
//                         <TableCell
//                           align="center"
//                           style={{ textTransform: "capitalize" }}>
//                           {row.title}
//                         </TableCell>
//                         <TableCell
//                           align="center"
//                           style={{ textTransform: "capitalize" }}>
//                           {dayjs(row.date).format("DD-MM-YYYY")}
//                         </TableCell>
//                         <TableCell
//                           align="center"
//                           style={{ textTransform: "capitalize" }}>
//                           {row.webView ? "yes" : "no"}
//                         </TableCell>

//                         <TableCell
//                           align="center"
//                           style={{ textTransform: "capitalize" }}>
//                           <div className="edit-delete">
//                             <Tooltip title="Edit">
//                               <Button onClick={() => handleEdit(row._id)}>
//                                 <Edit />
//                               </Button>
//                             </Tooltip>
//                             <Tooltip title="Delete">
//                               <Button onClick={() => setDeleteModal(row._id)}>
//                                 <Delete />
//                               </Button>
//                             </Tooltip>
//                           </div>
//                           <DeleteModal
//                             deleteModal={deleteModal}
//                             handleDelete={handleDelete}
//                             id={row._id}
//                             setDeleteModal={setDeleteModal}
//                           />
//                         </TableCell>
//                       </TableRow>
//                     ))}
//               </TableBody>
//             </Table>
//           </TableContainer>
//           {!announceNotice.length && !showSearch && (
//             <Typography
//               variant="h6"
//               sx={{ textAlign: "center", margin: "5px", padding: "5px" }}>
//               No data found
//             </Typography>
//           )}
//           {showSearch && !searchFilter.length && (
//             <Typography
//               variant="h6"
//               sx={{ textAlign: "center", margin: "5px", padding: "5px" }}>
//               No data found
//             </Typography>
//           )}
//         </Paper>
//         <TablePagination
//           rowsPerPageOptions={[10]}
//           component="div"
//           count={totalCount}
//           page={page}
//           style={{ position: "sticky", overflow: "visible" }}
//           onPageChange={handleChangePage}
//           rowsPerPage={rowsPerPage}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//         />

//         <Box className="add-icon">
//           <AddNotice
//             loader={loader}
//             // load={loading}

//             open={open}
//             handleOpen={handleOpen}
//             handleClose={handleClose}
//             handleSubmit={handleSubmit}
//             updateNotice={updateNotice}
//             handleUpdate={handleUpdate}
//           />
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default Notice;
