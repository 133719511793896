import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { del, get, post, put } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import { Delete } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SquareIcon from "@mui/icons-material/Square";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  Cell,
  Bar,
  ResponsiveContainer,
} from "recharts";
import { red } from "@mui/material/colors";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 500,
  bgcolor: "background.paper",
  p: 4,
  overflowY: "scroll",
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const LibraryReport = () => {
  const [formData, setFormData] = useState([]);
  const [fromDate, setFromDate] = useState(Date.now());
  const [toDate, setToDate] = useState(Date.now());
  const [academicYears, setAcademicYears] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const colors = ["#FFBB28", "#00C49F", "#32CD32"];
  const [groupBy, setGroupBy] = useState("");
  const [section, setSection] = useState("");
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        const resSections = await get(urls.schoolSection.getAllSection, {
          params: {
            search: {
              class: res.data.data[0]._id,
            },
          },
        });
        const searchStudents = await get(urls.students.getAllStudent, {
          params: {
            search: {
              academicYear: formData.academicYear,
              "academicInfo.class": res.data.data[0]._id,
              "academicInfo.section": resSections.data.data[0]._id,
            },
          },
        });
        setSections(resSections.data.data);
        setClasses(res.data.data);
        setFormData((prev) => ({
          ...prev,
          className: res.data.data[0]._id,
          section: resSections.data.data[0]._id,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, []);
  const handleClassChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    try {
      const section = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      if (section.data.data.length > 0) {
        setFormData((prev) => ({
          ...prev,
          section: section.data.data[0]._id,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          section: "",
        }));
      }
      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleGroupByChange = (event) => {
    const selectedGroupBy = event.target.value;
    setGroupBy(selectedGroupBy);
    setSection("");
  };

  const handleSectionChange = (event) => {
    const selectedSection = event.target.value;
    setSection(selectedSection);
    setGroupBy(selectedSection);
  };

  const pieData = [
    { name: "return", value: 7 },
    { name: "issue", value: 10 },
    { name: "remain", value: 3 },
  ];
  const getColor = (index) => colors[index % colors.length];
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll);
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data[0]._id,
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, []);
  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const styles = {
    cardsMargin: {
      marginTop: "15px",
    },
    cardStyle: {
      margin: "15px",
      padding: "10px",
    },

    card: {
      padding: 15,
      marginBottom: 20,
      width: "100%",
      marginTop: "15px",
    },
    card1: {
      marginBottom: 20,
      marginTop: "15px",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    Button: {
      background: "#1b3779",
      color: "#fff",
      ":hover": { background: "#1b3779" },
      marginLeft: "10px",
      marginTop: "5px",
    },
  };
  return (
    <div>
      <div style={{ margin: "15px" }}>
        <Card sx={{ padding: "10px" }}>
          <span
            style={{
              color: "red",
              fontSize: "large",
              fontFamily: "cursive",
            }}
          >
            Library Report:
          </span>
        </Card>
        <Card style={styles.cardsMargin}>
          <Box>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab label="Tabular Report" {...a11yProps(0)} />
              <Tab label="Grapical Report" {...a11yProps(1)} />
            </Tabs>
          </Box>
        </Card>
        <TabPanel value={tabValue} index={0}>
          <Card style={styles.card}>
            <Box>
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} lg={3}>
                    <FormControl
                      fullWidth
                      variant="standard"
                      sx={{ m: 1, minWidth: 227 }}
                      required
                    >
                      <InputLabel>Academic Year</InputLabel>
                      <Select
                        label="Academic Year"
                        id="demo-simple-select-filled"
                        name="academicYear"
                        variant="standard"
                        value={formData.academicYear || ""}
                        onChange={handleOnChange}
                        size="small"
                      >
                        {academicYears &&
                          academicYears.map((row, index) => (
                            <MenuItem key={row._id} value={row._id}>
                              {row.academicYearFrom} - {row.academicYearTo}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 227 }}
                    >
                      <InputLabel required sx={{ fontSize: 12 }}>
                        Group By Data
                      </InputLabel>
                      <Select
                        labelId="demo-simpless-select-filled-label"
                        id="demo-simple-select-filled-l"
                        label="Group By Data"
                      >
                        <MenuItem value={"daily"}>Daily</MenuItem>
                        <MenuItem value={"weekly"}>Weekly</MenuItem>
                        <MenuItem value={"monthly"}>Monthly</MenuItem>
                        <MenuItem value={"yearly"}>Yearly</MenuItem>
                        <MenuItem value={"class"}>Class</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3} alignSelf="center">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={2}>
                        <DatePicker
                          label="From Date"
                          inputFormat="DD-MM-YYYY"
                          value={fromDate || ""}
                          onChange={setFromDate}
                          renderInput={(params) => (
                            <TextField variant="standard" {...params} />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3} alignSelf="center">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={2}>
                        <DatePicker
                          label="To Date"
                          value={toDate || ""}
                          onChange={setToDate}
                          inputFormat="DD-MM-YYYY"
                          renderInput={(params) => (
                            <TextField variant="standard" {...params} />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Stack spacing={2} direction="row">
                  <Button variant="contained" style={styles.Button}>
                    Find
                  </Button>
                  <Button variant="contained" style={styles.Button}>
                    Print
                  </Button>
                </Stack>
              </form>
            </Box>
          </Card>
          <Table>
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  Academic Year
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Group By Data
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Issue Total
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Return Total
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Remain Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">1</TableCell>

                <TableCell align="center">2023</TableCell>

                <TableCell align="center"></TableCell>
                <TableCell align="center">5</TableCell>
                <TableCell align="center">4</TableCell>
                <TableCell align="center">1</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="start" colSpan={3}>
                  Group By Data:
                </TableCell>

                <TableCell align="center">5</TableCell>
                <TableCell align="center">4</TableCell>
                <TableCell align="center">1</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Card style={styles.card}>
            <Box>
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} lg={3}>
                    <FormControl
                      fullWidth
                      variant="standard"
                      sx={{ m: 1, minWidth: 227 }}
                      required
                    >
                      <InputLabel>Academic Year</InputLabel>
                      <Select
                        label="Academic Year"
                        id="demo-simple-select-filled"
                        name="academicYear"
                        variant="standard"
                        value={formData.academicYear || ""}
                        onChange={handleOnChange}
                        size="small"
                      >
                        {academicYears &&
                          academicYears.map((row, index) => (
                            <MenuItem key={row._id} value={row._id}>
                              {row.academicYearFrom} - {row.academicYearTo}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 227 }}
                    >
                      <InputLabel required sx={{ fontSize: 12 }}>
                        Group By Data
                      </InputLabel>
                      <Select
                        labelId="demo-simpless-select-filled-label"
                        id="demo-simple-select-filled-l"
                        label="Group By Data"
                        value={groupBy}
                        onChange={handleGroupByChange}
                      >
                        <MenuItem value={"daily"}>Daily</MenuItem>
                        <MenuItem value={"weekly"}>Weekly</MenuItem>
                        <MenuItem value={"monthly"}>Monthly</MenuItem>
                        <MenuItem value={"yearly"}>Yearly</MenuItem>
                        <MenuItem value={"class"}>class</MenuItem>
                      </Select>
                    </FormControl>

                    {groupBy === "class" && (
                      <FormControl
                        variant="standard"
                        sx={{ m: 1, minWidth: 227 }}
                      >
                        <InputLabel required sx={{ fontSize: 12 }}>
                          Class
                        </InputLabel>
                        <Select
                          name="className"
                          onChange={handleAddForm}
                          value={formData.className || ""}
                          labelId="demo-simpless-select-filled-label"
                          id="demo-simple-select-filled-l"
                          label="Class"
                        >
                          {classes &&
                            classes.map((row, index) => (
                              <MenuItem key={row._id} value={row._id}>
                                {row.className}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={12} md={4} lg={3} sx={{ alignSelf: "center" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={2}>
                        <DatePicker
                          label="From Date"
                          inputFormat="DD-MM-YYYY"
                          value={fromDate || ""}
                          onChange={setFromDate}
                          renderInput={(params) => (
                            <TextField variant="standard" {...params} />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3} sx={{ alignSelf: "center" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={2}>
                        <DatePicker
                          label="To Date"
                          value={toDate || ""}
                          onChange={setToDate}
                          inputFormat="DD-MM-YYYY"
                          renderInput={(params) => (
                            <TextField variant="standard" {...params} />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Stack spacing={2} direction="row">
                  <Button variant="contained" style={styles.Button}>
                    Find
                  </Button>
                  <Button variant="contained" style={styles.Button}>
                    Print
                  </Button>
                </Stack>
              </form>
            </Box>
          </Card>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Card sx={{ maxWidth: 420, mt: 3 }}>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img src="/Logo.png" height={30} width={30} />
                  <Typography
                    gutterBottom
                    sx={{ color: "red", fontSize: "7px", mt: 1.2, ml: 0.5 }}
                  >
                    Excellent English Medium Primary & High School
                  </Typography>
                </div>
                <Typography
                  gutterBottom
                  variant="h7"
                  sx={{ textAlign: "center", mt: 2 }}
                  component="div"
                >
                  Excellent English Medium Primary & High School
                </Typography>
                <Typography
                  variant="h11"
                  sx={{ textAlign: "center" }}
                  component="div"
                >
                  Ittangihal road, Vijaypur, Karnataka 586103
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "3px",
                  }}
                >
                  <AssessmentIcon style={{ color: "#1b3779" }} />
                  <Typography
                    style={{ color: "red" }}
                    variant="h8"
                    component="div"
                  >
                    Libary Report
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <PieChart width={1000} height={380}>
              <Pie
                dataKey="value"
                isAnimationActive={false}
                data={pieData}
                outerRadius={150}
                label
              >
                {pieData.map((entry, index) => (
                  <Cell key={index} fill={colors[index % colors.length]} />
                ))}
              </Pie>

              <Tooltip />
            </PieChart>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <b>Year:</b>
            <p>2023</p>
            <SquareIcon
              style={{ color: "#FFBB28", fontSize: "20px", marginLeft: "5px" }}
            />
            <p>Return</p>
            <SquareIcon
              style={{ color: "#00C49F", fontSize: "20px", marginLeft: "5px" }}
            />
            <p> Issue</p>
            <SquareIcon
              style={{ color: "#32CD32", fontSize: "20px", marginLeft: "5px" }}
            />
            <p>Remain</p>
          </div>
        </TabPanel>
      </div>
    </div>
  );
};

export default LibraryReport;
