import React, { useState } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid,
  Card,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TableHead,
  Tooltip,
  Switch,
} from "@mui/material";
import { useEffect, useContext } from "react";
import { urls as url } from "../../../services/urlConstant";
import "../../../styles/components/Exam/Exam.scss";
import { get, put } from "../../../services/apis";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../../components/SectionSubHeader";
import Wrapper from "../../Wrapper";

const styles = {
  card: {
    padding: 10,
    width: "100%",
    marginBottom: "10px",
    marginTOp: 20,
  },
  notfoundStyle: {
    minHeight: "74vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
    color: "white",
  },

  textfield: {
    width: "100%",
    label: { fontSize: 12 },
    mt: 1,
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
  },
};

const MarksCard = () => {
  const { selectedSetting } = useContext(SettingContext);

  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [academicYears, setAcademicYears] = useState([]);
  const [marksCardDetails, setMarksCardDetails] = useState({});
  const [exams, setExams] = useState([]);
  const [btnLoading, setBtnLoading] = useState("");
  const [loader, setLoader] = useState(false);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  useEffect(() => {
    const onAcademicYearChange = async () => {
      try {
        if (marksCardDetails.academicYear) {
          const getExams = await get(urls.examTerm.getAllExamTerm, {
            params: {
              search: {
                academicYear: marksCardDetails.academicYear,
              },
            },
          });
          if (getExams.data.success) {
            setExams(getExams.data.data);
          } else {
            console.log(getExams);
          }
        }
      } catch (error) {}
    };
    onAcademicYearChange();
  }, [marksCardDetails.academicYear, selectedSetting]);

  const handleClasses = async (e) => {
    try {
      const { name, value } = e.target;
      setMarksCardDetails({ ...marksCardDetails, [name]: value });
      const res = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: value,
          },
        },
      });
      setSections(res.data.data);
      setMarksCardDetails((prev) => ({
        ...prev,
        section: "",
      }));
    } catch (error) {
      console.log(error);
    }
  };
  const handleSections = (e) => {
    const { name, value } = e.target;
    setMarksCardDetails({ ...marksCardDetails, [name]: value });
  };

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        setClasses(res.data.data);
        const schoolClass = Array.isArray(res.data.data) && res.data.data[0];
        if (schoolClass) {
          setMarksCardDetails((prev) => ({
            ...prev,
            class: schoolClass._id,
          }));
          const res = await get(urls.schoolSection.getAllSection, {
            params: {
              search: {
                class: schoolClass._id,
              },
            },
          });
          setSections(res.data.data);
          const section = Array.isArray(res.data.data) && res.data.data[0];
          if (section) {
            setMarksCardDetails((prev) => ({
              ...prev,
              section: section._id,
            }));
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, [selectedSetting]);
  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll);
        if (res.data.success) {
          setAcademicYears(res.data.data);
          const academicYear = Array.isArray(res.data.data) && res.data.data[0];
          if (academicYear) {
            setMarksCardDetails((prev) => ({
              ...prev,
              academicYear: academicYear._id,
            }));
          }
        } else {
          console.log(res);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, [selectedSetting]);
  const handleSubmit = async (e) => {
    setLoader(true);
    console.log("reach");
    e.preventDefault();
    try {
      const getExams = await get(urls.examTerm.getAllExamTerm, {
        params: {
          search: {
            academicYear: marksCardDetails.academicYear,
          },
        },
      });
      if (getExams.data.success) {
        setExams(getExams.data.data);
      } else {
        console.log(getExams);
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(true);
  };
  const handleGetMarksCard = async (examId) => {
    try {
      if (
        marksCardDetails.academicYear &&
        marksCardDetails.class &&
        marksCardDetails.section
      ) {
        setBtnLoading(examId);
        const getMarksCards = await get(
          `${selectedSetting._id}/students-marks-cards/${marksCardDetails.class}/${marksCardDetails.section}/${marksCardDetails.academicYear}/${examId}`,
          {
            responseType: "blob",
          }
        );
        const downloadUri = URL.createObjectURL(getMarksCards.data);
        window.open(downloadUri, "__blank");
        setBtnLoading("");
      } else {
        toast.error("Please fill all fields");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleWebPublish = async (id, publish, checked) => {
    try {
      if (
        !(
          marksCardDetails.class &&
          marksCardDetails.section &&
          marksCardDetails.academicYear
        )
      )
        return toast.error("Please select all fields");
      if (checked) {
        const { status } = await put(
          `${selectedSetting._id}/publishweb/${id}`,
          "",
          {
            class: marksCardDetails.class,
            section: marksCardDetails.section,
            academicYear: marksCardDetails.academicYear,
          }
        );
        if (status > 199 && status < 299) {
          setExams((exam) =>
            exam.map((item) =>
              item._id === id
                ? {
                    ...item,
                    publishWeb: item.publishWeb
                      ? [
                          ...item.publishWeb,
                          {
                            class: marksCardDetails.class,
                            section: marksCardDetails.section,
                            academicYear: marksCardDetails.academicYear,
                          },
                        ]
                      : [
                          {
                            class: marksCardDetails.class,
                            section: marksCardDetails.section,
                            academicYear: marksCardDetails.academicYear,
                          },
                        ],
                  }
                : item
            )
          );
        }
      } else {
        const { status } = await put(`/un-publishweb/${id}`, "", {
          class: marksCardDetails.class,
          section: marksCardDetails.section,
          academicYear: marksCardDetails.academicYear,
        });
        if (status > 199 && status < 299) {
          setExams((exam) =>
            exam.map((item) =>
              item._id === id
                ? {
                    ...item,
                    publishWeb: item.publishWeb.filter(
                      (allowed) =>
                        allowed.class !== marksCardDetails.class &&
                        allowed.section !== marksCardDetails.section &&
                        allowed.academicYear !== marksCardDetails.academicYear
                    ),
                  }
                : item
            )
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleParentsPublish = async (id, publish) => {
    try {
      const { status } = await put(`${urls.examTerm.putExamTerm}${id}`, "", {
        publishToParents: !publish,
      });
      console.log("status ", status);
      if (status > 199 && status < 299) {
        setExams((exams) =>
          exams.map((exam) =>
            id === exam._id ? { ...exam, publishToParents: !publish } : exam
          )
        );
      }
    } catch (error) {}
  };
  return (
    <Wrapper>
      <SectionSubHeader title=" Marks Card " />

      <div>
        <form onSubmit={handleSubmit}>
          <Card sx={{ margin: "20px 0", padding: "10px" }}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4} lg={4}>
                  <FormControl size="small" sx={styles.textfield} required>
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Academic Year
                    </InputLabel>
                    <Select
                      label="Academic Year"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="academicYear"
                      value={marksCardDetails.academicYear || ""}
                      onChange={(event) => handleSections(event)}
                    >
                      {academicYears &&
                        academicYears.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.academicYearFrom} - {row.academicYearTo}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <FormControl size="small" sx={styles.textfield} required>
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Class
                    </InputLabel>
                    <Select
                      label="Class"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="class"
                      value={marksCardDetails.class || ""}
                      onChange={(event) => handleClasses(event)}
                    >
                      {classes &&
                        classes.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.className}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <FormControl size="small" sx={styles.textfield} required>
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Section
                    </InputLabel>
                    <Select
                      label="Section"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="section"
                      value={marksCardDetails.section || ""}
                      onChange={(event) => handleSections(event)}
                    >
                      {sections &&
                        sections.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.sectionName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </form>
      </div>
      {!exams ? (
        <div style={styles.notfoundStyle}>
          <img style={{ height: 241 }} src="./record.png" alt="notfound" />
        </div>
      ) : (
        <div style={{ margin: "15px 0" }}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead style={{ background: "rgb(27 55 121)" }}>
                  <TableRow>
                    <TableCell align="center">
                      <span className="class-table-header">#SL</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Type</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">
                        Published to web
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">
                        Published to app
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Action</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {exams &&
                    exams.map((exam, index) => (
                      <TableRow
                        key={exam._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {exam.examTitle}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <Switch
                            onChange={(e) =>
                              handleWebPublish(
                                exam._id,
                                exam.publishToWeb,
                                e.target.checked
                              )
                            }
                            checked={
                              Array.isArray(exam.publishWeb) &&
                              exam.publishWeb.some(
                                (item) =>
                                  item.class === marksCardDetails.class &&
                                  item.section === marksCardDetails.section &&
                                  item.academicYear ===
                                    marksCardDetails.academicYear
                              )
                            }
                          />
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <Switch
                            onChange={() =>
                              handleParentsPublish(
                                exam._id,
                                exam.publishToParents
                              )
                            }
                            checked={exam.publishToParents}
                          />
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <LoadingButton
                            onClick={() => handleGetMarksCard(exam._id)}
                            variant="contained"
                            size="small"
                            loading={btnLoading === exam._id}
                            sx={{
                              backgroundColor: "#1b3779",
                              ":hover": { backgroundColor: "#1b3779" },
                            }}
                          >
                            Marks Cards
                          </LoadingButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      )}
    </Wrapper>
  );
};

export default MarksCard;
