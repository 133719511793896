import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Dialog,
  Fab,
  Grid,
  styled,
  TextareaAutosize,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";

// const ContainerBox = styled(Box)(({ theme }) => ({
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "100%",
//   maxWidth: "600px",
//   maxHeight: "650px",
//   borderRadius: "5px",
//   height: "auto",
//   // overflow:"scroll",
//   backgroundColor: "white",
//   padding: "20px",
//   // border:"solid red",
//   [theme.breakpoints.down("md")]: {
//     overflow: "auto",
//     width: "90%",
//   },
//   [theme.breakpoints.between(819, 821)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.between(767, 769)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.between(911, 913)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.between(1023, 1025)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.between(1279, 1281)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "100%",
//     height: "100%",
//     overflow: "auto",
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "center",
//   },
// }));
const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 4,
};
const AddDepartment = (props) => {
  const [department, setDepartment] = useState("");
  const [orderSequence, setOrderSequence] = useState(0);
  const [note, setNote] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    if (props.updateDepartment) {
      setDepartment(props.updateDepartment.department);
      setOrderSequence(
        props.updateDepartment.orderSequence
          ? props.updateDepartment.orderSequence
          : ""
      );
      setNote(props.updateDepartment.note);
    }
  }, [props.updateDepartment]);

  const handleDepartment = (e) => {
    setDepartment(e.target.value);
  };

  const handleOrderSequence = (e) => {
    setOrderSequence(e.target.value);
  };

  const handleDepartmentNote = (e) => {
    setNote(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (props.updateDepartment) {
      await props.handleUpdate(
        {
          department,
          orderSequence,
          note,
        },
        props.updateDepartment._id
      );
    } else {
      await props.handleSubmit(e, {
        department,
        orderSequence,
        note,
      });
    }
    setDepartment("");
    setOrderSequence();
    setNote("");
    props.handleClose();
  };

  const handleCloseModal = () => {
    setDepartment("");
    setOrderSequence();
    setNote("");
    props.handleClose();
  };
  const handleClose = () => {
    setDepartment("");
    setOrderSequence();
    setNote("");
    props.handleClose();
  };
  return (
    <div>
      <Tooltip title="Add" disableInteractive>
        <Fab
          size="medium"
          style={{ background: "#1b3779", color: "#fff" }}
          aria-label="add"
          onClick={props.handleOpen}
        >
          <Add />
        </Fab>
      </Tooltip>

      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleSubmit}>
          <Box sx={style}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  textAlign="center"
                  fontSize="20px"
                  fontWeight="bold"
                >
                  Add Department
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  required
                  id="filled-required"
                  size="small"
                  label="Department"
                  value={department}
                  onChange={handleDepartment}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  required
                  type="number"
                  id="filled-required"
                  size="small"
                  label="Order Sequence"
                  value={orderSequence}
                  onChange={handleOrderSequence}
                />
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <TextareaAutosize
                  maxRows={4}
                  aria-label="maximum height"
                  placeholder="Drop a note"
                  value={note}
                  onChange={handleDepartmentNote}
                  style={{
                    // width: 712,
                    width: "100%",
                    maxHeight: 110,
                    padding: 10,
                    minHeight: 118,
                    borderRadius: "5px",
                  }}
                />
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: { md: "right", sm: "center", xs: "center" },
                  }}
                >
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    size="medium"
                    type="button"
                    color="error"
                    sx={{
                      m: 1,
                      color: "#ffff",
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={props.loader}
                    disabled={!department}
                    size="medium"
                    type="submit"
                    style={{ background: "#1b3779", color: "#fff" }}
                  >
                    Submit
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Dialog>
    </div>
  );
};

export default AddDepartment;
