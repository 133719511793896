export const urls = {
  settings: {
    getSettings: "/setting/",
    getAllSettings: "/settings",
    postSettings: "/setting",
    putSettings: "/setting/",
    toggleStatus: "/update/active-status/",
  },
  administrator: {
    academicYearGet: "/academic-year/",
    academicYearGetAll: "/academic-year",
    academicYearPost: "/academic-year",
    academicYearPut: "/academic-year/",
    academicYearDel: "/academic-year/",
    academicYearActive: "/active/academic-year/",
  },
  department: {
    getDepartment: "/department",
    postDepartment: "/department",
    putDepartment: "/department/",
    delDepartment: "/department/",
  },
  designation: {
    getAllDesignation: "/designation",
    getDesignation: "/designation/",
    postDesignation: "/designation",
    putDesignation: "/designation/",
    delDesignation: "/designation/",
  },
  class: {
    getClass: "/class/",
    getAllClass: "/class",
    postClass: "/class",
    putClass: "/class/",
    delClass: "/class/",
    getClassRoutineWeek: "/class-routine/class",
  },
  schoolSubject: {
    getSubject: "/subject/",
    getAllSubject: "/subject",
    postSubject: "/subject",
    putSubject: "/subject/",
    delSubject: "/subject/",
  },
  schoolSection: {
    getSection: "/section/",
    getAllSection: "/section",
    postSection: "/section",
    putSection: "/section/",
    delSection: "/section/",
  },
  employee: {
    getEmployee: "/employee/",
    getEmployeeId: "/employeebyid/",
    postEmployee: "/employee",
    putEmployee: "/employee/",
    delEmployee: "/employee/",
    getAllEmployee: "/employee",
    getCurrentEmployee: "/employee/current/",
    inactiveemployee: "/inactiveemployee",
    updateEmployeeAttendanceBulk: "/employee-attendance-bulk",
    createIfNotExists: "/emp-att-test",
  },
  examGrade: {
    getExamGrade: "/examgrade",
    postExamGrade: "/examgrade",
    putExamGrade: "/examgrade/",
    delExamGrade: "/examgrade/",
  },
  examTerm: {
    getExamTerm: "/examterm/",
    getAllExamTerm: "/examterm",
    postExamTerm: "/examterm",
    putExamTerm: "/examterm/",
    delExamTerm: "/examterm/",
  },
  examSchedule: {
    getExamSchedule: "/examschedule/",
    getAllExamSchedule: "/examschedule",
    postExamSchedule: "/examschedule",
    putExamSchedule: "/examschedule/",
    delExamSchedule: "/examschedule/",
    getGenHallTicket: "/hallticket/exam-schedule/exam=term/",
  },
  examAttendance: {
    postExamAttendance: "/examattendance",
    putExamAttendance: "/examattendance/",
    putExamAttendancePresent: "/examattendance",
    putExamAttendanceAbsent: "/examattendance",
    delExamAttendance: "/examattendance/delete/",
  },
  role: {
    getAllRole: "/role",
    postRole: "/role",
    putRole: "/role/",
    delRole: "/role/",
    putPermissionRole: "",
    getAccessRole: "",
  },
  students: {
    getAllStudent: "/student",
    getStudent: "/student/",
    getBulkUpdate: "/bulk-update/student",
    getBulkAdmitSheet: "/bulk-admit/student",
    postStudent: "/student",
    postBulkAdmit: "/bulk-admit/student",
    putStudent: "/student/",
    putStudentResuffle: "/reshuffle/student",
    putStudentPromo: "/promote/student",
    putBulkStudentPromo: "/bulk-promote",
    putBulkUpdateStudent: "/bult-update/student",
    delStudent: "/student/",
    getActivestudentOverview: "/activestudents-overview",
    studentsResuffleListExcel: "/student-resuffle-list-excel",
    updateStudentResuffle: "/student-resuffle",
    getStudentsListPdf: "/students-list-pdf",
    getStudentsListBySectionPdf: "/students-list-sections",
    getStudentsListExcel: "/students-list-excel",
    getStudentAttendanceDetailsOverview: "/students-attendance-overview",
    getAbsentAttendanceDetailsPdf: "/students-absent-pdf",
    getStudentAttendanceReport: "/student-attendance-report",
    getStudentsMonthlyAttendanceReport: "/students-attendance-pdf",
    updateStudentAttendanceBulk: "/update-bulk",
    studentRoutineList: "/student-dashboard/period-routine/list",
    updateStudentAttendanceByDate: "/student-attendace/date",
    getDashboardStudentAttendenceList: "/student-dashboard/attendance/list",
  },
  fileUpload: {
    postFileUpload: "/file-upload",
  },
  announceNews: {
    getNews: "/news",
    getNewsHome: "/news/home",
    postNews: "/news",
    putNews: "/news/",
    delNews: "/news/",
  },
  anounceHoliday: {
    getHoliday: "/holiday",
    postHoliday: "/holiday",
    putHoliday: "/holiday/",
    delHoliday: "/holiday/",
  },
  announceNotice: {
    getNotice: "/notice",
    postNotice: "/notice",
    putNotice: "/notice/",
    delNotice: "/notice/",
  },
  announceFlashNews: {
    getFlashNews: "/splashNews/all",
    postFlashNews: "/splashNews/create",
    putFlashNews: "/splashNews/update/",
    delFlashNews: "/splashNews/remove/",
  },
  classRoutine: {
    getAllClassRoutine: "/class-routine",
    getClassRoutine: "/class-routine/",
    postClassRoutine: "/class-routine",
    putClassRoutine: "/class-routine/",
    delClassRoutine: "/class-routine/",
  },
  CategoryList: {
    getAllCategoryList: "/student-category/all",
    getCategoryList: "/student-category",
    postCategoryList: "/student-category/new",
    putCategoryList: "/student-category/edit/",
    delCategoryList: "/student-category",
    toggleCategoryActive: "/student-category/toggle/activeStatus/",
    toggleCategoryFavorite: "/student-category/toggle/favoriteStatus/",
  },
  feeCategory: {
    getAllFeeCategory: "/fee-category/all",
    postFeeCategory: "/fee-category/new",
    editFeeCategory: "/fee-category/update/",
    toggleFeeCategoryFavorite: "/fee-category/toggle/favoriteStatus/",
    deleteFeeCategory: "/fee-category/remove/",
    deleteMultipleFeeCategory: "/fee-category/multiple",
    changePriority: "/fee-category/chage-priority/",
  },
  recepitBook: {
    create: "/receipt-bookItem/new",
    getAll: "/receipt-bookItem/all",
    getAllActiveReceipt: "/receipt-bookItem/active/all",
    update: "/receipt-bookItem/update/name/",
    status: "/receipt-bookItem/update/activeStatus/",
    categories: "/fee-receipt/fee-categories/",
    getAllActive: "/receipt-bookItem/active/all",
  },
  balanceFeereceipt: {
    getAll: "/fee-receipt/all/balance/receipts",
    getAllSection: "/fee-receipt/all/sections/balance/receipts",
  },
  studentsFeeOverview: {
    getAll: "/fee-receipt/all/receipts",
    getAllSection: "/fee-receipt/all/sections/receipts",
    getAmountCollectedWithDifferentModes:
      "/fee-receipt/all/amount-collected/modes/all",
    getAmountCollectedWithDifferentModesForAllSec:
      "/fee-receipt/all/amount-collected/all-sections/modes/all",
  },
  classMap: {
    create: "/class-map/new/",
    list: "/class-map/all/",
    getAll: "/class-map/all-classMaps",
    update: "/class-map/edit/",
    status: "/class-map/toggle/activeStatus/",
  },
  feeReceipt: {
    dueDetails: "/fee-receipt/student/due-details",
    create: "/fee-receipt/new",
    detailFee: "/fee-receipt/student/detailed/due-details",
    allPastDue: "/fee-receipt/allpastdue",
    preview: "/fee-receipt/preview",
    getPaidWithCheque: "/fee-receipt/paymentmode/cheque",
    updateSingleReconsollationStatus:
      "/fee-receipt/update/single/reconsolation/status/",
    updateMultiReconsollationStatus: "/fee-receipt/update/reconsolation/status",
  },
  feeRemittance: {
    totalAmount: "/fee-remittance/total-amount",
    create: "/fee-remittance/new",
    getAll: "/fee-remittance/all",
  },
  hostel: {
    create: "/hostel/new",
    editHostel: "/hostel/update/",
    getList: "/hostel/list",
    delHostel: "/hostel/remove/",
  },
  room: {
    create: "/room/new",
    editRoom: "/room/update/",
    getList: "/room/list",
    delRoom: "/room/remove/",
  },
  roomType: {
    create: "/room-type/new",
    getList: "/room-type/list",
    getById: "/room-type/details/",
    editRoomType: "/room-type/edit/",
    delRoom: "/room-type/delete/",
  },
  hostelMember: {
    editMember: "/hostel-member/update",
    deleteMember: "/hostel-member/delete",
  },
  book: {
    create: "/library/new",
    getList: "/library/list",
    editBook: "/library/update/",
    delBook: "/library/delete/",
  },
  periodical: {
    create: "/periodical/new",
    getList: "/periodical/list",
    editPerdiodical: "/periodical/update/",
    delPeriodical: "/periodical/delete/",
  },
  libraryStudentMember: {
    editMember: "/library-member/update",
    delMember: "/library-member/delete",
  },

  libraryEmpMember: {
    editMember: "/library-empmember/update",
    delMember: "/library-empmember/delete",
  },
  issueList: {
    create: "/issuereturn/new",
    get: "/issuereturn/list",
    getdue: "/issuereturn/duelist",
    edit: "/issuereturn/update",
    submitBook: "/issuereturn/submitbook",
    getHistory: "/issuereturn/listbydate",
    download: "/issuereturn/download/excel",
  },

  empIssueList: {
    create: "/empissue/new",
    get: "/empissue/list",
    getdue: "/empissue/duelist",
    edit: "/empissue/update",
    submitBook: "/empissue/submitbook",
    getHistory: "/empissue/listbydate",
    download: "/empissue/download/excel",
  },
  visitorInfo: {
    create: "/visitorinfo/new",
    getList: "/visitorinfo/list",
    update: "/visitorinfo/update/",
    delete: "/visitorinfo/delete/",
    downloadPdf: "/downloadpdf",
    downloadexcel: "/downloadexcel",
  },
  studentCheckOut: {
    create: "/visitorstu/new",
    getList: "/visitorstu/list",
    update: "/visitorstu/update/",
    delete: "/visitorstu/delete/",
  },
  transport: {
    create: "/vehicle/new",
    getById: "/vehicle/details/",
    update: "/vehicle/update",
    getList: "/vehicle/list",
    deletVehicle: "/vehicle/remove/",
  },
  route: {
    create: "/route/new",
    getById: "/route/details/",
    update: "/route/update/",
    getList: "/route/list",
    deletRoute: "/route/remove/",
  },
  vehicleFuel: {
    create: "/vehiclefuel/new",
    getList: "/vehiclefuel/list",
  },
  firm: {
    create: "/firm/new",
    getList: "/firm/list",
    update: "/firm/update/",
    delFirm: "/firm/remove/",
  },

  repair: {
    create: "/repair/new",
    getList: "/repair/list",
  },
  tyre: {
    create: "/tyre/new",
    getList: "/tyre/list",
  },
  greecing: {
    create: "/greecing/new",
    getList: "/greecing/list",
  },
  transportMember: {
    editMember: "/transport-member/update",
    deleteMember: "/transport-member/delete",
  },

  vehicleLog: {
    create: "/vehicleLog/new",
    getList: "/vehicleLog/list",
    update: "/vehicleLog/update/",
    delete: "/vehicleLog/remove/",
    downloadExcel: "/vehiclelog/downloadpdf",
  },
  assignment: {
    createAssignment: "/assignment",
    getAssignment: "/assignment",
    deleteAssignment: "/assignment",
    updateAssignment: "/assignment",
  },
  birthday: {
    getBirthday: "/birthday",
    createBirthday: "/birthday",
    deleteBirthday: "/birthday",
    getStudentBirthday: "/student-birthday",
  },
  certificates: {
    getStudyCertificate: "/study-certificate",
    getTransferCertificate: "/transfer-certificate",
    getBulkCertificate: "/bulkstudy-certificate",
  },
  events: {
    createEvent: "/event/create",
    deleteEvent: "/event/delete",
    updateEvent: "/event/update",
    getEventList: "/event/list",
    getEventListHome: "/event/list/home-page",
    getSingleEventList: "/event/list/single",
  },
  gallery: {
    createGallery: "/gallery",
    getAllGalleries: "/gallery",
    deleteGallery: "/gallery",
    updateGallery: "/gallery",
  },
  password: {
    resetUserPassword: "/reset-user-password",
    resetPasswordForUser: "/resetuser-password",
    forgotPassword: "/forgot-password",
    sendForgotPasswordCode: "/forgot-password-send-code",
    resetPassword: "/reset-password",
  },
  sms: {
    getAllSms: "/sms",
    getSmsReport: "/sms-report",
    sendEmployeesCredentials: "/sendsms-employee-credentails",
    createTemplate: "/template-sms",
    getAllTemplates: "/template-sms",
    sendSmsToSingle: "/sendsms-single",
    sendSmsToStudents: "/sendsms-students",
    getSmsSample: "/sample-file",
    reSendFailedSms: "/sms-resend",
  },
  award: {
    getAwardList: "/award/list",
    getSingleAwardList: "/award/list/single",
    deleteAward: "/award/delete/",
    createAward: "/award/create",
    updateAward: "/award/update/",
  },
  home: {
    getHome: "/home",
    getHomeSettings: "/home-setting",
    getHomePage: "/home-page",
  },
  leaveType: {
    create: "/leavetype",
    getList: "/leavetype",
    update: "/leavetype",
    delete: "/leavetype",
  },
  salaryGrade: {
    create: "/salaryGrade/create",
    getList: "/salaryGrade/all",
    update: "/salaryGrade/",
    delete: "/salaryGrade/",
  },
  makePayment: {
    getAll: "/paymenthistory",
  },
  register: {
    create: "/register/new",
    getAll: "/register/all",
    remove: "/register/remove/",
  },
  studentCheckOuting: {
    create: "/visitorstudent/new",
    getAll: "/visitorstudent/list",
    update: "/visitorstudent/update",
    remove: "/visitorstudent/delete",
    downloadExcel: "/visitorstudent/downloadexcel",
  },
  employeeLeaveApplication: {
    totalcount: "/leave-application/totalleave",
    createLeave: "/leave-application/employee/new",
    getAllLeaves: "/leave-application/all",
    getMyLeaves: "/leave-application/my",
    approveLeave: "/leave-application/approve",
    rejectLeave: "/leave-application/reject",
    downloadExcel: "/leave-application/downloadexcel",
    downloadPdf: "/leave-application/downloadpdf",
  },
  communication: {
    sendStudentCredentials: "/smsV2/student-credentials",
    getBalance: "/smsV2/balance",
    getDeliveryReport: "/smsV2/delivery-report/",
    getAllSms: "/smsV2/all",
    delete: "/smsV2/",
    sendSmsToRole: "/smsV2/role",
    sendSmsToSingle: "/smsV2/sendsms-single/",
    sendSmsToStudents: "/smsV2/sendsms-students",
    sendEmployeeCredentials: "/smsV2/sendsms-employee-credentails",
    getSmsReport: "/smsV2/sms-report/",
    resendFailedSms: "/smsV2/sms-resend/",
    getUploadSampleFile: "/smsV2/upload/sample-file",
    sendBulkSms: "/smsV2/bulk-sms",
    sendStudentMarksSms: "/smsV2/send-student-marks",
  },
  inventory: {
    createItem: "/inventory/item/new",
    getAllItem: "/inventory/item/all",
    updateItem: "/inventory/item/",
  },
  inventoryIssue: {
    create: "/inventory/issue/new",
    update: "/inventory/issue/status/",
    getAll: "/inventory/issue/all",
  },
  teacherActivity: {
    createActivity: "/teacher_activity/new",
    getList: "/teacher_activity/list",
    editActivity: "/teacher_activity/update/",
    delActivity: "/teacher_activity/delete/",
  },
  feedback: {
    createFeedback: "/teacher_activity/add/feedback",
    getActivity: "/teacher_activity/my/class/activities",
  },
  studentDashboard: {
    getSubject: "/student-dashboard/my/all-subjects",
    getAssignment: "/student-dashboard/all/assignments/active",
    getNews: "/student-dashboard/news",
    getnotice: "/student-dashboard/notices",
    getInvoice: "/student-dashboard/all/fee-receipts",
    getInvoicepdf: "/student-dashboard/download/fee-receipt",
  },

  inventorySell: {
    create: "/inventory/sell/new",
    update: "/inventory/sell/status/",
    getAll: "/inventory/sell/all",
  },

  vendor: {
    createVendor: "/inventory/vendor/new",
    getAll: "/inventory/vendor/all",
    updateVendor: "/inventory/vendor/",
  },
  transaction: {
    createTransaction: "/inventory/transaction/new",
    getAll: "/inventory/transaction/all",
    totalInventoryCount: "/inventory/transaction/totalInventoryCount",
  },
  stockList: {
    getAll: "/inventory/transaction/stock/data",
  },
  totalInventoryAmount: {
    get: "/inventory/issue/gettotalinventamount",
  },
};
