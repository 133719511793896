// import React, { useContext, useState } from "react";
// import SectionsSubHeader from "../../../components/SectionSubHeader";

// import {
//   Box,
//   Button,
//   Card,
//   FormControl,
//   Grid,
//   TextareaAutosize,
//   InputLabel,
//   MenuItem,
//   Select,
//   Stack,
//   TextField,
//   Typography,
// } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import EditIcon from "@mui/icons-material/Edit";
// import { Delete } from "@mui/icons-material";
// import SettingContext from "../../../context/SettingsContext";
// import AddIcon from "@mui/icons-material/Add";
// import { LoadingButton } from "@mui/lab";
// import dayjs from "dayjs";
// import { get, post, put } from "../../../services/apis";
// import TimezoneSelect from "react-timezone-select";
// import Switch from "@mui/material/Switch";
// import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { urls as url } from "../../../services/urlConstant";
// import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
// import appendStringToUrls from "../../../utils/appendString";
// const styles = {
//   card: {
//     padding: 15,
//     marginBottom: 20,
//     display: "flex",
//     alignItem: "center",
//     justifyContent: "space-between",
//     overflow: "visible",
//   },
//   textfield: {
//     width: "100%",
//     label: { fontSize: 12 },
//   },
//   Button: {
//     background: "#1b3779",
//     ":hover": { background: "#1b3779" },
//     color: "#fff",
//   },
// };
// const AddSetting = () => {
//   const { settings, setSettings, selectedSetting } = useContext(SettingContext);
//   const navigate = useNavigate();
//   const [addDialog, setAddDialog] = useState(false);

//   const [settingDetails, setSettingDetails] = useState(null);
//   const [schoolCreateLogoLink, setSchoolCreateLogoLink] = useState("");
//   const [schoolLogoLink, setSchoolLogoLink] = useState("");
//   const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
//   const [sessionStartMonth, setSessionStartMonth] = useState(dayjs(Date.now()));
//   const [sessionEndMonth, setSessionEndMonth] = useState(dayjs(Date.now()));
//   const [previewUrl, setPreviewUrl] = useState(null);

//   const [previewCreateUrl, setPreviewCreateUrl] = useState(null);
//   const [schoolCreateLogo, setSchoolCreatelLogo] = useState("");
//   const { setSettingsContext } = useContext(SettingContext);
//   const [createDetails, setCreateDetails] = useState({
//     regDate: dayjs(Date.now()),
//   });
//   const [loading, setLoading] = useState(false);
//   const handleCreateOnChange = (e) => {
//     const { name, value } = e.target;
//     setCreateDetails({ ...createDetails, [name]: value });
//   };
//   const handleCreateSubmit = async (e) => {
//     setLoading(true);
//     try {
//       e.preventDefault();
//       const data = {
//         ...createDetails,
//         schoolName: createDetails.schoolName,
//         twitterUrl: createDetails.twitterUrl,
//         linkedinUrl: createDetails.linkedinUrl,
//         gplusUrl: createDetails.gplusUrl,
//         youtubeUrl: createDetails.youtubeUrl,
//         instagramUrl: createDetails.instagramUrl,
//         pinterestUrl: createDetails.pinterestUrl,
//         facebookUrl: createDetails.facebookUrl,
//         address: createDetails.address,
//         phone: createDetails.phone,
//         regDate: dayjs(createDetails.regDate).format("YYYY-MM-DD"),
//         email: createDetails.email,
//         schFax: createDetails.schFax,
//         websiteFooter: createDetails.websiteFooter,
//         description: createDetails.description,
//         logo: createDetails.logo,
//         currency: createDetails.currency,
//         currencySymbol: createDetails.currencySymbol,
//         sessionStartMonth: createDetails.sessionStartMonth,
//         sessionEndMonth: createDetails.sessionEndMonth,
//         rollNumberType: createDetails.rollNumberType,
//         admissionNo: createDetails.admissionNo,
//         longitude: createDetails.longitude,
//         latitude: createDetails.latitude,
//         googleAnalyticsId: createDetails.googleAnalyticsId,
//         defaultTimeZone: createDetails.defaultTimeZone,
//         teacherActivityFeedbackEnabled:createDetails.teacherActivityFeedbackEnabled,
//       };
//       const res = await post(urls.settings.postSettings, data);
//       setSettings([...settings, res.data.data]);
//       setAddDialog(false);
//       resetForm();

//       setSettingsContext((prev) => ({
//         ...prev,
//         schoolName: data.schoolName,
//         schoolLogo: schoolCreateLogoLink,
//       }));
//       setTimeout(() => {
//         setLoading(false);
//       }, 2000);
//     } catch (error) {
//       setLoading(false);

//       console.log(error);
//     }
//   };
//   const resetForm = () => {
//     setCreateDetails({});
//   };
//   const handleCreateOnChangeTime = (d) => {
//     setCreateDetails({ ...createDetails, defaultTimeZone: d });
//   };
//   const handleCreatePhoto = async (e) => {
//     const formData = new FormData();
//     formData.append("files", e.target.files[0]);
//     try {
//       setPreviewCreateUrl(URL.createObjectURL(e.target.files[0]));
//       const res = await post(urls.fileUpload.postFileUpload, formData);
//       setCreateDetails({
//         ...createDetails,
//         logo: res.data.result[0]._id,
//       });
//       setSchoolCreateLogoLink(res.data.result[0].link);
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   return (
//     <div>
//       <form onSubmit={handleCreateSubmit}>
//         <div style={{ margin: 15 }}>
//           <Card style={styles.card}>
//             <>
//               <Grid
//                 container
//                 spacing={2}
//                 sx={{
//                   display: "flex",
//                   justifyContent: "flex-start",
//                   alignItems: "flex-end",
//                 }}>
//                 <Grid item xs={12} md={12} lg={12}>
//                   <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
//                     Basic Information
//                   </Typography>
//                 </Grid>
//                 <Grid item xs={12} md={6} lg={4}>
//                   <TextField
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     required
//                     id="filled-required"
//                     size="small"
//                     name="schoolName"
//                     value={createDetails.schoolName}
//                     onChange={(event) => handleCreateOnChange(event)}
//                     label="School Name"
//                     sx={styles.textfield}
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={6} lg={4}>
//                   <TextField
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     required
//                     id="filled-required"
//                     size="small"
//                     label="Address"
//                     name="address"
//                     value={createDetails.address}
//                     onChange={(event) => handleCreateOnChange(event)}
//                     sx={styles.textfield}
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={6} lg={4}>
//                   <TextField
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     required
//                     id="filled-required"
//                     size="small"
//                     label="Phone"
//                     name="phone"
//                     value={createDetails.phone}
//                     onChange={(event) => handleCreateOnChange(event)}
//                     sx={styles.textfield}
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={6} lg={4}>
//                   <TextField
//                     required
//                     id="filled-required"
//                     size="small"
//                     type="date"
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     label="Registration Date"
//                     name="regDate"
//                     value={dayjs(createDetails.regDate).format("YYYY-MM-DD")}
//                     onChange={(event) => handleCreateOnChange(event)}
//                     sx={styles.textfield}
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={6} lg={4}>
//                   <TextField
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     required
//                     id="filled-required"
//                     size="small"
//                     label="Email "
//                     name="email"
//                     value={createDetails.email}
//                     onChange={(event) => handleCreateOnChange(event)}
//                     sx={styles.textfield}
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={6} lg={4}>
//                   <TextField
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     id="filled-required"
//                     size="small"
//                     label="Fax"
//                     name="schFax"
//                     value={createDetails.schFax}
//                     onChange={(event) => handleCreateOnChange(event)}
//                     sx={styles.textfield}
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={6} lg={4}>
//                   <TextField
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     required
//                     id="filled-required"
//                     size="small"
//                     label="Website Footer"
//                     name="websiteFooter"
//                     value={createDetails.websiteFooter}
//                     onChange={(event) => handleCreateOnChange(event)}
//                     sx={styles.textfield}
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={12} lg={12}>
//                   <TextareaAutosize
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     maxRows={4}
//                     name="description"
//                     value={createDetails.description}
//                     onChange={(event) => handleCreateOnChange(event)}
//                     style={{
//                       width: "100%",
//                       // marginLeft: 10,
//                       height: 100,
//                       padding: "10px",
//                       borderRadius: "5px",
//                     }}
//                     aria-label="maximum height"
//                     placeholder="Drop a note"
//                   />
//                 </Grid>
//               </Grid>
//             </>
//           </Card>
//           <Card style={styles.card}>
//             <>
//               <Grid
//                 container
//                 spacing={2}
//                 sx={{
//                   display: "flex",
//                   justifyContent: "flex-start",
//                   alignItems: "flex-end",
//                 }}>
//                 <Grid item xs={12} md={12} lg={12}>
//                   <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
//                     Setting Information:
//                   </Typography>
//                 </Grid>
//                 <Grid item xs={12} md={6} lg={4}>
//                   <FormControl size="small" required fullWidth>
//                     <InputLabel>Currency</InputLabel>
//                     <Select
//                       label="Currency"
//                       id="demo-simple-select-filled"
//                       name="currency"
//                       fontSize="small"
//                       onChange={(event) => handleCreateOnChange(event)}
//                       value={createDetails.currency}>
//                       <MenuItem
//                         sx={{ fontSize: 12, fontWeight: 500 }}
//                         value={"rupee"}>
//                         Indian Rupee
//                       </MenuItem>
//                     </Select>
//                   </FormControl>
//                 </Grid>
//                 <Grid item xs={12} md={6} lg={4}>
//                   <FormControl size="small" required fullWidth>
//                     <InputLabel>Currency Symbol</InputLabel>
//                     <Select
//                       labelId="demo-simpless-select-filled-label"
//                       id="demo-simple-select-filled-l"
//                       label="Currency Symbol"
//                       onChange={(event) => handleCreateOnChange(event)}
//                       name="currencySymbol"
//                       value={createDetails.currencySymbol}>
//                       <MenuItem value={"rupeeIcon"}>
//                         <CurrencyRupeeIcon
//                           sx={{ fontSize: 14, fontWeight: 500 }}
//                         />
//                       </MenuItem>
//                     </Select>
//                   </FormControl>
//                 </Grid>
//                 <Grid item xs={12} md={6} lg={4}>
//                   <LocalizationProvider dateAdapter={AdapterDayjs}>
//                     <Stack spacing={2}>
//                       <DatePicker
//                         label="Session Start Month"
//                         views={["month"]}
//                         sx={{ fontSize: 14, fontWeight: 500 }}
//                         inputFormat="MMMM"
//                         value={createDetails.sessionStartMonth}
//                         name="sessionStartMonth"
//                         onChange={(newValue) => {
//                           setCreateDetails({
//                             ...createDetails,
//                             sessionStartMonth: newValue,
//                           });
//                         }}
//                         renderInput={(params) => (
//                           <TextField
//                             // sx={{ m: 1, fontSize: 14, fontWeight: 500 }}
//                             size="small"
//                             required
//                             {...params}
//                           />
//                         )}
//                       />
//                     </Stack>
//                   </LocalizationProvider>
//                 </Grid>
//                 <Grid item xs={12} md={6} lg={4}>
//                   <LocalizationProvider dateAdapter={AdapterDayjs}>
//                     <Stack spacing={2}>
//                       <DatePicker
//                         label="Session End Month"
//                         views={["month"]}
//                         inputFormat="MMMM"
//                         name="sessionEndMonth"
//                         sx={{ fontSize: 14, fontWeight: 500 }}
//                         value={createDetails.sessionEndMonth}
//                         onChange={(newValue) => {
//                           setCreateDetails({
//                             ...createDetails,
//                             sessionEndMonth: newValue,
//                           });
//                         }}
//                         renderInput={(params) => (
//                           <TextField
//                             // sx={{ m: 1, fontSize: 14, fontWeight: 500 }}
//                             size="small"
//                             required
//                             {...params}
//                           />
//                         )}
//                       />
//                     </Stack>
//                   </LocalizationProvider>
//                 </Grid>

//                 <Grid item xs={12} md={6} lg={4}>
//                   <FormControl size="small" fullWidth>
//                     <InputLabel>roll Number</InputLabel>
//                     <Select
//                       labelId="demo-simpless-select-filled-label"
//                       id="demo-simple-select-filled-l"
//                       name="rollNumberType"
//                       onChange={(event) => handleCreateOnChange(event)}
//                       value={createDetails.rollNumberType}
//                       label="Currency Symbol">
//                       <MenuItem value={"manual"}>manual</MenuItem>
//                       <MenuItem value={"ascendingName"}>
//                         auto ascending name
//                       </MenuItem>
//                       <MenuItem value={"ascendingNameFemale"}>
//                         auto ascending name Female
//                       </MenuItem>
//                     </Select>
//                   </FormControl>
//                 </Grid>

//                 <Grid item xs={12} md={6} lg={4}>
//                   <FormControl size="small" fullWidth>
//                     <InputLabel>Admission Number</InputLabel>
//                     <Select
//                       labelId="demo-simpless-select-filled-label"
//                       id="demo-simple-select-filled-l"
//                       label="Admission Number"
//                       onChange={(event) => handleCreateOnChange(event)}
//                       name="admissionNo"
//                       value={createDetails.admissionNo}>
//                       <MenuItem value={"manual"}>manual</MenuItem>
//                       <MenuItem value={"autoAscendingNo"}>
//                         autoAscendingNo
//                       </MenuItem>
//                     </Select>
//                   </FormControl>
//                 </Grid>
//                 <Grid item xs={12} md={6} lg={4}>
//                   <TextField
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     id="filled-required"
//                     fullWidth
//                     label="Latitude"
//                     size="small"
//                     onChange={(event) => handleCreateOnChange(event)}
//                     value={createDetails.latitude}
//                     // sx={{ mt: 1, label: { fontSize: 12 } }}
//                     name="latitude"
//                   />
//                 </Grid>

//                 <Grid item xs={12} md={6} lg={4}>
//                   <TextField
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     fullWidth
//                     size="small"
//                     label="Longitude"
//                     onChange={(event) => handleCreateOnChange(event)}
//                     value={createDetails.longitude}
//                     sx={{ mt: 1, label: { fontSize: 12 } }}
//                     name="longitude"
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={6} lg={4}>
//                   <TimezoneSelect
//                     styles={{
//                       control: (baseStyle, state) => ({
//                         ...baseStyle,
//                       }),
//                     }}
//                     placeholder="Select default timezone"
//                     key={createDetails.defaultTimeZone}
//                     value={createDetails.defaultTimeZone || {}}
//                     onChange={handleCreateOnChangeTime}
//                     name="defaultTimeZone"
//                   />
//                 </Grid>

//                 <Grid item xs={12} md={6} lg={4}>
//                   <TextField
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     size="small"
//                     fullWidth
//                     onChange={(event) => handleCreateOnChange(event)}
//                     label="Google Analytics"
//                     sx={{ label: { fontSize: 12 } }}
//                     name="googleAnalyticsId"
//                     value={createDetails.googleAnalyticsId}
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={6} lg={3}>
//                   <FormControl size="small" required fullWidth>
//                     <InputLabel>Teacher Activity Feedback Enabled</InputLabel>
//                     <Select
//                       label="Teacher Activity Feedback Enabled"
//                       id="demo-simple-select-filled"
//                       onChange={(event) => handleCreateOnChange(event)}
//                       value={createDetails.teacherActivityFeedbackEnabled}
//                       name="teacherActivityFeedbackEnabled"
//                       fontSize="small">
//                       <MenuItem
//                         sx={{ fontSize: 12, fontWeight: 500 }}
//                         value={"yes"}>
//                         yes
//                       </MenuItem>
//                       <MenuItem
//                         sx={{ fontSize: 12, fontWeight: 500 }}
//                         value={"no"}>
//                         no
//                       </MenuItem>
//                     </Select>
//                   </FormControl>
//                 </Grid>
//               </Grid>
//             </>
//           </Card>
//           <Card style={styles.card}>
//             <Box sx={{ flexGrow: 1 }}>
//               <Grid
//                 container
//                 spacing={2}
//                 sx={{
//                   display: "flex",
//                   justifyContent: "flex-start",
//                   alignItems: "flex-end",
//                 }}>
//                 <Grid item xs={12} md={12} lg={12}>
//                   <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
//                     Social Information:
//                   </Typography>
//                 </Grid>
//                 <Grid item xs={12} md={6} lg={4}>
//                   <TextField
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     name="facebookUrl"
//                     value={createDetails.facebookUrl}
//                     onChange={(event) => handleCreateOnChange(event)}
//                     id="filled-required"
//                     size="small"
//                     label="Facebook URL"
//                     sx={styles.textfield}
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={6} lg={4}>
//                   <TextField
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     name="twitterUrl"
//                     value={createDetails.twitterUrl || ""}
//                     onChange={handleCreateOnChange}
//                     id="filled-required"
//                     size="small"
//                     label="Twitter URL"
//                     sx={styles.textfield}
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={6} lg={4}>
//                   <TextField
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     name="linkedinUrl"
//                     value={createDetails.linkedinUrl || ""}
//                     onChange={handleCreateOnChange}
//                     id="filled-required"
//                     size="small"
//                     label="Linkedin URL"
//                     sx={styles.textfield}
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={6} lg={4}>
//                   <TextField
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     name="gplusUrl"
//                     value={createDetails.gplusUrl || ""}
//                     onChange={handleCreateOnChange}
//                     id="filled-required"
//                     size="small"
//                     label="Google Plus URL"
//                     sx={styles.textfield}
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={6} lg={4}>
//                   <TextField
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     name="youtubeUrl"
//                     value={createDetails.youtubeUrl || ""}
//                     onChange={handleCreateOnChange}
//                     id="filled-required"
//                     size="small"
//                     label="Youtube URL"
//                     sx={styles.textfield}
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={6} lg={4}>
//                   <TextField
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     name="instagramUrl"
//                     value={createDetails.instagramUrl || ""}
//                     onChange={handleCreateOnChange}
//                     id="filled-required"
//                     size="small"
//                     label="Instagram URL"
//                     sx={styles.textfield}
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={6} lg={4}>
//                   <TextField
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     name="pinterestUrl"
//                     value={createDetails.pinterestUrl || ""}
//                     onChange={handleCreateOnChange}
//                     id="filled-required"
//                     size="small"
//                     label="Pinterest URL "
//                     sx={styles.textfield}
//                   />
//                 </Grid>
//               </Grid>
//             </Box>
//           </Card>
//           <div>
//             <Card style={styles.card}>
//               <Box sx={{ flexGrow: 1 }}>
//                 <Grid
//                   container
//                   spacing={2}
//                   sx={{
//                     display: "flex",
//                     justifyContent: "flex-start",
//                     alignItems: "flex-end",
//                   }}>
//                   <Grid item xs={12} md={12} lg={12}>
//                     <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
//                       Logo Upload:
//                     </Typography>
//                   </Grid>
//                   <form>
//                     <Grid item lg={12} sx={{ marginLeft: "20px" }} mt={1}>
//                       <Button
//                         variant="contained"
//                         component="label"
//                         sx={styles.Button}>
//                         Upload
//                         <input
//                           hidden
//                           accept="image/*"
//                           multiple
//                           type="file"
//                           value={schoolCreateLogo}
//                           onChange={handleCreatePhoto}
//                         />
//                       </Button>

//                       {previewCreateUrl && (
//                         <img
//                           src={previewCreateUrl}
//                           style={{
//                             width: "100px",
//                             height: "100px",
//                             objectFit: "contain",
//                             marginLeft: " 40px",
//                           }}
//                           alt="Preview"
//                         />
//                       )}
//                     </Grid>
//                   </form>
//                 </Grid>
//               </Box>
//             </Card>
//             <Grid
//               item
//               xs={12}
//               md={12}
//               lg={12}
//               sx={{ display: "flex", justifyContent: "flex-end", gap: "5px" }}>
//               <Button
//                 variant="contained"
//                 color="error"
//                 onClick={() => navigate(-1)}
//                 sx={{ color: "#fff" }}>
//                 Back
//               </Button>
//               <LoadingButton
//                 type="submit"
//                 loading={loading}
//                 variant="contained">
//                 Create
//               </LoadingButton>
//             </Grid>
//           </div>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default AddSetting;





import React, { useContext, useState } from "react";
import SectionsSubHeader from "../../../components/SectionSubHeader";

import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  TextareaAutosize,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@mui/icons-material";
import SettingContext from "../../../context/SettingsContext";
import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";
import { get, post, put } from "../../../services/apis";
import TimezoneSelect from "react-timezone-select";
import Switch from "@mui/material/Switch";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { urls as url } from "../../../services/urlConstant";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import appendStringToUrls from "../../../utils/appendString";
const styles = {
  card: {
    padding: 15,
    marginBottom: 20,
    display: "flex",
    alignItem: "center",
    justifyContent: "space-between",
    overflow: "visible",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
    color: "#fff",
  },
};
const AddSetting = () => {
  const { settings, setSettings, selectedSetting } = useContext(SettingContext);
  const navigate = useNavigate();
  const [addDialog, setAddDialog] = useState(false);

  const [settingDetails, setSettingDetails] = useState(null);
  const [schoolCreateLogoLink, setSchoolCreateLogoLink] = useState("");
  const [schoolLogoLink, setSchoolLogoLink] = useState("");
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [sessionStartMonth, setSessionStartMonth] = useState(dayjs(Date.now()));
  const [sessionEndMonth, setSessionEndMonth] = useState(dayjs(Date.now()));
  const [previewUrl, setPreviewUrl] = useState(null);

  const [previewCreateUrl, setPreviewCreateUrl] = useState(null);
  const [schoolCreateLogo, setSchoolCreatelLogo] = useState("");
  const { setSettingsContext } = useContext(SettingContext);
  const [createDetails, setCreateDetails] = useState({
    regDate: dayjs(Date.now()),
  });
  const [loading, setLoading] = useState(false);
  const handleCreateOnChange = (e) => {
    const { name, value } = e.target;
    setCreateDetails({ ...createDetails, [name]: value });
  };
  const handleCreateSubmit = async (e) => {
    setLoading(true);
    try {
      e.preventDefault();
      const data = {
        ...createDetails,
        schoolName: createDetails.schoolName,
        twitterUrl: createDetails.twitterUrl,
        linkedinUrl: createDetails.linkedinUrl,
        gplusUrl: createDetails.gplusUrl,
        youtubeUrl: createDetails.youtubeUrl,
        instagramUrl: createDetails.instagramUrl,
        pinterestUrl: createDetails.pinterestUrl,
        facebookUrl: createDetails.facebookUrl,
        address: createDetails.address,
        phone: createDetails.phone,
        regDate: dayjs(createDetails.regDate).format("YYYY-MM-DD"),
        email: createDetails.email,
        schFax: createDetails.schFax,
        websiteFooter: createDetails.websiteFooter,
        description: createDetails.description,
        logo: createDetails.logo,
        currency: createDetails.currency,
        currencySymbol: createDetails.currencySymbol,
        sessionStartMonth: createDetails.sessionStartMonth,
        sessionEndMonth: createDetails.sessionEndMonth,
        rollNumberType: createDetails.rollNumberType,
        admissionNo: createDetails.admissionNo,
        longitude: createDetails.longitude,
        latitude: createDetails.latitude,
        googleAnalyticsId: createDetails.googleAnalyticsId,
        defaultTimeZone: createDetails.defaultTimeZone,
        teacherActivityFeedbackEnabled:createDetails.teacherActivityFeedbackEnabled
      };
      const res = await post(urls.settings.postSettings, data);
      setSettings([...settings, res.data.data]);
      setAddDialog(false);
      resetForm();

      setSettingsContext((prev) => ({
        ...prev,
        schoolName: data.schoolName,
        schoolLogo: schoolCreateLogoLink,
      }));
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };
  const resetForm = () => {
    setCreateDetails({});
  };
  const handleCreateOnChangeTime = (d) => {
    setCreateDetails({ ...createDetails, defaultTimeZone: d });
  };
  const handleCreatePhoto = async (e) => {
    const formData = new FormData();
    formData.append("files", e.target.files[0]);
    try {
      setPreviewCreateUrl(URL.createObjectURL(e.target.files[0]));
      const res = await post(urls.fileUpload.postFileUpload, formData);
      setCreateDetails({
        ...createDetails,
        logo: res.data.result[0]._id,
      });
      setSchoolCreateLogoLink(res.data.result[0].link);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <form onSubmit={handleCreateSubmit}>
        <div style={{ margin: 15 }}>
          <Card style={styles.card}>
            <>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                }}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                    Basic Information
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    id="filled-required"
                    size="small"
                    name="schoolName"
                    value={createDetails.schoolName}
                    onChange={(event) => handleCreateOnChange(event)}
                    label="School Name"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    id="filled-required"
                    size="small"
                    label="Address"
                    name="address"
                    value={createDetails.address}
                    onChange={(event) => handleCreateOnChange(event)}
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    id="filled-required"
                    size="small"
                    label="Phone"
                    name="phone"
                    value={createDetails.phone}
                    onChange={(event) => handleCreateOnChange(event)}
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    required
                    id="filled-required"
                    size="small"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Registration Date"
                    name="regDate"
                    value={dayjs(createDetails.regDate).format("YYYY-MM-DD")}
                    onChange={(event) => handleCreateOnChange(event)}
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    id="filled-required"
                    size="small"
                    label="Email "
                    name="email"
                    value={createDetails.email}
                    onChange={(event) => handleCreateOnChange(event)}
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="filled-required"
                    size="small"
                    label="Fax"
                    name="schFax"
                    value={createDetails.schFax}
                    onChange={(event) => handleCreateOnChange(event)}
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    id="filled-required"
                    size="small"
                    label="Website Footer"
                    name="websiteFooter"
                    value={createDetails.websiteFooter}
                    onChange={(event) => handleCreateOnChange(event)}
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextareaAutosize
                    InputLabelProps={{
                      shrink: true,
                    }}
                    maxRows={4}
                    name="description"
                    value={createDetails.description}
                    onChange={(event) => handleCreateOnChange(event)}
                    style={{
                      width: "100%",
                      // marginLeft: 10,
                      height: 100,
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                    aria-label="maximum height"
                    placeholder="Drop a note"
                  />
                </Grid>
              </Grid>
            </>
          </Card>
          <Card style={styles.card}>
            <>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                }}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                    Setting Information:
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl size="small" required fullWidth>
                    <InputLabel>Currency</InputLabel>
                    <Select
                      label="Currency"
                      id="demo-simple-select-filled"
                      name="currency"
                      fontSize="small"
                      onChange={(event) => handleCreateOnChange(event)}
                      value={createDetails.currency}>
                      <MenuItem
                        sx={{ fontSize: 12, fontWeight: 500 }}
                        value={"rupee"}>
                        Indian Rupee
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl size="small" required fullWidth>
                    <InputLabel>Currency Symbol</InputLabel>
                    <Select
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      label="Currency Symbol"
                      onChange={(event) => handleCreateOnChange(event)}
                      name="currencySymbol"
                      value={createDetails.currencySymbol}>
                      <MenuItem value={"rupeeIcon"}>
                        <CurrencyRupeeIcon
                          sx={{ fontSize: 14, fontWeight: 500 }}
                        />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={2}>
                      <DatePicker
                        label="Session Start Month"
                        views={["month"]}
                        sx={{ fontSize: 14, fontWeight: 500 }}
                        inputFormat="MMMM"
                        value={createDetails.sessionStartMonth}
                        name="sessionStartMonth"
                        onChange={(newValue) => {
                          setCreateDetails({
                            ...createDetails,
                            sessionStartMonth: newValue,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            // sx={{ m: 1, fontSize: 14, fontWeight: 500 }}
                            size="small"
                            required
                            {...params}
                          />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={2}>
                      <DatePicker
                        label="Session End Month"
                        views={["month"]}
                        inputFormat="MMMM"
                        name="sessionEndMonth"
                        sx={{ fontSize: 14, fontWeight: 500 }}
                        value={createDetails.sessionEndMonth}
                        onChange={(newValue) => {
                          setCreateDetails({
                            ...createDetails,
                            sessionEndMonth: newValue,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            // sx={{ m: 1, fontSize: 14, fontWeight: 500 }}
                            size="small"
                            required
                            {...params}
                          />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <FormControl size="small" fullWidth>
                    <InputLabel>roll Number</InputLabel>
                    <Select
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      name="rollNumberType"
                      onChange={(event) => handleCreateOnChange(event)}
                      value={createDetails.rollNumberType}
                      label="Currency Symbol">
                      <MenuItem value={"manual"}>manual</MenuItem>
                      <MenuItem value={"ascendingName"}>
                        auto ascending name
                      </MenuItem>
                      <MenuItem value={"ascendingNameFemale"}>
                        auto ascending name Female
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <FormControl size="small" fullWidth>
                    <InputLabel>Admission Number</InputLabel>
                    <Select
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      label="Admission Number"
                      onChange={(event) => handleCreateOnChange(event)}
                      name="admissionNo"
                      value={createDetails.admissionNo}>
                      <MenuItem value={"manual"}>manual</MenuItem>
                      <MenuItem value={"autoAscendingNo"}>
                        autoAscendingNo
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="filled-required"
                    fullWidth
                    label="Latitude"
                    size="small"
                    onChange={(event) => handleCreateOnChange(event)}
                    value={createDetails.latitude}
                    // sx={{ mt: 1, label: { fontSize: 12 } }}
                    name="latitude"
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    size="small"
                    label="Longitude"
                    onChange={(event) => handleCreateOnChange(event)}
                    value={createDetails.longitude}
                    sx={{ mt: 1, label: { fontSize: 12 } }}
                    name="longitude"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TimezoneSelect
                    styles={{
                      control: (baseStyle, state) => ({
                        ...baseStyle,
                      }),
                    }}
                    placeholder="Select default timezone"
                    key={createDetails.defaultTimeZone}
                    value={createDetails.defaultTimeZone || {}}
                    onChange={handleCreateOnChangeTime}
                    name="defaultTimeZone"
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    fullWidth
                    onChange={(event) => handleCreateOnChange(event)}
                    label="Google Analytics"
                    sx={{ label: { fontSize: 12 } }}
                    name="googleAnalyticsId"
                    value={createDetails.googleAnalyticsId}
                  />
                </Grid>


                 <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" required fullWidth>
                    <InputLabel>Teacher Activity Feedback Enabled</InputLabel>
                     <Select
                      label="Teacher Activity Feedback Enabled"
                      id="demo-simple-select-filled"
                      onChange={(event) => handleCreateOnChange(event)}
                      value={createDetails.teacherActivityFeedbackEnabled}
                      name="teacherActivityFeedbackEnabled"
                      fontSize="small">
                      <MenuItem
                        sx={{ fontSize: 12, fontWeight: 500 }}
                        value={"yes"}>
                        yes
                      </MenuItem>
                      <MenuItem
                        sx={{ fontSize: 12, fontWeight: 500 }}
                        value={"no"}>
                        no
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>


              </Grid>
            </>
          </Card>
          <Card style={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                }}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                    Social Information:
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="facebookUrl"
                    value={createDetails.facebookUrl}
                    onChange={(event) => handleCreateOnChange(event)}
                    id="filled-required"
                    size="small"
                    label="Facebook URL"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="twitterUrl"
                    value={createDetails.twitterUrl || ""}
                    onChange={handleCreateOnChange}
                    id="filled-required"
                    size="small"
                    label="Twitter URL"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="linkedinUrl"
                    value={createDetails.linkedinUrl || ""}
                    onChange={handleCreateOnChange}
                    id="filled-required"
                    size="small"
                    label="Linkedin URL"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="gplusUrl"
                    value={createDetails.gplusUrl || ""}
                    onChange={handleCreateOnChange}
                    id="filled-required"
                    size="small"
                    label="Google Plus URL"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="youtubeUrl"
                    value={createDetails.youtubeUrl || ""}
                    onChange={handleCreateOnChange}
                    id="filled-required"
                    size="small"
                    label="Youtube URL"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="instagramUrl"
                    value={createDetails.instagramUrl || ""}
                    onChange={handleCreateOnChange}
                    id="filled-required"
                    size="small"
                    label="Instagram URL"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="pinterestUrl"
                    value={createDetails.pinterestUrl || ""}
                    onChange={handleCreateOnChange}
                    id="filled-required"
                    size="small"
                    label="Pinterest URL "
                    sx={styles.textfield}
                  />
                </Grid>
              </Grid>
            </Box>
          </Card>
          <div>
            <Card style={styles.card}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-end",
                  }}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                      Logo Upload:
                    </Typography>
                  </Grid>
                  <form>
                    <Grid item lg={12} sx={{ marginLeft: "20px" }} mt={1}>
                      <Button
                        variant="contained"
                        component="label"
                        sx={styles.Button}>
                        Upload
                        <input
                          hidden
                          accept="image/*"
                          multiple
                          type="file"
                          value={schoolCreateLogo}
                          onChange={handleCreatePhoto}
                        />
                      </Button>

                      {previewCreateUrl && (
                        <img
                          src={previewCreateUrl}
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "contain",
                            marginLeft: " 40px",
                          }}
                          alt="Preview"
                        />
                      )}
                    </Grid>
                  </form>
                </Grid>
              </Box>
            </Card>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{ display: "flex", justifyContent: "flex-end", gap: "5px" }}>
              <Button
                variant="contained"
                color="error"
                onClick={() => navigate(-1)}
                sx={{ color: "#fff" }}>
                Back
              </Button>
              <LoadingButton
                type="submit"
                loading={loading}
                variant="contained">
                Create
              </LoadingButton>
            </Grid>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddSetting;
