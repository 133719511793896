import * as React from "react";
import { useState, useContext } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AttendanceOverview from "./AttendanceOverview";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";

import {
  Avatar,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { get, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { useEffect } from "react";
import { Stack } from "@mui/system";
import dayjs from "dayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AttendanceReport from "./AttendanceReport";

import { objHas } from "../../../utils/utils";
import { LoadingButton } from "@mui/lab";
import SectionSubHeader from "../../SectionSubHeader";
import Wrapper from "../../Wrapper";

const styles = {
  card: {
    padding: "15px 0px",
    margin: "0px 0px 20px 0px",
  },
  textfield: {
    // width: "100%",
    label: { fontSize: 12 },
    // m: 1,
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
    // marginTop: "10px",
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StudentAttendance = () => {
  const { selectedSetting } = useContext(SettingContext);
  const [value, setValue] = useState(0);
  const [attendanceDetails, setAttendanceDetails] = useState({});
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [date, setDate] = useState(dayjs(Date.now()));
  const [studentsAttendence, setStudentsAttendence] = useState([]);
  const [presentCheck, setPresentCheck] = useState(false);
  const [absentCheck, setAbsentCheck] = useState(false);
  const [loader, setLoader] = useState(false);
  const [updateloader, setUpdateLoader] = useState(false);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const [sort, setSort] = useState({
    name: "asc",
    rollNo: "asc",
  });

  const sortByName = () => {
    console.log("Name");
    if (sort.name === "asc") {
      let sortedList = [
        ...studentsAttendence.sort((a, b) =>
          b.student.basicInfo.name.localeCompare(a.student.basicInfo.name)
        ),
      ];

      setStudentsAttendence(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      let sortedList = [
        ...studentsAttendence.sort((a, b) =>
          a.student.basicInfo.name.localeCompare(b.student.basicInfo.name)
        ),
      ];
      setStudentsAttendence(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };

  const sortByRollNo = () => {
    console.log("RollNo");
    if (sort.rollNo === "asc") {
      let sortedList = [
        ...studentsAttendence.sort(
          (a, b) =>
            b.student.academicInfo.rollNo - a.student.academicInfo.rollNo
        ),
      ];

      setStudentsAttendence(sortedList);
      setSort({ ...sort, rollNo: "des" });
    } else {
      let sortedList = [
        ...studentsAttendence.sort(
          (a, b) =>
            a.student.academicInfo.rollNo - b.student.academicInfo.rollNo
        ),
      ];
      setStudentsAttendence(sortedList);
      setSort({ ...sort, rollNo: "asc" });
    }
  };

  const handleClasses = async (e) => {
    try {
      const { name, value } = e.target;
      setAttendanceDetails({ ...attendanceDetails, [name]: value });
      const res = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: value,
          },
        },
      });
      setSections(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        setClasses(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, [selectedSetting]);

  const handleSections = (e) => {
    const { name, value } = e.target;
    setAttendanceDetails({ ...attendanceDetails, [name]: value });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleFindStudentAttendance = async (e) => {
    setLoader(true);
    e.preventDefault();
    setLoader(true);
    console.log(attendanceDetails);
    try {
      const studentAttendanceRes = await put(
        `${urls.students.updateStudentAttendanceByDate}/${dayjs(date).format(
          "YYYY-MM-DD"
        )}/class/${attendanceDetails.class}/section/${
          attendanceDetails.section
        }/subject/639ab3e3f629e9d90ab27536`,
        ""
      );
      if (studentAttendanceRes.data.success) {
        setStudentsAttendence(studentAttendanceRes.data.data);
        console.log("setStudentsAttendence", studentAttendanceRes.data.data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };
  const handleAttendanceStatusChange = (e, id) => {
    const status = e.target.value == "true" ? true : false;
    setStudentsAttendence(
      studentsAttendence.map((studAttend) => {
        if (studAttend._id == id) {
          return {
            ...studAttend,
            attendanceDetails: {
              attendanceStatus: status,
            },
          };
        } else {
          return studAttend;
        }
      })
    );
  };
  const handleAllPresent = (e) => {
    setPresentCheck(e.target.checked);
    if (e.target.checked) {
      setStudentsAttendence(
        studentsAttendence.map((studAttend) => {
          return {
            ...studAttend,
            attendanceDetails: {
              attendanceStatus: true,
            },
          };
        })
      );
      setAbsentCheck(false);
    }
  };
  const handleAllAbsent = (e) => {
    setAbsentCheck(e.target.checked);
    if (e.target.checked) {
      setStudentsAttendence(
        studentsAttendence.map((studAttend) => {
          return {
            ...studAttend,
            attendanceDetails: {
              attendanceStatus: false,
            },
          };
        })
      );
      setPresentCheck(false);
    }
  };
  const handleUpdateStudentAttendance = async () => {
    setUpdateLoader(true);
    try {
      const updateStudAttendance = await put(
        `${urls.students.updateStudentAttendanceBulk}`,
        "",
        {
          attendances: studentsAttendence,
          attendanceType: "classWise",
        }
      );
    } catch (error) {
      console.log(error);
    }
    setUpdateLoader(false);
  };
  return (
    <Wrapper>
      <SectionSubHeader title="Student Attedance" />
      <div style={{ margin: "10px 0 20px 0" }}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Overview" {...a11yProps(0)} />
              <Tab label="Attendance" {...a11yProps(1)} />
              <Tab label="Report" {...a11yProps(2)} />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <AttendanceOverview />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <Card style={styles.card}>
              <Box>
                <form onSubmit={handleFindStudentAttendance}>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-end",
                    }}
                  >
                    <Grid item xs={12} md={3} lg={3}>
                      <FormControl
                        fullWidth
                        size="small"
                        // sx={styles.textfield}
                        required
                      >
                        <InputLabel
                          id="demo-simple-select-filled-label"
                          sx={{ fontSize: 12 }}
                        >
                          Class
                        </InputLabel>
                        <Select
                          label="Class"
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          name="class"
                          value={attendanceDetails.class}
                          onChange={(event) => handleClasses(event)}
                        >
                          {classes &&
                            classes.map((row, index) => (
                              <MenuItem
                                key={row._id}
                                value={row._id}
                                sx={{ fontSize: 12, fontWeight: 500 }}
                              >
                                {row.className}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                      <FormControl
                        fullWidth
                        size="small"
                        // sx={styles.textfield}
                        required
                      >
                        <InputLabel
                          id="demo-simple-select-filled-label"
                          sx={{ fontSize: 12 }}
                        >
                          Section
                        </InputLabel>
                        <Select
                          label="Section"
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          name="section"
                          value={attendanceDetails.section}
                          onChange={(event) => handleSections(event)}
                        >
                          {sections &&
                            sections.map((row, index) => (
                              <MenuItem
                                key={row._id}
                                value={row._id}
                                sx={{ fontSize: 12, fontWeight: 500 }}
                              >
                                {row.sectionName}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      lg={3}
                      // sx={{ alignSelf: "center" }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={2}>
                          <DatePicker
                            size="small"
                            label="Date"
                            openTo="day"
                            views={["day", "month", "year"]}
                            inputFormat="DD-MM-YYYY"
                            value={date}
                            onChange={(newValue) => {
                              setDate(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField fullWidth size="small" {...params} />
                            )}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      lg={3}
                      sx={{
                        alignSelf: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: {
                          md: "left",
                          sm: "center",
                          xs: "center",
                        },
                      }}
                    >
                      <Tooltip title="Find">
                        <LoadingButton
                          loading={loader}
                          variant="contained"
                          style={styles.Button}
                          size="small"
                          type="submit"
                        >
                          Find
                        </LoadingButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Card>
            <div>
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead style={{ background: "rgb(27 55 121)" }}>
                      <TableRow>
                        <TableCell align="center">
                          <span className="class-table-header">#SL</span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="class-table-header">Name</span>
                          <span
                            onClick={sortByName}
                            style={{ cursor: "pointer", color: "#fff" }}
                          >
                            {sort.name === "asc" ? "▲" : "▼"}
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="class-table-header">Photo</span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="class-table-header">Roll No</span>
                          <span
                            onClick={sortByRollNo}
                            style={{ cursor: "pointer", color: "#fff" }}
                          >
                            {sort.rollNo === "asc" ? "▲" : "▼"}
                          </span>
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              padding: "0px",
                            }}
                          >
                            <div align="center">
                              <span className="class-table-header">
                                Present All{" "}
                                <Checkbox
                                  onChange={handleAllPresent}
                                  checked={presentCheck}
                                  sx={{
                                    padding: 0,
                                    color: "#fff",
                                    "&.Mui-checked": {
                                      color: "#fff",
                                    },
                                  }}
                                />
                              </span>
                            </div>
                            <div align="center">
                              <span className="class-table-header">
                                Absent All{" "}
                                <Checkbox
                                  onChange={handleAllAbsent}
                                  checked={absentCheck}
                                  sx={{
                                    padding: 0,
                                    color: "#fff",
                                    "&.Mui-checked": {
                                      color: "#fff",
                                    },
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {studentsAttendence.length > 0 &&
                        studentsAttendence.map((studAttendance, index) => (
                          <TableRow
                            key={studAttendance._id}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {studAttendance.student.basicInfo.name}
                            </TableCell>
                            <TableCell>
                              <div
                                align="center"
                                style={{
                                  textTransform: "capitalize",

                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Avatar
                                  alt={studAttendance.student.basicInfo.name}
                                  src={objHas(
                                    studAttendance,
                                    "student.studentPhoto.link",
                                    ""
                                  )}
                                />
                              </div>
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {/* {studAttendance.academicInfo.rollNo} */}
                              {objHas(
                                studAttendance,
                                "student.academicInfo.rollNo",
                                ""
                              )}
                            </TableCell>
                            <TableCell>
                              <RadioGroup
                                style={{ justifyContent: "space-around" }}
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={
                                  studAttendance.attendanceDetails
                                    .attendanceStatus
                                }
                                onChange={(e) =>
                                  handleAttendanceStatusChange(
                                    e,
                                    studAttendance._id
                                  )
                                }
                                row
                              >
                                <div>
                                  <div
                                    align="center"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    <FormControlLabel
                                      style={{
                                        position: "relative",
                                        left: "18px",
                                      }}
                                      value={true}
                                      control={<Radio />}
                                    />
                                  </div>
                                </div>
                                <div>
                                  <div
                                    align="center"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    <FormControlLabel
                                      style={{
                                        position: "relative",
                                        left: "18px",
                                      }}
                                      value={false}
                                      control={<Radio />}
                                    />
                                  </div>
                                </div>
                              </RadioGroup>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {!studentsAttendence.length && (
                  <Typography
                    variant="h6"
                    sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
                  >
                    No data found
                  </Typography>
                )}
              </Paper>
              <div
                style={{
                  margin: "15px 0px",
                  padding: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Submit">
                  <LoadingButton
                    loading={updateloader}
                    size="small"
                    style={styles.Button}
                    variant="contained"
                    onClick={handleUpdateStudentAttendance}
                  >
                    Submit
                  </LoadingButton>
                </Tooltip>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <AttendanceReport />
          </TabPanel>
        </Box>
      </div>
    </Wrapper>
  );
};

export default StudentAttendance;
