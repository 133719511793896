// import React, { useState, useEffect } from "react";
// import { styled } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
// import DownloadIcon from "@mui/icons-material/Download";
// import Grid from "@mui/material/Grid";
// import Divider from "@mui/material/Divider";
// import StudentRoutine from "../components/StudentRoutine/StudentRoutine";
// import Holidays from "../components/Holidays/Holidays";
// import StudentAttendence from "../components/StudentAttendence/StudentAttendence";
// import NoContent from "../components/NoContent";
// import css from "../styles/Dashboard/Dashboard.module.css";
// import Notice from "../components/Notice/Notice";
// import StudentNews from "../components/StudentNews";
// import { urls } from "../services/urlConstant";
// import { get } from "../services/apis";
// import dayjs from "dayjs";

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
// }));

// export default function StudentDashbord() {
//   const styles = {
//     dash: {
//       fontSize: "18px",
//       color: "forestgreen",
//     },
//   };
//   const [assignment, setAssignment] = useState();
//   const [invoice, setInvoice] = useState();
//   const [downloaded, setDownloaded] = useState();

//   useEffect(() => {
//     const getAssignment = async () => {
//       try {
//         const response = await get(
//           `/student-dashboard/all/assignments/active`,
//           {
//             params: {
//               studentId: "63c8eab8f7cfb0957f470f8c",
//             },
//           }
//         );
//         setAssignment([...response.data.data]);
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     getAssignment();
//   }, []);
//   useEffect(() => {
//     const getInvoice = async () => {
//       try {
//         const response = await get(`/student-dashboard/all/fee-receipts`, {
//           params: {
//             studentId: "6399d75729fa86b424ce3957",
//           },
//         });
//         setInvoice([...response.data.data]);
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     getInvoice();
//   }, []);

//   const handleGetPrintPdf = async (id) => {
//     try {
//       {
//         const getPrint = await get(
//           `/student-dashboard/download/fee-receipt/${id}`,
//           {
//             responseType: "blob",
//           }
//         );

//         const uri = URL.createObjectURL(getPrint.data);
//         window.open(uri, "__blank");
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   return (
//     <div>
//       <div>
//         <Box sx={{ flexGrow: 1 }}>
//           <Grid container spacing={2}>
//             <Grid item xs={4}>
//               <paper sx={{ height: 250 }}>
//                 <Item style={{ paddingBottom: "10px" }}>
//                   <b style={styles.dash}>Notice</b>
//                   <Divider color="primary" />
//                   <Notice />
//                 </Item>
//               </paper>
//             </Grid>
//             <Grid item xs={4}>
//               <Item sx={{ pt: 3 }}>
//                 <b style={styles.dash}>Attendence</b>
//                 <Divider
//                   color="primary"
//                   sx={{
//                     fontSize: 20,
//                   }}
//                 />
//                 <StudentAttendence />
//               </Item>
//             </Grid>

//             <Grid item xs={4}>
//               <Item sx={{ pb: 5 }}>
//                 <b style={styles.dash}>News</b>
//                 <Divider color="primary" />
//                 <StudentNews />
//                 {/* <NoContent info="the hindu" /> */}
//               </Item>

//               {/* <Grid>
//                 <Item>
//                   <b style={styles.dash}>Holidays</b>
//                   <Divider color="primary" />
//                   <Holidays />
//                 </Item>
//               </Grid> */}
//             </Grid>
//           </Grid>
//         </Box>
//       </div>
//       <div>
//         <Box sx={{ flexGrow: 1, mr: 6, ml: 6, mb: 5 }}>
//           <Grid container spacing={4}>
//             <Grid item xs={4}>
//               <Item>
//                 <b style={styles.dash}>Assignment</b>
//                 <Divider color="primary" />
//                 {/* <div className={css.scrollable}>
//                   {assignment.map((row) => (
//                     <div>
//                       <Card
//                         sx={{ minWidth: 275, textAlign: "center", mt: 2 }}
//                         className={css.card}
//                       >
//                         <CardContent>
//                           <Typography
//                             sx={{ fontSize: 15, textAlign: "start" }}
//                             color="text.secondary"
//                             gutterBottom
//                           >
//                             <b style={{ color: "#E1AD01" }}>
//                               {row.subject.subjectName}
//                             </b>
//                           </Typography>
//                           <Typography
//                             sx={{ fontSize: 15, textAlign: "start" }}
//                             color="text.secondary"
//                             gutterBottom
//                           >
//                             <b style={{ color: "#E1AD01" }}>
//                               {row.assignmentTitle}
//                             </b>
//                           </Typography>
//                           <Typography
//                             sx={{ fontSize: 15, textAlign: "start" }}
//                             color="text.secondary"
//                             gutterBottom
//                           >
//                             <b style={{ color: "#E1AD01" }}> Due Date:</b>
//                             {dayjs(assignment.createdAt).format("DD-MM-YYYY")}
//                           </Typography>
//                         </CardContent>
//                       </Card>
//                     </div>
//                   ))}
//                 </div> */}
//                 <div className={css.scrollable}>
//                   {assignment && assignment.length > 0 ? (
//                     assignment.map((row) => (
//                       <div key={row.id}>
//                         <Card
//                           sx={{ minWidth: 275, textAlign: "center", mt: 2 }}
//                           className={css.card}
//                         >
//                           <CardContent>
//                             <Typography
//                               sx={{ fontSize: 15, textAlign: "start" }}
//                               color="text.secondary"
//                               gutterBottom
//                             >
//                               <b style={{ color: "#E1AD01" }}>Subject:</b>
//                               {row.subject.subjectName}
//                             </Typography>

//                             <Typography
//                               sx={{ fontSize: 15, textAlign: "start" }}
//                               color="text.secondary"
//                               gutterBottom
//                             >
//                               <b style={{ color: "#E1AD01" }}>Assignment:</b>
//                               {row.assignmentTitle}
//                             </Typography>
//                             <Typography
//                               sx={{ fontSize: 15, textAlign: "start" }}
//                               color="text.secondary"
//                               gutterBottom
//                             >
//                               <b style={{ color: "#E1AD01" }}> Due Date:</b>
//                               {dayjs(row.deadline).format("DD-MM-YYYY")}
//                             </Typography>
//                           </CardContent>
//                         </Card>
//                       </div>
//                     ))
//                   ) : (
//                     <p>No assignments found.</p>
//                   )}
//                 </div>
//               </Item>
//             </Grid>
//             <Grid item xs={8}>
//               <Item>
//                 <b style={styles.dash}>Routine</b>
//                 <Divider color="primary" />
//                 <StudentRoutine />
//               </Item>
//             </Grid>
//           </Grid>
//         </Box>
//       </div>
//       <div>
//         <Box sx={{ flexGrow: 1, mr: 6, ml: 6, mb: 5 }}>
//           <Grid container spacing={4}>
//             <Grid item xs={4}>
//               <Item>
//                 <b style={styles.dash}>Invoices</b>
//                 <Divider color="primary" />
//                 <Card sx={{ minWidth: 275, textAlign: "center", mt: 2 }}>
//                   <CardContent sx={{ display: "flex" }}>
//                     {invoice && invoice.length > 0 ? (
//                       invoice.map((row) => (
//                         <div
//                           style={{
//                             display: "flex",
//                             alignItems: "center",
//                             justifyContent: "space-between",
//                           }}
//                         >
//                           <div>
//                             <Typography
//                               sx={{ fontSize: 15, textAlign: "start" }}
//                               color="text.secondary"
//                             >
//                               <b style={{ color: "#E1AD01" }}>
//                                 {" "}
//                                 {row.receiptType.name}:
//                               </b>
//                               {row.totalAmountPaid}
//                             </Typography>
//                             <Typography
//                               sx={{ fontSize: 15, textAlign: "start" }}
//                               color="text.secondary"
//                             >
//                               <b style={{ color: "#E1AD01" }}> Date:</b>
//                               {dayjs(row.dueDate).format("DD-MM-YYYY")}
//                             </Typography>
//                           </div>
//                           <div
//                             style={{ margin: "auto" }}
//                             onClick={() => handleGetPrintPdf(row._id)}
//                           >
//                             <DownloadIcon
//                               sx={{ fontSize: 35, ml: 12, cursor: "pointer" }}
//                               color="success"
//                             />
//                           </div>
//                         </div>
//                       ))
//                     ) : (
//                       <p>no Invoices</p>
//                     )}
//                   </CardContent>
//                 </Card>
//               </Item>
//             </Grid>
//             {/* <Grid item xs={4}>
//               <Item>
//                 <b style={styles.dash}>Primary</b>
//                 <Divider color="primary" />
//               </Item>
//             </Grid>
//             <Grid item xs={4}>
//               <Item>
//                 <b style={styles.dash}>High School</b>
//                 <Divider color="primary" />
//               </Item>
//             </Grid> */}
//           </Grid>
//         </Box>
//       </div>
//     </div>
//   );
// }

//===============previous code ===================//

import React, { useState, useEffect, useContext } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DownloadIcon from "@mui/icons-material/Download";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import StudentRoutine from "../components/StudentRoutine/StudentRoutine";
import Holidays from "../components/Holidays/Holidays";
import StudentAttendence from "../components/StudentAttendence/StudentAttendence";
import NoContent from "../components/NoContent";
import css from "../styles/Dashboard/Dashboard.module.css";
import Notice from "../components/Notice/Notice";
import StudentNews from "../components/StudentNews";
import { get } from "../services/apis";
import dayjs from "dayjs";
import FeedBack from "../components/Layout/Teacher/FeedBack";
import { ListItemIcon, ListItemText, Tabs } from "@mui/material";
import Tab from "@mui/material/Tab";
import Avatar from "@mui/material/Avatar";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Public } from "@mui/icons-material";
import { Link } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";

import { urls as url } from "../services/urlConstant";
import appendStringToUrls from "../utils/appendString";

import SettingContext from "../context/SettingsContext";
const Heading = styled(Typography)(() => ({
  padding: 10,

  textAlign: "center",

  fontFamily: "cursive",

  color: "#212121",
  fontSize: "22px",
  background: "-webkit-linear-gradient(#eee, #eeeeee)",
  webKitBackgroundClip: " text",
  webkitTextFillColor: " transparent",
  fontWeight: 600,
}));
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const MenuItems = styled(MenuItem)(() => ({
  "&:hover": {
    background: "#607d8b",
    color: "#fff",
    // margin: 1,
  },
}));
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const Container = styled(Box)(() => ({}));
const TabHeading = styled(Tab)(() => ({
  fontSize: "15px",
  color: "#000",
  fontWeight: 600,
  fontFamily: "cursive",
}));
const StudentDetails = styled(Typography)(() => ({
  fontSize: "15px",
  fontFamily: "cursive",
  fontWeight: "bold",
  color: "#607d8b",
}));
export default function StudentDashbord() {
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const styles = {
    dash: {
      fontSize: "18px",
      color: "forestgreen",
    },
  };
  const [tabValue, setTabValue] = useState(0);

  const [assignment, setAssignment] = useState();
  const [invoice, setInvoice] = useState();
  const [downloaded, setDownloaded] = useState();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      sx={{
        top: "60px",
      }}
    >
      <MenuItem onClick={handleMenuClose}>
        <Link to="/profile">
          <p style={{ color: "black" }}>Profile</p>
        </Link>
      </MenuItem>
      {/* <MenuItem onClick={handleMenuClose}>
        <Link to="/resetPassword">
          <p style={{ color: "black" }}>Dashboard</p>
        </Link>
      </MenuItem> */}
      <MenuItems onClick={handleMenuClose}>
        <Link to="/resetPassword">
          <p style={{ color: "black" }}>Reset Password</p>
        </Link>
      </MenuItems>
      <MenuItems onClick={handleMenuClose}>
        <ListItemText> Log Out</ListItemText>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
      </MenuItems>
    </Menu>
  );
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  let photo = window.localStorage.getItem("empPhoto");
  console.log(photo, "=====");
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    getAssignment();
  }, [selectedSetting]);

  const getAssignment = async () => {
    try {
      const response = await get(`${urls.studentDashboard.getAssignment}`);

      setAssignment([...response.data.data]);
      console.log(response, "responselllll");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getInvoice = async () => {
      try {
        const response = await get(`${urls.studentDashboard.getInvoice}`);
        setInvoice([...response.data.data]);
        console.log(response, "invoice");
      } catch (error) {
        console.log(error);
      }
    };
    getInvoice();
  }, [selectedSetting]);

  const handleGetPrintPdf = async (id) => {
    console.log(id, "iiiiii");
    try {
      {
        const getPrint = await get(
          `${urls.studentDashboard.getInvoicepdf}/${id}`,
          {
            responseType: "blob",
          }
        );

        const uri = URL.createObjectURL(getPrint.data);
        window.open(uri, "__blank");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Container>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <Typography
              sx={{ mr: 2, display: { xs: "none", sm: "flex", md: "flex" } }}
            >
              Dashboard
            </Typography>

            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Kayaka Foundation School
            </Typography>
            {auth && (
              <div>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                  sx={{ display: { xs: "none", sm: "flex", md: "flex" } }}
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  sx={{
                    top: "55px",
                  }}
                >
                  <MenuItems onClick={handleClose}>Profile</MenuItems>
                  <MenuItems onClick={handleClose}>Reset Password</MenuItems>
                  <MenuItems onClick={handleClose}>
                    <ListItemText> Log Out</ListItemText>
                    <ListItemIcon>
                      <LogoutIcon fontSize="small" sx={{ color: "#fff" }} />
                    </ListItemIcon>
                  </MenuItems>
                </Menu>
              </div>
            )}
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2, display: { xs: "none", sm: "flex", md: "flex" } }}
            >
              <Public />
            </IconButton>
            <Box sx={{ display: { xs: "flex", sm: "none", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
      </Box>
      <Box m="0px 20px">
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box
              width="100%"
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box p={1}>
                <img src={photo} height={200} width={200} />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} display="flex" flex={1}>
            <Paper sx={{ maxHeight: 450, width: "100%" }}>
              <Heading>News</Heading>
              <Divider />
              <StudentNews />
            </Paper>
          </Grid>
        </Grid>
      </Box>

      <Paper sx={{ margin: "20px 20px" }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label="Routine" {...a11yProps(0)} />
          <Tab label="Notice" {...a11yProps(1)} />
          <Tab label="Invoice" {...a11yProps(2)} />
          <Tab label="Attendence" {...a11yProps(3)} />
          <Tab label="Assignment" {...a11yProps(4)} />
          <Tab label="Feedback" {...a11yProps(5)} />
        </Tabs>
      </Paper>
      <Box sx={{ margin: "0px 20px" }}>
        <TabPanel value={tabValue} index={0}>
          <Paper>
            <Heading>Routine</Heading>
            <Divider />
          </Paper>
          <StudentRoutine />
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Paper>
            <Heading>Notice</Heading>
            <Divider />
            <Notice />
          </Paper>
        </TabPanel>

        <TabPanel value={tabValue} index={2}>
          <Paper>
            <Heading>Invoice</Heading>
            <Divider />
            <Box sx={{ textAlign: "center", mt: 2 }}>
              <CardContent sx={{ display: "flex" }}>
                {invoice && invoice.length > 0 ? (
                  invoice.map((row) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Typography
                          sx={{ fontSize: 15, textAlign: "start" }}
                          color="text.secondary"
                        >
                          <b style={{ color: "#E1AD01" }}>
                            {" "}
                            {row.receiptType.name}:
                          </b>
                          {row.totalAmountPaid}
                        </Typography>
                        <Typography
                          sx={{ fontSize: 15, textAlign: "start" }}
                          color="text.secondary"
                        >
                          <b style={{ color: "#E1AD01" }}> Date:</b>
                          {dayjs(row.dueDate).format("DD-MM-YYYY")}
                        </Typography>
                      </div>
                      <div
                        style={{ margin: "auto" }}
                        onClick={() => handleGetPrintPdf(row._id)}
                      >
                        <DownloadIcon
                          sx={{ fontSize: 35, ml: 12, cursor: "pointer" }}
                          color="success"
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <p>no Invoices</p>
                )}
              </CardContent>
            </Box>
          </Paper>
        </TabPanel>

        <TabPanel value={tabValue} index={3}>
          <Paper>
            <Heading>Attendence</Heading>
            <Divider />
            <StudentAttendence />
          </Paper>
        </TabPanel>

        <TabPanel value={tabValue} index={4}>
          <Paper>
            <Heading>Assignment</Heading>
            <Divider />
            <div className={css.scrollable}>
              {assignment && assignment.length > 0 ? (
                assignment.map((row) => (
                  <div key={row.id}>
                    <Card
                      sx={{ textAlign: "center", mt: 2, width: "100%" }}
                      className={css.card}
                    >
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 15, textAlign: "start" }}
                          color="text.secondary"
                          gutterBottom
                        >
                          <b style={{ color: "#E1AD01" }}>Subject:</b>
                          {row.subject.subjectName}
                        </Typography>

                        <Typography
                          sx={{ fontSize: 15, textAlign: "start" }}
                          color="text.secondary"
                          gutterBottom
                        >
                          <b style={{ color: "#E1AD01" }}>Assignment:</b>
                          {row.assignmentTitle}
                        </Typography>
                        <Typography
                          sx={{ fontSize: 15, textAlign: "start" }}
                          color="text.secondary"
                          gutterBottom
                        >
                          <b style={{ color: "#E1AD01" }}> Due Date:</b>
                          {dayjs(row.deadline).format("DD-MM-YYYY")}
                        </Typography>
                      </CardContent>
                    </Card>
                  </div>
                ))
              ) : (
                <p>No assignments found.</p>
              )}
            </div>
          </Paper>
        </TabPanel>

        <TabPanel value={tabValue} index={5}>
          <Heading>Feedback</Heading>
          <Divider />

          <FeedBack />
        </TabPanel>
      </Box>
      <Box sx={{ mb: 3 }} />
    </Container>
  );
}
