import React, { useState } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Card,
  Grid,
  Tooltip,
  TextField,
  TableFooter,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Delete, Edit, Search } from "@mui/icons-material";
import TableHead from "@mui/material/TableHead";

import { useEffect, useContext } from "react";
import AddSubjectModal from "./AddSubjectModal";
import DeleteModal from "./DeleteModal";
import { del, get, post, put } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import TablePagination from "@mui/material/TablePagination";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";

import styles from "../../../styles/components/Layout/Administrator/UserRole.module.css";
import { toast } from "react-toastify";
import SectionSubHeader from "../../../components/SectionSubHeader";
import Wrapper from "../../Wrapper";

const SchoolSubject = () => {
  const { selectedSetting } = useContext(SettingContext);
  const [schoolSubject, setSchoolSubject] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [updateSubject, setUpdateSubject] = useState(undefined);
  const [page, setPage] = React.useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [schoolClasses, setSchoolClass] = useState([]);
  const [selectClass, setSelectClass] = useState("");
  const [loader, setLoader] = useState(false);

  const url = appendStringToUrls(urls, `/${selectedSetting._id}`);

  const [sort, setSort] = useState({
    subjectName: "asc",
    subjectCode: "asc",
    subjectType: "asc",
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setUpdateSubject(undefined);
  };

  const handleDelete = async (id) => {
    try {
      const res = await del(url.schoolSubject.delSubject, id);
      if (res.data.success) {
        const filteredSubject = schoolSubject.filter(
          (item) => item._id !== res.data.data._id
        );
        setSchoolSubject(filteredSubject);
      }
    } catch (error) {}
  };
  const handleSubmit = async (e, data) => {
    setLoader(true);
    try {
      e.preventDefault();
      const res = await post(url.schoolSubject.postSubject, data);
      setSchoolSubject([...schoolSubject, res.data.data]);
    } catch (error) {}
    setLoader(false);
  };

  const handleUpdate = async (data, id) => {
    setLoader(true);
    try {
      const response = await put(url.schoolSubject.putSubject, id, data);
      console.log(response.data.data);
      setSchoolSubject(
        schoolSubject.map((e) => {
          if (e._id === response.data.data._id) {
            return response.data.data;
          } else {
            return e;
          }
        })
      );
    } catch (error) {}
    setLoader(true);
  };
  const handleEdit = async (id) => {
    try {
      setOpen(true);
      const getSubjectById = schoolSubject.find((item) => item._id === id);
      setUpdateSubject(getSubjectById);
    } catch (error) {}
  };
  const handleChangePage = async (event, newPage) => {
    try {
      const res = await get(url.schoolSubject.getAllSubject, {
        params: {
          limit: 10,
          page: newPage + 1,
        },
      });
      setPage(newPage);
      setSchoolSubject([...res.data.data]);
    } catch (error) {}
  };

  useEffect(() => {
    const init = async () => {
      try {
        const getSchoolClasses = await get(url.class.getAllClass);
        if (getSchoolClasses.data.success)
          setSchoolClass(getSchoolClasses.data.data);
        setSelectClass(getSchoolClasses.data.data[0]._id);
        const getSubjects = await get(url.schoolSubject.getAllSubject, {
          params: {
            search: {
              class: getSchoolClasses.data.data[0]._id,
            },
          },
        });

        setSchoolSubject(getSubjects.data.data);
        setTotalCount(getSubjects.data.data.length);
      } catch (error) {}
    };
    init();
  }, [selectedSetting]);
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      setSearchFilter(
        schoolSubject.filter((ele) =>
          ele.subjectName.toLowerCase().includes(value.toLowerCase().trim())
        )
      );
    } else {
      setSearchFilter([]);
    }
  };
  const handleClassSelect = async (e) => {
    try {
      console.log(e.target.value);
      setSelectClass(e.target.value);
      const getSubjects = await get(url.schoolSubject.getAllSubject, {
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      if (getSubjects.data.success) setSchoolSubject(getSubjects.data.data);
    } catch (error) {
      toast.error(error.message);
    }
  };
  const sortBySubjectName = () => {
    console.log("sortBySectionName");
    let sortedList;

    if (search) {
      if (sort.subjectName === "asc") {
        sortedList = [
          ...searchFilter.sort((a, b) =>
            b.subjectName.localeCompare(a.subjectName)
          ),
        ];
        setSort({ ...sort, subjectName: "des" });
      } else {
        sortedList = [
          ...searchFilter.sort((a, b) =>
            a.subjectName.localeCompare(b.subjectName)
          ),
        ];
        setSort({ ...sort, subjectName: "asc" });
      }
    } else {
      if (sort.subjectName === "asc") {
        sortedList = [
          ...schoolSubject.sort((a, b) =>
            b.subjectName.localeCompare(a.subjectName)
          ),
        ];
        setSort({ ...sort, subjectName: "des" });
      } else {
        sortedList = [
          ...schoolSubject.sort((a, b) =>
            a.subjectName.localeCompare(b.subjectName)
          ),
        ];
        setSort({ ...sort, subjectName: "asc" });
      }
    }
    setSchoolSubject(sortedList);
  };

  const sortBySubjectCode = () => {
    console.log("sortBySubjectCode");
    let sortedList;
    if (search) {
      if (sort.subjectCode === "asc") {
        sortedList = [
          ...searchFilter.sort((a, b) =>
            b.subjectCode.localeCompare(a.subjectCode)
          ),
        ];
        setSort({ ...sort, subjectCode: "des" });
      } else {
        sortedList = [
          ...searchFilter.sort((a, b) =>
            a.subjectCode.localeCompare(b.subjectCode)
          ),
        ];
        setSort({ ...sort, subjectCode: "asc" });
      }
    } else {
      if (sort.subjectCode === "asc") {
        sortedList = [
          ...schoolSubject.sort((a, b) =>
            b.subjectCode.localeCompare(a.subjectCode)
          ),
        ];
        setSort({ ...sort, subjectCode: "des" });
      } else {
        sortedList = [
          ...schoolSubject.sort((a, b) =>
            a.subjectCode.localeCompare(b.subjectCode)
          ),
        ];
        setSort({ ...sort, subjectCode: "asc" });
      }
    }
    setSchoolSubject(sortedList);
  };

  const sortByAttendanceTypeCode = () => {
    console.log("sortByAttendanceTypeCode");
    if (sort.subjectType === "asc") {
      let sortedList = [
        ...schoolSubject.sort((a, b) => {
          if (a.subjectType === "optional" && b.subjectType === "mandatory") {
            console.log("yes");
            return -1;
          } else if (
            a.subjectType === "mandatory" &&
            b.subjectType === "optional"
          ) {
            console.log("no");

            return 1;
          } else {
            return 0;
          }
        }),
      ];
      setSchoolSubject(sortedList);
      setSort({ ...sort, subjectType: "des" });
    } else {
      let sortedList = [
        ...schoolSubject.sort((a, b) => {
          if (a.subjectType === "optional" && b.subjectType === "mandatory") {
            console.log("yes");
            return 1;
          } else if (
            a.subjectType === "mandatory" &&
            b.subjectType === "optional"
          ) {
            console.log("no");

            return -1;
          } else {
            return 0;
          }
        }),
      ];
      setSchoolSubject(sortedList);
      setSort({ ...sort, subjectType: "asc" });
    }
  };
  return (
    <Wrapper>
      <SectionSubHeader title="Subject" />

      <div style={{ margin: "20px 0" }}>
        <div className={styles.searchInputContainer}>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: { md: "right" },
            }}
          >
            <Grid item xs={12} md={3}>
              <TextField
                size="small"
                value={search}
                onChange={handleSearch}
                label="Search"
                variant="outlined"
                // sx={{width:"150px"}}
                sx={{ width: "100%", fontSize: "10px" }}
                placeholder="Search By Subject Name"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" type="submit">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl size="small" sx={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-label">Class</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectClass}
                  onChange={handleClassSelect}
                  label="Class"
                >
                  {schoolClasses.map((schoolClass) => (
                    <MenuItem key={schoolClass._id} value={schoolClass._id}>
                      {schoolClass.className}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
        <Paper sx={{ width: "100%" }}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Subject Name</span>
                    <span
                      onClick={sortBySubjectName}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.subjectName === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Subject Code</span>
                    <span
                      onClick={sortBySubjectCode}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.subjectCode === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Attendance Type</span>
                    <span
                      onClick={sortByAttendanceTypeCode}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.subjectType === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Action</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {search
                  ? searchFilter.map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.subjectName}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.subjectCode}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.subjectType}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div className="edit-delete">
                            <Tooltip title="Edit" disableInteractive>
                              <Button onClick={() => handleEdit(row._id)}>
                                <Edit />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Delete" disableInteractive>
                              <Button onClick={() => setDeleteModal(row._id)}>
                                <Delete />
                              </Button>
                            </Tooltip>
                          </div>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={row._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  : schoolSubject.map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.subjectName}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.subjectCode}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.subjectType}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div className="edit-delete">
                            <Tooltip title="Edit" disableInteractive>
                              <Button onClick={() => handleEdit(row._id)}>
                                <Edit />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Delete" disableInteractive>
                              <Button onClick={() => setDeleteModal(row._id)}>
                                <Delete />
                              </Button>
                            </Tooltip>
                          </div>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={row._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          {!schoolSubject.length && !search.trim() && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {search.trim() && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </Paper>
        <div className="add-icon">
          <AddSubjectModal
            loader={loader}
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            handleUpdate={handleUpdate}
            updateSubject={updateSubject}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default SchoolSubject;
