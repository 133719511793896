import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { Delete, Edit } from "@mui/icons-material";
import {
  Grid,
  Button,
  Card,
  Divider,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Wrapper from "../../Wrapper";
import SectionSubHeader from "../../SectionSubHeader";

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
});

function createData(name, leaveType, numberOfDays, startDate, endDate, status) {
  return { name, leaveType, numberOfDays, startDate, endDate, status };
}

const rows = [
  // createData("aditya", "student", 5, "10/5/2023", "15/5/2023",)
];

function StudentsLeave() {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [leaveType, setLeaveType] = useState("");
  const [numberOfDays, setNumberOfDays] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [calculatedDates, setCalculatedDates] = useState([]);

  const styles = {
    button: {
      backgroundColor: "orange",
    },
    textfield: {
      margin: "20px",
      // width: "70%",
    },
    addbutton: {
      paddingTop: "6.2px",
      paddingBottom: "6.2px",
    },
    tab: {
      width: "100%",
      borderCollapse: "collapse",
    },
  };
  return (
    <Wrapper>
      <SectionSubHeader title="Student Leave" />
      <div style={{ margin: "15px 0" }}>
        <Paper style={{ width: "100%", height: "60px", overflow: "hidden" }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={6}
              lg={12}
              sx={{
                display: "flex",
                alignItems: "center",
                p: "0.5%",
                m: "0.5%",
                justifyContent: { md: "right", sm: "right", xs: "center" },
              }}
            >
              <TextField
                size="small"
                // style={{ marginTop: "10px", width: "150px" }}
                label="Search"
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Paper>
      </div>
      <div style={{ margin: "15px 0" }}>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell>
                    <span style={{ color: "white" }}>#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span style={{ color: "white" }}>Name</span>
                  </TableCell>
                  <TableCell align="center">
                    <span style={{ color: "white" }}>Leave Type</span>
                  </TableCell>
                  <TableCell align="center">
                    <span style={{ color: "white" }}>Number of days</span>
                  </TableCell>
                  <TableCell align="center">
                    <span style={{ color: "white" }}>Start date</span>
                  </TableCell>
                  <TableCell align="center">
                    <span style={{ color: "white" }}>End date</span>
                  </TableCell>
                  <TableCell align="center">
                    <span style={{ color: "white" }}>Status</span>
                  </TableCell>
                  <TableCell align="center">
                    <span style={{ color: "white" }}>Action</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow style={{ border: 0 }}>
                    <TableCell
                      key={row.name}
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.leavetype}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.noOfDays}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.startDate}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.endDate}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.status}
                    </TableCell>
                    <div className="edit-delete">
                      <TableCell>
                        <Tooltip title="Edit" disableInteractive>
                          <Button>
                            <Edit />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Delete" disableInteractive>
                          <Button>
                            <Delete />
                          </Button>
                        </Tooltip>
                      </TableCell>
                    </div>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {!rows.length && (
            <Typography
              variant="h6"
              style={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </Paper>
      </div>
    </Wrapper>
  );
}
export default StudentsLeave;
