import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, Button, CardContent, Grid, Modal, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useRef } from "react";
import "swiper/css";
import "swiper/css/navigation";
import { values } from "office-ui-fabric-react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AppSlider } from "../Components2/Category/AppSlider";
import { setting } from "../Data/carousal";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

const ModalBox = styled(Card)(({ theme }) => ({
  borderRadius: "5px",
  backgroundColor: "rgba(0,0,0,0.4)",
  // backgroundColor: "#009974",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  maxWidth: "600px",
  border: "1px solid #0000",
}));

const GallerySubHome = ({ data }) => {
  console.log(data, "cards");
  let cardRef = useRef(null);

  let navigate = useNavigate();
  let handleClick = (data) => {
    navigate(data);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [currentImage, setCurrentImage] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleNext = (i) => {
    console.log(i, "index");
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentImage((prevImage) =>
        prevImage === values.length - 1 ? 0 : prevImage + 1
      );
      setTimeout(() => setIsTransitioning(false), 300);
    }
  };

  const handlePrevious = (i) => {
    console.log(i, "index");
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentImage((prevImage) =>
        prevImage === 0 ? values.length - 1 : prevImage - 1
      );
      setTimeout(() => setIsTransitioning(false), 300);
    }
  };

  const sliderRef = useRef(null);

  const handlePrevious1 = () => {
    sliderRef.current.slickPrev();
  };

  const handleNext1 = () => {
    sliderRef.current.slickNext();
  };

  const handlePrevious2 = () => {
    cardRef.current.slickPrev();
  };

  const handleNext2 = () => {
    cardRef.current.slickNext();
  };
  const GridBox = styled(Card)(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "300px 300px 300px 300px",
    justifyContent: "space-evenly",
    gap: "10px",

    [theme.breakpoints.down(1000)]: {
      gridTemplateColumns: "300px 300px  ",
    },
    [theme.breakpoints.down(660)]: {
      gridTemplateColumns: "300px   ",
    },
  }));

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    arrows: false,
  };
  return (
    <>
      <Box sx={{ position: "relative", height: "100%", maxHeight: "700px" }}>
        <Stack
          direction="row"
          sx={{
            zIndex: 50,
            position: "relative",
            bottom: "-20px",
            left: "15px",
            gap: "10px",
          }}
        >
          {data.title && (
            <Button
              variant="contained"
              size="small"
              onClick={() => handleClick(data.path1)}
              sx={{
                bgcolor: "#1779f7",
                color: "white",
                border: "solid #1779f7",
                // bgcolor: "#f55214",
                "&:hover": {
                  color: "white",
                  bgcolor: "#ff4500",
                  border: "solid #ff4500",
                },
              }}
            >
              {data.title}
            </Button>
          )}
          {data.role2 && (
            <Button
              variant="contained"
              size="small"
              onClick={() => handleClick(data.path2)}
              sx={{
                bgcolor: "#1779f7",
                color: "white",
                border: "solid #1779f7",
                // bgcolor: "#f55214",
                "&:hover": {
                  color: "white",
                  bgcolor: "#ff4500",
                  border: "solid #ff4500",
                },
              }}
            >
              {data.role2}
            </Button>
          )}
        </Stack>
        <AppSlider ref={sliderRef} {...settings}>
          {data.images.map((values, i) => {
            return (
              <React.Fragment key={i}>
                <Box sx={{ padding: 2 }}>
                  <CardMedia
                    onClick={handleOpen}
                    component="img"
                    alt="green iguana"
                    height="240"
                    width="200"
                    image={values.link}
                    sx={{
                      objectFit: "cover",
                      backgroundColor: "lightgrey",
                      borderRadius: "5px",
                      overflow: "hidden",
                      cursor: "pointer",
                    }}
                  />
                </Box>
              </React.Fragment>
            );
          })}
        </AppSlider>
        <Box
          sx={{
            display: "flex",
            position: "relative",
            bottom: "150px",
            justifyContent: "space-between",
            width: "100%",
            cursor: "pointer",
          }}
        >
          <ArrowCircleLeftIcon
            onClick={handlePrevious1}
            sx={{
              color: "#42a5f5",
              background: "#ffff",
              marginLeft: "1px",
              marginTop: "2px",
              fontSize: "35px",
              borderRadius: "50%",
            }}
          />

          <ArrowCircleRightIcon
            onClick={handleNext1}
            sx={{
              color: "#42a5f5",
              background: "#ffff",

              marginLeft: "3px",
              marginTop: "2px",

              fontSize: "35px",
              borderRadius: "50%",
            }}
          />
        </Box>

        <Typography
          variant="h6"
          sx={{
            color: "black",
            bgcolor: "transparent",
            fontFamily: "Times New Roman",
            "&:hover": { color: "#ff4500" },
          }}
        >
          <CardContent sx={{ paddingTop: "0px", marginTop: "-30px" }}>
            {data.note}
          </CardContent>
        </Typography>
      </Box>

      <>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            backgroundColor: "black",
            border: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ModalBox display="flex" sx={{ flexDirection: "column" }}>
            <Box>
              <Stack>
                {data.title && (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleClick(data.path1)}
                    sx={{
                      bgcolor: "#1779f7",
                      color: "white",
                      border: "solid #1779f7",
                      // bgcolor: "#f55214",
                      "&:hover": {
                        color: "white",
                        bgcolor: "#ff4500",
                        border: "solid #ff4500",
                      },
                    }}
                  >
                    {data.title}
                  </Button>
                )}
                {data.role2 && (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleClick(data.path2)}
                    sx={{
                      bgcolor: "#1779f7",
                      color: "white",
                      border: "solid #1779f7",
                      // bgcolor: "#f55214",
                      "&:hover": {
                        color: "white",
                        bgcolor: "#ff4500",
                        border: "solid #ff4500",
                      },
                    }}
                  >
                    {data.role2}
                  </Button>
                )}
              </Stack>
            </Box>

            <AppSlider {...setting}>
              {data.images.map((data, index) => {
                return (
                  <Box
                    sx={{
                      width: "100%",
                      height: "35vh",
                      paddingTop: "10px",
                    }}
                    key={index}
                  >
                    <img
                      src={data.link}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                      alt=""
                    />
                  </Box>
                );
              })}
            </AppSlider>
          </ModalBox>
        </Modal>
      </>
    </>
  );
};

export default GallerySubHome;
