import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Search, Settings } from "@mui/icons-material";
import { get, post, put, del } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { useLocation } from "react-router-dom";
import SectionSubHeader from "../../SectionSubHeader";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const IssueDetails = () => {
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [tabValue, setTabValue] = useState(0);

  const { selectedSetting } = useContext(SettingContext);

  // const [fromType, setFromType] = useState("");
  // const [toType, setToType] = useState("");

  const [fromType, setFromType] = useState("School");
  const [toType, setToType] = useState("");

  const [from, setSettingOrVendor] = useState("");
  const [to, setAdditionalDropdownValue] = useState("");
  const [roles, setRoles] = useState([]);
  const [schoolEmployee, setSchoolEmployee] = useState([]);
  const [allEmployees, setAllEmployees] = useState([]);
  const [departments, setDepartment] = useState([]);

  const [student, setStudent] = useState([]);
  const [formData, setFormData] = useState({});
  const [showEdit, setShowEdit] = useState(false);
  const [academicYears, setAcademicYears] = useState([]);
  const [sections, setSections] = useState([]);
  const [inventoryItem, setInventoryItem] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const { state } = useLocation();
  const [addForm, setAddForm] = useState({});
  const [dataSet, setDataSet] = useState({});
  const [editIssue, setEditIssue] = useState();
  const [settings, setSettings] = useState([]);
  const [issue, setIssue] = useState([]);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const [issueFrom, setIssueFrom] = useState({
    fromSchool: "",
    vendorId: "",
  });

  const [issueTo, setIssueTo] = useState({
    toSchoolId: "",
    vendorId: "",
    studentId: "",
    employeeId: "",
  });

  const [classes, setClasses] = useState([]);

  const handleToTypeChange = (event) => {
    const selectedToType = event.target.value;
    setToType(selectedToType);
    setAdditionalDropdownValue("");
  };

  const handleClose = () => {
    setOpen(false);
    setShowEdit(false);
    setEditIssue(null);
    setDataSet({});
  };

  const handleFromTypeChange = (event) => {
    const selectedFromType = event.target.value;
    setFromType(selectedFromType);
    setSettingOrVendor("");
  };

  useEffect(() => {
    const getRoles = async () => {
      try {
        const rolesRes = await get(urls.role.getAllRole);
        setRoles(rolesRes.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getRoles();
  }, []);

  useEffect(() => {
    const schoolEmployeeGet = async () => {
      try {
        const response = await get(urls.employee.getAllEmployee, {
          params: {
            limit: 10,
            page: 1,
          },
        });

        setSchoolEmployee(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    const schoolEmployeeGetAll = async () => {
      try {
        const response = await get(urls.employee.getAllEmployee);
        setAllEmployees(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    const getAllDepartment = async () => {
      try {
        const departments = await get(urls.department.getDepartment);
        setDepartment(departments.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAllDepartment();
    schoolEmployeeGet();
    schoolEmployeeGetAll();
  }, [selectedSetting]);

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll);
        setFormData((prev) => ({
          ...prev,
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, [selectedSetting]);

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        const resSections = await get(urls.schoolSection.getAllSection, {
          params: {
            search: {
              class: res.data.data[0]._id,
            },
          },
        });
        setClasses(res.data.data);
        setFormData((prev) => ({
          ...prev,
          className: res.data.data[0]._id,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, [selectedSetting]);

  useEffect(() => {
    const getsearchStudents = async () => {
      try {
        if (!formData.className || !formData.section) return;
        const response = await get(urls.students.getAllStudent, {
          params: {
            search: {
              academicYear: formData.academicYear,
              "academicInfo.class": formData.className,
              "academicInfo.section": formData.section,
            },
          },
        });
        setStudent(response.data.data);
      } catch (error) {}
    };
    getsearchStudents();
  }, [formData.className, formData.section]);

  const handleClassChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    try {
      const section = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      if (section.data.data.length > 0) {
        setFormData((prev) => ({
          ...prev,
          section: section.data.data[0]._id,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          section: "",
        }));
      }
      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let { data } = await get(`${urls.inventory.getAllItem}`);
        setInventoryItem(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [selectedSetting]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let { data } = await get(`${urls.vendor.getAll}`);
        setVendorData(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [selectedSetting]);

  let [employeeData, setEmployeeData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let { data } = await get(`${urls.employee.getAllEmployee}`);
        setEmployeeData(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [selectedSetting]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let { data } = await get(`${urls.settings.getAllSettings}`);
        setSettings(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [selectedSetting]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let { data } = await get(`${urls.employee.getAllEmployee}`);
        setStudentData(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [selectedSetting]);

  useEffect(() => {
    fetchData();
  }, [selectedSetting._id]);

  const fetchData = async () => {
    try {
      const response = await get(`${urls.inventoryIssue.getAll}`);
      const responseData = response.data.data;
      setIssue(responseData);
      console.log(responseData, "data");
    } catch (error) {
      console.log(error);
    }
  };

  //  const handleIssueFrom = async (e) => {
  //    const { name, value } = e.target;
  //    setIssueFrom({
  //      ...issueFrom,
  //      [name]: value,
  //    });
  //  };
  //  const handleIssueTo = async (e) => {
  //    const { name, value } = e.target;
  //    setIssueTo({
  //      ...issueTo,
  //      [name]: value,
  //    });
  //  };
  const handleChange = async (e) => {
    const { name, value } = e.target;
    setDataSet({
      ...dataSet,
      [name]: value,
    });
  };

  const handleIssueFrom = async (e) => {
    const { name, value } = e.target;
    setIssueFrom({
      ...issueFrom,
      [name]: value,
    });
  };
  const handleIssueTo = async (e) => {
    const { name, value } = e.target;
    setIssueTo({
      ...issueTo,
      [name]: value,
    });
  };

  const styles = {
    button: {
      backgroundColor: "orange",
    },
    card: {
      padding: 10,
      margin: "10px 0px",
    },
    cardButton: {
      display: "flex",
      justifyContent: "flex-end",
      textAlign: "center",
      gap: "10px",
    },

    textfield: {
      marginTop: "25px",
      width: "70%",
    },
    addbutton: {
      paddingTop: "6.2px",
      paddingBottom: "6.2px",
    },
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleTabChange = (event, newValue) => {
    if (newValue === 0) {
      setShowEdit(false);
      setEditIssue(null);
      setDataSet({});
    }
    setTabValue(newValue);
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      issue.length > 0 &&
        setSearchFilter(
          issue.filter((ele) =>
            ele.item.name.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  const handleEdit = (id, value) => {
    console.log(value, "valssue");
    setOpen(true);
    setEditIssue(id);
    setTabValue(2);
    setShowEdit(true);

    setDataSet({
      item: value.item.name,
      quantity: value.quantity,
      pricePerItem: value.pricePerItem,
      fromSchool: value.fromSchool.schoolName,
      issuedTo: value.toMeetUser,
      employeeId: value.issuedTo.basicInfo.empName,
      studentId: value.issuedTo.basicInfo.name,
      status: value.status,
      notes: value.notes,
    });
    setToType(value.issuedToRoleName);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!editIssue) {
      try {
        const data = await post(`${urls.inventoryIssue.create}`, {
          item: dataSet.item,
          quantity: dataSet.quantity,
          pricePerItem: dataSet.pricePerItem,
          fromSchool: dataSet.fromSchool,
          issuedTo: dataSet.toMeetUser,
          employeeId: dataSet.toMeetUser,
          studentId: dataSet.toMeetUser,
          status: dataSet.status,
          notes: dataSet.notes,
          issuedToRoleName: toType,
        });

        console.log("Data created:", data);
        fetchData();
        setTabValue(1);
        handleClose();
      } catch (error) {
        console.log("Error creating data:", error);
      }
    } else {
      try {
        const data = await put(
          `${urls.inventoryIssue.update}${editIssue}`,
          "",
          {
            item: dataSet.item,
            quantity: dataSet.quantity,
            pricePerItem: dataSet.pricePerItem,
            fromSchool: dataSet.fromSchool,
            issuedTo: dataSet.toMeetUser,
            employeeId: dataSet.toMeetUser,
            studentId: dataSet.toMeetUser,
            status: dataSet.status,
            notes: dataSet.notes,
            issuedToRoleName: toType,
          }
        );
        console.log("Data updated:", data);
        fetchData();
        setShowEdit(false);
        setTabValue(1);
        handleClose();
      } catch (error) {
        console.log("Error updating data:", error);
      }
    }
  };

  return (
    <div>
      <SectionSubHeader title="Issue Details" />
      <div style={{ margin: "15px" }}>
        <Card style={styles.card}>
          <Box>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab label="Issue" {...a11yProps(0)} />
              <Tab label="List of Issue" {...a11yProps(1)} />
              {showEdit && <Tab label="Edit Issue" {...a11yProps(2)} />}
            </Tabs>
          </Box>
        </Card>
        <TabPanel value={tabValue} index={0}>
          <Paper>
            <form onSubmit={handleSubmit}>
              <Box>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  textAlign="center"
                  marginBottom="20px"
                  marginTop="10px"
                ></Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl size="small" required fullWidth>
                      <InputLabel>Item </InputLabel>
                      <Select
                        label="Item"
                        name="item"
                        value={dataSet.item}
                        onChange={handleChange}
                      >
                        {inventoryItem.map((data) => (
                          <MenuItem key={data._id} value={data._id}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <TextField
                      required
                      size="small"
                      type="number"
                      label="Quantity"
                      name="quantity"
                      value={dataSet.quantity}
                      onChange={handleChange}
                      fullWidth
                      // sx={{ m: 1, width: "100%", label: { fontSize: 12 } }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl size="small" required fullWidth>
                      <InputLabel>Issue Status</InputLabel>
                      <Select
                        label="Issue Status"
                        name="status"
                        onChange={handleChange}
                        value={dataSet.status}
                      >
                        <MenuItem value={"Issued"}>Issued</MenuItem>
                        <MenuItem value={"Returned"}>Returned</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={0.5}>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl size="small" required fullWidth>
                      <InputLabel>From Type</InputLabel>
                      <Select
                        label="From Type"
                        value={fromType}
                        name="fromType"
                        onChange={handleFromTypeChange}
                      >
                        <MenuItem value={"School"}>School</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl size="small" required fullWidth>
                      <InputLabel>School</InputLabel>
                      <Select
                        label="School"
                        name="fromSchool"
                        value={dataSet.fromSchool}
                        onChange={handleChange}
                      >
                        {settings.map((data) => (
                          <MenuItem key={data._id} value={data._id}>
                            {data.schoolName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={2} mt={0.5}>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl size="small" required fullWidth>
                      <InputLabel>To Type</InputLabel>
                      <Select
                        label="To Type"
                        name="to"
                        value={toType}
                        onChange={handleToTypeChange}
                      >
                        <MenuItem value={"EMPLOYEE"}>Employee</MenuItem>
                        <MenuItem value={"STUDENT"}>Student</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    {toType === "STUDENT" ? (
                      <Grid item xs={12} md={12} lg={12}>
                        <FormControl size="small" required fullWidth>
                          <InputLabel>Class</InputLabel>
                          <Select
                            label="Class"
                            name="className"
                            value={formData.className}
                            onChange={handleClassChange}
                          >
                            {classes &&
                              classes.map((row, index) => (
                                <MenuItem key={row._id} value={row._id}>
                                  {row.className}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>

                        {formData.className && (
                          <Grid item xs={12} md={12} lg={12} mt={2}>
                            <FormControl
                              size="small"
                              style={{}}
                              required
                              fullWidth
                            >
                              <InputLabel> Section</InputLabel>
                              <Select
                                label="Section"
                                name="section"
                                value={formData.section || ""}
                                onChange={handleOnChange}
                              >
                                {sections.map((row, index) => (
                                  <MenuItem key={row._id} value={row._id}>
                                    {row.sectionName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        )}

                        {formData.className && (
                          <Grid item xs={12} md={12} lg={12} mt={2}>
                            <FormControl
                              size="small"
                              required
                              style={
                                {
                                  // width: "335px",
                                  // position: "absolute",
                                  // marginLeft: "372px",
                                  // marginTop: "-40px",
                                }
                              }
                              fullWidth
                            >
                              <InputLabel>Student Name</InputLabel>
                              <Select
                                label="Student Name"
                                name="toMeetUser"
                                value={dataSet.toMeetUser || ""}
                                onChange={handleChange}
                              >
                                {student.map((row, index) => (
                                  <MenuItem key={row._id} value={row._id}>
                                    {row.basicInfo.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        )}
                      </Grid>
                    ) : (
                      <Grid item xs={12} md={12} lg={12}>
                        <FormControl size="small" required fullWidth>
                          <InputLabel>Employee Name</InputLabel>
                          <Select
                            label="Employee Name"
                            value={dataSet.toMeetUser}
                            onChange={handleChange}
                            name="toMeetUser"
                          >
                            {schoolEmployee.map((emp) => (
                              <MenuItem key={emp._id} value={emp._id}>
                                {emp.basicInfo.empName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <TextareaAutosize
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={dataSet.notes}
                      onChange={handleChange}
                      maxRows={4}
                      name="description"
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                        height: 100,
                        padding: "10px",
                      }}
                      aria-label="maximum height"
                      placeholder="Drop a note"
                    />
                  </Grid>
                </Grid>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Tooltip title="Cancel">
                    <Button
                      variant="contained"
                      color="error"
                      size="medium"
                      type="button"
                      sx={{ m: 1, color: "#fff" }}
                    >
                      Cancel
                    </Button>
                  </Tooltip>
                  <Tooltip title="Submit">
                    <Button
                      variant="contained"
                      size="medium"
                      type="submit"
                      sx={{
                        marginRight: "5px",
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                    >
                      Issue
                    </Button>
                  </Tooltip>
                </div>
              </Box>
            </form>
          </Paper>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Card style={{ padding: "10px" }}>
            <div style={styles.cardButton}>
              <Button variant="contained">Excel</Button>

              <Button variant="contained">Pdf</Button>
            </div>
          </Card>
          <Paper
            sx={{ width: "100%", height: "60px", overflow: "hidden", mt: 2 }}
          >
            <div style={{ margin: "20px 0px" }}>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid xs="auto">
                  <form>
                    <TextField
                      size="small"
                      value={search}
                      onChange={handleSearch}
                      label="Search"
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton edge="end" type="submit">
                              <Search />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </form>
                </Grid>
              </Grid>
            </div>
          </Paper>
          <Table>
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Item Id
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Item Name
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Issuer Name
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  Total Quantity
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter.map((data, index) => (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{data.item.itemId}</TableCell>

                      <TableCell align="center">{data.item.name}</TableCell>
                      <TableCell align="center">
                        {data.issuedTo.basicInfo.empName}
                      </TableCell>
                      <TableCell align="center">{data.total}</TableCell>
                      <TableCell align="center">
                        <div className="edit-delete">
                          <Button onClick={() => handleEdit(data._id, data)}>
                            <EditIcon />
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                : issue.map((data, index) => (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{data.item.itemId}</TableCell>

                      <TableCell align="center">{data.item.name}</TableCell>
                      <TableCell align="center">
                        {data.issuedBy.basicInfo.empName}
                      </TableCell>
                      <TableCell align="center">{data.total}</TableCell>
                      <TableCell align="center">
                        <div className="edit-delete">
                          <Button onClick={() => handleEdit(data._id, data)}>
                            <EditIcon />
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
          {!issue.length && !search.trim() && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {search.trim() && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TabPanel>

        {showEdit && (
          <TabPanel value={tabValue} index={2}>
            <Paper style={{ margin: "0px 15px 15px 15px" }}>
              <form onSubmit={handleSubmit}>
                <Box>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    textAlign="center"
                    marginBottom="20px"
                    marginTop="10px"
                  ></Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                      <FormControl variant="standard" required fullWidth>
                        <InputLabel>Item</InputLabel>
                        <Select
                          name="item"
                          value={dataSet.item}
                          onChange={handleChange}
                        >
                          {inventoryItem.map((data) => (
                            <MenuItem key={data.name} value={data.name}>
                              {data.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        required
                        variant="standard"
                        type="number"
                        label="Quantity"
                        name="quantity"
                        value={dataSet.quantity}
                        onChange={handleChange}
                        sx={{ m: 1, width: "100%", label: { fontSize: 12 } }}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <FormControl variant="standard" required fullWidth>
                        <InputLabel>Issue Status</InputLabel>
                        <Select
                          name="status"
                          onChange={handleChange}
                          value={dataSet.status}
                        >
                          <MenuItem value={"Pending"}>Pending</MenuItem>
                          <MenuItem value={"Issued"}>Issued</MenuItem>
                          <MenuItem value={"Returned"}>Returned</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <FormControl variant="standard" required fullWidth>
                        <InputLabel>From Type</InputLabel>
                        <Select
                          value={fromType}
                          name="fromType"
                          onChange={handleFromTypeChange}
                        >
                          <MenuItem value={"School"}>School</MenuItem>
                        </Select>
                      </FormControl>
                      <Grid item xs={12} md={12} lg={12}>
                        <FormControl variant="standard" required fullWidth>
                          <InputLabel>School</InputLabel>
                          <Select
                            name="fromSchool"
                            value={dataSet.fromSchool || ""}
                            onChange={handleChange}
                          >
                            {settings.map((data) => (
                              <MenuItem
                                key={data.schoolName}
                                value={data.schoolName}
                              >
                                {data.schoolName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <FormControl variant="standard" required fullWidth>
                        <InputLabel>To Type</InputLabel>
                        <Select
                          name="to"
                          value={toType}
                          onChange={handleToTypeChange}
                        >
                          <MenuItem value={"EMPLOYEE"}>Employee</MenuItem>
                          <MenuItem value={"STUDENT"}>Student</MenuItem>
                        </Select>
                      </FormControl>
                      {toType === "EMPLOYEE" ? (
                        <Grid item xs={12} md={12} lg={12}>
                          <FormControl variant="standard" required fullWidth>
                            <InputLabel>Employee Name</InputLabel>
                            <Select
                              value={dataSet.employeeId}
                              onChange={handleChange}
                              name="employeeId"
                            >
                              {schoolEmployee.map((emp) => (
                                <MenuItem
                                  key={emp.basicInfo.empName}
                                  value={emp.basicInfo.empName}
                                >
                                  {emp.basicInfo.empName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      ) : (
                        <Grid item xs={12} md={12} lg={12}>
                          <FormControl variant="standard" required fullWidth>
                            <InputLabel>Class</InputLabel>
                            <Select
                              name="className"
                              value={formData.className}
                              onChange={handleClassChange}
                            >
                              {classes &&
                                classes.map((row, index) => (
                                  <MenuItem key={row._id} value={row._id}>
                                    {row.className}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>

                          {formData.className && (
                            <Grid item xs={12} md={12} lg={12}>
                              <FormControl
                                variant="standard"
                                required
                                style={{
                                  width: "310px",
                                  position: "absolute",
                                  marginLeft: "350px",
                                  marginTop: "-97px",
                                }}
                                fullWidth
                              >
                                <InputLabel> Section</InputLabel>
                                <Select
                                  name="section"
                                  value={formData.section || ""}
                                  onChange={handleOnChange}
                                >
                                  {sections.map((row, index) => (
                                    <MenuItem key={row._id} value={row._id}>
                                      {row.sectionName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                          )}

                          {formData.className && (
                            <Grid item xs={12} md={12} lg={12}>
                              <FormControl
                                variant="standard"
                                required
                                style={{
                                  width: "310px",
                                  position: "absolute",
                                  marginLeft: "349px",
                                  marginTop: "-50px",
                                }}
                                fullWidth
                              >
                                <InputLabel>Student Name</InputLabel>
                                <Select
                                  name="studentId"
                                  value={dataSet.studentId || ""}
                                  onChange={handleChange}
                                >
                                  {student.map((row, index) => (
                                    <MenuItem
                                      key={row.basicInfo.name}
                                      value={row.basicInfo.name}
                                    >
                                      {row.basicInfo.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                          )}
                        </Grid>
                      )}
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                      <TextareaAutosize
                        InputLabelProps={{
                          shrink: true,
                        }}
                        maxRows={4}
                        name="description"
                        style={{
                          width: "100%",
                          marginLeft: 10,
                          height: 100,
                          padding: "10px",
                        }}
                        aria-label="maximum height"
                        placeholder="Drop a note"
                      />
                    </Grid>
                  </Grid>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Tooltip title="Cancel">
                      <Button
                        variant="contained"
                        color="error"
                        size="medium"
                        type="button"
                        sx={{ m: 1, color: "#fff" }}
                        onClick={() => {
                          setTabValue(1);
                          setShowEdit(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Tooltip>
                    <Tooltip title="Submit">
                      <Button
                        variant="contained"
                        size="medium"
                        type="submit"
                        sx={{
                          marginRight: "5px",
                          background: "rgb(27, 55, 121)",
                          ":hover": { background: "rgb(27, 55, 121)" },
                        }}
                      >
                        Update
                      </Button>
                    </Tooltip>
                  </div>
                </Box>
              </form>
            </Paper>
          </TabPanel>
        )}
      </div>
    </div>
  );
};

export default IssueDetails;
