import React, { useContext, useState } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Card,
  Grid,
  Tooltip,
  TextField,
  Typography,
  styled,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { del, get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { Delete, Edit, Search } from "@mui/icons-material";
import TableHead from "@mui/material/TableHead";
import { useEffect } from "react";
import AddDepartment from "../HumanResource/AddDepartment";
import DeleteModal from "../Academic/DeleteModal";

import styles from "../../../styles/components/Layout/Administrator/UserRole.module.css";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";

const SearchField = styled(Grid)(({ theme }) => ({
  justifyContent: "flex-end",
  [theme.breakpoints.down(900)]: {
    justifyContent: "center",
  },
  [theme.breakpoints.down(685)]: {
    justifyContent: "flex-start",
  },
}));

const Department = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [schoolDepartment, setSchoolDepartment] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [updateDepartment, setUpdateDepartment] = useState(undefined);
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSeaarch] = useState("");
  const [sort, setSort] = useState({ department: "asc" });

  let [loader, setLoader] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setUpdateDepartment(undefined);
  };

  const handleDelete = async (id) => {
    try {
      const res = await del(urls.department.delDepartment, id);
      if (res.data.success) {
        const filteredSection = schoolDepartment.filter(
          (item) => item._id !== res.data.data._id
        );
        setSchoolDepartment(filteredSection);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = async (e, data) => {
    e.preventDefault();
    setLoader(true);
    try {
      const res = await post(urls.department.postDepartment, data);
      setSchoolDepartment([...schoolDepartment, res.data.data]);
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const handleUpdate = async (data, id) => {
    setLoader(true);
    try {
      const response = await put(urls.department.putDepartment, id, data);
      setSchoolDepartment(
        schoolDepartment.map((e) => {
          if (e._id === response.data.data._id) {
            return response.data.data;
          } else {
            return e;
          }
        })
      );
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };
  const handleEdit = async (id) => {
    try {
      setOpen(true);
      const getDepartmentById = schoolDepartment.find(
        (item) => item._id === id
      );
      setUpdateDepartment(getDepartmentById);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getSchoolDepartment = async () => {
      try {
        const res = await get(urls.department.getDepartment);
        setSchoolDepartment([...res.data.data]);
        setSearchFilter([...res.data.data]);
      } catch (error) {
        console.log(error);
      }
    };
    getSchoolDepartment();
  }, []);
  const handleSearch = (e) => {
    const { value } = e.target;
    setSeaarch(value.trim());
    if (value.trim() !== "") {
      setSearchFilter(
        schoolDepartment.filter((ele) =>
          ele.department.toLowerCase().includes(value.toLowerCase().trim())
        )
      );
    } else {
      setSearchFilter([...schoolDepartment]);
    }
  };

  const departmentSorted = () => {
    if (search) {
      if (sort.department === "des") {
        let sortedList = [
          ...searchFilter.sort((a, b) =>
            a.department.localeCompare(b.department)
          ),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, department: "asc" });
      } else {
        let sortedList = [
          ...searchFilter.sort((a, b) =>
            b.department.localeCompare(a.department)
          ),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, department: "des" });
      }
    } else {
      if (sort.department === "des") {
        let sortedList = [
          ...schoolDepartment.sort((a, b) =>
            a.department.localeCompare(b.department)
          ),
        ];
        setSchoolDepartment(sortedList);
        setSort({ ...sort, department: "asc" });
      } else {
        let sortedList = [
          ...schoolDepartment.sort((a, b) =>
            b.department.localeCompare(a.department)
          ),
        ];
        setSchoolDepartment(sortedList);
        setSort({ ...sort, department: "des" });
      }
    }
  };

  const noteSorted = () => {
    console.log("Note");
    if (search) {
      if (sort.note === "des") {
        let sortedList = [
          ...searchFilter.sort((a, b) => a.note.localeCompare(b.note)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, note: "asc" });
      } else {
        let sortedList = [
          ...searchFilter.sort((a, b) => b.note.localeCompare(a.note)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, note: "des" });
      }
    } else {
      if (sort.note === "des") {
        let sortedList = [
          ...schoolDepartment.sort((a, b) => a.note.localeCompare(b.note)),
        ];
        setSchoolDepartment(sortedList);
        setSort({ ...sort, note: "asc" });
      } else {
        let sortedList = [
          ...schoolDepartment.sort((a, b) => b.note.localeCompare(a.note)),
        ];
        setSchoolDepartment(sortedList);
        setSort({ ...sort, note: "des" });
      }
    }
  };

  return (
    <div>
      <Card style={{ margin: 15, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span style={{ color: "red", fontFamily: "cursive" }}>
              Department :
            </span>
          </Grid>
        </Grid>
      </Card>
      <div className={styles.searchInputContainer}>
        <SearchField container spacing={2}>
          <Grid item xs="auto">
            <TextField
              size="small"
              value={search}
              onChange={handleSearch}
              label="Search"
              variant="outlined"
              placeholder="Seacrh By Department"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </SearchField>
      </div>
      <div style={{ margin: "15px" }}>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">
                      Department
                      <span
                        onClick={departmentSorted}
                        style={{ cursor: "pointer" }}>
                        {sort.department === "asc" ? "▲" : "▼"}
                      </span>
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">
                      Note
                      <span onClick={noteSorted} style={{ cursor: "pointer" }}>
                        {sort.note === "asc" ? "▲" : "▼"}
                      </span>
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Action</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {search
                  ? searchFilter.map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}>
                          {row.department}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}>
                          {row.note}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}>
                          <div className="edit-delete">
                            <Tooltip title="Edit" disableInteractive>
                              <Button onClick={() => handleEdit(row._id)}>
                                <Edit />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Delete" disableInteractive>
                              <Button onClick={() => setDeleteModal(row._id)}>
                                <Delete />
                              </Button>
                            </Tooltip>
                          </div>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={row._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  : schoolDepartment.map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}>
                          {row.department}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}>
                          {row.note}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}>
                          <div className="edit-delete">
                            <Tooltip title="Edit" disableInteractive>
                              <Button onClick={() => handleEdit(row._id)}>
                                <Edit />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Delete" disableInteractive>
                              <Button onClick={() => setDeleteModal(row._id)}>
                                <Delete />
                              </Button>
                            </Tooltip>
                          </div>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={row._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          {!schoolDepartment.length && !search.trim() && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}>
              No data found
            </Typography>
          )}
          {search.trim() && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}>
              No data found
            </Typography>
          )}
        </Paper>
        <div className="add-icon">
          <AddDepartment
            loader={loader}
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            handleUpdate={handleUpdate}
            updateDepartment={updateDepartment}
          />
        </div>
      </div>
    </div>
  );
};

export default Department;
