import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  Box,
  Button,
  Card,
  Divider,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useEffect } from "react";

const ContainerBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
   width: "100%",
  maxWidth:"600px",
  borderRadius:"5px",
  height: "auto",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
    width: "90%",
  },
  [theme.breakpoints.between(819,821)]: {
    overflow: "auto",
    width: "100%",
  height: "100%",

  },
  [theme.breakpoints.between(767,769)]: {
    overflow: "auto",
    width: "100%",
  height: "100%",

  },
  [theme.breakpoints.between(911,913)]: {
    overflow: "auto",
    width: "100%",
  height: "100%",

  },
  [theme.breakpoints.between(1023,1025)]: {
    overflow: "auto",
    width: "100%",
  height: "100%",

  },
  [theme.breakpoints.between(1279,1281)]: {
    overflow: "auto",
    width: "100%",
  height: "100%",

  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}
const rows = [];

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function TeacherLeave() {
  const [state, setState] = useState({
    leaveType: "",
    startDate: "",
    endDate: "",
    information: "",
    subject: "",
    leaveDays: "",
    description: "",
  });
  const [value, setValue] = React.useState(0);
  const [range, setRange] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [numberOfDays, setNumberOfDays] = useState(0);
  const [editTeacherLeave, setEditTeacherLeave] = useState();
  const classes = useStyles();

  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");

  let handleNumberOfDay = (e, v) => {
    const { name, value } = e.target;

    const indexOfV = range.indexOf(v);
    let updatedList = range.map((item, index) =>
      index == indexOfV ? { ...item, value: value } : item
    );
    setRange(updatedList);
  };

  useEffect(() => {
    let number = 0;
    for (let item of range) {
      if (item.value) {
        if (item.value === "fullday") {
          number += 1;
        } else {
          number += 0.5;
        }
      }
    }
    setNumberOfDays(number);
  }, [range]);

  useEffect(() => {
    if (state.endDate && state.startDate) {
      const start = new Date(state.startDate);
      const end = new Date(state.endDate);
      if (start <= end) {
        const currentDate = new Date(start);
        let list = [];
        while (currentDate <= end) {
          let newItem = {
            value: "",
            date: new Date(currentDate).toLocaleDateString(),
          };
          list.push(newItem);
          currentDate.setDate(currentDate.getDate() + 1);
        }
        setRange(list);
      }
    }
  }, [state.endDate, state.startDate]);

  const {
    leaveType,
    startDate,
    endDate,
    information,
    subject,
    leaveDays,
    description,
  } = state;

  let handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let payload = {
        leaveType,
        startDate,
        endDate,
        subject,
        numberOfDays,
        description,
      };
      console.log(payload);
    } catch (error) {
      console.log(error);
    }
    setState({
      leaveType: "",
      startDate: "",
      endDate: "",
      information: "",
      subject: "",
      leaveDays: "",
      description: "",
    });
    setOpen(false);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      rows.length > 0 &&
        setFilter(
          rows.filter((ele) =>
            ele.name.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setFilter([]);
    }
  };

  let handleCancel = () => {
    setOpen(false);
    setState({});
  };

  let handleEdit = () => {
    setOpen(true);
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Card style={{ margin: "15px", padding: "15px" }}>
        <Grid container spacing={2} sx={{display:"flex",justifyContent:"flex-start",alignItems:"flex-end"}}>
          <Grid item xs={12} mg={12} lg={12}>
            <span
              style={{
                color: "red",
                fontFamily: "cursive",
                textAlign: "start",
              }}
            >
              Teacher Leave:
            </span>
          </Grid>
        </Grid>
      </Card>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", margin: 3 }}>
          <Tabs
            value={value}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
          >
            <Tab label="Leave List" {...a11yProps(0)} />
            <Tab label="Manage Leave" {...a11yProps(1)} />
          </Tabs>
          <Divider />
        </Box>
        <TabPanel value={value} index={0}>
          <Box>
            <Paper
              style={{ width: "100%", height: "60px", overflow: "hidden" }}
            >
              <Grid container >
                <Grid item xs={12} md={6} lg={12} sx={{  display: "flex",
          alignItems: "center",
          m:"0.5%",
          p:"0.5%",
          justifyContent: { md: "right",sm:"right", xs: "center" }}}>
                  <TextField
                    size="small"
                    value={search}
                    onChange={handleSearch}
                    // style={{ marginTop: "14px", width: "90%" }}
                    label="Search"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Paper>
          </Box>
          <Box>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead style={{ background: "rgb(27 55 121)" }}>
                    <TableRow>
                      <TableCell>
                        <span style={{ color: "white" }}>#SL</span>
                      </TableCell>
                      <TableCell align="center">
                        <span style={{ color: "white" }}>Name</span>
                      </TableCell>
                      <TableCell align="center">
                        <span style={{ color: "white" }}>Leave Type</span>
                      </TableCell>
                      <TableCell align="center">
                        <span style={{ color: "white" }}>Number of days</span>
                      </TableCell>
                      <TableCell align="center">
                        <span style={{ color: "white" }}>Start date</span>
                      </TableCell>
                      <TableCell align="center">
                        <span style={{ color: "white" }}>End date</span>
                      </TableCell>
                      <TableCell align="center">
                        <span style={{ color: "white" }}>Status</span>
                      </TableCell>
                      <TableCell align="center">
                        <span style={{ color: "white" }}>Action</span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => (
                      <TableRow style={{ border: 0 }}>
                        <TableCell
                          key={row.name}
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.leavetype}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.noOfDays}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.startDate}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.endDate}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.status}
                        </TableCell>
                        <div className="edit-delete">
                          <TableCell>
                            <Tooltip title="Edit">
                              <Button>
                                <Edit
                                  onClick={() => handleEdit(row._id, row)}
                                />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <Button>
                                <Delete />
                              </Button>
                            </Tooltip>
                          </TableCell>
                        </div>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {!rows.length && (
                <Typography
                  variant="h6"
                  style={{ textAlign: "center", margin: "5px", padding: "5px" }}
                >
                  No data found
                </Typography>
              )}
            </Paper>
          </Box>
        </TabPanel>
      </Box>
      <Box className="add-icon">
        <Tooltip title="Add">
          <Fab
            size="medium"
            style={{ background: "#1b3779", color: "#fff" }}
            aria-label="add"
            onClick={handleOpen}
          >
            <Add />
          </Fab>
        </Tooltip>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form action="" onSubmit={handleSubmit}>
          {/* <Card style={style}> */}
          <ContainerBox>
            <Box sx={{ marginBottom: "15px" }}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                align="center"
              >
                {editTeacherLeave ? "Update Leave" : "Add Leave"}
              </Typography>
            </Box>
            <Box>
              <Grid container spacing={2}  sx={{display:"flex",justifyContent:"flex-start",alignItems:"flex-end"}}>
                <Grid
                  item
                  xs={12}
                  md={6}
                     lg={6}
                  // style={{ marginBottom: "15px" }}
                >
                  <FormControl fullWidth     variant="standard"
                      style={{ label: { fontSize: 12 } }}>
                    <InputLabel id="demo-simple-select-filled-label"
                  
                    >
                      Leave Type
                    </InputLabel>
                    <Select
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      name="leaveType"
                      value={leaveType}
                      onChange={handleChange}
                      style={{ label: { fontSize: 12 } }}
                    >
                      <MenuItem value={"select"}>--select--</MenuItem>
                      <MenuItem value={"sickleave"}>Sickleave</MenuItem>
                      <MenuItem value={"earnedleave"}>Earnedleave</MenuItem>
                      <MenuItem value={"compoff"}>compoff</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                     lg={6}
                  // style={{ marginBottom: "15px" }}
                >
                  <FormControl fullWidth>
                    <TextField
                      required
                      id="filled-required"
                      variant="standard"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ label: { fontSize: 12 } }}
                      label="Start Date"
                      name="startDate"
                      value={startDate}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                     lg={6}
                  // style={{ marginBottom: "15px" }}
                >
                  <FormControl fullWidth>
                    <TextField
                      required
                      id="filled-required"
                      variant="standard"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ label: { fontSize: 12 } }}
                      label="End Date"
                      name="endDate"
                      value={endDate}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                     lg={6}
                  // style={{ marginBottom: "15px" }}
                >
                  <FormControl fullWidth>
                    <TextField
                      required
                      id="basic-standard"
                      variant="standard"
                      label="Subject"
                      name="subject"
                      value={subject}
                      onChange={handleChange}
                      style={{ label: { fontSize: 12 } }}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                     lg={6}
                  // style={{
                  //   marginBottom: "15px",
                  //   display: "flex",
                  //   justifyContent: "flex-start",
                  //   alignItems: "center",
                  // }}
                >
                  <FormControl>
                    <Typography>Number of days : {numberOfDays}</Typography>
                  </FormControl>
                </Grid>
                {!endDate ? (
                  <></>
                ) : (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    style={{
                      // marginBottom: "15px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <FormControl
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography required>Information :</Typography>
                      {!endDate ? (
                        <></>
                      ) : (
                        <Box style={{ height: "100px", overflow: "scroll" }}>
                          {range.map((date, index) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Typography>{date.date}</Typography>
                              <Select
                                value={date.value}
                                labelId="demo-simpless-select-filled-label"
                                id="mySelect"
                                name="numberOfDays"
                                style={{
                                  minWidth: 150,
                                  margin: "5px",
                                  marginLeft: "30px",
                                  fontSize: "15px",
                                }}
                                onChange={(e) => handleNumberOfDay(e, date)}
                              >
                                <MenuItem value={"fullday"}>FullDay</MenuItem>
                                <MenuItem value={"firsthalf"}>
                                  First half
                                </MenuItem>
                                <MenuItem value={"secondHalf"}>
                                  Second half
                                </MenuItem>
                              </Select>
                            </Box>
                          ))}
                        </Box>
                      )}
                    </FormControl>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  // style={{ marginBottom: "15px" }}
                >
                  <FormControl fullWidth>
                    <TextareaAutosize
                      maxRows={4}
                      aria-label="maximum height"
                      placeholder="Description"
                      name="description"
                      value={description}
                      onChange={handleChange}
                      style={{
                        width: "100%",
                        maxHeight: 110,
                        padding: 10,
                        height: 70,
                        overflow: "auto",
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid>
                  <FormControl
                    fullWidth
                    sx={{ marginLeft: "1rem", mt: "1rem" }}
                  >
                    <label htmlFor="upload">Upload</label>
                    <input type="file" name="upload" id="upload" />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: {
                        md: "right",
                        sm: "right",
                        xs: "center",
                        gap: "1%",
                      },
                    }}
                  >
                    <Tooltip title="Cancel">
                      <Button
                        onClick={handleCancel}
                        variant="outlined"
                        size="medium"
                        type="button"
                        // style={{ m: 1 }}
                      >
                        Cancel
                      </Button>
                    </Tooltip>
                    <Tooltip title="Submit">
                      <Button
                        variant="contained"
                        size="medium"
                        type="submit"
                        style={{
                          marginLeft: "10px",
                          color: "white",
                          background: "rgb(27, 55, 121)",
                          ":hover": { background: "rgb(27, 55, 121)" },
                        }}
                      >
                        {editTeacherLeave ? "Update" : "Add"}
                      </Button>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </ContainerBox>
          {/* </Card> */}
        </form>
      </Modal>
    </>
  );
}
export default TeacherLeave;
