import {
  Box,
  Button,
  Dialog,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import { Add } from "@mui/icons-material";
import { Delete, Edit, Search } from "@mui/icons-material";
import { urls as url } from "../../../services/urlConstant";
import { get, post, put, del } from "../../../services/apis";
import appendStringToUrls from "../../../utils/appendString";
import SettingContext from "../../../context/SettingsContext";
import DeleteModal from "../../Layout/Academic/DeleteModal";
import { useTheme } from "@mui/material/styles";

const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
};
const ContainerBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "600px",
  maxHeight: "650px",
  borderRadius: "5px",
  height: "auto",
  // overflow:"scroll",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
    width: "90%",
  },
  [theme.breakpoints.between(819, 821)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(767, 769)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(911, 913)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1023, 1025)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1279, 1281)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Horizontal = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);
  const [editText, setEditText] = useState();
  const [addForm, setAddForm] = useState({});
  const [flashNews, setFlashNews] = useState([]);
  const [deleteModal, setDeleteModal] = useState();
  const { settings, setSettings, selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setEditText(null);
    setAddForm({});
    setOpen(false);
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(`${urls.announceFlashNews.delFlashNews}`, id);
      if (res.data.success) {
        const filteredEvent = flashNews.filter((item) => item._id !== id);
        setFlashNews(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNewsEdit = (id, data) => {
    setEditText(id);
    setAddForm({
      title: data.title,
      text: data.text,
      webView: data.webView,
    });
    handleClickOpen();
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAddForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };
  useEffect(() => {
    getSplashNews();
  }, [selectedSetting]);
  const getSplashNews = async () => {
    try {
      const response = await get(urls.announceFlashNews.getFlashNews, {
        params: {
          page: 1,
          limit: 10,
        },
      });
      const horizontalvalues = response.data.data.filter(
        (newitem) => newitem.type === "Horizontal"
      );
      setFlashNews(horizontalvalues);
      console.log("Horizontal", horizontalvalues);
    } catch (error) {
      console.log(error);
    }
  };
  const handleAddSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!editText) {
        const { status } = await post(
          `${urls.announceFlashNews.postFlashNews}`,
          {
            title: addForm.title,
            text: addForm.text,
            webView: addForm.webView,
            type: "Horizontal",
            contentType: "Text",
          }
        );
        getSplashNews();
        handleClose();
      } else {
        const { status } = await put(
          `${urls.announceFlashNews.putFlashNews}${editText}`,
          "",
          {
            title: addForm.title,
            text: addForm.text,
            webView: addForm.webView,
            type: "Horizontal",
            contentType: "Text",
          }
        );
        getSplashNews();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleAddSubmit}>
          <Box sx={style}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  textAlign="center"
                  fontSize="20px"
                  fontWeight="bold"
                >
                  {editText ? "Edit" : "Add"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  fullWidth
                  required
                  size="small"
                  label=" Splash News Title"
                  name="title"
                  value={addForm.title}
                  onChange={handleInputChange}
                  sx={{
                    label: { fontSize: 12 },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  fullWidth
                  required
                  size="small"
                  label="Text"
                  name="text"
                  onChange={handleInputChange}
                  value={addForm.text}
                  sx={{
                    label: { fontSize: 12 },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormControl fullWidth size="small">
                  <InputLabel sx={{ fontSize: 12 }}>Is view on web?</InputLabel>
                  <Select
                    label="Is View On Web"
                    name="webView"
                    onChange={handleInputChange}
                    value={addForm.webView}
                  >
                    <MenuItem
                      sx={{ fontSize: 12, fontWeight: 500 }}
                      value={"yes"}
                    >
                      Yes
                    </MenuItem>
                    <MenuItem
                      sx={{ fontSize: 12, fontWeight: 500 }}
                      value={"no"}
                    >
                      No
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: { md: "right", xs: "center" },
                  }}
                >
                  <Tooltip title="Cancel">
                    <Button
                      variant="contained"
                      size="medium"
                      type="button"
                      color="error"
                      sx={{ m: 1, color: "#fff" }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </Tooltip>
                  <Tooltip title="Submit">
                    <Button
                      variant="contained"
                      size="medium"
                      type="submit"
                      sx={{
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                    >
                      Submit
                    </Button>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Dialog>
      <div style={{ marging: 15 }}>
        <Table aria-label="simple table">
          <TableHead style={{ background: "rgb(27 55 121)" }}>
            <TableRow>
              <TableCell align="center">
                <span className="class-table-header">#SL</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Title</span>
              </TableCell>

              <TableCell align="center">
                <span className="class-table-header">Text</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Is View On Web?</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Action </span>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {flashNews.map((data, index) => (
              <TableRow>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell
                  align="center"
                  style={{ textTransform: "capitalize" }}
                >
                  {data.title}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ textTransform: "capitalize" }}
                >
                  {data.text}
                </TableCell>

                <TableCell
                  align="center"
                  style={{ textTransform: "capitalize" }}
                >
                  {" "}
                  {data.webView}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ textTransform: "capitalize" }}
                >
                  <div className="edit-delete">
                    <Tooltip title="Edit">
                      <Button onClick={() => handleNewsEdit(data._id, data)}>
                        <Edit />
                      </Button>
                    </Tooltip>

                    <Tooltip title="Delete">
                      <div>
                        <Button
                          style={{
                            color: "#1b3779",
                          }}
                          onClick={() => setDeleteModal(data._id, data)}
                        >
                          <Delete />
                        </Button>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDeleteone}
                          id={data._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </div>
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {!flashNews.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No Data Found
          </Typography>
        )}
      </div>
      <div className="add-icon">
        <Fab
          size="medium"
          style={{ background: "#1b3779", color: "#fff" }}
          aria-label="add"
          onClick={handleClickOpen}
        >
          <Tooltip title="Add">
            <Add />
          </Tooltip>
        </Fab>
      </div>
    </div>
  );
};

export default Horizontal;
