import {
  Box,
  Button,
  Card,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Delete, Menu, Search } from "@mui/icons-material";
import SettingContext from "../../../context/SettingsContext";
import { urls as url } from "../../../services/urlConstant";

import { get, post, put } from "../../../services/apis";
import { useLocation, useNavigate } from "react-router-dom";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../SectionSubHeader";
import Wrapper from "../../Wrapper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "65%",
  overflow: "auto",
  bgcolor: "background.paper",
  p: 4,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Item = () => {
  let navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [open, setOpen] = React.useState(false);
  const [openStock, setOpenStock] = React.useState(false);
  const [openIssue, setOpenIssue] = React.useState(false);
  const { selectedSetting } = useContext(SettingContext);
  const [tabValue, setTabValue] = useState(0);
  const [departments, setDepartments] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [searchFilter, setSearchFilter] = useState([]);
  // const [employee, setEmployee] = useState([]);
  const [inventoryData, setInventoryData] = useState([]);

  const [editInventoryType, setEditInventoryType] = useState();

  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const { state: localState } = useLocation();

  useEffect(() => {
    if (localState) {
      if (localState == "item") {
        setTabValue(1);
      }
    }
  }, [localState]);

  const styles = {
    button: {
      backgroundColor: "orange",
    },
    card: {
      padding: 10,
      margin: "10px 0px",
    },
    cardButton: {
      display: "flex",
      justifyContent: "flex-end",
      textAlign: "center",
      gap: "10px",
    },

    textfield: {
      marginTop: "25px",
      width: "70%",
    },
    addbutton: {
      paddingTop: "6.2px",
      paddingBottom: "6.2px",
    },
  };
  const handleClose = () => {
    setOpen(false);
    setData({});
    setEditInventoryType(null);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleCloseStock = () => {
    setOpenStock(false);
  };
  const handleClickOpenStock = () => {
    setOpenStock(true);
  };
  const handleCloseIssue = () => {
    setOpenIssue(false);
  };
  const handleClickOpenIssue = () => {
    setOpenIssue(true);
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      inventoryData.length > 0 &&
        setSearchFilter(
          inventoryData.filter((ele) =>
            ele.name.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  let [data, setData] = useState({
    name: "",
    department: "",
    itemId: "",
    description: "",
    brand: "",
  });

  let { name, department, itemId, description, brand } = data;

  let handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(data);
    let payload = { name, department, itemId, description, brand };
    console.log(payload);

    if (!editInventoryType) {
      try {
        let { data } = await post(`${urls.inventory.createItem}`, payload);
        let newList = [...inventoryData, data.data];
        setInventoryData(newList);
        fetchData();
        setTabValue(0);
        setShowEdit(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      const response = await put(
        `${urls.inventory.updateItem}${editInventoryType}`,
        "",
        payload
      );
      console.log(response);
      setInventoryData(
        inventoryData.map((e) => {
          if (e._id === response.data.data._id) {
            return response.data.data;
          } else {
            return e;
          }
        })
      );
      fetchData();
      setTabValue(0);
      setShowEdit(false);
    }

    setData({
      name: "",
      department: "",
      itemId: "",
      description: "",
      brand: "",
    });
  };

  useEffect(() => {
    const fetchDept = async () => {
      try {
        let { data } = await get(`${urls.department.getDepartment}`);
        setDepartments(data.data);
        console.log(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchDept();
  }, [selectedSetting]);

  useEffect(() => {
    fetchData();
  }, [selectedSetting]);
  const fetchData = async () => {
    try {
      let { data } = await get(`${urls.inventory.getAllItem}`);
      console.log(data.data, "datattta");
      setInventoryData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  let handleEdit = (id, value) => {
    console.log("value", value);
    setOpen(true);
    setEditInventoryType(id);
    setTabValue(2);
    setShowEdit(true);

    console.log("id", id);

    setData({
      name: value.name,
      department: value.department._id,
      itemId: value.itemId,
      description: value.description,
      brand: value.brand,
    });
  };

  // let [stockData, setStockData] = useState([]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       let { data } = await get(urls.stockList.getAll);
  //       console.log(data.data, "stock");
  //       setStockData(data.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   fetchData();
  // }, [selectedSetting]);

  let [issue, setIssue] = useState({ name: "" });

  let handleChangeIssue = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setIssue({ ...issue, [name]: value });
  };

  let handleSubmitIssue = (e) => {
    e.preventDefault();
    console.log(issue);
  };

  let handleNavigate = (item) => {
    navigate("/dashboard/category", { state: item });
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Wrapper>
      <SectionSubHeader title="Item" />
      <div style={{ margin: "15px 0" }}>
        <Card style={styles.card}>
          <Box>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab label="Item List" {...a11yProps(0)} />
              <Tab label="Add Item" {...a11yProps(1)} />
              {showEdit && <Tab label="Edit Item" {...a11yProps(2)} />}
            </Tabs>
          </Box>
        </Card>
        <TabPanel value={tabValue} index={0}>
          <Card style={{ padding: "10px", margin: "10px 0px" }}>
            <div style={styles.cardButton}>
              <Button variant="contained">Excel</Button>
              <Button variant="contained">Pdf</Button>
            </div>
          </Card>
          <Paper
            sx={{ width: "100%", height: "60px", overflow: "hidden", mt: 2 }}
          >
            <div style={{ paddingTop: "10px" }}>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item xs="auto">
                  <form>
                    <TextField
                      size="small"
                      value={search}
                      onChange={handleSearch}
                      label="Search"
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton edge="end" type="submit">
                              <Search />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </form>
                </Grid>
              </Grid>
            </div>
          </Paper>
          <Table sx={{ marginTop: "3rem" }}>
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Name
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Deparment
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Brand
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter.map((item, index) => (
                    <TableRow key={item._id}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{item.name}</TableCell>
                      <TableCell align="center">
                        {item.department.department}
                      </TableCell>
                      <TableCell align="center">{item.brand}</TableCell>
                      <TableCell align="center">
                        <div className="edit-delete">
                          <Button onClick={() => handleEdit(item._id, item)}>
                            <EditIcon />
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                : inventoryData.map((item, index) => {
                    return (
                      <TableRow key={item._id}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">{item.name}</TableCell>
                        <TableCell align="center">
                          {item.department.department}
                        </TableCell>
                        <TableCell align="center">{item.brand}</TableCell>
                        <TableCell align="center">
                          <div className="edit-delete">
                            <Button onClick={() => handleEdit(item._id, item)}>
                              <EditIcon />
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
          {!inventoryData.length && !search.trim() && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {search.trim() && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Paper sx={{ marginTop: 2 }}>
            <form className="form-tag" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    required
                    id="outlined-basic"
                    label="Enter Name"
                    size="small"
                    name="name"
                    value={name}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <FormControl sx={{ width: "100%" }} size="small">
                    <InputLabel id="demo-select-small-label">
                      Department
                    </InputLabel>
                    <Select
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      name="department"
                      value={data.department || ""}
                      onChange={handleChange}
                      label="Department"
                    >
                      {departments.map((value, index) => (
                        <MenuItem key={value._id} value={value._id}>
                          {value.department}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    required
                    id="outlined-basic"
                    label="Enter brand"
                    name="brand"
                    value={brand}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    required
                    id="outlined-basic"
                    label="Enter Item id"
                    name="itemId"
                    value={itemId}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextareaAutosize
                    placeholder="Description"
                    name="description"
                    value={description}
                    onChange={handleChange}
                    size="small"
                    style={{
                      width: "100%",
                      maxHeight: 110,
                      // margin: 12,
                      borderRadius: "5px",
                      padding: 10,
                      height: 118,
                      overflow: "auto",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  display="flex"
                  justifyContent={{
                    xs: "center",
                    sm: "center",
                    md: "flex-end",
                    lg: "flex-end",
                  }}
                >
                  <Tooltip title="Cancel">
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      color="error"
                      size="medium"
                      type="button"
                      sx={{ m: 1, color: "#fff" }}
                    >
                      Cancel
                    </Button>
                  </Tooltip>
                  <Tooltip title="Submit">
                    <Button
                      variant="contained"
                      size="medium"
                      type="submit"
                      sx={{
                        m: 1,
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                    >
                      Add
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </TabPanel>
        {showEdit && (
          <TabPanel value={tabValue} index={2}>
            <form className="form-tag" onSubmit={handleSubmit}>
              {/* <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                textAlign="center"
                marginBottom="30px"
              >
                Add Info
              </Typography> */}

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    required
                    id="outlined-basic"
                    label="Enter Name"
                    name="name"
                    value={name}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    // sx={{ m: 1, label: { fontSize: 12 } }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-select-small-label">
                      Department
                    </InputLabel>
                    <Select
                      label="Department"
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      name="department"
                      value={data.department || ""}
                      onChange={handleChange}
                    >
                      {departments.map((value, index) => (
                        <MenuItem key={value._id} value={value._id}>
                          {value.department}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    required
                    id="outlined-basic"
                    label="Enter brand"
                    name="brand"
                    value={brand}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    // sx={{ m: 1, label: { fontSize: 12 } }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    required
                    id="outlined-basic"
                    label="Enter Item id"
                    name="itemId"
                    value={itemId}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    // sx={{ m: 1, label: { fontSize: 12 } }}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <TextareaAutosize
                    placeholder="Description"
                    name="description"
                    value={description}
                    onChange={handleChange}
                    style={{
                      width: "100%",
                      maxHeight: 110,
                      borderRadius: "5px",
                      padding: 10,
                      height: 118,
                      overflow: "auto",
                    }}
                  />
                </Grid>
              </Grid>

              <div className="cancel-submit-btn">
                <div>
                  <Tooltip title="Cancel">
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      color="error"
                      size="medium"
                      type="button"
                      sx={{ m: 1, color: "#fff" }}
                    >
                      Cancel
                    </Button>
                  </Tooltip>
                  <Tooltip title="Submit">
                    <Button
                      variant="contained"
                      size="medium"
                      type="submit"
                      sx={{
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                    >
                      Add
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </form>
          </TabPanel>
        )}
      </div>
    </Wrapper>
  );
};

export default Item;
