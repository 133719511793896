import {
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get, put } from "../../../services/apis";
import roleSettings from "./roleSettings.json";
import roleSetting from "./roleSetting.json";
import { urls as url } from "../../../services/urlConstant";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { useNavigate } from "react-router-dom";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const CRUD_OPERATIONS = ["view", "add", "update", "delete"];

function AssignRolePermission() {
  const navigate = useNavigate();
  const { selectedSetting } = useContext(SettingContext);
  const [roleData, setRoleData] = useState({});
  const [storageData, setStorageData] = useState({});
  const params = useParams();

  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const handleCheckBoxClicked = (event) => {
    const { name, checked } = event.target;
    const nameArray = name.split("/");
    const [moduleName, propertyName] = nameArray;

    const moduleValue = roleData[moduleName];
    const updatedModuleValue = {
      ...moduleValue,
      [propertyName]: checked,
    };

    setRoleData({
      ...roleData,
      [moduleName]: updatedModuleValue,
    });
  };

  useEffect(() => {
    function setUserRole() {
      const storageData = localStorage.getItem("role");

      if (storageData) {
        setStorageData(JSON.parse(storageData));
      }
    }
    setUserRole();
    window.addEventListener("storage", setUserRole);

    return () => {
      window.removeEventListener("storage", setUserRole);
    };
  }, []);

  const getRoleData = async () => {
    const roleData = await get(`${urls.role.getAllRole}/${params.id}`);
    setRoleData(roleData.data.data);
  };

  const handleBulkUpdate = (event) => {
    const { name, checked } = event.target;
    const toUpdateRoleDate = { ...roleData };
    for (const roleKey of Object.keys(toUpdateRoleDate)) {
      const moduleName = toUpdateRoleDate[roleKey];
      if (typeof moduleName.accessible !== "undefined") {
        // this check is for roleName property
        moduleName[name] = checked;
      }
    }
    setRoleData(toUpdateRoleDate);
  };

  const updateRole = async () => {
    const updatedRoleData = await put(
      `${urls.role.putRole}${params.id}`,
      "",
      roleData
    );
    console.log(updatedRoleData);
  };

  useEffect(() => {
    getRoleData();
  }, [params.id]);

  return (
    roleData &&
    Object.keys(roleData).length > 0 && (
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Card style={{ margin: 15, padding: 10 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} mg={12} lg={12}>
              <span style={{ color: "red", fontFamily: "cursive" }}>
                Editing Role Permission For:
                <span style={{ color: "black" }}>{roleData.roleName}</span>
              </span>
            </Grid>
          </Grid>
        </Card>

        <Box sx={{ margin: "15px" }}>
          <TableContainer sx={{ maxHeight: 520 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      border: "1px solid #c5c1c1",
                      backgroundColor: "skyblue",
                    }}
                  >
                    Module Name
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c5c1c1",
                      backgroundColor: "skyblue",
                    }}
                  >
                    Function Name
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c5c1c1",
                      backgroundColor: "skyblue",
                    }}
                  >
                    View
                    <Checkbox onChange={handleBulkUpdate} name="view" />
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c5c1c1",
                      backgroundColor: "skyblue",
                    }}
                  >
                    Add <Checkbox onChange={handleBulkUpdate} name="add" />{" "}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c5c1c1",
                      backgroundColor: "skyblue",
                    }}
                  >
                    Update{" "}
                    <Checkbox onChange={handleBulkUpdate} name="update" />
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c5c1c1",
                      backgroundColor: "skyblue",
                    }}
                  >
                    Delete{" "}
                    <Checkbox onChange={handleBulkUpdate} name="delete" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {roleSetting.modules.map((roleModule) => {
                  const { label, functionalities } = roleModule;
                  return (
                    <>
                      <TableRow>
                        <TableCell
                          sx={{
                            backgroundColor: "#EEEEEE",
                          }}
                          colSpan={6}
                        >
                          <b>{label}</b>
                        </TableCell>
                      </TableRow>
                      {functionalities.map((func) => {
                        const { label, id } = func;
                        const markup = (
                          <TableRow>
                            <TableCell>{"   "}</TableCell>
                            <TableCell
                              style={{ borderRight: "1px solid #c5c1c1" }}
                            >
                              {label}
                            </TableCell>
                            {CRUD_OPERATIONS.map((operation) => {
                              return roleData[id] &&
                                roleData[id][operation] !== undefined ? (
                                <TableCell
                                  key={operation}
                                  style={{
                                    borderRight: "1px solid #c5c1c1",
                                    textAlign: "center",
                                  }}
                                >
                                  <Checkbox
                                    name={`${id}/${operation}`}
                                    onChange={handleCheckBoxClicked}
                                    value={
                                      roleData[id]
                                        ? roleData[id][operation]
                                        : false
                                    }
                                    checked={
                                      roleData[id]
                                        ? roleData[id][operation]
                                        : false
                                    }
                                  />
                                </TableCell>
                              ) : (
                                <TableCell
                                  style={{
                                    borderRight: "1px solid #c5c1c1",
                                  }}
                                ></TableCell>
                              );
                            })}
                          </TableRow>
                        );
                        return markup;
                      })}
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Stack spacing={2} direction="row" margin={1}>
            <Button
              variant="contained"
              sx={{ color: "#ffff" }}
              color="error"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              onClick={updateRole}
              sx={{ marginRight: "2px" }}
              variant="contained"
            >
              Update
            </Button>
          </Stack>
        </div>
      </Paper>
    )
  );
}

export default AssignRolePermission;
