import { eventdetails } from "./EventsDetails";
import { Link, useLocation, useParams } from "react-router-dom";
import { CardComponent } from "./Events";
import { Box, Container, Typography, createTheme } from "@mui/material";
import styled from "@emotion/styled";
import style from "./Events.module.css";
import { ThemeProvider } from "@emotion/react";
import CustomComponent from "./CustomComponent";

import moment from "moment";
import Footer from "../Footer";
import NavBar from "../../NavBar";
import Mainnav from "../../NavbarComponents/MainNav";

const theme = createTheme();

const Text = styled(Box)(({ theme }) => ({
  textAlign: "center",
}));
const Heading = styled(Typography)(({ theme }) => ({
  color: "#FFFFFF",
  fontSize: "2rem",
  textTransform: "uppercase",
  fontFamily: "sans-serif",
  fontWeight: "bold",
}));

const Heading1 = styled(Typography)(({ theme }) => ({
  color: "#FFFFFF",
  fontSize: "1rem",
  fontFamily: "sans-serif",
  fontWeight: "bold",
}));

const Headin = styled(Link)(({ theme }) => ({
  color: "#FFFFFF",
  fontSize: "1rem",
  fontFamily: "sans-serif",
  fontWeight: "bold",
  textDecoration: "none",
}));

const BoxSize = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "30px",
  [theme.breakpoints.down("md")]: {},
}));

const OuterBox = styled(Box)(({ theme }) => ({
  backgroundImage:
    "linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.8)),url(https://c4.wallpaperflare.com/wallpaper/869/719/717/cuisine-food-india-indian-wallpaper-preview.jpg)",
  backgroundRepeat: "no-repeat",
  width: "100%",
  backgroundSize: "100% 100%",
  paddingTop: "176px",
}));

const EventInfo = () => {
  const { id } = useParams();

  const { state } = useLocation();

  console.log("state events ff", state);

  return (
    <>
      <>
        <OuterBox sx={{ paddingTop: { md: "176px", sm: "235px", xs: "236px" } }}>
             <Mainnav/>
         </OuterBox>
        <Container>
          <ThemeProvider theme={theme}>
            <BoxSize>
              {/* <CardComponent 
        elem={cardData} 
        makeStyle={{minWidth:'19%',
        paddingLeft:'45%'}} 
        Style={{paddingLeft:'45%'}} 
        View={{width:'10%',marginTop:'2px'}}
        /> */}

              <CustomComponent elem={state} />
            </BoxSize>
          </ThemeProvider>
        </Container>
        <Footer/>
      </>
    </>
  );
};
export default EventInfo;
