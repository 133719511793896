import React, { PureComponent, useEffect, useState, useContext } from "react";
import { get } from "../../../services/apis";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Paper } from "@mui/material";
import { getRandomColor } from "../../../utils/utils";
import style from "./chatBar.module.css";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";

const ChartBar = () => {
  const [sectionNames, setSectionNames] = useState([]);
  const [studentDetails, setStudentDetails] = useState([]);
  const { selectedSetting } = useContext(SettingContext);

  useEffect(() => {
    const getEvent = async () => {
      try {
        const res = await get(
          `/${selectedSetting._id}/dashboard/students-strength-class-section`
        );
        setSectionNames(res.data.data.sectionNames);
        setStudentDetails(res.data.data.strengthDetails);
      } catch (error) {
        console.log(error);
      }
    };
    getEvent();
  }, [selectedSetting._id]);

  return (
    <ResponsiveContainer width="100%" height="70%">
      <Paper
        sx={{
          width: "92%",
          height: "100%",
          marginLeft: "20px",
          marginRight: "50px",
        }}
      >
        <BarChart
          className={style.boxChart}
          width={700}
          height={370}
          data={studentDetails}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          {sectionNames.map((sectionName) => (
            <Bar
              key={sectionName}
              dataKey={`${sectionName}`}
              fill={`${getRandomColor()}`}
              stackId="a"
            />
          ))}
        </BarChart>
      </Paper>
    </ResponsiveContainer>
  );
};
export default ChartBar;
