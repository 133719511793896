import React, { useState } from "react";

import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid,
  Card,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TableHead,
  Avatar,
  Modal,
  Typography,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { useEffect, useContext } from "react";
import { urls as url } from "../../../services/urlConstant";
import "../../../styles/components/Exam/Exam.scss";
import { get, put } from "../../../services/apis";
import { objHas } from "../../../utils/utils";

import style from "../../../styles/components/Layout/Attendance/AttendanceReport.module.css";
import { DownloadForOffline, Print } from "@mui/icons-material";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../../components/SectionSubHeader";

const styles = {
  card: {
    padding: 10,
    width: "100%",
    marginBottom: "10px",
    marginTop: 20,
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
    m: 1,
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
    // marginTop: "10px",
  },
};
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fitContent",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ExamResult = () => {
  const { selectedSetting } = useContext(SettingContext);
  const [studentsExamResult, setStudentsExamResult] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [examTerms, setExamterms] = useState([]);
  const [examResultDetails, setExamResultDetails] = useState({});
  const [academicYear, setAcademicYear] = useState([]);
  const [studentMarksDetailsModal, setStudentMarksDetailsModal] =
    useState(false);
  const [studentExamResult, setStudentExamResult] = useState(undefined);
  const [bulkUploadModal, setBulkUploadModal] = useState(false);
  const [sheet, setSheet] = useState(undefined);
  const [fileChoosen, setFileChoosen] = useState(undefined);
  const [sheetLoader, setSheetLoader] = useState(false);
  const [pdfLoader, setPdfLoader] = useState(false);

  const [value, setValue] = useState(false);
  const [sort, setSort] = useState({
    rollNo: "asc",
    name: "asc",
    totalMaxMarks: "asc",
    totalScoredMarks: "asc",
    percentage: "asc",
    grade: "asc",
  });

  let [loader, setLoader] = useState(false);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const sortByRollNo = () => {
    console.log("RollNo");
    if (sort.rollNo === "asc") {
      let sortedList = [
        ...studentsExamResult.sort(
          (a, b) => b.academicInfo.rollNo - a.academicInfo.rollNo
        ),
      ];

      setStudentsExamResult(sortedList);
      setSort({ ...sort, rollNo: "des" });
    } else {
      let sortedList = [
        ...studentsExamResult.sort(
          (a, b) => a.academicInfo.rollNo - b.academicInfo.rollNo
        ),
      ];
      setStudentsExamResult(sortedList);
      setSort({ ...sort, rollNo: "asc" });
    }
  };

  const sortByGrade = () => {
    console.log("Grade");
    if (sort.grade === "asc") {
      let sortedList = [
        ...studentsExamResult.sort((a, b) => b.grade - a.grade),
      ];

      setStudentsExamResult(sortedList);
      setSort({ ...sort, grade: "des" });
    } else {
      let sortedList = [
        ...studentsExamResult.sort((a, b) => a.grade - b.grade),
      ];
      setStudentsExamResult(sortedList);
      setSort({ ...sort, grade: "asc" });
    }
  };

  const sortByTotalMaxMarks = () => {
    console.log("TotalMarks");
    if (sort.totalMaxMarks === "asc") {
      let sortedList = [
        ...studentsExamResult.sort((a, b) => b.totalMaxMarks - a.totalMaxMarks),
      ];

      setStudentsExamResult(sortedList);
      setSort({ ...sort, totalMaxMarks: "des" });
    } else {
      let sortedList = [
        ...studentsExamResult.sort((a, b) => a.totalMaxMarks - b.totalMaxMarks),
      ];
      setStudentsExamResult(sortedList);
      setSort({ ...sort, totalMaxMarks: "asc" });
    }
  };
  const handleFileChange = (e) => {
    setFileChoosen(e.target.files[0].name);
  };
  const sortByTotalScoredMarks = () => {
    console.log("TotalScoredMarks");
    if (sort.totalScoredMarks === "asc") {
      let sortedList = [
        ...studentsExamResult.sort(
          (a, b) => b.totalScoredMarks - a.totalScoredMarks
        ),
      ];

      setStudentsExamResult(sortedList);
      setSort({ ...sort, totalScoredMarks: "des" });
    } else {
      let sortedList = [
        ...studentsExamResult.sort(
          (a, b) => a.totalScoredMarks - b.totalScoredMarks
        ),
      ];
      setStudentsExamResult(sortedList);
      setSort({ ...sort, totalScoredMarks: "asc" });
    }
  };

  const sortByPercentage = () => {
    console.log("gggg");
    if (sort.percentage === "asc") {
      let sortedList = [
        ...studentsExamResult.sort((a, b) => b.percentage - a.percentage),
      ];

      setStudentsExamResult(sortedList);
      setSort({ ...sort, percentage: "des" });
    } else {
      let sortedList = [
        ...studentsExamResult.sort((a, b) => a.percentage - b.percentage),
      ];
      setStudentsExamResult(sortedList);
      setSort({ ...sort, percentage: "asc" });
    }
  };

  const sortByName = () => {
    console.log("name");
    if (sort.name === "asc") {
      let sortedList = [
        ...studentsExamResult.sort((a, b) =>
          b.basicInfo.name.localeCompare(a.basicInfo.name)
        ),
      ];

      setStudentsExamResult(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      let sortedList = [
        ...studentsExamResult.sort((a, b) =>
          a.basicInfo.name.localeCompare(b.basicInfo.name)
        ),
      ];
      setStudentsExamResult(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };

  const handleClasses = async (e) => {
    try {
      const { name, value } = e.target;
      setExamResultDetails({ ...examResultDetails, [name]: value });
      const res = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: value,
          },
        },
      });
      setSections(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSections = (e) => {
    const { name, value } = e.target;
    setExamResultDetails({ ...examResultDetails, [name]: value });
  };

  const handleExamTerms = (e) => {
    const { name, value } = e.target;
    setExamResultDetails({ ...examResultDetails, [name]: value });
  };

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        setClasses(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, [selectedSetting]);

  useEffect(() => {
    const getExamTerms = async () => {
      try {
        const res = await get(urls.examTerm.getAllExamTerm);
        setExamterms(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getExamTerms();
  }, [selectedSetting]);
  useEffect(() => {
    const getAcademicYear = async () => {
      try {
        const res = await get(urls.administrator.academicYearGet);
        setAcademicYear(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYear();
  }, [selectedSetting]);
  const handleFindStudentResult = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      const { examTitle, sectionName, className } = examResultDetails;
      const resultsRes = await get(
        `/${selectedSetting._id}/students-exam-percentage/class/${className}/section/${sectionName}/exam/${examTitle}`
      );
      if (resultsRes.data.success) {
        setStudentsExamResult(resultsRes.data.data);
      } else {
        console.log(resultsRes);
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };
  const handleModalClose = () => setStudentMarksDetailsModal(false);
  const handleModalOpen = () => setStudentMarksDetailsModal(true);
  const handleStudentMarksDetails = async (stud) => {
    try {
      const detailedMarksRes = await get(
        `/${selectedSetting._id}/exam-result-student/student/${stud._id}/exam/${examResultDetails.examTitle}`
      );

      if (detailedMarksRes.data.success) {
        setStudentExamResult(detailedMarksRes.data.data);
        handleModalOpen();
      } else {
        console.log(detailedMarksRes);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleGettingMarkCard = async (stud) => {
    try {
      const studentMarkCard = await get(
        `/${selectedSetting._id}/exam-result-report/student/${stud._id}/exam/${examResultDetails.examTitle}`,
        {
          responseType: "blob",
        }
      );
      const downloadUri = URL.createObjectURL(studentMarkCard.data);
      window.open(downloadUri, "__blank");
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetUploadSheet = async () => {
    try {
      const { className, sectionName, examTitle } = examResultDetails;
      const getMarksSheet = await get(
        `/${selectedSetting._id}/marks-update-sheet/${className}/${sectionName}/${examTitle}`,
        {
          responseType: "blob",
        }
      );
      console.log("class", classes);
      const url = window.URL.createObjectURL(new Blob([getMarksSheet.data]));
      const link = document.createElement("a");
      link.href = url;
      const classename = className;
      const getExcelById = classes.find((excel) => excel._id === classename);
      const sectionsname = sectionName;
      const getExcelsectionById = sections.find(
        (excel) => excel._id === sectionsname
      );
      const excelname = examTitle;
      const getExcelExamById = examTerms.find(
        (excel) => excel._id === excelname
      );

      link.setAttribute(
        "download",
        `${getExcelById.className}Std ,${getExcelsectionById.sectionName},${getExcelExamById.examTitle}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetAllUploadSheet = async () => {
    try {
      const { className, examTitle } = examResultDetails;
      const getAllMarksSheet = await get(
        `/${selectedSetting._id}/students-alllist-excel/${className}/${examTitle}`,
        {
          responseType: "blob",
        }
      );
      console.log("class", classes);
      const url = window.URL.createObjectURL(new Blob([getAllMarksSheet.data]));
      const link = document.createElement("a");
      link.href = url;
      const classename = className;
      const getExcelById = classes.find((excel) => excel._id === classename);

      const excelname = examTitle;
      const getExcelExamById = examTerms.find(
        (excel) => excel._id === excelname
      );

      link.setAttribute(
        "download",
        `${getExcelById.className}Std ,${getExcelExamById.examTitle}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSheetUpload = async (e) => {
    setValue(true);
    e.preventDefault();
    console.log("test ", e.target.files);
    try {
      const formData = new FormData();
      formData.append("sheet", fileChoosen.e.target.files[0]);
      const { className, sectionName, examTitle } = examResultDetails;
      const uploadSheet = await put(
        `/${selectedSetting._id}/marks-update-sheet/${className}/${sectionName}/${examTitle}`,
        "",
        formData
      );
      // const uploadSheet = awai
    } catch (error) {
      console.log(error);
    }
    setValue(false);
  };
  const handleMarksPdfDownload = async () => {
    try {
      setPdfLoader(true);
      if (
        examResultDetails.className &&
        examResultDetails.sectionName &&
        examResultDetails.examTitle
      ) {
        const getMarksListPdf = await get(
          `/${selectedSetting._id}/students-marks-pdf/${examResultDetails.className}/${examResultDetails.sectionName}/${examResultDetails.examTitle}`,
          {
            responseType: "blob",
          }
        );
        if (getMarksListPdf.status === 200) {
          const uri = URL.createObjectURL(getMarksListPdf.data);
          const link = document.createElement("a");
          link.href = uri;
          const getPDFacademicyearById = academicYear.find(
            (excel) => excel.status === "active"
          );
          const classename = examResultDetails.className;
          const getPDFById = classes.find((excel) => excel._id === classename);
          const sectionsname = examResultDetails.sectionName;
          const getPDFsectionById = sections.find(
            (excel) => excel._id === sectionsname
          );
          const examsTitle = examResultDetails.examTitle;
          const getPDFexamTitleById = examTerms.find(
            (excel) => excel._id === examsTitle
          );
          link.setAttribute(
            "download",
            `${getPDFacademicyearById.academicYearFrom}-${getPDFacademicyearById.academicYearTo} ${getPDFById.className}th std ,${getPDFsectionById.sectionName} ${getPDFexamTitleById.examTitle}.pdf`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      }
      setPdfLoader(false);
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleMarksExcelDownload = async () => {
    try {
      setSheetLoader(true);
      if (
        examResultDetails.className &&
        examResultDetails.sectionName &&
        examResultDetails.examTitle
      ) {
        const getMarksListSheet = await get(
          `/${selectedSetting._id}/students-marks-excel/${examResultDetails.className}/${examResultDetails.sectionName}/${examResultDetails.examTitle}`,
          {
            responseType: "blob",
          }
        );

        if (getMarksListSheet.status === 200) {
          const uri = URL.createObjectURL(getMarksListSheet.data);
          const link = document.createElement("a");
          link.href = uri;
          const getExcelacademicyearById = academicYear.find(
            (excel) => excel.status === "active"
          );
          console.log("academicYear", academicYear);
          const classename = examResultDetails.className;
          const getExcelById = classes.find(
            (excel) => excel._id === classename
          );
          const sectionsname = examResultDetails.sectionName;
          const getExcelsectionById = sections.find(
            (excel) => excel._id === sectionsname
          );
          const examsTitle = examResultDetails.examTitle;
          const getExcelexamTitleById = examTerms.find(
            (excel) => excel._id === examsTitle
          );

          link.setAttribute(
            "download",
            `${getExcelacademicyearById.academicYearFrom}-${getExcelacademicyearById.academicYearTo} ${getExcelById.className}th std ,${getExcelsectionById.sectionName} ${getExcelexamTitleById.examTitle}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      }
      setSheetLoader(false);
    } catch (error) {
      toast.error(error.message);
    }
  };
  // const handleGetUpdateSheet = async (e) => {
  //   e.preventDefault();
  //   setLoadingBtn(true);

  //   try {
  //     if (formData.section !== "all") {
  //       const getSheet = await get(
  //         `/bulk-update/student/${formData.schoolClass}/${formData.section}/${formData.academicYear}`,
  //         {
  //           responseType: "blob",
  //         }
  //       );
  //       const uri = URL.createObjectURL(getSheet.data);

  //       const link = document.createElement("a");
  //       link.href = uri;

  //       const academicsYear = formData.academicYear;
  //       const getExcelyearById = academicYears.find(
  //         (excel) => excel._id === academicsYear
  //       );
  //       const classename = formData.schoolClass;
  //       const getExcelById = classes.find((excel) => excel._id === classename);
  //       const sectionsname = formData.section;
  //       const getExcelsectionById = sections.find(
  //         (excel) => excel._id === sectionsname
  //       );

  //       link.setAttribute(
  //         "download",
  //         `${getExcelyearById.academicYearFrom}-${getExcelyearById.academicYearTo} ${getExcelById.className}th std ,${getExcelsectionById.sectionName}.xlsx`
  //       );
  //       document.body.appendChild(link);
  //       link.click();
  //       link.parentNode.removeChild(link);
  //       setLoadingBtn(false);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  return (
    <div>
      <div style={{ margin: 15 }}>
        <SectionSubHeader title=" Exam Result " />

        <form onSubmit={handleFindStudentResult}>
          <Card style={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                }}
              >
                <Grid item xs={12} md={3} lg={3}>
                  <FormControl size="small" sx={styles.textfield} required>
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Class
                    </InputLabel>
                    <Select
                      label="Class"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="className"
                      value={examResultDetails.className || ""}
                      onChange={(event) => handleClasses(event)}
                    >
                      {classes &&
                        classes.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.className}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <FormControl size="small" sx={styles.textfield} required>
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Section
                    </InputLabel>
                    <Select
                      label="Section"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="sectionName"
                      value={examResultDetails.sectionName || ""}
                      onChange={(event) => handleSections(event)}
                    >
                      {sections &&
                        sections.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.sectionName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <FormControl size="small" sx={styles.textfield} required>
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Exam
                    </InputLabel>
                    <Select
                      label="Exam"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="examTitle"
                      value={examResultDetails.examTitle || ""}
                      onChange={(event) => handleExamTerms(event)}
                    >
                      {examTerms &&
                        examTerms.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.examTitle}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={3}
                  lg={3}
                  sx={{
                    alignSelf: "center",
                    display: "flex",
                    justifyContent: { md: "left", xs: "center" },
                  }}
                >
                  <Tooltip title="Find">
                    <LoadingButton
                      loading={loader}
                      variant="contained"
                      style={styles.Button}
                      size="small"
                      type="submit"
                    >
                      Find
                    </LoadingButton>
                  </Tooltip>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={2}
                  sx={{
                    alignSelf: "center",
                  }}
                >
                  {examResultDetails.className &&
                    examResultDetails.sectionName &&
                    examResultDetails.examTitle && (
                      <LoadingButton
                        loading={loader}
                        size="small"
                        variant="contained"
                        style={styles.Button}
                        onClick={() => setBulkUploadModal(true)}
                      >
                        Bulk Upload
                      </LoadingButton>
                    )}
                </Grid>
              </Grid>
            </Box>
          </Card>
        </form>
      </div>
      <div style={{ margin: "15px" }}>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          {examResultDetails.className &&
            examResultDetails.sectionName &&
            examResultDetails.examTitle && (
              <div className={style.iconContainer}>
                <div>
                  {sheetLoader ? (
                    <Button size="small">
                      <CircularProgress size={25} />
                    </Button>
                  ) : (
                    <Button onClick={handleMarksExcelDownload}>
                      <DownloadForOffline />
                    </Button>
                  )}
                </div>
                <div>
                  {pdfLoader ? (
                    <Button size="small">
                      <CircularProgress size={25} />
                    </Button>
                  ) : (
                    <Button onClick={handleMarksPdfDownload}>
                      <Print />
                    </Button>
                  )}
                </div>
              </div>
            )}
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">Roll No</span>
                    <span
                      onClick={sortByRollNo}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.rollNo === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Name</span>
                    <span
                      onClick={sortByName}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.name === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Photo</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Exam Mark</span>
                    <span
                      onClick={sortByTotalMaxMarks}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.totalMaxMarks === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Obtained Mark</span>
                    <span
                      onClick={sortByTotalScoredMarks}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.totalScoredMarks === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Percentage</span>
                    <span
                      onClick={sortByPercentage}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.percentage === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Grade</span>
                    <span
                      onClick={sortByGrade}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.grade === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">View</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {studentsExamResult &&
                  studentsExamResult.map((result, index) => (
                    <TableRow
                      key={result._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center">
                        {result.academicInfo.rollNo}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {result.basicInfo.name}
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{
                          textTransform: "capitalize",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Avatar
                          alt="Remy Sharp"
                          src={`${objHas(result, "studentPhoto.link", "")}`}
                        />
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {result.totalMaxMarks}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {result.totalScoredMarks}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {result.percentage}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {result.grade}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        <Button
                          style={{
                            background: "#1b3779",
                            ":hover": { background: "#1b3779" },
                            margin: "0px 2px",
                          }}
                          size="small"
                          variant="contained"
                          onClick={() => handleStudentMarksDetails(result)}
                        >
                          View
                        </Button>
                        <Button
                          style={{
                            background: "#1b3779",
                            ":hover": { background: "#1b3779" },
                            margin: "0px 2px",
                          }}
                          size="small"
                          variant="contained"
                          onClick={() => handleGettingMarkCard(result)}
                        >
                          Card
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {!studentsExamResult.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </Paper>
        <Modal
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          open={studentMarksDetailsModal}
          onClose={handleModalClose}
        >
          <Box sx={modalStyle}>
            {studentExamResult && (
              <TableContainer
                component={Paper}
                style={{
                  width: 700,
                }}
              >
                <Table sx={{ minWidth: 250 }} aria-label="simple table">
                  <TableHead style={{ background: "rgb(27 55 121)" }}>
                    <TableRow>
                      <TableCell align="center">
                        <span className="class-table-header">SL No</span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="class-table-header">Subject</span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="class-table-header">Max Marks</span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="class-table-header">
                          Obtained Marks
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="class-table-header">Grade</span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {studentExamResult &&
                      studentExamResult.studentMarks.map((mark, index) => (
                        <TableRow
                          key={mark._id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {mark.subject.subjectName}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {mark.maxMarks}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {mark.writtenMarks}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {mark.grade}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {studentExamResult && (
              <div>
                <Typography variant="subtitle1">
                  Total Subject: {studentExamResult.studentMarks.length}
                </Typography>
                <Typography variant="subtitle1">
                  Max Marks: {studentExamResult.totalMax}
                </Typography>
                <Typography variant="subtitle1">
                  Obtained Marks: {studentExamResult.totalMarks}
                </Typography>
                <Typography variant="subtitle1">
                  Percentage: {studentExamResult.percentage}
                </Typography>
              </div>
            )}
          </Box>
        </Modal>
        <Modal
          open={bulkUploadModal}
          onClose={() => setBulkUploadModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <div style={{ textAlign: "center", marginBottom: "30px" }}>
              <span style={{ color: "red", fontFamily: "cursive" }}>
                Bulk Upload
              </span>
            </div>
            <form onSubmit={handleSheetUpload}>
              <div>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <Button
                        variant="contained"
                        style={styles.Button}
                        onClick={handleGetUploadSheet}
                      >
                        Download
                      </Button>
                    </div>

                    <div style={{ position: "relative" }}>
                      <Button
                        variant="contained"
                        sx={styles.Button}
                        component="label"
                      >
                        Upload
                        <input
                          onChange={handleFileChange}
                          name="file"
                          hidden
                          accept=".xlsx, .xls, xlsm, .xlsb, .csv, .ods"
                          type="file"
                        />
                      </Button>
                      <div
                        style={{
                          position: "fixed",
                          top: 70,
                          right: 10,
                          visibility: fileChoosen ? "visible" : "hidden",
                        }}
                      >
                        {fileChoosen && fileChoosen}
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <Button
                        variant="contained"
                        style={styles.Button}
                        onClick={handleGetAllUploadSheet}
                      >
                        Download All Student
                      </Button>
                    </div>
                    <LoadingButton
                      loading={value}
                      variant="contained"
                      sx={styles.Button}
                      type="submit"
                    >
                      submit
                    </LoadingButton>
                  </Grid>
                </Grid>
              </div>
            </form>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default ExamResult;
