import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Button,
  Grid,
  Tooltip,
  styled,
  useMediaQuery,
  Dialog,
} from "@mui/material";
import "../../../styles/components/AcademicYear/DateModal.scss";
import { Add } from "@mui/icons-material";
import { useState, useContext } from "react";
import { useEffect } from "react";
import dayjs from "dayjs";
import { post } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";

import { useTheme } from "@mui/material/styles";

const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
};

const ContainerBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "600px",
  maxHeight: "650px",
  borderRadius: "5px",
  height: "auto",
  // overflow:"scroll",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
    width: "90%",
  },
  [theme.breakpoints.between(819, 821)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(767, 769)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(911, 913)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1023, 1025)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1279, 1281)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const AddNews = (props) => {
  const { selectedSetting } = useContext(SettingContext);

  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [shortNews, setShortNews] = useState("");
  const [news, setNews] = useState("");
  const [webView, setWebView] = useState("");
  const [image, setImage] = useState(null);
  const [imageLink, setImageLink] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  useEffect(() => {
    if (props.updateNews) {
      setTitle(props.updateNews.title);
      setDate(dayjs(props.updateNews.date).format("YYYY-MM-DD"));
      setShortNews(props.updateNews.shortNews);
      setNews(props.updateNews.news);
      setWebView(props.updateNews.webView);
      setImageLink(props.updateNews.image ? props.updateNews.image.link : "");
    }
  }, [props.updateNews]);

  const handleTitle = (e) => {
    setTitle(e.target.value);
  };
  const handleDate = (e) => {
    setDate(e.target.value);
  };
  const handleShortNews = (e) => {
    setShortNews(e.target.value);
  };
  const handleNews = (e) => {
    setNews(e.target.value);
  };
  const handleWebView = (e) => {
    setWebView(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    props.setLoader(true);
    try {
      let imageRes = null;
      if (image) {
        console.log("reach img");
        const fileForm = new FormData();
        fileForm.append("files", image);
        imageRes = await post(urls.fileUpload.postFileUpload, fileForm);
      }
      let data = {
        title,
        date,
        shortNews,
        news,
        webView,
      };
      if (imageRes && imageRes.data.success) {
        data.image = imageRes.data.result[0]._id;
      }
      if (props.updateNews) {
        await props.handleUpdate(data, props.updateNews._id);
      } else {
        await props.handleSubmit(e, data);
      }
      setImage(null);
      setImageLink("");
      await props.getAllNews();
      setTitle("");
      setDate("");
      setShortNews("");
      setNews("");
      setWebView("");

      props.handleClose();
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong try again later");
    }
    props.setLoader(false);
  };

  const handleCloseModal = () => {
    setTitle("");
    setDate("");
    setShortNews("");
    setNews("");
    setWebView("");
    setImageLink("");
    setImage(null);
    props.handleClose();
  };

  const handleClose = () => {
    setTitle("");
    setDate("");
    setShortNews("");
    setNews("");
    setWebView("");
    setImageLink("");
    setImage(null);
    props.handleClose();
  };
  const handleImgChange = (e) => {
    setImage(e.target.files[0]);
  };
  return (
    <div>
      <Fab
        size="medium"
        style={{ background: "#1b3779", color: "#fff" }}
        aria-label="add"
        onClick={props.handleOpen}
      >
        <Tooltip title="Add">
          <Add />
        </Tooltip>
      </Fab>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleSubmit}>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign="center"
              marginBottom="20px"
              fontSize="20px"
              fontWeight="bold"
            >
              Add News
            </Typography>
            <>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                }}
              >
                <Grid item xs={12} lg={6} md={6}>
                  <TextField
                    fullWidth
                    required
                    size="small"
                    label="News Title"
                    value={title}
                    onChange={handleTitle}
                    sx={{
                      // m: 1,
                      // minWidth: { md: 227, xs: "100%" },
                      label: { fontSize: 12 },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    fullWidth
                    required
                    size="small"
                    label="Date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={date}
                    onChange={handleDate}
                    sx={{
                      // m: 1,
                      // minWidth: { md: 227, xs: "100%" },
                      label: { fontSize: 12 },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <FormControl
                    fullWidth
                    size="small"
                    // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
                    required
                  >
                    <InputLabel sx={{ fontSize: 12 }}>
                      Is view on web?
                    </InputLabel>
                    <Select
                      label="Is View On Web"
                      value={webView}
                      onChange={handleWebView}
                    >
                      <MenuItem
                        value={"yes"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Yes
                      </MenuItem>
                      <MenuItem
                        value={"no"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        No
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  sx={{
                    // border:"solid red",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    // textAlign:"center"
                  }}
                >
                  <>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImgChange}
                    />
                    {props.updateNews && imageLink && (
                      <img src={`${imageLink}`} alt="" width={30} height={30} />
                    )}
                  </>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <TextareaAutosize
                    maxRows={4}
                    aria-label="maximum height"
                    placeholder="Short News"
                    value={shortNews}
                    onChange={handleShortNews}
                    style={{
                      // width: 712,
                      // maxHeight: 110,
                      // margin: 8,
                      // padding: 10,
                      // height: 70,
                      // overflow: "auto",
                      width: "100%",
                      maxHeight: 110,
                      // margin: 8,
                      borderRadius: "5px",
                      padding: 10,
                      height: 70,
                      overflow: "auto",
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <TextareaAutosize
                    maxRows={4}
                    aria-label="maximum height"
                    placeholder="News"
                    value={news}
                    onChange={handleNews}
                    style={{
                      // width: 712,
                      // maxHeight: 110,
                      // margin: 8,
                      // padding: 10,
                      // height: 70,
                      // overflow: "auto",
                      width: "100%",
                      maxHeight: 110,
                      borderRadius: "5px",
                      padding: 10,
                      height: 70,
                      overflow: "auto",
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: { md: "right", xs: "center" },
                    }}
                  >
                    <Tooltip title="Cancel">
                      <Button
                        variant="contained"
                        size="medium"
                        type="button"
                        color="error"
                        sx={{ m: 1, color: "#fff" }}
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                    </Tooltip>
                    <Tooltip title="Submit">
                      <LoadingButton
                        loading={props.loader}
                        variant="contained"
                        size="medium"
                        type="submit"
                        sx={{
                          background: "rgb(27, 55, 121)",
                          ":hover": { background: "rgb(27, 55, 121)" },
                        }}
                      >
                        Submit
                      </LoadingButton>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            </>
          </Box>
        </form>
      </Dialog>
    </div>
  );
};

export default AddNews;
