import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Button,
  Tooltip,
  Grid,
  styled,
  Dialog,
  useMediaQuery,
} from "@mui/material";
import "../../../styles/components/AcademicYear/DateModal.scss";
import { Add } from "@mui/icons-material";
import { useState, useContext } from "react";
import { useEffect } from "react";
import { get } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { useTheme } from "@mui/material/styles";

const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 4,
};
// const ContainerBox = styled(Box)(({ theme }) => ({
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "100%",
//   maxWidth: "650px",
//   borderRadius: "5px",
//   height: "auto",
//   backgroundColor: "white",
//   padding: "20px",
//   // border:"solid red",
//   [theme.breakpoints.down("md")]: {
//     overflow: "auto",
//     width: "90%",
//   },
//   [theme.breakpoints.between(819, 821)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.between(767, 769)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.between(911, 913)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.between(1023, 1025)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.between(1279, 1281)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "100%",
//     height: "100%",
//     overflow: "auto",
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "center",
//   },
// }));

const AddClassModal = (props, { handleLoader }) => {
  const { selectedSetting } = useContext(SettingContext);
  const [className, setClassName] = useState("");
  const [numericName, setNumericName] = useState("");
  const [orderSequence, setOrderSequence] = useState("");
  const [studentAttendenceType, setStudentAttendenceType] = useState("");
  const [classTeacher, setClassTeacher] = useState([]);
  const [classNote, setClassNote] = useState("");
  const [status, setStatus] = useState("");
  const [employees, setEmployees] = useState([]);
  const [isPublish, setIsPublish] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const url = appendStringToUrls(urls, `/${selectedSetting._id}`);

  useEffect(() => {
    if (props.updateClass) {
      setClassName(
        props.updateClass.className ? props.updateClass.className : ""
      );
      setNumericName(
        props.updateClass.numericName ? props.updateClass.numericName : ""
      );
      setOrderSequence(
        props.updateClass.orderSequence ? props.updateClass.orderSequence : ""
      );
      setStudentAttendenceType(
        props.updateClass.studentAttendenceType
          ? props.updateClass.studentAttendenceType
          : ""
      );
      setClassTeacher(
        props.updateClass.classTeacher ? props.updateClass.classTeacher : ""
      );
      setClassNote(
        props.updateClass.classNote ? props.updateClass.classNote : ""
      );
      setStatus(props.updateClass.status ? props.updateClass.status : "");
      setIsPublish(
        props.updateClass.isPublish ? props.updateClass.isPublish : ""
      );
    }
  }, [props.updateClass]);

  const handleClassName = (e) => {
    setClassName(e.target.value);
  };
  const handleNumericName = (e) => {
    setNumericName(e.target.value);
  };
  const handleOrderSequence = (e) => {
    setOrderSequence(e.target.value);
  };
  const handleStudentAttendenceType = (e) => {
    setStudentAttendenceType(e.target.value);
  };
  const handleClassTeacher = (e) => {
    setClassTeacher(e.target.value);
  };
  const handleClassNote = (e) => {
    setClassNote(e.target.value);
  };
  const handleStatus = (e) => {
    setStatus(e.target.value);
  };
  const handleIsPublish = (e) => {
    setIsPublish(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (props.updateClass) {
      await props.handleUpdate(
        {
          className,
          orderSequence,
          studentAttendenceType,
          classNote,
          classTeacher,
          numericName,
          status,
          isPublish,
        },
        props.updateClass._id
      );
    } else {
      await props.handleSubmit(e, {
        className,
        orderSequence,
        studentAttendenceType,
        classNote,
        classTeacher,
        numericName,
        status,
        isPublish,
      });
    }

    setClassName("");
    setNumericName("");
    setOrderSequence("");
    setStudentAttendenceType("");
    setClassTeacher([]);
    setClassNote("");
    setStatus("");
    setIsPublish("");
    props.handleClose();
  };

  const handleCloseModal = () => {
    setClassName("");
    setNumericName("");
    setOrderSequence("");
    setStudentAttendenceType("");
    setClassTeacher([]);
    setClassNote("");
    setStatus("");
    setIsPublish("");
    props.handleClose();
  };

  useEffect(() => {
    const getEmps = async () => {
      try {
        const getEmps = await get(url.employee.getAllEmployee);
        setEmployees(getEmps.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getEmps();
  }, []);
  return (
    <>
      <Tooltip title="Add" disableInteractive>
        <Fab
          size="medium"
          style={{ background: "#1b3779", color: "#fff" }}
          aria-label="add"
          onClick={props.handleOpen}
        >
          <Add />
        </Fab>
      </Tooltip>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleSubmit}>
          <Box sx={style}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-end",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  textAlign="center"
                  fontSize="20px"
                  fontWeight="bold"
                >
                  Add Class
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  size="small"
                  label="Class Name"
                  value={className}
                  onChange={handleClassName}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <FormControl fullWidth size="small" required>
                  <InputLabel sx={{ fontSize: 12 }}>
                    Student Attendance Type
                  </InputLabel>
                  <Select
                    label=" Student Attendance Type"
                    value={studentAttendenceType}
                    onChange={handleStudentAttendenceType}
                  >
                    <MenuItem
                      value={"classWise"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Classwise
                    </MenuItem>
                    <MenuItem
                      value={"sessionWise"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Sessionwise
                    </MenuItem>
                    <MenuItem
                      value={"scetionWise"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Sectionwise
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <FormControl fullWidth size="small" required>
                  <InputLabel>Class Teacher</InputLabel>
                  <Select
                    id="demo-simple-select-filled"
                    label="Class Teacher"
                    multiple={true}
                    value={classTeacher || null}
                    onChange={handleClassTeacher}
                  >
                    {employees &&
                      employees.map((emp, i) => (
                        <MenuItem
                          key={emp._id}
                          value={emp._id}
                          style={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {emp.basicInfo.empName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <FormControl fullWidth size="small">
                  <InputLabel>Status</InputLabel>
                  <Select
                    id="demo-simple-select-filled"
                    label="Status"
                    value={status}
                    onChange={handleStatus}
                  >
                    <MenuItem
                      value={"inactive"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Inactive
                    </MenuItem>
                    <MenuItem
                      value={"active"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Active
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-required-label">
                    Is Publish? *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-required-label"
                    id="demo-simple-select-required"
                    label="Is Publish"
                    value={isPublish}
                    onChange={handleIsPublish}
                  >
                    <MenuItem
                      value={"inactive"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Inactive
                    </MenuItem>
                    <MenuItem
                      value={"active"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Active
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  type="number"
                  size="small"
                  label="Order Sequence"
                  value={orderSequence}
                  onChange={handleOrderSequence}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  type="number"
                  size="small"
                  label="Numeric Name"
                  value={numericName}
                  onChange={handleNumericName}
                />
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <TextareaAutosize
                  maxRows={4}
                  aria-label="maximum height"
                  placeholder="Drop a note"
                  value={classNote}
                  onChange={handleClassNote}
                  style={{
                    width: "100%",
                    maxHeight: 110,

                    padding: 10,
                    height: 70,
                    overflow: "auto",
                  }}
                />
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: { md: "right", xs: "center" },
                  }}
                >
                  <>
                    <Button
                      variant="contained"
                      size="medium"
                      color="error"
                      type="button"
                      sx={{ m: 1, color: "#fff" }}
                      onClick={() => props.handleClose()}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      loading={props.loader}
                      variant="contained"
                      size="medium"
                      type="submit"
                      sx={{
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                    >
                      Submit
                    </LoadingButton>
                  </>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Dialog>
    </>
  );
};

export default AddClassModal;
