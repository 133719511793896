// import React, { useEffect, useRef, useState } from "react";
// import {
//   Button,
//   Box,
//   Card,
//   Grid,
//   TextareaAutosize,
//   TextField,
//   MenuItem,
//   FormControl,
//   Select,
//   InputLabel,
//   Dialog,
//   Typography,
// } from "@mui/material";
// import dayjs from "dayjs";
// import { del, get, post, put } from "../../../services/apis";
// import { urls as url } from "../../../services/urlConstant";
// import { useContext } from "react";
// import SettingContext from "../../../context/SettingsContext";
// import LoadingButton from "@mui/lab/LoadingButton";
// import Stack from "@mui/material/Stack";
// import appendStringToUrls from "../../../utils/appendString";
// import { Add } from "@mui/icons-material";
// import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
// import TimezoneSelect from "react-timezone-select";
// import { Params, useParams } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

// const styles = {
//   card: {
//     padding: 15,
//     marginBottom: 20,
//     display: "flex",
//     alignItem: "center",
//     justifyContent: "space-between",
//     overflow: "visible",
//   },
//   textfield: {
//     width: "100%",
//     label: { fontSize: 12 },
//   },
//   Button: {
//     background: "#1b3779",
//     ":hover": { background: "#1b3779" },
//     color: "#fff",
//   },
// };

// const Settings = () => {
//   const { id } = useParams();

//   const navigate = useNavigate();
//   console.log(id, "iddddddd");
//   const { selectedSetting, setSelectedSetting, settings, setSettings } =
//     useContext(SettingContext);
//   const [loading, setLoading] = useState(false);
//   const [selectedTimezone, setSelectedTimezone] = useState({});
//   const [previewUrl, setPreviewUrl] = useState(null);
//   const [schoolLogo, setSchoolLogo] = useState("");
//   const [settingDetails, setSettingDetails] = useState(null);
//   const [createDetails, setCreateDetails] = useState({
//     regDate: dayjs(Date.now()),
//     sessionStartMonth: dayjs(Date.now()),
//     sessionEndMonth: dayjs(Date.now()),
//   });

//   const [previewCreateUrl, setPreviewCreateUrl] = useState(null);
//   const [schoolCreateLogo, setSchoolCreatelLogo] = useState("");
//   const [schoolCreateLogoLink, setSchoolCreateLogoLink] = useState("");
//   const [sessionStartMonth, setSessionStartMonth] = useState(dayjs(Date.now()));
//   const [sessionEndMonth, setSessionEndMonth] = useState(dayjs(Date.now()));
//   const [startMonth, setStartMonth] = useState(dayjs(Date.now()));
//   const [endMonth, setEndMonth] = useState(dayjs(Date.now()));
//   const [isSettingExists, setIsSettingExists] = useState(false);
//   const [schoolLogoLink, setSchoolLogoLink] = useState("");
//   const { setSettingsContext } = useContext(SettingContext);
//   const [school, setSchool] = useState([]);
//   const [addDialog, setAddDialog] = useState(false);

//   const [setting, setSetting] = useState(null);

//   const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
//   const handleChangeTimeZone = (t) => {
//     setSettingDetails({ ...settingDetails, defaultTimeZone: t });
//   };
//   const handleOnChange = (e) => {
//     const { name, value } = e.target;
//     setSettingDetails({ ...settingDetails, [name]: value });
//   };
//   const handleCreateOnChange = (e) => {
//     const { name, value } = e.target;
//     setCreateDetails({ ...createDetails, [name]: value });
//   };
//   const handleCreateOnChangeTime = (d) => {
//     setCreateDetails({ ...createDetails, defaultTimeZone: d });
//   };

//   const handlePhoto = async (e) => {
//     const formData = new FormData();
//     formData.append("files", e.target.files[0]);
//     try {
//       setPreviewUrl(URL.createObjectURL(e.target.files[0]));
//       const res = await post(urls.fileUpload.postFileUpload, formData);
//       setSettingDetails({ ...settingDetails, logo: res.data.result[0]._id });
//       setSchoolLogoLink(res.data.result[0].link);
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   const handleCreatePhoto = async (e) => {
//     const formData = new FormData();
//     formData.append("files", e.target.files[0]);
//     try {
//       setPreviewCreateUrl(URL.createObjectURL(e.target.files[0]));
//       const res = await post(urls.fileUpload.postFileUpload, formData);
//       setCreateDetails({ ...createDetails, logo: res.data.result[0]._id });
//       setSchoolCreateLogoLink(res.data.result[0].link);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleSubmit = async (e) => {
//     setLoading(true);
//     try {
//       e.preventDefault();
//       const data = {
//         ...settingDetails,
//         schoolName: settingDetails.schoolName,
//         address: settingDetails.address,
//         phone: settingDetails.phone,
//         regDate: dayjs(settingDetails.regDate).format("YYYY-MM-DD"),
//         email: settingDetails.email,
//         schFax: settingDetails.schFax,
//         websiteFooter: settingDetails.websiteFooter,
//         description: settingDetails.description,
//         logo: settingDetails.logo,

//         currency: settingDetails.currency,
//         currencySymbol: settingDetails.currencySymbol,
//         sessionStartMonth: settingDetails.sessionStartMonth,
//         sessionEndMonth: settingDetails.sessionEndMonth,
//         longitude: settingDetails.longitude,
//         rollNumberType: settingDetails.rollNumberType,
//         admissionNo: settingDetails.admissionNo,
//         latitude: settingDetails.latitude,

//         googleAnalyticsId: settingDetails.googleAnalyticsId,
//         defaultTimeZone: settingDetails.defaultTimeZone,
//       };
//       if (setting) {
//         const res = await put(
//           urls.settings.putSettings,
//           settingDetails._id,
//           data
//         );
//         setSettings(
//           settings.map((s) => (s._id === res.data.data._id ? res.data.data : s))
//         );

//         setSelectedSetting(res.data.data);
//         setSettingsContext((prev) => ({
//           ...prev,
//           schoolName: data.schoolName,
//           schoolLogo: schoolLogoLink,
//         }));

//         setLoading(false);
//       } else {
//         const res = await post(urls.settings.postSettings, data);
//         setSettingsContext((prev) => ({
//           ...prev,
//           schoolName: data.schoolName,
//           schoolLogo: schoolLogoLink,
//         }));
//         setSelectedSetting(res.data.data);
//       }
//     } catch (error) {
//       setLoading(false);
//       console.log(error);
//     }
//   };

//   const handleCreateSubmit = async (e) => {
//     setLoading(true);
//     try {
//       e.preventDefault();
//       const data = {
//         ...createDetails,
//         schoolName: createDetails.schoolName,
//         address: createDetails.address,
//         phone: createDetails.phone,
//         regDate: dayjs(createDetails.regDate).format("YYYY-MM-DD"),
//         email: createDetails.email,
//         schFax: createDetails.schFax,
//         websiteFooter: createDetails.websiteFooter,
//         description: createDetails.description,
//         logo: createDetails.logo,
//         currency: createDetails.currency,
//         currencySymbol: createDetails.currencySymbol,
//         sessionStartMonth: startMonth,
//         sessionEndMonth: endMonth,
//         rollNumberType: createDetails.rollNumberType,
//         admissionNo: createDetails.admissionNo,
//         longitude: settingDetails.longitude,
//         latitude: createDetails.latitude,
//         googleAnalyticsId: createDetails.googleAnalyticsId,
//         defaultTimeZone: createDetails.defaultTimeZone,
//         teacherActivityFeedbackEnabled:
//           createDetails.teacherActivityFeedbackEnabled,
//       };
//       const res = await post(urls.settings.postSettings, data);
//       setSettings([...settings, res.data.data]);
//       setAddDialog(false);
//       resetForm();

//       setSettingsContext((prev) => ({
//         ...prev,
//         schoolName: data.schoolName,
//         schoolLogo: schoolCreateLogoLink,
//       }));
//       setTimeout(() => {
//         setLoading(false);
//       }, 2000);
//     } catch (error) {
//       setLoading(false);

//       console.log(error);
//     }
//   };
//   const handleOpenAddDialog = () => {
//     setAddDialog(true);
//   };
//   const resetForm = () => {
//     setCreateDetails({});
//   };

//   const handleCloseAddDialog = () => {
//     setAddDialog(false);
//     resetForm();
//   };
//   useEffect(() => {
//     const setSettingById = async () => {
//       try {
//         const res = await get(`${urls.settings.getSettings}${id}`);
//         setSetting(res.data.data);
//         setSettingDetails(res.data.data);
//         console.log(res, "gggggggg");
//       } catch (error) {}
//     };
//     setSettingById();
//   }, [id]);

//   useEffect(() => {
//     if (setting) {
//       setSettingDetails(setting);
//     }
//   }, [id]);

//   const handleSchoolSelection = (selectedSchoolId) => {
//     let newSelectedSetting = settings.filter(
//       (s) => s._id == selectedSchoolId
//     )[0];
//     setSelectedSetting(newSelectedSetting);
//     const selectedSchool = school.find((s) => s._id === selectedSchoolId);
//     if (selectedSchool) {
//       setSettingDetails({
//         ...settingDetails,
//         schoolName: selectedSchool.schoolName,
//         facebookUrl: selectedSchool.facebookUrl,
//         gplusUrl: selectedSchool.gplusUrl,
//         youtubeUrl: selectedSchool.youtubeUrl,
//         instagramUrl: selectedSchool.instagramUrl,
//         pinterestUrl: selectedSchool.pinterestUrl,
//         twitterUrl: selectedSchool.twitterUrl,
//         linkedinUrl: selectedSchool.linkedinUrl,
//         address: selectedSchool.address,

//         // sessionStartMonth: sessionStartMonth,
//         // sessionEndMonth: dayjs(selectedSchool.sessionEndMonth),
//         phone: selectedSchool.phone,
//         regDate: dayjs(selectedSchool.regDate).format("YYYY-MM-DD"),
//         email: selectedSchool.email,
//         schFax: selectedSchool.schFax,
//         websiteFooter: selectedSchool.websiteFooter,
//         description: selectedSchool.description,
//         logo: selectedSchool.logo,
//         currency: selectedSchool.currency,
//         currencySymbol: selectedSchool.currencySymbol,
//         rollNumberType: selectedSchool.rollNumberType,
//         admissionNo: selectedSchool.admissionNo,
//         longitude: selectedSchool.longitude,
//         latitude: selectedSchool.latitude,
//         googleAnalyticsId: selectedSchool.googleAnalyticsId,
//         defaultTimeZone: selectedSchool.defaultTimeZone,
//         teacherActivityFeedbackEnabled: selectedSchool.teacherActivityFeedbackEnabled,
//       });

//       setSessionStartMonth(selectedSchool.sessionStartMonth);
//       setSessionEndMonth(selectedSchool.sessionEndMonth);

//       if (selectedSchool.logo) {
//         setPreviewUrl(selectedSchool.logo.link);
//         setSchoolLogoLink(selectedSchool.logo.link);
//       }
//     }
//   };
//   if (!setting || !settingDetails) return null;
//   return (
//     <div>
//       <form onSubmit={handleSubmit}>
//         <div style={{ margin: 15 }}>
//           <div>
//             <Card style={styles.card}>
//               <Grid
//                 container
//                 spacing={2}
//                 sx={{
//                   display: "flex",
//                   justifyContent: "flex-start",
//                   alignItems: "flex-end",
//                 }}>
//                 <Grid item xs={12} mg={12} lg={6}>
//                   <span
//                     style={{
//                       color: "red",
//                       fontFamily: "cursive",
//                     }}>
//                     Settings :
//                   </span>
//                 </Grid>
//               </Grid>
//             </Card>
//           </div>

//           <Card style={styles.card}>
//             <Grid
//               container
//               spacing={2}
//               sx={{
//                 display: "flex",
//                 justifyContent: "flex-start",
//                 alignItems: "flex-end",
//               }}>
//               <Grid item xs={12} md={12} lg={12}>
//                 <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
//                   Basic Information
//                 </Typography>
//               </Grid>
//               <Grid item xs={12} md={6} lg={3}>
//                 <TextField
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                   required
//                   id="filled-required"
//                   size="small"
//                   name="schoolName"
//                   value={settingDetails.schoolName}
//                   onChange={(event) => handleOnChange(event)}
//                   label="School Name"
//                   sx={styles.textfield}
//                 />
//               </Grid>
//               <Grid item xs={12} md={6} lg={3}>
//                 <TextField
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                   required
//                   id="filled-required"
//                   size="small"
//                   label="Address"
//                   name="address"
//                   value={settingDetails.address}
//                   onChange={(event) => handleOnChange(event)}
//                   sx={styles.textfield}
//                 />
//               </Grid>
//               <Grid item xs={12} md={6} lg={3}>
//                 <TextField
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                   required
//                   id="filled-required"
//                   size="small"
//                   label="Phone"
//                   name="phone"
//                   value={settingDetails.phone}
//                   onChange={(event) => handleOnChange(event)}
//                   sx={styles.textfield}
//                 />
//               </Grid>
//               <Grid item xs={12} md={6} lg={3}>
//                 <TextField
//                   required
//                   id="filled-required"
//                   size="small"
//                   type="date"
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                   label="Registration Date"
//                   name="regDate"
//                   value={dayjs(settingDetails.regDate).format("YYYY-MM-DD")}
//                   onChange={(event) => handleOnChange(event)}
//                   sx={styles.textfield}
//                 />
//               </Grid>
//               <Grid item xs={12} md={6} lg={3}>
//                 <TextField
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                   required
//                   id="filled-required"
//                   size="small"
//                   label="Email "
//                   name="email"
//                   value={settingDetails.email}
//                   onChange={(event) => handleOnChange(event)}
//                   sx={styles.textfield}
//                 />
//               </Grid>
//               <Grid item xs={12} md={6} lg={3}>
//                 <TextField
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                   id="filled-required"
//                   size="small"
//                   label="Fax"
//                   name="schFax"
//                   value={settingDetails.schFax}
//                   onChange={(event) => handleOnChange(event)}
//                   sx={styles.textfield}
//                 />
//               </Grid>
//               <Grid item xs={12} md={6} lg={3}>
//                 <TextField
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                   required
//                   id="filled-required"
//                   size="small"
//                   label="Website Footer"
//                   name="websiteFooter"
//                   value={settingDetails.websiteFooter}
//                   onChange={(event) => handleOnChange(event)}
//                   sx={styles.textfield}
//                 />
//               </Grid>
//               <Grid item xs={12} md={12} lg={12}>
//                 <TextareaAutosize
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                   maxRows={4}
//                   name="description"
//                   value={settingDetails.description}
//                   onChange={(event) => handleOnChange(event)}
//                   style={{
//                     width: "100%",
//                     maxHeight: 110,
//                     borderRadius: "5px",
//                     padding: 10,
//                     height: 70,
//                     overflow: "auto",
//                   }}
//                   aria-label="maximum height"
//                   placeholder="Drop a note"
//                 />
//               </Grid>
//             </Grid>
//           </Card>
//           <Card style={styles.card}>
//             <Grid
//               container
//               spacing={2}
//               sx={{
//                 display: "flex",
//                 justifyContent: "flex-start",
//                 alignItems: "flex-end",
//               }}>
//               <Grid item xs={12} md={12} lg={12}>
//                 <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
//                   Setting Information:
//                 </Typography>
//               </Grid>
//               <Grid item xs={12} md={6} lg={3}>
//                 <FormControl size="small" required fullWidth>
//                   <InputLabel>Currency</InputLabel>
//                   <Select
//                     label="Currency"
//                     id="demo-simple-select-filled"
//                     value={"rupee"}
//                     onChange={(event) => handleOnChange(event)}
//                     name="currency"
//                     fontSize="small">
//                     <MenuItem
//                       sx={{ fontSize: 12, fontWeight: 500 }}
//                       value={"rupee"}>
//                       Indian Rupee
//                     </MenuItem>
//                   </Select>
//                 </FormControl>
//               </Grid>
//               <Grid item xs={12} md={6} lg={3}>
//                 <FormControl size="small" required fullWidth>
//                   <InputLabel>Currency Symbol</InputLabel>
//                   <Select
//                     key={settingDetails._id}
//                     labelId="demo-simpless-select-filled-label"
//                     id="demo-simple-select-filled-l"
//                     label="Currency Symbol"
//                     value={settingDetails.currencySymbol}
//                     onChange={(event) => handleOnChange(event)}
//                     name="currencySymbol">
//                     <MenuItem value={"rupeeIcon"}>
//                       <CurrencyRupeeIcon
//                         sx={{ fontSize: 14, fontWeight: 500 }}
//                       />
//                     </MenuItem>
//                   </Select>
//                 </FormControl>
//               </Grid>
//               <Grid item xs={12} md={6} lg={3}>
//                 <LocalizationProvider dateAdapter={AdapterDayjs}>
//                   <Stack spacing={2}>
//                     <DatePicker
//                       label="Session Start Month"
//                       views={["month"]}
//                       sx={{ fontSize: 14, fontWeight: 500 }}
//                       inputFormat="MMMM"
//                       name="sessionStartMonth"
//                       value={settingDetails.sessionStartMonth}
//                       onChange={(newValue) => {
//                         setSettingDetails({
//                           ...settingDetails,
//                           sessionStartMonth: newValue,
//                         });
//                       }}
//                       renderInput={(params) => (
//                         <TextField size="small" required {...params} />
//                       )}
//                     />
//                   </Stack>
//                 </LocalizationProvider>
//               </Grid>
//               <Grid item xs={12} md={6} lg={3}>
//                 <LocalizationProvider dateAdapter={AdapterDayjs}>
//                   <Stack spacing={2}>
//                     <DatePicker
//                       label="Session End Month"
//                       views={["month"]}
//                       inputFormat="MMMM"
//                       sx={{ fontSize: 14, fontWeight: 500 }}
//                       name="sessionEndMonth"
//                       value={settingDetails.sessionEndMonth}
//                       onChange={(newValue) => {
//                         setSettingDetails({
//                           ...settingDetails,
//                           sessionEndMonth: newValue,
//                         });
//                       }}
//                       renderInput={(params) => (
//                         <TextField size="small" required {...params} />
//                       )}
//                     />
//                   </Stack>
//                 </LocalizationProvider>
//               </Grid>

//               <Grid item xs={12} md={6} lg={3}>
//                 <FormControl size="small" fullWidth>
//                   <InputLabel>roll Number Type</InputLabel>
//                   <Select
//                     key={settingDetails.rollNumberType}
//                     name="rollNumberType"
//                     value={settingDetails.rollNumberType}
//                     onChange={(event) => handleOnChange(event)}
//                     labelId="demo-simpless-select-filled-label"
//                     id="demo-simple-select-filled-l"
//                     label="Currency Symbol">
//                     <MenuItem value={"manual"}>manual</MenuItem>
//                     <MenuItem value={"ascendingName"}>
//                       auto ascending name
//                     </MenuItem>
//                     <MenuItem value={"ascendingNameFemale"}>
//                       auto ascending name Female
//                     </MenuItem>
//                   </Select>
//                 </FormControl>
//               </Grid>

//               <Grid item xs={12} md={6} lg={3}>
//                 <FormControl size="small" fullWidth>
//                   <InputLabel>Admission Number</InputLabel>
//                   <Select
//                     key={settingDetails.admissionNo}
//                     name="admissionNo"
//                     value={settingDetails.admissionNo}
//                     onChange={(event) => handleOnChange(event)}
//                     labelId="demo-simpless-select-filled-label"
//                     id="demo-simple-select-filled-l"
//                     label="Currency Symbol">
//                     <MenuItem value={"manual"}>manual</MenuItem>
//                     <MenuItem value={"autoAscendingNo"}>
//                       autoAscendingNo
//                     </MenuItem>
//                   </Select>
//                 </FormControl>
//               </Grid>
//               <Grid item xs={12} md={6} lg={3}>
//                 <TextField
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                   id="filled-required"
//                   fullWidth
//                   label="Latitude"
//                   size="small"
//                   name="latitude"
//                   value={settingDetails.latitude}
//                   onChange={(event) => handleOnChange(event)}
//                 />
//               </Grid>
//               <Grid item xs={12} md={6} lg={3}>
//                 <TextField
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                   fullWidth
//                   size="small"
//                   label="Longitude"
//                   sx={{ label: { fontSize: 12 } }}
//                   name="longitude"
//                   value={settingDetails.longitude}
//                   onChange={(event) => handleOnChange(event)}
//                 />
//               </Grid>
//               <Grid item xs={12} md={6} lg={3}>
//                 <TimezoneSelect
//                   styles={{
//                     control: (baseStyle, state) => ({
//                       ...baseStyle,
//                     }),
//                   }}
//                   size="small"
//                   placeholder="Select default timezone"
//                   key={settingDetails.defaultTimeZone}
//                   value={settingDetails.defaultTimeZone || {}}
//                   onChange={handleChangeTimeZone}
//                   name="defaultTimeZone"
//                 />
//               </Grid>

//               <Grid item xs={12} md={6} lg={3}>
//                 <TextField
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                   size="small"
//                   fullWidth
//                   label="Google Analytics"
//                   sx={{ minWidth: 229, label: { fontSize: 12 } }}
//                   value={settingDetails.googleAnalyticsId}
//                   onChange={(event) => handleOnChange(event)}
//                   name="googleAnalyticsId"
//                 />
//               </Grid>
//               <Grid item xs={12} md={6} lg={3}>
//                 <FormControl size="small" required fullWidth>
//                   <InputLabel>Teacher Activity Feedback Enabled</InputLabel>
//                   <Select
//                     key={settingDetails.teacherActivityFeedbackEnabled}
//                     label="Teacher Activity Feedback Enabled"
//                     id="demo-simple-select-filled"
//                     onChange={(event) => handleOnChange(event)}
//                     value={settingDetails.teacherActivityFeedbackEnabled}
//                     name="teacherActivityFeedbackEnabled"
//                     fontSize="small">
//                     <MenuItem
//                       sx={{ fontSize: 12, fontWeight: 500 }}
//                       value={"yes"}>
//                       yes
//                     </MenuItem>
//                     <MenuItem
//                       sx={{ fontSize: 12, fontWeight: 500 }}
//                       value={"no"}>
//                       no
//                     </MenuItem>
//                   </Select>
//                 </FormControl>
//               </Grid>
//             </Grid>
//           </Card>

//           <Card style={styles.card}>
//             <Grid container spacing={2}>
//               <Grid item xs={12} md={12} lg={12}>
//                 <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
//                   Social Information:
//                 </Typography>
//               </Grid>
//               <Grid item xs={12} md={6} lg={3}>
//                 <TextField
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                   name="facebookUrl"
//                   value={settingDetails.facebookUrl || ""}
//                   onChange={handleOnChange}
//                   id="filled-required"
//                   size="small"
//                   label="Facebook URL"
//                   sx={styles.textfield}
//                 />
//               </Grid>
//               <Grid item xs={12} md={6} lg={3}>
//                 <TextField
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                   name="twitterUrl"
//                   value={settingDetails.twitterUrl || ""}
//                   onChange={handleOnChange}
//                   id="filled-required"
//                   size="small"
//                   label="Twitter URL"
//                   sx={styles.textfield}
//                 />
//               </Grid>
//               <Grid item xs={12} md={6} lg={3}>
//                 <TextField
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                   name="linkedinUrl"
//                   value={settingDetails.linkedinUrl || ""}
//                   onChange={handleOnChange}
//                   id="filled-required"
//                   size="small"
//                   label="Linkedin URL"
//                   sx={styles.textfield}
//                 />
//               </Grid>
//               <Grid item xs={12} md={6} lg={3}>
//                 <TextField
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                   name="gplusUrl"
//                   value={settingDetails.gplusUrl || ""}
//                   onChange={handleOnChange}
//                   id="filled-required"
//                   size="small"
//                   label="Google Plus URL"
//                   sx={styles.textfield}
//                 />
//               </Grid>
//               <Grid item xs={12} md={6} lg={3}>
//                 <TextField
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                   name="youtubeUrl"
//                   value={settingDetails.youtubeUrl || ""}
//                   onChange={handleOnChange}
//                   id="filled-required"
//                   size="small"
//                   label="Youtube URL"
//                   sx={styles.textfield}
//                 />
//               </Grid>
//               <Grid item xs={12} md={6} lg={3}>
//                 <TextField
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                   name="instagramUrl"
//                   value={settingDetails.instagramUrl || ""}
//                   onChange={handleOnChange}
//                   id="filled-required"
//                   size="small"
//                   label="Instagram URL"
//                   sx={styles.textfield}
//                 />
//               </Grid>
//               <Grid item xs={12} md={6} lg={3}>
//                 <TextField
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                   name="pinterestUrl"
//                   value={settingDetails.pinterestUrl || ""}
//                   onChange={handleOnChange}
//                   id="filled-required"
//                   size="small"
//                   label="Pinterest URL "
//                   sx={styles.textfield}
//                 />
//               </Grid>
//             </Grid>
//           </Card>

//           <Card style={styles.card}>
//             <Grid container spacing={2}>
//               <Grid item xs={12} md={12} lg={12}>
//                 <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
//                   Logo Upload:
//                 </Typography>
//               </Grid>
//               <form>
//                 <Grid item lg={12} sx={{ marginLeft: "20px" }} mt={1}>
//                   <Button
//                     variant="contained"
//                     component="label"
//                     sx={styles.Button}>
//                     Upload
//                     <input
//                       hidden
//                       accept="image/*"
//                       multiple
//                       type="file"
//                       value={schoolLogo}
//                       onChange={handlePhoto}
//                     />
//                   </Button>

//                   {previewUrl && (
//                     <img
//                       src={previewUrl}
//                       style={{
//                         width: "100px",
//                         height: "100px",
//                         objectFit: "contain",
//                         marginLeft: " 40px",
//                       }}
//                       alt="Preview"
//                     />
//                   )}
//                 </Grid>
//               </form>
//             </Grid>
//           </Card>

//           <Grid
//             item
//             xs={12}
//             md={12}
//             lg={12}
//             sx={{ display: "flex", justifyContent: "flex-end", gap: "5px" }}>
//             <Button
//               variant="contained"
//               color="error"
//               sx={{ color: "#fff" }}
//               onClick={() => navigate(-1)}>
//               Back
//             </Button>
//             <LoadingButton
//               type="submit"
//               loading={loading}
//               onClick={() => navigate(-1)}
//               variant="contained"
//               style={{ marginRight: "8px" }}>
//               Update
//             </LoadingButton>
//           </Grid>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default Settings;

import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Box,
  Card,
  Grid,
  TextareaAutosize,
  TextField,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Dialog,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { del, get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { useContext } from "react";
import SettingContext from "../../../context/SettingsContext";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import appendStringToUrls from "../../../utils/appendString";
import { Add } from "@mui/icons-material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import TimezoneSelect from "react-timezone-select";
import { Params, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const styles = {
  card: {
    padding: 15,
    marginBottom: 20,
    display: "flex",
    alignItem: "center",
    justifyContent: "space-between",
    overflow: "visible",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
    color: "#fff",
  },
};

const Settings = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  console.log(id, "iddddddd");
  const { selectedSetting, setSelectedSetting, settings, setSettings } =
    useContext(SettingContext);
  const [loading, setLoading] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState({});
  const [previewUrl, setPreviewUrl] = useState(null);
  const [schoolLogo, setSchoolLogo] = useState("");
  const [settingDetails, setSettingDetails] = useState(null);
  const [createDetails, setCreateDetails] = useState({
    regDate: dayjs(Date.now()),
    sessionStartMonth: dayjs(Date.now()),
    sessionEndMonth: dayjs(Date.now()),
  });

  const [previewCreateUrl, setPreviewCreateUrl] = useState(null);
  const [schoolCreateLogo, setSchoolCreatelLogo] = useState("");
  const [schoolCreateLogoLink, setSchoolCreateLogoLink] = useState("");
  const [sessionStartMonth, setSessionStartMonth] = useState(dayjs(Date.now()));
  const [sessionEndMonth, setSessionEndMonth] = useState(dayjs(Date.now()));
  const [startMonth, setStartMonth] = useState(dayjs(Date.now()));
  const [endMonth, setEndMonth] = useState(dayjs(Date.now()));
  const [isSettingExists, setIsSettingExists] = useState(false);
  const [schoolLogoLink, setSchoolLogoLink] = useState("");
  const { setSettingsContext } = useContext(SettingContext);
  const [school, setSchool] = useState([]);
  const [addDialog, setAddDialog] = useState(false);

  const [setting, setSetting] = useState(null);

  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const handleChangeTimeZone = (t) => {
    setSettingDetails({ ...settingDetails, defaultTimeZone: t });
  };
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setSettingDetails({ ...settingDetails, [name]: value });
  };
  const handleCreateOnChange = (e) => {
    const { name, value } = e.target;
    setCreateDetails({ ...createDetails, [name]: value });
  };
  const handleCreateOnChangeTime = (d) => {
    setCreateDetails({ ...createDetails, defaultTimeZone: d });
  };

  const handlePhoto = async (e) => {
    const formData = new FormData();
    formData.append("files", e.target.files[0]);
    try {
      setPreviewUrl(URL.createObjectURL(e.target.files[0]));
      const res = await post(urls.fileUpload.postFileUpload, formData);
      setSettingDetails({ ...settingDetails, logo: res.data.result[0]._id });
      setSchoolLogoLink(res.data.result[0].link);
    } catch (error) {
      console.log(error);
    }
  };
  const handleCreatePhoto = async (e) => {
    const formData = new FormData();
    formData.append("files", e.target.files[0]);
    try {
      setPreviewCreateUrl(URL.createObjectURL(e.target.files[0]));
      const res = await post(urls.fileUpload.postFileUpload, formData);
      setCreateDetails({ ...createDetails, logo: res.data.result[0]._id });
      setSchoolCreateLogoLink(res.data.result[0].link);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      e.preventDefault();
      const data = {
        ...settingDetails,
        schoolName: settingDetails.schoolName,
        address: settingDetails.address,
        phone: settingDetails.phone,
        regDate: dayjs(settingDetails.regDate).format("YYYY-MM-DD"),
        email: settingDetails.email,
        schFax: settingDetails.schFax,
        websiteFooter: settingDetails.websiteFooter,
        description: settingDetails.description,
        logo: settingDetails.logo,

        currency: settingDetails.currency,
        currencySymbol: settingDetails.currencySymbol,
        sessionStartMonth: settingDetails.sessionStartMonth,
        sessionEndMonth: settingDetails.sessionEndMonth,
        longitude: settingDetails.longitude,
        rollNumberType: settingDetails.rollNumberType,
        admissionNo: settingDetails.admissionNo,
        latitude: settingDetails.latitude,

        googleAnalyticsId: settingDetails.googleAnalyticsId,
        defaultTimeZone: settingDetails.defaultTimeZone,
        teacherActivityFeedbackEnabled:settingDetails.teacherActivityFeedbackEnabled,
      };
      if (setting) {
        const res = await put(
          urls.settings.putSettings,
          settingDetails._id,
          data
        );
        setSettings(
          settings.map((s) => (s._id === res.data.data._id ? res.data.data : s))
        );

        setSelectedSetting(res.data.data);
        setSettingsContext((prev) => ({
          ...prev,
          schoolName: data.schoolName,
          schoolLogo: schoolLogoLink,
        }));

        setLoading(false);
      } else {
        const res = await post(urls.settings.postSettings, data);
        setSettingsContext((prev) => ({
          ...prev,
          schoolName: data.schoolName,
          schoolLogo: schoolLogoLink,
        }));
        setSelectedSetting(res.data.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleCreateSubmit = async (e) => {
    setLoading(true);
    try {
      e.preventDefault();
      const data = {
        ...createDetails,
        schoolName: createDetails.schoolName,
        address: createDetails.address,
        phone: createDetails.phone,
        regDate: dayjs(createDetails.regDate).format("YYYY-MM-DD"),
        email: createDetails.email,
        schFax: createDetails.schFax,
        websiteFooter: createDetails.websiteFooter,
        description: createDetails.description,
        logo: createDetails.logo,
        currency: createDetails.currency,
        currencySymbol: createDetails.currencySymbol,
        sessionStartMonth: startMonth,
        sessionEndMonth: endMonth,
        rollNumberType: createDetails.rollNumberType,
        admissionNo: createDetails.admissionNo,
        longitude: settingDetails.longitude,
        latitude: createDetails.latitude,
        googleAnalyticsId: createDetails.googleAnalyticsId,
        defaultTimeZone: createDetails.defaultTimeZone,
        teacherActivityFeedbackEnabled:createDetails.teacherActivityFeedbackEnabled,
      };
      const res = await post(urls.settings.postSettings, data);
      setSettings([...settings, res.data.data]);
      setAddDialog(false);
      resetForm();

      setSettingsContext((prev) => ({
        ...prev,
        schoolName: data.schoolName,
        schoolLogo: schoolCreateLogoLink,
      }));
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };
  const handleOpenAddDialog = () => {
    setAddDialog(true);
  };
  const resetForm = () => {
    setCreateDetails({});
  };

  const handleCloseAddDialog = () => {
    setAddDialog(false);
    resetForm();
  };
  useEffect(() => {
    const setSettingById = async () => {
      try {
        const res = await get(`${urls.settings.getSettings}${id}`);
        setSetting(res.data.data);
        setSettingDetails(res.data.data);
        console.log(res, "gggggggg");
      } catch (error) {}
    };
    setSettingById();
  }, [id]);

  useEffect(() => {
    if (setting) {
      setSettingDetails(setting);
    }
  }, [id]);

  const handleSchoolSelection = (selectedSchoolId) => {
    let newSelectedSetting = settings.filter(
      (s) => s._id == selectedSchoolId
    )[0];
    setSelectedSetting(newSelectedSetting);
    const selectedSchool = school.find((s) => s._id === selectedSchoolId);
    if (selectedSchool) {
      setSettingDetails({
        ...settingDetails,
        schoolName: selectedSchool.schoolName,
        facebookUrl: selectedSchool.facebookUrl,
        gplusUrl: selectedSchool.gplusUrl,
        youtubeUrl: selectedSchool.youtubeUrl,
        instagramUrl: selectedSchool.instagramUrl,
        pinterestUrl: selectedSchool.pinterestUrl,
        twitterUrl: selectedSchool.twitterUrl,
        linkedinUrl: selectedSchool.linkedinUrl,
        address: selectedSchool.address,

        // sessionStartMonth: sessionStartMonth,
        // sessionEndMonth: dayjs(selectedSchool.sessionEndMonth),
        phone: selectedSchool.phone,
        regDate: dayjs(selectedSchool.regDate).format("YYYY-MM-DD"),
        email: selectedSchool.email,
        schFax: selectedSchool.schFax,
        websiteFooter: selectedSchool.websiteFooter,
        description: selectedSchool.description,
        logo: selectedSchool.logo,
        currency: selectedSchool.currency,
        currencySymbol: selectedSchool.currencySymbol,
        rollNumberType: selectedSchool.rollNumberType,
        admissionNo: selectedSchool.admissionNo,
        longitude: selectedSchool.longitude,
        latitude: selectedSchool.latitude,
        googleAnalyticsId: selectedSchool.googleAnalyticsId,
        defaultTimeZone: selectedSchool.defaultTimeZone,
        teacherActivityFeedbackEnabled:selectedSchool.teacherActivityFeedbackEnabled,
      });

      setSessionStartMonth(selectedSchool.sessionStartMonth);
      setSessionEndMonth(selectedSchool.sessionEndMonth);

      if (selectedSchool.logo) {
        setPreviewUrl(selectedSchool.logo.link);
        setSchoolLogoLink(selectedSchool.logo.link);
      }
    }
  };
  if (!setting || !settingDetails) return null;
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div style={{ margin: 15 }}>
          <div>
            <Card style={styles.card}>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                }}>
                <Grid item xs={12} mg={12} lg={6}>
                  <span
                    style={{
                      color: "red",
                      fontFamily: "cursive",
                    }}>
                    Settings :
                  </span>
                </Grid>
              </Grid>
            </Card>
          </div>

          <Card style={styles.card}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-end",
              }}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                  Basic Information
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  id="filled-required"
                  size="small"
                  name="schoolName"
                  value={settingDetails.schoolName}
                  onChange={(event) => handleOnChange(event)}
                  label="School Name"
                  sx={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  id="filled-required"
                  size="small"
                  label="Address"
                  name="address"
                  value={settingDetails.address}
                  onChange={(event) => handleOnChange(event)}
                  sx={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  id="filled-required"
                  size="small"
                  label="Phone"
                  name="phone"
                  value={settingDetails.phone}
                  onChange={(event) => handleOnChange(event)}
                  sx={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  required
                  id="filled-required"
                  size="small"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Registration Date"
                  name="regDate"
                  value={dayjs(settingDetails.regDate).format("YYYY-MM-DD")}
                  onChange={(event) => handleOnChange(event)}
                  sx={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  id="filled-required"
                  size="small"
                  label="Email "
                  name="email"
                  value={settingDetails.email}
                  onChange={(event) => handleOnChange(event)}
                  sx={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id="filled-required"
                  size="small"
                  label="Fax"
                  name="schFax"
                  value={settingDetails.schFax}
                  onChange={(event) => handleOnChange(event)}
                  sx={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  id="filled-required"
                  size="small"
                  label="Website Footer"
                  name="websiteFooter"
                  value={settingDetails.websiteFooter}
                  onChange={(event) => handleOnChange(event)}
                  sx={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextareaAutosize
                  InputLabelProps={{
                    shrink: true,
                  }}
                  maxRows={4}
                  name="description"
                  value={settingDetails.description}
                  onChange={(event) => handleOnChange(event)}
                  style={{
                    width: "100%",
                    maxHeight: 110,
                    borderRadius: "5px",
                    padding: 10,
                    height: 70,
                    overflow: "auto",
                  }}
                  aria-label="maximum height"
                  placeholder="Drop a note"
                />
              </Grid>
            </Grid>
          </Card>
          <Card style={styles.card}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-end",
              }}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                  Setting Information:
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl size="small" required fullWidth>
                  <InputLabel>Currency</InputLabel>
                  <Select
                    label="Currency"
                    id="demo-simple-select-filled"
                    value={"rupee"}
                    onChange={(event) => handleOnChange(event)}
                    name="currency"
                    fontSize="small">
                    <MenuItem
                      sx={{ fontSize: 12, fontWeight: 500 }}
                      value={"rupee"}>
                      Indian Rupee
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl size="small" required fullWidth>
                  <InputLabel>Currency Symbol</InputLabel>
                  <Select
                    key={settingDetails._id}
                    labelId="demo-simpless-select-filled-label"
                    id="demo-simple-select-filled-l"
                    label="Currency Symbol"
                    value={settingDetails.currencySymbol}
                    onChange={(event) => handleOnChange(event)}
                    name="currencySymbol">
                    <MenuItem value={"rupeeIcon"}>
                      <CurrencyRupeeIcon
                        sx={{ fontSize: 14, fontWeight: 500 }}
                      />
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={2}>
                    <DatePicker
                      label="Session Start Month"
                      views={["month"]}
                      sx={{ fontSize: 14, fontWeight: 500 }}
                      inputFormat="MMMM"
                      name="sessionStartMonth"
                      value={settingDetails.sessionStartMonth}
                      onChange={(newValue) => {
                        setSettingDetails({
                          ...settingDetails,
                          sessionStartMonth: newValue,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField size="small" required {...params} />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={2}>
                    <DatePicker
                      label="Session End Month"
                      views={["month"]}
                      inputFormat="MMMM"
                      sx={{ fontSize: 14, fontWeight: 500 }}
                      name="sessionEndMonth"
                      value={settingDetails.sessionEndMonth}
                      onChange={(newValue) => {
                        setSettingDetails({
                          ...settingDetails,
                          sessionEndMonth: newValue,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField size="small" required {...params} />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <FormControl size="small" fullWidth>
                  <InputLabel>roll Number Type</InputLabel>
                  <Select
                    key={settingDetails.rollNumberType}
                    name="rollNumberType"
                    value={settingDetails.rollNumberType}
                    onChange={(event) => handleOnChange(event)}
                    labelId="demo-simpless-select-filled-label"
                    id="demo-simple-select-filled-l"
                    label="Currency Symbol">
                    <MenuItem value={"manual"}>manual</MenuItem>
                    <MenuItem value={"ascendingName"}>
                      auto ascending name
                    </MenuItem>
                    <MenuItem value={"ascendingNameFemale"}>
                      auto ascending name Female
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <FormControl size="small" fullWidth>
                  <InputLabel>Admission Number</InputLabel>
                  <Select
                    key={settingDetails.admissionNo}
                    name="admissionNo"
                    value={settingDetails.admissionNo}
                    onChange={(event) => handleOnChange(event)}
                    labelId="demo-simpless-select-filled-label"
                    id="demo-simple-select-filled-l"
                    label="Currency Symbol">
                    <MenuItem value={"manual"}>manual</MenuItem>
                    <MenuItem value={"autoAscendingNo"}>
                      autoAscendingNo
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id="filled-required"
                  fullWidth
                  label="Latitude"
                  size="small"
                  name="latitude"
                  value={settingDetails.latitude}
                  onChange={(event) => handleOnChange(event)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  size="small"
                  label="Longitude"
                  sx={{ label: { fontSize: 12 } }}
                  name="longitude"
                  value={settingDetails.longitude}
                  onChange={(event) => handleOnChange(event)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TimezoneSelect
                  styles={{
                    control: (baseStyle, state) => ({
                      ...baseStyle,
                    }),
                  }}
                  size="small"
                  placeholder="Select default timezone"
                  key={settingDetails.defaultTimeZone}
                  value={settingDetails.defaultTimeZone || {}}
                  onChange={handleChangeTimeZone}
                  name="defaultTimeZone"
                />
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  fullWidth
                  label="Google Analytics"
                  sx={{ minWidth: 229, label: { fontSize: 12 } }}
                  value={settingDetails.googleAnalyticsId}
                  onChange={(event) => handleOnChange(event)}
                  name="googleAnalyticsId"
                />
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <FormControl size="small" required fullWidth>
                  <InputLabel>Teacher Activity Feedback Enabled</InputLabel>
                  <Select
                    key={settingDetails.teacherActivityFeedbackEnabled}
                    label="Teacher Activity Feedback Enabled"
                    id="demo-simple-select-filled"
                    onChange={(event) => handleOnChange(event)}
                    value={settingDetails.teacherActivityFeedbackEnabled}
                    name="teacherActivityFeedbackEnabled"
                    fontSize="small">
                    <MenuItem
                      sx={{ fontSize: 12, fontWeight: 500 }}
                      value={"yes"}>
                      yes
                    </MenuItem>
                    <MenuItem
                      sx={{ fontSize: 12, fontWeight: 500 }}
                      value={"no"}>
                      no
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Card>

          <Card style={styles.card}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                  Social Information:
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="facebookUrl"
                  value={settingDetails.facebookUrl || ""}
                  onChange={handleOnChange}
                  id="filled-required"
                  size="small"
                  label="Facebook URL"
                  sx={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="twitterUrl"
                  value={settingDetails.twitterUrl || ""}
                  onChange={handleOnChange}
                  id="filled-required"
                  size="small"
                  label="Twitter URL"
                  sx={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="linkedinUrl"
                  value={settingDetails.linkedinUrl || ""}
                  onChange={handleOnChange}
                  id="filled-required"
                  size="small"
                  label="Linkedin URL"
                  sx={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="gplusUrl"
                  value={settingDetails.gplusUrl || ""}
                  onChange={handleOnChange}
                  id="filled-required"
                  size="small"
                  label="Google Plus URL"
                  sx={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="youtubeUrl"
                  value={settingDetails.youtubeUrl || ""}
                  onChange={handleOnChange}
                  id="filled-required"
                  size="small"
                  label="Youtube URL"
                  sx={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="instagramUrl"
                  value={settingDetails.instagramUrl || ""}
                  onChange={handleOnChange}
                  id="filled-required"
                  size="small"
                  label="Instagram URL"
                  sx={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="pinterestUrl"
                  value={settingDetails.pinterestUrl || ""}
                  onChange={handleOnChange}
                  id="filled-required"
                  size="small"
                  label="Pinterest URL "
                  sx={styles.textfield}
                />
              </Grid>
            </Grid>
          </Card>

          <Card style={styles.card}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                  Logo Upload:
                </Typography>
              </Grid>
              <form>
                <Grid item lg={12} sx={{ marginLeft: "20px" }} mt={1}>
                  <Button
                    variant="contained"
                    component="label"
                    sx={styles.Button}>
                    Upload
                    <input
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      value={schoolLogo}
                      onChange={handlePhoto}
                    />
                  </Button>

                  {previewUrl && (
                    <img
                      src={previewUrl}
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "contain",
                        marginLeft: " 40px",
                      }}
                      alt="Preview"
                    />
                  )}
                </Grid>
              </form>
            </Grid>
          </Card>

          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{ display: "flex", justifyContent: "flex-end", gap: "5px" }}>
            <Button
              variant="contained"
              color="error"
              sx={{ color: "#fff" }}
              onClick={() => navigate(-1)}>
              Back
            </Button>
            <LoadingButton
              type="submit"
              loading={loading}
              onClick={() => navigate(-1)}
              variant="contained"
              style={{ marginRight: "8px" }}>
              Update
            </LoadingButton>
          </Grid>
        </div>
      </form>
    </div>
  );
};

export default Settings;
