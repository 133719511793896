import React, { useContext } from "react";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import SettingContext from "../context/SettingsContext";

const SettingSelector = () => {
  const { settings, setSettings, selectedSetting, setSelectedSetting } =
    useContext(SettingContext);

  const handleSettingChange = (e) => {
    const { name, value } = e.target;
    setSelectedSetting({ ...settings.filter((s) => s._id == value)[0] });
  };

  return (
    <div>
      <FormControl
        sx={{ maxWidth: "300px", minWidth: "200px" }}
        variant="standard"
        fullWidth
      >
        <InputLabel sx={{ fontSize: 8 }}>Select Institution</InputLabel>

        <Select
          labelId="demo-simpless-select-filled-label"
          id="demo-simple-select-filled-l"
          value={selectedSetting._id}
          name="setting"
          onChange={handleSettingChange}
        >
          {settings.map((set) => (
            <MenuItem key={set._id} value={set._id}>
              {set.schoolName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default SettingSelector;
