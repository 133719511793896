import * as React from "react";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, Button, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Gallery from "./Gallery";
// import carddata from "./cardata";
import styles from "../../NavbarComponents/styles/cardevent.module.css";

import { useState,useEffect } from "react";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import { urls as url } from "../../services/urlConstant";
import { get } from "../../services/apis";

const TypographyMain =styled(Typography)(({theme})=>({
  textAlign:"center",
  marginTop:"25px",
  fontSize:"40px",
  color:"#f86f03",
  fontWeight:"bold",
  textShadow: "10px 8px 8px #969c96",
  [theme.breakpoints.down("md")]:{
      // textAlign:"center",
      // margin:0,
      // padding:"25px"
  },
  [theme.breakpoints.down("sm")]:{
      textAlign:"center",
      margin:0,
      padding:"0"
  },
}))


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const GridBox = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "250px 250px 250px 250px",
  justifyContent: "space-evenly",
  gap: "20px",
  [theme.breakpoints.down(1000)]: {
    gridTemplateColumns: "auto auto ",
  },
  [theme.breakpoints.down(660)]: {
    gridTemplateColumns: "auto",
  },
}));

const TextBox = styled(Box)(({ theme }) => ({
  textAlign: "center",
  marginTop: "5%",
}));

const Heading = styled(Typography)(({ theme }) => ({
  marginTop:"8rem",
  color: "#FF6D34",
  fontSize: "1rem",
  fontFamily: "sans-serif",
  fontWeight: "bold",
}));

const SecondHeading = styled(Typography)(({ theme }) => ({
  color:"#f86f03",
  textShadow:"3px 3px black",
  fontWeight: "bold",
  fontSize: "2.5rem",
  fontFamily: "sans-serif",
  marginTop: "0.8rem",
}));

export default function OurGallery() {
  const [ModalData, setModalData] = useState(false);
  let sliderRef = React.useRef(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // const[values,setValues]=useState('')

  let [data, setData] = useState();
  const carddata = [
    {
      desc: "Student Voting in School",
      dp: [
        "https://images.pexels.com/photos/16388234/pexels-photo-16388234/free-photo-of-moscow-street-art.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        "https://images.pexels.com/photos/17591298/pexels-photo-17591298/free-photo-of-wood-city-street-building.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        "https://images.pexels.com/photos/17215283/pexels-photo-17215283/free-photo-of-bird-water-animal-lake.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
      ],

      role1: "Design",
      role2: "DEVELOPMENT",
      onClick: handleOpen,
    },
    {
      desc: "First Parents Meet",
      onClick: handleOpen,
      dp: [
        "https://media.istockphoto.com/id/1381637603/photo/mountain-landscape.webp?b=1&s=612x612&w=0&k=20&c=pP0uG1Q4O3e2RGMKDcyDd3JxSkFIOj7Tp0Y14p3aE5E=",
        "https://live.staticflickr.com/5023/5646102132_4486c65e22_c.jpg",
        "https://media.istockphoto.com/id/1382384282/photo/bangalore-or-bengaluru.jpg?s=612x612&w=0&k=20&c=6pxwL3JxNV2B_NZSLMZLhrSLqAbyCPlGuSZYKImpjKQ=",
      ],

      role1: "Photo",
    },

    {
      desc: "Student Voting in School",
      onClick: handleOpen,
      dp: [
        "https://images.pexels.com/photos/17458347/pexels-photo-17458347/free-photo-of-summer-pattern-texture-wall.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        "https://images.pexels.com/photos/15222280/pexels-photo-15222280/free-photo-of-blurred-walking-person-on-white-background.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        "https://images.pexels.com/photos/17577062/pexels-photo-17577062/free-photo-of-montmartre-sacre-coeur.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
      ],

      role1: "Languages",
    },
    {
      desc: "Student Voting in School",
      onClick: handleOpen,
      dp: [
        "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg",
        "https://live.staticflickr.com/5023/5646102132_4486c65e22_c.jpg",
        "https://media.istockphoto.com/id/1382384282/photo/bangalore-or-bengaluru.jpg?s=612x612&w=0&k=20&c=6pxwL3JxNV2B_NZSLMZLhrSLqAbyCPlGuSZYKImpjKQ=",
      ],

      role1: "Design",
      role2: "DEVELOPMENT",
    },
    {
      desc: "Student Voting in School",
      onClick: handleOpen,
      dp: [
        "https://images.pexels.com/photos/17228932/pexels-photo-17228932/free-photo-of-california-surfer-beach-waves.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        "https://images.pexels.com/photos/14131901/pexels-photo-14131901.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        "https://media.istockphoto.com/id/1382384282/photo/bangalore-or-bengaluru.jpg?s=612x612&w=0&k=20&c=6pxwL3JxNV2B_NZSLMZLhrSLqAbyCPlGuSZYKImpjKQ=",
      ],

      role1: "Phychology",
    },
    {
      desc: "Student Voting in School",
      onClick: handleOpen,
      dp: [
        "https://cdn.pixabay.com/photo/2015/11/16/16/28/bird-1045954_640.jpg",
        "https://live.staticflickr.com/5023/5646102132_4486c65e22_c.jpg",
        "https://media.istockphoto.com/id/1382384282/photo/bangalore-or-bengaluru.jpg?s=612x612&w=0&k=20&c=6pxwL3JxNV2B_NZSLMZLhrSLqAbyCPlGuSZYKImpjKQ=",
      ],

      role1: "Technology",
    },
    {
      desc: "Student Voting in School",
      onClick: handleOpen,
      dp: [
        "https://images.pexels.com/photos/14958090/pexels-photo-14958090/free-photo-of-aerial-view-of-hills-in-the-death-valley.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        "https://live.staticflickr.com/5023/5646102132_4486c65e22_c.jpg",
        "https://images.pexels.com/photos/17568714/pexels-photo-17568714/free-photo-of-subway-on-sunset.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
      ],

      role1: "DEVELOPMENT",
    },
    {
      desc: "Student Voting in School",
      onClick: handleOpen,
      dp: [
        "https://images.pexels.com/photos/15222280/pexels-photo-15222280/free-photo-of-blurred-walking-person-on-white-background.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        "https://images.pexels.com/photos/15740547/pexels-photo-15740547/free-photo-of-the-courtyard-of-the-sheikh-zayed-grand-mosque-in-abu-dhabi-united-arab-emirates.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        "https://media.istockphoto.com/id/1382384282/photo/bangalore-or-bengaluru.jpg?s=612x612&w=0&k=20&c=6pxwL3JxNV2B_NZSLMZLhrSLqAbyCPlGuSZYKImpjKQ=",
      ],

      role1: "Business",
    },
  ];

  const items = [
    "BUSINESS",
    "DEVELOPMENT",
    "LANGUAGES",
    "MANAGEMENT",
    "PHOTO",
    "PSYCHOLOGY",
    "TECHNOLOGY",
  ];

  let handleData = (data) => {
    console.log(data);
    setData(data);
  };

  // let navigate = useNavigate();

  const { selectedSetting } = React.useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const [galleryData, setGalleryData] = useState([]);

  const getGalleryData = async () => {
    try {
      const { data } = await get(urls.gallery.getAllGalleries);
      setGalleryData(data.data);
      console.log(data.data,"gallery");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getGalleryData();
  }, []);

  return (
    <>
      <TextBox>
        <Heading >DISCOVER NEW</Heading>
        <TypographyMain>OUR GALLERY</TypographyMain>
      </TextBox>
      <Box>
        <ul className={styles.list}>
          <li
            style={{ borderLeft: "3px solid #ff4500", paddingLeft: "0.5rem" }}
          >
            ALL
          </li>
          {items.map((data, i) => {
            return (
              <React.Fragment key={i}>
                <li onClick={() => handleData(data)}>{data}</li>
              </React.Fragment>
            );
          })}
        </ul>
      </Box>

      <GridBox>
        {galleryData.map((items, i) => {
          return (
            <React.Fragment key={i}>
              <Gallery data={items} onClick={items.onClick} />
            </React.Fragment>
          );
        })}
      </GridBox>
      <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <Button
          size="large"
          variant="contained"
          sx={{
            bgcolor: "#1779f7",
            color: "white",
            border: "solid #1779f7",
            "&:hover": {
              bgcolor: "transparent",
              border: "solid #ff4500",
              color: "black",
            },
          }}
        >
          View Gallery
        </Button>
      </Box>
    </>
  );
}
