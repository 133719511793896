import { Box, Container, Grid, Typography, styled } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../../Components2/Footer";
import laboratoryImg from "../../../assets/Images/laboratory1.png";
import NavTopnav from "../../../components/NavTopnav/NavTopnav";

const BackGround = styled(Box)(({ theme }) => ({
  backgroundImage: "url(/LatestEvents.png)",
  backgroundRepeat: "noRepeat",
  backgroundPosition: "center",
  backgroundSize: "100% 100%",
  height: "30vh",
  width: "100%",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}));

const Heading = styled(Typography)(({ theme }) => ({
  color: "#FFFFFF",
  fontSize: "2rem",
  fontFamily: "sans-serif",
  fontWeight: "bold",
}));

const Heading1 = styled(Typography)(({ theme }) => ({
  color: "#FFFFFF",
  fontSize: "1rem",
  fontFamily: "sans-serif",
  fontWeight: "bold",
}));

const Headinglink = styled(Link)(({ theme }) => ({
  color: "#FFFFFF",
  fontSize: "1rem",
  fontFamily: "sans-serif",
  fontWeight: "bold",
  textDecoration: "none",
}));

const ContentContainer = styled(Container)(({ theme }) => ({
  padding: "30px",
  [theme.breakpoints.down("md")]: {
    padding: "20px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "5px 15px",
  },
}));

const OuterBox = styled(Box)(({ theme }) => ({
  backgroundImage:
    "linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)),url(https://www.vvsw.edu.in/wp-content/uploads/2019/08/VVSW-infrastructure-1.jpg)",
  backgroundRepeat: "no-repeat",
  width: "100%",
  backgroundSize: "100% 100%",
  paddingTop: "250px",
}));

const textContent = "white";

const Lab = () => {
  return (
    <>
      <NavTopnav />

      <OuterBox>
        <Typography
          variant="h4"
          color="#F86F03"
          textAlign="center"
          fontWeight="700"
        >
          LAB FACILITIES
        </Typography>
        <ContentContainer>
          <Box sx={{ padding: "10px 0" }}>
            <Typography sx={{ fontWeight: 700, color: textContent }}>
              We understand the importance of providing our students with
              state-of-the-art laboratory facilities that support their academic
              and research endeavors. Our well-equipped labs offer hands-on
              experiences, allowing students to apply theoretical knowledge and
              develop practical skills. Here are the various lab facilities
              available at our college:
            </Typography>
          </Box>
          <Grid container>
            <Grid item lg={8} md={8} sm={12}>
              <Box>
                <Box>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ color: "#F86F03" }}
                  >
                    1. Science Laboratories:
                  </Typography>
                  <Typography component="ul">
                    <Typography
                      component="li"
                      gutterBottom
                      sx={{ color: textContent }}
                    >
                      Biology Lab: A fully equipped lab where students can
                      conduct experiments related to anatomy, physiology,
                      microbiology, and genetics.
                    </Typography>
                    <Typography
                      component="li"
                      gutterBottom
                      sx={{ color: textContent }}
                    >
                      Chemistry Lab: A modern lab equipped with the necessary
                      apparatus and chemicals for conducting experiments in
                      organic and inorganic chemistry, analytical chemistry, and
                      physical chemistry.
                    </Typography>
                    <Typography
                      component="li"
                      gutterBottom
                      sx={{ color: textContent }}
                    >
                      Physics Lab: A dedicated space for students to perform
                      experiments in mechanics, electricity, optics, and modern
                      physics using advanced instruments and equipment.
                    </Typography>
                    <Typography
                      component="li"
                      gutterBottom
                      sx={{ color: textContent }}
                    >
                      Environmental Science Lab: A specialized lab where
                      students can study environmental samples, analyze data,
                      and conduct experiments related to ecology, pollution, and
                      conservation.
                    </Typography>
                    <br />
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ color: "#F86F03" }}
                  >
                    2. Computer Laboratories:
                  </Typography>
                  <Typography component="ul">
                    <Typography
                      component="li"
                      gutterBottom
                      sx={{ color: textContent }}
                    >
                      General Computer Lab: General Computer Lab: A spacious lab
                      equipped with desktop computers, printers, and high-speed
                      internet access, providing students with a suitable
                      environment for various computer-based activities,
                      research, and software development.
                    </Typography>
                    <Typography
                      component="li"
                      gutterBottom
                      sx={{ color: textContent }}
                    >
                      Specialized Labs: Additional labs dedicated to specific
                      areas such as programming, multimedia, networking, and
                      database management, equipped with specialized software
                      and hardware resources.
                    </Typography>
                    <br />
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ color: "#F86F03" }}
                  >
                    3. Engineering Laboratories:
                  </Typography>
                  <Typography component="ul">
                    <Typography
                      component="li"
                      gutterBottom
                      sx={{ color: textContent }}
                    >
                      Mechanical Engineering Lab: Equipped with machines, tools,
                      and equipment for hands-on learning in areas such as
                      thermodynamics, fluid mechanics, and manufacturing
                      processes.
                    </Typography>
                    <Typography
                      component="li"
                      gutterBottom
                      sx={{ color: textContent }}
                    >
                      Electrical and Electronics Lab: A lab that provides
                      students with the opportunity to work with electrical
                      circuits, digital electronics, microcontrollers, and
                      instrumentation systems.
                    </Typography>
                    <Typography
                      component="li"
                      gutterBottom
                      sx={{ color: textContent }}
                    >
                      Civil Engineering Lab: A dedicated space for conducting
                      experiments in materials testing, geotechnical
                      engineering, and structural analysis.
                    </Typography>
                    <br />
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ color: "#F86F03" }}
                  >
                    4. Research Laboratories:
                  </Typography>
                  <Typography component="ul">
                    <Typography
                      component="li"
                      gutterBottom
                      sx={{ color: textContent }}
                    >
                      Faculty Research Labs: Dedicated spaces where faculty
                      members and their research teams conduct cutting-edge
                      research in their respective fields, providing
                      opportunities for students to engage in research projects
                      and gain valuable research experience.
                    </Typography>
                    <Typography
                      component="li"
                      gutterBottom
                      sx={{ color: textContent }}
                    >
                      Student Research Labs: Designated labs where students can
                      pursue their own research projects under the guidance of
                      faculty mentors, fostering critical thinking and
                      scientific inquiry.
                    </Typography>
                    <br />
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={12} padding="10px">
              <img
                title="Lab Images"
                // src="https://www.vvsw.edu.in/wp-content/uploads/2019/08/VVSW-infrastructure-1.jpg"
                src={laboratoryImg}
                alt="image"
                style={{ maxWidth: "100%" }}
              />
            </Grid>
          </Grid>
          <Box sx={{ padding: "10px 0" }}>
            <Typography sx={{ fontWeight: 700, color: textContent }}>
              These lab facilities at Kayaka are designed to provide our
              students with hands-on learning experiences, foster innovation,
              and enhance their practical skills. We ensure that our labs are
              regularly updated with the latest equipment and technology to
              align with industry standards and advancements. If you have any
              specific questions about our lab facilities or would like to
              explore them further, please feel free to contact us.
            </Typography>
          </Box>
        </ContentContainer>
        <Footer />
      </OuterBox>
    </>
  );
};

export default Lab;
