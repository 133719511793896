import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
  createTheme,
} from "@mui/material";
import css from "./Events.module.css";
import { ThemeProvider } from "@emotion/react";
import styled from "@emotion/styled";
import { grey } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";

import moment from "moment";

const theme = createTheme();

const Btn = styled(Button)(({ theme }) => ({
  backgroundColor: "#ff6d34",
  border: "1px solid #ff6d34",
  color: " black",
  fontSize: "12px",
  fontFamily: "serif",
  marginRight: "13px",
  [theme.breakpoints.down("md")]: {
    marginRight: "0px",
  },
}));

const Title = styled(Box)(() => ({
  color: "#1976D2",
  fontSize: "1.5rem",
  fontWeight: "bold",
  fontFamily: "Muli",
  "&:hover": { color: "#ef6c00" },
}));

const Founder = styled(Box)(() => ({
  color: grey[500],
  fontSize: "14px",
  "&:hover": { color: "#ff6d34" },
}));

const Random = styled(Box)(({theme}) => ({
  textAlign: "center",
  width:"40%",
  
  [theme.breakpoints.down(900)]:{
       width:"100%"
  }
}));

const Content = styled(Typography)(() => ({
  fontSize: "20px",
  marginTop: "1%",
  color: "#2c2c2c",
  fontFamily: "Muli",
  lineHeight: "30px",
  fontWeight: "800",
  gap: "5%",
  "&:hover": { color: "#ef6c00" },
}));

const Cardcontent = styled(CardContent)(({ theme }) => ({
  // width:"65%",
  paddingLeft: "0px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const CardImageWrapper = styled(Box)(({ theme }) => ({

  width: "500px",
  position: "relative",
  "&:hover": {
    position: "relative",
    borderRadius: "5px",
    backgroundColor: "rgba(0, 0, 0, 2.5)",
  },
  [theme.breakpoints.down("md")]: {
    width: "95%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const CardImage = styled(CardMedia)(({ theme }) => ({
  width: "100%",
  height: "100%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const ViewMore = styled(CardMedia)(({ theme }) => ({
  fontSize: "13px",
  fontFamily: "Muli",
  color: "#1976D2",
  marginTop: "2%",
  "&:hover": {
    color: "#ef6c00",
  }
}));



// const AnchorBox = styled(Box)({
//   width: "65px",
//   "&::after": {
//     content: '""',
//     height: "3px",
//     marginTop: "2px",
//     backgroundColor: "#dfdfdf",
//     display: "block",
//     fontSize: "1rem",
//     transform: "scale(0,1)",
//     transition: "transform 0.35s ease",
//   },
//   "&:hover::after": {
//     width: "100%",
//     backgroundColor: "#ef6c00",
//     transform: "scale(1,1)",
//   },
// });



// const Our = styled(Typography)(({ theme }) => ({
//   textAlign: "center",
//   textTransform: "uppercase",
//   fontSize: "2.5rem",
//   [theme.breakpoints.down("sm")]: {
//     fontSize: "2rem",
//   },
// }));

const Month = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    paddingLeft: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    paddingLeft: "15px",
  },
  [theme.breakpoints.down("xs")]: {
    paddingLeft: "10px",
  },
}));

const Arc = styled(Box)(({ theme }) => ({
  position: "absolute",
  right: 0,
  minWidth: "19%",
  
  borderRadius: "0 5px 0 90%",
  backgroundColor: "#00BDA6",
  // padding: '5px 0 30px',
  padding: "11px 0 23px",

  zIndex: "2",
}));


const CustomComponent = ({ elem }) => {
    
    console.log("hello event",elem);

    
  const [showFull, setShowFull] = useState(false);

  // const handleReadMoreClick = () => {
  //   navigate(`/${elem.id}`);
  //   if (showFull) {
  //     navigate("/");
  //   } else {
  //     setShowFull(!showFull);
  //   }
  // };


  useEffect(() => {
    const currentPath = window.location.pathname;
    const isFullContentPage = currentPath === `/${elem.id}`;
    setShowFull(isFullContentPage);
  }, [elem.id]);

  let onlyDay = elem.fromDate;

  const date = moment(onlyDay);
  const specificDate = date.format("Do");

  const specificMonth = date.format("MMMM ,YYYY");

  

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <CardImageWrapper>
          <Arc>
            <Month fontSize="0.7rem" className={css.month}>
              {specificMonth}
            </Month>
            <Typography fontSize="1.5rem" className={css.date}>
              {specificDate}
            </Typography>
          </Arc>
          <CardImage
            className={css.image}
            component="img"
            image={elem.image.link}
            alt="Live from space album cover"
          />
        </CardImageWrapper>
        <Cardcontent>
          <Random>
            <Title>{elem.eventTitle}</Title>
          </Random>
          <Content>{elem.shortEvent}</Content>
        </Cardcontent>
      </Box>
    </ThemeProvider>
  );
};

export default CustomComponent;

