import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Dialog,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextareaAutosize,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";
import { LoadingButton } from "@mui/lab";

import { useTheme } from "@mui/material/styles";

const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
};

// const ContainerBox = styled(Box)(({ theme }) => ({
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "100%",
//   maxWidth: "650px",
//   maxHeight: "650px",
//   borderRadius: "5px",
//   height: "auto",
//   // overflow:"scroll",
//   backgroundColor: "white",
//   padding: "20px",
//   // border:"solid red",
//   [theme.breakpoints.down("md")]: {
//     overflow: "auto",
//     width: "90%",
//   },
//   [theme.breakpoints.between(819, 821)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.between(767, 769)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.between(911, 913)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.between(1023, 1025)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.between(1279, 1281)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "100%",
//     height: "100%",
//     overflow: "auto",
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "center",
//   },
// }));

const AddExamTerm = (props) => {
  const [examTitle, setExamTitle] = useState("");
  const [examType, setExamType] = useState("");
  const [assigmentMark, setAssigmentMark] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  console.log(props.loader);

  const [note, setNote] = useState("");
  useEffect(() => {
    if (props.updateExamTerm) {
      setExamTitle(props.updateExamTerm.examTitle);
      setExamType(props.updateExamTerm.examType);
      setAssigmentMark(props.updateExamTerm.assigmentMark);
      setNote(props.updateExamTerm.note);
    }
  }, [props.updateExamTerm]);

  const handleExamTitle = (e) => {
    setExamTitle(e.target.value);
  };
  const handleExamType = (e) => {
    setExamType(e.target.value);
  };
  const handleAssignmentMark = (e) => {
    setAssigmentMark(e.target.value);
  };

  const handleExamNote = (e) => {
    setNote(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (props.updateExamTerm) {
      await props.handleUpdate(
        {
          examTitle,
          examType,
          assigmentMark,
          note,
        },
        props.updateExamTerm._id
      );
    } else {
      await props.handleSubmit(e, {
        examTitle,
        examType,
        assigmentMark,
        note,
      });
    }
    setExamTitle("");
    setExamType("");
    setAssigmentMark("");
    setNote("");
    props.handleClose();
  };

  const handleCloseModal = () => {
    setExamTitle("");
    setExamType("");
    setAssigmentMark("");
    setNote("");
    props.handleClose();
  };

  const handleClose = () => {
    setExamTitle("");
    setExamType("");
    setAssigmentMark("");
    setNote("");
    props.handleClose();
  };
  return (
    <div>
      <Tooltip title="Add" disableInteractive>
        <Fab
          size="medium"
          style={{ background: "#1b3779", color: "#fff" }}
          aria-label="add"
          onClick={props.handleOpen}
        >
          <Add />
        </Fab>
      </Tooltip>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleSubmit}>
          <Box sx={style}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-end",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  textAlign="center"
                  fontSize="20px"
                  fontWeight="bold"
                >
                  Add Exam Grade
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  required
                  id="filled-required"
                  size="small"
                  label="Exam Title"
                  value={examTitle}
                  onChange={handleExamTitle}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormControl size="small" fullWidth required>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}
                  >
                    Type
                  </InputLabel>
                  <Select
                    label="Type"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={examType}
                    onChange={handleExamType}
                  >
                    <MenuItem
                      value={"general"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      general
                    </MenuItem>
                    <MenuItem
                      value={"competitive"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      competitive
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormControl size="small" fullWidth required>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}
                  >
                    Assignment Mark
                  </InputLabel>
                  <Select
                    label="Assignment Mark"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={assigmentMark}
                    onChange={handleAssignmentMark}
                  >
                    <MenuItem
                      value={"active"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Active
                    </MenuItem>
                    <MenuItem
                      value={"inactive"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Inactive
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <TextareaAutosize
                  maxRows={4}
                  aria-label="maximum height"
                  placeholder="Drop a note"
                  value={note}
                  onChange={handleExamNote}
                  style={{
                    width: "100%",
                    maxHeight: 110,
                    borderRadius: "5px",
                    padding: 10,
                    height: 70,
                    overflow: "auto",
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                display="flex"
                justifyContent={{
                  xs: "center",
                  sm: "center",
                  md: "flex-end",
                  lg: "flex-end",
                }}
                gap={2}
              >
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="error"
                  size="medium"
                  type="button"
                  sx={{ color: "#fff" }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  loading={props.loader}
                  disabled={!(examTitle && examType && assigmentMark)}
                  size="medium"
                  type="submit"
                  style={{ background: "#1b3779", color: "#fff" }}
                >
                  Submit
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Dialog>
    </div>
  );
};

export default AddExamTerm;
