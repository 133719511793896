import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Search } from "@material-ui/icons";
import { TableBody } from "@material-ui/core";
import { Delete } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import { del, get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../SectionSubHeader";
import Wrapper from "../../Wrapper";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}
const SalaryPayment = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [tabValue, setTabValue] = useState(0);
  const [month, setMonth] = useState(dayjs(Date.now()));
  const [selectmonth, setSelectMonth] = useState(dayjs(Date.now()));
  const [year, setYear] = useState(dayjs(Date.now()));
  const [history, setHistory] = useState([]);
  const [deduction, setDeduction] = useState([]);
  const [selectDeduction, setSelectDeduction] = useState([]);

  useEffect(() => {
    getHistory();
    getSalaryDeduction();
  }, [selectedSetting]);

  const getSalaryDeduction = async () => {
    try {
      const { data, status } = await get(`${urls.salaryGrade.getList}`);

      setDeduction(data.result.data);
      console.log(data.result.data, "sssss");
    } catch (error) {
      console.log(error);
    }
  };
  const getHistory = async () => {
    console.log("hjhjhjhjh");
    try {
      const { data, status } = await get(`${urls.makePayment.getAll}`);
      setHistory(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleLinkClick = (url) => {
    window.open(url, "_blank");
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleGetPrintPdf = async (e) => {
    e.preventDefault();
    console.log("hjhjhjhjhj");
    try {
      const selectedMonth = dayjs(month, "MMMM").format("MMMM");

      const getPrint = await get(
        `/${selectedSetting._id}/makePayment/downloadpdf?month=${selectedMonth}&year=${year}`,
        {
          responseType: "blob",
        }
      );
      console.log(getPrint, "getPrint");
      if (getPrint) {
        const uri = URL.createObjectURL(getPrint.data);
        window.open(uri, "__blank");
      }
      getHistory();
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };
  const handleGetPrintDeductionPdf = async (e) => {
    e.preventDefault();
    console.log("hjhjhjhjhj");
    try {
      const selectedMonth = dayjs(selectmonth, "MMMM").format("MMMM");
      const getPrint = await get(
        `/${selectedSetting._id}/downloadDeductionPdf?deduction=${selectDeduction}&month=${selectedMonth}`,
        {
          responseType: "blob",
        }
      );
      console.log(getPrint, "getPrint");
      if (getPrint) {
        const uri = URL.createObjectURL(getPrint.data);
        window.open(uri, "__blank");
      }

      getHistory();
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  return (
    <Wrapper>
      <SectionSubHeader title="Payments" />
      <div style={{ margin: "15px 0" }}>
        <Card sx={{ mt: 3 }}>
          <Box>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab label="Salary Sheet" {...a11yProps(0)} />
              <Tab label="Histroy" {...a11yProps(1)} />
              <Tab label="Salary Deduction" {...a11yProps(2)} />
            </Tabs>
          </Box>
        </Card>
        <TabPanel value={tabValue} index={0}>
          <Card sx={{ padding: "15px 5px", mt: 1.5 }}>
            <form>
              {" "}
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={2}>
                      <DatePicker
                        label="Select Month"
                        views={["month"]}
                        inputFormat="MMMM"
                        value={month}
                        onChange={(newValue) => {
                          setMonth(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            variant="outlined"
                            {...params}
                          />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={2}>
                      <DatePicker
                        label="Select Year"
                        views={["year"]}
                        inputFormat="YYYY"
                        value={year}
                        onChange={(newValue) => {
                          setYear(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            variant="outlined"
                            {...params}
                          />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={4}
                  display="flex"
                  justifyContent={{
                    xs: "center",
                    sm: "center",
                    md: "flex-end",
                    lg: "flex-start",
                  }}
                  alignSelf="center"
                >
                  <Button variant="contained" onClick={handleGetPrintPdf}>
                    Process
                  </Button>
                </Grid>
              </Grid>{" "}
            </form>
          </Card>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          {/* <Paper
            sx={{ width: "100%", height: "60px", overflow: "hidden", mt: 2 }}
          > */}
          <div style={{ margin: "10px 0px" }}>
            <Grid
              container
              spacing={2}
              justifyContent={{ md: "right", xs: "center" }}
            >
              <Grid item xs="auto" mb={1}>
                <TextField
                  size="small"
                  // value={search}
                  // onChange={handleSearch}
                  label="Search"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" type="submit">
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </div>
          {/* </Paper> */}
          <Table>
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  Month
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Year
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Download
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history &&
                history.map((data, index) => (
                  <TableRow>
                    <TableCell style={{ textAlign: "center" }}>
                      {data.month}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {new Date(data.year).getFullYear()}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <Button
                        style={{
                          color: "#1b3779",
                        }}
                        onClick={() => handleLinkClick(data.data.link)}
                      >
                        <DownloadIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <Card sx={{ padding: "15px 5px", mt: 1.5 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl fullWidth required size="small">
                  <InputLabel required>Deduction Type</InputLabel>
                  <Select
                    label="Deduction Type"
                    size="small"
                    value={selectDeduction}
                    onChange={(event) => {
                      const newValue = event.target.value;
                      setSelectDeduction(newValue);
                    }}
                  >
                    {deduction &&
                      deduction.map((data) =>
                        data.deduction.map((datas) => (
                          <MenuItem key={datas.id} value={datas.name}>
                            {datas.name}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={2}>
                    <DatePicker
                      required
                      label="Select Date"
                      views={["month", "year"]}
                      inputFormat="MMM-YYYY"
                      value={selectmonth}
                      onChange={(newValue) => {
                        setSelectMonth(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          variant="outlined"
                          {...params}
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                lg={4}
                display="flex"
                justifyContent={{
                  xs: "center",
                  sm: "center",
                  md: "flex-end",
                  lg: "flex-start",
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleGetPrintDeductionPdf}
                >
                  Process
                </Button>
              </Grid>
            </Grid>
          </Card>
        </TabPanel>
      </div>
    </Wrapper>
  );
};

export default SalaryPayment;
