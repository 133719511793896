import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  CalendarPicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { get } from "../../../../services/apis";
import { urls as url } from "../../../../services/urlConstant";

// style
import css from "../../../../styles/fees/FeeRemittanceView.module.css";
import FeeRemittanceViewDetails from "./FeeRemittanceViewDetails";
import SettingContext from "../../../../context/SettingsContext";
import appendStringToUrls from "../../../../utils/appendString";

const styles = {
  card: {
    padding: 10,
    margin: "0px 0px 20px 0px",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
    m: 1,
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
    marginTop: "1rem",
  },
};
const FeeRemittanceView = (props) => {
  const { selectedSetting } = useContext(SettingContext);
  const [formData, setFormData] = useState({});
  const [feeReceiptsBooks, setFeeReceiptBooks] = useState([]);
  const [fromDate, setFromDate] = useState(dayjs().subtract(1, "day"));
  const [toDate, setToDate] = useState(dayjs());
  const [employees, setEmployees] = useState([]);
  const [feeRemittances, setFeeRemittances] = useState([]);
  const [viewDetailDialog, setViewDetailDialog] = useState(false);
  const [selectViewFeeRemittance, setSelectViewFeeRemittance] = useState(null);
  const [sort, setSort] = useState({
    branchName: "asc",
    bankName: "asc",
    createdAt: "asc",
  });

  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const compareDatesAsc = (a, b) => {
    let dateA = new Date(a.createdAt);
    let dateB = new Date(b.createdAt);

    return dateA - dateB;
  };

  const compareDatesDec = (a, b) => {
    let dateA = new Date(a.createdAt);
    let dateB = new Date(b.createdAt);

    return dateB - dateA;
  };

  const sortByDate = () => {
    console.log("Date");

    if (sort.createdAt === "asc") {
      let sortedList = [...feeRemittances.sort(compareDatesDec)];

      setFeeRemittances(sortedList);
      setSort({ ...sort, createdAt: "des" });
    } else {
      let sortedList = [...feeRemittances.sort(compareDatesAsc)];
      setFeeRemittances(sortedList);
      setSort({ ...sort, createdAt: "asc" });
    }
  };

  const sortByBranchName = () => {
    console.log("BranchName");
    if (sort.branchName === "asc") {
      let sortedList = [
        ...feeRemittances.sort((a, b) =>
          b.feeRemittance.branchName.localeCompare(a.feeRemittance.branchName)
        ),
      ];

      setFeeRemittances(sortedList);
      setSort({ ...sort, branchName: "des" });
    } else {
      let sortedList = [
        ...feeRemittances.sort((a, b) =>
          a.feeRemittance.branchName.localeCompare(b.feeRemittance.branchName)
        ),
      ];
      setFeeRemittances(sortedList);
      setSort({ ...sort, branchName: "asc" });
    }
  };

  const sortByBankName = () => {
    console.log("BankName");
    if (sort.bankName === "asc") {
      let sortedList = [
        ...feeRemittances.sort((a, b) =>
          b.feeRemittance.bankName.localeCompare(a.feeRemittance.bankName)
        ),
      ];

      setFeeRemittances(sortedList);
      setSort({ ...sort, bankName: "des" });
    } else {
      let sortedList = [
        ...feeRemittances.sort((a, b) =>
          a.feeRemittance.bankName.localeCompare(b.feeRemittance.bankName)
        ),
      ];
      setFeeRemittances(sortedList);
      setSort({ ...sort, bankName: "asc" });
    }
  };

  useEffect(() => {
    const getReceiptBooks = async () => {
      try {
        const { data, status } = await get(`${urls.recepitBook.getAll}`);
        if (status > 199 && status < 299) {
          setFeeReceiptBooks(data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    const getAcountantEmps = async () => {
      try {
        const { data: role, status } = await get(`${urls.role.getAllRole}`, {
          params: {
            search: {
              roleName: "ACCOUNTANT",
            },
          },
        });
        console.log(role);
        if (status > 199 && status < 299) {
          const { data: employees, status } = await get(
            `${urls.employee.getAllEmployee}`,
            {
              params: {
                search: {
                  role: role.data[0]?._id,
                },
              },
            }
          );
          if (status > 199 && status < 299) {
            setEmployees(employees.data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getAcountantEmps();
    getReceiptBooks();
  }, [selectedSetting._id]);
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleOpenViewDetailModal = () => {
    setViewDetailDialog(true);
  };
  const handleCloseViewDetailModal = () => {
    setViewDetailDialog(false);
  };
  const handleSelectViewDetail = (data) => {
    handleOpenViewDetailModal();
    setSelectViewFeeRemittance(data);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data: feeRemittanceList, status } = await get(
        `${urls.feeRemittance.getAll}`,
        {
          params: {
            receiptItem: formData.feeReceipt,
            toDate,
            fromDate,
            enteredBy: formData.enteredBy,
          },
        }
      );
      if (status > 199 && status < 299) {
        setFeeRemittances(feeRemittanceList.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Card className={css.cardForm}>
        <Box>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl fullWidth required>
                  <InputLabel>Entered By</InputLabel>
                  <Select
                    value={formData.enteredBy || ""}
                    name="enteredBy"
                    size="small"
                    label="Entered By"
                    onChange={handleFormChange}>
                    {employees.map((employee) => (
                      <MenuItem key={employee._id} value={employee._id}>
                        {employee.basicInfo.empName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl fullWidth required>
                  <InputLabel>Fee Receipt</InputLabel>
                  <Select
                    value={formData.feeReceipt || ""}
                    name="feeReceipt"
                    size="small"
                    label="Fee Receipt"
                    onChange={handleFormChange}>
                    {feeReceiptsBooks.map((feeReceipt) => (
                      <MenuItem key={feeReceipt._id} value={feeReceipt._id}>
                        {feeReceipt.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* <Grid item xs={12} md={6} lg={3}>
                <FormControl fullWidth required>
                  <InputLabel>Range</InputLabel>
                  <Select
                    value={formData.range || ""}
                    name="range"
                    size="small"
                    label="Range "
                    onChange={handleFormChange}>
                    <MenuItem value="monthly">monthly</MenuItem>
                    <MenuItem value="quarterly">quarterly</MenuItem>
                    <MenuItem value="halfyearly">half-yearly</MenuItem>
                    <MenuItem value="yearly">yearly</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
              {/* {formData.range === "monthly" && (
                <div>
                  <Grid item xs={12} md={12} lg={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Select Month"
                        views={["month"]}
                        value={new Date()}
                        onChange={(newValue) => {}}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            sx={{ mt: 2 }}
                            fullWidth
                            {...params}
                          />
                        )}
                        openTo="month"
                        //   views={["month"]}
                        onViewChange={() => {}}
                        view={CalendarPicker}
                      />
                    </LocalizationProvider>
                  </Grid>
                </div>
              )} */}

              <Grid item xs={12} md={6} lg={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="From Date"
                    value={fromDate}
                    onChange={(newDate) => setFromDate(newDate)}
                    renderInput={(params) => (
                      <TextField {...params} size="small" fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="To Date"
                    value={toDate}
                    onChange={(newDate) => setToDate(newDate)}
                    renderInput={(params) => (
                      <TextField {...params} size="small" fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Button variant="contained" type="submit">
                  Find
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Card>
      <div className={css.tableContainer}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#1b3779" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Deposit Date
                  <span
                    onClick={sortByDate}
                    style={{ cursor: "pointer", color: "#fff" }}>
                    {sort.createdAt === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Deposit Amount
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span>Bank Name</span>
                  <span
                    onClick={sortByBankName}
                    style={{ cursor: "pointer", color: "#fff" }}>
                    {sort.bankName === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span>Branch Name</span>
                  <span
                    onClick={sortByBranchName}
                    style={{ cursor: "pointer", color: "#fff" }}>
                    {sort.branchName === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Challan No
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  View
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {feeRemittances.map((feeRemittance, index) => (
                <TableRow key={feeRemittance._id}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    {dayjs(feeRemittance.createdAt).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell align="center">
                    {feeRemittance.cashDeposited}
                  </TableCell>
                  <TableCell align="center">{feeRemittance.bankName}</TableCell>
                  <TableCell align="center">
                    {feeRemittance.branchName}
                  </TableCell>
                  <TableCell align="center">
                    {feeRemittance.challanNumber}
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      onClick={() => handleSelectViewDetail(feeRemittance)}
                      size="small"
                      variant="contained">
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <FeeRemittanceViewDetails
        data={selectViewFeeRemittance}
        open={viewDetailDialog}
        onClose={handleCloseViewDetailModal}
      />
    </div>
  );
};
export default FeeRemittanceView;
