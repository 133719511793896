import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useEffect, useState, useContext } from "react";
import { get } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../../components/SectionSubHeader";
import Wrapper from "../../Wrapper";

const styles = {
  card: {
    padding: 15,
    marginBottom: 20,
  },
  textfield: {
    width: "100%",
    fontSize: 12,
    m: 1,
  },
};

const HallTicket = () => {
  const { selectedSetting } = useContext(SettingContext);
  const [examTerms, setExamTerms] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [formData, setFormData] = useState({});
  const [loadngBtn, setLoadingBtn] = useState(false);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const handleClasses = async (e) => {
    try {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
      const res = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: value,
          },
        },
      });
      setSections(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const getExamTitle = async () => {
      try {
        const res = await get(urls.examTerm.getAllExamTerm);
        setExamTerms(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getExamTitle();
  }, [selectedSetting]);

  useEffect(() => {
    const getClassName = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        setClasses(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getClassName();
  }, [selectedSetting]);
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoadingBtn(true);
    try {
      const hallTicket = await get(
        `/${selectedSetting._id}/hallticket/exam-schedule/exam=term/${formData.exam}/class/${formData.schoolClass}/section/${formData.section}`,
        {
          responseType: "blob",
        }
      );
      const uri = URL.createObjectURL(hallTicket.data);
      window.open(uri, "__blank");
    } catch (error) {
      console.log(error);
    }
    setLoadingBtn(false);
  };
  return (
    <Wrapper>
      <SectionSubHeader title=" Hall Ticket " />

      <div style={{ margin: "15px 0" }}>
        <Card style={styles.card}>
          <Box>
            <form onSubmit={handleFormSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" style={styles.textfield} required>
                    <InputLabel id="demo-simple-select-filled-label">
                      Exam
                    </InputLabel>
                    <Select
                      label="Exam"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      onChange={handleOnChange}
                      value={formData.exam || ""}
                      name="exam"
                    >
                      {examTerms &&
                        examTerms.map((row, index) => (
                          <MenuItem key={row._id} value={row._id}>
                            {row.examTitle}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" style={styles.textfield} required>
                    <InputLabel id="demo-simple-select-filled-label">
                      Class
                    </InputLabel>
                    <Select
                      label="Class"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="schoolClass"
                      value={formData.schoolClass || ""}
                      onChange={(event) => handleClasses(event)}
                    >
                      {classes &&
                        classes.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.className}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" style={styles.textfield} required>
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Section
                    </InputLabel>
                    <Select
                      label="Section"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="section"
                      value={formData.section || ""}
                      onChange={(event) => handleOnChange(event)}
                    >
                      {sections &&
                        sections.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.sectionName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  style={{ alignSelf: "center" }}
                  xs={12}
                  md={6}
                  lg={3}
                >
                  <Tooltip title="Generate">
                    <LoadingButton
                      variant="contained"
                      size="small"
                      type="submit"
                      loading={loadngBtn}
                      sx={{
                        backgroundColor: "#1b3779",
                        ":hover": { backgroundColor: "#1b3779" },
                      }}
                    >
                      Generate
                    </LoadingButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Card>
      </div>
    </Wrapper>
  );
};

export default HallTicket;
