import React, { useState, useEffect, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { Grid } from "@material-ui/core";
import "./../styles/components/navbar/Navbar.scss";
import { Language } from "@mui/icons-material";
import "./../styles/components/navbar/NavTop.scss";

import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Card,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TableHead,
  Avatar,
  Modal,
  Typography,
  Tooltip,
  CircularProgress,
  styled,
} from "@mui/material";
import { urls as url } from "./../services/urlConstant";
import "../styles/components/Exam/Exam.scss";
import { get, put } from "../services/apis";
import { objHas } from "../utils/utils";

import style from "../styles/components/Layout/Attendance/AttendanceReport.module.css";
import { DownloadForOffline, Print } from "@mui/icons-material";
import { toast } from "react-toastify";
import SettingContext from "../context/SettingsContext";
import appendStringToUrls from "../utils/appendString";
import NavTopnav from "../components/NavTopnav/NavTopnav";
import Footer from "./Footer";

const styles = {
  card: {
    padding: 10,
    width: "100%",
    marginBottom: "10px",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
    m: 1,
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
    marginTop: "10px",
  },
};
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Heading = styled(Typography)(({ theme }) => ({
  color: "#F86F03",
  fontWeight: "bold",
  fontSize: "2rem",
  marginTop: "1rem",
}));

const MainHeading = styled(Box)(({ theme }) => ({
  textAlign: "center",
}));

const OuterBox = styled(Box)(({ theme }) => ({
  backgroundImage:
    "linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)),url(/admissionbg1.jpg)",
  backgroundRepeat: "no-repeat",
  width: "100%",
  backgroundSize: "100% 100%",
  paddingTop: "250px",
}));

const Results = () => {
  const { selectedSetting } = useContext(SettingContext);
  const [studentsExamResult, setStudentsExamResult] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [examTerms, setExamterms] = useState([]);
  const [examResultDetails, setExamResultDetails] = useState({});
  const [studentMarksDetailsModal, setStudentMarksDetailsModal] =
    useState(false);
  const [studentExamResult, setStudentExamResult] = useState(undefined);
  const [bulkUploadModal, setBulkUploadModal] = useState(false);
  const [sheet, setSheet] = useState(undefined);
  const [sheetLoader, setSheetLoader] = useState(false);
  const [pdfLoader, setPdfLoader] = useState(false);
  const [addClass, updateClass] = useState(false);
  const [stkyClass, setStkyClass] = useState(false);
  const [mega, setMega] = useState(false);
  const [academicYears, setAcademicYears] = useState([]);

  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const academicYearRes = await get(
          `${urls.home.getHome}/academic-year/`
        );
        setAcademicYears(academicYearRes.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, []);

  const handleClickCross = () => {
    updateClass(false);
    window.scrollTo(0, 0);
  };
  const scrollHandleScroll = () => {
    if (window.scrollY > 20) {
      setStkyClass(true);
    } else {
      setStkyClass(false);
    }
  };
  const handleMega = () => {
    setMega(!mega);
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollHandleScroll);
  }, []);

  const menuItems = [
    {
      title: "Home",
      children: [],
    },
    {
      title: "About",
      children: [
        {
          title: "Our School",
          link: "/our-school",
        },
        {
          title: "Principal Desk",
          link: "/principal-desk",
        },
        {
          title: "Faculty",
          link: "/faculty",
        },
      ],
    },
    {
      title: "Gallery",
      children: [],
    },
    {
      title: "Academics",
      children: [],
    },
    {
      title: "Facilities",
      children: [
        {
          title: "Library",
          link: "/library",
        },
        {
          title: "Sports",
          link: "/sports",
        },
        {
          title: "Canteen",
          link: "/canteen",
        },
        {
          title: "Transport",
          link: "/transport",
        },
        {
          title: "Edusmart",
          link: "/Edusmart",
        },
      ],
    },
    {
      title: "Jobs",
      children: [],
    },
    {
      title: "Exam",
      children: [
        {
          title: "Exam Time Table",
          link: "/birth-day",
        },
        {
          title: "Hall Ticket",
          link: "/annual-day",
        },
        {
          title: "Results",
          link: "/freshers-day",
        },
      ],
    },
    {
      title: "Contact Us",
      children: [],
    },
  ];
  const handleClasses = async (e) => {
    try {
      const { name, value } = e.target;
      setExamResultDetails({ ...examResultDetails, [name]: value });
      const res = await get(`/${selectedSetting._id}/home/section`, {
        params: {
          search: {
            class: value,
          },
        },
      });
      setSections(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSections = (e) => {
    const { name, value } = e.target;
    setExamResultDetails({ ...examResultDetails, [name]: value });
  };

  const handleExamTerms = (e) => {
    const { name, value } = e.target;
    setExamResultDetails({ ...examResultDetails, [name]: value });
  };

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(`/${selectedSetting._id}/home/class`);
        setClasses(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, []);

  useEffect(() => {
    const getExamTerms = async () => {
      if (examResultDetails.academicYear) {
        try {
          const res = await get(`/${selectedSetting._id}/home/examterm`, {
            params: {
              search: {
                academicYear: examResultDetails.academicYear,
              },
            },
          });
          setExamterms(res.data.data);
        } catch (error) {
          console.log(error);
        }
      }
    };
    getExamTerms();
  }, [examResultDetails.academicYear]);
  const handleFindStudentResult = async (e) => {
    e.preventDefault();
    try {
      const { examTitle, sectionName, className, academicYear } =
        examResultDetails;
      const resultsRes = await get(
        `/${selectedSetting._id}/students-exam-percentage/class/${className}/section/${sectionName}/exam/${examTitle}/academicYear/${academicYear}`
      );
      if (resultsRes.data.success) {
        setStudentsExamResult(resultsRes.data.data);
      } else {
        console.log(resultsRes);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleModalClose = () => setStudentMarksDetailsModal(false);
  const handleModalOpen = () => setStudentMarksDetailsModal(true);
  const handleStudentMarksDetails = async (stud) => {
    try {
      const detailedMarksRes = await get(
        `/${selectedSetting._id}/exam-result-student/student/${stud._id}/exam/${examResultDetails.examTitle}`
      );

      if (detailedMarksRes.data.success) {
        setStudentExamResult(detailedMarksRes.data.data);
        handleModalOpen();
      } else {
        console.log(detailedMarksRes);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleGettingMarkCard = async (stud) => {
    try {
      const studentMarkCard = await get(
        `/${selectedSetting._id}/exam-result-report/student/${stud._id}/exam/${examResultDetails.examTitle}`,
        {
          responseType: "blob",
        }
      );
      const downloadUri = URL.createObjectURL(studentMarkCard.data);
      window.open(downloadUri, "__blank");
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetUploadSheet = async () => {
    try {
      const { className, sectionName, examTitle } = examResultDetails;
      const getMarksSheet = await get(
        `/${selectedSetting._id}/marks-update-sheet/${className}/${sectionName}/${examTitle}`,
        {
          responseType: "blob",
        }
      );
      const uri = URL.createObjectURL(getMarksSheet.data);
      window.open(uri);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSheetUpload = async (e) => {
    e.preventDefault();
    console.log("test ", sheet.target.files);
    try {
      const formData = new FormData();
      formData.append("sheet", sheet.target.files[0]);
      const { className, sectionName, examTitle } = examResultDetails;
      const uploadSheet = await put(
        `/${selectedSetting._id}/marks-update-sheet/${className}/${sectionName}/${examTitle}`,
        "",
        formData
      );
      // const uploadSheet = awai
    } catch (error) {
      console.log(error);
    }
  };
  const handleMarksPdfDownload = async () => {
    try {
      setPdfLoader(true);
      if (
        examResultDetails.className &&
        examResultDetails.sectionName &&
        examResultDetails.examTitle
      ) {
        const getMarksListPdf = await get(
          `/${selectedSetting._id}/students-marks-pdf/${examResultDetails.className}/${examResultDetails.sectionName}/${examResultDetails.examTitle}`,
          {
            responseType: "blob",
          }
        );
        if (getMarksListPdf.status === 200) {
          const uri = URL.createObjectURL(getMarksListPdf.data);
          const link = document.createElement("a");
          link.href = uri;
          link.setAttribute("download", "AttendanceReport.pdf");
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      }
      setPdfLoader(false);
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleMarksExcelDownload = async () => {
    try {
      setSheetLoader(true);
      if (
        examResultDetails.className &&
        examResultDetails.sectionName &&
        examResultDetails.examTitle
      ) {
        const getMarksListSheet = await get(
          `/${selectedSetting._id}/students-marks-excel/${examResultDetails.className}/${examResultDetails.sectionName}/${examResultDetails.examTitle}`,
          {
            responseType: "blob",
          }
        );
        if (getMarksListSheet.status === 200) {
          const uri = URL.createObjectURL(getMarksListSheet.data);
          const link = document.createElement("a");
          link.href = uri;
          link.setAttribute("download", "AttendanceReport.xlsx");
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      }
      setSheetLoader(false);
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <>
      <NavTopnav />
      <OuterBox>
        <Box>
          <MainHeading>
            <Heading>Results</Heading>
          </MainHeading>
        </Box>
        <div>
          <div>
            <div style={{ margin: 15 }}>
              <Card style={styles.card}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item sx={12} md={12} lg={12}>
                      <span style={{ color: "red", fontFamily: "cursive" }}>
                        Exam Result :
                      </span>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
              <form onSubmit={handleFindStudentResult}>
                <Card style={styles.card}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6} lg={3}>
                        <FormControl
                          variant="standard"
                          sx={styles.textfield}
                          required
                        >
                          <InputLabel
                            id="demo-simple-select-filled-label"
                            sx={{ fontSize: 12 }}
                          >
                            Academic Year
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            name="academicYear"
                            value={examResultDetails.academicYear || ""}
                            onChange={(event) => handleClasses(event)}
                          >
                            {academicYears &&
                              academicYears.map((row, index) => (
                                <MenuItem
                                  key={row._id}
                                  value={row._id}
                                  sx={{ fontSize: 12, fontWeight: 500 }}
                                >
                                  {row.academicYearFrom}-{row.academicYearTo}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <FormControl
                          variant="standard"
                          sx={styles.textfield}
                          required
                        >
                          <InputLabel
                            id="demo-simple-select-filled-label"
                            sx={{ fontSize: 12 }}
                          >
                            Class
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            name="className"
                            value={examResultDetails.className || ""}
                            onChange={(event) => handleClasses(event)}
                          >
                            {classes &&
                              classes.map((row, index) => (
                                <MenuItem
                                  key={row._id}
                                  value={row._id}
                                  sx={{ fontSize: 12, fontWeight: 500 }}
                                >
                                  {row.className}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <FormControl
                          variant="standard"
                          sx={styles.textfield}
                          required
                        >
                          <InputLabel
                            id="demo-simple-select-filled-label"
                            sx={{ fontSize: 12 }}
                          >
                            Section
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            name="sectionName"
                            value={examResultDetails.sectionName || ""}
                            onChange={(event) => handleSections(event)}
                          >
                            {sections &&
                              sections.map((row, index) => (
                                <MenuItem
                                  key={row._id}
                                  value={row._id}
                                  sx={{ fontSize: 12, fontWeight: 500 }}
                                >
                                  {row.sectionName}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <FormControl
                          variant="standard"
                          sx={styles.textfield}
                          required
                        >
                          <InputLabel
                            id="demo-simple-select-filled-label"
                            sx={{ fontSize: 12 }}
                          >
                            Exam
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            name="examTitle"
                            value={examResultDetails.examTitle || ""}
                            onChange={(event) => handleExamTerms(event)}
                          >
                            {examTerms &&
                              examTerms.map((row, index) => (
                                <MenuItem
                                  key={row._id}
                                  value={row._id}
                                  sx={{ fontSize: 12, fontWeight: 500 }}
                                >
                                  {row.examTitle}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={1}
                        sx={{
                          alignSelf: "center",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Tooltip title="Find">
                          <Button
                            variant="contained"
                            style={styles.Button}
                            size="small"
                            type="submit"
                          >
                            Find
                          </Button>
                        </Tooltip>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={2}
                        sx={{
                          alignSelf: "center",
                        }}
                      ></Grid>
                    </Grid>
                  </Box>
                </Card>
              </form>
            </div>
            <div style={{ margin: "15px" }}>
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                {examResultDetails.className &&
                  examResultDetails.sectionName &&
                  examResultDetails.examTitle && (
                    <div className={style.iconContainer}>
                      <div>
                        {sheetLoader ? (
                          <Button size="small">
                            <CircularProgress size={25} />
                          </Button>
                        ) : (
                          <Button onClick={handleMarksExcelDownload}>
                            <DownloadForOffline />
                          </Button>
                        )}
                      </div>
                      <div>
                        {pdfLoader ? (
                          <Button size="small">
                            <CircularProgress size={25} />
                          </Button>
                        ) : (
                          <Button onClick={handleMarksPdfDownload}>
                            <Print />
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead style={{ background: "rgb(27 55 121)" }}>
                      <TableRow>
                        <TableCell align="center">
                          <span className="class-table-header">Roll No</span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="class-table-header">Name</span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="class-table-header">Photo</span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="class-table-header">Exam Mark</span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="class-table-header">
                            Obtained Mark
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="class-table-header">Percentage</span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="class-table-header">Grade</span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="class-table-header">View</span>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {studentsExamResult &&
                        studentsExamResult.map((result, index) => (
                          <TableRow
                            key={result._id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="center">
                              {result.academicInfo.rollNo}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {result.basicInfo.name}
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{
                                textTransform: "capitalize",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Avatar
                                alt="Remy Sharp"
                                src={`${objHas(
                                  result,
                                  "studentPhoto.link",
                                  ""
                                )}`}
                              />
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {result.totalMaxMarks}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {result.totalScoredMarks}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {result.percentage}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {result.grade}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              <Button
                                style={{
                                  background: "#1b3779",
                                  ":hover": { background: "#1b3779" },
                                  margin: "0px 2px",
                                }}
                                size="small"
                                variant="contained"
                                onClick={() =>
                                  handleStudentMarksDetails(result)
                                }
                              >
                                View
                              </Button>
                              <Button
                                style={{
                                  background: "#1b3779",
                                  ":hover": { background: "#1b3779" },
                                  margin: "0px 2px",
                                }}
                                size="small"
                                variant="contained"
                                onClick={() => handleGettingMarkCard(result)}
                              >
                                Card
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {!studentsExamResult.length && (
                  <Typography
                    variant="h6"
                    sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
                  >
                    No data found
                  </Typography>
                )}
              </Paper>
              <Modal
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                open={studentMarksDetailsModal}
                onClose={handleModalClose}
              >
                <Box sx={modalStyle}>
                  {studentExamResult && (
                    <TableContainer
                      component={Paper}
                      style={{
                        width: 700,
                      }}
                    >
                      <Table sx={{ minWidth: 250 }} aria-label="simple table">
                        <TableHead style={{ background: "rgb(27 55 121)" }}>
                          <TableRow>
                            <TableCell align="center">
                              <span className="class-table-header">SL No</span>
                            </TableCell>
                            <TableCell align="center">
                              <span className="class-table-header">
                                Subject
                              </span>
                            </TableCell>
                            <TableCell align="center">
                              <span className="class-table-header">
                                Max Marks
                              </span>
                            </TableCell>
                            <TableCell align="center">
                              <span className="class-table-header">
                                Obtained Marks
                              </span>
                            </TableCell>
                            <TableCell align="center">
                              <span className="class-table-header">Grade</span>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {studentExamResult &&
                            studentExamResult.studentMarks.map(
                              (mark, index) => (
                                <TableRow
                                  key={mark._id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell align="center">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {mark.subject.subjectName}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {mark.maxMarks}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {mark.writtenMarks}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {mark.grade}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                  {studentExamResult && (
                    <div>
                      <Typography variant="subtitle1">
                        Total Subject: {studentExamResult.studentMarks.length}
                      </Typography>
                      <Typography variant="subtitle1">
                        Max Marks: {studentExamResult.totalMax}
                      </Typography>
                      <Typography variant="subtitle1">
                        Obtained Marks: {studentExamResult.totalMarks}
                      </Typography>
                      <Typography variant="subtitle1">
                        Percentage: {studentExamResult.percentage}
                      </Typography>
                    </div>
                  )}
                </Box>
              </Modal>
              <Modal
                open={bulkUploadModal}
                onClose={() => setBulkUploadModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={modalStyle}>
                  <div style={{ textAlign: "center", marginBottom: "30px" }}>
                    <span style={{ color: "red", fontFamily: "cursive" }}>
                      Bulk Upload
                    </span>
                  </div>
                  <form onSubmit={handleSheetUpload}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Button
                          variant="contained"
                          style={styles.Button}
                          onClick={handleGetUploadSheet}
                        >
                          Download
                        </Button>
                      </div>
                      <div>
                        <Button
                          variant="contained"
                          sx={styles.Button}
                          component="label"
                        >
                          Upload
                          <input
                            onChange={(e) => setSheet(e)}
                            name="file"
                            hidden
                            type="file"
                          />
                        </Button>
                      </div>
                      <Button
                        variant="contained"
                        sx={styles.Button}
                        type="submit"
                      >
                        submit
                      </Button>
                    </div>
                  </form>
                </Box>
              </Modal>
            </div>
          </div>
        </div>
      </OuterBox>
      <Footer />
    </>
  );
};

export default Results;
