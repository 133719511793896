import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { del, get, post, put } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import Container from "@mui/material/Container";
import Wrapper from "../../Wrapper";
import SectionSubHeader from "../../SectionSubHeader";

const EmployeeAttendence = () => {
  const [formData, setFormData] = useState([]);
  const [fromDate, setFromDate] = useState(Date.now());
  const [toDate, setToDate] = useState(Date.now());
  const [academicYears, setAcademicYears] = useState([]);
  const [sections, setSections] = useState([]);
  const [month, setMonth] = useState(dayjs(Date.now()));
  const [classes, setClasses] = useState([]);

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll);
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data[0]._id,
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, []);
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        const resSections = await get(urls.schoolSection.getAllSection, {
          params: {
            search: {
              class: res.data.data[0]._id,
            },
          },
        });
        const searchStudents = await get(urls.students.getAllStudent, {
          params: {
            search: {
              academicYear: formData.academicYear,
              "academicInfo.class": res.data.data[0]._id,
              "academicInfo.section": resSections.data.data[0]._id,
            },
          },
        });
        setSections(resSections.data.data);
        setClasses(res.data.data);
        setFormData((prev) => ({
          ...prev,
          className: res.data.data[0]._id,
          section: resSections.data.data[0]._id,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, []);

  const handleClassChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    try {
      const section = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      if (section.data.data.length > 0) {
        setFormData((prev) => ({
          ...prev,
          section: section.data.data[0]._id,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          section: "",
        }));
      }
      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const styles = {
    Button: {
      background: "#1b3779",
      color: "#fff",
      ":hover": { background: "#1b3779" },
      marginLeft: "10px",
      marginTop: "5px",
    },
    Cards: {
      marginTop: "20px",
      padding: "10px",
      marginBottom: "10px",
    },
  };
  const numbers = [];
  for (let i = 1; i <= 31; i++) {
    numbers.push(
      <TableCell sx={{ color: "white", display: "inline" }} key={i}>
        {i}
      </TableCell>
    );
  }
  return (
    <Wrapper>
      <SectionSubHeader title="Employee Attendance" />
      <Box sx={{ mt: "15px" }}>
        <Card sx={{ padding: "10px" }}>
          <span
            style={{
              color: "red",
              fontSize: "large",
              fontFamily: "cursive",
            }}
          >
            Employee Attendence :
          </span>
        </Card>

        <Card style={styles.Cards}>
          <Box sx={{ flexGrow: 1 }}>
            <form>
              <Grid container spacing={2} marginBottom={"5px"}>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl fullWidth required>
                    <InputLabel>Academic Year</InputLabel>
                    <Select
                      label="Academic Year"
                      id="demo-simple-select-filled"
                      name="academicYear"
                      variant="standard"
                      value={formData.academicYear || ""}
                      onChange={handleOnChange}
                      size="small"
                    >
                      {academicYears &&
                        academicYears.map((row, index) => (
                          <MenuItem key={row._id} value={row._id}>
                            {row.academicYearFrom} - {row.academicYearTo}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={2}>
                      <DatePicker
                        label="Select Month"
                        views={["month"]}
                        inputFormat="MMMM"
                        value={month}
                        onChange={(newValue) => {
                          setMonth(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            variant="standard"
                            {...params}
                          />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={1}
                  style={{ alignSelf: "flex-end" }}
                >
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    style={styles.Button}
                  >
                    find
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Card>
        <Box sx={{ overflowX: "scroll" }}>
          <Table>
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  Employee Date
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  Working Days
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Present Days
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Absent Days
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  {numbers}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center"></TableCell>

                <TableCell align="center"></TableCell>

                <TableCell align="center"></TableCell>
                <TableCell align="center">5</TableCell>
                <TableCell align="center">4</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default EmployeeAttendence;
