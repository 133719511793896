import React, { useContext, useState } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Card,
  Grid,
  Tooltip,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { urls as url } from "../../../services/urlConstant";
import { del, post, put, get } from "../../../services/apis";
import { Delete, Edit, Search } from "@mui/icons-material";
import TableHead from "@mui/material/TableHead";
import { useEffect } from "react";
import DeleteModal from "../Academic/DeleteModal";
import AddUserRole from "./AddUserRole";

// styles
import styles from "../../../styles/components/Layout/Administrator/UserRole.module.css";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";

const UserRole = () => {
  const { selectedSetting } = useContext(SettingContext);
  const [schoolUserRole, setSchoolUserRole] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [updateUserRole, setUpdateUserRole] = useState(undefined);
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ roleName: "asc" });

  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  let [loader, setLoader] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setUpdateUserRole(undefined);
  };

  const handleDelete = async (id) => {
    try {
      const res = await del(urls.role.delRole, id);
      if (res.data.success) {
        setSchoolUserRole(
          schoolUserRole.filter((item) => item._id !== res.data.data._id)
        );
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e, data) => {
    // setLoader(true);
    try {
      e.preventDefault();
      const res = await post(urls.role.postRole, data);
      setSchoolUserRole([...schoolUserRole, res.data.data]);
    } catch (error) {
      console.log(error);
    }
    // setLoader(false);
  };

  const handleUpdate = async (data, id) => {
    //setLoader(true);
    try {
      const response = await put(urls.role.putRole, id, data);
      setSchoolUserRole(
        schoolUserRole.map((e) => {
          if (e._id === response.data.data._id) {
            return response.data.data;
          } else {
            return e;
          }
        })
      );
    } catch (error) {
      console.log(error);
    }
    //setLoader(false);
  };
  const handleEdit = async (id) => {
    try {
      setOpen(true);
      const getUserRoleById = schoolUserRole.find((item) => item._id === id);
      setUpdateUserRole(getUserRoleById);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getUserRole = async () => {
      try {
        const res = await get(urls.role.getAllRole);
        setSchoolUserRole([...res.data.data]);
        setSearchFilter([...res.data.data]);
      } catch (error) {
        console.log(error);
      }
    };
    getUserRole();
  }, []);
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      setSearchFilter(
        schoolUserRole.filter((ele) =>
          ele.roleName.toLowerCase().includes(value.trim().toLowerCase())
        )
      );
    } else {
      setSearchFilter([...schoolUserRole]);
    }
  };
  const roleNameSorted = () => {
    console.log("roleName");
    if (sort.roleName == "asc") {
      let sortedList = [
        ...schoolUserRole.sort((a, b) => b.roleName.localeCompare(a.roleName)),
      ];

      setSchoolUserRole(sortedList);
      setSort({ ...sort, roleName: "des" });
    } else {
      let sortedList = [
        ...schoolUserRole.sort((a, b) => a.roleName.localeCompare(b.roleName)),
      ];
      setSchoolUserRole(sortedList);
      setSort({ ...sort, roleName: "asc" });
    }
  };
  console.log("schoolUserRole", schoolUserRole);
  return (
    <div>
      <Card style={{ margin: 15, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span style={{ color: "red", fontFamily: "cursive" }}>
              User Role :
            </span>
          </Grid>
        </Grid>
      </Card>
      <div className={styles.searchInputContainer}>
        <Grid container spacing={2} justifyContent="flex-end" sx={{marginTop:"15px"}}>
          <Grid xs="auto">
            <TextField
              size="small"
              value={search}
              placeholder="Search By Role Name"
              onChange={handleSearch}
              label="Search"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </div>
      <div style={{ margin: "15px" }}>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">
                      {" "}
                      Role Name
                      <span
                        onClick={roleNameSorted}
                        style={{ cursor: "pointer" }}
                      >
                        {sort.roleName === "asc" ? "▲" : "▼"}
                      </span>
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Action</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {search
                  ? searchFilter.map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.roleName}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div className="edit-delete">
                           
                              <Tooltip title="Edit" disableInteractive>
                                <Button onClick={() => handleEdit(row._id)}>
                                  <Edit />
                                </Button>
                              </Tooltip>
                          
                            {row.canDelete ? null : (
                              <Tooltip title="Delete" disableInteractive>
                                <Button onClick={() => setDeleteModal(row._id)}>
                                  <Delete />
                                </Button>
                              </Tooltip>
                            )}
                          </div>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={row._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  : schoolUserRole.map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.roleName}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div className="edit-delete">
                           
                              <Tooltip title="Edit" disableInteractive>
                                <Button onClick={() => handleEdit(row._id)}>
                                  <Edit />
                                </Button>
                              </Tooltip>
                           
                            {!row.canDelete ? null : (
                              <Tooltip title="Delete" disableInteractive>
                                <Button onClick={() => setDeleteModal(row._id)}>
                                  <Delete />
                                </Button>
                              </Tooltip>
                            )}
                          </div>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={row._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          {!schoolUserRole.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </Paper>
        <div className="add-icon">
          <AddUserRole
            loader={loader}
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            handleUpdate={handleUpdate}
            updateUserRole={updateUserRole}
          />
        </div>
      </div>
    </div>
  );
};

export default UserRole;
