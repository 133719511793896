import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Dialog,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextareaAutosize,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import { useState, useContext } from "react";
import { useEffect } from "react";
import { urls as url } from "../../../services/urlConstant";

import { LoadingButton } from "@mui/lab";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { useTheme } from "@mui/material/styles";

const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
};
// const ContainerBox = styled(Box)(({ theme }) => ({
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "100%",
//   maxWidth: "650px",
//   maxHeight: "650px",
//   borderRadius: "5px",
//   height: "auto",
//   // overflow:"scroll",
//   backgroundColor: "white",
//   padding: "20px",
//   // border:"solid red",
//   [theme.breakpoints.down("md")]: {
//     overflow: "auto",
//     width: "90%",
//   },
//   [theme.breakpoints.between(819, 821)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.between(767, 769)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.between(911, 913)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.between(1023, 1025)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.between(1279, 1281)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "100%",
//     height: "100%",
//     overflow: "auto",
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "center",
//   },
// }));

const AddExamGrade = (props) => {
  const { selectedSetting } = useContext(SettingContext);
  const [examGrade, setExamGrade] = useState("");
  const [examGroup, setExamGroup] = useState("");
  const [examGradePoint, setExamGradePoint] = useState("");
  const [markFrom, setMarkFrom] = useState("");
  const [markTo, setMarkTo] = useState("");
  const [note, setNote] = useState("");

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  useEffect(() => {
    if (props.updateExam) {
      setExamGrade(props.updateExam.examGrade);
      setExamGroup(props.updateExam.group);
      setExamGradePoint(props.updateExam.gradePoint);
      setMarkFrom(props.updateExam.markFrom);
      setMarkTo(props.updateExam.markTo);
      setNote(props.updateExam.note);
    }
  }, [props.updateExam]);

  const handleExamGrade = (e) => {
    setExamGrade(e.target.value);
  };
  const handleExamgroup = (e) => {
    setExamGroup(e.target.value);
  };
  const handleExamGradePoint = (e) => {
    setExamGradePoint(e.target.value);
  };

  const handleMarkFrom = (e) => {
    setMarkFrom(e.target.value);
  };

  const handleMarkTo = (e) => {
    setMarkTo(e.target.value);
  };
  const handleExamNote = (e) => {
    setNote(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (props.updateExam) {
      await props.handleUpdate(
        {
          examGrade,
          group: examGroup,
          gradePoint: examGradePoint,
          markFrom,
          markTo,
          note,
        },
        props.updateExam._id
      );
    } else {
      await props.handleSubmit(e, {
        examGrade,
        group: examGroup,
        gradePoint: examGradePoint,
        markFrom,
        markTo,
        note,
      });
    }
    setExamGrade("");
    setExamGroup("");
    setExamGradePoint("");
    setMarkFrom("");
    setMarkTo("");
    setNote("");
    props.handleClose();
  };

  const handleCloseModal = () => {
    setExamGrade("");
    setExamGroup("");
    setExamGradePoint("");
    setMarkFrom("");
    setMarkTo("");
    setNote("");
    props.handleClose();
  };

  const handleClose = () => {
    setExamGrade("");
    setExamGroup("");
    setExamGradePoint("");
    setMarkFrom("");
    setMarkTo("");
    setNote("");
    props.handleClose();
  };
  return (
    <div>
      <Tooltip title="Add" disableInteractive>
        <Fab
          size="medium"
          style={{ background: "#1b3779", color: "#fff" }}
          aria-label="add"
          onClick={props.handleOpen}
        >
          <Add />
        </Fab>
      </Tooltip>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleSubmit}>
          <Box sx={style}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-end",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  textAlign="center"
                  fontSize="20px"
                  fontWeight="bold"
                >
                  Add Exam Grade
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  fullWidth
                  required
                  id="filled-required"
                  size="small"
                  label="Exam Grade"
                  value={examGrade}
                  onChange={handleExamGrade}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormControl fullWidth size="small" required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Group
                  </InputLabel>
                  <Select
                    label="Group"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={examGroup}
                    onChange={handleExamgroup}
                  >
                    <MenuItem
                      value={"scholastic"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Scholastic
                    </MenuItem>
                    <MenuItem
                      value={"co-scholastic"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Co-scholastic
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  fullWidth
                  required
                  id="filled-required"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    type: "number",
                  }}
                  size="small"
                  value={examGradePoint}
                  onChange={handleExamGradePoint}
                  label="Grade Point"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    type: "number",
                  }}
                  id="filled-required"
                  size="small"
                  value={markFrom}
                  onChange={handleMarkFrom}
                  label="Percentage From"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  required
                  id="filled-required"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    type: "number",
                  }}
                  value={markTo}
                  onChange={handleMarkTo}
                  label="Percentage To"
                />
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <TextareaAutosize
                  maxRows={4}
                  aria-label="maximum height"
                  placeholder="Drop a note"
                  value={note}
                  onChange={handleExamNote}
                  style={{
                    width: "100%",
                    maxHeight: 110,
                    borderRadius: "5px",
                    padding: 10,
                    height: 70,
                    overflow: "auto",
                  }}
                />
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: {
                      xs: "center",
                      sm: "center",
                      md: "flex-end",
                      lg: "flex-end",
                    },
                  }}
                >
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="error"
                    size="medium"
                    type="button"
                    sx={{
                      m: 1,
                      color: "#fff",
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={props.loader}
                    disabled={
                      !(
                        examGrade &&
                        examGroup &&
                        examGradePoint &&
                        markFrom &&
                        markTo
                      )
                    }
                    variant="contained"
                    size="medium"
                    type="submit"
                    style={{ background: "#1b3779", color: "#fff" }}
                  >
                    Submit
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Dialog>
    </div>
  );
};

export default AddExamGrade;
