import React, { useContext } from "react";
import Navbar from "../navbar/Navbar";
import TopNav from "../navbar/TopNav";
import { Outlet } from "react-router-dom";
import SettingContext from "../../context/SettingsContext";

// theme 2 components

import TopNav2 from "../../NavbarComponents/TopNav";
import MainNav from "../../NavbarComponents/MainNav";

const NavTopnav = () => {
  const { selectedSetting } = useContext(SettingContext);

  if (selectedSetting.themeOrder == 1) {
    return (
      <div>
        <TopNav />
        <Navbar />
        <Outlet />
      </div>
    );
  } else {
    return (
      <div>
        <TopNav2 />
        <MainNav />
        <Outlet />
      </div>
    );
  }
};

export default NavTopnav;
