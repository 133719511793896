const galleryData = [
  {
    title: "Learning Management System",
    content:
      " Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illum corrupti unde dolor aliquam commodi cum aut magnam a cumque, veritatis repellat facere eos tempora quas! Esse quas praesentium numquam minus dicta",
    images: [
      new URL(
        "https://i.pinimg.com/236x/15/49/08/154908b6e42458d2d1cb6f5b7b2864a1.jpg"
      ),
      new URL(
        "https://i.pinimg.com/236x/36/ee/cf/36eecfd8ba8d418efdfda5c556b4ac9d.jpg"
      ),
      new URL(
        "https://i.pinimg.com/236x/2e/94/f7/2e94f79c5c4e395aab3793976bd3923c.jpg"
      ),
      new URL(
        "https://i.pinimg.com/236x/15/49/08/154908b6e42458d2d1cb6f5b7b2864a1.jpg"
      ),
      new URL(
        "https://i.pinimg.com/236x/e5/71/ed/e571ed3bf8792c59b937d1587e4a0d35.jpg"
      ),
      new URL(
        "https://i.pinimg.com/236x/ca/5f/59/ca5f59b3f1b253217cacb09ee5831bbc.jpg"
      ),
      new URL(
        "https://i.pinimg.com/236x/ae/23/65/ae2365a2c99314afa59c6431fb7bc231.jpg"
      ),
    ],
  },
  {
    title: "Marketing and Management ",
    content:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quaerat, voluptate.",
    images: [
      new URL(
        "https://i.pinimg.com/236x/15/49/08/154908b6e42458d2d1cb6f5b7b2864a1.jpg"
      ),
      new URL(
        "https://i.pinimg.com/236x/36/ee/cf/36eecfd8ba8d418efdfda5c556b4ac9d.jpg"
      ),
      new URL(
        "https://i.pinimg.com/236x/2e/94/f7/2e94f79c5c4e395aab3793976bd3923c.jpg"
      ),
      new URL(
        "https://i.pinimg.com/236x/15/49/08/154908b6e42458d2d1cb6f5b7b2864a1.jpg"
      ),
      new URL(
        "https://i.pinimg.com/236x/e5/71/ed/e571ed3bf8792c59b937d1587e4a0d35.jpg"
      ),
      new URL(
        "https://i.pinimg.com/236x/ca/5f/59/ca5f59b3f1b253217cacb09ee5831bbc.jpg"
      ),
      new URL(
        "https://i.pinimg.com/236x/ae/23/65/ae2365a2c99314afa59c6431fb7bc231.jpg"
      ),
    ],
  },
  {
    title: "Web Design Advance Training Center",
    content:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quaerat, voluptate.",
    images: [
      new URL(
        "https://i.pinimg.com/236x/15/49/08/154908b6e42458d2d1cb6f5b7b2864a1.jpg"
      ),
      new URL(
        "https://i.pinimg.com/236x/36/ee/cf/36eecfd8ba8d418efdfda5c556b4ac9d.jpg"
      ),
      new URL(
        "https://i.pinimg.com/236x/2e/94/f7/2e94f79c5c4e395aab3793976bd3923c.jpg"
      ),
      new URL(
        "https://i.pinimg.com/236x/15/49/08/154908b6e42458d2d1cb6f5b7b2864a1.jpg"
      ),
      new URL(
        "https://i.pinimg.com/236x/e5/71/ed/e571ed3bf8792c59b937d1587e4a0d35.jpg"
      ),
      new URL(
        "https://i.pinimg.com/236x/ca/5f/59/ca5f59b3f1b253217cacb09ee5831bbc.jpg"
      ),
      new URL(
        "https://i.pinimg.com/236x/ae/23/65/ae2365a2c99314afa59c6431fb7bc231.jpg"
      ),
    ],
  },
  {
    title: "Learning Management System",
    content:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quaerat, voluptate.",
    images: [
      new URL(
        "https://i.pinimg.com/236x/15/49/08/154908b6e42458d2d1cb6f5b7b2864a1.jpg"
      ),
      new URL(
        "https://i.pinimg.com/236x/36/ee/cf/36eecfd8ba8d418efdfda5c556b4ac9d.jpg"
      ),
      new URL(
        "https://i.pinimg.com/236x/2e/94/f7/2e94f79c5c4e395aab3793976bd3923c.jpg"
      ),
      new URL(
        "https://i.pinimg.com/236x/15/49/08/154908b6e42458d2d1cb6f5b7b2864a1.jpg"
      ),
      new URL(
        "https://i.pinimg.com/236x/e5/71/ed/e571ed3bf8792c59b937d1587e4a0d35.jpg"
      ),
      new URL(
        "https://i.pinimg.com/236x/ca/5f/59/ca5f59b3f1b253217cacb09ee5831bbc.jpg"
      ),
      new URL(
        "https://i.pinimg.com/236x/ae/23/65/ae2365a2c99314afa59c6431fb7bc231.jpg"
      ),
    ],
  },
];

export default galleryData;
