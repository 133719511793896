import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  Box,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Delete } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 450,
  bgcolor: "background.paper",
  p: 4,
};
const ManageRoomFee = () => {
  const [search, setSearch] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const styles = {
    button: {
      backgroundColor: "orange",
    },
    textfield: {
      marginTop: "25px",
      width: "70%",
    },
    addbutton: {
      paddingTop: "6.2px",
      paddingBottom: "6.2px",
    },
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
  };
  return (
    <div>
      <Card style={{ margin: 15, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span
              style={{
                color: "red",
                fontFamily: "cursive",
                textAlign: "start",
              }}
            >
              Hostel Room Fee:
            </span>
          </Grid>
        </Grid>
      </Card>
      <div style={{ margin: "15px" }}>
        <Paper sx={{ width: "100%", height: "60px", overflow: "hidden" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid xs={3}>
                <TextField
                  size="small"
                  style={styles.textfield}
                  value={search}
                  onChange={handleSearch}
                  label="Search"
                  variant="outlined"
                />
              </Grid>
              <Grid>
                <Button
                  variant="contained"
                  sx={{ mt: 3.2 }}
                  style={styles.addbutton}
                  // onClick={handleClickOpen}
                >
                  <AddIcon />
                  Manage Room Fee List
                </Button>
              </Grid>
            </Grid>
          </div>
        </Paper>

        <Table>
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow sx={{ color: "white" }}>
              <TableCell sx={{ color: "white" }} align="center">
                #SL
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Name
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Specification
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Fee Plan
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableCell align="center">1</TableCell>
            <TableCell align="center">AC Room</TableCell>
            <TableCell align="center">Delux</TableCell>
            <TableCell align="center"></TableCell>

            <TableCell align="center">
              <div style={{ marginLeft: "30px" }}>
                <Button>
                  <a href="/freeplan" style={{ color: "#1b3779" }}>
                    {" "}
                    <EditIcon />
                  </a>
                </Button>
              </div>
            </TableCell>
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
export default ManageRoomFee;
