import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { Grid } from "@material-ui/core";
import "./../styles/components/navbar/Navbar.scss";
import { Language } from "@mui/icons-material";
import "./../styles/components/navbar/NavTop.scss";
import css from "./Addmission.module.css";
import Footer from "./Footer/Footer";
import TopNav from "./navbar/TopNav";
import Navbar from "./navbar/Navbar";
import Demo from "./NewsNotic/Demo";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const Toppers = () => {
  function createData(name, mark, percent) {
    return { name, mark, percent };
  }

  const rows2023 = [
    createData("Sachin S Sajjan", "621/625", 99.36),
    createData("Shruti R Rathod", "620/625", 99.2),
    createData("Vijayalaxmi S Reddy", "619/625", 99.04),
  ];
  const rows2022 = [
    createData("Pavitra Goravagundagi", "624/625", 99.84),
    createData("Prajwal Surgihalli", "624/625", 99.84),
    createData("Pallavi Bagewadi", "623/625", 99.68),
    createData("Vivek Nadagoudar", "623/625", 99.68),
    createData("Pranjal Kasabegoudar", "622/625", 99.52),
    createData("Priyanka Salotagi", "622/625", 99.52),
  ];
  const rows2021 = [
    createData("Prajwal KS", "621/625", 99.36),
    createData("Rohit Ganiger", "621/625", 99.36),
    createData("Rakshita Biradar", "620/625", 99.2),
    createData("Anagha Rajamanya", "618/625", 98.88),
    createData("Pranjal Kasabegoudar", "622/625", 99.52),
    createData("Aditya Nadagatti", "618/625", 98.88),
  ];
  const rows2020 = [
    createData("Akash Jitti", "622/625", 99.52),
    createData("Pramod I Ganachari", "622/625", 99.52),
    createData("Prasanna R Talawar", "622/625", 99.52),
    createData("Shrusti Patil", "618/625	", 98.88),
    createData("Sneha Yacchi", "	617/625", 98.72),
  ];
  const rows2019 = [
    createData("Supriya M Joshi", "625/625", 100),
    createData("Soumya Gangadhar Bagewadi", "620/625", 99.2),
    createData("Sayed Arsalan P Inamdar", "619/625", 99.04),
  ];
  const rows2018 = [
    createData(" Vishwareddy Biradar", "620/625", 99.2),
    createData("Vishwanath Kolakur", "617/625", 98.72),
    createData("Sathvik B M", "616/625", 98.56),
  ];
  const rows2017 = [
    createData(" Sakshi Biradar", "621/625", 99.36),
    createData(" Akash. R. Lalasangi", "618/625", 98.88),
    createData("Shashank. S. Nagathan", "	617/625", 98.72),
  ];
  const rows2016 = [
    createData(" Sayyed Maaz Inamadar", "624/625", 99.84),
    createData("Kshama S. Kelur", "622/625", 99.52),
    createData("Shridhar Dodamani", "	622/625", 99.52),
  ];
  const rows2015 = [
    createData("Rashmi Kandibal", "618/625", 98.88),
    createData("S.K.Vidyashri", "618/625", 98.88),
    createData("Annaray Kalashetty	", "617/625", 98.72),
  ];
  const rows2014 = [
    createData("Rohit Teli", "608/625", 97.28),
    createData("Amruta Kumasi", "607/625", 97.12),
    createData("Abubakar Baichabal", "	604/625", 96.64),
  ];
  const rows2013 = [
    createData("Shankar Dodamani", "613/625", 98.08),
    createData("Prashant Badiger", "	595/625", 95.2),
    createData(" Janvekar", "595/625", 95.2),
  ];

  return (
    <>
      <TopNav />
      <Navbar />
      <div className={css.headerContainer}>
        <div>
          <h1>TOPPER'S LIST</h1>
          <h5>
            <Link
              style={{
                color: "white",
              }}
              to="/"
            >
              Home
            </Link>{" "}
            / TOPPER'S LIST
          </h5>
        </div>
      </div>

      <Grid container spacing={12} marginLeft={"-76px"}>
        <Grid
          items
          xs={12}
          mg={12}
          lg={7}
          style={{
            margin: "40px 0px 20px 0px",
            "border-right": "1px solid #BDBDBD",
          }}
        >
          <h4 style={{ margin: "0px 10px 20px 40px", fontFamily: "cursive" }}>
            Following is the list of district top 3 candidates from our school
            who brought laurels to excellent school.
          </h4>

          <TableContainer
            component={Paper}
            style={{
              margin: "0px 10px 20px 70px",
              fontSize: "1.11rem",
              width: "850px",
            }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "800", color: "#f07b47" }}>
                    2023
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "800", color: "#f07b47" }}
                  >
                    Total Mark
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ fontWeight: "800", color: "#f07b47" }}
                  >
                    Percentage
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ borderBottom: "underline" }}>
                {rows2023.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      style={{ width: "410px" }}
                      component="th"
                      scope="row"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell style={{ width: "190px" }} align="center">
                      {row.mark}
                    </TableCell>
                    <TableCell style={{ width: "165px" }} align="right">
                      {row.percent}
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "800", color: "#f07b47" }}>
                    2022
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ fontWeight: "800" }}
                  ></TableCell>
                  <TableCell
                    align="right"
                    style={{ fontWeight: "800" }}
                  ></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ borderBottom: "underline" }}>
                {rows2022.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      style={{ maxWidth: "160px" }}
                      component="th"
                      scope="row"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="center">{row.mark}</TableCell>
                    <TableCell align="right">{row.percent}</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ borderTop: "0.1px solid #c9c1c1" }}>
                <TableRow>
                  <TableCell style={{ fontWeight: "800", color: "#f07b47" }}>
                    2021
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows2021.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      style={{ maxWidth: "160px" }}
                      component="th"
                      scope="row"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="center">{row.mark}</TableCell>
                    <TableCell align="right">{row.percent}</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ borderTop: "0.1px solid #c9c1c1" }}>
                <TableRow>
                  <TableCell style={{ fontWeight: "800", color: "#f07b47" }}>
                    2020
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows2020.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      style={{ maxWidth: "160px" }}
                      component="th"
                      scope="row"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="center">{row.mark}</TableCell>
                    <TableCell align="right">{row.percent}</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ borderTop: "0.1px solid #c9c1c1" }}>
                <TableRow>
                  <TableCell style={{ fontWeight: "800", color: "#f07b47" }}>
                    2019
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows2019.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      style={{ maxWidth: "160px" }}
                      component="th"
                      scope="row"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="center">{row.mark}</TableCell>
                    <TableCell align="right">{row.percent}</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ borderTop: "0.1px solid #c9c1c1" }}>
                <TableRow>
                  <TableCell style={{ fontWeight: "800", color: "#f07b47" }}>
                    2018
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows2018.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ maxWidth: "170px" }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="center">{row.mark}</TableCell>
                    <TableCell align="right">{row.percent}</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ borderTop: "0.1px solid #c9c1c1" }}>
                <TableRow>
                  <TableCell style={{ fontWeight: "800", color: "#f07b47" }}>
                    2017
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows2017.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      style={{ maxWidth: "160px" }}
                      component="th"
                      scope="row"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="center">{row.mark}</TableCell>
                    <TableCell align="right">{row.percent}</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ borderTop: "0.1px solid #c9c1c1" }}>
                <TableRow>
                  <TableCell style={{ fontWeight: "800", color: "#f07b47" }}>
                    2016
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows2016.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      style={{ maxWidth: "160px" }}
                      component="th"
                      scope="row"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="center">{row.mark}</TableCell>
                    <TableCell align="right">{row.percent}</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ borderTop: "0.1px solid #c9c1c1" }}>
                <TableRow>
                  <TableCell style={{ fontWeight: "800", color: "#f07b47" }}>
                    2015
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows2015.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      style={{ maxWidth: "160px" }}
                      component="th"
                      scope="row"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="center">{row.mark}</TableCell>
                    <TableCell align="right">{row.percent}</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ borderTop: "0.1px solid #c9c1c1" }}>
                <TableRow>
                  <TableCell style={{ fontWeight: "800", color: "#f07b47" }}>
                    2014
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows2014.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      style={{ maxWidth: "160px" }}
                      component="th"
                      scope="row"
                    >
                      {row.name}
                    </TableCell>

                    <TableCell align="center">{row.mark}</TableCell>
                    <TableCell align="right">{row.percent}</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ borderTop: "0.1px solid #c9c1c1" }}>
                <TableRow>
                  <TableCell style={{ fontWeight: "800", color: "#f07b47" }}>
                    2013
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows2013.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      style={{ maxWidth: "160px" }}
                      component="th"
                      scope="row"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="center">{row.mark}</TableCell>
                    <TableCell align="right">{row.percent}</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid
          items
          xs={12}
          mg={12}
          lg={5}
          style={{ height: "80vh", paddingTop: "40px" }}
        >
          <Demo />
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

export default Toppers;
