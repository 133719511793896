import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Dialog,
  Fab,
  Grid,
  TextField,
  Tooltip,
  styled,
  useMediaQuery,
} from "@mui/material";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import "../../../styles/components/AcademicYear/DateModal.scss";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material/styles";

const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
};

const DateModal = (props) => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [note, setNote] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleFormDate = (e) => {
    setFromDate(e.target.value);
  };
  const handleToDate = (e) => {
    setToDate(e.target.value);
  };
  const handleNote = (e) => {
    setNote(e.target.value);
  };
  useEffect(() => {
    if (props.updateAcademicYear) {
      console.log(props.updateAcademicYear);
      setFromDate(props.updateAcademicYear.academicYearFrom);
      setToDate(props.updateAcademicYear.academicYearTo);
      setNote(props.updateAcademicYear.note);
    }
  }, [props.updateAcademicYear]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (props.updateAcademicYear) {
      await props.handleUpdate(
        {
          academicYearFrom: fromDate,
          academicYearTo: toDate,
          note,
        },
        props.updateAcademicYear._id
      );
    } else {
      await props.handleSubmit(e, {
        academicYearFrom: fromDate,
        academicYearTo: toDate,
        note,
      });
    }
    setFromDate("");
    setNote("");
    setToDate("");
    props.handleClose();
  };

  const handleCloseModal = () => {
    setFromDate("");
    setNote("");
    setToDate("");
    props.handleClose();
  };

  const handleClose = () => {
    setFromDate("");
    setNote("");
    setToDate("");
    props.handleClose();
  };
  return (
    <div>
      <Tooltip title="Add" disableInteractive>
        <Fab
          size="medium"
          style={{ background: "#1b3779", color: "#fff" }}
          aria-label="add"
          onClick={props.handleOpen}
        >
          <Add />
        </Fab>
      </Tooltip>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form className="form-tag" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  textAlign="center"
                  fontSize="20px"
                  fontWeight="bold"
                >
                  Add Academic Year
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  id="filled-required"
                  label="From Year"
                  value={fromDate}
                  onChange={handleFormDate}
                  size="small"
                  sx={{ width: "100%" }}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  id="filled-required"
                  label="To Year"
                  value={toDate}
                  onChange={handleToDate}
                  size="small"
                  sx={{ width: "100%" }}
                  required
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <TextareaAutosize
                  value={note}
                  onChange={handleNote}
                  maxRows={4}
                  aria-label="maximum height"
                  placeholder="Drop a note"
                  style={{
                    width: "100%",
                    maxHeight: 110,
                    // margin: 8,
                    padding: 10,
                    height: 70,
                    overflow: "auto",
                    borderRadius: "5px",
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                display="flex"
                justifyContent={{
                  xs: "center",
                  sm: "center",
                  md: "flex-end",
                  lg: "flex-end",
                }}
              >
                <Tooltip title="Cancel" disableInteractive>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    size="medium"
                    type="button"
                    color="error"
                    sx={{
                      m: 1,
                      color: "#fff",
                      // borderColor: "rgb(27, 55, 121)",
                    }}
                  >
                    Cancel
                  </Button>
                </Tooltip>
                <Tooltip title="Submit" disableInteractive>
                  <LoadingButton
                    loading={props.loader}
                    variant="contained"
                    size="medium"
                    sx={{
                      m: 1,
                      background: "#1b3779",
                      ":hover": { background: "#1b3779" },
                    }}
                    type="submit"
                  >
                    Submit
                  </LoadingButton>
                </Tooltip>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Dialog>
    </div>
  );
};

export default DateModal;
