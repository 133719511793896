import {Typography} from "@mui/material";
import React, { useState } from "react";
import "../../../styles/components/Horizontal/Horizontal.scss";


const defaultPopupData = {
  title: "",
  text: ""
};

const SplashNewsHorizontal = ({horizontalData = defaultPopupData}) => {
  return (
    <div className="hwrap">
      <div className="hmove ">
        <div className="hitem">
        
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            textAlign="center"
            marginBottom="20px">
            {horizontalData.title}
          </Typography>
        </div>

        <div className="hitem">
          {horizontalData.text ? (
            <Typography>{horizontalData.text}</Typography>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SplashNewsHorizontal;
