import React, { useState, useEffect, useContext } from "react";
import "../../../styles/components/AcademicYear/AcademicYear.scss";
import {
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import "../../../styles/components/Academic/Academic.scss";
import { del, get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { Delete, Edit, Search } from "@mui/icons-material";
import AddHoliday from "./AddHoliday";
import DeleteModal from "../Academic/DeleteModal";
import dayjs from "dayjs";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../../components/SectionSubHeader";

import styles from "../../../styles/components/Layout/Administrator/UserRole.module.css";
import Wrapper from "../../Wrapper";

const AnnounceHoliday = () => {
  const { selectedSetting } = useContext(SettingContext);

  const [anounceHoliday, setAnounceHoliday] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [updateHoliday, setUpdateHoliday] = useState(undefined);
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [showSearch, setShowSearch] = useState(false);
  const [loader, setLoader] = useState(false);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const [sort, setSort] = useState({
    title: "asc",
    webView: "asc",
    date: "asc",
    fromDate: "asc",
    toDate: "asc",
  });

  const sortByTitle = () => {
    console.log("Title");
    if (search) {
      if (sort.title === "asc") {
        let sortedList = [
          ...searchFilter.sort((a, b) => b.title.localeCompare(a.title)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, title: "des" });
      } else {
        let sortedList = [
          ...searchFilter.sort((a, b) => a.title.localeCompare(b.title)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, title: "asc" });
      }
    } else {
      if (sort.title === "asc") {
        let sortedList = [
          ...anounceHoliday.sort((a, b) => b.title.localeCompare(a.title)),
        ];
        setAnounceHoliday(sortedList);
        setSort({ ...sort, title: "des" });
      } else {
        let sortedList = [
          ...anounceHoliday.sort((a, b) => a.title.localeCompare(b.title)),
        ];
        setAnounceHoliday(sortedList);
        setSort({ ...sort, title: "asc" });
      }
    }
  };

  const sortByWebView = () => {
    console.log("WebView");
    if (search) {
      if (sort.webView === "asc") {
        let sortedList = [
          ...searchFilter.sort((a, b) => b.webView.localeCompare(a.webView)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, webView: "des" });
      } else {
        let sortedList = [
          ...searchFilter.sort((a, b) => a.webView.localeCompare(b.webView)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, webView: "asc" });
      }
    } else {
      if (sort.webView === "asc") {
        let sortedList = [
          ...anounceHoliday.sort((a, b) => b.webView.localeCompare(a.webView)),
        ];
        setAnounceHoliday(sortedList);
        setSort({ ...sort, webView: "des" });
      } else {
        let sortedList = [
          ...anounceHoliday.sort((a, b) => a.webView.localeCompare(b.webView)),
        ];
        setAnounceHoliday(sortedList);
        setSort({ ...sort, webView: "asc" });
      }
    }
  };

  const compareDatesAsc = (a, b) => {
    let dateA = new Date(a.fromDate);
    let dateB = new Date(b.fromDate);

    return dateA - dateB;
  };

  const compareDatesDec = (a, b) => {
    let dateA = new Date(a.fromDate);
    let dateB = new Date(b.fromDate);

    return dateB - dateA;
  };

  const compareDatesAscTo = (a, b) => {
    let dateA = new Date(a.toDate);
    let dateB = new Date(b.toDate);

    return dateA - dateB;
  };

  const compareDatesDecTo = (a, b) => {
    let dateA = new Date(a.toDate);
    let dateB = new Date(b.toDate);

    return dateB - dateA;
  };

  const sortByFromDate = () => {
    console.log("Date");
    if (search) {
      if (sort.fromDate === "asc") {
        let sortedList = [...searchFilter.sort(compareDatesDec)];
        setSearchFilter(sortedList);
        setSort({ ...sort, fromDate: "des" });
      } else {
        let sortedList = [...searchFilter.sort(compareDatesAsc)];
        setSearchFilter(sortedList);
        setSort({ ...sort, fromDate: "asc" });
      }
    } else {
      if (sort.fromDate === "asc") {
        let sortedList = [...anounceHoliday.sort(compareDatesDec)];
        setAnounceHoliday(sortedList);
        setSort({ ...sort, fromDate: "des" });
      } else {
        let sortedList = [...anounceHoliday.sort(compareDatesAsc)];
        setAnounceHoliday(sortedList);
        setSort({ ...sort, fromDate: "asc" });
      }
    }
  };

  const sortByToDate = () => {
    console.log("ToDate");
    if (search) {
      if (sort.toDate === "asc") {
        let sortedList = [...searchFilter.sort(compareDatesDecTo)];
        setSearchFilter(sortedList);
        setSort({ ...sort, toDate: "des" });
      } else {
        let sortedList = [...searchFilter.sort(compareDatesAscTo)];
        setSearchFilter(sortedList);
        setSort({ ...sort, toDate: "asc" });
      }
    } else {
      if (sort.toDate === "asc") {
        let sortedList = [...anounceHoliday.sort(compareDatesDecTo)];
        setAnounceHoliday(sortedList);
        setSort({ ...sort, toDate: "des" });
      } else {
        let sortedList = [...anounceHoliday.sort(compareDatesAscTo)];
        setAnounceHoliday(sortedList);
        setSort({ ...sort, toDate: "asc" });
      }
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setUpdateHoliday(undefined);
  };

  const handleDelete = async (id) => {
    try {
      const response = await del(urls.anounceHoliday.delHoliday, id);
      if (response.data.success) {
        setAnounceHoliday(
          anounceHoliday.filter((item) => item._id !== response.data.data._id)
        );
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = async (e, data) => {
    e.preventDefault();
    setLoader(true);
    try {
      const response = await post(urls.anounceHoliday.postHoliday, data);
      setAnounceHoliday([...anounceHoliday, response.data.data]);
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const handleUpdate = async (data, id) => {
    setLoader(true);
    try {
      const response = await put(urls.anounceHoliday.putHoliday, id, data);
      setAnounceHoliday(
        anounceHoliday.map((e) => {
          if (e._id === response.data.data._id) {
            return response.data.data;
          } else {
            return e;
          }
        })
      );
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };
  const handleEdit = async (id) => {
    try {
      setOpen(true);
      const getHolidayById = anounceHoliday.find((item) => item._id === id);
      setUpdateHoliday(getHolidayById);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getHoliday = async () => {
      try {
        const response = await get(urls.anounceHoliday.getHoliday, {
          params: {
            limit: 10,
            page: 1,
          },
        });
        setAnounceHoliday([...response.data.data]);
        setTotalCount(response.data.count);
      } catch (error) {
        console.log(error);
      }
    };
    getHoliday();
  }, [selectedSetting]);
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    if (value.trim() === "") {
      setSearchFilter([]);
      setShowSearch(false);
    }
  };
  const handleChangePage = async (event, newPage) => {
    try {
      const res = await get(urls.anounceHoliday.getHoliday, {
        params: {
          limit: 10,
          page: newPage + 1,
        },
      });
      setAnounceHoliday([...res.data.data]);
      setPage(newPage);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    try {
      const noticeSearch = await get(`${urls.AnnounceHoliday.getHoliday}`, {
        params: {
          search: {
            $or: [
              {
                title: { $regex: search.trim() },
              },
              {
                fromDate: dayjs(search, "DD-MM-YYYY").isValid()
                  ? dayjs(search, "DD-MM-YYYY").toDate()
                  : "",
              },
              {
                toDate: dayjs(search, "DD-MM-YYYY").isValid()
                  ? dayjs(search, "DD-MM-YYYY").toDate()
                  : "",
              },
              {
                webView: search,
              },
            ],
          },
        },
      });
      setSearchFilter(noticeSearch.data.data);
      setShowSearch(true);
    } catch (error) {
      console.log(error.stack);
    }
  };
  return (
    <Wrapper>
      <SectionSubHeader title="Holidays" />
      <div style={{ margin: "20px 0" }}>
        {/* <Paper sx={{ width: "100%", overflow: "hidden" }}> */}
        {/* <div className={styles.searchInputContainer}> */}
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: { md: "right", xs: "center" },
          }}
        >
          <Grid item xs="auto" mb={2}>
            <form onSubmit={handleSearchSubmit}>
              <TextField
                size="small"
                value={search}
                onChange={handleSearch}
                label="Search by Title"
                variant="outlined"
                placeholder="Search By Title"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" type="submit">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </Grid>
        </Grid>
        {/* </div> */}
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead style={{ background: "rgb(27 55 121)" }}>
              <TableRow>
                <TableCell align="center">
                  <span className="class-table-header">#SL</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Title</span>
                  <span
                    onClick={sortByTitle}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.title === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header"> From Date</span>
                  <span
                    onClick={sortByFromDate}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.fromDate === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">To Date</span>
                  <span
                    onClick={sortByToDate}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.toDate === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>

                <TableCell align="center">
                  <span className="class-table-header">Is View On Web?</span>
                  <span
                    onClick={sortByWebView}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.webView === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Action </span>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {showSearch
                ? searchFilter.map((row, index) => (
                    <TableRow
                      key={row._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.title}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {dayjs(row.fromDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {dayjs(row.toDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.webView}
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        <div className="edit-delete">
                          <Tooltip title="Edit">
                            <Button onClick={() => handleEdit(row._id)}>
                              <Edit />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <Button onClick={() => setDeleteModal(row._id)}>
                              <Delete />
                            </Button>
                          </Tooltip>
                        </div>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDelete}
                          id={row._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                : anounceHoliday.map((row, index) => (
                    <TableRow
                      key={row._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.title}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {dayjs(row.fromDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {dayjs(row.toDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.webView}
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        <div className="edit-delete">
                          <Tooltip title="Edit">
                            <Button onClick={() => handleEdit(row._id)}>
                              <Edit />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <Button onClick={() => setDeleteModal(row._id)}>
                              <Delete />
                            </Button>
                          </Tooltip>
                        </div>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDelete}
                          id={row._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!anounceHoliday.length && !showSearch && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        {showSearch && !searchFilter.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        {/* </Paper> */}
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={totalCount}
          page={page}
          style={{ position: "sticky", overflow: "visible" }}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
        />

        <div className="add-icon">
          <AddHoliday
            loader={loader}
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            updateHoliday={updateHoliday}
            handleUpdate={handleUpdate}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default AnnounceHoliday;
