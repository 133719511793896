// import {
//   Box,
//   Button,
//   Card,
//   Fab,
//   FormControl,
//   Grid,
//   InputAdornment,
//   InputLabel,
//   MenuItem,
//   Modal,
//   Paper,
//   Select,
//   TextField,
//   TextareaAutosize,
//   Tooltip,
//   Typography,
// } from "@mui/material";
// import React, { useContext, useEffect, useState } from "react";
// import AddIcon from "@mui/icons-material/Add";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import EditIcon from "@mui/icons-material/Edit";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import { Delete, Menu, Search } from "@mui/icons-material";
// import Dialog from "@mui/material/Dialog";
// import DialogTitle from "@mui/material/DialogTitle";
// import DialogContent from "@mui/material/DialogContent";
// import IconButton from "@mui/material/IconButton";
// import CloseIcon from "@mui/icons-material/Close";
// import PropTypes from "prop-types";
// import { styled } from "@mui/material/styles";
// import { get, post, put, del } from "../../services/apis";
// import { urls as url } from "../../services/urlConstant";
// import DeleteModal from "../Layout/Academic/DeleteModal";
// import { LoadingButton } from "@mui/lab";
// import appendStringToUrls from "../../utils/appendString";
// import SettingContext from "../../context/SettingsContext";
// import SectionSubHeader from "../SectionSubHeader";
// import Wrapper from "../Wrapper";
// import { toast } from "react-toastify";

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//   "& .MuiDialogContent-root": {
//     padding: theme.spacing(2),
//   },
//   "& .MuiDialogActions-root": {
//     padding: theme.spacing(1),
//   },
// }));
// function BootstrapDialogTitle(props) {
//   const { children, onClose, ...other } = props;
//   return (
//     <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
//       {children}
//       {onClose ? (
//         <IconButton
//           aria-label="close"
//           onClick={onClose}
//           sx={{
//             position: "absolute",
//             right: 8,
//             top: 8,
//             color: (theme) => theme.palette.grey[500],
//           }}
//         >
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </DialogTitle>
//   );
// }
// BootstrapDialogTitle.propTypes = {
//   children: PropTypes.node,
//   onClose: PropTypes.func.isRequired,
// };

// const VisitoryInfoForm = styled(Box)(({ theme }) => ({
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   // width: 800,
//   width: "58%",
//   height: "auto",
//   backgroundColor: "white",
//   padding: "20px",
//   [theme.breakpoints.down(920)]: {
//     width: "70%",
//   },
//   [theme.breakpoints.down("md")]: {
//     height: "100%",
//     overflow: "auto",
//     width: "60%",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "100%",
//     height: "100%",
//   },
// }));

// const VisitoryInfo = () => {
//   const { selectedSetting } = useContext(SettingContext);
//   const [search, setSearch] = useState("");
//   const [schoolEmployee, setSchoolEmployee] = useState([]);
//   const [searchFilter, setSearchFilter] = useState([]);
//   const [open, setOpen] = React.useState(false);
//   const [dialog, setOpenDialog] = React.useState(false);
//   const [editVisitorInfo, setEditVisitorInfo] = useState();
//   const [addForm, setAddForm] = useState({});
//   const [list, setList] = React.useState([]);
//   const [schoolUserRole, setSchoolUserRole] = useState([]);
//   const [deleteModal, setDeleteModal] = React.useState(false);
//   const [showDetails, setShowDetails] = useState(null);
//   const [checkIn, setCheckIn] = useState("");
//   const [checkOut, setChcekOut] = useState("");

//   const [allEmployees, setAllEmployees] = useState([]);
//   const [employees, setEmployees] = useState([]);
//   const [selectEmployee, setSelectEmployee] = useState("");
//   const [departments, setDepartment] = useState([]);
//   const [roles, setRoles] = useState([]);
//   const [employeeAttendances, setEmployeeAttendances] = useState([]);
//   const [loadingBtn, setLoadingBtn] = useState(false);

//   const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

//   useEffect(() => {
//     const getRoles = async () => {
//       try {
//         const rolesRes = await get(urls.role.getAllRole);
//         setRoles(rolesRes.data.data);
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     getRoles();
//   }, [selectedSetting]);
//   useEffect(() => {
//     setEmployees([]);
//     setSelectEmployee("");
//     setAddForm({});
//   }, [selectedSetting]);
//   useEffect(() => {
//     console.log("console");

//     const schoolEmployeeGet = async () => {
//       try {
//         const response = await get(urls.employee.getAllEmployee, {
//           params: {
//             limit: 10,
//             page: 1,
//           },
//         });
//         setSchoolEmployee(response.data.data);

//       } catch (error) {
//         console.log(error);
//       }
//     };

//     const schoolEmployeeGetAll = async () => {
//       try {
//         const response = await get(urls.employee.getAllEmployee);
//         setAllEmployees(response.data.data);
//         // console.log(response.data.data, "alllllll");
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     const getAllDepartment = async () => {
//       try {
//         const departments = await get(urls.department.getDepartment);
//         setDepartment(departments.data.data);
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     getAllDepartment();
//     schoolEmployeeGet();
//     schoolEmployeeGetAll();
//   }, [selectedSetting._id]);
//   const handleRoleChange = async (e) => {
//     try {
//       const { name, value } = e.target;
//       setAddForm((prev) => ({
//         ...prev,
//         [name]: value,
//       }));
//       const getEmployees = await get(urls.employee.getAllEmployee, {
//         params: {
//           search: {
//             role: value,
//           },
//         },
//       });
//       if (getEmployees.data.success) setEmployees(getEmployees.data.data);
//     } catch (error) {
//       toast.error(error.message);
//     }
//   };
//   const handleEmployeeChange = (e, val) => {

//     setSelectEmployee(e.target.value);
//   };
//   useEffect(() => {
//     getVisitorInfo();
//   }, [selectedSetting._id]);

//   const getVisitorInfo = async () => {
//     try {
//       const { data, status } = await get(`${urls.visitorInfo.getList}`);

//       setList(data.data);
//       // console.log(data.data, "vvvvvvsss");
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleAddSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       if (!editVisitorInfo) {
//         const { status } = await post(`${urls.visitorInfo.create}`, {
//           name: addForm.name,
//           phone: addForm.phone,
//           comingForm: addForm.comingForm,
//           toMeetUserType: addForm.toMeetUserType,
//           toMeetUser: selectEmployee,
//           reasonToMeet: addForm.reasonToMeet,
//           note: addForm.note,
//         });
//         getVisitorInfo();
//         handleClose();
//       } else {
//         const { status } = await put(
//           `${urls.visitorInfo.update}${editVisitorInfo}`,
//           "",
//           {
//             name: addForm.name,
//             phone: addForm.phone,
//             comingForm: addForm.comingForm,
//             toMeetUserType: addForm.toMeetUserType,
//             toMeetUser: selectEmployee,
//             reasonToMeet: addForm.reasonToMeet,
//             note: addForm.note,
//             checkIn: addForm.checkIn,
//             checkOut: addForm.checkOut,
//           }
//         );
//         getVisitorInfo();
//         handleClose();
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleCheckIn = (e) => {
//     setCheckIn(e.target.value);
//   };
//   const handleCheckOut = (e) => {
//     setChcekOut(e.target.value);
//   };

//   const handleDeleteone = async (id) => {
//     try {
//       const res = await del(`${urls.visitorInfo.delete}`, id);
//       if (res.data.success) {
//         const filteredEvent = list.filter((item) => item._id !== id);
//         setList(filteredEvent);
//       } else {
//         console.log("Couldn't complete your request, Try again later");
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleVisitorInfoEdit = (id, data) => {
//     setEditVisitorInfo(id);
//     console.log(data, "ediiiiit");
//     setAddForm({
//       name: data.name,
//       phone: data.phone,
//       comingForm: data.comingForm,
//       roleName: data.roleName,
//       toMeetUser: data.toMeetUser._id,
//       toMeetUserType: data.toMeetUserType._id,
//       reasonToMeet: data.reasonToMeet,
//       checkIn: data.checkIn,
//       checkOut: data.checkOut,
//       note: data.note,
//     });
//     handleClickOpen();
//   };

//   const handleAddForm = (e) => {
//     const { name, value } = e.target;
//     setAddForm((prev) => ({
//       ...prev,
//       [name]: value,
//     }));
//   };

//   const handleClickOpen = () => {
//     setOpen(true);
//   };
//   const handleClose = () => {
//     setOpen(false);
//     setAddForm({
//       nam: "",
//       phone: 0,
//       comingForm: "",
//       toMeetUserType: "",
//       toMeetUser: "",
//       reasonToMeet: "",
//       note: "",
//       checkIn: "",
//       checkOut: "",
//     });
//     setEditVisitorInfo(null);
//   };
//   const handleClickOpenDialog = (visitor) => {
//     setShowDetails(visitor);
//     setOpenDialog(true);
//   };
//   const handleCloseDialog = () => {
//     setShowDetails(null);
//     setOpenDialog(false);
//   };
//   const styles = {
//     button: {
//       backgroundColor: "orange",
//     },
//     card: {
//       padding: 10,
//       margin: "10px 0px",
//     },
//     cardButton: {
//       display: "flex",
//       justifyContent: "flex-end",
//       textAlign: "center",
//       gap: "10px",
//     },

//     textfield: {
//       marginTop: "25px",
//       width: "70%",
//     },
//     addbutton: {
//       paddingTop: "6.2px",
//       paddingBottom: "6.2px",
//     },
//     tab: {
//       width: "100%",
//       borderCollapse: "collapse",
//     },
//   };
//   const handleSearch = (e) => {
//     const { value } = e.target;
//     setSearch(value.trim());
//     if (value.trim() !== "") {
//       list.length > 0 &&
//         setSearchFilter(
//           list.filter((ele) =>
//             ele.name.toLowerCase().includes(value.toLowerCase().trim())
//           )
//         );
//     } else {
//       setSearchFilter([]);
//     }
//   };

//   const handleGetDownloadSheet = async (e) => {
//     e.preventDefault();
//     setLoadingBtn(true);
//     try {
//       const getDownload = await get(
//         `${selectedSetting._id}/visitorinfo/downloadexcel/`,
//         {
//           responseType: "blob",
//         }
//       );
//       const uri = URL.createObjectURL(getDownload.data);
//       const link = document.createElement("a");
//       console.log(uri);
//       link.href = uri;
//       document.body.appendChild(link);
//       link.click();
//       link.parentNode.removeChild(link);
//       setLoadingBtn(false);
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   const handleGetPrintPdf = async (e) => {
//     setLoadingBtn(true);
//     e.preventDefault();
//     try {
//       const getPrint = await get(
//         `/${selectedSetting._id}/visitorinfo/downloadpdf`,
//         {
//           responseType: "blob",
//         }
//       );
//       const uri = URL.createObjectURL(getPrint.data);
//       window.open(uri, "__blank");
//     } catch (error) {
//       console.log(error);
//     }
//     setLoadingBtn(false);
//   };

//   return (
//     <Wrapper>
//       <SectionSubHeader title="Visitor Info" />
//       <div style={{ margin: "15px 0" }}>
//         <Card style={{ padding: "10px", margin: "10px 0px" }}>
//           <div style={styles.cardButton}>
//             <LoadingButton
//               onClick={handleGetDownloadSheet}
//               variant="contained"
//               loading={loadingBtn}
//             >
//               <span style={styles.icon}>Excel </span>
//             </LoadingButton>
//             <Button variant="contained"  loading={loadingBtn} onClick={handleGetPrintPdf} >Pdf</Button>

//             {/* <LoadingButton
//                 // onClick={() => handleGetVisitorInfo()}
//                 variant="contained"
//                 size="small"
//                 // loading={btnLoading === exam._id}
//                 sx={{
//                   backgroundColor: "#1b3779",
//                   ":hover": { backgroundColor: "#1b3779" },
//                 }}>
//                 Pdf
//               </LoadingButton> */}
//           </div>
//         </Card>

//         <Box
//           sx={{ padding: "15px", display: "flex", justifyContent: "flex-end" }}
//         >
//           <TextField
//             size="small"
//             value={search}
//             onChange={handleSearch}
//             label="Search"
//             variant="outlined"
//             InputProps={{
//               endAdornment: (
//                 <InputAdornment position="end">
//                   <IconButton edge="end" type="submit">
//                     <Search />
//                   </IconButton>
//                 </InputAdornment>
//               ),
//             }}
//           />
//         </Box>

//         <Modal
//           open={open}
//           onClose={handleClose}
//           aria-labelledby="modal-modal-title"
//           aria-describedby="modal-modal-description"
//         >
//           <form className="form-tag" onSubmit={handleAddSubmit}>
//             <VisitoryInfoForm
//             // sx={style}
//             >
//               <Typography
//                 id="modal-modal-title"
//                 variant="h6"
//                 component="h2"
//                 textAlign="center"
//                 marginBottom="30px"
//               >
//                 {editVisitorInfo ? "Update VisitorInfo" : "Add VisitorInfo"}
//               </Typography>
//               <div>
//                 <TextField
//                   required
//                   id="filled-required"
//                   variant="standard"
//                   label="Name"
//                   name="name"
//                   value={addForm.name || ""}
//                   onChange={handleAddForm}
//                   inputProps={{ readOnly: Boolean(editVisitorInfo) }}
//                   disabled={editVisitorInfo}
//                   sx={{
//                     m: 1,
//                     width: { md: 227, xs: "95%" },
//                     label: { fontSize: 12 },
//                   }}
//                 />
//                 <FormControl
//                   variant="standard"
//                   sx={{ m: 1, width: { md: 227, xs: "100%" } }}
//                   required
//                 >
//                   <TextField
//                     required
//                     id="filled-required"
//                     variant="standard"
//                     label="Phone "
//                     name="phone"
//                     type="number"
//                     value={addForm.phone}
//                     onChange={handleAddForm}
//                     inputProps={{ readOnly: Boolean(editVisitorInfo) }}
//                     disabled={editVisitorInfo}
//                     sx={{
//                       width: { md: 227, xs: "95%" },
//                       label: { fontSize: 12 },
//                     }}
//                   />
//                 </FormControl>
//                 <TextField
//                   required
//                   id="filled-required"
//                   variant="standard"
//                   label="Coming From"
//                   name="comingForm"
//                   value={addForm.comingForm || ""}
//                   onChange={handleAddForm}
//                   inputProps={{ readOnly: Boolean(editVisitorInfo) }}
//                   disabled={editVisitorInfo}
//                   sx={{
//                     m: 1,
//                     width: { md: 227, xs: "95%" },
//                     label: { fontSize: 12 },
//                   }}
//                 />

//                 <div>
//                   <FormControl
//                     variant="standard"
//                     sx={{ m: 1, width: { md: 227, xs: "95%" } }}
//                   >
//                     <InputLabel sx={{ fontSize: 8 }}>
//                       To Meet User Type
//                     </InputLabel>
//                     <Select
//                       labelId="demo-simpless-select-filled-label"
//                       id="demo-simple-select-filled-l"
//                       name="toMeetUserType"
//                       value={addForm.toMeetUserType || ""}
//                       onChange={handleRoleChange}
//                       inputProps={{ readOnly: Boolean(editVisitorInfo) }}
//                       disabled={editVisitorInfo}

//                     >
//                       {roles.map((role) => (
//                         <MenuItem key={role._id} value={role._id}>
//                           {role.roleName}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                   <FormControl
//                     variant="standard"
//                     sx={{ m: 1, width: { md: 227, xs: "95%" } }}
//                   >
//                     <InputLabel sx={{ fontSize: 8 }}>To Meet User</InputLabel>
//                     <Select
//                       labelId="demo-simpless-select-filled-label"
//                       id="demo-simple-select-filled-l"
//                       name="toMeetUser"
//                       value={selectEmployee}
//                    onChange={handleEmployeeChange}
//                       options={employees}
//                       inputProps={{ readOnly: Boolean(editVisitorInfo) }}
//                       disabled={editVisitorInfo}
//                     >
//                       {employees.map((emp) => (
//                         <MenuItem key={emp._id} value={emp._id}>
//                           {emp.basicInfo.empName}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                   <FormControl
//                     variant="standard"
//                     sx={{ m: 1, width: { md: 227, xs: "95%" } }}
//                   >
//                     <InputLabel sx={{ fontSize: 8 }}>Reason to Meet</InputLabel>
//                     <Select
//                       labelId="demo-simpless-select-filled-label"
//                       id="demo-simple-select-filled-l"
//                       name="reasonToMeet"
//                       value={addForm.reasonToMeet || ""}
//                       onChange={handleAddForm}
//                       inputProps={{ readOnly: Boolean(editVisitorInfo) }}
//                       disabled={editVisitorInfo}

//                     >
//                       <MenuItem value={"vendor"}>Vendor</MenuItem>
//                       <MenuItem value={"relative"}>Relative</MenuItem>
//                       <MenuItem value={"friend"}>Friend</MenuItem>
//                       <MenuItem value={"guardian"}>Guardian</MenuItem>
//                       <MenuItem value={"family"}>Family</MenuItem>
//                       <MenuItem value={"interview"}>InterView</MenuItem>
//                       <MenuItem value={"meeting"}>Meeting</MenuItem>
//                       <MenuItem value={"other"}>Other</MenuItem>
//                     </Select>
//                   </FormControl>
//                 </div>
//                 {editVisitorInfo ? (
//                   <div>
//                     <TextField
//                       required
//                       id="filled-required"
//                       variant="standard"
//                       label="Check In"
//                       // type="time"
//                       name="checkIn"
//                       value={addForm.checkIn || ""}
//                       onChange={handleAddForm}
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                       inputProps={{ readOnly: Boolean(editVisitorInfo) }}
//                       disabled={editVisitorInfo}
//                       sx={{
//                         m: 1,
//                         width: { md: 227, xs: "95%" },
//                         label: { fontSize: 12 },
//                       }}
//                     />

//                     <TextField
//                       required
//                       id="filled-required"
//                       variant="standard"
//                       label="Check Out"
//                       name="checkOut"
//                       type="datetime-local"
//                       value={addForm.checkOut || ""}
//                       onChange={handleAddForm}
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                       sx={{
//                         m: 1,
//                         width: { md: 227, xs: "95%" },
//                         label: { fontSize: 12 },
//                       }}
//                     />
//                   </div>
//                 ) : null}

//                 <div>
//                   <TextareaAutosize
//                     placeholder="Drop a note"
//                     name="note"
//                     value={addForm.note || ""}
//                     onChange={handleAddForm}
//                     inputProps={{ readOnly: Boolean(editVisitorInfo) }}
//                     style={{
//                       // width: 712,
//                       width: "95%",
//                       maxHeight: 110,
//                       margin: 10,
//                       padding: 10,
//                       height: 118,
//                       overflow: "auto",
//                     }}
//                   />
//                 </div>
//               </div>

//               <div
//                 style={{
//                   display: "flex",
//                   justifyContent: "flex-end",
//                   alignItems: "center",
//                 }}
//               >
//                 <Tooltip title="Cancel">
//                   <Button
//                     onClick={handleClose}
//                     variant="outlined"
//                     size="medium"
//                     type="button"
//                     sx={{ m: 1 }}
//                   >
//                     Cancel
//                   </Button>
//                 </Tooltip>
//                 <Tooltip title="Submit">
//                   <Button
//                     variant="contained"
//                     size="medium"
//                     type="submit"
//                     sx={{
//                       background: "rgb(27, 55, 121)",
//                       ":hover": { background: "rgb(27, 55, 121)" },
//                     }}
//                   >
//                     {editVisitorInfo ? "Update" : "Add VisitorInfo"}
//                   </Button>
//                 </Tooltip>
//               </div>
//             </VisitoryInfoForm>
//           </form>
//         </Modal>
//         <Box overflow="scroll">
//           <Table>
//             <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
//               <TableRow sx={{ color: "white" }}>
//                 <TableCell sx={{ color: "white" }} align="center">
//                   #SL
//                 </TableCell>
//                 <TableCell sx={{ color: "white" }} align="center">
//                   Name
//                 </TableCell>
//                 <TableCell sx={{ color: "white" }} align="center">
//                   Phone
//                 </TableCell>
//                 <TableCell sx={{ color: "white" }} align="center">
//                   To Meet
//                 </TableCell>
//                 <TableCell sx={{ color: "white" }} align="center">
//                   Reason To Meet
//                 </TableCell>
//                 <TableCell sx={{ color: "white" }} align="center">
//                   Check In
//                 </TableCell>
//                 <TableCell sx={{ color: "white" }} align="center">
//                   Check Out
//                 </TableCell>
//                 <TableCell sx={{ color: "white" }} align="center">
//                   Action
//                 </TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {search
//                 ? searchFilter.map((visitor, index) => (
//                     <TableRow key={index}>
//                       <TableCell align="center">{index + 1}</TableCell>
//                       <TableCell align="center">{visitor.name}</TableCell>
//                       <TableCell align="center">{visitor.phone}</TableCell>
//                       <TableCell align="center">
//                         {visitor.toMeetUser.basicInfo.empName ||
//                           visitor.toMeetUser.basicInfo.name}
//                       </TableCell>
//                       <TableCell align="center">
//                         {visitor.reasonToMeet}
//                       </TableCell>
//                       <TableCell align="center">
//                         {/* {new Date(visitor.checkIn).toLocaleTimeString()} */}
//                         {new Date(visitor.checkIn).toLocaleString()}
//                       </TableCell>
//                       <TableCell align="center">
//                         {visitor.checkOut
//                           ? new Date(visitor.checkOut).toDateString()
//                           : "not checked out"}
//                       </TableCell>
//                       <TableCell align="center">
//                         <div className="edit-delete">
//                           <Button
//                             style={{ color: "#1b3779" }}
//                             onClick={() =>
//                               handleVisitorInfoEdit(visitor._id, visitor)
//                             }
//                           >
//                             <EditIcon />
//                           </Button>
//                           <Button
//                             style={{ color: "#1b3779" }}
//                             onClick={() => handleClickOpenDialog(visitor)}
//                           >
//                             <VisibilityIcon />
//                           </Button>
//                           <Button
//                             style={{
//                               color: "#1b3779",
//                             }}
//                             onClick={() => setDeleteModal(visitor._id, visitor)}
//                           >
//                             <Delete />
//                           </Button>
//                           <DeleteModal
//                             deleteModal={deleteModal}
//                             handleDelete={handleDeleteone}
//                             id={visitor._id}
//                             setDeleteModal={setDeleteModal}
//                           />
//                         </div>
//                       </TableCell>
//                     </TableRow>
//                   ))
//                 : list.map((visitor, index) => (
//                     <TableRow key={index}>
//                       <TableCell align="center">{index + 1}</TableCell>
//                       <TableCell align="center">{visitor.name}</TableCell>
//                       <TableCell align="center">{visitor.phone}</TableCell>
//                       <TableCell align="center">
//                         {visitor.toMeetUser.basicInfo.empName ||
//                           visitor.toMeetUser.basicInfo.name}
//                       </TableCell>
//                       <TableCell align="center">
//                         {visitor.reasonToMeet}
//                       </TableCell>
//                       <TableCell align="center">
//                         {/* {new Date(visitor.checkIn).toLocaleTimeString()} */}
//                         {new Date(visitor.checkIn).toLocaleString()}
//                       </TableCell>
//                       <TableCell align="center">
//                         {visitor.checkOut
//                           ? new Date(visitor.checkOut).toLocaleString()
//                           : "Not checked out"}
//                       </TableCell>
//                       <TableCell align="center">
//                         <div className="edit-delete">
//                           <Button
//                             style={{ color: "#1b3779" }}
//                             onClick={() =>
//                               handleVisitorInfoEdit(visitor._id, visitor)
//                             }
//                           >
//                             <EditIcon />
//                           </Button>
//                           <Button
//                             style={{ color: "#1b3779" }}
//                             onClick={() => handleClickOpenDialog(visitor)}
//                           >
//                             <VisibilityIcon />
//                           </Button>
//                           {/* <Button
//                             style={{
//                               color: "#1b3779",
//                             }}
//                             onClick={() => setDeleteModal(visitor._id, visitor)}
//                           >
//                             <Delete />
//                           </Button> */}
//                           <DeleteModal
//                             deleteModal={deleteModal}
//                             handleDelete={handleDeleteone}
//                             id={visitor._id}
//                             setDeleteModal={setDeleteModal}
//                           />
//                         </div>
//                       </TableCell>
//                     </TableRow>
//                   ))}
//             </TableBody>
//           </Table>
//         </Box>
//         {!list.length && !search.trim() && (
//           <Typography
//             variant="h6"
//             sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
//           >
//             No data found
//           </Typography>
//         )}
//         {search.trim() && !searchFilter.length && (
//           <Typography
//             variant="h6"
//             sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
//           >
//             No data found
//           </Typography>
//         )}
//         <div className="add-icon">
//           <Fab
//             variant="contained"
//             style={{ color: "#ffff", backgroundColor: "#1b3779" }}
//             onClick={handleClickOpen}
//           >
//             <AddIcon />
//           </Fab>
//         </div>
//         <BootstrapDialog
//           onClose={handleCloseDialog}
//           aria-labelledby="customized-dialog-title"
//           open={dialog}
//         >
//           <BootstrapDialogTitle
//             id="customized-dialog-title"
//             onClose={handleCloseDialog}
//             style={{ backgroundColor: "#1b3779", color: "white" }}
//           >
//             Visitor Information
//           </BootstrapDialogTitle>

//           <DialogContent dividers>
//             <table className="hostel" style={styles.tab}>
//               <tbody>
//                 {showDetails ? (
//                   <>
//                     <tr>
//                       <td>School Name</td>
//                       {/* <td>Excellent English Medium Primary & High School</td> */}
//                       <td>{selectedSetting.schoolName}</td>
//                     </tr>
//                     <tr>
//                       <td> Name</td>
//                       <td>{showDetails.name}</td>
//                     </tr>
//                     <tr>
//                       <td>Phone</td>
//                       <td>{showDetails.phone}</td>
//                     </tr>
//                     <tr>
//                       <td>Coming From</td>
//                       <td>{showDetails.comingForm}</td>
//                     </tr>
//                     <tr>
//                       <td>User Type</td>
//                       <td>{showDetails.toMeetUserType.roleName}</td>
//                     </tr>
//                     <tr>
//                       <td>To Meet</td>
//                       <td>
//                         {showDetails.toMeetUser.basicInfo.name ||
//                           showDetails.toMeetUser.basicInfo.empName}
//                       </td>
//                     </tr>
//                     <tr>
//                       <td>Reason To Meet</td>
//                       <td>{showDetails.reasonToMeet}</td>
//                     </tr>
//                     <tr>
//                       <td>Check In</td>
//                       <td>{new Date(showDetails.checkIn).toLocaleString()}</td>
//                     </tr>
//                     <tr>
//                       <td>Check Out</td>
//                       <td>
//                         {showDetails.checkOut
//                           ? new Date(showDetails.checkOut).toLocaleString()
//                           : "not checked out"}
//                       </td>
//                     </tr>

//                     <tr>
//                       <td>Note</td>
//                       <td>{showDetails.note}</td>
//                     </tr>
//                   </>
//                 ) : null}
//               </tbody>
//             </table>
//           </DialogContent>
//         </BootstrapDialog>
//       </div>
//     </Wrapper>
//   );
// };

// export default VisitoryInfo;

import {
  Box,
  Button,
  Card,
  Fab,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Delete, Menu, Search } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { get, post, put, del } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import DeleteModal from "../Layout/Academic/DeleteModal";
import { LoadingButton } from "@mui/lab";
import appendStringToUrls from "../../utils/appendString";
import SettingContext from "../../context/SettingsContext";
import SectionSubHeader from "../SectionSubHeader";
import Wrapper from "../Wrapper";
import { toast } from "react-toastify";
import { useTheme } from "@mui/material/styles";
const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
};
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const VisitoryInfoForm = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 800,
  width: "58%",
  height: "auto",
  backgroundColor: "white",
  padding: "20px",
  [theme.breakpoints.down(920)]: {
    width: "70%",
  },
  [theme.breakpoints.down("md")]: {
    height: "100%",
    overflow: "auto",
    width: "60%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
  },
}));

const VisitoryInfo = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { selectedSetting } = useContext(SettingContext);
  const [search, setSearch] = useState("");
  const [schoolEmployee, setSchoolEmployee] = useState([]);
  const [searchFilter, setSearchFilter] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [dialog, setOpenDialog] = React.useState(false);
  const [editVisitorInfo, setEditVisitorInfo] = useState();
  const [addForm, setAddForm] = useState({});
  const [list, setList] = React.useState([]);
  const [schoolUserRole, setSchoolUserRole] = useState([]);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [showDetails, setShowDetails] = useState(null);
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setChcekOut] = useState("");

  const [allEmployees, setAllEmployees] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectEmployee, setSelectEmployee] = useState("");
  const [departments, setDepartment] = useState([]);
  const [roles, setRoles] = useState([]);
  const [employeeAttendances, setEmployeeAttendances] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  useEffect(() => {
    const getRoles = async () => {
      try {
        const rolesRes = await get(urls.role.getAllRole);
        setRoles(rolesRes.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getRoles();
  }, [selectedSetting]);
  useEffect(() => {
    setEmployees([]);
    setSelectEmployee("");
    setAddForm({});
  }, [selectedSetting]);
  useEffect(() => {
    console.log("console");

    const schoolEmployeeGet = async () => {
      try {
        const response = await get(urls.employee.getAllEmployee, {
          params: {
            limit: 10,
            page: 1,
          },
        });
        setSchoolEmployee(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    const schoolEmployeeGetAll = async () => {
      try {
        const response = await get(urls.employee.getAllEmployee);
        setAllEmployees(response.data.data);
        // console.log(response.data.data, "alllllll");
      } catch (error) {
        console.log(error);
      }
    };
    const getAllDepartment = async () => {
      try {
        const departments = await get(urls.department.getDepartment);
        setDepartment(departments.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAllDepartment();
    schoolEmployeeGet();
    schoolEmployeeGetAll();
  }, [selectedSetting._id]);
  const handleRoleChange = async (e) => {
    try {
      const { name, value } = e.target;
      setAddForm((prev) => ({
        ...prev,
        [name]: value,
      }));
      const getEmployees = await get(urls.employee.getAllEmployee, {
        params: {
          search: {
            role: value,
          },
        },
      });
      if (getEmployees.data.success) setEmployees(getEmployees.data.data);
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleEmployeeChange = (e, val) => {
    setSelectEmployee(e.target.value);
  };
  useEffect(() => {
    getVisitorInfo();
  }, [selectedSetting._id]);

  const getVisitorInfo = async () => {
    try {
      const { data, status } = await get(`${urls.visitorInfo.getList}`);

      setList(data.data);
      // console.log(data.data, "vvvvvvsss");
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!editVisitorInfo) {
        const { status } = await post(`${urls.visitorInfo.create}`, {
          name: addForm.name,
          phone: addForm.phone,
          comingForm: addForm.comingForm,
          toMeetUserType: addForm.toMeetUserType,
          toMeetUser: selectEmployee,
          reasonToMeet: addForm.reasonToMeet,
          note: addForm.note,
        });
        getVisitorInfo();
        handleClose();
      } else {
        const { status } = await put(
          `${urls.visitorInfo.update}${editVisitorInfo}`,
          "",
          {
            name: addForm.name,
            phone: addForm.phone,
            comingForm: addForm.comingForm,
            toMeetUserType: addForm.toMeetUserType,
            toMeetUser: selectEmployee,
            reasonToMeet: addForm.reasonToMeet,
            note: addForm.note,
            checkIn: addForm.checkIn,
            checkOut: addForm.checkOut,
          }
        );
        getVisitorInfo();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckIn = (e) => {
    setCheckIn(e.target.value);
  };
  const handleCheckOut = (e) => {
    setChcekOut(e.target.value);
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(`${urls.visitorInfo.delete}`, id);
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleVisitorInfoEdit = (id, data) => {
    setEditVisitorInfo(id);
    console.log(data, "ediiiiit");
    setAddForm({
      name: data.name,
      phone: data.phone,
      comingForm: data.comingForm,
      roleName: data.roleName,
      toMeetUser: data.toMeetUser._id,
      toMeetUserType: data.toMeetUserType._id,
      reasonToMeet: data.reasonToMeet,
      checkIn: data.checkIn,
      checkOut: data.checkOut,
      note: data.note,
    });
    handleClickOpen();
  };

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setAddForm({
      nam: "",
      phone: 0,
      comingForm: "",
      toMeetUserType: "",
      toMeetUser: "",
      reasonToMeet: "",
      note: "",
      checkIn: "",
      checkOut: "",
    });
    setEditVisitorInfo(null);
  };
  const handleClickOpenDialog = (visitor) => {
    setShowDetails(visitor);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setShowDetails(null);
    setOpenDialog(false);
  };
  const styles = {
    button: {
      backgroundColor: "orange",
    },
    card: {
      padding: 10,
      margin: "10px 0px",
    },
    cardButton: {
      display: "flex",
      justifyContent: "flex-end",
      textAlign: "center",
      gap: "10px",
    },

    textfield: {
      marginTop: "25px",
      width: "70%",
    },
    addbutton: {
      paddingTop: "6.2px",
      paddingBottom: "6.2px",
    },
    tab: {
      width: "100%",
      borderCollapse: "collapse",
    },
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      list.length > 0 &&
        setSearchFilter(
          list.filter((ele) =>
            ele.name.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  const handleGetDownloadSheet = async (e) => {
    e.preventDefault();
    setLoadingBtn(true);
    try {
      const getDownload = await get(
        `${selectedSetting._id}/visitorinfo/downloadexcel/`,
        {
          responseType: "blob",
        }
      );
      const uri = URL.createObjectURL(getDownload.data);
      const link = document.createElement("a");
      console.log(uri);
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setLoadingBtn(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetPrintPdf = async (e) => {
    setLoadingBtn(true);
    e.preventDefault();
    try {
      const getPrint = await get(
        `/${selectedSetting._id}/visitorinfo/downloadpdf`,
        {
          responseType: "blob",
        }
      );
      const uri = URL.createObjectURL(getPrint.data);
      window.open(uri, "__blank");
    } catch (error) {
      console.log(error);
    }
    setLoadingBtn(false);
  };

  return (
    <Wrapper>
      <SectionSubHeader title="Visitor Info" />
      <div style={{ margin: "15px 0" }}>
        <Card style={{ padding: "10px", margin: "10px 0px" }}>
          <div style={styles.cardButton}>
            <LoadingButton
              onClick={handleGetDownloadSheet}
              variant="contained"
              loading={loadingBtn}
            >
              <span style={styles.icon}>Excel </span>
            </LoadingButton>
            <Button
              variant="contained"
              loading={loadingBtn}
              onClick={handleGetPrintPdf}
            >
              Pdf
            </Button>

            {/* <LoadingButton
                // onClick={() => handleGetVisitorInfo()}
                variant="contained"
                size="small"
                // loading={btnLoading === exam._id}
                sx={{
                  backgroundColor: "#1b3779",
                  ":hover": { backgroundColor: "#1b3779" },
                }}>
                Pdf
              </LoadingButton> */}
          </div>
        </Card>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "20px 0px",
          }}
        >
          <TextField
            size="small"
            value={search}
            onChange={handleSearch}
            label="Search"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" type="submit">
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form onSubmit={handleAddSubmit}>
            {/* <VisitoryInfoForm
            // sx={style}
            > */}
            <Box sx={style}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  {" "}
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    textAlign="center"
                    fontSize="20px"
                    fontWeight="bold"
                  >
                    {editVisitorInfo ? "Update VisitorInfo" : "Add VisitorInfo"}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  {" "}
                  <TextField
                    required
                    id="filled-required"
                    fullWidth
                    size="small"
                    label="Name"
                    name="name"
                    value={addForm.name || ""}
                    onChange={handleAddForm}
                    inputProps={{ readOnly: Boolean(editVisitorInfo) }}
                    disabled={editVisitorInfo}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  {" "}
                  <FormControl fullWidth size="small" required>
                    <TextField
                      required
                      id="filled-required"
                      fullWidth
                      size="small"
                      label="Phone "
                      name="phone"
                      type="number"
                      value={addForm.phone}
                      onChange={handleAddForm}
                      inputProps={{ readOnly: Boolean(editVisitorInfo) }}
                      disabled={editVisitorInfo}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  {" "}
                  <TextField
                    required
                    id="filled-required"
                    fullWidth
                    size="small"
                    label="Coming From"
                    name="comingForm"
                    value={addForm.comingForm || ""}
                    onChange={handleAddForm}
                    inputProps={{ readOnly: Boolean(editVisitorInfo) }}
                    disabled={editVisitorInfo}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  {" "}
                  <FormControl fullWidth size="small">
                    <InputLabel sx={{ fontSize: 8 }}>
                      To Meet User Type
                    </InputLabel>
                    <Select
                      label="To Meet User Type"
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      name="toMeetUserType"
                      value={addForm.toMeetUserType || ""}
                      onChange={handleRoleChange}
                      inputProps={{ readOnly: Boolean(editVisitorInfo) }}
                      disabled={editVisitorInfo}
                    >
                      {roles.map((role) => (
                        <MenuItem key={role._id} value={role._id}>
                          {role.roleName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  {" "}
                  <FormControl fullWidth size="small">
                    <InputLabel sx={{ fontSize: 8 }}>To Meet User</InputLabel>
                    <Select
                      label="To Meet User"
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      name="toMeetUser"
                      value={selectEmployee}
                      onChange={handleEmployeeChange}
                      options={employees}
                      inputProps={{ readOnly: Boolean(editVisitorInfo) }}
                      disabled={editVisitorInfo}
                    >
                      {employees.map((emp) => (
                        <MenuItem key={emp._id} value={emp._id}>
                          {emp.basicInfo.empName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  {" "}
                  <FormControl fullWidth size="small">
                    <InputLabel sx={{ fontSize: 8 }}>Reason to Meet</InputLabel>
                    <Select
                      label="Reason To Meet"
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      name="reasonToMeet"
                      value={addForm.reasonToMeet || ""}
                      onChange={handleAddForm}
                      inputProps={{ readOnly: Boolean(editVisitorInfo) }}
                      disabled={editVisitorInfo}
                    >
                      <MenuItem value={"vendor"}>Vendor</MenuItem>
                      <MenuItem value={"relative"}>Relative</MenuItem>
                      <MenuItem value={"friend"}>Friend</MenuItem>
                      <MenuItem value={"guardian"}>Guardian</MenuItem>
                      <MenuItem value={"family"}>Family</MenuItem>
                      <MenuItem value={"interview"}>InterView</MenuItem>
                      <MenuItem value={"meeting"}>Meeting</MenuItem>
                      <MenuItem value={"other"}>Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {editVisitorInfo ? (
                  <>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        required
                        id="filled-required"
                        fullWidth
                        size="small"
                        label="Check In"
                        // type="time"
                        name="checkIn"
                        value={addForm.checkIn || ""}
                        onChange={handleAddForm}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{ readOnly: Boolean(editVisitorInfo) }}
                        disabled={editVisitorInfo}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        required
                        id="filled-required"
                        fullWidth
                        size="small"
                        label="Check Out"
                        name="checkOut"
                        type="datetime-local"
                        value={addForm.checkOut || ""}
                        onChange={handleAddForm}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </>
                ) : null}
                <Grid item xs={12} md={12} lg={12}>
                  <TextareaAutosize
                    placeholder="Drop a note"
                    name="note"
                    value={addForm.note || ""}
                    onChange={handleAddForm}
                    inputProps={{ readOnly: Boolean(editVisitorInfo) }}
                    style={{
                      // width: 712,
                      width: "100%",
                      maxHeight: 110,
                      borderRadius: "5px",
                      padding: 10,
                      height: 118,
                      overflow: "auto",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  display="flex"
                  justifyContent={{
                    xs: "center",
                    sm: "center",
                    md: "flex-end",
                    lg: "flex-end",
                  }}
                >
                  <Tooltip title="Cancel">
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      size="medium"
                      color="error"
                      type="button"
                      sx={{ m: 1, color: "#fff" }}
                    >
                      Cancel
                    </Button>
                  </Tooltip>
                  <Tooltip title="Submit">
                    <Button
                      variant="contained"
                      size="medium"
                      type="submit"
                      sx={{
                        m: 1,
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                    >
                      {editVisitorInfo ? "Update" : "Add VisitorInfo"}
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </Box>

            {/* <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Tooltip title="Cancel">
                <Button
                  onClick={handleClose}
                  variant="contained"
                  size="medium"
                  color="error"
                  type="button"
                  sx={{ m: 1, color: "#fff" }}
                >
                  Cancel
                </Button>
              </Tooltip>
              <Tooltip title="Submit">
                <Button
                  variant="contained"
                  size="medium"
                  type="submit"
                  sx={{
                    background: "rgb(27, 55, 121)",
                    ":hover": { background: "rgb(27, 55, 121)" },
                  }}
                >
                  {editVisitorInfo ? "Update" : "Add VisitorInfo"}
                </Button>
              </Tooltip>
            </div> */}
            {/* </VisitoryInfoForm> */}
          </form>
        </Dialog>
        <Box overflow="scroll">
          <Table>
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Name
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Phone
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  To Meet
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Reason To Meet
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Check In
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Check Out
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter.map((visitor, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{visitor.name}</TableCell>
                      <TableCell align="center">{visitor.phone}</TableCell>
                      <TableCell align="center">
                        {visitor.toMeetUser.basicInfo.empName ||
                          visitor.toMeetUser.basicInfo.name}
                      </TableCell>
                      <TableCell align="center">
                        {visitor.reasonToMeet}
                      </TableCell>
                      <TableCell align="center">
                        {/* {new Date(visitor.checkIn).toLocaleTimeString()} */}
                        {new Date(visitor.checkIn).toLocaleString()}
                      </TableCell>
                      <TableCell align="center">
                        {visitor.checkOut
                          ? new Date(visitor.checkOut).toDateString()
                          : "not checked out"}
                      </TableCell>
                      <TableCell align="center">
                        <div className="edit-delete">
                          <Button
                            style={{ color: "#1b3779" }}
                            onClick={() =>
                              handleVisitorInfoEdit(visitor._id, visitor)
                            }
                          >
                            <EditIcon />
                          </Button>
                          <Button
                            style={{ color: "#1b3779" }}
                            onClick={() => handleClickOpenDialog(visitor)}
                          >
                            <VisibilityIcon />
                          </Button>
                          <Button
                            style={{
                              color: "#1b3779",
                            }}
                            onClick={() => setDeleteModal(visitor._id, visitor)}
                          >
                            <Delete />
                          </Button>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDeleteone}
                            id={visitor._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                : list.map((visitor, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{visitor.name}</TableCell>
                      <TableCell align="center">{visitor.phone}</TableCell>
                      <TableCell align="center">
                        {visitor.toMeetUser.basicInfo.empName ||
                          visitor.toMeetUser.basicInfo.name}
                      </TableCell>
                      <TableCell align="center">
                        {visitor.reasonToMeet}
                      </TableCell>
                      <TableCell align="center">
                        {/* {new Date(visitor.checkIn).toLocaleTimeString()} */}
                        {new Date(visitor.checkIn).toLocaleString()}
                      </TableCell>
                      <TableCell align="center">
                        {visitor.checkOut
                          ? new Date(visitor.checkOut).toLocaleString()
                          : "Not checked out"}
                      </TableCell>
                      <TableCell align="center">
                        <div className="edit-delete">
                          <Button
                            style={{ color: "#1b3779" }}
                            onClick={() =>
                              handleVisitorInfoEdit(visitor._id, visitor)
                            }
                          >
                            <EditIcon />
                          </Button>
                          <Button
                            style={{ color: "#1b3779" }}
                            onClick={() => handleClickOpenDialog(visitor)}
                          >
                            <VisibilityIcon />
                          </Button>
                          {/* <Button
                            style={{
                              color: "#1b3779",
                            }}
                            onClick={() => setDeleteModal(visitor._id, visitor)}
                          >
                            <Delete />
                          </Button> */}
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDeleteone}
                            id={visitor._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </Box>
        {!list.length && !search.trim() && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        {search.trim() && !searchFilter.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        <div className="add-icon">
          <Fab
            variant="contained"
            style={{ color: "#ffff", backgroundColor: "#1b3779" }}
            onClick={handleClickOpen}
          >
            <AddIcon />
          </Fab>
        </div>
        <BootstrapDialog
          onClose={handleCloseDialog}
          aria-labelledby="customized-dialog-title"
          open={dialog}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: "#1b3779", color: "white" }}
          >
            Visitor Information
          </BootstrapDialogTitle>

          <DialogContent dividers>
            <table className="hostel" style={styles.tab}>
              <tbody>
                {showDetails ? (
                  <>
                    <tr>
                      <td>School Name</td>
                      {/* <td>Excellent English Medium Primary & High School</td> */}
                      <td>{selectedSetting.schoolName}</td>
                    </tr>
                    <tr>
                      <td> Name</td>
                      <td>{showDetails.name}</td>
                    </tr>
                    <tr>
                      <td>Phone</td>
                      <td>{showDetails.phone}</td>
                    </tr>
                    <tr>
                      <td>Coming From</td>
                      <td>{showDetails.comingForm}</td>
                    </tr>
                    <tr>
                      <td>User Type</td>
                      <td>{showDetails.toMeetUserType.roleName}</td>
                    </tr>
                    <tr>
                      <td>To Meet</td>
                      <td>
                        {showDetails.toMeetUser.basicInfo.name ||
                          showDetails.toMeetUser.basicInfo.empName}
                      </td>
                    </tr>
                    <tr>
                      <td>Reason To Meet</td>
                      <td>{showDetails.reasonToMeet}</td>
                    </tr>
                    <tr>
                      <td>Check In</td>
                      <td>{new Date(showDetails.checkIn).toLocaleString()}</td>
                    </tr>
                    <tr>
                      <td>Check Out</td>
                      <td>
                        {showDetails.checkOut
                          ? new Date(showDetails.checkOut).toLocaleString()
                          : "not checked out"}
                      </td>
                    </tr>

                    <tr>
                      <td>Note</td>
                      <td>{showDetails.note}</td>
                    </tr>
                  </>
                ) : null}
              </tbody>
            </table>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </Wrapper>
  );
};

export default VisitoryInfo;
