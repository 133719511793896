import {
  Box,
  Button,
  Grid,
  styled,
  Stack,
  TextField,
  Typography,
  css,
} from "@mui/material";
import React from "react";
// import style from "../styles/loginfeedback.module.css"
import RocketIcon from "@mui/icons-material/Rocket";
import LanguageIcon from "@mui/icons-material/Language";
import { Link } from "react-router-dom";

const Image1 = styled(Box)(({}) => ({
  // backgroundImage: "url(http://iguru.wgl-demo.net/wp-content/uploads/2019/09/icon_bg-02.png)",
  // backgroundImage: "url(/icon_bg3.png)",
  backgroundRepeat: " no-repeat",
  width: "130px",
  height: "120px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // boxShadow:theme.shadows[2]
}));

const Image2 = styled(Box)(({}) => ({
  backgroundImage:
    "url(http://iguru.wgl-demo.net/wp-content/uploads/2019/09/icon_bg-02.png)",
  // backgroundImage: "url(/icon_bg3.png)",

  backgroundRepeat: "no-repeat",
  width: "130px",
  height: "120px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const RocketIconStyle = styled(RocketIcon)(({}) => ({
  color: "#1779f7",
  fontSize: "60px",
  rotate: "-10deg",
  "&:hover": {
    color: "#ff4500",
    cursor: "pointer",
    transition: "1s",
  },
}));

const LanguageIconStyle = styled(LanguageIcon)(({}) => ({
  color: "#1779f7",
  fontSize: "60px",
  rotate: "-10deg",

  "&:hover": {
    color: "#ff4500",
    cursor: "pointer",
    transition: "1s",
  },
}));

const SubBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  // alignItems:"center",
  // textAlign:"center",
  justifyContent: "left",
  gap: "2%",
  height: "100%",
  marginTop: "20%",
  marginLeft: "5%",
  color: "white",
  [theme.breakpoints.down("md")]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginTop: "5%",
  },
}));

const IconsBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const EnquiryTypography = styled(Typography)(({ theme }) => ({
  color: "white",
  variant: "h4",
  backgroundColor: "#ff4500",
  paddingTop: "6%",
  paddingBottom: "6%",
  textAlign: "center",
  borderRadius: "15px 15px 0px 0px",
  [theme.breakpoints.down("md")]: {
    fontSize: "25px",
  },
}));

const TextFieldStack = styled(Stack)(({ theme }) => ({
  backgroundColor: "#ffff",
  paddingTop: "30px",
  paddingBottom: "35px",
  paddingLeft: "10px",
  paddingRight: "10px",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  borderRadius: "0px 0px 0.5rem 0.5rem",
}));

const TextFieldStyles = styled(TextField)(({}) => ({
  border: "none",
  backgroundColor: "#F3F3F3",
  width: "90%",
  alignSelf: "center",
}));

const SendButton = styled(Button)(({}) => ({
  color: "white",
  backgroundColor: "#1779f7",
  border: "2px solid #1779f7",
  borderRadius: "5px",
  paddingTop: "10px",
  paddingBottom: "10px",
  textAlign: "center",
  width: "90%",
  fontWeight: "bold",
  alignSelf: "center",
  transition: "0.3s",
  "&:hover": {
    backgroundColor: "none",
    color: "black",
    border: "2px solid #ff4500",
  },
}));

const AnchorBox = styled(Box)({
  width: "100px",
  [`&::after`]: {
    content: '""',
    width: "0%",
    height: "3px",
    backgroundColor: "white",
    display: "block",
    transition: "0.5s",
    fontWeight: "bold",
    fontSize: "1rem",
    color: "white",
  },
  [`&:hover::after`]: {
    width: "100%",
  },
});

const LoginBox = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: 50,
  top: "-10%",
  paddingLeft: "10px",

  [theme.breakpoints.down("md")]: {
    top: 0,
    padding: "5px",
  },
}));

const LoginForm = () => {
  return (
    <Box sx={{ bgcolor: "#4ab1fb", height: "auto" }}>
      <Grid container rowGap={2}>
        <Grid item xs={12} sm={6} md={3.7}>
          <SubBox>
            <IconsBox>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  zIndex: 10,
                  width: "100px",
                  height: "100px",
                  bgcolor: "#ffffff",
                  rotate: "10deg",
                  borderRadius: "52% 48% 48% 51% / 75% 79% 22% 23% ",
                }}
              >
                {" "}
                <RocketIconStyle />
              </Box>
              <Box
                sx={{
                  width: "100px",
                  rotate: "10deg",
                  height: "110px",
                  bgcolor: "#ff4500",
                  borderRadius: "49% 49% 51% 51% / 33% 30% 68% 66% ",
                }}
              ></Box>
            </IconsBox>

            <Box>
              <Typography style={{ marginTop: "1rem", fontWeight: "bolder" }}>
                Learn Anything
              </Typography>
            </Box>

            <Box>
              <Typography sx={{ fontFamily: "sans-serif" }}>
                Join millions of people from around the world learning together.
                Try it now!
              </Typography>
            </Box>

            <Box>
              <a
                href=""
                title="Course Grid"
                style={{ fontWeight: "bold", textDecoration: "none" }}
              >
                <AnchorBox>
                  <Link
                    to="/learnanything"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <Typography color={"white"}>View More+</Typography>
                  </Link>
                </AnchorBox>
              </a>
              {/* <Link to="/" sx={{textDecoration:"none"}}>  <Typography color='black'> View More+</Typography></Link> */}
            </Box>
          </SubBox>
        </Grid>

        <Grid item xs={12} sm={6} md={3.7}>
          <SubBox>
            <IconsBox>
              <Box
                sx={{
                  display: "flex",
                  rotate: "10deg",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  zIndex: 10,
                  width: "100px",
                  height: "100px",
                  bgcolor: "#ffffff",
                  borderRadius: "52% 48% 48% 51% / 75% 79% 22% 23% ",
                }}
              >
                {" "}
                <LanguageIconStyle />
              </Box>
              <Box
                sx={{
                  width: "100px",
                  rotate: "10deg",
                  height: "110px",
                  bgcolor: "#ff4500",
                  borderRadius: "49% 49% 51% 51% / 33% 30% 68% 66% ",
                }}
              ></Box>
            </IconsBox>

            {/* <Box>
                    <Image2 >
                    <LanguageIconStyle />
                    </Image2>
                </Box> */}

            <Box>
              
                <Typography style={{ marginTop: "1rem", fontWeight: "bolder" }}>
                  Learn Together
                </Typography>
            </Box>

            <Box>
              <Typography sx={{ fontFamily: "sans-serif" }}>
                Join millions of people from around the world learning together.
                Try it now!
              </Typography>
            </Box>

            <Box>
              <a
                href=""
                title="Course Grid"
                style={{ fontWeight: "bold", textDecoration: "none" }}
              >
                <AnchorBox>
                <Link
                to="/learntogether"
                style={{ textDecoration: "none", color: "white" }}
              >
                  <Typography color={"white"}>View More+</Typography>
                </Link>
                </AnchorBox>
              </a>
              {/* <Link to="/" sx={{textDecoration:"none"}}>  <Typography color='black'> View More+</Typography></Link> */}
            </Box>
          </SubBox>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <LoginBox>
            <EnquiryTypography variant="h4">
              <Box
                component="span"
                style={{
                  borderLeft: "3px solid #ffbca2",
                  lineHeight: "1.25px",
                  paddingLeft: "15px",
                }}
              >
                Enquiry Form
              </Box>
            </EnquiryTypography>
            <TextFieldStack spacing={3}>
              <TextFieldStyles
                fullWidth
                id="outlined-basic"
                label="Name"
                variant="outlined"
                required
              />

              <TextFieldStyles
                fullWidth
                id="outlined-basic"
                label="Email"
                variant="outlined"
                required
              />

              <TextFieldStyles
                fullWidth
                id="outlined-basic"
                label="Message"
                variant="outlined"
              />

              <SendButton fullWidth>Send</SendButton>
            </TextFieldStack>
          </LoginBox>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginForm;
