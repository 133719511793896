import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import Stack from "@mui/material/Stack";
import SectionSubHeader from "../../../components/SectionSubHeader";
import { get, post, put, del } from "../../../services/apis";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { urls as url } from "../../../services/urlConstant";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const style = {
  card: {
    color: "red",
    fontFamily: "cursive",
    textAlign: "start",
    alignSelf: "center",
  },
  card1: {
    padding: 15,
    maxWidth: "600px",
    marginBottom: 20,
    margin: "70px 200px",
    width: "100%",
  },
};
const PasswordReset = () => {
  const navigate = useNavigate();
  let [loader, setLoader] = useState(false);
  const [addForm, setAddForm] = useState({});
  const { selectedSetting } = useContext(SettingContext);
  const [profileId, setProfileId] = useState("");
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const handleFormChange = (e) => {
    const { value, name } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  useEffect(() => {
    const profileId = window.localStorage.getItem("profileId");
    setProfileId(profileId);
    console.log(profileId, "profileId");
  }, []);
  const handleUserChangePassword = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      await post(`${urls.password.resetPasswordForUser}/${profileId}`, {
        oldpassword: addForm.oldPassword,
        password: addForm.newPassword,
      });

      setAddForm({ oldPassword: "", newPassword: "" });
    } catch (error) {
      toast.error(error.message);
    }
    setLoader(false);
    //  setAddForm({});
  };

  const handleCancleClick = () => navigate("/profile", { replace: true });
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        padding: "0 10px",
      }}
    >
      <SectionSubHeader
        showSelector={false}
        title="Reset Password"
        style={{ margin: "15px", width: "100%" }}
      />
      <Card style={style.card1}>
        <Box sx={{ flexGrow: 1 }}>
          <form onSubmit={handleUserChangePassword}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <FormControl
                  size="small"
                  sx={style.textfield}
                  fullWidth
                  required
                >
                  <TextField
                    size="small"
                    label="Old Password"
                    name="oldPassword"
                    value={addForm.oldPassword}
                    onChange={handleFormChange}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <FormControl
                  size="small"
                  sx={style.textfield}
                  fullWidth
                  required
                >
                  <TextField
                    size="small"
                    label="New Password"
                    name="newPassword"
                    value={addForm.newPassword}
                    onChange={handleFormChange}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                display="flex"
                justifyContent={{
                  xs: "center",
                  sm: "center",
                  md: "flex-end",
                  lg: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  color="error"
                  sx={{ color: "#ffff" }}
                  onClick={handleCancleClick}
                >
                  Cancel
                </Button>

                <Button
                  loader={loader}
                  variant="contained"
                  sx={{
                    background: "rgb(27, 55, 121)",
                    ":hover": { background: "rgb(27, 55, 121)" },
                  }}
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
            {/* <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginTop: "15px",
              }}
            >
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  color="error"
                  sx={{ color: "#ffff" }}
                  onClick={handleCancleClick}
                >
                  Cancel
                </Button>

                <Button
                  loader={loader}
                  variant="contained"
                  sx={{
                    background: "rgb(27, 55, 121)",
                    ":hover": { background: "rgb(27, 55, 121)" },
                  }}
                  type="submit"
                >
                  Submit
                </Button>
              </Stack>
            </div> */}
          </form>
        </Box>
      </Card>
    </Box>
  );
};

export default PasswordReset;
