import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { del, get, post, put } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import React, { useEffect, useState } from "react";
const styles = {
  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
    marginLeft: "10px",
    marginTop: "5px",
  },
};
const StudentYearlyAttendence = () => {
  const [academicYears, setAcademicYears] = useState([]);
  const [sections, setSections] = useState([]);
  const [formData, setFormData] = useState([]);
  const [classes, setClasses] = useState([]);

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll);
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data[0]._id,
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, []);

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        const resSections = await get(urls.schoolSection.getAllSection, {
          params: {
            search: {
              class: res.data.data[0]._id,
            },
          },
        });
        const searchStudents = await get(urls.students.getAllStudent, {
          params: {
            search: {
              academicYear: formData.academicYear,
              "academicInfo.class": res.data.data[0]._id,
              "academicInfo.section": resSections.data.data[0]._id,
            },
          },
        });
        setSections(resSections.data.data);
        setClasses(res.data.data);
        setFormData((prev) => ({
          ...prev,
          className: res.data.data[0]._id,
          section: resSections.data.data[0]._id,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, []);

  const handleClassChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    try {
      const section = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      if (section.data.data.length > 0) {
        setFormData((prev) => ({
          ...prev,
          section: section.data.data[0]._id,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          section: "",
        }));
      }
      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const numbers = [];
  for (let i = 1; i <= 31; i++) {
    numbers.push(
      <TableCell sx={{ color: "white", display: "inline" }} key={i}>
        {i}
      </TableCell>
    );
  }

  return (
    <Container maxWidth="xl">
      <Box sx={{ mt: "15px" }}>
        <Card sx={{ padding: "10px" }}>
          <span
            style={{
              color: "red",
              fontSize: "large",
              fontFamily: "cursive",
            }}
          >
            Student Yearly Attendence Report:
          </span>
        </Card>
        <Card style={{ marginTop: 10, padding: 10 }}>
          <Box sx={{ flexGrow: 1 }}>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth required>
                    <InputLabel>Select Report</InputLabel>
                    <Select
                      label="Select report"
                      id="demo-simple-select-filled"
                      variant="standard"
                      // onChange={handleOnChange}
                      size="small"
                    >
                      <MenuItem></MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth required>
                    <InputLabel>Select School</InputLabel>
                    <Select
                      label="Select School"
                      id="demo-simple-select-filled"
                      variant="standard"
                      // onChange={handleOnChange}
                      size="small"
                    >
                      <MenuItem></MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth required>
                    <InputLabel>Academic Year</InputLabel>
                    <Select
                      label="Academic Year"
                      id="demo-simple-select-filled"
                      name="academicYear"
                      variant="standard"
                      value={formData.academicYear || ""}
                      onChange={handleOnChange}
                      size="small"
                    >
                      {academicYears &&
                        academicYears.map((row, index) => (
                          <MenuItem key={row._id} value={row._id}>
                            {row.academicYearFrom} - {row.academicYearTo}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth required>
                    <InputLabel>Class</InputLabel>
                    <Select
                      label="Class"
                      variant="standard"
                      id="demo-simple-select-filled"
                      name="className"
                      value={formData.className || ""}
                      onChange={handleClassChange}
                      size="small"
                    >
                      {classes &&
                        classes.map((row, index) => (
                          <MenuItem key={row._id} value={row._id}>
                            {row.className}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth variant="standard" required>
                    <InputLabel>Section</InputLabel>
                    <Select
                      label="Section"
                      variant="standard"
                      id="demo-simple-select-filled"
                      name="section"
                      value={formData.section || ""}
                      onChange={handleOnChange}
                      size="small"
                    >
                      {formData.className && (
                        <MenuItem value="all">--all--</MenuItem>
                      )}
                      {sections.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.sectionName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth required>
                    <InputLabel>Select Student</InputLabel>
                    <Select
                      label="Select report"
                      id="demo-simple-select-filled"
                      variant="standard"
                      // onChange={handleOnChange}
                      size="small"
                    >
                      <MenuItem></MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Button
                    size="small"
                    variant="contained"
                    style={styles.Button}
                  >
                    Find
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Card>
        <Card style={{ marginTop: 10, padding: 10 }}>
          <div
            style={{
              paddingLeft: "1rem",
              display: "flex",
              gap: "20px",
            }}
          >
            <div>
              Present Days:<span>0</span>
            </div>
            <div>
              Absent Days:<span>0</span>
            </div>
            <div>
              Percentage:<span>0%</span>
            </div>
          </div>
        </Card>
        <Box sx={{ overflowX: "scroll" }}>
          <Table>
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  Month-Year
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  Present Days
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Absent Days
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  {numbers}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">2022-2023</TableCell>

                <TableCell align="center">50</TableCell>

                <TableCell align="center">45</TableCell>
                <TableCell align="center">5</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Box>
    </Container>
  );
};

export default StudentYearlyAttendence;
