import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
  Modal,
  Fab,
  styled,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useState } from "react";
import { Delete } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { get, post, put, del } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import DeleteModal from "../Layout/Academic/DeleteModal";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import SectionsSubHeader from "../../components/SectionSubHeader";
import { Search } from "@material-ui/icons";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

const style = {
  position: "relative",
  width: "100%",
  height: 650,
  bgcolor: "background.paper",
  p: 4,
};

const VehicleLog = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [date, setDate] = useState(dayjs(Date.now()));
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState([]);
  const [vehicleNumber, setVehicleNumber] = React.useState([]);
  const [transportRouteTitle, setTransportRouteTitle] = React.useState([]);
  const [fromDate, setFromDate] = useState(Date.now());
  const [toDate, setToDate] = useState(Date.now());
  const [addForm, setAddForm] = useState({});
  const [list, setList] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [editVehicleLog, setEditVehicleLog] = useState();
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [dToSclTime, setDToSclTime] = useState("");
  const [aToSclTime, setAToSclTime] = useState("");
  const [dFromSclTime, setDFromSclTime] = useState("");
  const [aFromSclTime, setAFromSclTime] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  let [loader, setLoader] = useState(false);

  useEffect(() => {
    getVehicleLog();
  }, []);

  const getVehicleLog = async () => {
    try {
      const { data, status } = await get(urls.vehicleLog.getList, {
        params: {
          transportRouteTitle: addForm.transportRouteTitle,
          vehicleNumber: addForm.vehicleNumber,
          fromDate: fromDate,
          toDate: toDate,
        },
      });
      setList(data.data);
      console.log("lklklklklklklklk", data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(`${urls.vehicleLog.delete}`, id);
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const styles = {
    cardStyle: {
      margin: "15px",
      padding: "10px",
      overflowY: "hidden",
    },
    addbutton: {
      paddingTop: "6.2px",
      paddingBottom: "6.2px",
    },
    cardStyles: {
      padding: "10px",
    },
    card: {
      padding: 10,
      margin: "0px 0px 20px 0px",
    },
    Button: {
      background: "#1b3779",
      ":hover": { background: "#1b3779" },
      marginTop: "10px",
      marginLeft: "5px",
    },
    textfield: {
      // marginTop: "25px",
      width: "100%",
      marginBottom: "18px",
    },
  };

  useEffect(() => {
    const getVehicleNumber = async () => {
      try {
        const { data, status } = await get(`${urls.transport.getList}`, {});
        if (status > 199 && status < 299) {
          setVehicleNumber(data.data);
          console.log(data.data, "dattttttttttt");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getVehicleNumber();
  }, [selectedSetting]);

  const handleClose = () => {
    setAddForm({});
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    const getRouteTitle = async () => {
      try {
        const { data, status } = await get(`${urls.route.getList}`, {});
        if (status > 199 && status < 299) {
          setTransportRouteTitle(data.data);
          console.log(data.data, "vvvv");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getRouteTitle();
  }, [selectedSetting]);

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEditFormChange = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDToSclTime = (e) => {
    setDToSclTime(e.target.value);
  };
  const handleAToSclTime = (e) => {
    setAToSclTime(e.target.value);
  };

  const handleDFromSclTime = (e) => {
    setDFromSclTime(e.target.value);
  };

  const handleAFromSclTime = (e) => {
    setAFromSclTime(e.target.value);
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!editVehicleLog) {
        const { status } = await post(`${urls.vehicleLog.create}`, {
          transportRouteTitle: addForm.transportRouteTitle,
          vehicleNumber: addForm.vehicleNumber,
          date: date,
          DeptartureToSchoolTime: dToSclTime,
          DeptarturetoSchoolKM: addForm.DeptarturetoSchoolKM,
          ArrivaltoschoolTime: aToSclTime,
          ArrivalToSchoolKM: addForm.ArrivalToSchoolKM,
          ArrivaltoKMRun: addForm.ArrivaltoKMRun,
          Spareusereason: addForm.Spareusereason,
          SpareuseKM: addForm.SpareuseKM,
          SpareuseKMrun: addForm.SpareuseKMrun,
          DeptartureFromSchoolTime: dFromSclTime,
          DeptartureFromSchoolKM: addForm.DeptartureFromSchoolKM,
          ArrivalFromschoolTime: aFromSclTime,
          ArrivalFromSchoolKM: addForm.ArrivalFromSchoolKM,
          ArrivalFromKMRun: addForm.ArrivalFromKMRun,
          totalKMRun: addForm.totalKMRun,
          diselFilled: addForm.diselFilled,
        });
        getVehicleLog();
        handleClose();
      } else {
        const { status } = await put(
          `${urls.vehicleLog.update}/${editVehicleLog}`,
          "",
          {
            transportRouteTitle: addForm.transportRouteTitle,
            vehicleNumber: addForm.vehicleNumber,
            date: date,
            DeptartureToSchoolTime: dToSclTime,
            DeptarturetoSchoolKM: addForm.DeptarturetoSchoolKM,
            ArrivaltoschoolTime: aToSclTime,
            ArrivalToSchoolKM: addForm.ArrivalToSchoolKM,
            ArrivaltoKMRun: addForm.ArrivaltoKMRun,
            Spareusereason: addForm.Spareusereason,
            SpareuseKM: addForm.SpareuseKM,
            SpareuseKMrun: addForm.SpareuseKMrun,
            DeptartureFromSchoolTime: dFromSclTime,
            DeptartureFromSchoolKM: addForm.DeptartureFromSchoolKM,
            ArrivalFromschoolTime: aFromSclTime,
            ArrivalFromSchoolKM: addForm.ArrivalFromSchoolKM,
            ArrivalFromKMRun: addForm.ArrivalFromKMRun,
            totalKMRun: addForm.totalKMRun,
            diselFilled: addForm.diselFilled,
          }
        );
        getVehicleLog();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleVehicleLogEdit = (id, data) => {
    setEditVehicleLog(id);
    setAddForm({
      transportRouteTitle: data.transportRouteTitle,
      vehicleNumber: data.vehicleNumber,
      date: data.date,
      DeptartureToSchoolTime: data.dToSclTime,
      DeptarturetoSchoolKM: data.DeptarturetoSchoolKM,
      ArrivaltoschoolTime: data.aToSclTime,
      ArrivalToSchoolKM: data.ArrivalToSchoolKM,
      ArrivaltoKMRun: data.ArrivaltoKMRun,
      Spareusereason: data.Spareusereason,
      SpareuseKM: data.SpareuseKM,
      SpareuseKMrun: data.SpareuseKMrun,
      DeptartureFromSchoolTime: data.dFromSclTime,
      DeptartureFromSchoolKM: data.DeptartureFromSchoolKM,
      ArrivalFromschoolTime: data.aFromSclTime,
      ArrivalFromSchoolKM: data.ArrivalFromSchoolKM,
      ArrivalFromKMRun: data.ArrivalFromKMRun,
      totalKMRun: data.totalKMRun,
      diselFilled: data.diselFilled,
    });
    handleClickOpen();
  };
  const formStyle = {
    margin: "10px",
  };

  const handleGetPrintPdf = async (e) => {
    setLoader(true);
    e.preventDefault();
    console.log(toDate, "todate");
    try {
      const getPrint = await get(
        `/${selectedSetting._id}/vehicleLog/downloadpdf/${addForm.transportRouteTitle}/${addForm.vehicleNumber}/${fromDate}/${toDate}`,
        {
          responseType: "blob",
        }
      );
      const uri = URL.createObjectURL(getPrint.data);
      window.open(uri, "__blank");
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      list.length > 0 &&
        setSearchFilter(
          list.filter((ele) =>
            ele.vehicleNumber.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  return (
    <div>
      <div style={styles.cardStyle}>
        <SectionsSubHeader title="Vehicle Log" />

        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <form onSubmit={handleAddSubmit}>
            <Box sx={style}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    textAlign="center"
                    fontSize="20px"
                    fontWeight="bold"
                    onChange={handleEditFormChange}
                  >
                    {editVehicleLog ? "Update Vehicle Log" : "Add Vehicle Log"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date"
                      variant="standard"
                      value={date}
                      onChange={(newDate) => setDate(newDate)}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          sx={{
                            label: { fontSize: 12 },
                          }}
                          {...params}
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl fullWidth required size="small">
                    <InputLabel>Route Title</InputLabel>
                    <Select
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      label="Route Title"
                      variant="outlined"
                      name="transportRouteTitle"
                      size="small"
                      fullWidth
                      onChange={handleEditFormChange}
                      value={addForm.transportRouteTitle || ""}
                    >
                      {transportRouteTitle.map((name) => (
                        <MenuItem key={name._id} value={name._id}>
                          {name.transportRouteTitle}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl fullWidth required size="small">
                    <InputLabel>Vehicle Number</InputLabel>
                    <Select
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      label="Vehicle Number"
                      name="vehicleNumber"
                      size="small"
                      fullWidth
                      variant="outlined"
                      onChange={handleEditFormChange}
                      value={addForm.vehicleNumber || ""}
                    >
                      {vehicleNumber.map((name) => (
                        <MenuItem key={name._id} value={name._id}>
                          {name.vehicleNumber}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl fullWidth required size="small">
                    <TextField
                      required
                      id="filled-required"
                      variant="outlined"
                      label="Deptarture To School Time"
                      type="time"
                      size="small"
                      fullWidth
                      value={dToSclTime}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={handleDToSclTime}
                      sx={{
                        label: { fontSize: 12 },
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl fullWidth required size="small">
                    <TextField
                      id="outlined-basic"
                      label="Deptarture to School KM"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={handleEditFormChange}
                      value={addForm.DeptarturetoSchoolKM || ""}
                      name="DeptarturetoSchoolKM"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl fullWidth required size="small">
                    <TextField
                      required
                      id="filled-required"
                      variant="outlined"
                      label="Arrival To School Time"
                      type="time"
                      size="small"
                      fullWidth
                      value={aToSclTime}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={handleAToSclTime}
                      sx={{
                        label: { fontSize: 12 },
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl fullWidth required size="small">
                    <TextField
                      id="outlined-basic"
                      label="Arrival To School KM"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={handleEditFormChange}
                      name="ArrivalToSchoolKM"
                      value={addForm.ArrivalToSchoolKM || ""}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl fullWidth required size="small">
                    <TextField
                      id="outlined-basic"
                      label="Arrival KM Run"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={handleEditFormChange}
                      value={addForm.ArrivaltoKMRun || ""}
                      name="ArrivaltoKMRun"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl fullWidth required size="small">
                    <TextField
                      id="outlined-basic"
                      label="Spare Use Reason"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={handleEditFormChange}
                      value={addForm.Spareusereason || ""}
                      name="Spareusereason"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl fullWidth required size="small">
                    <TextField
                      id="outlined-basic"
                      label="Spare Use Km"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={handleEditFormChange}
                      value={addForm.SpareuseKM || ""}
                      name="SpareuseKM"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl fullWidth required size="small">
                    <TextField
                      id="outlined-basic"
                      label="Spare Use Km Run"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={handleEditFormChange}
                      value={addForm.SpareuseKMrun || ""}
                      name="SpareuseKMrun"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl fullWidth required size="small">
                    <TextField
                      required
                      id="filled-required"
                      variant="outlined"
                      label="Depature from School Time"
                      type="time"
                      size="small"
                      fullWidth
                      value={dFromSclTime}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={handleDFromSclTime}
                      sx={{
                        label: { fontSize: 12 },
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl fullWidth required size="small">
                    <TextField
                      id="outlined-basic"
                      label="Depature From School Km"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={handleEditFormChange}
                      value={addForm.DeptartureFromSchoolKM || ""}
                      name="DeptartureFromSchoolKM"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl fullWidth required size="small">
                    <TextField
                      required
                      id="filled-required"
                      variant="outlined"
                      label="Arrival From School Time"
                      type="time"
                      size="small"
                      fullWidth
                      value={aFromSclTime}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={handleAFromSclTime}
                      sx={{
                        label: { fontSize: 12 },
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl fullWidth required size="small">
                    <TextField
                      id="outlined-basic"
                      label="Arrivel From School Km"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={handleEditFormChange}
                      value={addForm.ArrivalFromSchoolKM || ""}
                      name="ArrivalFromSchoolKM"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl fullWidth required size="small">
                    <TextField
                      id="outlined-basic"
                      label="Arrivel From School Km Run"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={handleEditFormChange}
                      value={addForm.ArrivalFromKMRun || ""}
                      name="ArrivalFromKMRun"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl fullWidth required size="small">
                    <TextField
                      required
                      id="outlined-basic"
                      label="Total Km Run"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={handleEditFormChange}
                      value={addForm.totalKMRun || ""}
                      name="totalKMRun"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl fullWidth required size="small">
                    <TextField
                      id="outlined-basic"
                      label="Disel Filled"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={handleEditFormChange}
                      value={addForm.diselFilled || ""}
                      name="diselFilled"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: {
                        md: "right",
                        sm: "right",
                        xs: "center",
                      },
                    }}
                  >
                    <Tooltip title="Cancel">
                      <Button
                        variant="contained"
                        color="error"
                        size="medium"
                        type="button"
                        sx={{ m: 1, color: "#fff" }}
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                    </Tooltip>
                    <Tooltip title="Submit">
                      <Button
                        variant="contained"
                        size="medium"
                        type="submit"
                        sx={{
                          marginRight: "1px",
                          background: "rgb(27, 55, 121)",
                          ":hover": { background: "rgb(27, 55, 121)" },
                        }}
                      >
                        {editVehicleLog ? "Update" : "Add"}
                      </Button>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Dialog>
        <div style={{ marginTop: "2%" }}>
          <Card style={styles.card}>
            <Box>
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={3}>
                    <FormControl fullWidth size="small" required>
                      <InputLabel>Route Title</InputLabel>
                      <Select
                        labelId="demo-simpless-select-filled-label"
                        id="demo-simple-select-filled-l"
                        label="Route Title"
                        name="transportRouteTitle"
                        onChange={handleAddForm}
                        value={addForm.transportRouteTitle || ""}
                      >
                        {transportRouteTitle.map((name) => (
                          <MenuItem key={name._id} value={name._id}>
                            {name.transportRouteTitle}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FormControl fullWidth size="small" required>
                      <InputLabel>Vehicle Number</InputLabel>
                      <Select
                        labelId="demo-simpless-select-filled-label"
                        id="demo-simple-select-filled-l"
                        label="vehicle number"
                        name="vehicleNumber"
                        onChange={handleAddForm}
                        value={addForm.vehicleNumber || ""}
                      >
                        {vehicleNumber.map((name) => (
                          <MenuItem key={name._id} value={name._id}>
                            {name.vehicleNumber}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3} sx={{ alignSelf: "center" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={2}>
                        <DatePicker
                          label="From Date"
                          inputFormat="DD-MM-YYYY"
                          value={fromDate || ""}
                          onChange={setFromDate}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3} sx={{ alignSelf: "center" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={2}>
                        <DatePicker
                          label="To Date"
                          inputFormat="DD-MM-YYYY"
                          value={toDate || ""}
                          onChange={setToDate}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    display="flex"
                    justifyContent={{
                      xs: "center",
                      sm: "center",
                      md: "flex-end",
                      lg: "flex-end",
                    }}
                  >
                    <Button
                      variant="contained"
                      style={styles.Button}
                      onClick={getVehicleLog}
                    >
                      Find
                    </Button>
                    <Button
                      variant="contained"
                      style={styles.Button}
                      onClick={handleGetPrintPdf}
                      loading={loader}
                    >
                      Print
                    </Button>
                  </Grid>
                </Grid>
              </form>

              {/* <Stack spacing={2} direction="row" ml="5px">
                <Button
                  variant="contained"
                  style={styles.Button}
                  onClick={getVehicleLog}
                >
                  Find
                </Button>
                <Button
                  variant="contained"
                  style={styles.Button}
                  onClick={handleGetPrintPdf}
                  loading={loader}
                >
                  Print
                </Button>
              </Stack> */}
            </Box>
          </Card>
        </div>
        {/* <Paper sx={{ height: "60px", overflow: "hidden" }}> */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs="auto">
              <TextField
                size="small"
                fullWidth
                style={styles.textfield}
                value={search}
                onChange={handleSearch}
                placeholder="enter vehicle number"
                label="Search"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" type="submit">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                // sx={{ mt: 1.2 }}
              />
            </Grid>
          </Grid>
        </div>
        {/* </Paper> */}
        <Box overflow="scroll">
          <Table>
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  Date
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Arrival KM Run
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Spare Use KM Run
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Arrivel From school KM Run
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Total KM Run
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter.map((data, index) => (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {dayjs(data.date).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        {data.ArrivaltoKMRun}
                      </TableCell>
                      <TableCell align="center">
                        {data.SpareuseKMrun ? data.SpareuseKMrun : "0"}
                      </TableCell>
                      <TableCell align="center">
                        {data.ArrivalFromSchoolKM}
                      </TableCell>
                      <TableCell align="center">{data.totalKMRun}</TableCell>
                      <TableCell align="center">
                        <div className="edit-delete">
                          <Button
                            style={{ color: "#1b3779" }}
                            onClick={() => handleVehicleLogEdit(data._id, data)}
                          >
                            <EditIcon />
                          </Button>
                          <Button
                            style={{
                              color: "#1b3779",
                            }}
                            onClick={() => setDeleteModal(data._id, data)}
                          >
                            <Delete fontSize="small" />
                          </Button>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDeleteone}
                            id={data._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                : list.map((data, index) => (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {dayjs(data.date).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        {data.ArrivaltoKMRun}
                      </TableCell>
                      <TableCell align="center">
                        {data.SpareuseKMrun ? data.SpareuseKMrun : "0"}
                      </TableCell>
                      <TableCell align="center">
                        {data.ArrivalFromSchoolKM}
                      </TableCell>
                      <TableCell align="center">{data.totalKMRun}</TableCell>
                      <TableCell align="center">
                        <div className="edit-delete">
                          <Button
                            style={{ color: "#1b3779" }}
                            onClick={() => handleVehicleLogEdit(data._id, data)}
                          >
                            <EditIcon />
                          </Button>
                          <Button
                            style={{
                              color: "#1b3779",
                            }}
                            onClick={() => setDeleteModal(data._id, data)}
                          >
                            <Delete fontSize="small" />
                          </Button>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDeleteone}
                            id={data._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </Box>
        {!list.length && !search.trim() && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        {search.trim() && !searchFilter.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}

        <div className="add-icon">
          <Fab
            variant="contained"
            style={{ color: "#ffff", backgroundColor: "#1b3779" }}
            onClick={handleClickOpen}
          >
            <AddIcon />
          </Fab>
        </div>
      </div>
    </div>
  );
};

export default VehicleLog;
