import React from "react";
import css from "../NavbarComponents/styles/midnav.module.css";
import MovingText from "react-moving-text";
import { Fragment } from "react";
import { Box, Grid, Typography, styled } from "@mui/material";
import { Button, Stack } from "@mui/material";
import { useState } from "react";
import Modal from "@mui/material/Modal";

import { Cancel } from "@material-ui/icons";
import CircularWaves from "./CircularWaves";
// import zIndex from '@mui/material/styles/zIndex';
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const MainBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
  },
}));
const GridContainer = styled(Grid)(({ theme }) => ({
  paddingBottom: "15vh",
  // position:'relative',
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    display: "flex",
    gap: "20px",
    marginTop: "-8%",
    marginLeft: "45px",
  },
}));

const IconBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const Midnav = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let [play, setPlay] = useState(false);
  let handleVideo = () => {
    setPlay(!play);
  };

  let navigate = useNavigate();

  const styles = {
    background: {
      backgroundImage: "url(/boy.png)",
      border: "solid grey 0.1px",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      width: "100%",
      opacity: "1",
    },
  };

  let handleNavigate = () => {
    navigate("/addmission");
  };

  return (
    <>
      <MainBox style={styles.background}>
        <Grid container sx={{ marginBottom: "10vh", marginTop: "300px" }}>
          <Grid item xs={12} md={1}></Grid>

          <Grid item xs={12} md={9} sx={{ marginTop: "5%", marginLeft: "-8%" }}>
            <Stack style={{ gap: "20px" }}>
              <div className={css.sideContent}>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ borderLeft: "solid #ff4500" }}>
                    <MovingText
                      type="slideInFromLeft"
                      duration="3000ms"
                      delay="0.5s"
                      direction="normal"
                      timing="ease"
                      iteration="1"
                      fillMode="none"
                      className={css.title}
                    >
                      <Typography sx={{ paddingLeft: "10px" }}>
                        FUEL YOUR FUTURE
                      </Typography>
                    </MovingText>
                  </Box>
                </Box>
              </div>
              <div className={css.sideTitle}>
                <MovingText
                  type="slideInFromRight"
                  duration="3000ms"
                  delay="0.5s"
                  direction="normal"
                  timing="ease"
                  iteration="1"
                  fillMode="none"
                >
                  <Fragment>More Than 50+ Faculties </Fragment>
                </MovingText>
                <MovingText
                  type="slideInFromLeft"
                  duration="3000ms"
                  delay="0.5s"
                  direction="normal"
                  timing="ease"
                  iteration="1"
                  fillMode="none"
                >
                  <Fragment>To Knowledge With Us.</Fragment>
                </MovingText>
                <div
                  className="mainBtn"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "0px",
                  }}
                ></div>
              </div>
            </Stack>
          </Grid>

          <Grid item sm={12} md={2}>
            <img
              src="pencill.png"
              width="100"
              height="90"
              className={css.rotate}
              style={{ position: "absolute", right: "250px", top: "250px " }}
            />
            <IconBox>
              <img
                src="science.png"
                width="100"
                height="90"
                style={{
                  position: "absolute",
                  marginLeft: "-1000px",
                  top: "250px ",
                }}
                className={css.rotate}
              />
              <img
                src="magnifying.png"
                width="100"
                height="90"
                style={{
                  position: "absolute",
                  marginLeft: "-1000px",
                  top: "610px ",
                }}
                className={css.rotate}
              />
              <img
                src="globe-pencil.png"
                width="100"
                height="90"
                style={{
                  position: "absolute",
                  marginLeft: "285px",
                  top: "350px ",
                }}
                className={css.rotate}
              />
              <img
                src="light-bulb.png"
                width="100"
                height="90"
                style={{
                  position: "absolute",
                  marginLeft: "285px",
                  top: "600px ",
                }}
                className={css.rotate}
              />
            </IconBox>
          </Grid>
        </Grid>

        <GridContainer container>
          <Grid item xs={12} md={1}></Grid>

          <Grid item xs={12} md={2}>
            <Button
              variant="contained"
              onClick={handleNavigate}
              sx={{
                bgcolor: "#1779f7",
                border: "solid #1779f7",
                "&:hover": { bgcolor: "transparent", border: "solid #ff4500" },
              }}
            >
              <Typography>GET ADMISSION</Typography>
            </Button>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box component="div" onClick={handleVideo}>
              <Box
                sx={{ zIndex: 10, position: "relative" }}
                onClick={handleOpen}
              >
                <Box sx={{ zIndex: 10, position: "relative" }}>
                  <CircularWaves />
                </Box>

                <Box
                  sx={{
                    zIndex: 30,
                    position: "relative",
                    color: "white",
                    borderRadius: "50%",
                    bgcolor: "orangered",
                    height: "50px",
                    width: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": { bgcolor: "#1779f7" },
                  }}
                >
                  <PlayArrowIcon />
                </Box>
              </Box>

              <div>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <iframe
                      style={{
                        position: "absolute",
                        width: "100%",
                        top: 0,
                        left: 0,
                        height: "100%",
                      }}
                      width=""
                      src="https://www.youtube.com/embed/ZCKYz6cgiRs"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                    <Button
                      variant="contained"
                      sx={{
                        zIndex: 10,
                        position: "absolute",
                        left: "103%",
                        top: "0%",
                        bgcolor: "#ff4500",
                        "&:hover": { bgcolor: "#1779f7" },
                      }}
                      onClick={handleClose}
                    >
                      <Cancel />
                    </Button>
                  </Box>
                </Modal>
              </div>
            </Box>
          </Grid>
        </GridContainer>
      </MainBox>
    </>
  );
};

export default Midnav;
