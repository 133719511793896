import React from "react";
import {
  Paper,
  TextField,
  Button,
  Box,
  InputAdornment,
  IconButton,
  Avatar,
} from "@mui/material";
import "../../../styles/components/ResetPassword/ResetPassword.scss";
import { VisibilityOff, Visibility, Key } from "@mui/icons-material";
import { useState } from "react";
import Password from "@mui/icons-material/Password";

const paperStyle = { padding: 28, width: 320 };
const btnstyle = { margin: "8px 0" };
const avatarStyle = { backgroundColor: "#1bbd7e" };

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div>
      <form>
        <div className="imageStyles">
          <div className="divStyle">
            <Paper elevation={10} style={paperStyle}>
              <div align="center">
                <Avatar style={avatarStyle}>
                  <Password />
                </Avatar>
                <h2 className="font">Reset Password</h2>
              </div>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <Key sx={{ color: "action.active", mr: 1, my: 0.5 }} />

                <TextField
                  label=" New password"
                  size="small"
                  fullWidth
                  required
                  id="standard-adornment-password"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <Key sx={{ color: "action.active", mr: 1, my: 0.5 }} />

                <TextField
                  label="Confirm Password"
                  size="small"
                  fullWidth
                  required
                  id="standard-adornment-password"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <div className="button">
                <Button
                  type="reset"
                  color="primary"
                  fullWidth
                  variant="contained"
                  style={btnstyle}
                >
                  Reset
                </Button>
              </div>
            </Paper>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
