import React, { useEffect, useState, useContext } from "react";
import {
  Card,
  CardContent,
  Container,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  Typography,
  Button,
  Box,
  Tabs,
  TableCell,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TextField,
  TableRow,
  TextareaAutosize,
  MenuItem,
  Tooltip,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Delete, Edit } from "@mui/icons-material";
import dayjs from "dayjs";
import { urls as url } from "../../../services/urlConstant";
import appendStringToUrls from "../../../utils/appendString";
import SettingContext from "../../../context/SettingsContext";
import { get, post, put, del } from "../../../services/apis";
import DeleteModal from "../../Layout/Academic/DeleteModal";

import SectionSubHeader from "../../SectionSubHeader";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const TeacherActivity = () => {
  const [tabValue, setTabValue] = useState(0);
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs());
  const [data, setData] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [formData, setFormData] = useState({
    class: "",
    section: "",
    subject: "",
    topic: "",
  });
  console.log(formData, "formmmmmmmmm");
  const [list, setList] = useState([]);
  const [editActivity, setEditActivity] = useState();
  const { selectedSetting } = useContext(SettingContext);
  const [employees, setEmployees] = useState([]);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const handleAddSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.class ||
      !formData.section ||
      !formData.subject ||
      !formData.topic
    )
      return alert("please fill the form");
    try {
      if (!editActivity) {
        const data = await post(urls.teacherActivity.createActivity, {
          classId: formData.class,
          subjectId: formData.subject,
          sectionId: formData.section,
          topic: formData.topic,
        });
        setShowEdit(false);
        setFormData({});
        // setTabValue(1);
        getList();
      } else {
        const data = await put(
          `${urls.teacherActivity.editActivity}${editActivity}`,
          "",
          {
            classId: formData.class,
            subjectId: formData.subject,
            sectionId: formData.section,
            topic: formData.topic,
          }
        );
        if (data.data.success) {
          setShowEdit(false);
          setTabValue(1);
          getList();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (newValue !== 2) {
      setShowEdit(false);
    }
  };

  const handleClasses = async (e) => {
    try {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
      const res = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: value,
          },
        },
      });

      setSections(res.data.data);
      const response = await get(urls.schoolSubject.getAllSubject, {
        params: {
          search: {
            class: value,
          },
        },
      });
      setSubjects(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (tabValue === 0) {
      setFormData({});
    }
  }, [tabValue]);

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        const resSections = await get(urls.schoolSection.getAllSection, {
          params: {
            search: {
              class: res.data.data[0]._id,
            },
          },
        });

        const resSubject = await get(urls.schoolSubject.getAllSubject, {
          params: {
            search: {
              "academicInfo.class": res.data.data[0]?._id,
            },
          },
        });
        setClasses(res.data.data);
        setSections(resSections.data.data);
        setSubjects(resSubject.data.data);
        setFormData((prev) => ({
          ...prev,
          class: res.data.data[0]._id,
          //  section: resSections.data.data[0]._id,
          // subject: resSubject.data.data[0]._id,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, [selectedSetting]);

  const handleOnchange = (e) => {
    const { name, value } = e.target;
    console.log(value);
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (!formData.class || !formData.section || !formData.subject) return;
    getList();
  }, [selectedSetting, formData.class, formData.section, formData.subject]);

  const getList = async () => {
    setList([]);

    try {
      let search = {
        createdAt: {
          $gte: dayjs(fromDate).subtract(1, "day").toDate(),
          $lte: dayjs(toDate).toDate(),
        },
        class: formData.class,
        subject: formData.subject,
        section: formData.section,
      };
      if (formData.employee) {
        search.createdBy = formData.employee;
      }

      const { data, status } = await get(urls.teacherActivity.getList, {
        params: {
          search,
        },
      });
      console.log(data, "----------------------");
      setList(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleActivityEdit = (id, data) => {
    setEditActivity(id);

    setFormData({
      class: data.class._id,
      subject: data.subject._id,
      section: data.section._id,
      topic: data.topic,
    });
    setTabValue(2);
    setShowEdit(true);
  };

  useEffect(() => {
    const getTeacher = async () => {
      try {
        const { data: role, status } = await get(`${urls.role.getAllRole}`, {
          params: {
            search: {
              roleName: "TEACHER",
            },
          },
        });
        if (status > 199 && status < 299) {
          const { data: employees, status } = await get(
            `${urls.employee.getAllEmployee}`,
            {
              params: {
                search: {
                  role: role.data[0]?._id,
                },
              },
            }
          );
          if (status > 199 && status < 299) {
            setEmployees(employees.data);
            console.log(employees.data, "teacher");
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getTeacher();
  }, [selectedSetting]);

  const editButtonDisable = (createdAt) => {
    const createdAtTime = dayjs(createdAt);
    const currentTime = dayjs();
    const hoursDiff = currentTime.diff(createdAtTime, "hours");
    return hoursDiff >= 24;
  };

  return (
    <Box sx={{ padding: "10px 20px 0 20px" }}>
      <SectionSubHeader title="Teacher Activity" />
      <Box sx={{ marginTop: 3 }}>
        <Paper>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Add Activity" {...a11yProps(0)} />
            <Tab label="All Activity" {...a11yProps(1)} />
            {showEdit && <Tab label="Edit Activity" {...a11yProps(2)} />}
          </Tabs>
        </Paper>
      </Box>

      <TabPanel value={tabValue} index={0}>
        <Card style={{ margin: "15px 0px", padding: 10 }}>
          <Box sx={{ flexGrow: 1 }}>
            <form onSubmit={handleAddSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Class</InputLabel>
                    <Select
                      label="Class"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="class"
                      value={formData.class || ""}
                      onChange={(event) => handleClasses(event)}
                    >
                      {classes &&
                        classes.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.className}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Section</InputLabel>
                    <Select
                      label="Section"
                      id="demo-simple-select-filled"
                      name="section"
                      value={formData.section || ""}
                      onChange={(event) => handleOnchange(event)}
                    >
                      {sections.length > 0 &&
                        sections.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.sectionName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Subject</InputLabel>
                    <Select
                      label="Subject"
                      id="demo-simple-select-filled"
                      name="subject"
                      value={formData.subject || ""}
                      onChange={(event) => handleOnchange(event)}
                    >
                      {subjects &&
                        subjects.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.subjectName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextareaAutosize
                    maxRows={4}
                    aria-label="maximum height"
                    placeholder="Topic Covered"
                    style={{
                      width: "100%",
                      maxHeight: 110,
                      borderRadius: "5px",
                      padding: 10,
                      height: 70,
                      overflow: "auto",
                    }}
                    onChange={handleOnchange}
                    value={formData.topic || ""}
                    name="topic"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  display="flex"
                  justifyContent={{
                    xs: "center",
                    sm: "center",
                    md: "flex-end",
                    lg: "flex-end",
                  }}
                >
                  <Button type="submit" variant="contained">
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Card>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <Card style={{ margin: "15px 0px", padding: 10 }}>
          <Box sx={{ flexGrow: 1 }}>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Teacher</InputLabel>
                    <Select
                      label="teacher"
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      name="teacher"
                      value={formData.teacher}
                      onChange={handleOnchange}
                    >
                      {employees.map((employee) => (
                        <MenuItem key={employee._id} value={employee._id}>
                          {employee.basicInfo.empName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Class</InputLabel>
                    <Select
                      label="Class"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="class"
                      value={formData.class || ""}
                      onChange={(event) => handleClasses(event)}
                    >
                      {classes &&
                        classes.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.className}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Section</InputLabel>
                    <Select
                      label="Section"
                      id="demo-simple-select-filled"
                      name="section"
                      value={formData.section || ""}
                      onChange={(event) => handleOnchange(event)}
                    >
                      {sections.length > 0 &&
                        sections.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.sectionName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Subject</InputLabel>
                    <Select
                      label="Subject"
                      id="demo-simple-select-filled"
                      name="subject"
                      value={formData.subject || null}
                      onChange={(event) => handleOnchange(event)}
                    >
                      {subjects &&
                        subjects.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.subjectName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="From Date"
                      inputFormat="DD-MM-YYYY"
                      value={fromDate || ""}
                      onChange={(newValue) => {
                        setFromDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="To Date"
                      inputFormat="DD-MM-YYYY"
                      value={toDate || ""}
                      onChange={(newValue) => {
                        setToDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  display="flex"
                  justifyContent={{
                    xs: "center",
                    sm: "center",
                    md: "flex-end",
                    lg: "flex-end",
                  }}
                >
                  <Button variant="contained" onClick={getList}>
                    Find
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Card>

        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">
                      Date
                      <span style={{ cursor: "pointer" }}></span>
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">
                      Topic
                      <span style={{ cursor: "pointer" }}></span>
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Action</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((row, i) => (
                  <TableRow>
                    <TableCell align="center">
                      {dayjs(row.createdAt).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell align="center">{row.topic}</TableCell>
                    <TableCell align="center">
                      <div className="edit-delete">
                        <Tooltip title="Edit" disableInteractive>
                          <Button
                            onClick={() => handleActivityEdit(row._id, row)}
                            disabled={editButtonDisable(row.createdAt)}
                          >
                            <Edit />
                          </Button>
                        </Tooltip>
                        {/* 
                        <Tooltip title="Delete" disableInteractive>
                          <Button onClick={() => setDeleteModal(row._id, row)}>
                            <Delete />
                          </Button>
                        </Tooltip> */}
                        {/* <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDeleteone}
                          id={row._id}
                          setDeleteModal={setDeleteModal}
                        /> */}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {!list.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </Paper>
      </TabPanel>
      {showEdit && (
        <TabPanel value={tabValue} index={2}>
          <Card style={{ margin: "15px 0px", padding: 10 }}>
            <Box sx={{ flexGrow: 1 }}>
              <form onSubmit={handleAddSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Class</InputLabel>
                      <Select
                        label="Class"
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        name="class"
                        value={formData.class || ""}
                        onChange={(event) => handleClasses(event)}
                      >
                        {classes &&
                          classes.map((row, index) => (
                            <MenuItem
                              key={row._id}
                              value={row._id}
                              sx={{ fontSize: 12, fontWeight: 500 }}
                            >
                              {row.className}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Section</InputLabel>
                      <Select
                        label="Section"
                        id="demo-simple-select-filled"
                        name="section"
                        value={formData.section || ""}
                        onChange={(event) => handleOnchange(event)}
                      >
                        {sections.length > 0 &&
                          sections.map((row, index) => (
                            <MenuItem
                              key={row._id}
                              value={row._id}
                              sx={{ fontSize: 12, fontWeight: 500 }}
                            >
                              {row.sectionName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Subject</InputLabel>
                      <Select
                        label="Subject"
                        id="demo-simple-select-filled"
                        name="subject"
                        value={formData.subject || ""}
                        onChange={(event) => handleOnchange(event)}
                      >
                        {subjects &&
                          subjects.map((row, index) => (
                            <MenuItem
                              key={row._id}
                              value={row._id}
                              sx={{ fontSize: 12, fontWeight: 500 }}
                            >
                              {row.subjectName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextareaAutosize
                      maxRows={4}
                      aria-label="maximum height"
                      placeholder="Topic Covered"
                      style={{
                        width: "100%",
                        maxHeight: 110,
                        borderRadius: "5px",
                        padding: 10,
                        height: 70,
                        overflow: "auto",
                      }}
                      onChange={handleOnchange}
                      value={formData.topic || ""}
                      name="topic"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    display="flex"
                    justifyContent={{
                      xs: "center",
                      sm: "center",
                      md: "flex-end",
                      lg: "flex-end",
                    }}
                  >
                    <Button type="submit" variant="contained">
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Card>
        </TabPanel>
      )}
    </Box>
  );
};

export default TeacherActivity;
