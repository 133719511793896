import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { get } from "../../../services/apis";
import css from "../../../styles/Overview.module.css";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { urls as url } from "../../../services/urlConstant";
import SectionSubHeader from "../../../components/SectionSubHeader";
import Wrapper from "../../Wrapper";
import StudentCount from "./StudentCount";
import Divider from "@mui/material/Divider";
import { Padding } from "@mui/icons-material";

const styles = {
  icon: {
    padding: "10px",
    fontSize: "12px",
    fontWeight: 500,
  },
  card: {
    padding: "2%",
    marginBottom: 20,
    marginLeft: "1rem",
    marginRight: "1rem",
  },
  textfield: {
    width: "100%",
  },
  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
  },
};

const Overview = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [academicYears, setAcademicYears] = useState([]);
  const [selectAcademicYear, setSelectAcademicYear] = useState("");
  const [overviewDetails, setOverviewDetails] = useState([]);
  const [totalStudents, setTotalStudents] = useState(0);
  const [totalMaleStudents, setTotalMaleStudents] = useState(0);
  const [totalFemaleStudents, setTotalFemaleStudents] = useState(0);

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const { data: academicYears, status } = await get(
          urls.administrator.academicYearGetAll
        );
        if (status > 199 && status < 299) {
          setAcademicYears(academicYears.data);
          console.log("setAcademicYears", academicYears.data);
          if (academicYears.data.length > 0) {
            setSelectAcademicYear(academicYears.data[0]._id);
          }
        }
      } catch (error) {}
    };
    getAcademicYears();
  }, [selectedSetting]);

  //   const academicYearChanged = async () => {
  //     try {
  //       if (selectAcademicYear) {
  //         const { data: overview, status } = await get(
  //           `/inactivestudents-overview/${selectAcademicYear}`
  //         );
  //         if (status > 199 && status < 299) {
  //           setInActiveOverviewDetails(overview.data);
  //           setInActiveTotalStudents(overview.inActivetotalStudentsCount);
  //           setInActiveTotalMaleStudents(
  //             overview.inActivetotalMaleStudentsCount
  //           );
  //           setInActiveTotalFemaleStudents(
  //             overview.inActivetotalFemaleStudentsCount
  //           );
  //         }
  //         console.log(inActiveoverviewDetails, "InActiveOverviewDetails");
  //       }
  //     } catch (error) {}
  //   };
  //   academicYearChanged();
  // }, [selectAcademicYear]);

  useEffect(() => {
    const academicYearChanged = async () => {
      try {
        if (selectAcademicYear) {
          const { data: overview, status } = await get(
            `/${selectedSetting._id}/students-overview/${selectAcademicYear}`
          );
          if (status > 199 && status < 299) {
            setOverviewDetails(overview.data);
            console.log(overview.data, "jkjkjk");
            setTotalStudents(overview.totalStudentsCount);
            setTotalMaleStudents(overview.totalMaleStudentsCount);
            setTotalFemaleStudents(overview.totalFemaleStudentsCount);
          }
        }
      } catch (error) {
        // Handle error
      }
    };

    academicYearChanged();
  }, [selectAcademicYear, selectedSetting]);

  return (
    <Wrapper>
      <SectionSubHeader title="Student Overview " />

      <Card sx={{ margin: "20px 0", padding: "10px" }}>
        <Box>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={3}>
                <FormControl size="small" style={styles.textfield} required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Academic Year
                  </InputLabel>
                  <Select
                    label="Academic Year"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="academicYear"
                    value={selectAcademicYear}
                    onChange={(e) => setSelectAcademicYear(e.target.value)}
                  >
                    {academicYears &&
                      academicYears.map((row) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.academicYearFrom} - {row.academicYearTo}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Card>
      <Box style={{ fontSize: { md: "15px", sm: "12px" }, padding: "1.2rem" }}>
        <div className={css.totalOverviewContainer}>
          <div>Total Students:{totalStudents}</div>
          <div>Total Male Students:{totalMaleStudents}</div>
          <div>Total Female Students:{totalFemaleStudents}</div>
        </div>
      </Box>
      {/* <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead className={css.tableHeader}>
            <TableRow>
              <TableCell className={css.tableCell} align="left">
                Class
              </TableCell>
              
              <TableCell className={css.tableCell} align="left">
                Total
              </TableCell>
              <TableCell className={css.tableCell} align="left">
                Male
              </TableCell>
              <TableCell className={css.tableCell} align="left">
                Female
              </TableCell>
              <TableCell className={css.tableCell} align="left">
                Section
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {overviewDetails.map((overview) => (
              <TableRow key={overview.className}>
                <TableCell align="left">{overview.className}</TableCell>
                

                <TableCell align="left">
                
                  {overview.totalStudents}
                </TableCell>
                <TableCell align="left">{overview.maleStudents}</TableCell>
                <TableCell align="left">{overview.femaleStudents}</TableCell>
                <TableCell align="left">
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead className={css.tableHeader}>
                        <TableRow>
                          {overview.sections
                            .sort((a, b) => a.section.localeCompare(b.section)) // Sort sections alphabetically
                            .map((section) => (
                              <TableCell
                                className={css.tableCell}
                                align="left"
                                key={section.section}>
                                {section.section}
                              </TableCell>
                            ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          {overview.sections
                            .sort((a, b) => a.section.localeCompare(b.section)) 
                            .map((section) => (
                              <TableCell align="left" key={section.section}>
                               
                                {section.count}
                              </TableCell>
                            ))}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className={css.tableHeader}>
            <TableRow>
              <TableCell
                style={{ borderRight: "1px solid grey" }}
                className={css.tableCell}
                align="center"
              >
                Class
              </TableCell>
              {/* <TableCell className={css.tableCell} align='left'>Active / <br></br> Inactive</TableCell> */}
              <TableCell
                style={{
                  borderRight: "1px solid grey",
                }}
                className={css.tableCell}
                align="center"
              >
                Students
              </TableCell>
              {/* <TableCell className={css.tableCell} align="left">
                  Male
                </TableCell>
                <TableCell className={css.tableCell} align="left">
                  Female
                </TableCell> */}
              <TableCell className={css.tableCell} align="center">
                Sections
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {overviewDetails.map((overview, i) => (
              <TableRow key={overview.className}>
                <TableCell
                  style={{ borderRight: "1px solid grey" }}
                  align="center"
                >
                  {overview.className}
                </TableCell>

                <TableCell
                  sx={{ borderRight: "1px solid grey", padding: 0 }}
                  align="center"
                >
                  <Box
                    sx={{
                      //width: "100px",
                      marginTop: "52px",
                      display: "flex",
                      rowGap: "2px",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingBottom: "10px",
                    }}
                  >
                    <StudentCount
                      showTitle={true}
                      title="Male"
                      count={overview.maleStudents}
                    />
                    <Divider
                      style={{ borderBottomColor: "grey" }}
                      width="100%"
                    />
                    <StudentCount
                      showTitle={true}
                      title="Female"
                      count={overview.femaleStudents}
                    />
                    <Divider
                      style={{ borderBottomColor: "grey" }}
                      width="100%"
                    />
                    <StudentCount
                      showTitle={true}
                      title="Total"
                      count={overview.totalStudents}
                    />
                  </Box>
                </TableCell>

                <TableCell align="left">
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead className={css.tableHeader}>
                        <TableRow>
                          {overview.sections
                            .sort((a, b) => a.section.localeCompare(b.section)) // Sort sections alphabetically
                            .map((section) => (
                              <TableCell
                                className={css.tableCell}
                                align="left"
                                key={section.section}
                              >
                                {section.section}
                              </TableCell>
                            ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          {overview.sections
                            .sort((a, b) => a.section.localeCompare(b.section)) // Sort sections alphabetically
                            .map((section) => (
                              <TableCell align="left" key={section.section}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    rowGap: "2px",
                                    flexDirection: "column",
                                  }}
                                >
                                  <StudentCount
                                    title="Male"
                                    count={section.maleCount}
                                  />
                                  <Divider style={{ background: "grey" }} />
                                  <StudentCount
                                    title="Female"
                                    count={section.femaleCount}
                                  />
                                  <Divider style={{ background: "grey" }} />
                                  <StudentCount
                                    title="Total"
                                    count={
                                      section.maleCount + section.femaleCount
                                    }
                                  />
                                </Box>
                                {/* <Stack direction={"column"}>
                                    <Box>{section.maleCount} M</Box>
                                    <Box>{section.femaleCount} F</Box>
                                    <Box>
                                      Total :{" "}
                                      {section.maleCount + section.femaleCount}
                                    </Box>
                                  </Stack> */}
                              </TableCell>
                            ))}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Wrapper>
  );
};

export default Overview;
