import { Box, Card, Typography } from "@material-ui/core";
import React from "react";
import SettingSelector from "./SettingSelector";
import { Container } from "@mui/material";

export default function SectionSubHeader({
  title = "Title",
  style = {},
  showSelector = true,
}) {
  return (
    <Card style={{ padding: 10, ...style }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", sm: "center" },
          justifyContent: "space-between",
          alignItems: "center",
          // border:"solid red",
        }}
        spacing={2}
      >
        <Typography
          style={{
            color: "red",
            fontFamily: "cursive",
            display: "inline-block",
          }}
        >
          {title} {title ? "" : ""}
        </Typography>
        {showSelector ? <SettingSelector /> : null}
      </Box>
    </Card>
  );
}
