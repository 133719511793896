import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Dialog,
  Fab,
  Grid,
  styled,
  TextareaAutosize,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";
import { urls as url } from "../../../services/urlConstant";
import { get } from "../../../services/apis";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import { useTheme } from "@mui/material/styles";
const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
};
// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 800,
//   height: 250,
//   bgcolor: "background.paper",
//   p: 4,
// };

const ContainerBox = styled(Box)(({ theme }) => ({
  // position: "absolute",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  width: "50%",
  // maxWidth: "600px",
  // maxHeight: "650px",
  borderRadius: "5px",
  // height: "auto",
  // overflow:"scroll",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",
  // [theme.breakpoints.down("md")]: {
  //   overflow: "auto",
  //   width: "90%",
  // },
  // [theme.breakpoints.between(819, 821)]: {
  //   overflow: "auto",
  //   width: "100%",
  //   height: "100%",
  // },
  // [theme.breakpoints.between(767, 769)]: {
  //   overflow: "auto",
  //   width: "100%",
  //   height: "100%",
  // },
  // [theme.breakpoints.between(911, 913)]: {
  //   overflow: "auto",
  //   width: "100%",
  //   height: "100%",
  // },
  // [theme.breakpoints.between(1023, 1025)]: {
  //   overflow: "auto",
  //   width: "100%",
  //   height: "100%",
  // },
  // [theme.breakpoints.between(1279, 1281)]: {
  //   overflow: "auto",
  //   width: "100%",
  //   height: "100%",
  // },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const AddUserRole = (props) => {
  const { selectedSetting } = React.useContext(SettingContext);
  const [userRole, setUserRole] = useState([]);
  const [orderSequence, setOrderSequence] = useState(0);
  const [roleName, setRoleName] = useState("");
  const [userNote, setUserNote] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  useEffect(() => {
    if (props.updateUserRole) {
      setRoleName(props.updateUserRole.roleName);
      setOrderSequence(
        props.updateUserRole.orderSequence
          ? props.updateUserRole.orderSequence
          : ""
      );
      setUserNote(props.updateUserRole.userNote);
    }
  }, [props.updateUserRole]);

  useEffect(() => {
    const role = async () => {
      try {
        const res = await get(urls.role.getAllRole);
        setUserRole(res.data.data);
      } catch (error) {}
    };
    role();
  }, []);

  const handleRoleName = (e) => {
    setRoleName(e.target.value);
  };
  const handleOrderSequence = (e) => {
    setOrderSequence(e.target.value);
  };
  const handleUserNote = (e) => {
    setUserNote(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (props.updateUserRole) {
      props.handleUpdate(
        {
          roleName,
          orderSequence,
          // userDefault,
          userNote,
        },
        props.updateUserRole._id
      );
    } else {
      props.handleSubmit(e, {
        roleName,
        orderSequence,
        // userDefault,
        userNote,
      });
    }
    setRoleName("");
    setOrderSequence();
    setUserNote("");
    props.handleClose();
  };

  const handleCloseModal = () => {
    setRoleName("");
    setOrderSequence();
    setUserNote("");
    props.handleClose();
  };

  const handleClose = () => {
    setRoleName("");
    setOrderSequence();
    setUserNote("");
    props.handleClose();
  };
  return (
    <div>
      <Tooltip title="Add" disableInteractive>
        <Fab
          size="medium"
          style={{ background: "#1b3779", color: "#fff" }}
          aria-label="add"
          onClick={props.handleOpen}
        >
          <Add />
        </Fab>
      </Tooltip>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form className="form-tag" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  textAlign="center"
                  fontWeight="bold"
                >
                  Add Role
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  required
                  id="filled-required"
                  size="small"
                  label="Role Name"
                  value={roleName}
                  onChange={handleRoleName}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  required
                  type="number"
                  id="filled-required"
                  size="small"
                  label="Order Sequence"
                  value={orderSequence}
                  onChange={handleOrderSequence}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: {
                      md: "right",
                      sm: "right",
                      xs: "center",
                    },
                  }}
                >
                  <Tooltip title="Cancel">
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      size="medium"
                      type="button"
                      color="error"
                      sx={{
                        m: 1,
                        color: "#fff",
                        // borderColor: "rgb(27, 55, 121)",
                      }}
                    >
                      Cancel
                    </Button>
                  </Tooltip>
                  <Tooltip title="Submit">
                    <LoadingButton
                      loading={props.loader}
                      disabled={!roleName}
                      // variant="contained"
                      size="medium"
                      type="submit"
                      style={{
                        background: "#1b3779",
                        color: "#fff",
                      }}
                    >
                      Submit
                    </LoadingButton>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Dialog>
    </div>
  );
};

export default AddUserRole;
