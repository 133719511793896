import React, { useContext, useEffect } from "react";

// style
import css from "../../../styles/fees/QuickFeeCollection.module.css";
import HeaderCard from "../../ui/HeaderCard";
import { useState } from "react";
import { get, post } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Delete } from "@mui/icons-material";
import QuickFeeConcessionModal from "./components/QuickFeeConcessionModal";
import QuickFeeCollectModal from "./components/QuickFeeCollectModal";
import { instance } from "../../../services/axios.Config";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../SectionSubHeader";
import DownloadForOfflineSharpIcon from "@mui/icons-material/DownloadForOfflineSharp";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
const styles = {
  card: {
    padding: 10,
    margin: "0px 0px 20px 0px",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
    // marginTop: "0.3rem",
  },
};

const DetailedFeeCollection = () => {
  const { selectedSetting } = useContext(SettingContext);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [formData, setFormData] = useState({});
  const [students, setStudents] = useState([]);
  const [selectStudent, setSelectStudent] = useState(null);
  const [feeReceipt, setFeeReceipt] = useState(null);
  const [allPastDue, setAllPastDue] = useState([]);
  const [itemDetails, setItemDetails] = useState([]);
  const [concessionDialog, setConcessionDialog] = useState(false);
  const [concession, setConcession] = useState(null);
  const [collectFeeModal, setCollectFeeModal] = useState(false);
  const [feeCollect, setFeeCollect] = useState(null);
  const [receiptBooks, setReceiptBooks] = useState([]);
  const [studentCategories, setStudentCategories] = useState([]);
  const [selectCategory, setSelectCategory] = useState("");
  const [categoryFeesList, setCategoryFeesList] = useState([]);
  const [collectingFee, setCollectingFee] = useState(false);
  const [downloadingPreview, setDownloadingPreview] = useState(false);
  const options = ["Miscellaneous", "Penalty"];
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  // get all classes
  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        setClasses(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, [selectedSetting._id]);

  // get all receiptBookItems
  useEffect(() => {
    const getReceiptsBooks = async () => {
      try {
        const { data: receiptsList, status } = await get(
          `${urls.recepitBook.getAllActiveReceipt}`
        );
        if (status > 199 && status < 299) {
          setReceiptBooks(receiptsList.data);
        }
      } catch (error) {}
    };
    getReceiptsBooks();
  }, []);

  // get all students
  useEffect(() => {
    const getStudents = async () => {
      if (!formData.receiptBookItem) return;

      let selectedReceiptBookItem = receiptBooks.filter(
        (r) => r._id == formData.receiptBookItem
      )[0].name;

      let searchParams = {
        "academicInfo.class": formData.class,
        "academicInfo.section": formData.section,
      };

      if (selectedReceiptBookItem.toLowerCase() === "hostel fee") {
        searchParams["otherInfo.hostelMember"] = "yes";
      } else if (selectedReceiptBookItem.toLowerCase() === "transport fee") {
        searchParams["otherInfo.transportMember"] = "yes";
      } else if (selectedReceiptBookItem.toLowerCase() === "library fee") {
        searchParams["otherInfo.libraryMember"] = "yes";
      }

      try {
        if (formData.class && formData.section) {
          const { data: students, status } = await get(
            `${urls.students.getAllStudent}`,
            {
              params: {
                search: searchParams,
              },
            }
          );
          if (status > 199 && status < 299) {
            setSelectStudent(null);
            setStudents(students.data);
          }
        }
      } catch (error) {}
    };
    getStudents();
  }, [formData, selectedSetting._id]);

  const handleClasses = async (e) => {
    try {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
      const res = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: value,
          },
        },
      });
      setSections(res.data.data);
      setFormData((prev) => ({
        ...prev,
        section: "",
      }));
    } catch (error) {
      console.log(error);
    }
  };
  const handleSections = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleStudentSelect = (e, val) => {
    setSelectStudent(val);
    setFeeReceipt(null);
    setItemDetails([]);
  };
  const handleSubmitFind = async (e) => {
    e.preventDefault();
    handleSubmitPastDue();
    //  setFeeReceipt(null);

    try {
      const { data: feeReceipt, status } = await get(
        `${urls.feeReceipt.detailFee}`,
        {
          params: {
            classId: formData.class,
            sectionId: formData.section,
            studentId: selectStudent._id,
            receiptBookItem: formData.receiptBookItem,
          },
        }
      );
      if (status > 199 && status < 299) {
        setFeeReceipt(feeReceipt.data);
        console.log(feeReceipt.data.feeCategories, "::::");
        setItemDetails(
          feeReceipt.data.feeCategories.map((d) => ({
            name: d.name,
            amount: d.amount,
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmitPastDue = async () => {
    try {
      const data = await get(`${urls.feeReceipt.allPastDue}`, {
        params: {
          classId: formData.class,
          sectionId: formData.section,
          studentId: selectStudent._id,
          receiptBookItem: formData.receiptBookItem,
        },
      });
      setAllPastDue(data.data.data);
      console.log(data.data.data, "setAllPastDue");
    } catch (error) {
      console.log(error);
    }
  };
  const handleAddedRowsChange = (e, changeIndex) => {
    const { name, value } = e.target;
    setItemDetails((prev) =>
      prev.map((itemDetail, index) =>
        index === changeIndex
          ? { ...itemDetail, [name]: parseInt(value) }
          : itemDetail
      )
    );
  };
  const handleAddRow = () => {
    setItemDetails((prev) => [
      ...prev,
      {
        name: "",
        amount: 0,
      },
    ]);
  };
  const handleRemoveAddedRow = (removeIndex) => {
    setItemDetails((prev) =>
      prev.filter((ele, index) => removeIndex !== index)
    );
  };
  const handleConcessionDialogClose = () => {
    setConcessionDialog(false);
  };
  const handleConcessionDialogOpen = () => {
    setConcessionDialog(true);
  };
  const handleCollectFeeDialogClose = () => {
    setCollectFeeModal(false);
  };
  const handleCollectFeeDialogOpen = () => {
    setCollectFeeModal(true);
  };
  const handleConcessionSubmit = (data) => {
    setConcession(data);
    handleConcessionDialogClose();
  };

  const handleFeeCollect = async (submitData, paymentDetails) => {
    setCollectingFee(true);
    const data = {
      studentId: selectStudent._id,
      classId: formData.class,
      sectionId: formData.section,
      paymentMode: submitData.payment,
      itemDetails: [
        ...categoryFeesList,
        ...itemDetails
          .map((i) => ({ ...i, amount: Number(i.amount) }))
          .filter((i) => i.name && i.amount),
      ],
      concessionDetails: concession
        ? {
            amount: concession.concession,
            referredBy: concession.refer,
            givenAs: concession.format,
          }
        : {},
      receiptItem: formData.receiptBookItem,
    };
    if (submitData.payment === "Cash") {
      data.cashDetails = {
        note: submitData.note,
        date: submitData.date,
      };
    }
    if (submitData.payment === "Cheque") {
      data.chequeDetails = {
        bankName: paymentDetails.bankName,
        branchName: paymentDetails.branchName,
        chequeNumber: paymentDetails.chequeNumber,
        chequeDate: paymentDetails.chequeDate,
      };
    }
    if (submitData.payment === "DD") {
      data.ddDetails = {
        bankName: paymentDetails.bankName,
        branchName: paymentDetails.branchName,
      };
    }
    if (submitData.payment === "Upi") {
      data.upiDetails = {
        bankName: paymentDetails.bankName,
        refNumber: paymentDetails.refNumber,
        paidByName: paymentDetails.paidByName,
      };
    }
    if (submitData.payment === "Card") {
      data.cardDetails = {
        bankName: paymentDetails.bankName,
        cardType: paymentDetails.cardType,
      };
    }
    if (submitData.payment === "Netbanking") {
      data.netBankingDetails = {
        bankName: paymentDetails.bankName,
        refNumber: paymentDetails.refNumber,
        paidByName: paymentDetails.paidByName,
      };
    }
    try {
      const response = await instance({
        method: "post",
        url: `${urls.feeReceipt.create}`,
        data,
        responseType: "blob",
      });

      if (response) {
        if (response.status > 199 && response.status < 299) {
          const uri = URL.createObjectURL(response.data);
          window.open(uri, "__blank");
          toast.success("Fees collected successfully");
          setFormData({});
          setSelectStudent(null);
          setFeeReceipt(null);
          setItemDetails([]);
          setConcessionDialog(false);
          setConcession(null);
          setCollectFeeModal(false);
        }
      } else {
        const response = await instance({
          method: "post",
          url: `${urls.feeReceipt.create}`,
          data,
        });
      }
    } catch (error) {
      let jsonBlob = new Blob([error.response.data], {
        type: "application/json",
      });
      const jsonErr = JSON.parse(await jsonBlob.text());
      if (jsonErr.message === "jwt expired") {
        toast.error("Session expired! Please login again!");
        setDownloadingPreview(false);
        window.location.href = "/login";
        return;
      }
      toast.error(jsonErr.message);
    }
    setCollectingFee(false);
  };

  const handlePreviewButtonClick = async (submitData, paymentDetails) => {
    console.log("submitData", submitData);
    setDownloadingPreview(true);
    const data = {
      studentId: selectStudent._id,
      classId: formData.class,
      sectionId: formData.section,
      paymentMode: submitData.payment,
      itemDetails: [
        ...categoryFeesList,
        ...itemDetails
          .map((i) => ({ ...i, amount: Number(i.amount) }))
          .filter((i) => i.name && i.amount),
      ],
      concessionDetails: concession
        ? {
            amount: concession.concession,
            referredBy: concession.refer,
            givenAs: concession.format,
          }
        : {},
      receiptItem: formData.receiptBookItem,
    };
    if (submitData.payment === "Cash") {
      data.cashDetails = {
        note: submitData.note,
        date: submitData.date,
      };
    }
    if (submitData.payment === "Cheque") {
      data.chequeDetails = {
        bankName: paymentDetails.bankName,
        branchName: paymentDetails.branchName,
        chequeNumber: paymentDetails.chequeNumber,
        chequeDate: paymentDetails.chequeDate,
      };
    }
    if (submitData.payment === "DD") {
      data.ddDetails = {
        bankName: paymentDetails.bankName,
        branchName: paymentDetails.branchName,
      };
    }
    if (submitData.payment === "Upi") {
      data.upiDetails = {
        bankName: paymentDetails.bankName,
        refNumber: paymentDetails.refNumber,
        paidByName: paymentDetails.paidByName,
      };
    }
    if (submitData.payment === "Card") {
      data.cardDetails = {
        bankName: paymentDetails.bankName,
        cardType: paymentDetails.cardType,
      };
    }
    if (submitData.payment === "Netbanking") {
      data.netBankingDetails = {
        bankName: paymentDetails.bankName,
        refNumber: paymentDetails.refNumber,
        paidByName: paymentDetails.paidByName,
      };
    }
    try {
      const response = await instance({
        method: "post",
        url: `${urls.feeReceipt.preview}`,
        data,
        responseType: "blob",
      });
      if (response) {
        if (response.status > 199 && response.status < 299) {
          const uri = URL.createObjectURL(response.data);
          window.open(uri, "__blank");
        }
      } else {
        const response = await instance({
          method: "post",
          url: `${urls.feeReceipt.preview}`,
          data,
        });
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        let jsonBlob = new Blob([error.response.data], {
          type: "application/json",
        });
        const jsonErr = JSON.parse(await jsonBlob.text());
        if (jsonErr.message === "jwt expired") {
          toast.error("Session expired! Please login again!");
          setDownloadingPreview(false);
          window.location.href = "/login";
          return;
        }
        toast.error(jsonErr.message);
      } else {
        toast.error(error);
      }
    }
    setDownloadingPreview(false);
  };

  const handleRowChange = (index, newValue) => {
    const updatedData = [...itemDetails];
    updatedData[index].name = newValue;
    setItemDetails(updatedData);
  };
  const handlePastdue = async (e, id) => {
    // e.preventDefault;
    try {
      const getPrint = await get(
        `/${selectedSetting._id}/fee-receipt/download/pastdue?receiptId=${formData.receiptBookItem}&classId=${formData.class}&section=${formData.section}&studentId=${selectStudent._id}&receiptId=${id}`,
        {
          responseType: "blob",
        }
      );
      const uri = URL.createObjectURL(getPrint.data);
      window.open(uri, "__blank");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box p={"1rem"}>
      <SectionSubHeader title="Collect Fees" />
      <div>
        <Card sx={{ mt: 3 }}>
          <Box sx={{ padding: "10px 0" }}>
            <form onSubmit={handleSubmitFind}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" sx={styles.textfield} required>
                    <InputLabel id="demo-simple-select-filled-label">
                      Receipt
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="receiptBookItem"
                      label="Receipt"
                      required
                      value={formData.receiptBookItem || ""}
                      onChange={(event) => handleSections(event)}
                    >
                      {receiptBooks &&
                        receiptBooks.map((receiptBook, index) => (
                          <MenuItem
                            key={receiptBook._id}
                            value={receiptBook._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {receiptBook.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" sx={styles.textfield} required>
                    <InputLabel id="demo-simple-select-filled-label">
                      Class
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="class"
                      label="Class"
                      required
                      value={formData.class || ""}
                      onChange={(event) => handleClasses(event)}
                    >
                      {classes &&
                        classes.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.className}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" sx={styles.textfield} required>
                    <InputLabel id="demo-simple-select-filled-label">
                      Section
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="section"
                      label="Section"
                      value={formData.section || ""}
                      required
                      onChange={(event) => handleSections(event)}
                    >
                      {sections &&
                        sections.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.sectionName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Autocomplete
                    size="small"
                    value={selectStudent}
                    sx={styles.textfield}
                    onChange={handleStudentSelect}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value._id
                    }
                    options={students}
                    getOptionLabel={(option) =>
                      `${option.basicInfo.name} | ${option.academicInfo.admissionNo} | ${option.academicInfo.rollNo}`
                    }
                    renderInput={(params) => (
                      <TextField
                        required
                        size="small"
                        {...params}
                        label="Students"
                        placeholder="Name | Admission no | Roll no"
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  alignSelf="center"
                  display="flex"
                  justifyContent={{
                    xs: "center",
                    sm: "center",
                    md: "flex-end",
                    lg: "flex-end",
                  }}
                >
                  <Button
                    style={styles.Button}
                    size="small"
                    type="submit"
                    variant="contained"
                  >
                    find
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Card>
        {selectStudent && feeReceipt && (
          <div>
            <Card
              sx={{ padding: "10px", margin: "10px 0" }}
              className={css.dueInfoContainer}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box className={css.dueInfoContainerFlex}>
                  <div>
                    <Typography fontSize="20px">
                      <b>{selectStudent?.basicInfo?.name}</b>
                    </Typography>
                    <Typography fontSize="15px">
                      <b> Payment Method :</b>
                      {feeReceipt?.paymentStyle}
                    </Typography>
                    {feeReceipt?.paymentStyle === "Installments" ? (
                      <Box
                        sx={{
                          border: "1px solid lightgray",
                          padding: "5px",
                          borderRadius: "5px",
                        }}
                      >
                        <Typography fontSize="15px">
                          <b> Current Installment Details: </b>
                        </Typography>
                        <Typography fontSize="15px">
                          <b>Installment:</b>{" "}
                          {feeReceipt?.installMentToBePaid?.priority}
                        </Typography>
                        <Typography fontSize="15px">
                          <b>Amount:</b>{" "}
                          {feeReceipt?.installMentToBePaid?.amount}
                        </Typography>
                      </Box>
                    ) : null}
                  </div>
                </Box>
                <Box>
                  <div>
                    <b>Total Due -</b> ₹ <span>{feeReceipt.dueAmount}</span>
                  </div>
                  <div>
                    <b>Current Due -</b> ₹ <span>{feeReceipt?.currentDue}</span>
                  </div>
                  <div>
                    <b>Past Due -</b> ₹ <span>{feeReceipt.previousDue}</span>
                  </div>
                </Box>
              </div>
            </Card>
            <Card
              sx={{ padding: "10px", margin: "10px 0" }}
              className={css.dueInfoContainer}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {allPastDue.map((data) => (
                  <div
                    key={data._id}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginRight: "10px",
                    }}
                  >
                    <Button onClick={(e) => handlePastdue(e, data._id)}>
                      <DownloadForOfflineSharpIcon />
                    </Button>
                    <p style={{ fontSize: 13 }}>
                      {" "}
                      {dayjs(data.createdAt).format("DD-MM-YYYY")}
                    </p>
                  </div>
                ))}
              </div>
            </Card>

            <div className={css.tableContainer}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead sx={{ backgroundColor: "#1b3779" }}>
                    <TableRow>
                      <TableCell sx={{ color: "white" }} align="center">
                        #SL
                      </TableCell>
                      <TableCell sx={{ color: "white" }} align="center">
                        Category
                      </TableCell>
                      <TableCell sx={{ color: "white" }} align="center">
                        Amount
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {categoryFeesList.map((categoryFeesItem, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {categoryFeesItem.name}
                        </TableCell>
                        <TableCell align="center">
                          {categoryFeesItem.amount}
                        </TableCell>
                        <TableCell align="center"></TableCell>
                      </TableRow> 
                    ))} */}
                    {itemDetails.map((itemDetail, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          <Autocomplete
                            disabled={
                              (itemDetail.name &&
                                itemDetail.name === "Penalty") ||
                              (itemDetail.name &&
                                itemDetail.name === "Miscellaneous")
                                ? true
                                : false
                            }
                            value={itemDetail.name || ""}
                            onChange={(event, newValue) => {
                              handleRowChange(index, newValue);
                            }}
                            options={options}
                            freeSolo
                            renderInput={(params) => (
                              <TextField
                                value={itemDetail.name || ""}
                                onChange={(event) => {
                                  handleRowChange(index, event.target.value);
                                }}
                                placeholder="Type here.."
                                {...params}
                                size="small"
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            size="small"
                            type="number"
                            value={itemDetail.amount || "0"}
                            name="amount"
                            onChange={(e) => handleAddedRowsChange(e, index)}
                            placeholder="Amount"
                            InputProps={{
                              startAdornment: <CurrencyRupeeIcon />,
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => handleRemoveAddedRow(index)}
                          >
                            <Delete />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <div className={css.addRowBtn}>
                  <div>
                    <Button
                      onClick={handleAddRow}
                      variant="contained"
                      size="small"
                    >
                      add row
                    </Button>
                  </div>
                  <div className={css.addRowBtn_childFlex}>
                    <div>
                      <Button
                        onClick={handleConcessionDialogOpen}
                        variant="contained"
                        size="small"
                      >
                        add concession
                      </Button>
                      <div className={css.concessionText}>
                        {concession
                          ? concession?.format === "Value"
                            ? `Concession:- ₹${concession?.concession}`
                            : `Concession:- ${concession?.concession}%`
                          : ""}
                      </div>
                    </div>
                    <Button
                      onClick={handleCollectFeeDialogOpen}
                      variant="contained"
                      size="small"
                    >
                      collect fee
                    </Button>
                  </div>
                </div>
              </TableContainer>
            </div>
          </div>
        )}
        <QuickFeeConcessionModal
          onSubmit={handleConcessionSubmit}
          open={concessionDialog}
          onClose={handleConcessionDialogClose}
          opener={handleConcessionDialogOpen}
        />
        <QuickFeeCollectModal
          currentDue={feeReceipt?.currentDue}
          collectingFee={collectingFee}
          downloadingPreview={downloadingPreview}
          onPreviewButtonClick={handlePreviewButtonClick}
          feeReceipt={feeReceipt}
          onSubmit={handleFeeCollect}
          open={collectFeeModal}
          onClose={handleCollectFeeDialogClose}
        />
      </div>
    </Box>
  );
};

export default DetailedFeeCollection;
