import React, { useContext, useEffect, useState } from "react";
import css from "../../../styles/fees/ReceiptBook.module.css";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import ClassMap from "./ClassMap";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../SectionSubHeader";
import { useTheme } from "@mui/material/styles";

const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const ReceiptBook = () => {
  const { selectedSetting } = useContext(SettingContext);
  const [tabValue, setTabValue] = useState(0);
  const [addDialog, setAddDialog] = useState(false);
  const [name, setName] = useState("");
  const [receipts, setReceipts] = useState([]);
  const [isEdit, setIsEdit] = useState(null);
  const [selectedClassMap, setSelectedClassMap] = useState();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const handleClassMap = (id) => {
    setSelectedClassMap(id);
    setTabValue(1);
  };

  useEffect(() => {
    const init = async () => {
      try {
        await getAllReceipts();
      } catch (error) {
        console.log(error);
      }
    };
    init();
  }, []);
  const getAllReceipts = async () => {
    try {
      const { data: allReceipts, status } = await get(
        `${urls.recepitBook.getAll}`
      );
      if (status > 199 && status < 299) {
        setReceipts(allReceipts.data);
        return {
          data: allReceipts,
          status,
        };
      }
    } catch (error) {
      return error;
    }
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleCloseAddDialog = () => {
    setName("");
    setIsEdit(null);
    setAddDialog(false);
  };
  const handleOpenAddDialog = () => {
    setAddDialog(true);
  };
  const handleAddNewReceipt = async () => {
    try {
      if (!isEdit) {
        const { status } = await post(`${urls.recepitBook.create}`, {
          name,
        });
        if (status > 199 && status < 299) {
          await getAllReceipts();
          handleCloseAddDialog();
        }
      } else {
        const { status } = await put(
          `${urls.recepitBook.update}${isEdit}`,
          "",
          {
            name,
          }
        );
        if (status > 199 && status < 299) {
          await getAllReceipts();
          handleCloseAddDialog();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleEdit = async (id, name) => {
    setIsEdit(id);
    setName(name);
    handleOpenAddDialog();
  };

  const handleReceiptStatus = async (id) => {
    try {
      const { status } = await put(`${urls.recepitBook.status}${id}`, "");
      if (status > 199 && status < 299) {
        await getAllReceipts();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={css.container}>
      <SectionSubHeader title="Add Class Map" />
      <Card sx={{ mt: 3 }}>
        <Box>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="RECEIPT BOOK" {...a11yProps(0)} />
            <Tab label="CLASS MAP" {...a11yProps(1)} />
          </Tabs>
        </Box>
      </Card>
      <TabPanel value={tabValue} index={0}>
        <div className={css.btnContainer}>
          <Button onClick={handleOpenAddDialog} variant="contained">
            <Add /> Add receipt
          </Button>
        </div>
        <div className={css.tableContainer}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
                <TableRow sx={{ color: "white" }}>
                  <TableCell sx={{ color: "white" }} align="center">
                    #SL
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Receipt Name
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {receipts.map((receipt, index) => (
                  <TableRow
                    key={receipt._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{receipt.name}</TableCell>
                    <TableCell align="center">
                      <div>
                        <div className={css.actionContainer}>
                          <Button
                            onClick={() =>
                              handleEdit(receipt._id, receipt.name)
                            }
                            size="small"
                            variant="contained"
                          >
                            edit
                          </Button>
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() => handleClassMap(receipt._id)}
                          >
                            Class map
                          </Button>
                          <Button
                            color={receipt.activated ? "success" : "error"}
                            onClick={() => handleReceiptStatus(receipt._id)}
                            size="small"
                            variant="contained"
                          >
                            {receipt.activated ? "active" : "inactive"}
                          </Button>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Dialog
          fullScreen={fullScreen}
          open={addDialog}
          onClose={handleCloseAddDialog}
        >
          <Box sx={style}>
            <DialogTitle sx={{ fontSize: "20px", fontWeight: "bold" }}>
              {isEdit ? "Update Receipt Book" : "Add New Receipt Book"}
            </DialogTitle>
            <DialogContent>
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoFocus
                margin="dense"
                label="Receipt Book"
                type="text"
                fullWidth
                variant="standard"
              />
            </DialogContent>
            <DialogActions
              sx={{
                justifyContent: "center",
                width: "100%",
                alignItems: "center",
                display: "flex",
                columnGap: "10px",
              }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={handleCloseAddDialog}
                sx={{ color: "#fff" }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleAddNewReceipt}
              >
                {isEdit ? "update" : "Add"}
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <ClassMap selectedClassMap={selectedClassMap} />
      </TabPanel>
    </div>
  );
};
export default ReceiptBook;
