import React from "react";
import css from "../../styles/Dashboard/Dashboard.module.css";
import { useState, useEffect, useContext } from "react";
import { get } from "../../services/apis";
import dayjs from "dayjs";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { blue, red } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { urls as url } from "../../services/urlConstant";
import appendStringToUrls from "../../utils/appendString";
import SettingContext from "../../context/SettingsContext";

const Notice = () => {
  const [announceNotice, setAnounceNotice] = useState([]);
  const [expanded, setExpanded] = React.useState(false);
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  useEffect(() => {
    const getNotice = async () => {
      try {
        const response = await get(`${urls.studentDashboard.getnotice}`, {
          params: {
            search: {
              webView: true,
            },
          },
        });
        setAnounceNotice([...response.data.data]);
        console.log(response, "hhhhhhhhhhh");
      } catch (error) {
        console.log(error);
      }
    };
    getNotice();
  }, [selectedSetting]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));
  return (
    <div className={css.noticescoll}>
      <div>
        <Box sx={{ minWidth: 200, textAlign: "start" }} className={css.card}>
          {announceNotice.map((news) => (
            // <>
            //   <CardContent>
            //     <Typography sx={{ fontSize: 14, mb: 1 }}>
            //       <b>{news.title}</b>
            //     </Typography>
            //     <Typography sx={{ fontSize: 14, mb: 1 }}>
            //       <p>{news.notice} </p>
            //     </Typography>
            //     <Typography sx={{ fontSize: 12, mb: 1 }}>
            //       <div style={{ display: "flex" }}>
            //         <b>Date:</b>
            //         <b>{dayjs(news.date).format("DD/MM/YYYY")}</b>
            //       </div>
            //     </Typography>
            //   </CardContent>
            // </>
            <>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                    N
                  </Avatar>
                }
                action={
                  <CardActions disableSpacing>
                    <ExpandMore
                      expand={expanded}
                      onClick={handleExpandClick}
                      aria-expanded={expanded}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  </CardActions>
                }
                sx={{
                  textAlign: "center",
                  fontSize: "20px",
                  fontWeight: "bold",
                  fontFamily: "cursive",
                }}
                title={news.title}
              />

              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                  <CardMedia
                  // component="img"
                  // height="194"
                  // image=""
                  // alt="no image"
                  />
                  <Typography
                    paragraph
                    sx={{
                      textAlign: "left",
                      fontSize: 15,
                      fontFamily: "cursive",
                      color: "#607d8b",
                    }}
                  >
                    {news.notice}
                  </Typography>
                  <Typography style={{ display: "flex", textAlign: "left" }}>
                    <b>{dayjs(news.date).format("DD/MM/YYYY")}</b>
                  </Typography>
                </CardContent>
              </Collapse>
            </>
          ))}
        </Box>
      </div>
    </div>
  );
};

export default Notice;
