import {
  Box,
  Avatar,
  Button,
  CircularProgress,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  Modal,
  Popper,
  Icon,
  InputBase,
  Input,
  Stack,
  Dialog,
  useMediaQuery,
} from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import React from "react";

import { objHas } from "../../../utils/utils";

import { useEffect, useState, useContext } from "react";

import { get } from "../../../services/apis";

import { urls as url } from "../../../services/urlConstant";

import Autocomplete from "@mui/material/Autocomplete";

import Dayjs from "dayjs";

import { LoadingButton } from "@mui/lab";

import SettingContext from "../../../context/SettingsContext";

import appendStringToUrls from "../../../utils/appendString";

import SectionSubHeader from "../../SectionSubHeader";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import CheckBoxIcon from "@mui/icons-material/CheckBox";

import dayjs from "dayjs";

import "../../../styles/components/Certificate/StudyCertificate.scss";

import { Checkbox, Chip } from "@material-ui/core";

import ListItemText from "@mui/material/ListItemText";

import styled from "@emotion/styled";
import { useTheme } from "@mui/material/styles";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

const checkedIcon = <CheckBoxIcon fontSize="small" />;

const style = {
  card: {
    padding: 10,
    width: "100%",
    marginBottom: "20px",
    marginTop: 20,
  },
  textfield: {
    width: "100%",
    label: { fontSize: 50 },
    mt: 1,
  },
  text: {
    textAlign: "center",
    fontSize: "50px",
  },
};

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 10,

  width: "100%",

  borderBottom: `1px solid ${
    theme.palette.mode === "light" ? "#eaecef" : "#30363d"
  }`,

  "& input": {
    borderRadius: 4,

    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#0d1117",

    padding: 8,

    transition: theme.transitions.create(["border-color", "box-shadow"]),

    border: `1px solid ${
      theme.palette.mode === "light" ? "#eaecef" : "#30363d"
    }`,

    fontSize: 14,

    "&:focus": {
      boxShadow: `0px 0px 0px 3px ${
        theme.palette.mode === "light"
          ? "rgba(3, 102, 214, 0.3)"
          : "rgb(12, 45, 107)"
      }`,

      borderColor: theme.palette.mode === "light" ? "#0366d6" : "#388bfd",
    },
  },
}));

const modal = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
};

const StudyCertificate = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { selectedSetting } = useContext(SettingContext);

  const [studyCertificateDetails, setStudyCertificateDetails] = useState({});

  const [academicYears, setAcademicYears] = useState([]);

  const [studentsSC, setStudentsSC] = useState([]);

  const [fromDate, setFromDate] = useState(Dayjs(Date.now()));

  const [toDate, setToDate] = useState(Dayjs(Date.now()));

  const [studentSelect, setStudentSelect] = useState(undefined);

  const [loading, setLoading] = useState(false);

  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const [settings, setSettings] = useState({});

  const [bulkIssue, setBulkIssue] = useState(false);

  const [classes, setClasses] = useState([]);

  const [sections, setSections] = useState([]);

  const [names, setNames] = useState([]);

  const [selectedStudents, setSelectedStudents] = useState([]);

  const [namePopper, setNamePopper] = useState(false);

  const [certificatePdfDownloading, setCertificatePdfDownloading] =
    useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const [formData, setFormData] = useState({});

  const [currentDate, setCurrentDate] = useState(
    new Date().toISOString().substr(0, 10)
  );

  useEffect(() => {
    const getSettings = async () => {
      try {
        const settings = await get(urls.settings.getAllSettings);

        setSettings(settings.data.data[0]);

        console.log(settings.data.data[0], "fffffffffffff");

        const data = settings.data.data[0];

        setFormData({
          ...formData,

          schoolName: data.schoolName,
        });
      } catch (error) {
        console.log(error);
      }
    };

    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll);

        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    getAcademicYears();

    getSettings();
  }, [selectedSetting]);

  const handleAcademicYears = async (e) => {
    try {
      const { name, value } = e.target;

      setStudyCertificateDetails({ ...studyCertificateDetails, [name]: value });

      const res = await get(urls.students.getAllStudent, {
        params: {
          search: {
            academicYear: value,
          },
        },
      });

      setStudentsSC(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setStudentSelect(null);

    const getStudents = async () => {
      try {
        const res = await get(urls.students.getAllStudent);

        setStudentsSC(res.data.data);

        console.log(res.data, "ppppppp");
      } catch (error) {
        console.log(error);
      }
    };

    getStudents();
  }, [selectedSetting._id]);

  const handleStudentSelect = (val, newVal) => {
    console.log(newVal);

    setStudentSelect(newVal);

    console.log(newVal, "newVal");

    setFormData({});
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,

      [name]: value,
    });
  };

  const handleSubmitCertificate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      setFormData({
        ...formData,
        fatherName:
          studentSelect.fatherInfo && studentSelect.fatherInfo.fatherName,
        academicYearFrom:
          studentSelect.academicYear.academicYearFrom &&
          studentSelect.academicYear.academicYearFrom,

        academicYearTo:
          studentSelect.academicYear.academicYearFrom &&
          studentSelect.academicYear.academicYearTo,

        studentName: studentSelect.basicInfo && studentSelect.basicInfo.name,

        currentClass:
          studentSelect.academicInfo &&
          studentSelect.academicInfo.class.className,

        dob: studentSelect.basicInfo && studentSelect.basicInfo.dob,

        grNo: studentSelect.basicInfo && studentSelect.basicInfo.grNo,

        studentPhoto: studentSelect.studentPhoto?.link,
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleSubmitCertificateDownload = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const studyCertificateRes = await get(
        `${urls.certificates.getStudyCertificate}/${fromDate.format(
          "YYYY"
        )}/${toDate.format("YYYY")}/${studentSelect._id}`,

        {
          responseType: "blob",
        }
      );

      const uri = URL.createObjectURL(studyCertificateRes.data);

      // window.open(uri, "__blank");

      const link = document.createElement("a");

      link.href = uri;

      link.setAttribute("download", "studyCertificate.pdf");

      document.body.appendChild(link);

      link.click();

      link.parentNode.removeChild(link);

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleBulkCertificateDownload = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const studyCertificateRes = await get(
        `${urls.certificates.getBulkCertificate}/${fromDate.format(
          "YYYY"
        )}/${toDate.format("YYYY")}?class=${formData.className}&section=${
          formData.section
        }&studentId=${selectedItems}`,
        {
          responseType: "blob",
        }
      );
      const uri = URL.createObjectURL(studyCertificateRes.data);
      // window.open(uri, "__blank");
      const link = document.createElement("a");
      link.href = uri;
      link.setAttribute("download", "studyCertificate.pdf");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleBulkPrint = async (e) => {
    e.preventDefault();
    try {
      const studyCertificateRes = await get(
        `${urls.certificates.getBulkCertificate}/${fromDate.format(
          "YYYY"
        )}/${toDate.format("YYYY")}?class=${formData.className}&section=${
          formData.section
        }&studentId=${selectedItems}`,
        {
          responseType: "blob",
        }
      );
      const uri = URL.createObjectURL(studyCertificateRes.data);
      window.open(uri, "__blank");
    } catch (error) {
      console.log(error);
    }
  };
  const handlePrint = async (e) => {
    e.preventDefault();
    try {
      const studyCertificateRes = await get(
        `${urls.certificates.getStudyCertificate}/${fromDate.format(
          "YYYY"
        )}/${toDate.format("YYYY")}/${studentSelect._id}`,
        {
          responseType: "blob",
        }
      );
      const uri = URL.createObjectURL(studyCertificateRes.data);
      window.open(uri, "__blank");
    } catch (error) {
      console.log(error);
    }
  };

  const styles = (theme) => ({
    multilineColor: {
      fontSize: "50px",

      textAlign: "center",
    },
  });

  const handleClose = () => {
    setBulkIssue(false);
  };

  const handleClickIssue = () => {
    setBulkIssue(true);
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    // Update the formData with the selected academic year
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleClassChange = async (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,

      [name]: value,
    });

    try {
      const section = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: e.target.value,
          },
        },
      });

      if (section.data.data.length > 0) {
        setFormData((prev) => ({
          ...prev,

          section: section.data.data[0]._id,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,

          section: "",
        }));
      }

      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getStudents = async () => {
      console.log(formData, "Formdata");

      if (!formData.className || !formData.section) return;

      let payload = {};
      if (formData.section === "all") {
        payload = {
          academicYear: formData.academicYear,
          "academicInfo.class": formData.className,
        };
      } else {
        payload = {
          academicYear: formData.academicYear,
          "academicInfo.section": formData.section,
          "academicInfo.class": formData.className,
        };
      }
      const searchStudents = await get(urls.students.getAllStudent, {
        params: {
          search: payload,
        },
      });
      console.log(searchStudents, "stdd");
      setNames(searchStudents.data.data);
    };
    getStudents();
  }, [formData.className, formData.section, selectedSetting._id]);

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        let search = res.data.data.length
          ? { class: res.data.data[0]._id }
          : {};
        const resSections = await get(urls.schoolSection.getAllSection, {
          params: {
            search,
          },
        });
        setSections(resSections.data.data);
        setClasses(res.data.data);
        setFormData((prev) => ({
          ...prev,
          className: res.data?.data[0]?._id,
          section: resSections.data?.data[0]?._id,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, [selectedSetting._id]);
  const handleSelectChange = (event) => {
    setSelectedItems(event.target.value);
  };

  return (
    <div>
      <SectionSubHeader title="Study Certificate" />

      <Box m={2}>
        <Card style={style.card}>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <FormControl fullWidth size="small">
                  <InputLabel>Academic Year</InputLabel>

                  <Select
                    label="Academic Year"
                    fullWidth
                    name="academicYear"
                    value={studyCertificateDetails.academicYear}
                    onChange={(event) => handleAcademicYears(event)}
                  >
                    {academicYears &&
                      academicYears.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.academicYearFrom} - {row.academicYearTo}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="From Date"
                    name="fromDate"
                    value={fromDate}
                    inputFormat="DD-MM-YYYY"
                    onChange={(newValue) => {
                      setFromDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        // sx={{ width:{md:227,xs:"100%"}}}

                        size="small"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="To Date"
                    value={toDate}
                    inputFormat="DD-MM-YYYY"
                    onChange={(newValue) => {
                      setToDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        // sx={{ width:{md:227,xs:"100%"}}}

                        size="small"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={3}>
                <Autocomplete
                  size="small"
                  fullWidth
                  value={studentSelect?.basicInfo.name}
                  // inputValue={studentSelect?.basicInfo.name || ""}

                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  getOptionLabel={(option) => option.basicInfo.name}
                  options={studentsSC}
                  onChange={handleStudentSelect}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      value={studentSelect?.basicInfo.name}
                      {...params}
                      label="Students"
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{
                  display: "flex",

                  alignItems: "center",

                  gap: "5px",

                  paddingBottom: "5px",

                  justifyContent: { md: "right", sm: "right", xs: "center" },
                }}
              >
                <Tooltip title="Bulk Issue">
                  <LoadingButton
                    style={{ background: "#1b3779" }}
                    variant="contained"
                    size="small"
                    type="submit"
                    onClick={handleClickIssue}
                  >
                    <span>Bulk Issue</span>
                  </LoadingButton>
                </Tooltip>

                <Tooltip title="Issue">
                  <LoadingButton
                    style={{ background: "#1b3779" }}
                    variant="contained"
                    size="small"
                    type="submit"
                    onClick={handleSubmitCertificate}
                    loading={loading}
                  >
                    <span>Issue</span>
                  </LoadingButton>
                </Tooltip>
              </Grid>
            </Grid>
          </div>
        </Card>

        {!formData?.studentName ? null : (
          <div>
            <Box
              style={{
                width: "100%",

                marginTop: "10px",

                border: "1px solid black",
              }}
            >
              <Grid container spacing={2} style={{ display: "flex" }}>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={3}
                  style={{
                    justifyContent: "left",

                    alignItems: "center",

                    marginTop: "15px",
                  }}
                >
                  <img
                    src={selectedSetting.logo.link}
                    alt="logo"
                    height={110}
                    width={100}
                  />
                </Grid>

                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  style={{ textAlign: "center", marginTop: "15px" }}
                >
                  {" "}
                  <h4>{selectedSetting.schoolName}</h4>
                  <h6>{selectedSetting.address}</h6>
                </Grid>

                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={3}
                  style={{
                    display: "flex",

                    justifyContent: "flex-end",

                    marginTop: "15px",
                  }}
                >
                  <img
                    src={formData.studentPhoto}
                    alt="photo"
                    height={120}
                    width={100}
                  />
                </Grid>
              </Grid>

              <div
                style={{
                  textAlign: "center",

                  fontSize: "30px",

                  fontWeight: "bold",

                  marginTop: "60px",

                  textDecoration: "underline",
                }}
              >
                STUDY CERTIFICATE
              </div>

              <Box sx={{ p: 3, display: "flex", textIndent: "50px" }}>
                <span
                  style={{
                    fontSize: "25px",

                    fontWeight: "200px",

                    fontFamily: " Georgia, sans-serif",
                  }}
                >
                  {" "}
                  This is to certify Mister/Miss son/daughter of Sri{" "}
                  <Typography
                    component={"span"}
                    sx={{
                      fontSize: "25px",

                      padding: "0 5px",

                      display: "inline",

                      fontWeight: "bold",

                      textDecoration: "underline",

                      fontFamily:
                        "Lucida Handwriting, Brush Script MT, cursive",
                    }}
                  >
                    {formData.fatherName || ""}
                  </Typography>{" "}
                  a resident of{" "}
                  <Typography
                    component={"span"}
                    sx={{
                      fontSize: "25px",

                      padding: "0 5px",

                      display: "inline",

                      fontWeight: "bold",

                      textDecoration: "underline",

                      fontFamily:
                        "Lucida Handwriting, Brush Script MT, cursive",
                    }}
                  >
                    {formData.studentName || ""}
                  </Typography>{" "}
                  is a bonafide student of our school. He / She is studying in
                  class
                  <Typography
                    component={"span"}
                    sx={{
                      fontSize: "25px",

                      padding: "0 5px",

                      display: "inline",

                      fontWeight: "bold",

                      textDecoration: "underline",

                      fontFamily:
                        "Lucida Handwriting, Brush Script MT, cursive",
                    }}
                  >
                    {formData.currentClass || ""}
                  </Typography>
                  for the academic year
                  <Typography
                    component={"span"}
                    sx={{
                      fontSize: "25px",
                      padding: "0 5px",
                      display: "inline",
                      fontWeight: "bold",
                      textDecoration: "underline",
                      fontFamily:
                        "Lucida Handwriting, Brush Script MT, cursive",
                    }}
                  >
                    {`${fromDate.format("YYYY")}-${toDate.format("YYYY")}`}
                  </Typography>
                  and His/Her date of birth is
                  <Typography
                    component={"span"}
                    sx={{
                      fontSize: "25px",

                      padding: "0 5px",

                      display: "inline",

                      fontWeight: "bold",

                      textDecoration: "underline",

                      fontFamily:
                        "Lucida Handwriting, Brush Script MT, cursive",
                    }}
                  >
                    {dayjs(formData.dob || "").format("DD-MM-YYYY")}
                  </Typography>
                  as per our school record G R No is{" "}
                  <Typography
                    component={"span"}
                    sx={{
                      fontSize: "25px",

                      padding: "0 5px",

                      display: "inline",

                      fontWeight: "bold",

                      textDecoration: "underline",

                      fontFamily:
                        "Lucida Handwriting, Brush Script MT, cursive",
                    }}
                  >
                    {formData.grNo || ""}
                  </Typography>
                </span>
              </Box>

              <Box
                style={{
                  display: "flex",

                  justifyContent: "space-between",

                  alignItems: "center",
                }}
              >
                <div>
                  <div>
                    <div style={{ width: "100%", marginLeft: "15px" }}>
                      <span
                        style={{
                          fontSize: "20px",

                          textIndent: "40px",

                          width: "100%",
                        }}
                      >
                        Date:{dayjs(currentDate).format("DD-MM-YYYY")}
                      </span>
                    </div>

                    <div className="tc-details">
                      <div>
                        {" "}
                        <span
                          style={{
                            fontSize: "20px",

                            textIndent: "40px",
                          }}
                        >
                          Place:{selectedSetting.address}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ marginRight: "5px" }}>
                  <span style={{ fontSize: "20px" }}>Sign Of Head Master</span>
                </div>
              </Box>
            </Box>

            <Box
              style={{
                display: "flex",

                justifyContent: "flex-end",

                marginTop: "7px",

                gap: "5px",
              }}
            >
              <Button
                style={{ background: "#1b3779", color: "white" }}
                onClick={handleSubmitCertificateDownload}
              >
                Download
              </Button>
              <Button
                onClick={handlePrint}
                type="button"
                style={{ background: "#1b3779", color: "white" }}
                aria-label="search"
              >
                Print
              </Button>
            </Box>
          </div>
        )}
      </Box>

      <Dialog
        fullScreen={fullScreen}
        open={bulkIssue}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form>
          <Box sx={modal}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",

                justifyContent: "flex-start",

                alignItems: "flex-end",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  textAlign="center"
                  fontSize="20px"
                  fontWeight="bold"
                >
                  Bulk Issue
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <FormControl size="small" fullWidth required>
                  <InputLabel>Academic Year</InputLabel>
                  <Select
                    label="Academic Year"
                    id="demo-simple-select-filled"
                    name="academicYear"
                    value={formData.academicYear || ""}
                    onChange={handleOnChange}
                    size="small"
                  >
                    {academicYears &&
                      academicYears.map((row, index) => (
                        <MenuItem key={row._id} value={row._id}>
                          {row.academicYearFrom} - {row.academicYearTo}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="From Date"
                    name="fromDate"
                    value={fromDate}
                    inputFormat="DD-MM-YYYY"
                    onChange={(newValue) => {
                      setFromDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        // sx={{ width:{md:227,xs:"100%"}}}

                        size="small"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="To Date"
                    value={toDate}
                    inputFormat="DD-MM-YYYY"
                    onChange={(newValue) => {
                      setToDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        // sx={{ width:{md:227,xs:"100%"}}}

                        size="small"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <FormControl fullWidth required size="small">
                  <InputLabel>Class</InputLabel>

                  <Select
                    label="Class"
                    id="demo-simple-select-filled"
                    name="className"
                    value={formData.className || ""}
                    onChange={handleClassChange}
                    size="small"
                  >
                    {classes &&
                      classes.map((row, index) => (
                        <MenuItem key={row._id} value={row._id}>
                          {row.className}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <FormControl fullWidth size="small" required>
                  <InputLabel>Section</InputLabel>

                  <Select
                    label="Section"
                    id="demo-simple-select-filled"
                    name="section"
                    value={formData.section || ""}
                    onChange={handleOnChange}
                    size="small"
                  >
                    {formData.className && (
                      <MenuItem value="all">--all--</MenuItem>
                    )}

                    {sections.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.sectionName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <FormControl fullWidth size="small" required>
                  <InputLabel>Student Names</InputLabel>
                  <Select
                    label="Student Names"
                    id="demo-simple-select-filled"
                    name="name"
                    multiple
                    value={selectedItems}
                    onChange={handleSelectChange}
                    // input={<Input />}
                    renderValue={(selected) => (
                      <div>
                        {selected.map((studentId) => (
                          <Chip
                            key={studentId}
                            label={
                              names.find((student) => student._id === studentId)
                                ?.basicInfo.name
                            }
                          />
                        ))}
                      </div>
                    )}
                  >
                    {names.map((row) => (
                      <MenuItem key={row._id} value={row._id}>
                        {row.basicInfo.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Box
                  display="flex"
                  gap={2}
                  justifyContent={{
                    xs: "center",
                    sm: "center",
                    md: "flex-end",
                    lg: "flex-end",
                  }}
                >
                  <Button
                    style={{ background: "#1b3779", color: "white" }}
                    onClick={handleBulkCertificateDownload}
                  >
                    Download
                  </Button>

                  <Button
                    onClick={handleBulkPrint}
                    type="button"
                    style={{ background: "#1b3779", color: "white" }}
                    aria-label="search"
                  >
                    Print
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Dialog>
    </div>
  );
};

export default StudyCertificate;
