import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Button,
  TextField,
  Tooltip,
  Typography,
  Modal,
} from "@mui/material";
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import CloudUploadRounded from "@mui/icons-material/CloudUploadRounded";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Stack from "@mui/material/Stack";
import AddDocInfo from "./AddDocInfo";
import { useEffect } from "react";
import { get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { objHas } from "../../../utils/utils";
import { useNavigation } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";

const styles = {
  card: {
    padding: 15,
    marginBottom: 20,
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
    // mt: 1,
  },
  Button: {
    color: "#fff",
  },
};

const AddEmployee = (props) => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [roles, setRoles] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [designtions, setDesignations] = useState([]);
  const navigate = useNavigate();
  const [employeeDetails, setEmployeeDetails] = useState({});
  const [resume, setResume] = useState(false);
  const [empPhoto, setEmpPhoto] = useState(false);
  const [dob, setDob] = useState(Date.now());
  const [joinDate, setJoinDate] = useState(Date.now());
  const [disabled, setDisabled] = useState(false);

  const [loader, setLoader] = useState(false);

  const [passwordState, setPasswordState] = useState({
    currentPassword: "",
    newpassword: "",
    // confirmPassword: "",
  });
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let { oldpassword, newpassword } = passwordState;

  let handleChangePassword = (e) => {
    setPasswordState({ ...passwordState, [e.target.name]: e.target.value });
  };

  const handleResetPasswordSubmit = async (e) => {
    e.preventDefault();
    let payload = {
      oldpassword,
      newpassword,
    };
    try {
      // console.log(payload, urls)
      await post(`${urls.password.resetPassword}`, payload);
      setPasswordState({
        oldpassword: "",
        newpassword: "",
      });
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    setPasswordState({
      oldpassword: "",
      newpassword: "",
    });
    handleClose();
  };

  const handleFileChange = (e) => {};
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    //let temp = [...employeeDetails];

    setEmployeeDetails({ ...employeeDetails, [name]: value });
    //setEmpName(e.target.value);
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    try {
      e.preventDefault();
      let data = {
        basicInfo: {
          empName: employeeDetails.empName,
          empId: employeeDetails.empId,
          responsibility: employeeDetails.responsibility,
          designation: employeeDetails.designation,
          secMobileNo: employeeDetails.secMobileNo,
          empGender: employeeDetails.empGender,
          empBloodGroup: employeeDetails.empBloodGroup,
          Religion: employeeDetails.Religion,
          dob: dayjs(dob),
          presentAddress: employeeDetails.presentAddress,
          permanentAddress: employeeDetails.permanentAddress,
          fatherName: employeeDetails.fatherName,
          spouseName: employeeDetails.spouseName,
          aadharNo: employeeDetails.aadharNo,
          fatherOccupation: employeeDetails.fatherOccupation,
          spouseOccupation: employeeDetails.spouseOccupation,
        },
        academicInfo: {
          qualification: employeeDetails.qualification,
          workExp: employeeDetails.workExp,
          email: employeeDetails.email,
          salaryGrade: employeeDetails.salaryGrade,
          salaryType: employeeDetails.salaryType,
          department: employeeDetails.department,
          joiningDate: dayjs(joinDate),
          resume: employeeDetails.resume,
        },
        username: employeeDetails.username,
        password: employeeDetails.password,
        status: employeeDetails.status,
        role: employeeDetails.role,
        empPhoto: employeeDetails.empPhoto,
        contactNumber: employeeDetails.contactNumber,
      };
      if (props.employee) {
        delete data.username;
        delete data.password;
        const res = await put(
          `${urls.employee.putEmployee}${props.employee._id}`,
          "",
          data
        );
        if (res.data.success) {
          navigate("/dashboard/employee");
        } else {
          console.log("error ", res.data);
        }
      } else {
        const res = await post(urls.employee.postEmployee, data);
        if (res.data.success) {
          navigate("/dashboard/employee");
        } else {
          console.log("error ", res);
        }
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };
  useEffect(() => {
    if (props.employee) {
      const update = {
        ...props.employee.basicInfo,
        ...props.employee.academicInfo,
        ...props.employee,
      };
      // setDisabled(false); //to change based on employee status
      setEmployeeDetails(update);
      setDob(objHas(props, "employee.basicInfo.dob", ""));
      setJoinDate(objHas(props, "employee.academicInfo.joiningDate", ""));
    }
  }, [props]);

  useEffect(() => {
    const getRole = async () => {
      try {
        const res = await get(urls.role.getAllRole);
        setRoles(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getRole();
  }, []);

  useEffect(() => {
    const getDepartment = async () => {
      try {
        const res = await get(urls.department.getDepartment);
        setDepartments(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getDepartment();
  }, []);
  useEffect(() => {
    const getDesignation = async () => {
      try {
        const res = await get(urls.designation.getAllDesignation);
        setDesignations(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getDesignation();
  }, []);
  const handleResume = async (e) => {
    const formData = new FormData();
    if (e.target.files.length > 0) {
      formData.append("files", e.target.files[0]);
      try {
        const res = await post(urls.fileUpload.postFileUpload, formData);
        if (res.data.success) {
          setEmployeeDetails({
            ...employeeDetails,
            resume: res.data.result[0]._id,
          });
          setResume(e.target.files[0].name);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleEmpPhoto = async (e) => {
    const formData = new FormData();
    if (e.target.files.length > 0) {
      formData.append("files", e.target.files[0]);
      try {
        const res = await post(urls.fileUpload.postFileUpload, formData);
        if (res.data.success) {
          setEmployeeDetails({
            ...employeeDetails,
            empPhoto: res.data.result[0]._id,
          });
          setEmpPhoto(e.target.files[0].name);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleNav = () => {
    navigate("/dashboard/employee");
  };
  return (
    <div>
      <Box
        sx={{ display: "flex", justifyContent: "flex-end", padding: "15px" }}
      >
        <Button
          variant="contained"
          onClick={handleOpen}
          sx={{
            background: "#1b3779",
            ":hover": { background: "#1b3779" },
            color: "#fff",
          }}
        >
          Reset Password
        </Button>
      </Box>
      <form onSubmit={handleSubmit}>
        <div style={{ margin: "15px" }}>
          <Card style={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: { md: "left", xs: "center" },
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "15px", fontWeight: "bold", mb: 1 }}
                    >
                      Basic Information:
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                }}
              >
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    required
                    id="filled-required"
                    size="small"
                    label="Name"
                    disabled={props.view && true}
                    name="empName"
                    value={employeeDetails.empName}
                    onChange={(event) => handleOnChange(event)}
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-required"
                    size="small"
                    value={employeeDetails.empId}
                    name="empId"
                    //onChange={handleEmpid}

                    disabled={props.view && true}
                    onChange={(event) => handleOnChange(event)}
                    label="Employee Id"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-required"
                    size="small"
                    disabled={props.view && true}
                    value={employeeDetails.responsibility}
                    name="responsibility"
                    onChange={(event) => handleOnChange(event)}
                    label="Responsibility"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" sx={styles.textfield} required>
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Designation
                    </InputLabel>
                    <Select
                      label="Designation"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      disabled={props.view && true}
                      value={employeeDetails.designation}
                      defaultValue={
                        props.employee &&
                        props.employee.basicInfo &&
                        props.employee.basicInfo.designation
                      }
                      name="designation"
                      onChange={(event) => handleOnChange(event)}
                    >
                      {designtions &&
                        designtions.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.designation}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    required
                    id="filled-required"
                    size="small"
                    disabled={props.view && true}
                    value={employeeDetails.contactNumber}
                    type="number"
                    name="contactNumber"
                    onChange={(event) => handleOnChange(event)}
                    label="Contact Number"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-required"
                    size="small"
                    disabled={props.view && true}
                    value={employeeDetails.secMobileNo}
                    name="secMobileNo"
                    onChange={(event) => handleOnChange(event)}
                    label="Secondary mobile number"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" sx={styles.textfield}>
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Gender
                    </InputLabel>
                    <Select
                      label="Gender"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      disabled={props.view && true}
                      value={employeeDetails.empGender}
                      defaultValue={
                        props.employee &&
                        props.employee.basicInfo &&
                        props.employee.basicInfo.empGender
                      }
                      name="empGender"
                      onChange={(event) => handleOnChange(event)}
                    >
                      <MenuItem
                        value={"male"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Male
                      </MenuItem>
                      <MenuItem
                        value={"female"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Female
                      </MenuItem>
                      <MenuItem
                        value={"others"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Others
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" sx={styles.textfield}>
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Blood group
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={employeeDetails.empBloodGroup}
                      defaultValue={
                        props.employee &&
                        props.employee.basicInfo &&
                        props.employee.basicInfo.empBloodGroup
                      }
                      disabled={props.view && true}
                      name="empBloodGroup"
                      onChange={(event) => handleOnChange(event)}
                    >
                      <MenuItem
                        value={"a+"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        A+
                      </MenuItem>
                      <MenuItem
                        value={"a-"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        A-
                      </MenuItem>
                      <MenuItem
                        value={"b+"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        B+
                      </MenuItem>
                      <MenuItem
                        value={"b-"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        B-
                      </MenuItem>
                      <MenuItem
                        value={"o+"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        O+
                      </MenuItem>
                      <MenuItem
                        value={"o-"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        O-
                      </MenuItem>
                      <MenuItem
                        value={"ab+"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        AB+
                      </MenuItem>
                      <MenuItem
                        value={"ab-"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        AB-
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-required"
                    size="small"
                    label="Religion"
                    disabled={props.view && true}
                    value={employeeDetails.Religion}
                    name="Religion"
                    onChange={(event) => handleOnChange(event)}
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date of birth"
                      value={dob}
                      inputFormat="DD-MM-YYYY"
                      disabled={props.view && true}
                      onChange={(newValue) => {
                        setDob(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          style={styles.textfield}
                          size="small"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-required"
                    size="small"
                    disabled={props.view && true}
                    value={employeeDetails.presentAddress}
                    name="presentAddress"
                    onChange={(event) => handleOnChange(event)}
                    label="Present Address"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-required"
                    size="small"
                    disabled={props.view && true}
                    value={employeeDetails.permanentAddress}
                    name="permanentAddress"
                    onChange={(event) => handleOnChange(event)}
                    label="Permanent Address"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-required"
                    size="small"
                    disabled={props.view && true}
                    value={employeeDetails.fatherName}
                    name="fatherName"
                    onChange={(event) => handleOnChange(event)}
                    label="Father Name"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-required"
                    disabled={props.view && true}
                    size="small"
                    value={employeeDetails.spouseName}
                    name="spouseName"
                    onChange={(event) => handleOnChange(event)}
                    label="Spouse Name"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-required"
                    size="small"
                    disabled={props.view && true}
                    value={employeeDetails.aadharNo}
                    name="aadharNo"
                    pattern="^\d{4}-\d{4}-\d{4}$"
                    onChange={(event) => handleOnChange(event)}
                    label="Aadhar No."
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-required"
                    size="small"
                    disabled={props.view && true}
                    value={employeeDetails.fatherOccupation}
                    name="fatherOccupation"
                    onChange={(event) => handleOnChange(event)}
                    label="Father Occupation"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={3}>
                  <TextField
                    id="filled-required"
                    size="small"
                    disabled={props.view && true}
                    value={employeeDetails.spouseOccupation}
                    name="spouseOccupation"
                    onChange={(event) => handleOnChange(event)}
                    label="Spouse Occupation"
                    sx={styles.textfield}
                  />
                </Grid>
              </Grid>
            </Box>
          </Card>
          <Card style={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: { md: "left", xs: "center" },
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "15px", fontWeight: "bold", mb: 1 }}
                    >
                      Academic Information:
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                }}
              >
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    required
                    disabled={props.view && true}
                    id="filled-required"
                    size="small"
                    value={employeeDetails.qualification}
                    name="qualification"
                    onChange={(event) => handleOnChange(event)}
                    label="Qualification"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    disabled={props.view && true}
                    id="filled-required"
                    size="small"
                    value={employeeDetails.workExp}
                    name="workExp"
                    onChange={(event) => handleOnChange(event)}
                    label="Work Experience"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" sx={styles.textfield} required>
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Salary Grade
                    </InputLabel>
                    <Select
                      disabled={props.view && true}
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      label="Salary Grade"
                      value={employeeDetails.salaryGrade}
                      defaultValue={
                        props.employee &&
                        props.employee.academicInfo &&
                        props.employee.academicInfo.salaryGrade
                      }
                      name="salaryGrade"
                      onChange={(event) => handleOnChange(event)}
                    >
                      <MenuItem
                        value={"n/a"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        N/A
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    required
                    disabled={props.view && true}
                    id="filled-required"
                    size="small"
                    value={employeeDetails.email}
                    name="email"
                    onChange={(event) => handleOnChange(event)}
                    label="Email"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" sx={styles.textfield} required>
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Salary Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={employeeDetails.salaryType}
                      disabled={props.view && true}
                      label="Salary Type"
                      defaultValue={
                        props.employee &&
                        props.employee.academicInfo &&
                        props.employee.academicInfo.salaryType
                      }
                      name="salaryType"
                      onChange={(event) => handleOnChange(event)}
                    >
                      <MenuItem
                        value={"monthly"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Monthly
                      </MenuItem>
                      <MenuItem
                        value={"hourly"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Hourly
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" sx={styles.textfield} required>
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Role
                    </InputLabel>
                    <Select
                      label="Role"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      disabled={props.view && true}
                      value={employeeDetails.role}
                      defaultValue={props.employee && props.employee.role}
                      name="role"
                      onChange={(event) => handleOnChange(event)}
                    >
                      {roles &&
                        roles.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.roleName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl size="small" sx={styles.textfield} required>
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Department
                    </InputLabel>
                    <Select
                      label="Department"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={employeeDetails.department}
                      disabled={props.view && true}
                      defaultValue={
                        props.employee &&
                        props.employee.academicInfo &&
                        props.employee.academicInfo.department
                      }
                      name="department"
                      onChange={(event) => handleOnChange(event)}
                    >
                      {departments &&
                        departments.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.department}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Admission Date"
                      value={joinDate}
                      inputFormat="DD-MM-YYYY"
                      onChange={(newValue) => {
                        setJoinDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          disabled={props.view && true}
                          style={styles.textfield}
                          size="small"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <div style={{ marginLeft: 10 }}>
                      <Box
                        sx={{ fontSize: 12, color: "#666", fontWeight: "bold" }}
                      >
                        Resume:
                      </Box>
                      <Tooltip title="Upload">
                        <IconButton
                          sx={{
                            color: "#1b3779",
                            ":hover": { color: "#1b3779" },
                          }}
                          color="primary"
                          aria-label="upload picture"
                          component="label"
                        >
                          <input
                            hidden
                            disabled={props.view && true}
                            name="resume"
                            label="resume"
                            onChange={(event) => handleResume(event)}
                            accept=".pdf, .doc, .docx, .txt"
                            type="file"
                          />
                          <CloudUploadRounded />
                          {resume && (
                            <span
                              style={{ marginLeft: "10px", fontSize: "12px" }}
                            >
                              {resume}
                            </span>
                          )}
                        </IconButton>
                      </Tooltip>
                    </div>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Card>
          <Card style={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: { md: "left", xs: "center" },
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "15px", fontWeight: "bold", mb: 1 }}
                    >
                      Log In Information:
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    required
                    disabled={props.view && true}
                    id="filled-required"
                    size="small"
                    label="Username"
                    value={employeeDetails.username}
                    name="username"
                    onChange={(event) => handleOnChange(event)}
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  {!props.employee && (
                    <TextField
                      required
                      disabled={props.view && true}
                      id="filled-required"
                      size="small"
                      value={employeeDetails.password}
                      name="password"
                      onChange={(event) => handleOnChange(event)}
                      label="Password"
                      sx={styles.textfield}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          </Card>
          <Card style={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: { md: "left", xs: "center" },
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "15px", fontWeight: "bold", mb: 1 }}
                    >
                      Other Information:
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                }}
              >
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl size="small" sx={styles.textfield}>
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Is View On Web?
                    </InputLabel>
                    <Select
                      label="Is View On Web"
                      disabled={props.view && true}
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                    >
                      <MenuItem
                        value={"yes"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Yes
                      </MenuItem>
                      <MenuItem
                        value={"no"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        No
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl size="small" sx={styles.textfield}>
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Have details for web
                    </InputLabel>
                    <Select
                      label="Have Details For Web"
                      disabled={props.view && true}
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                    >
                      <MenuItem
                        value={"yes"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Yes
                      </MenuItem>
                      <MenuItem
                        value={"no"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        No
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Stack direction="row" alignItems="center">
                    <div style={{ marginLeft: 10, display: "flex" }}>
                      <div
                        style={{
                          fontSize: 12,
                          color: "#666",
                          fontWeight: "bold",
                          marginTop: "10px",
                        }}
                      >
                        Photo:
                      </div>
                      <Tooltip title="Upload">
                        <IconButton
                          sx={{
                            color: "#1b3779",
                            ":hover": { color: "#1b3779" },
                          }}
                          color="primary"
                          aria-label="upload picture"
                          component="label"
                        >
                          <input
                            disabled={props.view && true}
                            hidden
                            name="empPhoto"
                            onChange={(event) => handleEmpPhoto(event)}
                            accept="image/*"
                            type="file"
                          />
                          <PhotoCamera />
                          {empPhoto && (
                            <span
                              style={{ marginLeft: "10px", fontSize: "12px" }}
                            >
                              {empPhoto}
                            </span>
                          )}
                        </IconButton>
                      </Tooltip>
                    </div>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Card>
          <Card style={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: { md: "left", xs: "center" },
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "15px", fontWeight: "bold", mb: 1 }}
                    >
                      Profile Information:
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={4}>
                  <FormControl size="small" sx={styles.textfield}>
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Status
                    </InputLabel>
                    <Select
                      label="Status"
                      disabled={props.view && true}
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={employeeDetails.status}
                      defaultValue={props.employee && props.employee.status}
                      name="status"
                      onChange={(event) => handleOnChange(event)}
                    >
                      <MenuItem
                        value={"active"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Active
                      </MenuItem>
                      <MenuItem
                        value={"inactive"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Inactive
                      </MenuItem>

                      <MenuItem
                        value={"off-board"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Off Boarding
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Card>
          {/* <AddDocInfo /> */}
          <Card style={styles.card}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                display="flex"
                justifyContent={{
                  xs: "center",
                  sm: "center",
                  md: "flex-end",
                  lg: "flex-end",
                }}
                gap={2}
              >
                <Tooltip title="Cancel">
                  <Button
                    style={styles.Button}
                    variant="contained"
                    size="small"
                    color="error"
                    onClick={handleNav}
                  >
                    Cancel
                  </Button>
                </Tooltip>
                <LoadingButton
                  loading={loader}
                  style={styles.Button}
                  variant="contained"
                  size="small"
                  disabled={disabled}
                  type="submit"
                >
                  {props.employee ? "UPDATE" : "CREATE"}
                </LoadingButton>
              </Grid>
            </Grid>
          </Card>
        </div>
      </form>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "white",
          justifyContent: "center",
        }}
      >
        <form action="" onSubmit={handleResetPasswordSubmit}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              maxWidth: "400px",
              padding: "20px",
              gap: 2,
            }}
          >
            <Typography>Reset Password</Typography>
            <FormControl>
              <TextField
                required
                id="basic-standard"
                size="small"
                name="oldpassword"
                value={oldpassword}
                onChange={handleChangePassword}
                label="Current Password"
                sx={{ label: { fontSize: 12 }, minWidth: 230 }}
              />
            </FormControl>
            <FormControl>
              <TextField
                required
                id="basic-standard"
                type="password"
                size="small"
                name="newpassword"
                value={newpassword}
                onChange={handleChangePassword}
                label="New Password"
                sx={{ label: { fontSize: 12 }, minWidth: 230 }}
              />
            </FormControl>
            {/* <FormControl>
              <TextField
                required
                type="password"
                id="basic-standard"
                variant="standard"
                name="confirmPassword"
                value={confirmPassword}
                onChange={handleChangePassword}
                label="Confirm Password"
                sx={{ label: { fontSize: 12 }, minWidth: 227 }}
              />
            </FormControl> */}
            <Box>
              <Button
                variant="contained"
                color="error"
                sx={{
                  background: "#1b3779",
                  ":hover": { background: "#1b3779" },
                  color: "#fff",
                  marginRight: "3px",
                }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  background: "#1b3779",
                  ":hover": { background: "#1b3779" },
                  color: "#fff",
                }}
              >
                Reset Password
              </Button>
            </Box>
          </Box>
        </form>
      </Modal>
    </div>
  );
};

export default AddEmployee;
