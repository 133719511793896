import React from 'react'
import { Box } from '@mui/material';
import {Typography} from '@mui/material';



const NavItem = ({onClick,title,open,path}) => {



  return (
    <>
    <Box onClick={onClick} open={open}  sx={{color:"white",cursor:"pointer"}} >
      <Typography component='div' onClick={path} sx={{fontWeight:"bold"}} >
        {title}
      </Typography>
      
      
      
    </Box>
  </>
  )
}

export default NavItem