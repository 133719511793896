import { Box, Button, Typography } from "@mui/material";
import React from "react";

import style from "./blog.module.css";
import { useNavigate } from "react-router-dom";

const Blog = ({ role, color, image, courses, path, title }) => {
  let navigate = useNavigate();

  let handlePath = (path) => {
    navigate(path);
  };
  return (
    <Box component="div" title={title} className={style.btnMain}>
      <Box
        sx={{
          height: "300px",
          width: "250px",
          borderRadius: "3%",
          bgcolor: `${color}`,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <Box
          sx={{
            bgcolor: "rgba(255,255,255,0.2)",
            height: "120px",
            width: "120px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={image} alt="" style={{ height: "60px", width: "60px" }} />
        </Box>

        <Typography variant="h4" sx={{ fontWeight: "bold", color: "white" }}>
          {role}
        </Typography>

        <button className={style.btn} onClick={() => handlePath(path)}>
          {courses}
        </button>
      </Box>
    </Box>
  );
};

export default Blog;
