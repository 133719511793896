import React, { useContext, useState } from "react";
import SettingContext from "../context/SettingsContext";
import { Autocomplete, Box, MenuItem, Select } from "@mui/material";
import { Home } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { inputClasses } from "@mui/base/Input";
import Input from "@mui/material/Input";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled, Button } from "@mui/material";
import { InputAdornment, IconButton } from "@mui/material";

export default function SchoolSelector() {
  const navigate = useNavigate();
  const handleGoHome = () => navigate("/");
  const { selectedSetting, settings, setSelectedSetting } =
    useContext(SettingContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleUpdateSelectSchool = (e) => {
    setSelectedSetting(settings.filter((s) => s._id === e.target.value)[0]);
  };

  const handleExpandMoreClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
<Box sx={{boxShadow:theme=>theme.shadows[3],borderRadius:"20px",overflow:'hidden'}}>
<Select
fullWidth
size="small"
      value={selectedSetting._id}
      onChange={handleUpdateSelectSchool}
      open={isMenuOpen}
      onOpen={() => setIsMenuOpen(true)}
      onClose={() => setIsMenuOpen(false)}
     sx={{paddingLeft:"0px", 
      borderRadius:"20px",
backgroundColor:"#ef6c00",
border:'none',

     color:'#ffff'}}
      startAdornment={
        <InputAdornment>
          <IconButton onClick={handleGoHome}>
            <Home style={{color:"skyblue"}}/>
          </IconButton>
        </InputAdornment>
      }

    >
      {settings.map((s) => (
        <MenuItem key={s._id} value={s._id}>
          {s.schoolName}
        </MenuItem>
      ))}
    </Select>
</Box>
    </>
  );
}
