// import React, { useContext, useEffect, useState } from "react";
// import {
//   Box,
//   Button,
//   Card,
//   Fab,
//   FormControl,
//   Grid,
//   IconButton,
//   InputAdornment,
//   InputLabel,
//   MenuItem,
//   Modal,
//   Paper,
//   Select,
//   Table,
//   TableCell,
//   TableHead,
//   TableRow,
//   TextField,
//   TextareaAutosize,
//   Tooltip,
//   Typography,
//   styled,
// } from "@mui/material";
// import { Search } from "@material-ui/icons";
// import { TableBody } from "@material-ui/core";
// import EditIcon from "@mui/icons-material/Edit";
// import { Delete } from "@mui/icons-material";
// import AddIcon from "@mui/icons-material/Add";
// import ClearIcon from "@mui/icons-material/Clear";
// import { get, post, put, del } from "../../../services/apis";
// import { urls as url } from "../../../services/urlConstant";
// import DeleteModal from "../../Layout/Academic/DeleteModal";
// import { LoadingButton } from "@mui/lab";
// import SettingContext from "../../../context/SettingsContext";
// import appendStringToUrls from "../../../utils/appendString";
// import SectionsSubHeader from "../../../components/SectionSubHeader";
// import RemoveIcon from "@mui/icons-material/Remove";

// const ContainerBox = styled(Box)(({ theme }) => ({
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "100%",
//   maxWidth: "650px",
//   maxHeight: "650px",
//   borderRadius: "5px",
//   height: "auto",
//   overflow: "scroll",
//   backgroundColor: "white",
//   padding: "20px",
//   // border:"solid red",
//   [theme.breakpoints.down("md")]: {
//     overflow: "auto",
//     width: "90%",
//   },
//   [theme.breakpoints.between(819, 821)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.between(767, 769)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.between(911, 913)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.between(1023, 1025)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.between(1279, 1281)]: {
//     overflow: "auto",
//     width: "100%",
//     height: "100%",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "100%",
//     height: "100%",
//     overflow: "auto",
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "center",
//   },
// }));

// const styles = {
//   button: {
//     color: "#ffff",
//     backgroundColor: "orange",
//     marginBottom: "5px",
//     padding: "2.5px",
//     marginLeft: "5px",
//   },
//   modal: {
//     overflowY: "scroll",
//     bgcolor: "rgba(255,255,255,1)",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   box: {
//     backgroundColor: "white",
//     maxWidth: "700px",
//     maxHeight: "100vh",
//     overflowY: "scroll",
//     padding: "20px",
//   },
// };

// const SalaryGrade = () => {
//   const { selectedSetting } = useContext(SettingContext);
//   const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
//   const [search, setSearch] = useState("");
//   const [open, setOpen] = React.useState(false);
//   const [editSalaryGrade, setEditSalaryGrade] = useState();
//   const [list, setList] = React.useState([]);
//   const [searchFilter, setSearchFilter] = useState([]);
//   const [deleteModal, setDeleteModal] = React.useState(false);
//   const [removeField, setRemoveField] = useState([]);

//   const [addForm, setAddForm] = useState({
//     grade: "",
//     gradeId: "",
//     from: 0,
//     to: 0,
//     breakups: [{ id: 1, name: "", percentage: "" }],
//     deduction: [{ id: 1, name: "", percentage: "" }],
//   });

//   let [loader, setLoader] = useState(false);

//   // const [data, setData] = useState([
//   //   { breakupName: "", breakupPercentage: "" },
//   // ]);
//   // const [dataDeduction, setDataDeduction] = useState([
//   //   { deductionName: "", deductionPercentage: "" },
//   // ]);

//   const handleClick = () => {
//     setAddForm({
//       ...addForm,
//       breakups: [
//         ...addForm.breakups,
//         {
//           id: addForm.breakups.length + 1,
//           name: "",
//           percentage: "",
//         },
//       ],
//     });
//   };

//   const handleClickDeduction = () => {
//     setAddForm({
//       ...addForm,
//       deduction: [...addForm.deduction, { name: "", percentage: "" }],
//     });
//   };

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setEditSalaryGrade(null);
//     setAddForm({
//       grade: "",
//       gradeId: "",
//       from: 0,
//       to: 0,
//       breakups: [{ name: "", percentage: "" }],
//       deduction: [{ name: "", percentage: "" }],
//     });
//     setOpen(false);
//   };

//   useEffect(() => {
//     getSalaryGrade();
//   }, [selectedSetting]);

//   useEffect(() => {
//     setList([]);
//     setSearchFilter([]);
//   }, [selectedSetting]);

//   const getSalaryGrade = async () => {
//     try {
//       const { data, status } = await get(`${urls.salaryGrade.getList}`);

//       setList(data.result.data);
//       console.log(data, "sssss");
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleBreakupInputChange = (e, id) => {
//     setAddForm({
//       ...addForm,
//       breakups: [
//         ...addForm.breakups.map((d) =>
//           d.id === id ? { ...d, [e.target.name]: e.target.value } : d
//         ),
//       ],
//     });
//   };

//   const handleDeductionInputChange = (e, id) => {
//     setAddForm({
//       ...addForm,
//       deduction: [
//         ...addForm.deduction.map((d) =>
//           d.id === id ? { ...d, [e.target.name]: e.target.value } : d
//         ),
//       ],
//     });
//   };

//   const handleAddForm = (e) => {
//     const { name, value } = e.target;
//     setAddForm((prev) => ({
//       ...prev,
//       [name]: value,
//     }));
//   };

//   const handleAddSubmit = async (e) => {
//     e.preventDefault();
//     setLoader(true);
//     try {
//       if (!editSalaryGrade) {
//         const { status } = await post(`${urls.salaryGrade.create}`, {
//           grade: addForm.grade,
//           gradeId: addForm.gradeId,
//           from: addForm.from,
//           to: addForm.to,
//           breakups: addForm.breakups,
//           deduction: addForm.deduction,
//           status: addForm.status,
//         });

//         getSalaryGrade();
//         handleClose();
//       } else {
//         const { status } = await put(
//           `${urls.salaryGrade.update}${editSalaryGrade}`,
//           "",
//           {
//             grade: addForm.grade,
//             gradeId: addForm.gradeId,
//             from: addForm.from,
//             to: addForm.to,
//             breakups: addForm.breakups,
//             deduction: addForm.deduction,
//             status: addForm.status,
//           }
//         );

//         getSalaryGrade();
//         handleClose();
//       }
//     } catch (error) {
//       console.log(error);
//     }
//     setLoader(false);
//   };

//   const handleGradeEdit = (id, data) => {
//     setEditSalaryGrade(id);
//     setAddForm({
//       grade: data.grade,
//       gradeId: data.gradeId,
//       from: data.from,
//       to: data.to,
//       breakups: data.breakups,
//       deduction: data.deduction,
//       status: data.status,
//     });
//     handleClickOpen();
//   };

//   const handleDeleteone = async (id) => {
//     try {
//       const res = await del(`${urls.salaryGrade.delete}`, id);
//       if (res.data.success) {
//         const filteredEvent = list.filter((item) => item._id !== id);
//         setList(filteredEvent);
//       } else {
//         console.log("Couldn't complete your request, Try again later");
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleSearch = (e) => {
//     const { value } = e.target;
//     setSearch(value.trim());
//     if (value.trim() !== "") {
//       list.length > 0 &&
//         setSearchFilter(
//           list.filter((ele) =>
//             ele.grade.toLowerCase().includes(value.toLowerCase().trim())
//           )
//         );
//     } else {
//       setSearchFilter([]);
//     }
//   };

//   const handleRemoveConBreakups = (indexToRemove) => {
//     setAddForm((prevAddForm) => ({
//       ...prevAddForm,
//       breakups: prevAddForm.breakups.filter((val, i) => i !== indexToRemove),
//     }));
//   };

//   const handleRemoveConDeduction = (indexToRemove) => {
//     setAddForm((prevAddForm) => ({
//       ...prevAddForm,
//       deduction: prevAddForm.deduction.filter((val, i) => i !== indexToRemove),
//     }));
//   };

//   return (
//     <div>
//       <div style={{ margin: "15px" }}>
//         <SectionsSubHeader title="Salary Grade" />

//         <Paper
//           sx={{ width: "100%", height: "60px", overflow: "hidden", mt: 2 }}
//         >
//           <>
//             <Grid
//               container
//               spacing={2}
//               sx={{
//                 display: "flex",
//                 alignItems: "center",
//                 p: "0.5%",
//                 justifyContent: { md: "right", sm: "right", xs: "center" },
//               }}
//             >
//               <Grid item xs="auto">
//                 <TextField
//                   size="small"
//                   label="Search by Grade..."
//                   value={search}
//                   onChange={handleSearch}
//                   variant="outlined"
//                   InputProps={{
//                     endAdornment: (
//                       <InputAdornment position="end">
//                         <IconButton edge="end" type="submit">
//                           <Search />
//                         </IconButton>
//                       </InputAdornment>
//                     ),
//                   }}
//                 />
//               </Grid>
//             </Grid>
//           </>
//         </Paper>
//         <Modal
//           open={open}
//           onClose={handleClose}
//           aria-labelledby="modal-modal-title"
//           aria-describedby="modal-modal-description"
//         >
//           <form className="form-tag" onSubmit={handleAddSubmit}>
//             <ContainerBox>
//               <Typography
//                 id="modal-modal-title"
//                 variant="h6"
//                 component="h2"
//                 textAlign="center"
//                 marginBottom="30px"
//                 fontSize="20px"
//                 fontWeight="bold"
//               >
//                 {editSalaryGrade ? "Edit Info" : "Add Info"}
//               </Typography>

//               <Grid container spacing={2}>
//                 <Grid item xs={12} md={6} lg={6}>
//                   <FormControl fullWidth required>
//                     <TextField
//                       required
//                       label="Grade"
//                       name="grade"
//                       value={addForm.grade || ""}
//                       onChange={handleAddForm}
//                       size="small"
//                       // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
//                     />
//                   </FormControl>
//                 </Grid>

//                 <Grid item xs={12} md={6} lg={6}>
//                   <FormControl fullWidth required>
//                     <TextField
//                       required
//                       label="From"
//                       value={addForm.from || ""}
//                       onChange={handleAddForm}
//                       size="small"
//                       name="from"
//                       // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
//                     />
//                   </FormControl>
//                 </Grid>

//                 <Grid item xs={12} md={6} lg={6}>
//                   <FormControl fullWidth required>
//                     <TextField
//                       required
//                       size="small"
//                       label="To"
//                       name="to"
//                       value={addForm.to || ""}
//                       onChange={handleAddForm}
//                       // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
//                     />
//                   </FormControl>
//                 </Grid>

//                 <Grid item xs={12} md={6} lg={6}>
//                   <FormControl fullWidth required>
//                     <InputLabel>Status</InputLabel>
//                     <Select
//                       fullWidth
//                       label="Status"
//                       labelId="demo-simpless-select-filled-label"
//                       id="demo-simple-select-filled-l"
//                       size="small"
//                       name="status"
//                       // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
//                       value={addForm.status || ""}
//                       onChange={handleAddForm}
//                     >
//                       <MenuItem value={"active"}>Active</MenuItem>
//                       <MenuItem value={"inactive"}>InActive</MenuItem>
//                     </Select>
//                   </FormControl>
//                 </Grid>

//                 <Grid item xs={12} md={6} lg={12}>
//                   <Typography
//                     component="p"
//                     sx={{ fontSize: "15px", fontWeight: 600, marginBottom: 1 }}
//                   >
//                     Breakups:
//                   </Typography>
//                   {addForm.breakups.map((val, i) => (
//                     <Card sx={{ mb: 2, p: 2 }}>
//                       <Grid container spacing={2}>
//                         <Grid item xs={12} md={12} lg={4}>
//                           {" "}
//                           <FormControl>
//                             <TextField
//                               required
//                               value={val.name}
//                               label="Name"
//                               name="name"
//                               size="small"
//                               onChange={(e) =>
//                                 handleBreakupInputChange(e, val.id)
//                               }
//                               // sx={{
//                               //   m: 1,
//                               //   ml: 4,
//                               //   minWidth: { md: 227, xs: "100%" },
//                               // }}
//                             />
//                           </FormControl>
//                         </Grid>
//                         <Grid item xs={12} md={12} lg={4}>
//                           {" "}
//                           <FormControl>
//                             <TextField
//                               required
//                               value={val.percentage}
//                               label="Percentage"
//                               name="percentage"
//                               size="small"
//                               onChange={(e) =>
//                                 handleBreakupInputChange(e, val.id)
//                               }
//                               // sx={{
//                               //   m: 1,
//                               //   ml: 4,
//                               //   minWidth: { md: 227, xs: "100%" },
//                               // }}
//                             />
//                           </FormControl>
//                         </Grid>

//                         <Grid item xs={12} md={12} lg={4}>
//                           <Button
//                             // sx={{ ml: 5, mt: 2 }}
//                             style={{ color: "#1b3779" }}
//                             onClick={() => handleRemoveConBreakups(i)}
//                           >
//                             <ClearIcon />
//                           </Button>
//                         </Grid>
//                       </Grid>
//                     </Card>
//                   ))}
//                   <Grid
//                     item
//                     xs={12}
//                     md={6}
//                     lg={6}
//                     sx={{
//                       display: "flex",
//                       justifyContent: { md: "flex-start", xs: "center" },
//                       alignItems: "center",
//                       // border:"solid red"
//                     }}
//                   >
//                     <Button
//                       onClick={handleClick}
//                       style={{
//                         color: "#ffff",
//                         backgroundColor: "orange",
//                         marginLeft: "-4px",
//                         // paddingBottom: "2px",
//                       }}
//                     >
//                       Add New
//                     </Button>
//                   </Grid>
//                 </Grid>

//                 <Grid item xs={12} md={6} lg={12}>
//                   <Typography
//                     component="p"
//                     sx={{ fontSize: "15px", fontWeight: 600, marginBottom: 1 }}
//                   >
//                     Deduction:
//                   </Typography>

//                   {addForm.deduction.map((val, i) => (
//                     <Card sx={{ mb: 2, p: 2 }}>
//                       <Grid container spacing={2}>
//                         <Grid item xs={12} md={12} lg={4}>
//                           {" "}
//                           <FormControl>
//                             <TextField
//                               required
//                               label="Name"
//                               value={val.name}
//                               name="name"
//                               size="small"
//                               onChange={(e) =>
//                                 handleDeductionInputChange(e, val.id)
//                               }
//                             />
//                           </FormControl>
//                         </Grid>
//                         <Grid item xs={12} md={12} lg={4}>
//                           {" "}
//                           <FormControl>
//                             <TextField
//                               required
//                               value={val.percentage}
//                               label="Percentage"
//                               name="percentage"
//                               size="small"
//                               onChange={(e) =>
//                                 handleDeductionInputChange(e, val.id)
//                               }
//                             />
//                           </FormControl>
//                         </Grid>
//                         <Grid item xs={12} md={12} lg={4}>
//                           {" "}
//                           <Button
//                             style={{ color: "#1b3779" }}
//                             onClick={() => handleRemoveConDeduction(i)}
//                           >
//                             <ClearIcon />
//                           </Button>
//                         </Grid>
//                       </Grid>
//                     </Card>
//                   ))}
//                   <Grid
//                     item
//                     xs={12}
//                     md={6}
//                     lg={6}
//                     sx={{
//                       display: "flex",
//                       justifyContent: { md: "flex-start", xs: "center" },
//                       alignItems: "center",
//                       // border:"solid red"
//                     }}
//                   >
//                     <Button
//                       onClick={handleClickDeduction}
//                       style={{
//                         color: "#ffff",
//                         backgroundColor: "orange",
//                         marginLeft: "-4px",
//                       }}
//                     >
//                       Add New
//                     </Button>
//                   </Grid>
//                 </Grid>

//                 <Grid item xs={12} md={6} lg={12}>
//                   <Box
//                     sx={{
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: {
//                         md: "right",
//                         sm: "right",
//                         xs: "center",
//                       },
//                     }}
//                   >
//                     <Tooltip title="Cancel">
//                       <Button
//                         onClick={handleClose}
//                         variant="contained"
//                         color="error"
//                         size="medium"
//                         type="button"
//                         sx={{ m: 1, color: "#fff" }}
//                       >
//                         Cancel
//                       </Button>
//                     </Tooltip>
//                     <Tooltip title="Submit">
//                       <LoadingButton
//                         loading={loader}
//                         variant="contained"
//                         size="medium"
//                         type="submit"
//                         sx={{
//                           background: "rgb(27, 55, 121)",
//                           ":hover": { background: "rgb(27, 55, 121)" },
//                         }}
//                       >
//                         {editSalaryGrade ? "Update" : "Add"}
//                       </LoadingButton>
//                     </Tooltip>
//                   </Box>
//                 </Grid>
//               </Grid>
//             </ContainerBox>
//           </form>
//         </Modal>

//         <Table sx={{ mt: 3 }}>
//           <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
//             <TableRow sx={{ color: "white" }}>
//               <TableCell sx={{ color: "white" }} align="center">
//                 #SL
//               </TableCell>
//               <TableCell sx={{ color: "white" }} align="center">
//                 Grade Id
//               </TableCell>
//               <TableCell sx={{ color: "white" }} align="center">
//                 Grade
//               </TableCell>
//               <TableCell sx={{ color: "white" }} align="center">
//                 From
//               </TableCell>
//               <TableCell sx={{ color: "white" }} align="center">
//                 To
//               </TableCell>
//               <TableCell sx={{ color: "white" }} align="center">
//                 Status
//               </TableCell>
//               <TableCell sx={{ color: "white" }} align="center">
//                 Action
//               </TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {search
//               ? searchFilter.map((grade, index) => (
//                   <TableRow>
//                     <TableCell align="center">{index + 1}</TableCell>
//                     <TableCell align="center">{grade.gradeCode}</TableCell>
//                     <TableCell align="center">{grade.grade}</TableCell>
//                     <TableCell align="center">{grade.from}</TableCell>
//                     <TableCell align="center">{grade.to}</TableCell>
//                     <TableCell align="center">{grade.status}</TableCell>
//                     <TableCell align="center">
//                       <div className="edit-delete">
//                         <Button
//                           style={{ color: "#1b3779" }}
//                           onClick={() => handleGradeEdit(grade._id, grade)}
//                         >
//                           <EditIcon />
//                         </Button>

//                         <Button
//                           style={{
//                             color: "#1b3779",
//                           }}
//                           onClick={() => setDeleteModal(grade._id, grade)}
//                         >
//                           <Delete />
//                         </Button>
//                         <DeleteModal
//                           deleteModal={deleteModal}
//                           handleDelete={handleDeleteone}
//                           id={grade._id}
//                           setDeleteModal={setDeleteModal}
//                         />
//                       </div>
//                     </TableCell>
//                   </TableRow>
//                 ))
//               : list.map((grade, index) => (
//                   <TableRow>
//                     <TableCell align="center">{index + 1}</TableCell>
//                     <TableCell align="center">{grade.gradeCode}</TableCell>
//                     <TableCell align="center">{grade.grade}</TableCell>
//                     <TableCell align="center">{grade.from}</TableCell>
//                     <TableCell align="center">{grade.to}</TableCell>
//                     <TableCell align="center">{grade.status}</TableCell>
//                     <TableCell align="center">
//                       <div className="edit-delete">
//                         <Button
//                           style={{ color: "#1b3779" }}
//                           onClick={() => handleGradeEdit(grade._id, grade)}
//                         >
//                           <EditIcon />
//                         </Button>

//                         <Button
//                           style={{
//                             color: "#1b3779",
//                           }}
//                           onClick={() => setDeleteModal(grade._id, grade)}
//                         >
//                           <Delete />
//                         </Button>
//                         <DeleteModal
//                           deleteModal={deleteModal}
//                           handleDelete={handleDeleteone}
//                           id={grade._id}
//                           setDeleteModal={setDeleteModal}
//                         />
//                       </div>
//                     </TableCell>
//                   </TableRow>
//                 ))}
//           </TableBody>
//         </Table>
//         {!list.length && !search.trim() && (
//           <Typography
//             variant="h6"
//             sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
//           >
//             No data found
//           </Typography>
//         )}
//         {search.trim() && !searchFilter.length && (
//           <Typography
//             variant="h6"
//             sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
//           >
//             No data found
//           </Typography>
//         )}
//         <div className="add-icon">
//           <Fab
//             variant="contained"
//             style={{ color: "#ffff", backgroundColor: "#1b3779" }}
//             onClick={handleClickOpen}
//           >
//             <AddIcon />
//           </Fab>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SalaryGrade;

//OLD Data*****

import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Dialog,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import { Search } from "@material-ui/icons";
import { TableBody } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import { get, post, put, del } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import DeleteModal from "../../Layout/Academic/DeleteModal";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionsSubHeader from "../../../components/SectionSubHeader";
import RemoveIcon from "@mui/icons-material/Remove";
import { useTheme } from "@mui/material/styles";

const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
};
const ContainerBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "650px",
  maxHeight: "650px",
  borderRadius: "5px",
  height: "auto",
  overflow: "scroll",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
    width: "90%",
  },
  [theme.breakpoints.between(819, 821)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(767, 769)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(911, 913)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1023, 1025)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1279, 1281)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const styles = {
  button: {
    color: "#ffff",
    backgroundColor: "orange",
    marginBottom: "5px",
    padding: "2.5px",
    marginLeft: "5px",
  },
  modal: {
    overflowY: "scroll",
    bgcolor: "rgba(255,255,255,1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  box: {
    backgroundColor: "white",
    maxWidth: "700px",
    maxHeight: "100vh",
    overflowY: "scroll",
    padding: "20px",
  },
};

const SalaryGrade = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [search, setSearch] = useState("");
  const [open, setOpen] = React.useState(false);
  const [editSalaryGrade, setEditSalaryGrade] = useState();
  const [list, setList] = React.useState([]);
  const [searchFilter, setSearchFilter] = useState([]);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [removeField, setRemoveField] = useState([]);

  const [addForm, setAddForm] = useState({
    grade: "",
    gradeId: "",
    from: 0,
    to: 0,
    breakups: [{ id: 1, name: "", percentage: "" }],
    deduction: [{ id: 1, name: "", percentage: "" }],
  });

  let [loader, setLoader] = useState(false);

  // const [data, setData] = useState([
  //   { breakupName: "", breakupPercentage: "" },
  // ]);
  // const [dataDeduction, setDataDeduction] = useState([
  //   { deductionName: "", deductionPercentage: "" },
  // ]);

  const handleClick = () => {
    setAddForm({
      ...addForm,
      breakups: [
        ...addForm.breakups,
        {
          id: addForm.breakups.length + 1,
          name: "",
          percentage: "",
        },
      ],
    });
  };

  const handleClickDeduction = () => {
    setAddForm({
      ...addForm,
      deduction: [...addForm.deduction, { name: "", percentage: "" }],
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setEditSalaryGrade(null);
    setAddForm({
      grade: "",
      gradeId: "",
      from: 0,
      to: 0,
      breakups: [{ name: "", percentage: "" }],
      deduction: [{ name: "", percentage: "" }],
    });
    setOpen(false);
  };

  useEffect(() => {
    getSalaryGrade();
  }, [selectedSetting]);

  useEffect(() => {
    setList([]);
    setSearchFilter([]);
  }, [selectedSetting]);

  const getSalaryGrade = async () => {
    try {
      const { data, status } = await get(`${urls.salaryGrade.getList}`);

      setList(data.result.data);
      console.log(data, "sssss");
    } catch (error) {
      console.log(error);
    }
  };

  const handleBreakupInputChange = (e, id) => {
    setAddForm({
      ...addForm,
      breakups: [
        ...addForm.breakups.map((d) =>
          d.id === id ? { ...d, [e.target.name]: e.target.value } : d
        ),
      ],
    });
  };

  const handleDeductionInputChange = (e, id) => {
    setAddForm({
      ...addForm,
      deduction: [
        ...addForm.deduction.map((d) =>
          d.id === id ? { ...d, [e.target.name]: e.target.value } : d
        ),
      ],
    });
  };

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      if (!editSalaryGrade) {
        const { status } = await post(`${urls.salaryGrade.create}`, {
          grade: addForm.grade,
          gradeId: addForm.gradeId,
          from: addForm.from,
          to: addForm.to,
          breakups: addForm.breakups,
          deduction: addForm.deduction,
          status: addForm.status,
        });

        getSalaryGrade();
        handleClose();
      } else {
        const { status } = await put(
          `${urls.salaryGrade.update}${editSalaryGrade}`,
          "",
          {
            grade: addForm.grade,
            gradeId: addForm.gradeId,
            from: addForm.from,
            to: addForm.to,
            breakups: addForm.breakups,
            deduction: addForm.deduction,
            status: addForm.status,
          }
        );

        getSalaryGrade();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const handleGradeEdit = (id, data) => {
    setEditSalaryGrade(id);
    setAddForm({
      grade: data.grade,
      gradeId: data.gradeId,
      from: data.from,
      to: data.to,
      breakups: data.breakups,
      deduction: data.deduction,
      status: data.status,
    });
    handleClickOpen();
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(`${urls.salaryGrade.delete}`, id);
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      list.length > 0 &&
        setSearchFilter(
          list.filter((ele) =>
            ele.grade.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  const handleRemoveConBreakups = (indexToRemove) => {
    setAddForm((prevAddForm) => ({
      ...prevAddForm,
      breakups: prevAddForm.breakups.filter((val, i) => i !== indexToRemove),
    }));
  };

  const handleRemoveConDeduction = (indexToRemove) => {
    setAddForm((prevAddForm) => ({
      ...prevAddForm,
      deduction: prevAddForm.deduction.filter((val, i) => i !== indexToRemove),
    }));
  };

  return (
    <div>
      <div style={{ margin: "15px" }}>
        <SectionsSubHeader title="Salary Grade" />

        {/* <Paper
          sx={{ width: "100%", height: "60px", overflow: "hidden", mt: 2 }}
        > */}

        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: { md: "right", sm: "right", xs: "center" },
            marginTop: 1.5,
          }}
        >
          <Grid item xs="auto">
            <TextField
              size="small"
              label="Search by Grade..."
              value={search}
              onChange={handleSearch}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        {/* </Paper> */}
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form className="form-tag" onSubmit={handleAddSubmit}>
            <Box sx={style}>
              <Grid container spacing={1.5}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    textAlign="center"
                    fontSize="20px"
                    fontWeight="bold"
                  >
                    {editSalaryGrade ? "Edit Info" : "Add Info"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth required>
                    <TextField
                      required
                      label="Grade"
                      name="grade"
                      value={addForm.grade || ""}
                      onChange={handleAddForm}
                      size="small"
                      // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth required>
                    <TextField
                      required
                      label="From"
                      value={addForm.from || ""}
                      onChange={handleAddForm}
                      size="small"
                      name="from"
                      // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth required>
                    <TextField
                      required
                      size="small"
                      label="To"
                      name="to"
                      value={addForm.to || ""}
                      onChange={handleAddForm}
                      // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Status</InputLabel>
                    <Select
                      fullWidth
                      label="Status"
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      size="small"
                      name="status"
                      // sx={{ m: 1, minWidth: { md: 227, xs: "100%" } }}
                      value={addForm.status || ""}
                      onChange={handleAddForm}
                    >
                      <MenuItem value={"active"}>Active</MenuItem>
                      <MenuItem value={"inactive"}>InActive</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
                    Breakups:
                  </Typography>
                </Grid>
                {addForm.breakups.map((val, i) => (
                  <React.Fragment key={i}>
                    <Grid item xs={6} sm={5} md={5} lg={5}>
                      {" "}
                      <FormControl fullWidth size="small">
                        <TextField
                          fullWidth
                          required
                          value={val.name}
                          label="Name"
                          name="name"
                          size="small"
                          onChange={(e) => handleBreakupInputChange(e, val.id)}
                          // sx={{
                          //   m: 1,
                          //   ml: 4,
                          //   minWidth: { md: 227, xs: "100%" },
                          // }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={5} md={5} lg={5}>
                      {" "}
                      <FormControl fullWidth size="small">
                        <TextField
                          fullWidth
                          required
                          value={val.percentage}
                          label="Percentage"
                          name="percentage"
                          size="small"
                          onChange={(e) => handleBreakupInputChange(e, val.id)}
                          // sx={{
                          //   m: 1,
                          //   ml: 4,
                          //   minWidth: { md: 227, xs: "100%" },
                          // }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2}>
                      <Button
                        // sx={{ ml: 5, mt: 2 }}
                        style={{ color: "#1b3779" }}
                        onClick={() => handleRemoveConBreakups(i)}
                      >
                        <ClearIcon />
                      </Button>
                    </Grid>
                  </React.Fragment>
                ))}

                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  sx={{
                    display: "flex",
                    justifyContent: { md: "flex-start", xs: "center" },
                    alignItems: "center",
                    // border:"solid red"
                  }}
                >
                  <Button
                    size="small"
                    onClick={handleClick}
                    style={{
                      color: "#ffff",
                      backgroundColor: "orange",
                      // marginLeft: "-4px",
                      // paddingBottom: "2px",
                    }}
                  >
                    Add New
                  </Button>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
                    Deduction:
                  </Typography>
                </Grid>
                {addForm.deduction.map((val, i) => (
                  <React.Fragment key={i}>
                    <Grid item xs={6} sm={5} md={5} lg={5}>
                      {" "}
                      <FormControl fullWidth size="small">
                        <TextField
                          required
                          label="Name"
                          value={val.name}
                          name="name"
                          size="small"
                          onChange={(e) =>
                            handleDeductionInputChange(e, val.id)
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={5} md={5} lg={5}>
                      {" "}
                      <FormControl fullWidth size="small">
                        <TextField
                          required
                          value={val.percentage}
                          label="Percentage"
                          name="percentage"
                          size="small"
                          onChange={(e) =>
                            handleDeductionInputChange(e, val.id)
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2}>
                      {" "}
                      <Button
                        style={{ color: "#1b3779" }}
                        onClick={() => handleRemoveConDeduction(i)}
                      >
                        <ClearIcon />
                      </Button>
                    </Grid>
                  </React.Fragment>
                ))}
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  sx={{
                    display: "flex",
                    justifyContent: { md: "flex-start", xs: "center" },
                    alignItems: "center",
                    // border:"solid red"
                  }}
                >
                  <Button
                    size="small"
                    onClick={handleClickDeduction}
                    style={{
                      color: "#ffff",
                      backgroundColor: "orange",
                      // marginLeft: "-4px",
                    }}
                  >
                    Add New
                  </Button>
                </Grid>

                <Grid item xs={12} md={6} lg={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: {
                        md: "right",
                        sm: "center",
                        xs: "center",
                      },
                    }}
                  >
                    <Tooltip title="Cancel">
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        color="error"
                        size="medium"
                        type="button"
                        sx={{ m: 1, color: "#fff" }}
                      >
                        Cancel
                      </Button>
                    </Tooltip>
                    <Tooltip title="Submit">
                      <LoadingButton
                        loading={loader}
                        variant="contained"
                        size="medium"
                        type="submit"
                        sx={{
                          background: "rgb(27, 55, 121)",
                          ":hover": { background: "rgb(27, 55, 121)" },
                        }}
                      >
                        {editSalaryGrade ? "Update" : "Add"}
                      </LoadingButton>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Dialog>

        <Table sx={{ mt: 3 }}>
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow sx={{ color: "white" }}>
              <TableCell sx={{ color: "white" }} align="center">
                #SL
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Grade Id
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Grade
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                From
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                To
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Status
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {search
              ? searchFilter.map((grade, index) => (
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{grade.gradeCode}</TableCell>
                    <TableCell align="center">{grade.grade}</TableCell>
                    <TableCell align="center">{grade.from}</TableCell>
                    <TableCell align="center">{grade.to}</TableCell>
                    <TableCell align="center">{grade.status}</TableCell>
                    <TableCell align="center">
                      <div className="edit-delete">
                        <Button
                          style={{ color: "#1b3779" }}
                          onClick={() => handleGradeEdit(grade._id, grade)}
                        >
                          <EditIcon />
                        </Button>

                        <Button
                          style={{
                            color: "#1b3779",
                          }}
                          onClick={() => setDeleteModal(grade._id, grade)}
                        >
                          <Delete />
                        </Button>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDeleteone}
                          id={grade._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              : list.map((grade, index) => (
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{grade.gradeCode}</TableCell>
                    <TableCell align="center">{grade.grade}</TableCell>
                    <TableCell align="center">{grade.from}</TableCell>
                    <TableCell align="center">{grade.to}</TableCell>
                    <TableCell align="center">{grade.status}</TableCell>
                    <TableCell align="center">
                      <div className="edit-delete">
                        <Button
                          style={{ color: "#1b3779" }}
                          onClick={() => handleGradeEdit(grade._id, grade)}
                        >
                          <EditIcon />
                        </Button>

                        <Button
                          style={{
                            color: "#1b3779",
                          }}
                          onClick={() => setDeleteModal(grade._id, grade)}
                        >
                          <Delete />
                        </Button>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDeleteone}
                          id={grade._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
        {!list.length && !search.trim() && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        {search.trim() && !searchFilter.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        <div className="add-icon">
          <Fab
            variant="contained"
            style={{ color: "#ffff", backgroundColor: "#1b3779" }}
            onClick={handleClickOpen}
          >
            <AddIcon />
          </Fab>
        </div>
      </div>
    </div>
  );
};

export default SalaryGrade;
