import React from 'react'

import { Grid } from '@mui/material'

import style from './createevents.module.css'

const CreateEvents = () => {
  return (
    <>
        <div style={{height:"8vh"}}></div>
        <Grid container sx={{margin:"0 auto",backgroundColor:"#00bda6",marginTop:"5vh",display:"flex",justifyContent:"space-evenly",alignItems:"center",width:"90%",borderRadius:"1rem"}}>
            
               

               <Grid item xs={12} sm={6}  md={3} >

                  <div>
                    
                      <div style={{borderLeft:"3px solid #50dbca",padding:"0px 15px"}} className={style.events}>
                      
                          <h2 style={{color:"white",fontSize:"40px"}}>500+</h2>
                      
                          <p style={{color:"white",fontSize:"1.3rem"}}>Total Students</p>
                      </div>
                 </div>
                

                </Grid>

                <Grid  item xs={12} sm={6} md={3} >
                <div>
                   <div style={{borderLeft:"3px solid #50dbca",padding:"0px 15px"}} className={style.events} >
                      
                      <h2 style={{color:"white",fontSize:"40px"}}>40+</h2>
                      
                      <p style={{color:"white",fontSize:"1.3rem"}}>Total Teachers</p>
                    </div>
                </div>
                </Grid>


                <Grid  item xs={12} sm={6}  md={3} >
                <div>
                    <div style={{borderLeft:"3px solid #50dbca",padding:"0px 15px"}} className={style.events} >
                      
                      <h2 style={{color:"white",fontSize:"40px"}}>100+</h2>
                      
                      
                      <p style={{color:"white",fontSize:"1.3rem"}}>Online Courses</p>
                    </div>
                </div>
                </Grid>

                <Grid  item xs={12} sm={6}  md={3} >
                <div>
                    <div style={{borderLeft:"3px solid #50dbca",padding:"0px 15px"}} className={style.events} >
                      
                      <h2 style={{color:"white",fontSize:"40px"}}>5k+</h2>
                      
                      <p style={{color:"white",fontSize:"1.3rem"}}>People WorldWide</p>
                    </div>
                </div>
                </Grid>
               
            
            
         
        </Grid>
    </>
  )
}

export default CreateEvents
