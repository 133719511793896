import React, { useState, useContext } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Card,
  Grid,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  styled,
  Box,
  InputAdornment,
  IconButton,
} from "@mui/material";
import DeleteModal from "./DeleteModal";
import { Delete, Edit, Search } from "@mui/icons-material";
import TableHead from "@mui/material/TableHead";
import AddSectionModal from "./AddSectionModal";
import { useEffect } from "react";
import { del, get, post, put } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";

import stylesSection from "../../../styles/components/Layout/Administrator/UserRole.module.css";
import SectionSubHeader from "../../SectionSubHeader";
import Wrapper from "../../Wrapper";

const styles = {
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
  },
};

const SearchBox = styled(Box)(({ theme }) => ({
  margin: "20px",
  marginTop: "25px",
  [theme.breakpoints.down("md")]: {
    // display:"flex",
    // justifyContent:"left",
    // border:"solid red"
  },
}));

const SchoolSection = () => {
  const { selectedSetting } = useContext(SettingContext);
  const [schoolSection, setSchoolSection] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [updateSection, setUpdateSection] = useState(undefined);
  const [classes, setClasses] = useState([]);
  const [formData, setFormData] = useState({});
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const [deleteloader, setDeleteLoader] = useState(false);
  const [updateloader, setUpdateLoader] = useState(false);
  const [searchloader, setSearchLoader] = useState(false);

  const [loader, setLoader] = useState(false);
  const url = appendStringToUrls(urls, `/${selectedSetting._id}`);

  useEffect(() => {
    setSearch("");
    setSchoolSection([]);
    setSearchFilter([]);
  }, [selectedSetting]);

  const [sort, setSort] = useState({
    sectionName: "asc",
    className: "asc",
    sectionNote: "asc",
    status: "asc",
    isPublish: "asc",
  });

  const handleClassChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    const init = async () => {
      try {
        const getClasses = await get(url.class.getAllClass);
        if (getClasses.data.success) setClasses(getClasses.data.data);
        if (getClasses.data.data.length > 0) {
          setFormData((prevState) => ({
            ...prevState,
            schoolClass: getClasses.data.data[0]._id,
          }));
          const getInitSection = await get(url.schoolSection.getAllSection, {
            params: {
              search: {
                class: getClasses.data.data[0]._id,
              },
            },
          });
          setSchoolSection(getInitSection.data.data);
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
    init();
  }, [selectedSetting]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setUpdateSection(undefined);
  };

  const handleDelete = async (id) => {
    try {
      const response = await del(url.schoolSection.delSection, id);
      if (response.data.success) {
        const filteredSection = schoolSection.filter(
          (item) => item._id !== response.data.data._id
        );
        setSchoolSection(filteredSection);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = async (e, data) => {
    setLoader(true);
    try {
      e.preventDefault();
      const response = await post(url.schoolSection.postSection, data);
      if (data.class === formData.schoolClass)
        setSchoolSection([...schoolSection, response.data.data]);
    } catch (error) {
      toast.error(error.message);
    }
    setLoader(false);
  };

  const handleUpdate = async (data, id) => {
    setLoader(true);
    try {
      const response = await put(url.schoolSection.putSection, id, data);
      setSchoolSection(
        schoolSection.map((e) => {
          if (e._id === response.data.data._id) {
            return response.data.data;
          } else {
            return e;
          }
        })
      );
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };
  const handleEdit = async (id) => {
    try {
      setOpen(true);
      const getSectionById = schoolSection.find((item) => item._id === id);
      setUpdateSection(getSectionById);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormSubmit = async (e) => {
    console.log("reach", e);
    e.preventDefault();
    setSearchLoader(true);
    try {
      const res = await get(url.schoolSection.getAllSection, {
        params: {
          search: {
            class: formData.schoolClass,
          },
        },
      });
      console.log(res);
      setSchoolSection(res.data.data);
    } catch (error) {
      console.log(error);
    }
    setSearchLoader(false);
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      schoolSection.length > 0 &&
        setSearchFilter(
          schoolSection.filter((ele) =>
            ele.sectionName.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  const sortBySectionName = () => {
    console.log("sortBySectionName");
    let sortedList;

    if (search) {
      if (sort.sectionName === "asc") {
        sortedList = [
          ...searchFilter.sort((a, b) =>
            b.sectionName.localeCompare(a.sectionName)
          ),
        ];
        setSort({ ...sort, sectionName: "des" });
      } else {
        sortedList = [
          ...searchFilter.sort((a, b) =>
            a.sectionName.localeCompare(b.sectionName)
          ),
        ];
        setSort({ ...sort, sectionName: "asc" });
      }
    } else {
      if (sort.sectionName === "asc") {
        sortedList = [
          ...schoolSection.sort((a, b) =>
            b.sectionName.localeCompare(a.sectionName)
          ),
        ];
        setSort({ ...sort, sectionName: "des" });
      } else {
        sortedList = [
          ...schoolSection.sort((a, b) =>
            a.sectionName.localeCompare(b.sectionName)
          ),
        ];
        setSort({ ...sort, sectionName: "asc" });
      }
    }
    setSchoolSection(sortedList);
  };

  const sortByClassName = () => {
    console.log("sortByClassName");
    let sortedList;
    if (search) {
      if (sort.className === "asc") {
        sortedList = [
          ...searchFilter.sort((a, b) =>
            b.class.className.localeCompare(a.class.className)
          ),
        ];
        setSort({ ...sort, className: "des" });
      } else {
        sortedList = [
          ...searchFilter.sort((a, b) =>
            a.class.className.localeCompare(b.class.className)
          ),
        ];
        setSort({ ...sort, className: "asc" });
      }
    } else {
      if (sort.className === "asc") {
        sortedList = [
          ...schoolSection.sort((a, b) =>
            b.class.className.localeCompare(a.class.className)
          ),
        ];
        setSort({ ...sort, className: "des" });
      } else {
        sortedList = [
          ...schoolSection.sort((a, b) =>
            a.class.className.localeCompare(b.class.className)
          ),
        ];
        setSort({ ...sort, className: "asc" });
      }
    }
    setSchoolSection(sortedList);
  };
  const sortBySectionNote = () => {
    console.log("sortBySectionNote");
    let sortedList;
    if (search) {
      if (sort.sectionNote === "asc") {
        sortedList = [
          ...searchFilter.sort((a, b) =>
            b.sectionNote.localeCompare(a.sectionNote)
          ),
        ];
        setSort({ ...sort, sectionNote: "des" });
      } else {
        sortedList = [
          ...searchFilter.sort((a, b) =>
            a.sectionNote.localeCompare(b.sectionNote)
          ),
        ];
        setSort({ ...sort, sectionNote: "asc" });
      }
    } else {
      if (sort.sectionNote === "asc") {
        sortedList = [
          ...schoolSection.sort((a, b) =>
            b.sectionNote.localeCompare(a.sectionNote)
          ),
        ];
        setSort({ ...sort, sectionNote: "des" });
      } else {
        sortedList = [
          ...schoolSection.sort((a, b) =>
            a.sectionNote.localeCompare(b.sectionNote)
          ),
        ];
        setSort({ ...sort, sectionNote: "asc" });
      }
    }
    setSchoolSection(sortedList);
  };

  const statusSorted = () => {
    console.log("statusSorted");
    if (sort.status === "asc") {
      let sortedList = [
        ...schoolSection.sort((a, b) => {
          if (a.status === "inactive" && b.status === "active") {
            console.log("yes");
            return -1;
          } else if (a.status === "active" && b.status === "inactive") {
            console.log("no");

            return 1;
          } else {
            return 0;
          }
        }),
      ];
      setSchoolSection(sortedList);
      setSort({ ...sort, status: "des" });
    } else {
      let sortedList = [
        ...schoolSection.sort((a, b) => {
          if (a.status === "inactive" && b.status === "active") {
            console.log("yes");
            return 1;
          } else if (a.status === "active" && b.status === "inactive") {
            console.log("no");

            return -1;
          } else {
            return 0;
          }
        }),
      ];
      setSchoolSection(sortedList);
      setSort({ ...sort, status: "asc" });
    }
  };
  const publishSorted = () => {
    console.log("publishSorted");
    let sortedList;
    if (search) {
      if (sort.isPublish === "asc") {
        sortedList = [
          ...searchFilter.sort((a, b) => {
            if (a.isPublish === "inactive" && b.isPublish === "active") {
              console.log("yes");
              return -1;
            } else if (a.isPublish === "active" && b.isPublish === "inactive") {
              console.log("no");
              return 1;
            } else {
              return 0;
            }
          }),
        ];
        setSort({ ...sort, isPublish: "des" });
      } else {
        sortedList = [
          ...searchFilter.sort((a, b) => {
            if (a.isPublish === "inactive" && b.isPublish === "active") {
              console.log("yes");
              return 1;
            } else if (a.isPublish === "active" && b.isPublish === "inactive") {
              console.log("no");
              return -1;
            } else {
              return 0;
            }
          }),
        ];
        setSort({ ...sort, isPublish: "asc" });
      }
    } else {
      if (sort.isPublish === "asc") {
        sortedList = [
          ...schoolSection.sort((a, b) => {
            if (a.isPublish === "inactive" && b.isPublish === "active") {
              console.log("yes");
              return -1;
            } else if (a.isPublish === "active" && b.isPublish === "inactive") {
              console.log("no");
              return 1;
            } else {
              return 0;
            }
          }),
        ];
        setSort({ ...sort, isPublish: "des" });
      } else {
        sortedList = [
          ...schoolSection.sort((a, b) => {
            if (a.isPublish === "inactive" && b.isPublish === "active") {
              console.log("yes");
              return 1;
            } else if (a.isPublish === "active" && b.isPublish === "inactive") {
              console.log("no");
              return -1;
            } else {
              return 0;
            }
          }),
        ];
        setSort({ ...sort, isPublish: "asc" });
      }
    }

    setSchoolSection(sortedList);
  };

  return (
    <Wrapper>
      <form onSubmit={handleFormSubmit}>
        <SectionSubHeader title="" />
        <Card style={{ margin: "20px 0", padding: "10px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: { md: "left", xs: "center" },
                }}
              >
                <Typography sx={{ color: "red", fontFamily: "cursive" }}>
                  Section :
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl size="small" style={styles.textfield} required>
                <InputLabel>Classes</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  name="schoolClass"
                  label="Classes"
                  value={formData.schoolClass || ""}
                  onChange={handleClassChange}
                >
                  {classes &&
                    classes.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.className}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3} sx={{ alignSelf: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: { md: "left", xs: "center" },
                }}
              >
                <LoadingButton
                  loading={searchloader}
                  type="submit"
                  size="small"
                  variant="contained"
                  style={styles.Button}
                >
                  SEARCH
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </form>
      <div style={{ margin: "20px 0" }}>
        <SearchBox>
          <Grid
            container
            spacing={2}
            sx={{ justifyContent: { md: "right", xs: "center" } }}
          >
            <Grid item xs="auto">
              <TextField
                size="small"
                value={search}
                onChange={handleSearch}
                label="Search"
                variant="outlined"
                placeholder="Search By Section Name"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" type="submit">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </SearchBox>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">Section Order</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Section Name</span>
                    <span
                      onClick={sortBySectionName}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.sectionName === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">class</span>
                    <span
                      onClick={sortByClassName}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.className === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Note</span>
                    <span
                      onClick={sortBySectionNote}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.sectionNote === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>

                  <TableCell align="center">
                    <span className="class-table-header">Status</span>
                    <span
                      onClick={statusSorted}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.status === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Is Publish?</span>
                    <span
                      onClick={publishSorted}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.isPublish === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Action</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {search
                  ? searchFilter.map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.sectionName}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.class.className}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.sectionNote}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.status}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.isPublish}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div className="edit-delete">
                            <Tooltip title="Edit" disableInteractive>
                              <Button onClick={() => handleEdit(row._id)}>
                                <Edit />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Delete" disableInteractive>
                              <Button onClick={() => setDeleteModal(row._id)}>
                                <Delete />
                              </Button>
                            </Tooltip>
                          </div>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={row._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  : schoolSection.map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.sectionName}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.class.className}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.sectionNote}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.status}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.isPublish}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div className="edit-delete">
                            <Tooltip title="Edit" disableInteractive>
                              <Button onClick={() => handleEdit(row._id)}>
                                <Edit />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Delete" disableInteractive>
                              <Button onClick={() => setDeleteModal(row._id)}>
                                <Delete />
                              </Button>
                            </Tooltip>
                          </div>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={row._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          {!schoolSection.length && !search.trim() && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {search.trim() && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </Paper>
        <div className="add-icon">
          <AddSectionModal
            loader={loader}
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            handleUpdate={handleUpdate}
            updateSection={updateSection}

            // users={sortedUsers}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default SchoolSection;
