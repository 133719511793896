// import React, { useContext, useState } from "react";
// import SectionsSubHeader from "../../../components/SectionSubHeader";

// import {
//   Box,
//   Button,
//   Card,
//   Fab,
//   Dialog,
//   FormControl,
//   Grid,
//   TextareaAutosize,
//   InputLabel,
//   Link,
//   MenuItem,
//   Select,
//   Stack,
//   Table,
//   TableBody,
//   TableCell,
//   TableHead,
//   TableRow,
//   TextField,
// } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import EditIcon from "@mui/icons-material/Edit";
// import { Delete } from "@mui/icons-material";
// import SettingContext from "../../../context/SettingsContext";
// import AddIcon from "@mui/icons-material/Add";
// import { LoadingButton } from "@mui/lab";
// import dayjs from "dayjs";
// import { get, post, put } from "../../../services/apis";
// import TimezoneSelect from "react-timezone-select";
// import Switch from "@mui/material/Switch";
// import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// // import "../../../styles/components/Setting/Setting.scss";
// import { urls as url } from "../../../services/urlConstant";
// import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
// import appendStringToUrls from "../../../utils/appendString";
// import Wrapper from "../../Wrapper";
// const label = { inputProps: { "aria-label": "Switch demo" } };

// const styles = {
//   card: {
//     padding: 15,
//     marginBottom: 20,
//     display: "flex",
//     alignItem: "center",
//     justifyContent: "space-between",
//     overflow: "visible",
//   },
//   textfield: {
//     width: "100%",
//     label: { fontSize: 12 },
//   },
//   Button: {
//     background: "#1b3779",
//     ":hover": { background: "#1b3779" },
//     color: "#fff",
//   },
// };
// const ManageInstitute = () => {
//   const { settings, setSettings, selectedSetting } = useContext(SettingContext);
//   const [addDialog, setAddDialog] = useState(false);
//   const [settingDetails, setSettingDetails] = useState(null);
//   const [schoolCreateLogoLink, setSchoolCreateLogoLink] = useState("");
//   const [schoolLogoLink, setSchoolLogoLink] = useState("");
//   const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
//   const [sessionStartMonth, setSessionStartMonth] = useState(dayjs(Date.now()));
//   const [sessionEndMonth, setSessionEndMonth] = useState(dayjs(Date.now()));
//   const [previewUrl, setPreviewUrl] = useState(null);

//   const [previewCreateUrl, setPreviewCreateUrl] = useState(null);
//   const [schoolCreateLogo, setSchoolCreatelLogo] = useState("");
//   const { setSettingsContext } = useContext(SettingContext);
//   const [createDetails, setCreateDetails] = useState({
//     regDate: dayjs(Date.now()),
//   });
//   const [loading, setLoading] = useState(false);

//   const navigate = useNavigate();
//   const handleOpenAddDialog = () => {
//     setAddDialog(true);
//   };
//   const handleCreatePhoto = async (e) => {
//     const formData = new FormData();
//     formData.append("files", e.target.files[0]);
//     try {
//       setPreviewCreateUrl(URL.createObjectURL(e.target.files[0]));
//       const res = await post(urls.fileUpload.postFileUpload, formData);
//       setCreateDetails({ ...createDetails, logo: res.data.result[0]._id });
//       setSchoolCreateLogoLink(res.data.result[0].link);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleCreateOnChange = (e) => {
//     const { name, value } = e.target;
//     setCreateDetails({ ...createDetails, [name]: value });
//   };
//   const handleCreateSubmit = async (e) => {
//     setLoading(true);
//     try {
//       e.preventDefault();
//       const data = {
//         ...createDetails,
//         schoolName: createDetails.schoolName,
//         twitterUrl: createDetails.twitterUrl,
//         linkedinUrl: createDetails.linkedinUrl,
//         gplusUrl: createDetails.gplusUrl,
//         youtubeUrl: createDetails.youtubeUrl,
//         instagramUrl: createDetails.instagramUrl,
//         pinterestUrl: createDetails.pinterestUrl,
//         facebookUrl: createDetails.facebookUrl,
//         address: createDetails.address,
//         phone: createDetails.phone,
//         regDate: dayjs(createDetails.regDate).format("YYYY-MM-DD"),
//         email: createDetails.email,
//         schFax: createDetails.schFax,
//         websiteFooter: createDetails.websiteFooter,
//         description: createDetails.description,
//         logo: createDetails.logo,
//         currency: createDetails.currency,
//         currencySymbol: createDetails.currencySymbol,
//         sessionStartMonth: createDetails.sessionStartMonth,
//         sessionEndMonth: createDetails.sessionEndMonth,
//         rollNumberType: createDetails.rollNumberType,
//         admissionNo: createDetails.admissionNo,
//         longitude: createDetails.longitude,
//         latitude: createDetails.latitude,
//         googleAnalyticsId: createDetails.googleAnalyticsId,
//         defaultTimeZone: createDetails.defaultTimeZone,
//         teacherActivityFeedbackEnabled:createDetails.teacherActivityFeedbackEnabled,
//       };
//       const res = await post(urls.settings.postSettings, data);
//       setSettings([...settings, res.data.data]);
//       console.log(res.data.data, "dataaaaa");
//       setAddDialog(false);
//       resetForm();

//       setSettingsContext((prev) => ({
//         ...prev,
//         schoolName: data.schoolName,
//         schoolLogo: schoolCreateLogoLink,
//       }));
//       setTimeout(() => {
//         setLoading(false);
//       }, 2000);
//     } catch (error) {
//       setLoading(false);

//       console.log(error);
//     }
//   };
//   const resetForm = () => {
//     setCreateDetails({});
//   };
//   const handleCreateOnChangeTime = (d) => {
//     setCreateDetails({ ...createDetails, defaultTimeZone: d });
//   };
//   const handleCloseAddDialog = () => {
//     setAddDialog(false);
//     resetForm();
//   };
//   const handlePhoto = async (e) => {
//     const formData = new FormData();
//     formData.append("files", e.target.files[0]);
//     try {
//       setPreviewUrl(URL.createObjectURL(e.target.files[0]));
//       const res = await post(urls.fileUpload.postFileUpload, formData);
//       setSettingDetails({ ...settingDetails, logo: res.data.result[0]._id });
//       setSchoolLogoLink(res.data.result[0].link);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleToggleActive = async (id) => {
//     console.log("yyyyyyy");
//     try {
//       const res = await put(`${urls.settings.toggleStatus}${id}`);
//       console.log("status ", res.data.data);
//       if (res.data.data) {
//         setSettings(
//           settings.map((toggles) =>
//             id === toggles._id ? res.data.data : toggles
//           )
//         );
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   let addNewSetting = () => {
//     navigate("/addSetting");
//   };

//   return (
//     <Wrapper>
//       <SectionsSubHeader title="Manage Institute" />
//       <div style={{ margin: "20px 0" }}>
//         <Table>
//           <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
//             <TableRow sx={{ color: "white" }}>
//               <TableCell sx={{ color: "white" }} align="center">
//                 #SL
//               </TableCell>
//               <TableCell sx={{ color: "white" }} align="center">
//                 School Name
//               </TableCell>
//               <TableCell sx={{ color: "white" }} align="center">
//                 Logo
//               </TableCell>
//               <TableCell sx={{ color: "white" }} align="center">
//                 Action
//               </TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {settings.map((set, index) => (
//               <TableRow>
//                 <TableCell align="center">{index + 1}</TableCell>
//                 <TableCell align="center">{set.schoolName}</TableCell>
//                 <TableCell align="center">
//                   {set.logo ? (
//                     <img
//                       src={set.logo.link}
//                       alt="logo"
//                       height={50}
//                       width={50}
//                       style={{
//                         borderRadius: "50%",
//                         resize: "contain",
//                       }}
//                     />
//                   ) : (
//                     "NA"
//                   )}
//                 </TableCell>
//                 <TableCell align="center">
//                   <div>
//                     <Button
//                       style={{ color: "#1b3779" }}
//                       onClick={() => navigate(`/settings/edit/${set._id}`)}
//                     >
//                       <EditIcon />
//                     </Button>

//                     <Button>
//                       <Switch
//                         onChange={() => handleToggleActive(set._id)}
//                         checked={set.active}
//                         defaultChecked
//                         style={{
//                           color: set.active ? "green" : "red",
//                         }}
//                         color="success"
//                         sx={{
//                           "& .MuiSwitch-track": {
//                             backgroundColor: set.active ? "green" : "red",
//                           },
//                         }}
//                       />
//                     </Button>
//                   </div>
//                 </TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </div>
//       <div className="add-icon">
//         <Fab
//           variant="contained"
//           onClick={() => addNewSetting()}
//           sx={{
//             color: "#ffff",
//             backgroundColor: "#1b3779",
//             background: "rgb(27, 55, 121)",
//             ":hover": { background: "rgb(27, 55, 121)" },
//           }}
//         >
//           <AddIcon />
//         </Fab>
//       </div>
//     </Wrapper>
//   );
// };

// export default ManageInstitute;


import React, { useContext, useState } from "react";
import SectionsSubHeader from "../../../components/SectionSubHeader";

import {
  Box,
  Button,
  Card,
  Fab,
  Dialog,
  FormControl,
  Grid,
  TextareaAutosize,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@mui/icons-material";
import SettingContext from "../../../context/SettingsContext";
import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";
import { get, post, put } from "../../../services/apis";
import TimezoneSelect from "react-timezone-select";
import Switch from "@mui/material/Switch";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import "../../../styles/components/Setting/Setting.scss";
import { urls as url } from "../../../services/urlConstant";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import appendStringToUrls from "../../../utils/appendString";
import Wrapper from "../../Wrapper";
const label = { inputProps: { "aria-label": "Switch demo" } };

const styles = {
  card: {
    padding: 15,
    marginBottom: 20,
    display: "flex",
    alignItem: "center",
    justifyContent: "space-between",
    overflow: "visible",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
    color: "#fff",
  },
};
const ManageInstitute = () => {
  const { settings, setSettings, selectedSetting } = useContext(SettingContext);
  const [addDialog, setAddDialog] = useState(false);
  const [settingDetails, setSettingDetails] = useState(null);
  const [schoolCreateLogoLink, setSchoolCreateLogoLink] = useState("");
  const [schoolLogoLink, setSchoolLogoLink] = useState("");
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [sessionStartMonth, setSessionStartMonth] = useState(dayjs(Date.now()));
  const [sessionEndMonth, setSessionEndMonth] = useState(dayjs(Date.now()));
  const [previewUrl, setPreviewUrl] = useState(null);

  const [previewCreateUrl, setPreviewCreateUrl] = useState(null);
  const [schoolCreateLogo, setSchoolCreatelLogo] = useState("");
  const { setSettingsContext } = useContext(SettingContext);
  const [createDetails, setCreateDetails] = useState({
    regDate: dayjs(Date.now()),
  });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const handleOpenAddDialog = () => {
    setAddDialog(true);
  };
  const handleCreatePhoto = async (e) => {
    const formData = new FormData();
    formData.append("files", e.target.files[0]);
    try {
      setPreviewCreateUrl(URL.createObjectURL(e.target.files[0]));
      const res = await post(urls.fileUpload.postFileUpload, formData);
      setCreateDetails({ ...createDetails, logo: res.data.result[0]._id });
      setSchoolCreateLogoLink(res.data.result[0].link);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateOnChange = (e) => {
    const { name, value } = e.target;
    setCreateDetails({ ...createDetails, [name]: value });
  };
  const handleCreateSubmit = async (e) => {
    setLoading(true);
    try {
      e.preventDefault();
      const data = {
        ...createDetails,
        schoolName: createDetails.schoolName,
        twitterUrl: createDetails.twitterUrl,
        linkedinUrl: createDetails.linkedinUrl,
        gplusUrl: createDetails.gplusUrl,
        youtubeUrl: createDetails.youtubeUrl,
        instagramUrl: createDetails.instagramUrl,
        pinterestUrl: createDetails.pinterestUrl,
        facebookUrl: createDetails.facebookUrl,
        address: createDetails.address,
        phone: createDetails.phone,
        regDate: dayjs(createDetails.regDate).format("YYYY-MM-DD"),
        email: createDetails.email,
        schFax: createDetails.schFax,
        websiteFooter: createDetails.websiteFooter,
        description: createDetails.description,
        logo: createDetails.logo,
        currency: createDetails.currency,
        currencySymbol: createDetails.currencySymbol,
        sessionStartMonth: createDetails.sessionStartMonth,
        sessionEndMonth: createDetails.sessionEndMonth,
        rollNumberType: createDetails.rollNumberType,
        admissionNo: createDetails.admissionNo,
        longitude: createDetails.longitude,
        latitude: createDetails.latitude,
        googleAnalyticsId: createDetails.googleAnalyticsId,
        defaultTimeZone: createDetails.defaultTimeZone,
        teacherActivityFeedbackEnabled:createDetails.teacherActivityFeedbackEnabled,
      };
      const res = await post(urls.settings.postSettings, data);
      setSettings([...settings, res.data.data]);
      setAddDialog(false);
      resetForm();

      setSettingsContext((prev) => ({
        ...prev,
        schoolName: data.schoolName,
        schoolLogo: schoolCreateLogoLink,
      }));
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };
  const resetForm = () => {
    setCreateDetails({});
  };
  const handleCreateOnChangeTime = (d) => {
    setCreateDetails({ ...createDetails, defaultTimeZone: d });
  };
  const handleCloseAddDialog = () => {
    setAddDialog(false);
    resetForm();
  };
  const handlePhoto = async (e) => {
    const formData = new FormData();
    formData.append("files", e.target.files[0]);
    try {
      setPreviewUrl(URL.createObjectURL(e.target.files[0]));
      const res = await post(urls.fileUpload.postFileUpload, formData);
      setSettingDetails({ ...settingDetails, logo: res.data.result[0]._id });
      setSchoolLogoLink(res.data.result[0].link);
    } catch (error) {
      console.log(error);
    }
  };

  const handleToggleActive = async (id) => {
    console.log("yyyyyyy");
    try {
      const res = await put(`${urls.settings.toggleStatus}${id}`);
      console.log("status ", res.data.data);
      if (res.data.data) {
        setSettings(
          settings.map((toggles) =>
            id === toggles._id ? res.data.data : toggles
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  let addNewSetting = () => {
    navigate("/addSetting");
  };

  return (
    <Wrapper>
      <SectionsSubHeader title="Manage Institute" />
      <div style={{ margin: "20px 0" }}>
        <Table>
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow sx={{ color: "white" }}>
              <TableCell sx={{ color: "white" }} align="center">
                #SL
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                School Name
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Logo
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {settings.map((set, index) => (
              <TableRow>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center">{set.schoolName}</TableCell>
                <TableCell align="center">
                  {set.logo ? (
                    <img
                      src={set.logo.link}
                      alt="logo"
                      height={50}
                      width={50}
                      style={{
                        borderRadius: "50%",
                        resize: "contain",
                      }}
                    />
                  ) : (
                    "NA"
                  )}
                </TableCell>
                <TableCell align="center">
                  <div>
                    <Button
                      style={{ color: "#1b3779" }}
                      onClick={() => navigate(`/settings/edit/${set._id}`)}>
                      <EditIcon />
                    </Button>

                    <Button>
                      <Switch
                        onChange={() => handleToggleActive(set._id)}
                        checked={set.active}
                        defaultChecked
                        style={{
                          color: set.active ? "green" : "red",
                        }}
                        color="success"
                        sx={{
                          "& .MuiSwitch-track": {
                            backgroundColor: set.active ? "green" : "red",
                          },
                        }}
                      />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className="add-icon">
        <Fab
          variant="contained"
          onClick={() => addNewSetting()}
          sx={{
            color: "#ffff",
            backgroundColor: "#1b3779",
            background: "rgb(27, 55, 121)",
            ":hover": { background: "rgb(27, 55, 121)" },
          }}>
          <AddIcon />
        </Fab>
      </div>
    </Wrapper>
  );
};

export default ManageInstitute;
