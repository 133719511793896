import * as React from "react";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, Button, Card, Container, Grid, Modal } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

import styles from "../styles/gallery.module.css";

import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import GallerySubHome from "./GallerySubHome";
import NavBar from "./navbar/Navbar";
import Footer from "./Footer/Footer";
import SettingContext from "../context/SettingsContext";
import appendStringToUrls from "../utils/appendString";
import { get } from "../services/apis";
import { urls as url } from "../services/urlConstant";
import NavTopnav from "./NavTopnav/NavTopnav";

const TypographyMain = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  marginTop: "25px",
  fontSize: "40px",
  color: "#f86f03",
  fontWeight: "bold",
  textShadow: "10px 8px 8px #969c96",

  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
    margin: 0,
    padding: "0",
  },
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const TextBox = styled(Box)(({ theme }) => ({
  textAlign: "center",
}));

const Heading = styled(Typography)(({ theme }) => ({
  marginTop: "8rem",
  color: "#FF6D34",
  fontSize: "1rem",
  fontFamily: "sans-serif",
  fontWeight: "bold",
}));

const SecondHeading = styled(Typography)(({ theme }) => ({
  color: "#f86f03",
  textShadow: "3px 3px black",
  fontWeight: "bold",
  fontSize: "2.5rem",
  fontFamily: "sans-serif",
  marginTop: "0.8rem",
}));

const LibraryTitleContainer = styled(Box)(({ theme }) => ({
  // color: "#F86F03",
  color: "white",
  padding: "80px",
  backgroundImage:
    "linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.1)),url(/HeaderImage.png)",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    padding: "15px",
  },
}));

const LibraryTitle = styled(Typography)(({ theme }) => ({
  fontSize: "40px",
  [theme.breakpoints.down("md")]: {
    fontSize: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "25px",
  },
}));

export default function Slider() {
  const [ModalData, setModalData] = useState(false);
  let sliderRef = React.useRef(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [galleryTitles, setGalleryTitles] = useState(["All"]);
  const [selectedFilter, setSelectedFilter] = useState("All");

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let [data, setData] = useState();

  const { selectedSetting } = React.useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const [galleryData, setGalleryData] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("ALL");
  const [filteredGalleryData, setFilteredGalleryData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  // filter gallery based on title change
  React.useEffect(() => {
    if (selectedFilter === "All") {
      setFilteredData(galleryData);
    } else {
      setFilteredData(galleryData.filter((d) => d.title == selectedFilter));
    }
  }, [selectedFilter]);

  const getGalleryData = async () => {
    try {
      const { data } = await get(urls.gallery.getAllGalleries);
      setGalleryData(data.data);
      setItems(data.data);

      console.log(data.data, "rahull");
      setFilteredData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getGalleryData();
  }, [selectedSetting._id]);

  const carddata = [
    {
      desc: "Student Voting in School",
      dp: [
        "https://images.pexels.com/photos/16388234/pexels-photo-16388234/free-photo-of-moscow-street-art.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        "https://images.pexels.com/photos/17591298/pexels-photo-17591298/free-photo-of-wood-city-street-building.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        "https://images.pexels.com/photos/17215283/pexels-photo-17215283/free-photo-of-bird-water-animal-lake.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        "https://images.pexels.com/photos/17215283/pexels-photo-17215283/free-photo-of-bird-water-animal-lake.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        "https://images.pexels.com/photos/17215283/pexels-photo-17215283/free-photo-of-bird-water-animal-lake.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        "https://images.pexels.com/photos/17215283/pexels-photo-17215283/free-photo-of-bird-water-animal-lake.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
      ],

      role1: "Design",
      onClick: handleOpen,
    },
    {
      desc: "Student Voting in School",
      onClick: handleOpen,
      dp: [
        "https://media.istockphoto.com/id/1381637603/photo/mountain-landscape.webp?b=1&s=612x612&w=0&k=20&c=pP0uG1Q4O3e2RGMKDcyDd3JxSkFIOj7Tp0Y14p3aE5E=",
        "https://live.staticflickr.com/5023/5646102132_4486c65e22_c.jpg",
        "https://media.istockphoto.com/id/1382384282/photo/bangalore-or-bengaluru.jpg?s=612x612&w=0&k=20&c=6pxwL3JxNV2B_NZSLMZLhrSLqAbyCPlGuSZYKImpjKQ=",
        "https://media.istockphoto.com/id/1382384282/photo/bangalore-or-bengaluru.jpg?s=612x612&w=0&k=20&c=6pxwL3JxNV2B_NZSLMZLhrSLqAbyCPlGuSZYKImpjKQ=",
        "https://media.istockphoto.com/id/1382384282/photo/bangalore-or-bengaluru.jpg?s=612x612&w=0&k=20&c=6pxwL3JxNV2B_NZSLMZLhrSLqAbyCPlGuSZYKImpjKQ=",
        "https://media.istockphoto.com/id/1382384282/photo/bangalore-or-bengaluru.jpg?s=612x612&w=0&k=20&c=6pxwL3JxNV2B_NZSLMZLhrSLqAbyCPlGuSZYKImpjKQ=",
      ],

      role1: "Photo",
    },

    {
      desc: "Student Voting in School",
      onClick: handleOpen,
      dp: [
        "https://images.pexels.com/photos/17458347/pexels-photo-17458347/free-photo-of-summer-pattern-texture-wall.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        "https://images.pexels.com/photos/15222280/pexels-photo-15222280/free-photo-of-blurred-walking-person-on-white-background.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        "https://images.pexels.com/photos/17577062/pexels-photo-17577062/free-photo-of-montmartre-sacre-coeur.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        "https://images.pexels.com/photos/17577062/pexels-photo-17577062/free-photo-of-montmartre-sacre-coeur.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        "https://images.pexels.com/photos/17577062/pexels-photo-17577062/free-photo-of-montmartre-sacre-coeur.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        "https://images.pexels.com/photos/17577062/pexels-photo-17577062/free-photo-of-montmartre-sacre-coeur.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
      ],

      role1: "Languages",
    },
    {
      desc: "Student Voting in School",
      onClick: handleOpen,
      dp: [
        "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg",
        "https://live.staticflickr.com/5023/5646102132_4486c65e22_c.jpg",
        "https://media.istockphoto.com/id/1382384282/photo/bangalore-or-bengaluru.jpg?s=612x612&w=0&k=20&c=6pxwL3JxNV2B_NZSLMZLhrSLqAbyCPlGuSZYKImpjKQ=",
      ],

      role1: "Design",
    },
    {
      desc: "Student Voting in School",
      onClick: handleOpen,
      dp: [
        "https://images.pexels.com/photos/17228932/pexels-photo-17228932/free-photo-of-california-surfer-beach-waves.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        "https://images.pexels.com/photos/14131901/pexels-photo-14131901.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        "https://media.istockphoto.com/id/1382384282/photo/bangalore-or-bengaluru.jpg?s=612x612&w=0&k=20&c=6pxwL3JxNV2B_NZSLMZLhrSLqAbyCPlGuSZYKImpjKQ=",
      ],

      role1: "Phychology",
    },
    {
      desc: "Student Voting in School",
      onClick: handleOpen,
      dp: [
        "https://cdn.pixabay.com/photo/2015/11/16/16/28/bird-1045954_640.jpg",
        "https://live.staticflickr.com/5023/5646102132_4486c65e22_c.jpg",
        "https://media.istockphoto.com/id/1382384282/photo/bangalore-or-bengaluru.jpg?s=612x612&w=0&k=20&c=6pxwL3JxNV2B_NZSLMZLhrSLqAbyCPlGuSZYKImpjKQ=",
      ],

      role1: "Technology",
    },
    {
      desc: "Student Voting in School",
      onClick: handleOpen,
      dp: [
        "https://images.pexels.com/photos/14958090/pexels-photo-14958090/free-photo-of-aerial-view-of-hills-in-the-death-valley.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        "https://live.staticflickr.com/5023/5646102132_4486c65e22_c.jpg",
        "https://images.pexels.com/photos/17568714/pexels-photo-17568714/free-photo-of-subway-on-sunset.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
      ],

      role1: "DEVELOPMENT",
    },
    {
      desc: "Student Voting in School",
      onClick: handleOpen,
      dp: [
        "https://images.pexels.com/photos/15222280/pexels-photo-15222280/free-photo-of-blurred-walking-person-on-white-background.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        "https://images.pexels.com/photos/15740547/pexels-photo-15740547/free-photo-of-the-courtyard-of-the-sheikh-zayed-grand-mosque-in-abu-dhabi-united-arab-emirates.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        "https://media.istockphoto.com/id/1382384282/photo/bangalore-or-bengaluru.jpg?s=612x612&w=0&k=20&c=6pxwL3JxNV2B_NZSLMZLhrSLqAbyCPlGuSZYKImpjKQ=",
      ],

      role1: "Business",
    },
  ];

  const item = [
    "BUSINESS",
    "DEVELOPMENT",
    "LANGUAGES",
    "MANAGEMENT",
    "PHOTO",
    "PSYCHOLOGY",
    "TECHNOLOGY",
  ];
  useEffect(() => {
    if (selectedCategory === "ALL") {
      setFilteredGalleryData(items);
    } else {
      const filteredData = items.filter(
        (item) => item.title === selectedCategory
      );
      setFilteredGalleryData(filteredData);
    }
  }, [selectedCategory, items]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    console.log(category, "kkkkk");
  };

  let handleData = (filter) => {
    setSelectedFilter(filter);
  };

  return (
    <>
      <NavTopnav />
      <LibraryTitleContainer variant="h4" align="center">
        <LibraryTitle>GALLERY</LibraryTitle>
        <Typography sx={{ color: "white" }}>
          <Link to="/" style={{ textDecoration: "none", color: "white" }}>
            Home
          </Link>
          &nbsp; / Gallery
        </Typography>
      </LibraryTitleContainer>

      <TextBox>
        <Heading>DISCOVER NEW</Heading>
        <TypographyMain>Our Gallery</TypographyMain>
      </TextBox>

      <Grid container spacing={2}>
        {filteredGalleryData.map((item, i) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              key={i}
              sx={{ display: "flex", flexDirection: "column", flex: 1 }}
            >
              <Box
                boxShadow={1}
                sx={{ display: "flex", flexDirection: "column", flex: 1 }}
              >
                <GallerySubHome data={item} />
              </Box>
            </Grid>
          );
        })}
      </Grid>

      <Footer />
    </>
  );
}
