import { Box, Container, Grid, Typography, styled } from "@mui/material";
import React from "react";

import { Link } from "react-router-dom";
import Footer from "../../../Components2/Footer";
import foodImage from "../../../assets/Images/food2.jpg";
import NavTopnav from "../../../components/NavTopnav/NavTopnav";

const BackGround = styled(Box)(({ theme }) => ({
  backgroundImage: "url(/LatestEvents.png)",
  backgroundRepeat: "noRepeat",
  backgroundPosition: "center",
  backgroundSize: "100% 100%",
  height: "30vh",
  width: "100%",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}));

const Heading = styled(Typography)(({ theme }) => ({
  color: "#FFFFFF",
  fontSize: "2rem",
  fontFamily: "sans-serif",
  fontWeight: "bold",
}));

const Heading1 = styled(Typography)(({ theme }) => ({
  color: "#FFFFFF",
  fontSize: "1rem",
  fontFamily: "sans-serif",
  fontWeight: "bold",
}));

const Headinglink = styled(Link)(({ theme }) => ({
  color: "#FFFFFF",
  fontSize: "1rem",
  fontFamily: "sans-serif",
  fontWeight: "bold",
  textDecoration: "none",
}));

const ContentContainer = styled(Container)(({ theme }) => ({
  padding: "30px",
  [theme.breakpoints.down("md")]: {
    padding: "20px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "5px 15px",
  },
}));

const OuterBox = styled(Box)(({ theme }) => ({
  backgroundImage:
    "linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.8)),url(https://c4.wallpaperflare.com/wallpaper/869/719/717/cuisine-food-india-indian-wallpaper-preview.jpg)",
  backgroundRepeat: "no-repeat",
  width: "100%",
  backgroundSize: "100% 100%",
  paddingTop: "250px",
}));

const textContent = "white";

const Food = () => {
  return (
    <Box>
      {/* <BackGround>
        <Box>
          <Heading>Facilities</Heading>
          <Heading1>
            <Headinglink to="/facilities">Home /</Headinglink>&nbsp;Food
            Facility
          </Heading1>
        </Box>
      </BackGround> */}
      <NavTopnav />

      <OuterBox>
        <Typography
          variant="h4"
          color="#F86F03"
          textAlign="center"
          fontWeight="700"
        >
          FOOD
        </Typography>
        <ContentContainer>
          <Box sx={{ padding: "10px 0" }}>
            <Typography sx={{ fontWeight: 700, color: textContent }}>
              At Kayaka, we understand the importance of providing students with
              nutritious meals to support their overall well-being and academic
              success. We are committed to ensuring that no child goes hungry
              and offer a free food facility to eligible students. Here's
              everything you need to know:
            </Typography>
          </Box>
          <Grid container>
            <Grid lg={8} md={8} sm={12}>
              <Box>
                <Typography variant="h6" gutterBottom sx={{ color: "#F86F03" }}>
                  Nutritious Meal Options:
                </Typography>
                <Typography component="ul">
                  <Typography
                    component="li"
                    gutterBottom
                    sx={{ color: textContent }}
                  >
                    Description of the healthy and balanced meals provided to
                    students through the free food facility.
                  </Typography>
                  <Typography component="li" sx={{ color: textContent }}>
                    Emphasis on the use of fresh ingredients, whole grains,
                    fruits, and vegetables to promote good nutrition and support
                    physical and cognitive development.
                  </Typography>
                  <br />
                </Typography>
              </Box>
              <Box>
                <Typography variant="h6" gutterBottom sx={{ color: "#F86F03" }}>
                  Meal Distribution:
                </Typography>
                <Typography component="ul">
                  <Typography
                    component="li"
                    gutterBottom
                    sx={{ color: textContent }}
                  >
                    Details on how and where meals are distributed within the
                    school premises.
                  </Typography>
                  <Typography component="li" sx={{ color: textContent }}>
                    Information on the schedule and timings for breakfast,
                    lunch, and any additional meal programs, including summer
                    meal programs if applicable.
                  </Typography>
                  <br />
                </Typography>
              </Box>
              <Box>
                <Typography variant="h6" gutterBottom sx={{ color: "#F86F03" }}>
                  Allergies and Dietary Restrictions:
                </Typography>
                <Typography component="ul">
                  <Typography
                    component="li"
                    gutterBottom
                    sx={{ color: textContent }}
                  >
                    Instructions for parents to communicate any specific
                    allergies or dietary restrictions their child may have.
                  </Typography>
                  <Typography component="li" sx={{ color: textContent }}>
                    Information on how the school accommodates and addresses
                    these requirements to ensure the safety and well-being of
                    all students.
                  </Typography>
                  <br />
                </Typography>
              </Box>
              <Box>
                <Typography variant="h6" gutterBottom sx={{ color: "#F86F03" }}>
                  Food Safety and Quality:
                </Typography>
                <Typography component="ul">
                  <Typography
                    component="li"
                    gutterBottom
                    sx={{ color: textContent }}
                  >
                    Assurance of adherence to strict food safety and hygiene
                    standards in meal preparation and distribution.
                  </Typography>
                  <Typography component="li" sx={{ color: textContent }}>
                    Overview of any certifications or partnerships with local
                    health authorities to ensure the highest quality of food
                    service.
                  </Typography>
                  <br />
                </Typography>
              </Box>
              <Box>
                <Typography variant="h6" sx={{ color: "#F86F03" }}>
                  Additional Support:
                </Typography>
                <Typography component="ul">
                  <Typography
                    component="li"
                    gutterBottom
                    sx={{ color: textContent }}
                  >
                    Information on any supplementary programs or resources
                    available to students and families, such as nutrition
                    education workshops, community referrals, or partnerships
                    with local food banks or organizations.
                  </Typography>
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={12} padding="10px">
              <img src={foodImage} alt="" style={{ maxWidth: "100%" }} />
            </Grid>
          </Grid>
          <Box sx={{ padding: "10px 0" }}>
            <Typography sx={{ fontWeight: 700, color: textContent }}>
              We believe that access to healthy meals is fundamental to a
              student's ability to learn and thrive. If you have any questions
              regarding our free food facility or need assistance with the
              application process, please don't hesitate to contact our school
              administration. We are committed to ensuring that every child
              receives the nourishment they need to reach their full potential.
            </Typography>
          </Box>
        </ContentContainer>
        <Footer />
      </OuterBox>
    </Box>
  );
};

export default Food;
