import React from "react";
import GiteIcon from "@mui/icons-material/Gite";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ManIcon from "@mui/icons-material/Man";
import InventoryIcon from "@mui/icons-material/Inventory";

import {
  SettingsSuggest,
  Boy,
  AccountBalance,
  AccessTime,
  Groups,
  CheckBox,
  LocalAtmSharp,
  TextSnippet,
  SchoolRounded,
  CardMembershipRounded,
  InstallMobileRounded,
  CampaignRounded,
  CalendarMonth,
  Person,
  FiberManualRecord,
  EnhancedEncryption,
  Preview,
  CurrencyRupee,
  Receipt,
  FastForward,
  PointOfSale,
  BarChart,
  FiberManualRecordOutlined,
} from "@mui/icons-material";
import { FaChalkboardTeacher } from "react-icons/fa";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { styled } from "@mui/material";
const Teacher = styled(FaChalkboardTeacher)(() => ({
  color: "#1de9b6",
  fontSize: "22px",
}));
const menuItem = [
  {
    path: "/setting",
    name: "Setting",
    renderName: ["setting"],
    icon: <SettingsSuggest sx={{ color: "#47a093" }} />,
    subMenu: [
      // {
      //   path: "/settings",
      //   name: "School Setting",
      //   icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
      //   renderName: "setting",
      // },
      {
        path: "/manageSetting",
        name: "Manage Institute",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "manageSetting",
      },
    ],
  },

  {
    path: "/dashboard/administrator",
    name: "Administrator",
    renderName: ["academicYear", "role", "auth"], //TODO: did not find a paramter on the response
    icon: <Person sx={{ color: "#9c27b0" }} />,
    subMenu: [
      {
        path: "/dashboard/academicyear",
        name: "Academic Year",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "academicYear",
      },
      {
        path: "/role",
        name: "User Role (ACL)",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "role",
      },
      {
        path: "/role-permission",
        name: "Role Permission (ACL)",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "role",
      },
      {
        path: "/reset-password-user",
        name: "User Password Reset",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "auth",
      },
    ],
  },

  {
    path: "/dashboard/hr",
    name: "Human Resource",
    renderName: ["designation", "department", "employee"], //TODO: did not find a paramter on the response
    icon: <Boy sx={{ color: "#1779f7" }} />,
    subMenu: [
      {
        path: "/dashboard/designation",
        name: "Manage Designation",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "designation",
      },
      {
        path: "/dashboard/department",
        name: "Manage Department",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "department",
      },
      {
        path: "/dashboard/employee",
        name: "Employee",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "employee",
      },
    ],
  },
  {
    path: "/dashboard/academic",
    name: "Academic",
    renderName: ["class", "section", "subject"],
    icon: <AccountBalance sx={{ color: "#283618" }} />,
    subMenu: [
      {
        path: "/dashboard/class",
        name: "Class",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "class",
      },
      {
        path: "/dashboard/section",
        name: "Section",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "section",
      },
      {
        path: "/dashboard/subject",
        name: "Subject",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "subject",
      },
    ],
  },
  {
    path: "/routine",
    name: "Class Routine",
    renderName: ["periodRoutine"],
    icon: <AccessTime sx={{ color: "#bc6c25" }} />,
    subMenu: [
      {
        path: "/routine",
        name: "Class Routine",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "periodRoutine",
      },
    ],
  },

  {
    path: "/dashboard/student",
    name: "Student",
    renderName: ["student"],
    icon: <Groups sx={{ color: "#ff006e" }} />,
    subMenu: [
      {
        path: "/student-overview",
        name: "Overview",
        icon: <Preview style={{ fontSize: "10px" }} />,
        renderName: "student",
      },
      {
        path: "/admitstudent",
        name: "Admit Student",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "student",
      },

      {
        path: "/reshuffle",
        name: "Reshuffle",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "student",
      },
      {
        path: "/promotion",
        name: "Promotion",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "student",
      },
      {
        path: "/bulkadmission",
        name: "Bulk Admission",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "student",
      },
      {
        path: "/student-credential",
        name: "Credential",
        icon: <EnhancedEncryption style={{ fontSize: "10px" }} />,
        renderName: "student",
      },
    ],
  },
  {
    name: "Teacher",
    renderName: ["teacherActivity", "teacherFeedback"],
    icon: <Teacher />,
    subMenu: [
      {
        path: "/dashboard/teacher",
        name: "TeacherActivity",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "teacherActivity",
      },
    
    ],
  },

  {
    path: "/ac",
    name: "Account",
    renderName: ["account"],
    icon: <AccountCircleIcon sx={{ color: "#607d8b" }} />,
    // path: "/dashboard/account",

    // subMenu: [
    //   {
    //     path: "/ac",
    //     name: "Account",
    //     icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
    //     renderName: "account",
    //   },
    // ],
  },

  {
    name: "Fees",
    renderName: [
      "receiptBookItem",
      "classMap",
      "studentCategory",
      "feeCategory",
      "feeReceipt",
      "feeRemittance",
      "reconciliation",
    ],
    icon: <CurrencyRupee />,
    subMenu: [
      {
        path: "/receipt",
        name: "Receipt Book",
        icon: <Receipt />,
        renderName: "receiptBookItem",
      },
      {
        path: "/feecategorylist",
        name: "Fee Category",
        icon: <Receipt />,
        renderName: "studentCategory",
      },
      // {
      //   path: "/quick-fee",
      //   name: "Quick Fee",
      //   icon: <FastForward />,
      //   renderName: "feeReceipt",
      // },
      {
        path: "/detailed-fee",
        name: "Collect Fees",
        icon: <Receipt />,
        renderName: "feeReceipt",
      },
      {
        path: "/balancefeereport",
        name: "Balance Fee Report",
        icon: <FastForward />,
        renderName: "feeReceipt",
      },
      {
        path: "/feeoverview",
        name: "Fee Overview",
        icon: <FastForward />,
        renderName: "feeReceipt",
      },
      // {
      //   path: "/fee-remittance",
      //   name: "Fee-Remittance",
      //   icon: <PointOfSale />,
      //   renderName: "feeRemittance",
      // },
      {
        path: "/dashboard/fee/reconciliation",
        name: "Reconciliation",
        icon: <FiberManualRecordOutlined style={{ fontSize: "10px" }} />,
      },
    ],
  },
  // {
  //   path: "/dashboard/teacher",
  //   name: "TeacherActivity",
  //   renderName:"teacherActivity",
  //   icon: <CalendarMonth sx={{ color: "#c9ada7" }} />,
  // },
  {
    path: "/dashboard/attendance",
    name: "Attendance",
    renderName: ["studentAttendance", "employeeAttendanceTest"],
    icon: <CheckBox sx={{ color: "#5e548e" }} />,
    subMenu: [
      {
        path: "/student-attendance",
        name: "Student Attendance",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "studentAttendance",
      },

      {
        path: "employee-attendance",
        name: "Employee Attendance",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "employeeAttendanceTest",
      },
    ],
  },

  {
    path: "/dashboard/fee",
    name: "Fee",
    renderName: "academicYear", //TODO: did not find a paramter on the response
    icon: <LocalAtmSharp sx={{ color: "#5e548e" }} />,
    subMenu: [
      {
        path: "/dashboard/feeheader",
        name: "Fee Header",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
      },
      {
        path: "/dashboard/discount",
        name: "Discount Header",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
      },
      {
        path: "/dashboard/feecategory",
        name: "Fees Category & Plan",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
      },
      {
        path: "/dashboard/discountplan",
        name: "Disscount Plan",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
      },
      {
        path: "/dashboard/fee/category",
        name: "Fees Category & Class Map",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
      },
      {
        path: "/dashboard/fee/studentfee",
        name: "Student Fee Plan Map",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
      },
      {
        path: "/dashboard/fee/studentfees",
        name: "Student Fee",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
      },
      {
        path: "/dashboard/fee/collect",
        name: "Collect Fee",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
      },
      {
        path: "/dashboard/fee/receipt",
        name: "Fee Receipt",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
      },
      {
        path: "/dashboard/fee/report",
        name: "Report",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
      },
      {
        path: "/dashboard/fee/reporttime",
        name: "Report Time",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
      },
      {
        path: "/dashboard/fee/remittance",
        name: "Fees Remittance",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
      },
      {
        path: "/dashboard/fee/meals",
        name: "Meals",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
      },
      {
        path: "/dashboard/fee/reconciliation",
        name: "Reconciliation",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
      },
      {
        path: "/dashboard/fee/balance",
        name: "Balance Fee Report",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
      },
    ],
  },
  {
    path: "/dashboard/exam",
    name: "Exam",
    icon: <TextSnippet sx={{ color: "#d62828" }} />,
    renderName: ["examGrade", "examSchedule", "examTerm"], //TODO: did not find a paramter on the response
    subMenu: [
      {
        path: "/examgrade",
        name: "Exam Grade",
        renderName: "examGrade",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
      },
      {
        path: "/examterm",
        name: "Exam Term",
        renderName: "examTerm",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
      },
      {
        path: "/examschedule",
        name: "Exam Schedule",
        renderName: "examSchedule",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
      },
      {
        path: "/exam/hallticket",
        name: "Exam Hall Ticket",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "examSchedule",
      },
    ],
  },
  {
    path: "/dashboard/exam/mark",
    name: "Exam Mark",
    renderName: ["examAttendance", "examResult", "studentMark"], //TODO: did not find a paramter on the response
    icon: <SchoolRounded sx={{ color: "#212529" }} />,
    subMenu: [
      {
        path: "/examattendance",
        name: "Exam Attendance",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "examAttendance",
      },
      {
        path: "/managemark",
        name: "Manage Mark",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "studentMark",
      },
      {
        path: "/examresult",
        name: "Exam Result",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "examResult",
      },
      {
        path: "/marks-card",
        name: "Marks card",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "examResult",
      },

      {
        path: "/consolidated",
        name: "Consolidated Marks Sheet",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "examResult",
      },
    ],
  },

  {
    path: "/dashboard/certificate",
    name: "Certificate",
    renderName: ["certificate"],
    icon: <CardMembershipRounded sx={{ color: "#00b4d8" }} />,
    subMenu: [
      {
        path: "/studycertificate",
        name: "Study Certificate",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "certificate",
      },

      // {
      //   path: "/dashboard/certificate/feesCertificate",
      //   name: "Fees Certificate",
      //   icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
      //   renderName: "certificate",
      // },
      {
        path: "/transfercertificate",
        name: "Transfer Certificate",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "certificate",
      },
    ],
  },

  {
    path: "/dashboard/communication",
    name: "Communication",
    renderName: ["sms"], //TODO: did not find a paramter on the response
    icon: <InstallMobileRounded sx={{ color: "#ff69eb" }} />,
    subMenu: [
      {
        path: "/compose",
        name: "Compose",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "sms",
      },
      {
        path: "/sms-report",
        name: "Report",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "sms",
      },
      {
        path: "/send-credentials",
        name: "Credentials",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "sms",
      },
    ],
  },
  {
    path: "/dashboard/announcement",
    name: "Announcement",
    icon: <CampaignRounded sx={{ color: "#390099" }} />,
    renderName: ["notice", "news", "holiday"],
    subMenu: [
      {
        path: "/notice",
        name: "Notice",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "notice",
      },
      {
        path: "/announcenews",
        name: "News",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "news",
      },
      {
        path: "/announceholiday",
        name: "Holiday",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "holiday",
      },
      {
        path: "/awards",
        name: "Awards and Achievements",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "awards",
      },
      {
        path: "/splashNews",
        name: "Splash News",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "splashNews",
      },
    ],
  },

  {
    path: "/dashboard/event",
    name: "Event",
    renderName: "event",
    icon: <CalendarMonth sx={{ color: "#c9ada7" }} />,
  },

  {
    name: "Hostel",
    renderName: ["hostel"],
    icon: <GiteIcon sx={{ color: "#ffd60a" }} />,
    subMenu: [
      {
        path: "/dashboard/hostel",
        name: "Manage Hostel",
        renderName: "hostel",
      },

      {
        path: "/dashboard/hostelroomtype",
        name: "Manage Room Type",
        renderName: "hostelroomtype",
      },
      {
        path: "/dashboard/hostelroom",
        name: "Manage Room & Bed",
        renderName: "hostelroom",
      },
      {
        path: "/dashboard/hostelmember",
        name: "Hostel Member",
        renderName: "hostelmember",
      },
    ],
  },

  {
    name: "Visitor Info",
    renderName: ["visitory"],
    icon: <ManIcon />,
    subMenu: [
      {
        path: "/dashboard/visitoryinfo",
        name: "Visitor Info",
        renderName: "visitory",
      },
      {
        path: "/dashboard/studentcheckout",
        name: "Student Checkout",
        renderName: "visitory",
      },
    ],
  },

  {
    name: "Payroll",
    renderName: ["payroll"],
    icon: <CurrencyRupeeIcon sx={{ color: "#007f5f" }} />,
    subMenu: [
      {
        path: "/dashboard/grade",
        name: "Salary Grade",
        renderName: "salaryGrade",
      },
      {
        path: "/dashboard/payment",
        name: "Make Payment",
        renderName: "salaryPayment",
      },
    ],
  },

  {
    name: "Transport",
    renderName: [
      "vehicle",
      "route",
      "trips",
      "transportmember",
      "log",
      "maintenance",
    ],
    icon: <DirectionsBusIcon sx={{ color: "#6f1d1b" }} />,
    subMenu: [
      {
        path: "/dashboard/vehicle",
        name: "Vehicle",
        renderName: "vehicle",
      },
      {
        path: "/dashboard/route",
        name: "Manage Route & Trips",
        renderName: "route",
      },

      {
        path: "/dashboard/trips",
        name: "Trips",
        renderName: "trips",
      },

      {
        path: "/dashboard/transportmember",
        name: "Transport Member",
        renderName: "transportmember",
      },
      {
        path: "/dashboard/log",
        name: "Vehicle Log",
        renderName: "log",
      },
      {
        path: "/dashboard/maintenance",
        name: "Vehicle Maintenance",
        renderName: "maintenance",
      },
    ],
  },
  {
    name: "Inventory",
    renderName: ["inventory"],
    icon: <InventoryIcon />,
    subMenu: [
      {
        path: "/dashboard/item",
        name: "Item",
        renderName: "item",
      },

      {
        path: "/dashboard/vendor",
        name: "Vendor",
        renderName: "vendor",
      },
      {
        path: "/dashboard/transaction",
        name: "In/Out Transaction",
        renderName: "transaction",
      },

      {
        path: "/dashboard/itemInventory",
        name: "Stock List",
        renderName: "itemInventory",
      },
      {
        path: "/dashboard/issueDetails",
        name: "Issue",
        renderName: "issueDetails",
      },
      // {
      //   path: "/dashboard/transaction",
      //   name: "Transaction",
      //   renderName: "InOutTransaction",
      // },
      {
        path: "/dashboard/sold",
        name: "Sell",
        renderName: "Sell",
      },
    ],
  },

  // {
  //   path: "/dashboard/report",
  //   name: "Report",
  //   icon: <BarChart />,
  //   renderName: ["reports"],
  //   subMenu: [
  //     {
  //       path: "/dashboard/report/libraryReport",
  //       name: "Library report",
  //       icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
  //     },
  //     {
  //       path: "/dashboard/report/studentAttendance",
  //       name: "Student Attendance",
  //       icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
  //     },
  //     {
  //       path: "/dashboard/report/yearlyAttendance",
  //       name: "Student Yearly Attendance",
  //       icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
  //     },
  //     {
  //       path: "/dashboard/report/teacherAttendance",
  //       name: "Teacher Attendance",
  //       icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
  //     },
  //     {
  //       path: "/dashboard/report/teacherYearlyAttendance",
  //       name: "Teacher Yearly Attendance",
  //       icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
  //     },
  //     {
  //       path: "/dashboard/report/employeeAttendance",
  //       name: "Employee Attendance",
  //       icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
  //     },
  //     {
  //       path: "/dashboard/report/employeeYearlyAttendance",
  //       name: "Employee Yearly Attendance",
  //       icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
  //     },
  //     {
  //       path: "/dashboard/report/studentReport",
  //       name: "Student Report",
  //       icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
  //     },
  //     {
  //       path: "/dashboard/report/studentActivityReport",
  //       name: "Student Activity Report",
  //       icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
  //     },
  //   ],
  // },
  {
    name: "Leave Management",
    renderName: ["leave"],
    icon: <LibraryBooksIcon sx={{ color: "#bb9457" }} />,
    subMenu: [
      {
        path: "/dashboard/leaveType",
        name: "Leave Type",
        renderName: "leaveType",
      },
      {
        path: "/dashboard/employeeLeave",
        name: "Employee Leave",
        renderName: "employeeLeave",
      },
      {
        path: "/dashboard/studentsLeave",
        name: "Students Leave",
        renderName: "studentLeave",
      },

      {
        path: "/dashboard/leaveReport",
        name: "Leave Report",
        renderName: "leaveReport",
      },
    ],
  },

  {
    name: "Library",
    renderName: ["library"],
    icon: <LibraryBooksIcon sx={{ color: "#7b2cbf" }} />,
    subMenu: [
      {
        path: "/dashboard/librarybooks",
        name: "Books",
        renderName: "books",
      },
      {
        path: "/dashboard/libraryperiodical",
        name: "Periodical",
        renderName: "periodical",
      },
      {
        path: "/dashboard/studentlibrarymember",
        name: "Student Library Member",
        renderName: "studentLibraryMember",
      },

      {
        path: "/dashboard/studentissue",
        name: "Student Issue & Return",
        renderName: "studentIssue",
      },
      {
        path: "/dashboard/employeelibrary",
        name: "Employee Library Member",
        renderName: "employeeLibraryMember",
      },
      {
        path: "/dashboard/employeeissue",
        name: "Employee Issue & Return",
        renderName: "employeeIssue",
      },
    ],
  },
];

export default menuItem;
