import { Box, Card, Container, Typography } from "@mui/material";

import React from "react";

const CustomCourse = ({ data }) => {
  return (
    <Container sx={{ marginTop: "25px" }}>
      <Card sx={{ textAlign: "center" }}>
        <Box>
          <img
            src={data.image ? data.image.link : "awards.png"}
            alt=""
            style={{ width: "40vw" }}
          />
        </Box>

        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 700, color: "#F76D35", fontSize: "2rem" }}
        >
          {" "}
          {data.title}{" "}
        </Typography>

        <Typography
          variant="subtitle1"
          sx={{ textAlign: "center", textIndent: "2rem", marginTop: "1rem" }}
        >
          {" "}
          {data.note}{" "}
        </Typography>
      </Card>
    </Container>
  );
};

export default CustomCourse;
