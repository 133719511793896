import {
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState, useContext } from "react";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import { styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SettingContext from "../../context/SettingsContext";
import { useNavigate } from "react-router-dom";
import { Person } from "@material-ui/icons";

const MainContainer = styled(Box)({
  backgroundColor: "#C8C8C8",
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
  padding: "10px",
});

const SideContainer = styled(Grid)(({ theme }) => ({
  marginLeft: "5%",
  [theme.breakpoints.down("sm")]: {
    padding: "5px",
    marginLeft: "0",
  },
}));

const ButtonContainer = styled(Grid)(({ theme }) => ({
  width: "60vw",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "3px",
  marginLeft: "30%",
  [theme.breakpoints.down("md")]: {
    padding: "3px",
    marginLeft: 0,
    gap: "5px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "3px",
    marginLeft: 0,
  },
}));

const SelectButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#068FFF",
  "&:hover": { backgroundColor: "#068FFF" },
  [theme.breakpoints.down("sm")]: {
    fontSize: "11px",
  },
}));

const TopNav = () => {  
  const navigate = useNavigate();
  
  const { settings, selectedSetting, setSelectedSetting } =
    useContext(SettingContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(
    selectedSetting ? selectedSetting.schoolName : "ABC School"
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionSelect = (option) => {
    setSelectedSetting(option);
    handleClose();
  };

  return (
    <>
      <MainContainer>
        <SideContainer container sx={{ padding: "10px", gap: 2 }}>
          <Grid item sx={{ display: "flex", alignItems: "center" }}>
            <CallIcon
              sx={{ color: "#068FFF", marginRight: "10px" }}
              fontSize="2px"
            />
            <Typography
              variant="body2"
              sx={{ color: "black", fontWeight: "bold" }}
            >
              +91 {selectedSetting ? selectedSetting.phone : 9999999999}
            </Typography>
          </Grid>
          <Grid item sx={{ display: "flex", alignItems: "center" }}>
            <EmailIcon
              sx={{ color: "#068FFF", marginRight: "10px" }}
              fontSize="2px"
            />
            <Typography
              variant="body2"
              sx={{ color: "black", fontWeight: "bold" }}
            >
              {selectedSetting ? selectedSetting.email : "abc@gmail.com"}
            </Typography>
          </Grid>
        </SideContainer>

        <Stack
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: { xs: "10px", lg: "80px" },
          }}
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
        >
          <>
            <SelectButton
              variant="contained"
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
            >
              {selectedSetting ? selectedSetting.schoolName : "ABC School"}
            </SelectButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {" "}
              {settings.map((s) => {
                return (
                  <MenuItem key={s._id} onClick={() => handleOptionSelect(s)}>
                    {s.schoolName}
                  </MenuItem>
                );
              })}
            </Menu>
          </>
          <Button
            startIcon={    <Person sx={{ color: "#ffff" }} />}
            onClick={() => navigate(selectedSetting ? "/dashboard" : "/login")}
            variant="contained"
            sx={{
              color: "white",
              background: "#F86F03",
              "&:hover": { backgroundColor: "#F86F03" },
            }}
          >
            Login
          </Button>
        </Stack>
      </MainContainer>
    </>
  );
};

export default TopNav;
