import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Card,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  linearProgressClasses,
  MenuItem,
  Select,
  styled,
  Stack,
  TextField,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import dayjs from "dayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DeleteModal from "../Academic/DeleteModal";
import { Delete, Edit, RefreshRounded } from "@mui/icons-material";
import { get, post } from "../../../services/apis";
import { useNavigate } from "react-router-dom";
import { urls as url } from "../../../services/urlConstant";
import { ReportOffOutlined } from "@material-ui/icons";
import SummarizeRoundedIcon from "@mui/icons-material/SummarizeRounded";
import ReplyRoundedIcon from "@mui/icons-material/ReplyRounded";
import RedoRoundedIcon from "@mui/icons-material/RedoRounded";
import SimCardDownloadRoundedIcon from "@mui/icons-material/SimCardDownloadRounded";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import Wrapper from "../../Wrapper";
import SectionSubHeader from "../../SectionSubHeader";

const styles = {
  card: {
    padding: 10,
    margin: "10px 0px",
  },
};
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 25,
  borderRadius: 15,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 800 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 15,
    backgroundColor: theme.palette.mode === "light" ? "#f42323" : "#308fe8",
  },
}));

const Report = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [fromDate, setFromDate] = useState(dayjs().subtract(1, "day"));
  const [toDate, setToDate] = useState(dayjs());
  const [deleteModal, setDeleteModal] = useState(false);
  const [report, setReport] = useState([]);
  const [smsType, setSmsType] = useState("manual");
  const [sort, setSort] = useState({
    smsSubject: "asc",
    smsType: "asc",
    message: "asc",
    sentTime: "asc",
  });

  const sortBySmsSubject = () => {
    console.log("Subject");
    if (sort.smsSubject === "asc") {
      let sortedList = [
        ...report.sort((a, b) => b.smsSubject.localeCompare(a.smsSubject)),
      ];

      setReport(sortedList);
      setSort({ ...sort, smsSubject: "des" });
    } else {
      let sortedList = [
        ...report.sort((a, b) => a.smsSubject.localeCompare(b.smsSubject)),
      ];
      setReport(sortedList);
      setSort({ ...sort, smsSubject: "asc" });
    }
  };

  const sortBySmsType = () => {
    console.log("Type");
    if (sort.smsType === "asc") {
      let sortedList = [
        ...report.sort((a, b) => b.smsType.localeCompare(a.smsType)),
      ];

      setReport(sortedList);
      setSort({ ...sort, smsType: "des" });
    } else {
      let sortedList = [
        ...report.sort((a, b) => a.smsType.localeCompare(b.smsType)),
      ];
      setReport(sortedList);
      setSort({ ...sort, smsType: "asc" });
    }
  };

  const sortByMessage = () => {
    console.log("message");
    if (sort.message === "asc") {
      let sortedList = [
        ...report.sort((a, b) => b.message.localeCompare(a.message)),
      ];

      setReport(sortedList);
      setSort({ ...sort, message: "des" });
    } else {
      let sortedList = [
        ...report.sort((a, b) => a.message.localeCompare(b.message)),
      ];
      setReport(sortedList);
      setSort({ ...sort, message: "asc" });
    }
  };

  const compareDatesAsc = (a, b) => {
    let dateA = new Date(a.sentTime);
    let dateB = new Date(b.sentTime);

    return dateA - dateB;
  };

  const compareDatesDec = (a, b) => {
    let dateA = new Date(a.sentTime);
    let dateB = new Date(b.sentTime);

    return dateB - dateA;
  };

  const sortBySentTime = () => {
    console.log("time");
    if (sort.sentTime === "asc") {
      let sortedList = [...report.sort(compareDatesDec)];

      setReport(sortedList);
      setSort({ ...sort, sentTime: "des" });
    } else {
      let sortedList = [...report.sort(compareDatesAsc)];
      setReport(sortedList);
      setSort({ ...sort, sentTime: "asc" });
    }
  };

  const navigate = useNavigate();

  const getChangeSms = async () => {
    let res;
    if (smsType === "manual") {
      const changeSms = await get(urls.communication.getAllSms, {
        params: {
          search: {
            smsType,
            sentTime: {
              $gte: dayjs(fromDate).toDate(),
              $lte: dayjs(toDate).toDate(),
            },
          },
        },
      });
      res = changeSms;
    } else {
      const changeSms = await get(urls.communication.getAllSms, {
        params: {
          search: {
            smsType: { $not: { $regex: "manual" } },
            sentTime: {
              $gte: dayjs(fromDate).toDate(),
              $lte: dayjs(toDate).toDate(),
            },
          },
        },
      });
      res = changeSms;
    }
    setReport(res.data.data);
  };
  useEffect(() => {
    getChangeSms();
  }, [fromDate, toDate, smsType, selectedSetting._id]);

  const handleButtonClick = (message) => {
    navigate("/compose", { state: { message } });
  };
  const handleReportDownload = async (id) => {
    try {
      const smsReport = await get(`${urls.communication.getSmsReport}${id}`, {
        responseType: "blob",
      });
      if (smsReport.status === 200) {
        const uri = URL.createObjectURL(smsReport.data);
        const link = document.createElement("a");
        link.href = uri;
        link.setAttribute("download", "sms_report.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleResend = async (id) => {
    try {
      await post(`${urls.communication.resendFailedSms}${id}`);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Wrapper>
      <SectionSubHeader title="SMS Reports" />
      <div style={{ margin: "15px 0" }}>
        <Card style={styles.card}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} lg={4}>
                <FormControl size="small" fullWidth>
                  <InputLabel sx={{ fontSize: 12 }}>Type</InputLabel>
                  <Select
                    label="Type"
                    id="demo-simple-select-filled"
                    name="type"
                    value={smsType}
                    onChange={(e) => setSmsType(e.target.value)}
                  >
                    <MenuItem
                      value={"manual"}
                      style={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Manual
                    </MenuItem>
                    <MenuItem
                      value={"automatic"}
                      style={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Automatic
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} lg={4} sx={{ alignSelf: "center" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>
                    <DatePicker
                      disableFuture
                      variant="standard"
                      label="From Date"
                      openTo="day"
                      views={["day"]}
                      inputFormat="DD-MM-YYYY"
                      value={fromDate}
                      onChange={(newValue) => {
                        setFromDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={4} lg={4} sx={{ alignSelf: "center" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>
                    <DatePicker
                      disableFuture
                      variant="standard"
                      inputFormat="DD-MM-YYYY"
                      label="To Date"
                      openTo="day"
                      views={["day"]}
                      value={toDate}
                      onChange={(newValue) => {
                        setToDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Box>
        </Card>
        <div>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead style={{ background: "rgb(27 55 121)" }}>
                  <TableRow>
                    <TableCell align="center">
                      <span className="class-table-header">#SL</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Subject</span>
                      <span
                        onClick={sortBySmsSubject}
                        style={{ cursor: "pointer", color: "#fff" }}
                      >
                        {sort.smsSubject === "asc" ? "▲" : "▼"}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Type</span>
                      <span
                        onClick={sortBySmsType}
                        style={{ cursor: "pointer", color: "#fff" }}
                      >
                        {sort.smsType === "asc" ? "▲" : "▼"}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Message</span>
                      <span
                        onClick={sortByMessage}
                        style={{ cursor: "pointer", color: "#fff" }}
                      >
                        {sort.message === "asc" ? "▲" : "▼"}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Total SMS</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Time/Date</span>
                      <span
                        onClick={sortBySentTime}
                        style={{ cursor: "pointer", color: "#fff" }}
                      >
                        {sort.sentTime === "asc" ? "▲" : "▼"}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Action</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {report &&
                    report.map((row, index) => (
                      <TableRow key={row._id}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.smsSubject}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.smsType}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.message}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div>
                            <div>Total:{row.totalMessagesSent}</div>
                            <div>Awaiting:{row.totalWaiting || "NA"}</div>
                            <div>Delivered:{row.totalDelivered || "NA"}</div>
                            <div>
                              Rejected/Failed:
                              {row.totalBlocked + row.totalRejected || "NA"}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {dayjs(row.sentTime).format("DD-MM-YYYY hh:mm A")}
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              rowGap: "10px",
                              columnGap: "10px",
                              width: "100px",
                            }}
                          >
                            <IconButton
                              sx={{ borderRadius: "100%" }}
                              title="Refresh"
                              onClick={getChangeSms}
                            >
                              <RefreshRounded color="primary" />
                            </IconButton>
                            <IconButton
                              sx={{ borderRadius: "100%" }}
                              title="Report"
                              onClick={() => handleReportDownload(row._id)}
                            >
                              <SimCardDownloadRoundedIcon color="secondary" />
                            </IconButton>

                            <IconButton
                              sx={{ borderRadius: "100%" }}
                              title="Resend"
                              onClick={() => handleResend(row._id)}
                            >
                              <ReplyRoundedIcon color="warning" />
                            </IconButton>

                            <IconButton
                              sx={{ borderRadius: "100%" }}
                              title="Forward"
                              onClick={() => handleButtonClick(row.message)}
                            >
                              <RedoRoundedIcon color="success" />
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {!report.length && (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
              >
                No data found
              </Typography>
            )}
          </Paper>
        </div>
      </div>
    </Wrapper>
  );
};

export default Report;
