import { Box, Button, CardMedia, Divider, Typography } from "@mui/material";
import React from "react";
import css from "./style.module.css";
// import CourseData from './CourseData'
// import img from '../../assets/images/imgCource1.jpg'
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import TelegramIcon from "@mui/icons-material/Telegram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { FaComment } from "react-icons/fa";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import awardsImg from "../../assets/Images/awards.png";

const Cource = ({ coursedata }) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`/courseDetails/${coursedata._id}`);
    // console.log(coursedata, "iiiiiiiiiii")
  };
  return (
    <Box
      component="div"
      m={2}
      className={css.mainBox}
      sx={{
        position: "relative",
        marginBottom: "50px",
        width: "300px",
        boxShadow: (theme) => theme.shadows[2],
        borderRadius: "5px",
        padding: "5px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        //height: "300px",
      }}
    >
      <Box
        sx={{
          backgroundColor: "black",
          overflow: "hidden",
          borderRadius: "5px",
        }}
      >
        <img
          className={css.mainImage}
          src={coursedata.image ? coursedata.image.link : "awards.png"}
          style={{
            height: "35vh",
            width: "100%",
            borderRadius: "5px",
            overflow: "hidden",
            backgroundSize: "contain",
          }}
          alt=""
        />
      </Box>
      <Box>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 700,
            color: "#F86F03",
            textAlign: "center",
            padding: "10px 2px 0",
          }}
        >
          {coursedata.title}
        </Typography>
        <Typography
          varient="body2"
          fontSize={16}
          sx={{ padding: "10px 2px 10px" }}
        >
          {coursedata.note ? coursedata.note.substring(0, 150) + "..." : ""}
        </Typography>

        <Button
          className={css.button}
          onClick={handleNavigate}
          sx={{
            display: "none",
            width: "100%",
            height: "50px",
            //position: "absolute",
            marginTop: "10px",
            //marginBottom: "10px",
            //right: "2px",
            alignSelf: "center",
            color: "white",
            "&:hover": { backgroundColor: "rgb(0, 125, 241)" },
            backgroundColor: "rgb(0, 125, 241)",
          }}
        >
          View Details
        </Button>
      </Box>
    </Box>
  );
};

export default Cource;
