// import { Box, Button, Container, Grid, Typography, styled } from '@mui/material'
// import React from 'react'
// import { Link } from 'react-router-dom'

// const Para=styled(Typography)(({theme})=>({
//     fontSize:"1.1rem",
//     fontFamily:"sans-serif",
//     marginTop:"1rem",
//     color:"white",
//     lineHeight:"2.5rem",
//     textIndent:"2rem",
//     [theme.breakpoints.down(600)]:{
//       lineHeight:"2rem",
//        margin:"20px",
//     },
//     [theme.breakpoints.down(900)]:{
//        margin:"10px",
//     }

// }))



// const MainBox=styled(Box)(({theme})=>({
//         backgroundImage: "linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.8)),url(https://shtheme.com/demosd/eduvisionwp/wp-content/themes/eduvision/images/index06/3.jpg)",
//         backgroundRepeat:"no-repeat",
//         width:"100%",
      
//         justifyContent:"center",
//         display:"flex",
//         flexDirection:"column",
//         alignItems:"center",
//         marginTop:"2rem"
// }))

// const Information = () => {
//   return (
//     <MainBox>
//         <Grid container >

//            <Grid item xs={12} md={6} lg={4} sx={{display:"flex",justifyContent:"center",alignItems:"center",paddingTop:{md:"20px",xs:"20px"}}}>
//                 <img src="https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExYThhcGVmeWY4MnFqZGtjOXlraWp2a2d5cjd1OG9kYnAzNDRlbnBhayZlcD12MV9naWZzX3NlYXJjaCZjdD1n/VZUhn04QSs0AmsHRic/giphy.gif" alt="image1" height={300} width={300} />
 
//            </Grid>

           
           
//            <Grid item xs={12} md={6} lg={4}>
                 
//               <Typography sx={{fontSize:"2.5rem",fontFamily:"serif",fontWeight:"bold",color:"white",marginTop:"2rem",textAlign:"center",fontFamily:"sans-serif"}}>A QUICK LOOK AT <span style={{color:"#068FFF"}}>KAYAKA</span></Typography>
          
//             <Para> Kayaka School is a prestigious educational institution dedicated to nurturing young minds and empowering them to reach their full potential.
//                 Established in 1998, our school has garnered a reputation for academic excellence, holistic development, and a supportive learning environment.
//                 We take pride in our team of experienced educators, state-of-the-art facilities,
//                and a comprehensive curriculum designed to prepare students for success in the dynamic world of tomorrow.</Para>
            
//             <Box sx={{textAlign:"center"}}>
//            <Link to="/contact-us"> <Button
//               sx={{
//                 color: "white",
//                 backgroundColor: "#068FFF",
//                 border: "2px solid #068FFF",
//                 borderRadius: "5px",
//                 paddingTop: "10px",
//                 paddingBottom: "10px",
//                 textAlign: "center",
//                 fontWeight: "bold",
//                 alignSelf: "center",
//                 transition: "0.3s",
//                 marginTop:"1rem",
//                 marginBottom:"2rem",
//                 "&:hover": {
//                   backgroundColor: "none",
//                   color: "white",
//                 },
//               }}
//             >
//               Contact Us
//             </Button> </Link>
//             </Box>
            
//            </Grid>
               
        
//            <Grid item xs={12} md={12} lg={4} sx={{display:"flex",justifyContent:"center",alignItems:"center",marginBottom:"2rem"}}>
  
//                 <img src="https://media.baamboozle.com/uploads/images/363013/1622526141_67741_url.gif" alt="image2" height={300} width={300} />
                  

//            </Grid>


//         </Grid>
        
//     </MainBox>
//   )
// }

// export default Information









import { Box, Button, Container, Grid, Typography, styled } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const Para=styled(Typography)(({theme})=>({
    fontSize:"1.1rem",
    fontFamily:"sans-serif",
    margin:"2rem",
    color:"white",
    lineHeight:"2.5rem",
    textIndent:"2rem",
    [theme.breakpoints.down(600)]:{
      lineHeight:"2rem",
       margin:"20px",
    },
    [theme.breakpoints.down(900)]:{
       margin:"10px",
    }

}))



const MainBox=styled(Box)(({theme})=>({
        backgroundImage: "linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.8)),url(https://shtheme.com/demosd/eduvisionwp/wp-content/themes/eduvision/images/index06/3.jpg)",
        backgroundRepeat:"no-repeat",
        width:"100%",
      
        justifyContent:"center",
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        marginTop:"2rem"
}))

const Information = () => {
  return (
    <MainBox>
        <Grid container > 
           
           <Grid item xs={12} md={6} lg={8}>
                 
              <Typography sx={{fontSize:"2.5rem",fontFamily:"serif",fontWeight:"bold",color:"white",marginTop:"2rem",textAlign:"center",fontFamily:"sans-serif"}}>A QUICK LOOK AT <span style={{color:"#068FFF"}}>KAYAKA</span></Typography>
          
            <Para> Kayaka School is a prestigious educational institution dedicated to nurturing young minds and empowering them to reach their full potential.
                Established in 1998, our school has garnered a reputation for academic excellence, holistic development, and a supportive learning environment.
                We take pride in our team of experienced educators, state-of-the-art facilities,
               and a comprehensive curriculum designed to prepare students for success in the dynamic world of tomorrow.</Para>
            
            <Box sx={{textAlign:"center"}}>
           <Link to="/contact-us"> <Button
              sx={{
                color: "white",
                backgroundColor: "#068FFF",
                border: "2px solid #068FFF",
                borderRadius: "5px",
                paddingTop: "10px",
                paddingBottom: "10px",
                textAlign: "center",
                fontWeight: "bold",
                alignSelf: "center",
                transition: "0.3s",
                marginTop:"1rem",
                marginBottom:"2rem",
                "&:hover": {
                  backgroundColor: "none",
                  color: "white",
                },
              }}
            >
              Contact Us
            </Button> </Link>
            </Box>
            
           </Grid>
               
        
           <Grid item xs={12} md={6} lg={4} sx={{display:"flex",justifyContent:"center",alignItems:"center",marginBottom:"2rem",paddingTop:{md:"20px",xs:"20px"}}}>
                <img src="https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExYThhcGVmeWY4MnFqZGtjOXlraWp2a2d5cjd1OG9kYnAzNDRlbnBhayZlcD12MV9naWZzX3NlYXJjaCZjdD1n/VZUhn04QSs0AmsHRic/giphy.gif" alt="image1" height={300} width={300} />
 
           </Grid>


        </Grid>
        
    </MainBox>
  )
}

export default Information