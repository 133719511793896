// import React, { useState } from "react";

// import {
//   Paper,
//   TableContainer,
//   Table,
//   TableRow,
//   TableCell,
//   TableBody,
//   Button,
//   Grid,
//   Card,
//   Box,
//   Select,
//   MenuItem,
//   InputLabel,
//   FormControl,
//   TableHead,
//   Modal,
//   Typography,
//   TextField,
//   Collapse,
//   IconButton,
//   Tooltip,
// } from "@mui/material";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import { makeStyles } from "@mui/styles";
// import { useEffect, useContext } from "react";
// import { urls as url } from "../../../services/urlConstant";
// import "../../../styles/components/Exam/Exam.scss";
// import { get, post } from "../../../services/apis";
// import { Close, DownloadForOffline } from "@mui/icons-material";

// import style from "../../../styles/components/Layout/Attendance/AttendanceReport.module.css";
// import { instance } from "../../../services/axios.Config";
// import { LoadingButton } from "@mui/lab";
// import SettingContext from "../../../context/SettingsContext";
// import appendStringToUrls from "../../../utils/appendString";
// import SectionSubHeader from "../../../components/SectionSubHeader";
// import Wrapper from "../../Wrapper";
// import { toast, ToastContainer } from "react-toastify";

// const styles = {
//   card: {
//     padding: 10,
//     width: "100%",
//     marginBottom: "10px",
//     marginTop: 20,
//   },
//   textfield: {
//     width: "100%",
//     label: { fontSize: 12 },
//     mt: 1,
//   },
//   Button: {
//     background: "#1b3779",
//     ":hover": { background: "#1b3779" },
//     marginTop: 7,
//   },
// };
// const modalStyle = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 800,
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };
// const useStyles = makeStyles({
//   table: {
//     minWidth: 650,
//   },
// });

// const Consolidated = () => {
//   const { selectedSetting } = useContext(SettingContext);

//   const [studentsExamResult, setStudentsExamResult] = useState([]);
//   const [classes, setClasses] = useState([]);
//   const [sections, setSections] = useState([]);
//   const [examTerms, setExamterms] = useState([]);
//   const [examResultDetails, setExamResultDetails] = useState({});
//   const [studentMarksDetailsModal, setStudentMarksDetailsModal] =
//     useState(false);
//   const [studentExamResult, setStudentExamResult] = useState(undefined);
//   const [consolidated, setConsolidated] = useState([]);
//   const [selectClass, setSelectClass] = useState("");
//   const [consolidatedMarks, setConsolidatedMarks] = useState([]);
//   const consolidate = useStyles();
//   const [open, setOpen] = useState(false);
//   const [subjects, setSubjects] = useState([]);
//   const [isSubmitted, setIsSubmitted] = useState(false);
//   const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

//   let [loader, setLoader] = useState(false);
//   console.log(consolidated, "consolidated");
//   useEffect(() => {
//     setIsSubmitted(false);
//   }, [consolidated, selectedSetting]);

//   useEffect(() => {
//     const getSubjects = async () => {
//       try {
//         if (selectClass) {
//           const subjects = await get(urls.schoolSubject.getAllSubject, {
//             params: {
//               search: {
//                 class: selectClass,
//               },
//             },
//           });
//           if (subjects.data.success) {
//             setSubjects(subjects.data.data);
//           }
//         }
//       } catch (error) {}
//     };
//     getSubjects();
//   }, [selectClass, selectedSetting]);

//   const handleClick = (index) => {
//     if (index === open) return setOpen(false);
//     setOpen(index);
//   };

//   const handleAddConsolidated = () => {
//     setConsolidated([
//       ...consolidated,
//       {
//         examId: "",
//         compress: 0,
//       },
//     ]);
//   };
//   const handleCompressChange = (e, index) => {
//     setConsolidated(
//       consolidated.map((con, i) => {
//         if (index == i) {
//           return {
//             ...con,
//             compress: parseInt(e.target.value),
//           };
//         } else {
//           return con;
//         }
//       })
//     );
//   };
//   const handleExamChange = (e, index) => {
//     setConsolidated(
//       consolidated.map((con, i) => {
//         if (index == i) {
//           return {
//             ...con,
//             examId: e.target.value,
//           };
//         } else {
//           return con;
//         }
//       })
//     );
//   };
//   const handleSubjectChange = (e, examIndex, subjectIndex) => {
//     setConsolidated(
//       consolidated.map((con, i) => {
//         if (examIndex === i) {
//           return {
//             ...con,
//             subjects: con.subjects.map((subject, i) => {
//               if (subjectIndex === i) {
//                 return {
//                   ...subject,
//                   subject: e.target.value,
//                 };
//               } else {
//                 return subject;
//               }
//             }),
//           };
//         } else {
//           return con;
//         }
//       })
//     );
//   };
//   const handleSubjectCompressChange = (e, examIndex, subjectIndex) => {
//     let compress = Number(e.target.value);
//     setConsolidated(
//       consolidated.map((con, i) => {
//         if (examIndex === i) {
//           return {
//             ...con,
//             subjects: con.subjects.map((subject, i) => {
//               if (subjectIndex === i) {
//                 return {
//                   ...subject,
//                   compress: compress ? compress : "",
//                 };
//               } else {
//                 return subject;
//               }
//             }),
//           };
//         } else {
//           return con;
//         }
//       })
//     );
//   };

//   const handleClasses = async (e) => {
//     try {
//       const { name, value } = e.target;
//       setExamResultDetails({ ...examResultDetails, [name]: value });
//       const res = await get(urls.schoolSection.getAllSection, {
//         params: {
//           search: {
//             class: value,
//           },
//         },
//       });
//       setSections(res.data.data);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     const getClasses = async () => {
//       try {
//         const res = await get(urls.class.getAllClass);
//         setClasses(res.data.data);
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     getClasses();
//   }, [selectedSetting]);

//   useEffect(() => {
//     const getExamTerms = async () => {
//       try {
//         const res = await get(urls.examTerm.getAllExamTerm);
//         setExamterms(res.data.data);
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     getExamTerms();
//   }, [selectedSetting]);
//   const handleFindStudentResult = async (e) => {
//     e.preventDefault();
//     setLoader(true);
//     try {
//       const data = {
//         con: consolidated,
//         classId: selectClass,
//       };
//       const consolidatedRes = await post(
//         `/${selectedSetting._id}/exam-res/con`,
//         data
//       );
//       setConsolidatedMarks(consolidatedRes.data.data);
//       setIsSubmitted(true);
//     } catch (error) {
//       console.log(error);
//     }
//     setLoader(false);
//   };
//   const handleModalClose = () => setStudentMarksDetailsModal(false);
//   const handleModalOpen = () => setStudentMarksDetailsModal(true);

//   const handleRemoveCon = (removeIndex) => {
//     setConsolidated((prev) =>
//       prev.filter((ele, index) => index !== removeIndex)
//     );
//   };

//   const handleSheetConsolidated = async () => {
//     try {
//       const data = {
//         con: consolidated,
//         classId: selectClass,
//       };
//       const getConsolidatedSheet = await instance({
//         method: "post",
//         url: `/${selectedSetting._id}/exam-result-con-excel`,
//         data: data,
//         responseType: "blob",
//       });
//       const uri = URL.createObjectURL(getConsolidatedSheet.data);
//       const link = document.createElement("a");
//       link.href = uri;
//       link.setAttribute("download", "consolidated.xlsx");
//       document.body.appendChild(link);
//       link.click();
//       link.parentNode.removeChild(link);
//     } catch (error) {
//       console.log(error);
//       toast.error("No data available for PDF generation.");
//     }
//   };
//   const handleAddSubject = (addIndex) => {
//     setConsolidated(
//       consolidated.map((con, index) => {
//         if (index === addIndex) {
//           if (con.subjects) {
//             return {
//               ...con,
//               subjects: [
//                 ...con.subjects,
//                 {
//                   subject: "",
//                   compress: 0,
//                 },
//               ],
//             };
//           } else {
//             return {
//               ...con,
//               subjects: [
//                 {
//                   subject: "",
//                   compress: 0,
//                 },
//               ],
//             };
//           }
//         } else {
//           return con;
//         }
//       })
//     );
//   };
//   const handleRemoveSubject = (examIndex, subjectIndex) => {
//     setConsolidated(
//       consolidated.map((con, i) => {
//         if (i === examIndex) {
//           return {
//             ...con,
//             subjects: con.subjects.filter(
//               (subject, index) => index !== subjectIndex
//             ),
//           };
//         } else {
//           return con;
//         }
//       })
//     );
//   };
//   return (
//     <Wrapper>
//       <Box>
//         <SectionSubHeader title=" Consolidate " />

//         <form onSubmit={handleFindStudentResult}>
//           <Card sx={{ margin: "15px 0", padding: "10px" }}>
//             <Box sx={{ flexGrow: 1 }}>
//               <Grid container spacing={2}>
//                 <Grid item xs={12} sm={12} md={4} lg={4}>
//                   <FormControl size="small" sx={styles.textfield} required>
//                     <InputLabel
//                       id="demo-simple-select-filled-label"
//                       sx={{ fontSize: 12 }}
//                     >
//                       Class
//                     </InputLabel>
//                     <Select
//                       label="Class"
//                       labelId="demo-simple-select-filled-label"
//                       id="demo-simple-select-filled"
//                       name="examTitle"
//                       value={selectClass}
//                       onChange={(e) => setSelectClass(e.target.value)}
//                     >
//                       {classes &&
//                         classes.map((row, index) => (
//                           <MenuItem
//                             key={row._id}
//                             value={row._id}
//                             sx={{ fontSize: 12, fontWeight: 500 }}
//                           >
//                             {row.className}
//                           </MenuItem>
//                         ))}
//                     </Select>
//                   </FormControl>
//                 </Grid>
//                 <Grid item xs={12} sm={12} md={4} lg={4}>
//                   <FormControl size="small" sx={styles.textfield} required>
//                     <InputLabel
//                       id="demo-simple-select-filled-label"
//                       sx={{ fontSize: 12 }}
//                     >
//                       Section
//                     </InputLabel>
//                     <Select
//                       label="Class"
//                       labelId="demo-simple-select-filled-label"
//                       id="demo-simple-select-filled"
//                       name="examTitle"
//                       value={selectClass}
//                       onChange={(e) => setSelectClass(e.target.value)}
//                     >
//                       {sections &&
//                         sections.map((row, index) => (
//                           <MenuItem
//                             key={row._id}
//                             value={row._id}
//                             sx={{ fontSize: 12, fontWeight: 500 }}
//                           ></MenuItem>
//                         ))}
//                     </Select>
//                   </FormControl>
//                 </Grid>
//                 <Grid
//                   item
//                   xs={12}
//                   sm={12}
//                   md={4}
//                   lg={3}
//                   alignSelf="center"
//                   display="flex"
//                 >
//                   <Button
//                     variant="contained"
//                     style={styles.Button}
//                     size="small"
//                     onClick={handleAddConsolidated}
//                   >
//                     Add Exams
//                   </Button>
//                 </Grid>
//               </Grid>
//               <Box sx={{ display: "flex" }}>
//                 {/* <Box
//                   sx={{
//                     width: "100%",
//                     display: "flex",
//                     alignItems: "center",
//                     padding: "5px",
//                     ml: "0.5%",
//                     justifyContent: { md: "left", sm: "left", xs: "center" },
//                   }}>
//                    <Button
//                     variant="contained"
//                     style={styles.Button}
//                     size="small"
//                     onClick={handleAddConsolidated}>
//                     Add Exams
//                   </Button>
//                 </Box> */}

//                 <Box sx={{ width: "90%" }}>
//                   {consolidated.length > 0 &&
//                     consolidated.map((con, index) => (
//                       <Box key={index}>
//                         <Box
//                           sx={{
//                             display: "flex",
//                             gap: "12px",
//                             justifyContent: "space-around",
//                             alignItems: "flex-end",
//                           }}
//                         >
//                           <Box sx={{ width: "36%" }}>
//                             <FormControl
//                               size="small"
//                               sx={styles.textfield}
//                               required
//                               fullWidth
//                             >
//                               <TextField
//                                 required
//                                 id="outlined-uncontrolled"
//                                 label="Compress"
//                                 size="small"
//                                 sx={{ width: "100%" }}
//                                 type="number"
//                                 value={con.compress}
//                                 onChange={(e) => handleCompressChange(e, index)}
//                               />
//                             </FormControl>
//                           </Box>
//                           <Box sx={{ width: "35%" }}>
//                             <FormControl
//                               size="small"
//                               sx={styles.textfield}
//                               required
//                               fullWidth
//                             >
//                               <InputLabel
//                                 id="demo-simple-select-filled-label"
//                                 sx={{ fontSize: 12 }}
//                               >
//                                 Exam
//                               </InputLabel>
//                               <Select
//                                 label="Exam"
//                                 labelId="demo-simple-select-filled-label"
//                                 id="demo-simple-select-filled"
//                                 name="examTitle"
//                                 value={con.examId || ""}
//                                 onChange={(e) => handleExamChange(e, index)}
//                               >
//                                 {examTerms &&
//                                   examTerms.map((row, index) => (
//                                     <MenuItem
//                                       key={row._id}
//                                       value={row._id}
//                                       sx={{ fontSize: 12, fontWeight: 500 }}
//                                     >
//                                       {row.examTitle}
//                                     </MenuItem>
//                                   ))}
//                               </Select>
//                             </FormControl>
//                           </Box>
//                           <Box>
//                             <Button onClick={() => handleRemoveCon(index)}>
//                               <Close />
//                             </Button>
//                           </Box>
//                           <Box>
//                             <Button onClick={() => handleAddSubject(index)}>
//                               Add Subject
//                             </Button>
//                           </Box>
//                         </Box>
//                         <Box>
//                           {con.subjects &&
//                             con.subjects.map((subject, subjectIndex) => (
//                               <Box
//                                 sx={{
//                                   display: "flex",
//                                   gap: "10px",
//                                   justifyContent: "left",
//                                   alignItems: "flex-end",
//                                 }}
//                               >
//                                 <Box sx={{ width: "35%" }}>
//                                   <FormControl
//                                     size="small"
//                                     sx={styles.textfield}
//                                     required
//                                     fullWidth
//                                   >
//                                     <TextField
//                                       required
//                                       fullWidth
//                                       id="outlined-uncontrolled"
//                                       label="Compress"
//                                       size="small"
//                                       sx={{ width: "100%" }}
//                                       type="number"
//                                       value={subject.compress}
//                                       onChange={(e) =>
//                                         handleSubjectCompressChange(
//                                           e,
//                                           index,
//                                           subjectIndex
//                                         )
//                                       }
//                                     />
//                                   </FormControl>
//                                 </Box>
//                                 <Box sx={{ width: "35%" }}>
//                                   <FormControl
//                                     size="small"
//                                     sx={styles.textfield}
//                                     required
//                                     fullWidth
//                                   >
//                                     <InputLabel
//                                       id="demo-simple-select-filled-label"
//                                       sx={{ fontSize: 12 }}
//                                     >
//                                       Subjects
//                                     </InputLabel>
//                                     <Select
//                                       label="Subjects"
//                                       labelId="demo-simple-select-filled-label"
//                                       id="demo-simple-select-filled"
//                                       name="examTitle"
//                                       value={subject.subject || ""}
//                                       onChange={(e) =>
//                                         handleSubjectChange(
//                                           e,
//                                           index,
//                                           subjectIndex
//                                         )
//                                       }
//                                     >
//                                       {subjects &&
//                                         subjects.map((row, index) => (
//                                           <MenuItem
//                                             key={row._id}
//                                             value={row.subjectName}
//                                             sx={{
//                                               fontSize: 12,
//                                               fontWeight: 500,
//                                             }}
//                                           >
//                                             {row.subjectName}
//                                           </MenuItem>
//                                         ))}
//                                     </Select>
//                                   </FormControl>
//                                 </Box>
//                                 <Box>
//                                   <Button
//                                     onClick={() =>
//                                       handleRemoveSubject(index, subjectIndex)
//                                     }
//                                   >
//                                     <Close />
//                                   </Button>
//                                 </Box>
//                               </Box>
//                             ))}
//                         </Box>
//                       </Box>
//                     ))}
//                 </Box>
//               </Box>

//               <Grid container spacing={2}>
//                 <Grid
//                   item
//                   xs={12}
//                   md={12}
//                   lg={12}
//                   sx={{
//                     alignSelf: "center",
//                     display: "flex",
//                     justifyContent: "flex-end",
//                   }}
//                 >
//                   {consolidated.length > 0 && (
//                     <LoadingButton
//                       loading={loader}
//                       variant="contained"
//                       style={styles.Button}
//                       size="small"
//                       type="submit"
//                     >
//                       Consolidate
//                     </LoadingButton>
//                   )}
//                 </Grid>
//               </Grid>
//             </Box>
//           </Card>
//         </form>
//       </Box>
//       <Box sx={{ margin: "15px 0" }}>
//         <Paper sx={{ width: "100%", overflow: "hidden" }}>
//           {selectClass && isSubmitted && (
//             <Box className={style.iconContainer}>
//               <Button onClick={handleSheetConsolidated}>
//                 <DownloadForOffline />
//               </Button>
//             </Box>
//           )}
//           <TableContainer component={Paper}>
//             <Table aria-label="simple table">
//               <TableHead sx={{ background: "rgb(27 55 121)" }}>
//                 <TableRow>
//                   <Box
//                     sx={{
//                       display: "flex",
//                       justifyContent: "space-around",
//                       padding: "10px",
//                     }}
//                   >
//                     <Box
//                       sx={{
//                         display: "flex",
//                         width: "30%",
//                         justifyContent: "flex-start ",
//                       }}
//                     >
//                       <Box align="center">
//                         <span className="class-table-header">Name</span>
//                       </Box>
//                     </Box>
//                     <Box
//                       sx={{
//                         display: "flex",
//                         width: "100%",
//                         justifyContent: "space-around",
//                       }}
//                     >
//                       <Box align="center">
//                         <span className="class-table-header"></span>
//                       </Box>
//                       <Box align="center">
//                         <span className="class-table-header"></span>
//                       </Box>
//                       <Box align="center">
//                         <span className="class-table-header"></span>
//                       </Box>
//                     </Box>
//                   </Box>
//                 </TableRow>
//               </TableHead>
//               <TableContainer>
//                 <Table
//                   sx={{ width: "100%" }}
//                   className={consolidate.table}
//                   aria-label="collapsible table"
//                 >
//                   {consolidatedMarks.length > 0 &&
//                     consolidatedMarks.map((result, index) => (
//                       <Box>
//                         <Box sx={{ width: "100%" }}>
//                           <TableHead>
//                             <TableRow>
//                               <Box sx={{ padding: "10px" }}>
//                                 {result.basicInfo.name}
//                                 <IconButton
//                                   aria-label="expand rows"
//                                   size="small"
//                                   onClick={() => handleClick(index + 1)}
//                                 >
//                                   {open === index + 1 ? (
//                                     <KeyboardArrowUpIcon />
//                                   ) : (
//                                     <KeyboardArrowDownIcon />
//                                   )}
//                                 </IconButton>
//                               </Box>
//                             </TableRow>
//                           </TableHead>
//                         </Box>

//                         <Box sx={{ width: "100%" }}>
//                           <Collapse
//                             in={open === index + 1}
//                             timeout="auto"
//                             unmountOnExit
//                           >
//                             <TableCell
//                               sx={{
//                                 display: "flex",
//                                 justifyContent: "space-between",
//                               }}
//                             >
//                               <Box>
//                                 <Box>#SL</Box>
//                               </Box>
//                               <Box>
//                                 <Box>Subject</Box>
//                               </Box>
//                               <Box>
//                                 <Box>Consolidated Marks</Box>
//                               </Box>
//                             </TableCell>
//                             {result.exams.map((exam, index) => (
//                               <TableCell
//                                 sx={{
//                                   display: "flex",
//                                   justifyContent: "space-between",
//                                   width: "100%",
//                                 }}
//                               >
//                                 <Box
//                                   sx={{
//                                     display: "flex",
//                                     justifyContent: "center",
//                                     position: "relative",
//                                     left: "10px",
//                                   }}
//                                 >
//                                   {index + 1}
//                                 </Box>
//                                 <Box
//                                   sx={{
//                                     display: "flex",
//                                     justifyContent: "center",
//                                     position: " relative",
//                                     right: "48px",
//                                   }}
//                                 >
//                                   {exam.subjectName}
//                                 </Box>
//                                 <Box
//                                   sx={{
//                                     display: "flex",
//                                     justifyContent: "center",
//                                     position: "relative",
//                                     right: "58px",
//                                   }}
//                                 >
//                                   {exam.compressTotal}
//                                 </Box>
//                               </TableCell>
//                             ))}
//                           </Collapse>
//                         </Box>
//                       </Box>
//                     ))}
//                 </Table>
//               </TableContainer>
//             </Table>
//           </TableContainer>
//         </Paper>
//         <Modal
//           aria-labelledby="modal-modal-title"
//           aria-describedby="modal-modal-description"
//           open={studentMarksDetailsModal}
//           onClose={handleModalClose}
//         >
//           <Box sx={modalStyle}>
//             {studentExamResult && (
//               <TableContainer component={Paper}>
//                 <Table sx={{ minWidth: 250 }} aria-label="simple table">
//                   <TableHead sx={{ background: "rgb(27 55 121)" }}>
//                     <TableRow>
//                       <TableCell align="center">
//                         <span className="class-table-header">SL No</span>
//                       </TableCell>
//                       <TableCell align="center">
//                         <span className="class-table-header">Subject</span>
//                       </TableCell>
//                       <TableCell align="center">
//                         <span className="class-table-header">Max Marks</span>
//                       </TableCell>
//                       <TableCell align="center">
//                         <span className="class-table-header">
//                           Obtained Marks
//                         </span>
//                       </TableCell>
//                       <TableCell align="center">
//                         <span className="class-table-header">Grade</span>
//                       </TableCell>
//                       <TableCell align="center">
//                         <span className="class-table-header">
//                           Position In Section
//                         </span>
//                       </TableCell>
//                       <TableCell align="center">
//                         <span className="class-table-header">
//                           Postion In Class
//                         </span>
//                       </TableCell>
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     {studentExamResult &&
//                       studentExamResult.studentMarks.map((mark, index) => (
//                         <TableRow
//                           key={mark._id}
//                           sx={{
//                             "&:last-child td, &:last-child th": { border: 0 },
//                           }}
//                         >
//                           <TableCell align="center">{index + 1}</TableCell>
//                           <TableCell
//                             align="center"
//                             sx={{ textTransform: "capitalize" }}
//                           >
//                             {mark.subject.subjectName}
//                           </TableCell>
//                           <TableCell
//                             align="center"
//                             sx={{ textTransform: "capitalize" }}
//                           >
//                             {mark.maxMarks}
//                           </TableCell>
//                           <TableCell
//                             align="center"
//                             sx={{ textTransform: "capitalize" }}
//                           >
//                             {mark.writtenMarks}
//                           </TableCell>
//                           <TableCell
//                             align="center"
//                             sx={{ textTransform: "capitalize" }}
//                           >
//                             {mark.grade}
//                           </TableCell>
//                           <TableCell
//                             align="center"
//                             sx={{ textTransform: "capitalize" }}
//                           >
//                             {`postion in class`}
//                           </TableCell>
//                           <TableCell
//                             align="center"
//                             sx={{ textTransform: "capitalize" }}
//                           >
//                             {`position in class`}
//                           </TableCell>
//                         </TableRow>
//                       ))}
//                   </TableBody>
//                 </Table>
//               </TableContainer>
//             )}
//             {studentExamResult && (
//               <Box>
//                 <Typography variant="subtitle1">
//                   Total Subject: {studentExamResult.studentMarks.length}
//                 </Typography>
//                 <Typography variant="subtitle1">
//                   Max Marks: {studentExamResult.totalMax}
//                 </Typography>
//                 <Typography variant="subtitle1">
//                   Obtained Marks: {studentExamResult.totalMarks}
//                 </Typography>
//                 <Typography variant="subtitle1">
//                   Percentage: {studentExamResult.percentage}
//                 </Typography>
//               </Box>
//             )}
//           </Box>
//         </Modal>
//       </Box>
//     </Wrapper>
//   );
// };

// export default Consolidated;

import React, { useState } from "react";

import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid,
  Card,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TableHead,
  Modal,
  Typography,
  TextField,
  Collapse,
  IconButton,
  Tooltip,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { makeStyles } from "@mui/styles";
import { useEffect, useContext } from "react";
import { urls as url } from "../../../services/urlConstant";
import "../../../styles/components/Exam/Exam.scss";
import { get, post } from "../../../services/apis";
import { Close, DownloadForOffline } from "@mui/icons-material";

import style from "../../../styles/components/Layout/Attendance/AttendanceReport.module.css";
import { instance } from "../../../services/axios.Config";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../../components/SectionSubHeader";
import Wrapper from "../../Wrapper";
import { toast, ToastContainer } from "react-toastify";

const styles = {
  card: {
    padding: 10,
    width: "100%",
    marginBottom: "10px",
    marginTop: 20,
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
    mt: 1,
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
    marginTop: 7,
  },
};
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const Consolidated = () => {
  const { selectedSetting } = useContext(SettingContext);

  const [studentsExamResult, setStudentsExamResult] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [examTerms, setExamterms] = useState([]);
  const [examResultDetails, setExamResultDetails] = useState({});
  const [studentMarksDetailsModal, setStudentMarksDetailsModal] =
    useState(false);
  const [studentExamResult, setStudentExamResult] = useState(undefined);
  const [consolidated, setConsolidated] = useState([]);
  const [selectClass, setSelectClass] = useState("");
  const [consolidatedMarks, setConsolidatedMarks] = useState([]);
  const consolidate = useStyles();
  const [open, setOpen] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  let [loader, setLoader] = useState(false);
  console.log(consolidated, "consolidated");
  useEffect(() => {
    setIsSubmitted(false);
  }, [consolidated, selectedSetting]);

  useEffect(() => {
    const getSubjects = async () => {
      try {
        if (selectClass) {
          const subjects = await get(urls.schoolSubject.getAllSubject, {
            params: {
              search: {
                class: selectClass,
              },
            },
          });
          if (subjects.data.success) {
            setSubjects(subjects.data.data);
          }
        }
      } catch (error) {}
    };
    getSubjects();
  }, [selectClass, selectedSetting]);

  const handleClick = (index) => {
    if (index === open) return setOpen(false);
    setOpen(index);
  };

  const handleAddConsolidated = () => {
    setConsolidated([
      ...consolidated,
      {
        examId: "",
        compress: 0,
      },
    ]);
  };
  const handleCompressChange = (e, index) => {
    setConsolidated(
      consolidated.map((con, i) => {
        if (index == i) {
          return {
            ...con,
            compress: parseInt(e.target.value),
          };
        } else {
          return con;
        }
      })
    );
  };
  const handleExamChange = (e, index) => {
    setConsolidated(
      consolidated.map((con, i) => {
        if (index == i) {
          return {
            ...con,
            examId: e.target.value,
          };
        } else {
          return con;
        }
      })
    );
  };
  const handleSubjectChange = (e, examIndex, subjectIndex) => {
    setConsolidated(
      consolidated.map((con, i) => {
        if (examIndex === i) {
          return {
            ...con,
            subjects: con.subjects.map((subject, i) => {
              if (subjectIndex === i) {
                return {
                  ...subject,
                  subject: e.target.value,
                };
              } else {
                return subject;
              }
            }),
          };
        } else {
          return con;
        }
      })
    );
  };
  const handleSubjectCompressChange = (e, examIndex, subjectIndex) => {
    let compress = Number(e.target.value);
    setConsolidated(
      consolidated.map((con, i) => {
        if (examIndex === i) {
          return {
            ...con,
            subjects: con.subjects.map((subject, i) => {
              if (subjectIndex === i) {
                return {
                  ...subject,
                  compress: compress ? compress : "",
                };
              } else {
                return subject;
              }
            }),
          };
        } else {
          return con;
        }
      })
    );
  };

  const handleClasses = async (e) => {
    try {
      const { name, value } = e.target;
      setExamResultDetails({ ...examResultDetails, [name]: value });
      const res = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: value,
          },
        },
      });
      setSections(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        setClasses(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, [selectedSetting]);

  useEffect(() => {
    const getExamTerms = async () => {
      try {
        const res = await get(urls.examTerm.getAllExamTerm);
        setExamterms(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getExamTerms();
  }, [selectedSetting]);
  const handleFindStudentResult = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const data = {
        con: consolidated,
        classId: selectClass,
      };
      const consolidatedRes = await post(
        `/${selectedSetting._id}/exam-res/con`,
        data
      );
      setConsolidatedMarks(consolidatedRes.data.data);
      setIsSubmitted(true);
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };
  const handleModalClose = () => setStudentMarksDetailsModal(false);
  const handleModalOpen = () => setStudentMarksDetailsModal(true);

  const handleRemoveCon = (removeIndex) => {
    setConsolidated((prev) =>
      prev.filter((ele, index) => index !== removeIndex)
    );
  };

  const handleSheetConsolidated = async () => {
    try {
      const data = {
        con: consolidated,
        classId: selectClass,
      };
      const getConsolidatedSheet = await instance({
        method: "post",
        url: `/${selectedSetting._id}/exam-result-con-excel`,
        data: data,
        responseType: "blob",
      });
      const uri = URL.createObjectURL(getConsolidatedSheet.data);
      const link = document.createElement("a");
      link.href = uri;
      link.setAttribute("download", "consolidated.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.log(error);
      toast.error("No data available for PDF generation.");
    }
  };
  const handleAddSubject = (addIndex) => {
    setConsolidated(
      consolidated.map((con, index) => {
        if (index === addIndex) {
          if (con.subjects) {
            return {
              ...con,
              subjects: [
                ...con.subjects,
                {
                  subject: "",
                  compress: 0,
                },
              ],
            };
          } else {
            return {
              ...con,
              subjects: [
                {
                  subject: "",
                  compress: 0,
                },
              ],
            };
          }
        } else {
          return con;
        }
      })
    );
  };
  const handleRemoveSubject = (examIndex, subjectIndex) => {
    setConsolidated(
      consolidated.map((con, i) => {
        if (i === examIndex) {
          return {
            ...con,
            subjects: con.subjects.filter(
              (subject, index) => index !== subjectIndex
            ),
          };
        } else {
          return con;
        }
      })
    );
  };
  return (
    <Wrapper>
      <Box>
        <SectionSubHeader title=" Consolidate " />

        <form onSubmit={handleFindStudentResult}>
          <Card sx={{ margin: "15px 0", padding: "10px" }}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <FormControl size="small" sx={styles.textfield} required>
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Class
                    </InputLabel>
                    <Select
                      label="Class"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="examTitle"
                      value={selectClass}
                      onChange={(e) => setSelectClass(e.target.value)}
                    >
                      {classes &&
                        classes.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.className}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <FormControl size="small" sx={styles.textfield} required>
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Section
                    </InputLabel>
                    <Select
                      label="Class"
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="examTitle"
                      value={selectClass}
                      onChange={(e) => setSelectClass(e.target.value)}
                    >
                      {sections &&
                        sections.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          ></MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={3}
                  alignSelf="center"
                  display="flex"
                >
                  <Button
                    variant="contained"
                    style={styles.Button}
                    onClick={handleAddConsolidated}
                  >
                    Add Exams
                  </Button>
                </Grid>
              </Grid>
              <Box sx={{ display: "flex" }}>
                {/* <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    padding: "5px",
                    ml: "0.5%",
                    justifyContent: { md: "left", sm: "left", xs: "center" },
                  }}>
                   <Button
                    variant="contained"
                    style={styles.Button}
                    size="small"
                    onClick={handleAddConsolidated}>
                    Add Exams
                  </Button>
                </Box> */}

                <Box sx={{ width: "100%" }}>
                  {consolidated.length > 0 &&
                    consolidated.map((con, index) => (
                      <Box key={index}>
                        {/* <Box
                          sx={{
                            display: "flex",
                            gap: "12px",
                            justifyContent: "space-around",
                            alignItems: "flex-end",
                          }}
                        > */}
                        <Grid
                          container
                          spacing={1}
                          style={{ marginTop: "0px" }}
                        >
                          <Grid item xs={12} sm={3.5} md={3.5} lg={3.5}>
                            <FormControl
                              size="small"
                              sx={styles.textfield}
                              required
                              fullWidth
                            >
                              <TextField
                                required
                                id="outlined-uncontrolled"
                                label="Compress"
                                size="small"
                                sx={{ width: "100%" }}
                                type="number"
                                value={con.compress}
                                onChange={(e) => handleCompressChange(e, index)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={3.5} md={3.5} lg={3.5}>
                            <FormControl
                              size="small"
                              sx={styles.textfield}
                              required
                              fullWidth
                            >
                              <InputLabel
                                id="demo-simple-select-filled-label"
                                sx={{ fontSize: 12 }}
                              >
                                Exam
                              </InputLabel>
                              <Select
                                label="Exam"
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                name="examTitle"
                                value={con.examId || ""}
                                onChange={(e) => handleExamChange(e, index)}
                              >
                                {examTerms &&
                                  examTerms.map((row, index) => (
                                    <MenuItem
                                      key={row._id}
                                      value={row._id}
                                      sx={{ fontSize: 12, fontWeight: 500 }}
                                    >
                                      {row.examTitle}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={1} md={1} lg={1}>
                            <Button
                              sx={{
                                marginTop: {
                                  xs: "0px",
                                  sm: "0px",
                                  md: "10px",
                                  lg: "10px",
                                },
                              }}
                              onClick={() => handleRemoveCon(index)}
                            >
                              <Close />
                            </Button>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={3}
                            md={3}
                            lg={3.5}
                            alignSelf="center"
                          >
                            <Button
                              variant="contained"
                              size="small"
                              sx={{
                                color: "#ffff",
                                backgroundColor: "#ff6d00",
                                m: 1,
                                "&:hover": {
                                  color: "#ffff",
                                  backgroundColor: "#ff6d00",
                                },
                              }}
                              onClick={() => handleAddSubject(index)}
                            >
                              Add Subject
                            </Button>
                          </Grid>
                        </Grid>
                        {/* <Box sx={{ width: "36%" }}>
                            <FormControl
                              size="small"
                              sx={styles.textfield}
                              required
                              fullWidth
                            >
                              <TextField
                                required
                                id="outlined-uncontrolled"
                                label="Compress"
                                size="small"
                                sx={{ width: "100%" }}
                                type="number"
                                value={con.compress}
                                onChange={(e) => handleCompressChange(e, index)}
                              />
                            </FormControl>
                          </Box>
                          <Box sx={{ width: "35%" }}>
                            <FormControl
                              size="small"
                              sx={styles.textfield}
                              required
                              fullWidth
                            >
                              <InputLabel
                                id="demo-simple-select-filled-label"
                                sx={{ fontSize: 12 }}
                              >
                                Exam
                              </InputLabel>
                              <Select
                                label="Exam"
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                name="examTitle"
                                value={con.examId || ""}
                                onChange={(e) => handleExamChange(e, index)}
                              >
                                {examTerms &&
                                  examTerms.map((row, index) => (
                                    <MenuItem
                                      key={row._id}
                                      value={row._id}
                                      sx={{ fontSize: 12, fontWeight: 500 }}
                                    >
                                      {row.examTitle}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </Box>
                          <Box>
                            <Button onClick={() => handleRemoveCon(index)}>
                              <Close />
                            </Button>
                          </Box>
                          <Box>
                            <Button onClick={() => handleAddSubject(index)}>
                              Add Subject
                            </Button>
                          </Box> */}
                        {/* </Box> */}
                        <Box>
                          {con.subjects &&
                            con.subjects.map((subject, subjectIndex) => (
                              <Grid
                                container
                                spacing={1}
                                style={{ marginTop: "0px" }}
                                // sx={{
                                //   backgroundColor: {
                                //     xs: "#e0e0e0",
                                //     sm: "#e0e0e0",
                                //     md: "none",
                                //     lg: "none",
                                //   },
                                //   borderRadius: "5px",
                                // }}
                              >
                                <Grid item xs={10} sm={4} md={4} lg={4}>
                                  <FormControl
                                    size="small"
                                    sx={styles.textfield}
                                    required
                                    fullWidth
                                  >
                                    <TextField
                                      required
                                      fullWidth
                                      id="outlined-uncontrolled"
                                      label="Compress"
                                      size="small"
                                      sx={{ width: "100%" }}
                                      type="number"
                                      value={subject.compress}
                                      onChange={(e) =>
                                        handleSubjectCompressChange(
                                          e,
                                          index,
                                          subjectIndex
                                        )
                                      }
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={10} sm={4} md={4} lg={4}>
                                  <FormControl
                                    size="small"
                                    sx={styles.textfield}
                                    required
                                    fullWidth
                                  >
                                    <InputLabel
                                      id="demo-simple-select-filled-label"
                                      sx={{ fontSize: 12 }}
                                    >
                                      Subjects
                                    </InputLabel>
                                    <Select
                                      label="Subjects"
                                      labelId="demo-simple-select-filled-label"
                                      id="demo-simple-select-filled"
                                      name="examTitle"
                                      value={subject.subject || ""}
                                      onChange={(e) =>
                                        handleSubjectChange(
                                          e,
                                          index,
                                          subjectIndex
                                        )
                                      }
                                    >
                                      {subjects &&
                                        subjects.map((row, index) => (
                                          <MenuItem
                                            key={row._id}
                                            value={row.subjectName}
                                            sx={{
                                              fontSize: 12,
                                              fontWeight: 500,
                                            }}
                                          >
                                            {row.subjectName}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={2} sm={1} md={1} lg={1}>
                                  <Button
                                    sx={{
                                      marginTop: {
                                        xs: "-25px",
                                        sm: "10px",
                                        md: "0px",
                                        lg: "0px",
                                      },
                                    }}
                                    onClick={() =>
                                      handleRemoveSubject(index, subjectIndex)
                                    }
                                  >
                                    <Close />
                                  </Button>
                                </Grid>
                              </Grid>
                              // <Box
                              //   sx={{
                              //     display: "flex",
                              //     gap: "10px",
                              //     justifyContent: "left",
                              //     alignItems: "flex-end",
                              //   }}
                              // >
                              //   <Box sx={{ width: "35%" }}>
                              //     <FormControl
                              //       size="small"
                              //       sx={styles.textfield}
                              //       required
                              //       fullWidth
                              //     >
                              //       <TextField
                              //         required
                              //         fullWidth
                              //         id="outlined-uncontrolled"
                              //         label="Compress"
                              //         size="small"
                              //         sx={{ width: "100%" }}
                              //         type="number"
                              //         value={subject.compress}
                              //         onChange={(e) =>
                              //           handleSubjectCompressChange(
                              //             e,
                              //             index,
                              //             subjectIndex
                              //           )
                              //         }
                              //       />
                              //     </FormControl>
                              //   </Box>
                              //   <Box sx={{ width: "35%" }}>
                              //     <FormControl
                              //       size="small"
                              //       sx={styles.textfield}
                              //       required
                              //       fullWidth
                              //     >
                              //       <InputLabel
                              //         id="demo-simple-select-filled-label"
                              //         sx={{ fontSize: 12 }}
                              //       >
                              //         Subjects
                              //       </InputLabel>
                              //       <Select
                              //         label="Subjects"
                              //         labelId="demo-simple-select-filled-label"
                              //         id="demo-simple-select-filled"
                              //         name="examTitle"
                              //         value={subject.subject || ""}
                              //         onChange={(e) =>
                              //           handleSubjectChange(
                              //             e,
                              //             index,
                              //             subjectIndex
                              //           )
                              //         }
                              //       >
                              //         {subjects &&
                              //           subjects.map((row, index) => (
                              //             <MenuItem
                              //               key={row._id}
                              //               value={row.subjectName}
                              //               sx={{
                              //                 fontSize: 12,
                              //                 fontWeight: 500,
                              //               }}
                              //             >
                              //               {row.subjectName}
                              //             </MenuItem>
                              //           ))}
                              //       </Select>
                              //     </FormControl>
                              //   </Box>
                              //   <Box>
                              //     <Button
                              //       onClick={() =>
                              //         handleRemoveSubject(index, subjectIndex)
                              //       }
                              //     >
                              //       <Close />
                              //     </Button>
                              //   </Box>
                              // </Box>
                            ))}
                        </Box>
                      </Box>
                    ))}
                </Box>
              </Box>

              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{
                    alignSelf: "center",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {consolidated.length > 0 && (
                    <LoadingButton
                      loading={loader}
                      variant="contained"
                      style={styles.Button}
                      size="small"
                      type="submit"
                    >
                      Consolidate
                    </LoadingButton>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Card>
        </form>
      </Box>
      <Box sx={{ margin: "15px 0" }}>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          {selectClass && isSubmitted && (
            <Box className={style.iconContainer}>
              <Button onClick={handleSheetConsolidated}>
                <DownloadForOffline />
              </Button>
            </Box>
          )}
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead sx={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      padding: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        width: "30%",
                        justifyContent: "flex-start ",
                      }}
                    >
                      <Box align="center">
                        <span className="class-table-header">Name</span>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-around",
                      }}
                    >
                      <Box align="center">
                        <span className="class-table-header"></span>
                      </Box>
                      <Box align="center">
                        <span className="class-table-header"></span>
                      </Box>
                      <Box align="center">
                        <span className="class-table-header"></span>
                      </Box>
                    </Box>
                  </Box>
                </TableRow>
              </TableHead>
              <TableContainer>
                <Table
                  sx={{ width: "100%" }}
                  className={consolidate.table}
                  aria-label="collapsible table"
                >
                  {consolidatedMarks.length > 0 &&
                    consolidatedMarks.map((result, index) => (
                      <Box>
                        <Box sx={{ width: "100%" }}>
                          <TableHead>
                            <TableRow>
                              <Box sx={{ padding: "10px" }}>
                                {result.basicInfo.name}
                                <IconButton
                                  aria-label="expand rows"
                                  size="small"
                                  onClick={() => handleClick(index + 1)}
                                >
                                  {open === index + 1 ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <KeyboardArrowDownIcon />
                                  )}
                                </IconButton>
                              </Box>
                            </TableRow>
                          </TableHead>
                        </Box>

                        <Box sx={{ width: "100%" }}>
                          <Collapse
                            in={open === index + 1}
                            timeout="auto"
                            unmountOnExit
                          >
                            <TableCell
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box>
                                <Box>#SL</Box>
                              </Box>
                              <Box>
                                <Box>Subject</Box>
                              </Box>
                              <Box>
                                <Box>Consolidated Marks</Box>
                              </Box>
                            </TableCell>
                            {result.exams.map((exam, index) => (
                              <TableCell
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    position: "relative",
                                    left: "10px",
                                  }}
                                >
                                  {index + 1}
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    position: " relative",
                                    right: "48px",
                                  }}
                                >
                                  {exam.subjectName}
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    position: "relative",
                                    right: "58px",
                                  }}
                                >
                                  {exam.compressTotal}
                                </Box>
                              </TableCell>
                            ))}
                          </Collapse>
                        </Box>
                      </Box>
                    ))}
                </Table>
              </TableContainer>
            </Table>
          </TableContainer>
        </Paper>
        <Modal
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          open={studentMarksDetailsModal}
          onClose={handleModalClose}
        >
          <Box sx={modalStyle}>
            {studentExamResult && (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 250 }} aria-label="simple table">
                  <TableHead sx={{ background: "rgb(27 55 121)" }}>
                    <TableRow>
                      <TableCell align="center">
                        <span className="class-table-header">SL No</span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="class-table-header">Subject</span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="class-table-header">Max Marks</span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="class-table-header">
                          Obtained Marks
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="class-table-header">Grade</span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="class-table-header">
                          Position In Section
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="class-table-header">
                          Postion In Class
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {studentExamResult &&
                      studentExamResult.studentMarks.map((mark, index) => (
                        <TableRow
                          key={mark._id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell
                            align="center"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {mark.subject.subjectName}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {mark.maxMarks}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {mark.writtenMarks}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {mark.grade}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {`postion in class`}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {`position in class`}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {studentExamResult && (
              <Box>
                <Typography variant="subtitle1">
                  Total Subject: {studentExamResult.studentMarks.length}
                </Typography>
                <Typography variant="subtitle1">
                  Max Marks: {studentExamResult.totalMax}
                </Typography>
                <Typography variant="subtitle1">
                  Obtained Marks: {studentExamResult.totalMarks}
                </Typography>
                <Typography variant="subtitle1">
                  Percentage: {studentExamResult.percentage}
                </Typography>
              </Box>
            )}
          </Box>
        </Modal>
      </Box>
    </Wrapper>
  );
};

export default Consolidated;
